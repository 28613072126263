<template>
    <div class="table-body-row">
        <div
            class="table-body-cell | relative flex items-center p-2"
            :class="[column.class, { 'justify-center text-center': column.name != 'fullname' }]"
            :style="column.style"
            :key="`${lead.id}-${column.name}`"
            v-for="column in activeDashboardColumns"
        >
            <component
                :is="column.component"
                sticker-type="label"
                :lead="lead"
                :account="contextAccount"
                :dashboard-type="dashboardType"
                :start-date="parsedStartDate"
                :end-date="parsedEndDate"
                :simple-view="true"
                :in-client-card="false"
                :client-card-section="column.clientCardSection"
                :custom-field="column.customField"
                :column-title="column.title"
                :field="column.name"
                v-if="column.component"
            />

            <bulk-select
                :lead="lead"
                :disabled="$wait.is('fetching.leadTable')"
                @toggle-bulk-select="$emit('toggle-bulk-select', $event)"
                v-else-if="column.name == 'bulk_select'"
            />

            <comment :lead="lead" v-else-if="column.name == 'latest_comment:content'" />

            <product-dashboard
                :lead="lead"
                :product-name="getServiceProductName(column.name)"
                product-type="service"
                v-else-if="getServiceProductName(column.name)"
            />

            <product-dashboard
                :lead="lead"
                :product-name="getCommercialProductName(column.name)"
                product-type="commercial"
                v-else-if="getCommercialProductName(column.name)"
            />

            <lead-table-cell
                :column="column"
                :lead="lead"
                :content="column.formater(lead)"
                v-else-if="column.formater"
            />
        </div>
    </div>
</template>

<script>
    import { upperFirst, camelCase, startsWith } from 'lodash-es';
    // Components
    import { mapState } from 'pinia';
    import LeadTableCell from './LeadTableCell.vue';
    import Rating from '../lead/Rating.vue';
    import RenewalAlertNotificationDate from './columns/RenewalAlertNotificationDate.vue';
    import RenewalAlertNotificationType from './columns/RenewalAlertNotificationType.vue';
    import ResultTime from '../lead/ResultTime.vue';
    import SaleSticker from '../sale_table/SaleSticker.vue';

    // Pinia
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    // Import all components from columns folder
    const components = {};
    const requireComponent = require.context('./columns', true, /\.(js|vue)$/i);

    requireComponent.keys().forEach(fileName => {
        // Get component config
        const componentConfig = requireComponent(fileName);

        // Get PascalCase name of component
        const componentName = upperFirst(
            camelCase(
                fileName
                    .split('/')
                    .pop()
                    .replace(/\.\w+$/, ''),
            ),
        );

        components[componentName] = componentConfig.default || componentConfig;
    });

    export default {

        components: {
            ...components,
            LeadTableCell,
            Rating,
            RenewalAlertNotificationDate,
            RenewalAlertNotificationType,
            ResultTime,
            SaleSticker,
        },

        props: {
            lead: {
                type: Object,
                required: true,
            },
            activeDashboardColumns: {
                type: Array,
                required: true,
            },
            parsedStartDate: {
                type: Object,
                required: true,
            },
            parsedEndDate: {
                type: Object,
                required: true,
            },
        },

        computed: {
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),
            ...mapState(useDashboardStore, ['dashboardType']),
        },

        methods: {
            getServiceProductName(columnName) {
                return startsWith(columnName, 'service_products:') ? columnName.split('service_products:').pop() : null;
            },

            getCommercialProductName(columnName) {
                return startsWith(columnName, 'commercial_products:') ? columnName.split('commercial_products:').pop() : null;
            },
        },

        mounted() {
            this.$el.querySelectorAll('[data-tooltip]').forEach(nodeTooltip => {
                this.$tooltip.destroy(nodeTooltip);

                this.$nextTick(() => {
                    this.$tooltip.init(nodeTooltip, nodeTooltip.dataset.tooltip);
                });
            });
        },
    };
</script>
