<template>
    <activix-modal
        size="sm"
        :opened="opened"
        @open="onOpen"
        @close="close"
        @closed="onClosed"
    >
        <template slot="header">
            <div class="flex items-center justify-center">
                <h4 class="modal-title">
                    {{ editMode ? $t('dashboards.editDashboardView') : $t('dashboards.addDashboardView') }}
                </h4>
                <activix-tooltip :content="$t('dashboards.infoDashboardView')" :show-on-mobile="true">
                    <icon class="text-grey-600 text-sm ml-2" name="regular/information-circle" />
                </activix-tooltip>
            </div>
        </template>

        <template slot="body">
            <template v-if="!isSaved">
                <div class="flex items-center justify-center">
                    <div class="mr-6">
                        <activix-tooltip :content="$t('modal.title')">
                            <icon class="text-xl" name="regular/newspaper" />
                        </activix-tooltip>
                    </div>
                    <activix-input class="w-2/3" :placeholder="$t('dashboards.viewTitle')" v-model="name" />
                </div>
                <div class="flex items-center justify-center mt-4">
                    <div class="mr-6">
                        <activix-tooltip :content="$t('modal.date')">
                            <icon class="text-xl" name="regular/calendar-3" />
                        </activix-tooltip>
                    </div>
                    <div class="w-2/3">
                        <activix-multiselect
                            :options="filteredHotSwapOptions"
                            :selected="selectedHotSwap"
                            @update="updateHotSwap"
                        />
                    </div>
                </div>

                <template v-if="currentHotSwap == 'custom'">
                    <div class="flex items-center justify-center mt-4">
                        <div class="mr-6">
                            <activix-tooltip :content="$t('modal.startDate')">
                                <icon class="text-xl" name="regular/calendar-3" />
                            </activix-tooltip>
                        </div>
                        <date-time-picker
                            class="w-2/3"
                            :date-only="true"
                            :placeholder="$t('modal.startDate')"
                            v-model="currentStartDate"
                        />
                    </div>
                    <div class="flex items-center justify-center mt-4">
                        <div class="mr-6">
                            <activix-tooltip :content="$t('modal.endDate')">
                                <icon class="text-xl" name="regular/calendar-3" />
                            </activix-tooltip>
                        </div>
                        <date-time-picker
                            class="w-2/3"
                            :placeholder="$t('modal.endDate')"
                            :date-only="true"
                            v-model="currentEndDate"
                        />
                    </div>
                </template>

                <template v-if="shouldDisplaySubscription">
                    <div class="flex items-center justify-center mt-12">
                        <h4 class="modal-title">
                            {{ editMode ? $t('dashboards.editSubscription') : $t('dashboards.addSubscription') }}
                        </h4>
                        <activix-tooltip :content="$t('dashboards.infoSubscription')" :show-on-mobile="true">
                            <icon class="text-grey-600 text-sm ml-2" name="regular/information-circle" />
                        </activix-tooltip>
                    </div>

                    <hr />

                    <div class="flex items-center justify-center mt-6">
                        <div class="mr-6">
                            <activix-tooltip :content="$t('modal.recurrence')">
                                <icon class="text-xl" name="regular/calendar-sync" />
                            </activix-tooltip>
                        </div>
                        <div class="w-2/3 flex items-center">
                            <activix-multiselect
                                :options="recurrenceOptions"
                                :selected="selectedRecurrence"
                                @update="updateRecurrence"
                            />
                            <icon
                                class="link-grey-light ml-2 | hover:text-red-500"
                                :name="$icons.trash"
                                @click="subscription.recurrence = 'noSubscription'"
                                v-if="subscription.recurrence != 'noSubscription'"
                            />
                        </div>
                    </div>

                    <div class="flex items-center justify-center mt-6" v-if="subscription.recurrence !== 'noSubscription'">
                        <div class="mr-6">
                            <activix-tooltip :content="$t('modal.chooseExportType')">
                                <icon class="text-xl" name="regular/office-file-doc" />
                            </activix-tooltip>
                        </div>
                        <div class="w-2/3 flex items-center">
                            <activix-multiselect
                                :options="exportTypeOptions"
                                :placeholder="$t('modal.exportType')"
                                :selected="selectedExportType"
                                @update="updateSubscriptionExportType"
                            />
                        </div>
                    </div>
                    <div class="flex items-center justify-center mt-4" v-if="showSubGroup">
                        <div class="mr-6">
                            <activix-tooltip :content="$t('modal.chooseIncludeSubGroupToolTip')">
                                <icon class="text-xl" name="regular/information-circle" />
                            </activix-tooltip>
                        </div>
                        <activix-checkbox
                            class="w-2/3"
                            size="sm"
                            :value="subscription.subGroupsIncluded"
                            @input="updateSubGroupsIncluded($event)"
                        >
                            {{ $t('modal.chooseIncludeSubGroup') }}
                        </activix-checkbox>
                    </div>

                    <div
                        class="flex items-center justify-center mt-4"
                        v-if="showParentAccountSelector && showNextExecution"
                    >
                        <div class="mr-6">
                            <activix-tooltip :content="$t('modal.account')">
                                <icon class="text-xl" name="regular/shop" />
                            </activix-tooltip>
                        </div>
                        <div class="w-2/3">
                            <el-select
                                height="400px"
                                :filterable="true"
                                :multiple="true"
                                :collapse-tags="true"
                                :placeholder="$t('modal.accounts')"
                                :value="subscription.account"
                                @change="updateAccount"
                            >
                                <el-option-group :label="$t('modal.groups')" v-if="groupOptions.length">
                                    <el-option
                                        :label="group.label"
                                        :value="group.value"
                                        :key="group.value"
                                        v-for="group in groupOptions"
                                    />
                                </el-option-group>
                                <el-option-group :label="$t('modal.parentAccounts')">
                                    <el-option
                                        :label="parentOption.label"
                                        :value="parentOption.value"
                                        :key="parentOption.value"
                                        v-for="parentOption in parentAccountOptions"
                                    />
                                </el-option-group>
                                <el-option-group :label="$t('modal.childAccounts')">
                                    <el-option
                                        :label="childOption.label"
                                        :value="childOption.value"
                                        :key="childOption.value"
                                        v-for="childOption in childAccountOptions"
                                    />
                                </el-option-group>
                            </el-select>
                        </div>
                    </div>

                    <div class="flex items-center justify-center mt-4" v-if="showNextExecution">
                        <div class="mr-6">
                            <activix-tooltip :content="$t('modal.firstExecutionDate')">
                                <icon class="text-xl" name="regular/send-email" />
                            </activix-tooltip>
                        </div>
                        <date-time-picker
                            class="w-2/3"
                            :placeholder="$t('modal.firstExecutionDate')"
                            :start-date="minimunDate"
                            v-model="subscription.start_at"
                        />
                    </div>
                </template>
            </template>

            <div class="text-center" v-else>
                {{
                    $t('modal.subscriptionCreated', [
                        authUser.email,
                        translatedRecurrence,
                        formatedExecutionDate.toHumanShort(false),
                        formatedExecutionDate.toHumanTime(),
                        subscription.type,
                    ])
                }}
            </div>
        </template>

        <template slot="footer">
            <div class="flex justify-center">
                <activix-button @click="close">
                    {{ isSaved ? $t('general.close') : $t('general.cancel') }}
                </activix-button>
                <activix-button
                    type="primary"
                    :disabled="missingRequiredFields"
                    @click="newView"
                    v-if="!editMode && !isSaved"
                >
                    {{ $t('general.new') }}
                </activix-button>
                <activix-button
                    type="primary"
                    :disabled="missingRequiredFields"
                    @click="saveView"
                    v-if="editMode && !isSaved"
                >
                    {{ $t('general.save') }}
                </activix-button>
            </div>
        </template>
    </activix-modal>
</template>

<script>
    import { cloneDeep } from 'lodash-es';
    import { mapState } from 'pinia';
    import DashboardView from '../../entities/DashboardView.js';
    import DashboardType from '../../entities/DashboardType.js';
    import ExportType from '../../entities/ExportType.js';
    import AutomationRecurrenceType from '../../entities/AutomationRecurrenceType.js';
    import ActivixDate from '../../value-objects/ActivixDate.js';
    import Hotswap from '../../entities/Hotswap.js';
    import DateTimePicker from '../inputs/DateTimePicker.vue';
    import DashboardViewSubscription from '../../entities/DashboardViewSubscription.js';

    // Pinia
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            DateTimePicker,
        },

        props: {
            opened: {
                type: Boolean,
                required: true,
            },
            editMode: {
                type: Boolean,
                default: false,
            },
            dashboardView: {
                type: DashboardView,
                default: () => new DashboardView(),
            },
        },

        data() {
            return {
                isSaved: false,
                submitted: false,
                currentStartDate: null,
                currentEndDate: null,
                name: '',
                currentHotSwap: '',
                subscription: {
                    start_at: null,
                    type: ExportType.PDF,
                    recurrence: 'noSubscription',
                    account: [],
                    userParam: null,
                    subGroupsIncluded: false,
                },
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['accounts', 'groups', 'parentAuthUser', 'authUser']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),
            ...mapState(useDashboardStore, {
                dashboardType: 'dashboardType',
                endDate: 'endDate',
                dashboardConfigs: 'configs',
                filteredDates: 'filteredDates',
                startDate: 'startDate',
            }),

            hotSwapDefaultOptions() {
                return [
                    {
                        id: Hotswap.TODAY,
                        label: this.$t('datepicker.today'),
                    },
                    {
                        id: Hotswap.TOMORROW,
                        label: this.$t('datepicker.tomorrow'),
                    },
                    {
                        id: Hotswap.YESTERDAY,
                        label: this.$t('datepicker.yesterday'),
                    },
                    {
                        id: Hotswap.LAST_7,
                        label: this.$t('datepicker.last7Days'),
                    },
                    {
                        id: Hotswap.THIS_MONTH,
                        label: this.$t('datepicker.thisMonth'),
                    },
                    {
                        id: Hotswap.LAST_30,
                        label: this.$t('datepicker.last30Days'),
                    },
                    {
                        id: Hotswap.LAST_MONTH,
                        label: this.$t('datepicker.lastMonth'),
                    },
                    {
                        id: Hotswap.LAST_90,
                        label: this.$t('datepicker.last90Days'),
                    },
                    {
                        id: Hotswap.NEXT_90,
                        label: this.$t('datepicker.next90'),
                    },
                    {
                        id: Hotswap.SINCE_BEGINNING,
                        label: this.$t('datepicker.sinceBeginning'),
                    },
                ];
            },

            hotSwapSaleTableOptions() {
                return [
                    {
                        id: Hotswap.TODAY,
                        label: this.$t('datepicker.today'),
                    },
                    {
                        id: Hotswap.YESTERDAY,
                        label: this.$t('datepicker.yesterday'),
                    },
                    {
                        id: Hotswap.LAST_7,
                        label: this.$t('datepicker.last7Days'),
                    },
                    {
                        id: Hotswap.THIS_MONTH,
                        label: this.$t('datepicker.thisMonth'),
                    },
                    {
                        id: Hotswap.LAST_30,
                        label: this.$t('datepicker.last30Days'),
                    },
                    {
                        id: Hotswap.LAST_MONTH,
                        label: this.$t('datepicker.lastMonth'),
                    },
                    {
                        id: Hotswap.LAST_90,
                        label: this.$t('datepicker.last90Days'),
                    },
                    {
                        id: Hotswap.NEXT_90,
                        label: this.$t('datepicker.next90'),
                    },
                    {
                        id: Hotswap.FISCAL_YEAR_TO_DATE,
                        label: this.$t('datepicker.fiscalYearToDate'),
                    },
                    {
                        id: Hotswap.SINCE_BEGINNING,
                        label: this.$t('datepicker.sinceBeginning'),
                    },
                ];
            },

            hotSwapRenewalOptions() {
                return [
                    {
                        id: Hotswap.LAST_3_MONTH,
                        label: this.$t('datepicker.last3Month'),
                    },
                    {
                        id: Hotswap.LAST_MONTH,
                        label: this.$t('datepicker.lastMonth'),
                    },
                    {
                        id: Hotswap.THIS_MONTH,
                        label: this.$t('datepicker.thisMonth'),
                    },
                    {
                        id: Hotswap.NEXT_MONTH,
                        label: this.$t('datepicker.nextMonth'),
                    },
                    {
                        id: Hotswap.NEXT_3_MONTH,
                        label: this.$t('datepicker.next3Month'),
                    },
                    {
                        id: Hotswap.NEXT_6_MONTH,
                        label: this.$t('datepicker.next6Month'),
                    },
                    {
                        id: Hotswap.NEXT_12_MONTH,
                        label: this.$t('datepicker.next12Month'),
                    },
                    {
                        id: Hotswap.NEXT_18_MONTH,
                        label: this.$t('datepicker.next18Month'),
                    },
                    {
                        id: Hotswap.NEXT_24_MONTH,
                        label: this.$t('datepicker.next24Month'),
                    },
                    {
                        id: Hotswap.NEXT_36_MONTH,
                        label: this.$t('datepicker.next36Month'),
                    },
                ];
            },

            translatedRecurrence() {
                return this.subscription.recurrence
                    ? this.$t(`recurrenceTypes.adverb.${this.subscription.recurrence}`)
                    : '';
            },

            missingRequiredFields() {
                if (this.nameIsNotFilled || !this.currentHotSwap) {
                    return true;
                }

                if (this.currentHotSwap == 'custom' && (!this.currentStartDate || !this.currentEndDate)) {
                    return true;
                }

                if (this.currentHotSwap != 'custom' && !['commercial', 'event'].includes(this.dashboardType)) {
                    if (this.subscription.recurrence == null) {
                        return true;
                    }

                    if (this.subscription.recurrence != 'noSubscription') {
                        if (this.subscription.start_at == null || this.subscription.type == '') {
                            return true;
                        }

                        if (this.showParentAccountSelector && this.subscription.account.length == 0) {
                            return true;
                        }
                    }
                }

                return false;
            },

            isAdmin() {
                return this.authUser.isAdmin();
            },

            selectedRecurrence() {
                return this.recurrenceOptions.find(value => {
                    return value.id == this.subscription.recurrence;
                });
            },

            filteredHotSwapOptions() {
                let options = cloneDeep(this.hotSwapDefaultOptions);

                if (this.dashboardType == DashboardType.RENEWAL) {
                    options = cloneDeep(this.hotSwapRenewalOptions);
                } else if (this.dashboardType == DashboardType.SALE) {
                    options = cloneDeep(this.hotSwapSaleTableOptions);
                } else {
                    if (this.authUser.newer_than) {
                        options.push({
                            id: Hotswap.OLDER_THAN,
                            label: this.$t(`datepicker.${Hotswap.OLDER_THAN}`, { months: this.authUser.older_than }),
                        });
                    }

                    if (this.authUser.older_than) {
                        options.push({
                            id: Hotswap.NEWER_THAN,
                            label: this.$t(`datepicker.${Hotswap.NEWER_THAN}`, { months: this.authUser.newer_than }),
                        });
                    }
                }

                if ([DashboardType.ALL, DashboardType.WALK_IN].includes(this.dashboardType)) {
                    options.push({
                        id: Hotswap.THIS_WEEK,
                        label: this.$t('datepicker.thisWeek'),
                    });
                }

                options.push({
                    id: 'custom',
                    label: this.$t('datepicker.custom'),
                });

                return options;
            },

            selectedHotSwap() {
                return this.filteredHotSwapOptions.find(value => {
                    return value.id == this.currentHotSwap;
                });
            },

            formatedExecutionDate() {
                return new ActivixDate(this.subscription.start_at);
            },

            recurrenceOptions() {
                const payload = ['noSubscription', 'daily', 'weekly', 'monthly', 'yearly'];

                return AutomationRecurrenceType.selectOptions('id', 'label', payload);
            },

            exportTypeOptions() {
                return ExportType.values.filter(value => {
                    if (['csv', 'xls'].includes(value) && !this.authUser.hasAccessToExport()) {
                        return false;
                    }

                    return true;
                }).map(value => {
                    return {
                        id: value,
                        label: value.toUpperCase(),
                    };
                });
            },

            selectedExportType() {
                return this.exportTypeOptions.find(value => {
                    return value.id == this.subscription.type;
                });
            },

            showNextExecution() {
                return !!this.subscription.recurrence && this.subscription.recurrence != 'noSubscription';
            },

            showParentAccountSelector() {
                return this.parentAuthUser.children.length != 0 || this.isAdmin;
            },

            groupOptions() {
                let options = this.parentAuthUser.groups;

                if (this.isAdmin) {
                    options = this.groups;
                }

                return options.map(group => {
                    return {
                        label: group.name,
                        value: `g-${group.id}`,
                    };
                });
            },

            childAccountOptions() {
                if (this.isAdmin) {
                    return this.accounts
                        .filter(account => {
                            return !account.account_manager && account.active;
                        })
                        .map(account => {
                            return {
                                label: account.name,
                                value: account.id,
                            };
                        });
                }

                return this.parentAuthUser.children.map(children => {
                    return {
                        label: children.account.name,
                        value: children.account_id,
                    };
                });
            },

            parentAccountOptions() {
                if (this.isAdmin) {
                    return this.accounts
                        .filter(account => {
                            return account.account_manager && account.active;
                        })
                        .map(account => {
                            return {
                                label: account.name,
                                value: account.id,
                            };
                        });
                }

                return [
                    {
                        label: this.parentAuthUser.account.name,
                        value: this.parentAuthUser.account.id,
                    },
                ];
            },

            shouldDisplaySubscription() {
                if (this.nameIsNotFilled) {
                    return false;
                }

                if (!this.currentHotSwap || ['custom'].includes(this.currentHotSwap)) {
                    return false;
                }

                return !['commercial', 'event'].includes(this.dashboardType);
            },

            nameIsNotFilled() {
                return this.name.trim().length == 0;
            },

            minimunDate() {
                if (this.selectedRecurrence) {
                    return now().startOfDay().toString();
                }

                return '';
            },

            showSubGroup() {
                return this.subscription.recurrence !== 'noSubscription' &&
                    this.subscription.type === 'pdf' &&
                    this.dashboardConfigs.expanded.statsTable &&
                    this.dashboardType != 'service';
            },
        },

        watch: {
            dashboardView(newValue) {
                if (!newValue.isEmpty()) {
                    this.name = newValue.name;
                }
            },

            selectedHotSwap(newValue) {
                if (newValue && newValue.id == 'custom' && !this.editMode) {
                    this.currentStartDate = this.startDate;
                    this.currentEndDate = this.endDate;
                    this.subscription.recurrence = 'noSubscription';
                    this.subscription.start_at = null;
                    this.subscription.account = [];
                }
            },

            recurrence(newValue) {
                if (newValue && newValue == 'noSubscription') {
                    this.subscription.start_at = null;
                    this.subscription.account = [];
                }
            },
        },

        methods: {
            updateSubGroupsIncluded($event) {
                this.subscription.subGroupsIncluded = $event;
            },

            async saveView() {
                if (!this.showParentAccountSelector) {
                    this.subscription.account = [this.contextAccount.id];
                }

                if (this.subscription.recurrence == 'noSubscription') {
                    this.subscription.account = [];
                }

                this.subscription.userParam = this.$route.query.userId ? this.$route.query.userId : null;

                const payload = {
                    dashboard: this.dashboardType,
                    name: this.name,
                    filter: this.dashboardConfigs.filters,
                    option: this.dashboardConfigs.options,
                    expand: this.dashboardConfigs.expanded,
                    hot_swap_date: this.currentHotSwap || null,
                    start_date: this.currentStartDate && this.currentHotSwap == 'custom' ? this.currentStartDate : null,
                    end_date: this.currentEndDate && this.currentHotSwap == 'custom' ? this.currentEndDate : null,
                    order: this.dashboardConfigs.order,
                    column: this.dashboardConfigs.columnVisibilities,
                    stats_table_filter: this.dashboardConfigs.statsTableFilter,
                    dates: !empty(this.filteredDates) ? this.filteredDates : [],
                    subscription: this.subscription,
                };

                if (['newerThan', 'olderThan'].includes(payload.hot_swap_date)) {
                    payload.preset_dates = { olderThan: this.authUser.older_than, newerThan: this.authUser.newer_than };
                }

                try {
                    const response = await this.$axios.patch(`v1/dashboard-views/${this.dashboardView.id}`, payload);

                    // Update current dashboard view
                    useDashboardStore().selectedDashboardView = response.data.data;

                    const viewData = this.parentAuthUser.getDashboardView(this.dashboardView.id);
                    viewData.name = response.data.data.name;

                    // Hide modal & apply filters
                    this.closeOrDisplayMessage();
                    this.$eventBus.$emit('apply-filters');

                    this.parentAuthUser.updateDashboardView(response.data.data);
                } catch (error) {
                    this.$notify.error(this.$t('dashboardViews.alerts.update.error'));

                    throw error;
                }
            },

            closeOrDisplayMessage() {
                if (this.subscription.recurrence == 'noSubscription' || this.subscription.recurrence == '') {
                    this.close();
                    return;
                }

                this.isSaved = true;
            },

            async newView() {
                if (!this.showParentAccountSelector) {
                    this.subscription.account = [this.contextAccount.id];
                }

                if (this.subscription.recurrence == 'noSubscription') {
                    this.subscription.account = [];
                }

                this.subscription.userParam = this.$route.query.userId ? this.$route.query.userId : null;

                const payload = {
                    dashboard: this.dashboardType,
                    name: this.name,
                    filter: this.dashboardConfigs.filters,
                    option: this.dashboardConfigs.options,
                    expand: this.dashboardConfigs.expanded,
                    hot_swap_date: this.currentHotSwap ? this.currentHotSwap : null,
                    start_date: this.currentStartDate && this.currentHotSwap == 'custom' ? this.currentStartDate : null,
                    end_date: this.currentEndDate && this.currentHotSwap == 'custom' ? this.currentEndDate : null,
                    order: this.dashboardConfigs.order,
                    column: this.dashboardConfigs.columnVisibilities,
                    stats_table_filter: this.dashboardConfigs.statsTableFilter,
                    dates: !empty(this.filteredDates) ? this.filteredDates : [],
                    subscription: this.subscription,
                };

                if (['newerThan', 'olderThan'].includes(payload.hot_swap_date)) {
                    payload.preset_dates = { olderThan: this.authUser.older_than, newerThan: this.authUser.newer_than };
                }

                try {
                    const response = await this.$axios.post('v1/dashboard-views', payload);

                    this.closeOrDisplayMessage();
                    useDashboardStore().selectedDashboardView = response.data.data;
                    useDashboardStore().viewId = response.data.data.id;
                    this.$eventBus.$emit('apply-filters');
                    this.$eventBus.$emit('update-query');

                    this.parentAuthUser.addDashboardView(response.data.data);
                } catch (error) {
                    this.$notify.error(this.$t('dashboardViews.alerts.store.error'));

                    throw error;
                }
            },

            updateRecurrence(newValue) {
                this.subscription.recurrence = newValue.id;
            },

            updateSubscriptionExportType(exportType) {
                this.subscription.type = exportType.id;
            },

            updateHotSwap(newValue) {
                this.currentHotSwap = newValue.id;

                if (!this.currentHotSwap || ['custom'].includes(this.currentHotSwap)) {
                    this.subscription.recurrence = 'noSubscription';
                }
            },

            close() {
                this.$emit('update:opened', false);
                this.isSaved = false;
            },

            onOpen() {
                if (!this.dashboardView.isEmpty() && this.editMode) {
                    this.name = this.dashboardView.name;
                    this.subscription = this.dashboardView.subscription || new DashboardViewSubscription();
                    this.currentHotSwap = this.dashboardView.hot_swap_date || 'custom';

                    if (!this.subscription.type) {
                        this.subscription.type = ExportType.PDF;
                    }

                    if (empty(this.subscription.account)) {
                        this.subscription.account.push(this.contextAccount.id);
                    }

                    if (this.currentHotSwap == 'custom') {
                        this.currentStartDate = this.dashboardView.start_date;
                        this.currentEndDate = this.dashboardView.end_date;
                    }
                } else {
                    this.currentHotSwap = 'custom';

                    if (this.dashboardConfigs.currentView.hotSwapDate) {
                        this.currentHotSwap = this.dashboardConfigs.currentView.hotSwapDate;
                    } else {
                        this.currentStartDate = this.startDate;
                        this.currentEndDate = this.endDate;
                    }

                    this.subscription.account.push(this.contextAccount.id);

                    this.name = '';
                    this.subscription.recurrence = 'noSubscription';
                }
            },

            updateAccount(event) {
                this.subscription.account = event;
            },

            onClosed() {
                this.subscription.account = [];
                this.name = '';
                this.subscription.recurrence = '';
                this.subscription.start_at = null;
                this.currentHotSwap = '';
                this.subscription.type = ExportType.PDF;
                this.subscription.subGroupsIncluded = false;
            },
        },

        async mounted() {
            await this.$nextTick();

            if (!this.dashboardView.isEmpty() && this.editMode) {
                this.name = this.dashboardView.name;
            }
        },
    };
</script>
