var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.component,{tag:"component",staticClass:"table-striped table-bordered | h-full border-t-2",class:{ 'no-checkbox': !_vm.bulkEditVisible },attrs:{"id":"table-leads","key-field":"id","items":_vm.hideRows ? [_vm.emptyLead] : _vm.tableLeads,"item-size":85,"page-mode":true,"buffer":_vm.hideRows ? 200 : Math.max(_vm.tableLeads.length * 85 / 25, 10 * 85)},scopedSlots:_vm._u([{key:"before",fn:function(){return [_c('div',{ref:"header",staticClass:"lead-table-header | sticky overflow-x-auto",style:(_vm.headerStyle),attrs:{"data-scroll":"hidden"}},[(_vm.defer(1))?_c('div',{staticClass:"table-header-row"},_vm._l((_vm.activeDashboardColumns),function(column){return _c('div',{key:column.name,staticClass:"table-header-cell | relative flex items-center justify-center p-2",class:[
                        column.class,
                        {
                            'sorting-asc': _vm.pagination.orderBy == column.name && _vm.pagination.sortBy == 'asc',
                            'sorting-desc': _vm.pagination.orderBy == column.name && _vm.pagination.sortBy == 'desc',
                            'cursor-pointer': !column.fixed && !column.disableSorting,
                        } ],style:(column.style),attrs:{"data-column":("col-" + (column.name))},on:{"click":function($event){!column.fixed && !column.disableSorting ? _vm.sort(column.name) : ''}}},[_c('activix-tooltip',{attrs:{"content":column.tooltip}},[(column.name == 'bulk_select')?_c('activix-checkbox',{attrs:{"size":"xs","disabled":_vm.$wait.is('fetching.leadTable'),"data-intercom-target":"massAction.selectPage","value":_vm.allLeadsOnPageSelected || _vm.allLeadsInTableSelected},nativeOn:{"click":function($event){$event.preventDefault();return _vm.selectAll.apply(null, arguments)}}}):_c('div',{class:{ truncate: column.truncating },domProps:{"innerHTML":_vm._s(column.title)}})],1)],1)}),0):_vm._e()]),_c('div',{ref:"filter",staticClass:"overflow-x-auto",attrs:{"data-scroll":"hidden"}},[(_vm.defer(1))?_c('lead-filter',{attrs:{"filters-buffer":_vm.filtersBuffer,"filter-applied":_vm.filterApplied,"filtered":_vm.filtered},on:{"update:filter":_vm.updateFilter,"update:filters":_vm.updateFilters}}):_vm._e()],1),_vm._t("header")]},proxy:true},{key:"default",fn:function(ref){
                    var lead = ref.item;
return [(_vm.defer(1))?_c('lead-table-row',{class:{ 'opacity-0 pointer-events-none': _vm.hideRows },attrs:{"lead":lead,"active-dashboard-columns":_vm.activeDashboardColumns,"parsed-start-date":_vm.parsedStartDate,"parsed-end-date":_vm.parsedEndDate},on:{"toggle-bulk-select":_vm.toggleBulkSelect}}):_vm._e()]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }