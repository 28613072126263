<template>
    <activix-multiselect
        identifier="id"
        label="name"
        min-width="190"
        multiple="multiple"
        :allow-empty="false"
        :close-on-select="false"
        :icon="mdLayout ? 'regular/single-neutral-actions-question' : ''"
        :outline="true"
        :limit-text="limitText"
        :max-height="350"
        :options="leadTypes"
        :searchable="false"
        :selected="selectedLeadTypes"
        @update="updateTypes"
    />
</template>

<script>
    import { cloneDeep, camelCase } from 'lodash-es';
    import { mapState } from 'pinia';
    import Division from '../../entities/Division.js';
    import LeadType from '../../entities/LeadType.js';

    // Pinia
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        computed: {
            ...mapState(useGlobalStore, ['authUser', 'configs']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),
            ...mapState(useDashboardStore, ['filteredLeadTypes']),

            leadTypes() {
                let leadTypes = [];

                for (const leadType of this.configs.leadTypes) {
                    if (
                        leadType.id == LeadType.EVENT ||
                        (!this.authUser.isAdmin() &&
                            ((leadType.id == LeadType.LOYALTY && !this.authUser.hasAccessTo('dashboards.loyalty')) ||
                                (leadType.id == LeadType.RENEWAL &&
                                    !this.authUser.hasAccessTo('dashboards.renewal')))) ||
                        (leadType.id == LeadType.PRE_BOOKING &&
                            (!this.contextAccount.service || !this.authUser.hasDivisionAccess(Division.SERVICE)))
                    ) {
                        continue;
                    }

                    if (
                        leadType.id == LeadType.SMS &&
                        !(
                            (this.contextAccount.hasNioText() || this.contextAccount.childrenHasNioText()) &&
                            (this.authUser.isAdmin() || this.authUser.hasNioText() || this.authUser.childrenHasNioText())
                        )
                    ) {
                        continue;
                    }

                    leadTypes.push(leadType);
                }

                // Map
                leadTypes = leadTypes.map(a => {
                    const b = cloneDeep(a);
                    b.name = this.$t(`leadTypes.${camelCase(b.name)}`);
                    return b;
                });

                // Sort
                leadTypes.sort((a, b) => a.name.localeCompare(b.name));

                return leadTypes;
            },

            selectedLeadTypes() {
                return this.leadTypes.filter(c => this.filteredLeadTypes.find(a => a.id === c.id));
            },
        },

        watch: {
            leadTypes() {
                this.initLeadTypes();
            },
        },

        methods: {
            updateTypes(newValue) {
                useDashboardStore().filteredLeadTypes = newValue;
            },

            limitText(count) {
                return this.$t('selectpicker.xSelected', [count]);
            },

            initLeadTypes() {
                useDashboardStore().filteredLeadTypes = this.leadTypes;
            },
        },

        mounted() {
            this.initLeadTypes();
        },
    };
</script>
