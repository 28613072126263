<template>
    <div class="relative">
        <activix-checkbox
            readonly
            :value="checked"
            :size="size"
            :color="color"
            @click.native.prevent
        />
    </div>
</template>

<script>
    /* eslint-disable vue/require-prop-types */
    export default {
        props: ['lead', 'size', 'color'],

        computed: {
            intentions() {
                return this.lead.service_process?.intention?.value || [];
            },

            checked() {
                const hasOverSale = this.lead.products.some(product => {
                    return (
                        product.type == 'service' && product.pivot.sold && !this.intentions.includes(product.category)
                    );
                });

                return !!this.intentions.length && hasOverSale;
            },
        },
    };
</script>
