<template>
    <div>
        <div class="overflow-x-auto">
            <activix-table
                class="border-t-2 border-b"
                sort-by-key=""
                trans-table="tradeReport"
                :columns="generalColumns"
                :data="generalLeads"
                :headers="generalHeaders"
            />
        </div>

        <div class="overflow-x-auto">
            <activix-table
                class="border-t-2 mt-8"
                sort-by-key=""
                trans-table="tradeReport"
                :columns="detailedColumns"
                :data="detailedLeads"
                :headers="detailedHeaders"
            />
        </div>
    </div>
</template>

<script>
    import { upperFirst } from 'lodash-es';
    import ActivixTable from './Table.vue';

    export default {
        name: 'TradeReport',

        components: {
            ActivixTable,
        },

        props: {
            generalLeads: {
                type: Array,
                default: () => [],
            },
            detailedLeads: {
                type: Array,
                default: () => [],
            },
        },

        computed: {
            generalHeaders() {
                return [
                    {
                        name: 'general',
                        class: 'text-center',
                        colspan: '4',
                    },
                    {
                        name: 'totalsAndAverages',
                        class: 'text-center',
                        colspan: '3',
                    },
                    {
                        name: 'divisionRation',
                        class: 'text-center',
                        colspan: '2',
                    },
                ];
            },

            generalColumns() {
                return [
                    {
                        name: 'vehicles',
                        class: 'text-center',
                    },
                    {
                        name: 'evaluatedGeneral',
                        class: 'text-center',
                    },
                    {
                        name: 'boughtGeneral',
                        tooltip: this.boughtTooltip,
                        class: 'text-center',
                    },
                    {
                        name: 'lostGeneral',
                        tooltip: this.lostTooltip,
                        class: 'text-center',
                    },
                    {
                        name: 'evaluatedValue',
                        subTooltip: this.$t('tradeReport.totalsAndAverages'),
                        class: 'text-center',
                    },
                    {
                        name: 'boughtValue',
                        tooltip: this.boughtTooltip,
                        subTooltip: this.$t('tradeReport.totalsAndAverages'),
                        class: 'text-center',
                    },
                    {
                        name: 'lostValue',
                        tooltip: this.lostTooltip,
                        subTooltip: this.$t('tradeReport.totalsAndAverages'),
                        class: 'text-center',
                    },
                    {
                        name: 'new',
                        class: 'text-center',
                    },
                    {
                        name: 'used',
                        class: 'text-center',
                    },
                ];
            },

            detailedHeaders() {
                return [
                    {
                        name: 'detailed',
                        class: 'text-center',
                        colspan: '6',
                    },
                    {
                        name: 'totals',
                        class: 'text-center',
                        colspan: '6',
                    },
                ];
            },

            detailedColumns() {
                return [
                    {
                        name: 'detailsGeneral',
                        class: 'text-center',
                    },
                    {
                        name: 'wholesaleGeneral',
                        class: 'text-center',
                    },
                    {
                        name: 'recycledGeneral',
                        class: 'text-center',
                    },
                    {
                        name: 'returnGeneral',
                        class: 'text-center',
                    },
                    {
                        name: 'excludeGeneral',
                        class: 'text-center',
                    },
                    {
                        name: 'lostGeneral',
                        class: 'text-center',
                    },
                    {
                        name: 'detailsValue',
                        class: 'text-center',
                    },
                    {
                        name: 'wholesaleValue',
                        class: 'text-center',
                    },
                    {
                        name: 'recycledValue',
                        class: 'text-center',
                    },
                    {
                        name: 'returnValue',
                        class: 'text-center',
                    },
                    {
                        name: 'excludeValue',
                        class: 'text-center',
                    },
                    {
                        name: 'lostValue',
                        class: 'text-center',
                    },
                ];
            },

            boughtTooltip() {
                return `${upperFirst(this.$t('tradeReport.detailsGeneral'))}, ${this.$t(
                    'tradeReport.wholesaleGeneral',
                )}, ${this.$t('tradeReport.recycledGeneral')}`;
            },

            lostTooltip() {
                return `${upperFirst(this.$t('tradeReport.returnGeneral'))}, ${this.$t(
                    'tradeReport.excludeGeneral',
                )}, ${this.$t('tradeReport.lostGeneral')}`;
            },
        },
    };
</script>
