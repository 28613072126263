deliveryObjective<template>
    <div class="flex items-center justify-center" v-if="saleObjective > 0 && saleObjective <= deliveryObjective">
        <activix-tooltip :content="tooltip">
            <div class="relative">
                <icon class="text-green-500" scale="1.6" name="light/trophy" />
                <div class="absolute inset-0 text-center text-xs font-bold text-green-500">
                    {{ deliveryObjective }}
                </div>
            </div>
        </activix-tooltip>
    </div>
</template>

<script>
    export default {
        props: {
            deliveryObjective: Number,
            saleObjective: Number,
        },

        computed: {
            tooltip() {
                const objective = this.saleObjective;
                const deliveries = this.deliveryObjective;

                return `
                    <div>
                        ${this.$t('saleTable.objective')} : ${objective}
                        ${this.$tc('saleTable.table.objectiveSales', objective)}
                    </div>
                    <div>
                        ${this.$t('saleTable.objectiveReached')} : ${deliveries}
                        ${this.$tc('saleTable.cards.delivered', deliveries)}
                    </div>
                `;
            },
        },
    };
</script>
