<template>
    <div class="truncate">
        <template v-if="isReception">
            {{ $t('divisions.reception') }}
        </template>
        <template v-else-if="lead.service_advisor_id || lead.service_agent_id">
            <activix-tooltip :content="serviceAdvisorTooltip" v-if="lead.service_advisor_id">
                <div :class="{ 'text-grey-500': isSertiImported('service_advisor_id') }">
                    {{ lead.service_advisor.fullName }}
                </div>
            </activix-tooltip>
            <activix-tooltip :content="serviceAgentTooltip" v-if="lead.service_agent_id">
                <div :class="{ 'text-grey-500': isSertiImported('service_agent_id') }">
                    {{ `${lead.service_agent.fullName} [Agent]` }}
                </div>
            </activix-tooltip>
        </template>
        <template v-else>
            <div>-</div>
        </template>
    </div>
</template>

<script>
    /* eslint-disable vue/require-prop-types */

    import { mapState } from 'pinia';
    import { useGlobalStore } from '../../../store/store.js';

    export default {
        props: ['lead'],

        computed: {
            ...mapState(useGlobalStore, ['groupDisplay']),

            serviceAdvisorTooltip() {
                if (!this.isSertiImported('service_advisor_id')) {
                    return '';
                }

                return this.$t('service.sertiImportedTooltip');
            },

            serviceAgentTooltip() {
                if (!this.isSertiImported('service_agent_id')) {
                    return '';
                }

                return this.$t('service.sertiImportedTooltip');
            },

            isReception() {
                const serviceUserAssigned = !!this.lead.service_advisor_id || !!this.lead.service_agent_id;

                if (!this.lead.communications.length) {
                    return false;
                }

                if (!this.lead.communications[0].receptionist) {
                    return false;
                }

                if (this.groupDisplay && serviceUserAssigned) {
                    return false;
                }

                return true;
            },
        },

        methods: {
            isSertiImported(field) {
                const importedFields = this.lead.serti_imported_fields || [];

                return importedFields.includes(field);
            },
        },
    };
</script>
