<template>
    <div>
        <div class="flex flex-wrap lg:-mx-3">
            <card-small
                class="px-3 w-full | xs:w-1/2 | xl:w-1/4"
                back-icon="regular/single-neutral-actions"
                :number="cards.leads.number.count"
                :title="$tc('service.cards.clients', cards.leads.number.count)"
                :back-number="cards.leads.assigned.count"
                :back-title="$tc('dashboards.assignedClient', cards.leads.assigned.count)"
            />
            <card-small
                class="px-3 w-full | xs:w-1/2 | xl:w-1/4"
                back-icon="regular/single-neutral-actions"
                :number="cards.leadsPhoneUp.number.count"
                :title="$tc('service.cards.clientsPhoneUp', cards.leadsPhoneUp.number.count)"
            />
            <card-small
                class="px-3 w-full | xs:w-1/2 | xl:w-1/4"
                back-icon="regular/single-neutral-actions"
                :number="cards.leadsPrebooking.number.count"
                :title="$tc('service.cards.clientsPrebooking', cards.leadsPrebooking.number.count)"
            />
            <card-small
                class="px-3 w-full | xs:w-1/2 | xl:w-1/4"
                back-icon="regular/single-neutral-actions"
                :number="cards.leadsWeb.number.count"
                :title="$tc('service.cards.clientsWeb', cards.leadsWeb.number.count)"
                :back-number="timeHms(cards.reachedAverage.count, true)"
                :back-title="$t('leadXpress.reachedAverage')"
            />
        </div>

        <div class="flex flex-wrap lg:-mx-3">
            <card
                class="px-3 w-full | xs:w-1/3 | xl:w-1/6"
                :icon="$icons.appointment"
                :card="cards.appointment.number"
                :title="$tc('service.cards.appointment', cards.appointment.number.count)"
            >
                <template #description>
                    <div class="flex justify-center text-lg -mt-1">
                        <div class="text-right">
                            <div class="mt-1">
                                {{ $t('result.noShow') }}
                            </div>
                            <div class="mt-1">
                                {{ $tc('result.canceled', cards.appointment.number.canceled) }}
                            </div>
                        </div>
                        <div class="text-left ml-3">
                            <div class="mt-1">
                                <span class="font-bold">{{ cards.appointment.number.noShow }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="font-bold">{{ cards.appointment.number.canceled }}</span>
                            </div>
                        </div>
                        <div class="text-left ml-3">
                            <div class="mt-1">
                                <span class="text-grey-600 text-base">({{ noShowApptPercent }})</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-grey-600 text-base">({{ canceledApptPercent }})</span>
                            </div>
                        </div>
                    </div>
                </template>
            </card>
            <card
                class="px-3 w-full | xs:w-1/3 | xl:w-1/6"
                icon="regular/calendar-check"
                :card="cards.confirmation.number"
                :denominator="cards.appointment.number.count"
                :title="$tc('service.cards.confirmation', cards.appointment.number.confirmed)"
            />
            <card
                class="px-3 w-full | xs:w-1/3 | xl:w-1/6"
                :icon="$icons.visit"
                :card="cards.presented.number"
                :numerator="cards.presented.number.count"
                :denominator="cards.leads.number.count"
                :back-denominator="cards.leads.number.count"
                :title="$tc('service.cards.presentedCard', cards.presented.number.count)"
            >
                <template #description>
                    <div class="flex justify-center text-lg -mt-1">
                        <div class="text-right">
                            <div class="mt-1">
                                {{ $t('service.cards.visitWithAppointment') }}
                            </div>
                            <div class="mt-1">
                                {{ $t('service.cards.visitWithoutAppointment') }}
                            </div>
                        </div>
                        <div class="text-right ml-3">
                            <div class="mt-1">
                                <span class="font-bold">{{ visitWithAppt }}</span> /
                                <span class="font-bold">{{ cards.leads.number.count }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="font-bold">{{ cards.presented.number.countWithoutAppointment }}</span> /
                                <span class="font-bold">{{ cards.leads.number.count }}</span>
                            </div>
                        </div>
                        <div class="text-left ml-3">
                            <div class="mt-1">
                                <span class="text-grey-600 text-base">({{ visitWithApptPercent }})</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-grey-600 text-base">({{ visitWithoutApptPercent }})</span>
                            </div>
                        </div>
                    </div>
                </template>
            </card>
            <card
                class="px-3 w-full | xs:w-1/3 | xl:w-1/6"
                icon="regular/car-repair-fluid"
                :card="cards.maintenance.number"
                :title="$tc('service.cards.maintenance', cards.maintenance.number.count)"
            />
            <card
                class="px-3 w-full | xs:w-1/3 | xl:w-1/6"
                icon="regular/car-actions-shield"
                :card="cards.overSale.number"
                :title="$t('service.cards.overSale')"
            >
                <template #description>
                    <div class="text-xl">
                        {{ $t('service.cards.overSaleCardBack') }}
                    </div>
                </template>
            </card>
            <card
                back-type="time"
                class="px-3 w-full | xs:w-1/3 | xl:w-1/6"
                icon="regular/accounting-invoice"
                type="money"
                :back-card="cards.total.number.minutes"
                :back-title="$t('service.cards.total')"
                :back-value="timeHms(cards.total.number.minutes.count * 60, true)"
                :card="cards.total.number.price"
                :title="$t('service.cards.total')"
            />
        </div>

        <stats-table :dashboard="dashboard" ref="statsTable" />
    </div>
</template>

<script>
    // Utils
    import { round } from 'lodash-es';
    import { mapState } from 'pinia';
    import { toPercentString } from '../../utils/numbers.js';

    // Components
    import Card from '../../components/dashboards/cards/Card.vue';
    import CardSmall from '../../components/dashboards/cards/CardSmall.vue';
    import StatsTable from '../StatsTables/StatsTable.vue';

    // Mixins
    import TrackView from '../../mixins/TrackView.js';
    import DashboardCaching from '../../mixins/DashboardCaching.js';
    import DashboardCustomFields from '../../mixins/DashboardCustomFields.js';

    // Entities
    import DashboardType from '../../entities/DashboardType.js';

    // Pinia
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        name: 'Service',

        components: {
            Card,
            CardSmall,
            StatsTable,
        },

        mixins: [TrackView, DashboardCaching, DashboardCustomFields],

        data() {
            return {
                dashboard: DashboardType.SERVICE,
                cards: {
                    leads: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                        assigned: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                    },
                    leadsPhoneUp: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                    },
                    leadsPrebooking: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                    },
                    leadsWeb: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                    },
                    reached: {
                        count: 0,
                        colors: {
                            0: 'grey',
                        },
                    },
                    reachedAverage: {
                        count: 0,
                    },
                    appointment: {
                        number: {
                            count: 0,
                            confirmed: 0,
                            noShow: 0,
                            canceled: 0,
                            colors: {
                                1: 'green',
                            },
                        },
                    },
                    confirmation: {
                        number: {
                            count: 0,
                            colors: {
                                1: 'green',
                            },
                        },
                    },
                    presented: {
                        number: {
                            count: 0,
                            colors: {
                                1: 'green',
                            },
                            countWithoutAppointment: 0,
                        },
                    },
                    maintenance: {
                        number: {
                            count: 0,
                            colors: {
                                1: 'blue',
                            },
                        },
                    },
                    overSale: {
                        number: {
                            count: 0,
                            colors: {
                                1: 'blue',
                            },
                        },
                    },
                    total: {
                        number: {
                            price: {
                                count: 0,
                                colors: {
                                    1: 'blue',
                                },
                            },
                            minutes: {
                                count: 0,
                                colors: {
                                    1: 'blue',
                                },
                            },
                        },
                    },
                },
            };
        },

        computed: {
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            confirmedApptPercent() {
                return toPercentString(this.cards.appointment.number.confirmed, this.cards.appointment.number.count);
            },

            noShowApptPercent() {
                return toPercentString(this.cards.appointment.number.noShow, this.cards.appointment.number.count);
            },

            canceledApptPercent() {
                return toPercentString(this.cards.appointment.number.canceled, this.cards.appointment.number.count);
            },

            visitWithApptPercent() {
                return toPercentString(this.visitWithAppt, this.cards.leads.number.count);
            },

            visitWithoutApptPercent() {
                return toPercentString(
                    this.cards.presented.number.countWithoutAppointment,
                    this.cards.leads.number.count,
                );
            },

            visitWithAppt() {
                return this.cards.presented.number.count - this.cards.presented.number.countWithoutAppointment;
            },
        },

        methods: {
            async fetchStats(options) {
                await this.$refs.statsTable.fetchStats(options);
            },

            average(total, count) {
                return round((total || 0) / (count || 1), 2);
            },

            setColumns() {
                useDashboardStore().configs.columns = {
                    bulk_select: {
                        visible: true,
                    },
                    fullname: {
                        visible: true,
                    },
                    contact_method: {
                        visible: true,
                    },
                    civility: {
                        visible: false,
                    },
                    business: {
                        visible: false,
                    },
                    created_by: {
                        visible: false,
                    },
                    updated_by: {
                        visible: false,
                    },
                    'customer:casl_consent_status': {
                        visible: false,
                    },
                    'customer:casl_consent_limit_date': {
                        visible: false,
                    },
                    date: {
                        visible: true,
                    },
                    'exchange_vehicles:end_contract_date': {
                        visible: false,
                    },
                    updated_at: {
                        visible: true,
                    },
                    status: {
                        visible: true,
                    },
                    result: {
                        visible: true,
                    },
                    'lead_type:name': {
                        visible: true,
                    },
                    advisors: {
                        visible: true,
                    },
                    agents: {
                        visible: true,
                    },
                    service_agents: {
                        visible: true,
                    },
                    'account:name': {
                        visible: true,
                    },
                    rating: {
                        visible: true,
                    },
                    address: {
                        visible: false,
                    },
                    locale: {
                        visible: false,
                    },
                    created_method: {
                        visible: false,
                    },
                    'import:original_file': {
                        visible: false,
                    },
                    'exchange_vehicles:make': {
                        title: () => {
                            return this.$t('general.make');
                        },
                        visible: true,
                    },
                    'exchange_vehicles:model': {
                        title: () => {
                            return this.$t('general.model');
                        },
                        visible: true,
                    },
                    'exchange_vehicles:year': {
                        title: () => {
                            return this.$t('clientCard.vehicles.year');
                        },
                        visible: true,
                    },
                    'exchange_vehicles:version': {
                        title: () => {
                            return this.$t('clientCard.vehicles.version');
                        },
                        visible: false,
                    },
                    'exchange_vehicles:certified': {
                        visible: false,
                    },
                    'exchange_vehicles:vin': {
                        visible: false,
                    },
                    'exchange_vehicles:transmission': {
                        title: () => {
                            return this.$t('service.categories.transmission');
                        },
                        visible: true,
                    },
                    'exchange_vehicles:color_exterior': {
                        visible: false,
                    },
                    'exchange_vehicles:color_interior': {
                        visible: false,
                    },
                    'exchange_vehicles:condition': {
                        visible: false,
                    },
                    'exchange_vehicles:fuel': {
                        visible: false,
                    },
                    'exchange_vehicles:tire_type': {
                        visible: false,
                    },
                    'exchange_vehicles:equity': {
                        visible: false,
                    },
                    'exchange_vehicles:mileage': {
                        title: () => {
                            return this.$t('clientCard.vehicles.mileage');
                        },
                        visible: true,
                    },
                    'exchange_vehicles:allowed_mileage': {
                        visible: false,
                    },
                    'exchange_vehicles:mo_remaining': {
                        visible: false,
                    },
                    'exchange_vehicles:warranty': {
                        visible: false,
                    },
                    'exchange_vehicles:accidented_damage_cost': {
                        visible: false,
                    },
                    'exchange_vehicles:modality': {
                        title: () => {
                            return this.$t('clientCard.vehicles.modality');
                        },
                        visible: false,
                    },
                    'exchange_vehicles:payment': {
                        visible: false,
                    },
                    'exchange_vehicles:term': {
                        visible: false,
                    },
                    'exchange_vehicles:frequency': {
                        visible: false,
                    },
                    available: {
                        visible: false,
                    },
                    deliverable: {
                        visible: false,
                    },
                    paperwork: {
                        visible: false,
                    },
                    'wanted_vehicles:recorded_date': {
                        visible: false,
                    },
                    prepaid: {
                        visible: true,
                    },
                    loyalty: {
                        visible: true,
                    },
                    source: {
                        visible: true,
                    },
                    last_presented_date: {
                        visible: true,
                    },
                    odometer_last_visit: {
                        visible: true,
                    },
                    service_intention: {
                        visible: true,
                    },
                    service_transport: {
                        visible: true,
                    },
                    service_walk_around: {
                        visible: true,
                    },
                    service_interval_km: {
                        visible: true,
                    },
                    service_monthly_km: {
                        visible: true,
                    },
                    next_presented_date: {
                        visible: true,
                    },
                    storage: {
                        visible: true,
                    },
                    average_spending: {
                        visible: true,
                    },
                    segment: {
                        visible: false,
                    },
                    appointment: {
                        visible: true,
                    },
                    appt_confirmation: {
                        visible: true,
                    },
                    presented: {
                        visible: true,
                    },
                    long_term: {
                        visible: false,
                    },
                    promised_date: {
                        visible: false,
                    },
                    end_service_date: {
                        visible: false,
                    },
                    planned_pick_up_date: {
                        visible: false,
                    },
                    prepared_work_order: {
                        visible: false,
                    },
                    work_order: {
                        visible: false,
                    },
                    invoiced: {
                        visible: false,
                    },
                    repair_order: {
                        visible: false,
                    },
                    repair_date: {
                        visible: false,
                    },
                    open_work_order_date: {
                        visible: false,
                    },
                    work_order_partial_closure_date: {
                        visible: false,
                    },
                    work_order_closure_date: {
                        visible: false,
                    },
                    'exchange_vehicles:purchase_date': {
                        visible: false,
                    },
                    'exchange_vehicles:extended_warranty': {
                        visible: false,
                    },
                    'exchange_vehicles:end_warranty_date': {
                        visible: false,
                    },
                    'exchange_vehicles:license_plate': {
                        visible: false,
                    },
                    'exchange_vehicles:rate': {
                        visible: false,
                    },
                    'exchange_vehicles:recall': {
                        visible: false,
                    },
                    'exchange_vehicles:bank:name': {
                        visible: false,
                    },
                    'exchange_vehicles:sold_by': {
                        visible: false,
                    },
                    maintenance: {
                        visible: true,
                    },
                    oversale: {
                        visible: true,
                    },
                    total_price: {
                        visible: true,
                    },
                    total_minutes: {
                        visible: true,
                    },
                    listening: {
                        visible: true,
                    },
                    followed: {
                        visible: true,
                    },
                    followed_by: {
                        visible: false,
                    },
                    todo: {
                        visible: false,
                    },

                    unsubscribe: {
                        visible: true,
                    },
                    code: {
                        visible: true,
                    },
                    'latest_comment:content': {
                        visible: true,
                    },
                    'service_products:maintenance_a': {
                        visible: true,
                    },
                    'service_products:maintenance_b': {
                        visible: true,
                    },
                    'service_products:maintenance_c': {
                        visible: true,
                    },
                    'service_products:maintenance_d': {
                        visible: true,
                    },
                    'service_products:maintenance_recommended': {
                        visible: true,
                    },
                    'service_products:diagnostic': {
                        visible: true,
                    },
                    'service_products:air_filter': {
                        visible: true,
                    },
                    'service_products:pollen_filter': {
                        visible: true,
                    },
                    'service_products:alignment': {
                        visible: true,
                    },
                    'service_products:brakes': {
                        visible: true,
                    },
                    'service_products:injection': {
                        visible: true,
                    },
                    'service_products:transmission': {
                        visible: true,
                    },
                    'service_products:wash': {
                        visible: true,
                    },
                    'service_products:tires': {
                        visible: true,
                    },
                    'service_products:parts': {
                        visible: true,
                    },
                    'service_products:body': {
                        visible: true,
                    },
                    'service_products:oil_filter': {
                        visible: true,
                    },
                    'service_products:others': {
                        visible: true,
                    },
                    ...this.getCustomFields(),
                };
            },

            setCards(results) {
                this.cards.leads.number.count = results.count;
                this.cards.leads.assigned.count = results.assignments;

                this.cards.appointment.number.count = results.appointments.count;
                this.cards.appointment.number.noShow = results.appointments.noShow;
                this.cards.appointment.number.confirmed = results.appointments.confirmed;
                this.cards.appointment.number.canceled = results.appointments.canceled;

                this.cards.confirmation.number.count = results.appointments.confirmed;

                this.cards.presented.number.count = results.visits.count;
                this.cards.presented.number.countWithoutAppointment = results.visits.withoutAppointment;

                this.cards.maintenance.number.count = results.products.maintenance;
                this.cards.overSale.number.count = results.products.overSale;

                this.cards.total.number.price.count = results.products.price;
                this.cards.total.number.minutes.count = results.products.minutes;

                this.cards.leadsWeb.number.count = results.counts.web;
                this.cards.leadsPhoneUp.number.count = results.counts.phone;
                this.cards.leadsPrebooking.number.count = results.counts.preBooking;

                this.cards.reachedAverage.count = results.timings.responseTime.average;

                this.$nextTick(() => {
                    this.setCaching('cards', this.cards);
                    this.$forceUpdate();
                });
            },
        },
    };
</script>
