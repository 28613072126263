<template functional>
    <activix-tooltip :only-on-truncate="true" :content="props.content">
        <div :class="{ truncate: props.column.truncating }" v-html="props.content" />
    </activix-tooltip>
</template>

<script>
    export default {
        props: {
            column: {
                type: Object,
                required: true,
            },
            content: {
                type: String,
                required: true,
            },
        },
    };
</script>
