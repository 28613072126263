<template>
    <activix-confirm-modal
        type="warning"
        :name="name"
        :content="$t('webBoost.confirmRevive')"
        :opened.sync="opened"
        @update:opened="$emit('update:opened', $event)"
        @approve="reviveLead"
        @before-open="onBeforeOpen"
    />
</template>

<script>
    import { mapState } from 'pinia';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        props: {
            name: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                leadId: null,
                opened: false,
            };
        },
        computed: {
            ...mapState(useContextStore, ['contextLead']),
        },

        methods: {
            async reviveLead() {
                this.$axios.get(`twilio/make-call/${this.leadId}/0`);
            },

            onBeforeOpen(leadId) {
                this.opened = true;
                this.leadId = leadId;
            },
        },
    };
</script>
