<template>
    <div>
        <activix-tooltip :content="$t('followUp.none')" v-if="hideFollowup">
            <div class="p-2">
                -
            </div>
        </activix-tooltip>
        <template v-else>
            <activix-tooltip :content="$t('followUp.lastDate')">
                <div class="mb-1">
                    {{ lastDate }}
                </div>
            </activix-tooltip>

            <div class="flex flex-wrap justify-center -m-1" :class="wrapperClass">
                <div class="p-1">
                    <activix-tooltip :content="tooltipCall">
                        <div
                            class="label | flex items-center text-base cursor-pointer leading-none"
                            :class="colorCall"
                            @mouseenter="fetchLead(lead.id)"
                            @click="openFollowedModal('call')"
                        >
                            {{ outgoingPhoneCommunications.length }}
                            <icon class="ml-1 text-sm" :name="$icons.phone" />
                        </div>
                    </activix-tooltip>
                </div>

                <div class="p-1">
                    <activix-tooltip :content="tooltipEmail">
                        <div
                            class="label | flex items-center text-base cursor-pointer leading-none"
                            :class="colorEmail"
                            @mouseenter="fetchLead(lead.id)"
                            @click="openFollowedModal('email')"
                        >
                            {{ outgoingEmailCommunications.length }}
                            <icon class="ml-1 text-sm" :name="$icons.email" />
                        </div>
                    </activix-tooltip>
                </div>

                <div class="p-1">
                    <activix-tooltip :content="tooltipSms">
                        <div
                            class="label | flex items-center text-base cursor-pointer leading-none"
                            :class="colorSms"
                            @mouseenter="fetchLead(lead.id)"
                            @click="openFollowedModal('sms')"
                        >
                            {{ outgoingSmsCommunications.length }}
                            <icon class="ml-1 text-sm" :name="$icons.sms" />
                        </div>
                    </activix-tooltip>
                </div>

                <div class="p-1" v-if="accountHasMessenger">
                    <activix-tooltip :content="tooltipMessenger">
                        <div
                            class="label | flex items-center text-base cursor-pointer leading-none"
                            :class="colorMessenger"
                            @mouseenter="fetchLead(lead.id)"
                            @click="openFollowedModal('messenger')"
                        >
                            {{ outgoingMessengerCommunications.length }}
                            <icon class="ml-1 text-sm" :name="$icons.messenger" />
                        </div>
                    </activix-tooltip>
                </div>

                <div class="p-1">
                    <activix-tooltip :content="tooltipVideo">
                        <div
                            class="label | flex items-center text-base cursor-pointer leading-none"
                            :class="colorVideo"
                            @mouseenter="fetchLead(lead.id)"
                            @click="openFollowedModal('video')"
                        >
                            {{ outgoingVideoCommunications.length }}
                            <icon class="ml-1 text-sm" :name="$icons.camera" />
                        </div>
                    </activix-tooltip>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    // Utils
    import { cloneDeep } from 'lodash-es';

    import { mapActions, mapState } from 'pinia';

    // Entities
    import Lead from '../../../entities/Lead.js';
    import CommunicationType from '../../../entities/CommunicationType.js';
    import CommunicationMethod from '../../../entities/CommunicationMethod.js';
    import ActivixDate from '../../../value-objects/ActivixDate.js';

    // Pinia
    import { useContextStore } from '../../../store/modules/context/store.js';
    import { useGlobalStore } from '../../../store/store.js';

    export default {
        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        computed: {
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            accountHasMessenger() {
                return (
                    this.contextAccount.hasMessenger() ||
                    this.contextAccount.children.some(child => child.hasMessenger())
                );
            },

            colorCall() {
                return this.lastFollowUp === CommunicationMethod.PHONE ? 'label-primary' : 'label-default';
            },

            colorEmail() {
                return this.lastFollowUp === CommunicationMethod.EMAIL ? 'label-primary' : 'label-default';
            },

            colorSms() {
                return this.lastFollowUp === CommunicationMethod.SMS ? 'label-primary' : 'label-default';
            },

            colorMessenger() {
                return this.lastFollowUp === CommunicationMethod.MESSENGER ? 'label-primary' : 'label-default';
            },

            colorVideo() {
                return this.lastFollowUp === CommunicationMethod.VIDEO ? 'label-primary' : 'label-default';
            },

            hideFollowup() {
                return (
                    !this.outgoingEmailCommunications.length &&
                    !this.outgoingPhoneCommunications.length &&
                    !this.outgoingSmsCommunications.length &&
                    !this.outgoingMessengerCommunications.length &&
                    !this.outgoingVideoCommunications.length
                );
            },

            tooltipCall() {
                if (!this.outgoingPhoneCommunications.length) {
                    return this.$t('followUp.noCall');
                }

                const callCount = `${this.$t('followUp.phoneCount')} : ${this.outgoingPhoneCommunications.length}`;

                if (this.lastFollowUp !== CommunicationMethod.PHONE) {
                    return callCount;
                }

                return `
                    <div class="font-bold">${this.$t('followUp.last')}</div>
                    <div>${callCount}</div>
                `;
            },

            tooltipEmail() {
                if (!this.outgoingEmailCommunications.length) {
                    return this.$t('followUp.noEmail');
                }

                const emailCount = `${this.$t('followUp.emailCount')} : ${this.outgoingEmailCommunications.length}`;

                if (this.lastFollowUp !== CommunicationMethod.EMAIL) {
                    return emailCount;
                }

                return `
                    <div class="font-bold">${this.$t('followUp.last')}</div>
                    <div>${emailCount}</div>
                `;
            },

            tooltipSms() {
                if (!this.outgoingSmsCommunications.length) {
                    return this.$t('followUp.noSms');
                }

                const smsCount = `${this.$t('followUp.smsCount')} : ${this.outgoingSmsCommunications.length}`;

                if (this.lastFollowUp !== CommunicationMethod.SMS) {
                    return smsCount;
                }

                return `
                    <div class="font-bold">${this.$t('followUp.last')}</div>
                    <div>${smsCount}</div>
                `;
            },

            tooltipMessenger() {
                if (!this.outgoingMessengerCommunications.length) {
                    return this.$t('followUp.noMessenger');
                }

                const messengerCount = `${this.$t('followUp.messengerCount')} : ${this.outgoingMessengerCommunications.length}`;

                if (this.lastFollowUp !== CommunicationMethod.MESSENGER) {
                    return messengerCount;
                }

                return `
                    <div class="font-bold">${this.$t('followUp.last')}</div>
                    <div>${messengerCount}</div>
                `;
            },

            tooltipVideo() {
                if (!this.outgoingVideoCommunications.length) {
                    return this.$t('followUp.noVideo');
                }

                const videoCount = `${this.$t('followUp.videoCount')} : ${this.outgoingVideoCommunications.length}`;

                if (this.lastFollowUp !== CommunicationMethod.VIDEO) {
                    return videoCount;
                }

                return `
                    <div class="font-bold">${this.$t('followUp.last')}</div>
                    <div>${videoCount}</div>
                `;
            },

            outgoingPhoneCommunications() {
                return this.lead.communications.filter(communication => {
                    return (
                        communication.connected &&
                        communication.communication_type_id == CommunicationType.OUTGOING &&
                        communication.communication_method_id == CommunicationMethod.PHONE
                    );
                });
            },

            outgoingEmailCommunications() {
                return this.lead.communications.filter(communication => {
                    return (
                        communication.connected &&
                        communication.communication_type_id == CommunicationType.OUTGOING &&
                        communication.communication_method_id == CommunicationMethod.EMAIL
                    );
                });
            },

            outgoingSmsCommunications() {
                return this.lead.communications.filter(communication => {
                    const isOutgoingSms =
                        communication.connected &&
                        communication.communication_method_id == CommunicationMethod.SMS &&
                        (communication.communication_type_id == CommunicationType.OUTGOING || communication.outbound_counter);

                    const isIncomingSmsWithMultipleExchange =
                        communication.exchange_counter > 1 &&
                        communication.communication_method_id == CommunicationMethod.SMS &&
                        communication.communication_type_id == CommunicationType.INCOMING;

                    return isOutgoingSms || isIncomingSmsWithMultipleExchange;
                });
            },

            outgoingMessengerCommunications() {
                return this.lead.communications.filter(communication => {
                    const isOutgoingMessenger =
                        communication.connected &&
                        communication.communication_method_id == CommunicationMethod.MESSENGER &&
                        (communication.communication_type_id == CommunicationType.OUTGOING || communication.outbound_counter);

                    const isIncomingMessengerWithMultipleExchange =
                        communication.exchange_counter > 1 &&
                        communication.communication_method_id == CommunicationMethod.MESSENGER &&
                        communication.communication_type_id == CommunicationType.INCOMING;

                    return isOutgoingMessenger || isIncomingMessengerWithMultipleExchange;
                });
            },

            outgoingVideoCommunications() {
                return this.lead.communications.filter(communication => {
                    return (
                        communication.connected &&
                        communication.communication_type_id === CommunicationType.OUTGOING &&
                        communication.communication_method_id === CommunicationMethod.VIDEO
                    );
                });
            },

            outgoingCommunications() {
                const allCommunications = [].concat(
                    this.outgoingEmailCommunications,
                    this.outgoingSmsCommunications,
                    this.outgoingMessengerCommunications,
                    this.outgoingPhoneCommunications,
                    this.outgoingVideoCommunications,
                );

                return cloneDeep(allCommunications).sort((a, b) => {
                    const communicationA = new ActivixDate(a.created_at);
                    const communicationB = new ActivixDate(b.created_at);

                    return communicationB.diffInSeconds(communicationA);
                });
            },

            lastFollowUp() {
                if (!this.outgoingCommunications.length) {
                    return '';
                }

                return this.outgoingCommunications[0].communication_method_id;
            },

            lastDate() {
                if (!this.outgoingCommunications.length) {
                    return null;
                }

                return new ActivixDate(this.outgoingCommunications[0].created_at).toHumanShort();
            },

            wrapperClass() {
                if (this.accountHasMessenger) {
                    return 'px-3';
                }

                return '';
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['fetchLead']),

            openFollowedModal(type) {
                this.$modal.show('dashboard:followed', { leadId: this.lead.id, type });
            },
        },
    };
</script>
