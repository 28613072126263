<template>
    <div class="box | mb-6" v-if="isVisible">
        <div class="box-header | flex items-center justify-between">
            <h4 class="box-title">
                {{ $t('graph.title') }}
            </h4>
            <div class="flex items-center">
                <button class="ml-3 link-grey" @click="isOpened = !isOpened">
                    <icon :name="$icons.expand" v-if="!isOpened" />
                    <icon class="spin-inverse" :name="$icons.loading" v-else-if="$wait.is('fetching.graphs')" />
                    <icon :name="$icons.collapse" v-else />
                </button>
            </div>
        </div>
        <div class="box-body | border-t" :class="{ loading: $wait.is('fetching.graphs') && isLoaded }" v-if="isOpened">
            <div class="text-center" v-if="!hasLeads">
                {{ $t('dashboards.noResults') }}
            </div>
            <activix-spinner
                :size="50"
                :message="$t('graph.loading')"
                v-else-if="$wait.is('fetching.graphs') && !isLoaded"
            />
            <template v-else>
                <component
                    :is="component"
                    class="flex flex-wrap"
                    :data="responseData"
                    @graph-loaded="isLoaded = $event"
                />
                <slot />
            </template>
        </div>
    </div>
</template>

<script>
    import Highcharts from 'highcharts/highstock.js';
    import resolveConfig from 'tailwindcss/resolveConfig';
    import { mapState } from 'pinia';
    import customTailwindConfig from '../../../../tailwind.config.js';

    // Entities
    import DashboardType from '../../../entities/DashboardType.js';

    // Components
    import LeadXpressGraphs from './LeadXpressGraphs.vue';
    import PhoneUpGraphs from './PhoneUpGraphs.vue';
    import WalkInGraphs from './WalkInGraphs.vue';
    import WebOrderGraphs from './WebOrderGraphs.vue';

    // Mixins
    import DashboardColumns from '../../../mixins/DashboardColumns.js';
    import { useDashboardStore } from '../../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../../store/modules/context/store.js';
    import { useGlobalStore } from '../../../store/store.js';

    const tailwindConfig = resolveConfig(customTailwindConfig);

    export default {
        components: {
            LeadXpressGraphs,
            PhoneUpGraphs,
            WalkInGraphs,
            WebOrderGraphs,
        },

        mixins: [DashboardColumns],

        props: {
            dashboard: {
                type: String,
                required: true,
            },
            hasLeads: {
                type: Boolean,
                required: true,
            },
        },

        data() {
            return {
                charts: [],
                responseData: {},
                isOpened: false,
                isLoaded: false,
            };
        },

        computed: {
            ...mapState(useContextStore, {
                contextAccount: 'account',
                contextUser: 'user',
                contextTeam: 'contextTeam',
            }),
            ...mapState(useGlobalStore, ['groupDisplay']),
            ...mapState(useDashboardStore, {
                endDate: 'endDate',
                filteredDates: 'filteredDates',
                options: store => store.configs.options,
                startDate: 'startDate',
            }),

            isVisible() {
                return !this.contextUser.id && !this.groupDisplay;
            },

            component() {
                switch (this.dashboard) {
                    case DashboardType.WEB_ORDER:
                        return WebOrderGraphs;
                    case DashboardType.LEAD_XPRESS:
                        return LeadXpressGraphs;
                    case DashboardType.WALK_IN:
                        return WalkInGraphs;
                    case DashboardType.PHONE_UP:
                        return PhoneUpGraphs;
                }

                return null;
            },
        },

        watch: {
            isOpened() {
                this.fetch();
            },
        },

        methods: {
            async fetch({ background = false } = {}) {
                if (!this.isVisible || !this.isOpened || !this.startDate || !this.endDate) {
                    return;
                }

                if (!background) {
                    this.$wait.start('fetching.graphs');
                }

                try {
                    const response = await this.$api.dashboard.getGraphs({
                        dashboard: this.dashboard,
                        endDate: this.parsedEndDate.toDateTimeString(),
                        startDate: this.parsedStartDate.toDateTimeString(),
                        options: this.options,
                        accountId: this.contextAccount.id,
                        filters: this.activeDashboardFilters,
                        filteredDates: this.filteredDates,
                        teamId: this.contextTeam.id,
                    });

                    this.responseData = response.data;
                } catch (error) {
                    this.$notify.warning(this.$t('dashboards.alerts.graphs.error'));
                } finally {
                    this.$wait.end('fetching.graphs');
                }
            },
        },

        created() {
            Highcharts.setOptions({
                colors: ['rgba(60,141,188,1)', 'rgba(158,158,158,1)'],
                chart: {
                    backgroundColor: null,
                    style: {
                        fontFamily: tailwindConfig.theme.fontFamily.sans,
                    },
                },
                title: {
                    style: {
                        fontSize: '16px',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                    },
                },
                tooltip: {
                    borderWidth: 0,
                    backgroundColor: 'rgba(236,236,236,0.8)',
                    shadow: false,
                    headerFormat: '<span style="font-size: 12px"><b>{point.key}</b></span><br>',
                    shared: true,
                    useHTML: true,
                },
                legend: {
                    itemStyle: {
                        fontSize: '13px',
                    },
                    align: 'right',
                    verticalAlign: 'top',
                    layout: 'vertical',
                    floating: true,
                    useHTML: true,
                },
                xAxis: {
                    labels: {
                        style: {
                            fontSize: '12px',
                        },
                    },
                    title: {
                        style: {
                            textTransform: 'uppercase',
                        },
                    },
                    minTickInterval: 1,
                    gridLineWidth: 0,
                    minorGridLineWidth: 0,
                },
                yAxis: {
                    title: {
                        style: {
                            textTransform: 'uppercase',
                        },
                    },
                    labels: {
                        style: {
                            fontSize: '12px',
                        },
                    },
                    minTickInterval: 1,
                    gridLineWidth: 0,
                    minorGridLineWidth: 0,
                },
                scrollbar: {
                    barBackgroundColor: 'rgba(158,158,158,1)',
                    barBorderRadius: 7,
                    barBorderWidth: 0,
                    buttonArrowColor: '#FFF',
                    buttonBackgroundColor: '#FFF',
                    buttonBorderWidth: 0,
                    buttonBorderRadius: 0,
                    trackBackgroundColor: '#EEE',
                    trackBorderWidth: 0,
                    trackBorderRadius: 0,
                    trackBorderColor: '#CCC',
                },
                plotOptions: {
                    series: {
                        animation: false,
                    },
                    candlestick: {
                        lineColor: '#404048',
                    },
                    pie: {
                        colors: () => {
                            const colors = [];
                            const base = 'rgba(60,141,188,1)';

                            for (let i = 5; i > 0; i--) {
                                colors.push(
                                    Highcharts.Color(base)
                                        .brighten(i / 20)
                                        .get(),
                                );
                            }

                            colors.push(base);

                            for (let i = 0; i < 5; i++) {
                                colors.push(
                                    Highcharts.Color(base)
                                        .brighten(-(i / 20))
                                        .get(),
                                );
                            }

                            return colors;
                        },
                    },
                },
                credits: {
                    enabled: false,
                },
            });
        },

        deactivated() {
            this.isOpened = false;
            this.isLoaded = false;
        },
    };
</script>
