<template>
    <div class="overflow-hidden cursor-normal">
        <span v-if="!communications.length">-</span>
        <activix-tooltip
            :content="communication.created_at_human"
            :key="communication.id"
            v-for="communication in communications"
            v-else
        >
            <div class="truncate">
                {{ communication.user_full_name }}
            </div>
        </activix-tooltip>
    </div>
</template>

<script>
    import { orderBy, sortedUniqBy } from 'lodash-es';

    import { getFullName } from '@/utils/index.js';

    import ActivixDate from '@/value-objects/ActivixDate.js';
    import Lead from '@/entities/Lead.js';

    export default {
        props: {
            lead: {
                type: Lead,
                required: true,
            },
        },

        data() {
            return {
                names: [],
            };
        },

        computed: {
            communications() {
                return sortedUniqBy(orderBy(this.lead.communications.filter(communication => communication.user_id), 'created_at', 'desc'), 'user_id')
                    .map(communication => {
                        return {
                            ...communication,
                            user_full_name: getFullName(this.lead.account.getUserById(communication.user_id)),
                            created_at_human: new ActivixDate(communication.created_at).toHumanShort(),
                        };
                    }).slice(0, 3);
            },
        },
    };
</script>
