<template>
    <div>
        <span class="relative" v-if="communication">
            <videoconference-icon :communication="communication" :disabled="!userHasAccessToMedia" v-if="isVideo" />

            <activix-tooltip :content="communication.description || communication.excerpt || ''" v-else-if="communication.created_by_user">
                <div class="manual-audio small" :class="audioClass">
                    M
                </div>
            </activix-tooltip>

            <template v-else>
                <template v-if="lead.account.disable_communication_audio">
                    <activix-tooltip :content="$t('audio.noAudio')">
                        <activix-player :custom-class="audioClass" :disabled="true" />
                    </activix-tooltip>
                </template>
                <template v-else>
                    <activix-player
                        :custom-class="audioClass"
                        :duration="duration"
                        :url="url"
                        :downloadable="true"
                        :file-name="filename(communication, lead) + getExtension(communication)"
                        v-if="userHasAccessToMedia && url"
                    />
                    <activix-tooltip :content="$t('dashboards.noAudioAccess')" v-else>
                        <activix-player :custom-class="audioClass" :disabled="true" />
                    </activix-tooltip>
                    <template v-if="authUser.analyst_access"> ID: {{ communication.id }} </template>
                </template>
            </template>
        </span>

        <span class="relative" v-else>-</span>
    </div>
</template>

<script>
    /* eslint-disable vue/require-prop-types */
    import { orderBy } from 'lodash-es';

    import { mapState } from 'pinia';
    import ActivixPlayer from '../../elements/ActivixPlayer.vue';
    import VideoconferenceIcon from '../../VideoconferenceIcon.vue';
    import CommunicationMethod from '../../../entities/CommunicationMethod.js';
    import Service from '../../../entities/Service.js';

    import CommunicationMixin from '../../../mixins/Communication.js';
    import { useDashboardStore } from '../../../store/modules/dashboard/store.js';
    import { useGlobalStore } from '../../../store/store.js';

    export default {
        components: { ActivixPlayer, VideoconferenceIcon },

        mixins: [CommunicationMixin],

        props: ['lead'],

        computed: {
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useDashboardStore, ['dashboardType']),

            audioClass() {
                let service = '';

                if (this.communication) {
                    service = Service.getKey(this.communication.service_id);
                }

                return this.lead.ivr ? 'ivr' : service;
            },

            communication() {
                const sorting = this.dashboardType == 'webBoost' ? 'asc' : 'desc';
                const communications = orderBy(this.lead.communications, ['id'], [sorting]);

                return communications.find(communication => {
                    const haveUrlAndNotMessenger =
                        communication.url &&
                        !communication.created_by_user &&
                        communication.communication_method_id != CommunicationMethod.MESSENGER;
                    const isPhone =
                        communication.created_by_user &&
                        communication.communication_method_id == CommunicationMethod.PHONE;

                    return haveUrlAndNotMessenger || isPhone;
                });
            },

            duration() {
                if (this.communication.duration_call) {
                    return this.timeHms(this.communication.duration_call);
                }

                return '';
            },

            isVideo() {
                return this.communication.communication_method_id === CommunicationMethod.VIDEO;
            },
        },
    };
</script>
