<template>
    <activix-modal
        size="sm"
        :opened="opened"
        @close="close"
        @closed="onClosed"
        @open="onOpen"
    >
        <template slot="header">
            <h4 class="modal-title">
                {{ $t('option.generateReport') }}
            </h4>
        </template>

        <template slot="body">
            <div class="text-center">
                <div class="alert alert-info | text-center" v-if="exportLeadsOnly">
                    {{ $t('modal.reportLeadsOnly') }}
                </div>

                <div class="alert alert-danger | text-center" v-if="error && !processing">
                    {{ $t('toastr.errorMessage') }}
                </div>

                <template v-if="!processing">
                    <div class="mb-6" v-if="startDate">
                        <div class="text-lg">
                            {{ $t('modal.chooseDateRage') }}
                        </div>
                        <report-date-picker
                            class="mt-2 shadow-none"
                            :start-date.sync="startDate"
                            :end-date.sync="endDate"
                        />
                    </div>

                    <div>
                        <div class="text-lg">
                            {{ $t('modal.chooseExportType') }}
                        </div>
                        <div class="mt-2 flex justify-center">
                            <activix-select :autosize="true" :options="[]" v-model="exportType">
                                <el-option
                                    :label="item.toUpperCase()"
                                    :value="item"
                                    :key="item"
                                    v-for="item in exportTypes"
                                />
                            </activix-select>
                        </div>
                    </div>

                    <div class="flex items-center justify-center mt-4 gap-1.5" v-if="showSubGroup">
                        <activix-checkbox
                            size="sm"
                            :value="subGroupsIncluded"
                            @input="updateSubGroupsIncluded($event)"
                        >
                            {{ $t('modal.chooseIncludeSubGroup') }}
                        </activix-checkbox>
                        <activix-tooltip :content="$t('modal.chooseIncludeSubGroupToolTip')">
                            <icon class="mr-2 text-grey-650" name="regular/information-circle" />
                        </activix-tooltip>
                    </div>

                </template>
                <template v-else-if="completed">
                    {{ $t('modal.reportStarted', [authUser.email]) }}
                </template>
                <template v-else>
                    <activix-spinner />
                </template>
            </div>
        </template>

        <template slot="footer">
            <div class="flex justify-center">
                <activix-button @click="close" v-if="processing">
                    {{ $t('general.close') }}
                </activix-button>

                <activix-button @click="close" v-if="!processing">
                    {{ $t('general.cancel') }}
                </activix-button>

                <activix-button
                    type="primary"
                    :loading="loading"
                    @click="generateReport"
                    v-if="!processing"
                >
                    {{ $t('modal.generate') }}
                </activix-button>
            </div>
        </template>
    </activix-modal>
</template>

<script>

    import { mapState } from 'pinia';

    // Components
    import ReportDatePicker from '../dashboards/ReportDatePicker.vue';

    // Mixins
    import DashboardColumns from '../../mixins/DashboardColumns.js';
    import ExportType from '../../entities/ExportType.js';

    // Pinia
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            ReportDatePicker,
        },

        mixins: [DashboardColumns],

        props: {
            opened: {
                type: Boolean,
                required: true,
            },
        },

        data() {
            return {
                subGroupsIncluded: false,
                processing: false,
                completed: false,
                loading: false,
                error: false,
                exportTypes: [
                    ExportType.XLS,
                    ExportType.CSV,
                ],
                exportType: ExportType.XLS,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'groupDisplay']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
                contextGroup: 'group',
                contextUser: 'user',
                contextTeam: 'contextTeam',
            }),
            ...mapState(useDashboardStore, {
                division: 'division',
                dashboardView: 'dashboardView',
                mainStartDate: 'startDate',
                mainEndDate: 'endDate',
                options: store => store.configs.options,
                order: store => store.configs.order,
                expanded: store => store.configs.expanded,
                statsTableFilter: store => store.configs.statsTableFilter,
                dashboardType: 'dashboardType',
                filteredCampaigns: 'filteredCampaigns',
                filteredDates: 'filteredDates',
            }),

            startDate: {
                get() {
                    return this.mainStartDate;
                },
                set(startDate) {
                    return startDate;
                },
            },
            endDate: {
                get() {
                    return this.mainEndDate;
                },
                set(endDate) {
                    return endDate;
                },
            },

            exportLeadsOnly() {
                return ['event'].includes(this.dashboardType);
            },

            exportableColumns() {
                const columns = {};

                this.activeDashboardColumns
                    .filter(column => {
                        return column.name !== 'bulk_select';
                    })
                    .forEach(column => {
                        if (column.name === 'phone_appointment') {
                            columns.appt_call = this.$t('general.phoneAppointment');
                        } else {
                            columns[column.name] = column.exportTitle || column.title;
                        }
                    });

                return columns;
            },

            accountManagerSelected() {
                return this.groupDisplay && !this.contextGroup.id;
            },

            showSubGroup() {
                return this.exportType === 'pdf' && this.expanded.statsTable && this.dashboardType != 'service';
            },
        },

        watch: {
            mainStartDate: {
                immediate: true,
                handler() {
                    this.startDate = this.mainStartDate ? this.mainStartDate : null;
                },
            },

            mainEndDate: {
                immediate: true,
                handler() {
                    this.endDate = this.mainEndDate ? this.mainEndDate : null;
                },
            },
        },

        methods: {
            updateSubGroupsIncluded($event) {
                this.subGroupsIncluded = $event;
            },

            generateReport() {
                this.error = false;
                this.loading = true;
                this.processing = true;

                const payload = {
                    startDate: this.startDate,
                    endDate: this.endDate,
                    userId: this.contextUser.id,
                    accountId: this.contextAccount.id,
                    groupId: this.contextGroup.id,
                    options: this.options,
                    division: this.division,
                    dashboard: this.dashboardType,
                    customFilters: this.activeDashboardFilters,
                    groupDisplay: this.groupDisplay,
                    customOrderBy: this.order.orderBy ? this.order.orderBy : 'fullname',
                    customSortBy: this.order.sortBy ? this.order.sortBy : 'asc',
                    filteredDates: this.filteredDates,
                    carryOver: this.options.carryOver,
                    events: this.filteredCampaigns,
                    exportType: this.exportType,
                    exportableColumns: this.exportableColumns,
                    viewId: this.dashboardView.id,
                    statsTable: this.expanded.statsTable,
                    accountManagerSelected: this.accountManagerSelected,
                    teamId: this.contextTeam.id,
                    statsTableFilter: this.statsTableFilter,
                    subGroupsIncluded: this.subGroupsIncluded,
                };

                this.$axios
                    .post('v1/export-lead/generate', payload)
                    .then(() => {
                        this.error = false;
                        this.loading = false;
                        this.completed = true;
                    })
                    .catch(() => {
                        this.error = true;
                        this.loading = false;
                        this.processing = false;
                    });
            },

            close() {
                this.$emit('update:opened', false);
                this.subGroupsIncluded = false;
            },

            onOpen() {
                if (!['event', 'commercial'].includes(this.dashboardType)) {
                    this.exportTypes.push(ExportType.PDF);
                }
            },

            onClosed() {
                this.exportTypes = [
                    ExportType.XLS,
                    ExportType.CSV,
                ];
                this.exportType = ExportType.XLS;
                this.processing = false;
                this.loading = false;
                this.completed = false;
                this.error = false;
            },
        },
    };
</script>
