<template>
    <div>
        <div class="flex items-center h-6">
            <div
                class="flex items-center cursor-pointer p-2 group -ml-2"
                @click="$emit('toggle-row', row.rowNumber)"
                v-if="showSubLevel"
            >
                <icon
                    class="text-xs text-grey-600 group-hover:text-grey-700"
                    :class="{ 'rotate-1/4': row.expanded }"
                    name="regular/arrow-right-1"
                />
            </div>
            <div class="pl-1 text-left whitespace-nowrap truncate" :class="{ 'font-semibold': row.expanded }">
                <a @click="redirect(row.id)" v-if="row.redirect && !groupDisplay">
                    {{ row.name }}
                </a>
                <span v-else>
                    {{ row.name }}
                </span>
            </div>
        </div>
        <template v-if="row.expanded">
            <sub-level-cell
                :row="row"
                column-name="name"
                default-class="flex items-end h-10 justify-start ml-6 truncate"
                :sub-level-key="subLevelKey"
            />
        </template>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import SubLevelCell from './SubLevelCell.vue';

    // Pinia
    import { useDashboardStore } from '../../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../../store/modules/context/store.js';
    import { useGlobalStore } from '../../../store/store.js';

    export default {
        components: {
            SubLevelCell,
        },

        props: {
            row: {
                type: Object,
                default: () => {},
            },
            subLevelKey: {
                type: String,
                default: '',
            },
            showSubLevel: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['groupDisplay']),

            subItems() {
                const subAggregations = this.row.sub_aggregations || {};

                if (!subAggregations || !this.subLevelKey) {
                    return {};
                }

                return subAggregations[this.subLevelKey] || {};
            },
        },

        methods: {
            ...mapActions(useContextStore, ['setContextUserAction']),

            redirect(id) {
                useDashboardStore().disableStatFetch = true;
                this.setContextUserAction(id);
            },
        },
    };
</script>
