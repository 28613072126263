<template>
    <activix-tooltip :content="getTooltip" :prerender="false">
        <div class="label | min-w-22" :class="[labelClass, { lost: status == 'lost' }]" v-if="stickerType == 'label'">
            {{ $t(`saleTable.stickers.${status}`) }}
        </div>
        <router-link
            class="flex items-center justify-center cursor-pointer shadow bg-white rounded-sm"
            :to="{ name: 'leads.update', params: { id: lead.id } }"
            :class="className"
            v-else
        >
            <template v-if="view == GridReportView.CAR">
                <template v-if="contextAccount.power_sport">
                    <icon
                        name="bold/scooter-sport"
                        scale="1.4"
                        class="flip-x"
                        v-if="iconIsBold"
                    />
                    <icon name="light/scooter-sport" scale="1.4" v-else />
                </template>
                <template v-else>
                    <icon
                        name="bold/car-retro-1"
                        scale="1.5"
                        class="flip-x"
                        v-if="iconIsBold"
                    />
                    <icon name="light/car-retro-1" scale="1.5" v-else />
                </template>
            </template>
            <template v-else-if="view == GridReportView.STOCK">
                <span class="uppercase font-mono truncate">{{ stock }}</span>
            </template>
            <template v-else>
                <span class="font-bold" v-if="lead.isFinance()">
                    V12
                </span>
                <span v-else-if="lead.isNew() || lead.isUsed()">
                    {{ $t(`saleTable.${leadDivision}Short`) }}
                </span>
                <span class="font-bold text-xl" v-else>
                    ?
                </span>
            </template>
        </router-link>
    </activix-tooltip>
</template>

<script>
    import { mapState } from 'pinia';
    import ActivixDate from '../../value-objects/ActivixDate.js';
    import GridReportView from '../../entities/GridReportView.js';
    import Division from '../../entities/Division.js';

    // Pinia
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        props: {
            lead: {
                type: Object,
                required: true,
            },
            stickerType: {
                type: String,
                default: 'sticker',
            },
            view: {
                type: String,
                default: GridReportView.DIVISION,
            },
        },

        data() {
            return {
                GridReportView,
            };
        },

        computed: {
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),
            ...mapState(useDashboardStore, {
                endDate: store => new ActivixDate(store.endDate).endOfDay(),
                options: store => store.configs.options,
                startDate: store => new ActivixDate(store.startDate).startOfDay(),
            }),

            stock() {
                return this.lead.getSoldOrFirstWantedVehicle()?.stock || '-';
            },

            leadDivision() {
                return Division.getKey(this.lead.division_id);
            },

            className() {
                switch (this.view) {
                    case GridReportView.CAR:
                        return [this.textColor, 'h-7 w-10 text-xl', { 'bg-grey-300': this.lead.isUsed() }];

                    case GridReportView.STOCK:
                        return [
                            this.textColor,
                            'h-9 w-21 border-b-3 text-sm px-2',
                            this.borderColor,
                            this.iconIsBold ? this.backgroundColor : 'font-semibold border',
                        ];

                    case GridReportView.DIVISION:
                    default:
                        return [
                            this.textColor,
                            'h-9 w-12 text-xl border-b-3',
                            this.borderColor,
                            this.iconIsBold ? this.backgroundColor : 'font-semibold border',
                        ];
                }
            },

            iconIsBold() {
                switch (this.status) {
                    case 'approved':
                    case 'deliveryCarryOver':
                    case 'carryOverNotDelivered':
                    case 'refused':
                    case 'postponed':
                    case 'sold':
                        return false;

                    case 'waitingSale':
                        if (this.lead.waiting_sale) {
                            return false;
                        }

                        return true;

                    default:
                        return true;
                }
            },

            borderColor() {
                switch (this.status) {
                    case 'sold':
                    case 'discounted':
                    case 'waitingSale':
                        return 'border-grey-600';

                    case 'planned':
                    case 'postponed':
                        return 'border-blue-500';

                    case 'delivered':
                    case 'approved':
                        return 'border-green-500';

                    case 'carryOver':
                    case 'deliveryCarryOver':
                    case 'carryOverNotDelivered':
                        return 'border-orange-500';

                    case 'lost':
                    case 'refused':
                        return 'border-red-500';
                }

                return '';
            },

            textColor() {
                switch (this.status) {
                    case 'sold':
                    case 'discounted':
                    case 'waitingSale':
                        return 'link-gray';

                    case 'planned':
                    case 'postponed':
                        return 'link-blue';

                    case 'delivered':
                    case 'approved':
                        return 'link-green';

                    case 'carryOver':
                    case 'deliveryCarryOver':
                    case 'carryOverNotDelivered':
                        return 'link-orange';

                    case 'lost':
                    case 'refused':
                        return 'link-red';
                }

                return '';
            },

            backgroundColor() {
                switch (this.status) {
                    case 'sold':
                    case 'discounted':
                    case 'waitingSale':
                        return 'bg-grey-300';

                    case 'planned':
                    case 'postponed':
                        return 'bg-blue-100';

                    case 'delivered':
                    case 'approved':
                        return 'bg-green-100';

                    case 'carryOver':
                    case 'deliveryCarryOver':
                    case 'carryOverNotDelivered':
                        return 'bg-orange-100';

                    case 'lost':
                    case 'refused':
                        return 'bg-red-100';
                }

                return '';
            },

            labelClass() {
                switch (this.status) {
                    case 'sold':
                        return 'bg-white text-grey-600 border-2 border-grey-600';

                    case 'discounted':
                        return 'bg-grey-600 text-white';

                    case 'planned':
                        return 'bg-blue-500 text-white';

                    case 'postponed':
                        return 'bg-white text-blue-500 border-2 border-blue-500';

                    case 'delivered':
                        return 'bg-green-500 text-white';

                    case 'approved':
                        return 'bg-white text-green-500 border-2 border-green-500';

                    case 'carryOver':
                        return 'bg-orange-500 text-white';

                    case 'deliveryCarryOver':
                    case 'carryOverNotDelivered':
                        return 'bg-white text-orange-500 border-2 border-orange-500';

                    case 'lost':
                        return 'bg-red-500 text-white';

                    case 'refused':
                        return 'bg-white text-red-500 border-2 border-red-500';

                    case 'waitingSale':
                        if (this.lead.waiting_sale) {
                            return 'bg-white text-grey-600 border-2 border-grey-600';
                        }

                        return 'bg-grey-300 text-grey-600';
                }

                return '';
            },

            nextStep() {
                const stepFlowReversed = [
                    {
                        step: 'csi',
                        value: this.lead.csi_date || this.lead.division_id == Division.USED ? 'completed' : '',
                        required: this.lead.division_id != Division.USED,
                    },
                    {
                        step: 'recorded',
                        value: this.vehicle.recorded_date,
                        required: false,
                    },
                    {
                        step: 'delivered',
                        value: this.lead.delivered_date,
                        required: true,
                    },
                    {
                        step: 'gas',
                        value: this.lead.gas ? 'completed' : '',
                        required: this.lead.division_id != Division.USED,
                    },
                    {
                        step: 'inspected',
                        value: this.lead.inspected ? 'completed' : '',
                        required: true,
                    },
                    {
                        step: 'prepared',
                        value: this.lead.prepared ? 'completed' : '',
                        required: true,
                    },
                    {
                        step: 'available',
                        value: this.lead.available_date,
                        required: false,
                    },
                    {
                        step: 'delivery',
                        value: this.lead.delivery_date,
                        required: true,
                    },
                ].filter(step => step.required);

                const nextStepIndex = stepFlowReversed.findIndex(s => s.value) - 1;

                return nextStepIndex >= 0 ? stepFlowReversed[nextStepIndex].step : '';
            },

            status() {
                return this.lead.getSaleState(this.startDate, this.endDate, this.options.carryOver);
            },

            vehicle() {
                const soldVehicle = this.lead.wanted_vehicles.find(v => v.sold);
                const matchingVehicle = this.lead.wanted_vehicles.find(v => v.model);
                const firstVehicle = !empty(this.lead.wanted_vehicles) ? this.lead.wanted_vehicles[0] : {};

                const vehicle = soldVehicle || matchingVehicle || firstVehicle;

                return {
                    model: vehicle.model || this.$t('saleTable.unknown'),
                    trim: vehicle.trim || '',
                    year: vehicle.year || '',
                    stock: vehicle.stock || '',
                    stock_state: vehicle.stock_state || '',
                    recorded_date: vehicle.recorded_date || '',
                };
            },
        },

        methods: {
            getTooltip() {
                let generalInfo = '';
                let detailedInfo = '';
                let status = '';
                let division = '';
                let stock = '';
                let stockState = '';
                let discounted = '';

                if (this.status == 'waitingSale' && this.lead.waiting_sale) {
                    status = this.$t('saleTable.filters.manualWaitingSale');
                } else {
                    status = this.$t(`saleTable.filters.${this.status}`);
                }

                if (this.lead.division_id == Division.NEW) {
                    division += this.$t('saleTable.new');
                } else if (this.lead.division_id == Division.USED) {
                    division += this.$t('saleTable.used');
                } else {
                    division += this.$t('saleTable.withoutDivision');
                }

                if (this.vehicle.stock_state && this.vehicle.stock_state != 'none') {
                    stockState = `${this.$t(`inventory.${this.vehicle.stock_state}`)} `;
                }

                if (this.vehicle.stock) {
                    if (this.vehicle.stock != 'locate' && this.vehicle.stock != 'order') {
                        stock = `#${this.vehicle.stock}`;
                    } else {
                        stock = this.$t(`saleTable.${this.vehicle.stock}`);
                    }
                }

                generalInfo += `<div class="font-bold mb-1">${status} - ${division}</div>`;
                generalInfo += `<div>${this.lead.fullName}</div>`;
                generalInfo += `<div>${this.vehicle.model} ${this.vehicle.trim} ${this.vehicle.year}</div>`;
                generalInfo += `<div>${stockState}${stock}</div>`;

                if (this.lead.commercial_id) {
                    detailedInfo += `<div>${this.$t('general.commercial')}: ${this.lead.commercial.shortName}</div>`;
                }

                if (this.nextStep) {
                    detailedInfo += `<div>${this.$t('saleTable.nextStep')}${this.$t(
                        `saleTable.steps.${this.nextStep}`,
                    )}</div>`;
                }

                const saleDate = new ActivixDate(this.lead.sale_date, 'date');
                const deliveryDate = new ActivixDate(this.lead.delivery_date);
                const deliveredDate = new ActivixDate(this.lead.delivered_date);

                if (this.lead.isWaitingSale(this.startDate, this.endDate)) {
                    if (this.lead.waiting_sale) {
                        detailedInfo += `
                            <div>
                                ${this.$tc('saleTable.cards.sale', 1)}:
                                ${saleDate.toHumanShort() || 'N/A'}
                            </div>
                            <div>
                                ${this.$tc('saleTable.deliveryDate', 1)}:
                                ${deliveryDate.toHumanShort(false) || 'N/A'}
                            </div>
                            <div>
                                ${this.$tc('saleTable.cards.delivered', 1)}:
                                ${deliveredDate.toHumanShort(false) || 'N/A'}
                            </div>
                        `;
                    } else {
                        detailedInfo += `
                            <div>
                                ${this.$tc('saleTable.cards.sale', 1)}:
                                ${saleDate.toHumanShort()}
                            </div>
                        `;
                    }
                } else if (this.lead.delivery_date) {
                    detailedInfo += `
                        <div>
                            ${this.$tc('saleTable.deliveryDate', 1)}:
                            ${deliveryDate.toHumanShort(false)}
                        </div>
                    `;
                }

                if (this.vehicle.recorded_date && this.contextAccount.hasClientCardField('recorded_date')) {
                    const recordedDate = new ActivixDate(this.vehicle.recorded_date);

                    detailedInfo += `
                        <div>
                            ${this.$t('clientCard.vehicles.saved_date')}:
                            ${recordedDate.toHumanShort(false)}
                        </div>
                    `;
                }

                if (this.status == 'discounted') {
                    discounted = `
                        <div>
                            ${this.$t('date.discountedDate')}:
                            ${new ActivixDate(this.lead.discounted_date, 'date').toHumanShort()}
                        </div>
                    `;

                    detailedInfo += discounted;
                }

                if (this.stickerType !== 'sticker') {
                    return discounted;
                }

                return `
                    <div class="text-left space-y-2">
                        <div>${generalInfo}</div>
                        <div>${detailedInfo}</div>
                    </div>
                `;
            },
        },
    };
</script>
