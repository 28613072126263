<template>
    <div class="relative">
        <activix-checkbox
            readonly
            :value="checked"
            :size="size"
            :color="color"
            @click.native.prevent
        />
    </div>
</template>

<script>
    /* eslint-disable vue/require-prop-types */
    export default {
        props: ['lead', 'size', 'color'],

        computed: {
            checked() {
                return this.lead.products.some(product => {
                    return product.type == 'service' && product.category == 'maintenance' && product.pivot.sold;
                });
            },
        },
    };
</script>
