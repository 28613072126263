<template>
    <div>
        <span>
            <span class="font-bold">{{ cards.leadsWeb.number.count }}</span>
            {{ $tc('leadXpress.leads', cards.leadsWeb.number.count).toLowerCase() }}
        </span>
        <span class="ml-4">
            <span class="font-bold">{{ cards.leadsWebOrder.number.count }}</span>
            {{ $tc('webOrder.leads', cards.leadsWebOrder.number.count).toLowerCase() }}
        </span>
    </div>
</template>

<script>
    export default {
        props: {
            cards: {
                type: Object,
                default: () => {},
            },
        },
    };
</script>
