<template>
    <div>
        <div class="flex items-center space-x-2" v-if="vehicleObjects && vehicleObjects.length > 3">
            <span class="text-sm">{{ vehicleObjects.length }} {{ $t('dashboards.customFields.vehicles') }}</span>
            <activix-button
                type="white"
                size="small"
                @click="openEditVehicles"
            >
                <icon class="text-sm" name="regular/list-bullets" />
            </activix-button>
        </div>

        <div v-else>
            <div v-if="customField.type === 'boolean'">
                <div :key="index" v-for="(vehicleObject, index) in vehicleObjects">
                    <activix-tooltip :content="tooltip(vehicleObject)">
                        <activix-checkbox :value="getCustomable(vehicleObject) ? getCustomable(vehicleObject).pivot.value[0] : false" @click="updateCheckbox(vehicleObject)" />
                    </activix-tooltip>
                </div>
            </div>

            <div class="input-wrapper flex flex-col" v-else>
                <div :key="index" v-for="(vehicleObject, index) in vehicleObjects">
                    <activix-tooltip :content="tooltip(vehicleObject)">
                        <a class="min-w-8 inline-flex justify-center items-center min-h-2" :class="{ truncate: customField.type !== 'datetime' }" @click="openCustomFieldEditModal(vehicleObject)">
                            {{ displayName(vehicleObject) }}
                        </a>
                    </activix-tooltip>
                </div>

                <span v-if="vehicleObjects && !vehicleObjects.length">-</span>
            </div>
        </div>
    </div>
</template>

<script>
    // Value Objects
    import { parseCustomField } from '@/value-objects/CustomField.js';

    // Entities
    import Lead from '@/entities/Lead.js';

    // Pinia
    import { mapActions } from 'pinia';
    import { useGlobalStore } from '@/store/store.js';

    export default {
        props: {
            lead: {
                type: Lead,
                required: true,
            },
            field: {
                type: String,
                required: true,
            },
            columnTitle: {
                type: String,
                required: true,
            },
            customField: {
                type: Object,
                default: null,
                required: true,
            },
            clientCardSection: {
                type: String,
                default: '',
                required: true,
            },
        },

        computed: {
            vehicleObjects() {
                return this.lead.getVehicleCustomFieldsById(this.customField.id, this.clientCardSection);
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadVehicleAction']),

            getCustomable(value) {
                return value?.custom_fields.find(customField => customField.id == this.customField.id);
            },

            tooltip(vehicleObject) {
                const vehicle = vehicleObject.vehicle;
                let tooltip = [];

                if (vehicle.make) {
                    tooltip.push(vehicle.make);
                }

                if (vehicle.model) {
                    tooltip.push(vehicle.model);
                }

                if (vehicle.year) {
                    tooltip.push(vehicle.year);
                }

                tooltip = tooltip.join(' ');

                if (tooltip && vehicle.stock_state && vehicle.stock) {
                    tooltip += '<br>';
                }

                if (vehicle.stock_state && vehicle.stock) {
                    tooltip += `${this.stockStateText(vehicle.stock_state)} ${vehicle.stock}`;
                }

                return tooltip;
            },

            stockStateText(stockState) {
                const key = {
                    locate: 'leadVehicleStockStates.locate',
                    notAvailable: 'leadVehicleStockStates.notAvailable',
                    order: 'leadVehicleStockStates.order',
                    stock: 'leadVehicleStockStates.stock',
                }[stockState];

                return this.$t(key);
            },

            displayName(vehicleObject) {
                const customable = this.getCustomable(vehicleObject);

                if (!customable || !customable.pivot.value[0]) {
                    return '-';
                }

                return parseCustomField.toRead(customable.pivot.value, this.customField.type);
            },

            updateCheckbox(vehicleObject) {
                const customable = this.getCustomable(vehicleObject);
                let checked = false;

                if (customable) {
                    checked = customable.pivot.value[0];
                }

                this.updateLeadVehicleAction(
                    {},
                    vehicleObject.vehicle.id,
                    this.lead.id,
                    {
                        customField: {
                            id: this.customField.id,
                            value: !checked,
                        },
                    },
                );
            },

            openCustomFieldEditModal(vehicleObject) {
                const customable = this.getCustomable(vehicleObject);
                this.update = true;

                this.$modal.show('dashboard:customTextEdit', {
                    leadId: this.lead.id,
                    vehicle: vehicleObject?.vehicle,
                    title: this.columnTitle,
                    customField: customable,
                    customFieldId: this.customField.id,
                });
            },

            openEditVehicles() {
                this.$modal.show('dashboard:customVehiclesEdit', {
                    lead: this.lead,
                    vehicleObjects: this.vehicleObjects,
                    accountCustomField: this.customField,
                    title: this.columnTitle,
                });
            },
        },
    };
</script>
