<template>
    <el-table
        class="w-full border-t-2"
        border
        stripe
        :empty-text="isLoading ? $t('dashboards.statsTableLoading') : ''"
        :data="tableData"
        :default-sort="sort"
        :show-summary="true"
        :summary-method="formatTotals"
        ref="table"
        @sort-change="sortChange"
    >
        <el-table-column label-class-name="sticky-column">
            <el-table-column
                prop="name"
                :fixed="true"
                header-align="center"
                :label="nameColumnText"
                min-width="200"
                sort-by="name"
                :sortable="true"
                :sort-method="sortNameColumn"
                :sort-orders="['descending', 'ascending']"
                class-name="sticky-column"
            >
                <template slot-scope="{ row }">
                    <name-cell
                        :row="row"
                        :sub-level-key="filters.subLevelKey"
                        :show-sub-level="showSubLevel"
                        @toggle-row="toggleRow"
                    />
                </template>
            </el-table-column>
        </el-table-column>
        <el-table-column>
            <el-table-column
                prop="total_leads"
                :label="$tc('general.client', 0)"
                header-align="center"
                align="center"
                min-width="125"
                sort-by="total_leads"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="total_appointments"
                :label="$tc('webBoost.appointment', 0)"
                header-align="center"
                align="center"
                min-width="105"
                sort-by="total_appointments"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }} {{ toPercent(row.total_appointments, row.total_leads) }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        :percentage="['total_appointments', 'total_leads']"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="total_phone_appointments"
                :label="$tc('general.appointment', 0)"
                header-align="center"
                align="center"
                min-width="105"
                sort-by="total_phone_appointments"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot="header">
                    <span class="flex items-center justify-center">
                        {{ $tc('general.appointment', 0) }}
                        <icon name="regular/phone-landline" class="ml-2" />
                    </span>
                </template>
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }}
                        {{ toPercent(row.total_phone_appointments, row.total_leads) }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        :percentage="['total_phone_appointments', 'total_leads']"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
        </el-table-column>
        <el-table-column header-align="center" :label="$tc('leadXpress.presented', 0)">
            <el-table-column
                prop="total_leads_presented_with_appointment"
                :label="$tc('leadXpress.visitWithAppointment', 0)"
                header-align="center"
                align="center"
                sort-by="total_leads_presented_with_appointment"
                min-width="95"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }}
                        {{ toPercent(row.total_leads_presented_with_appointment, row.total_appointments) }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        :percentage="['total_leads_presented_with_appointment', 'total_appointments']"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="total_leads_presented_without_appointment"
                :label="$tc('leadXpress.visitWithoutAppointment', 0)"
                header-align="center"
                align="center"
                sort-by="total_leads_presented_without_appointment"
                min-width="95"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot="header">
                    {{ $tc('leadXpress.visitWithoutAppointment', 0) }}
                    <activix-tooltip placement="top" :content="$t('phoneUp.visitWithoutAppointmentTooltip')">
                        <icon name="regular/information-circle" class="text-grey-500 text-sm ml-1" />
                    </activix-tooltip>
                </template>
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }}
                        {{ toPercent(row.total_leads_presented_without_appointment, row.total_leads) }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        :percentage="['total_leads_presented_without_appointment', 'total_leads']"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
        </el-table-column>
        <el-table-column header-align="center">
            <el-table-column
                prop="total_sales"
                :label="$tc('allLead.sales', 0)"
                header-align="center"
                align="center"
                sort-by="total_sales"
                min-width="115"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }}
                        {{ toPercent(row[column.property], row.total_leads) }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        :percentage="['total_sales', 'total_leads']"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="total_deliveries"
                :label="$t('saleTable.table.delivered')"
                column-key="total_deliveries"
                header-align="center"
                align="center"
                sort-by="total_deliveries"
                min-width="95"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }}
                        {{ toPercent(row.total_deliveries, row.total_sales) }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        :percentage="['total_deliveries', 'total_sales']"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
        </el-table-column>
    </el-table>
</template>

<script>
    // Mixins
    import StatsTableMixin from '../../mixins/StatsTable.js';

    // Components
    import NameCell from './Components/NameCell.vue';
    import SubLevelCell from './Components/SubLevelCell.vue';

    export default {
        components: {
            NameCell,
            SubLevelCell,
        },

        mixins: [StatsTableMixin],

        props: {
            tableData: {
                type: Array,
                default: () => [],
            },
            filters: {
                type: Object,
                default: () => {},
            },
            isLoading: {
                type: Boolean,
                default: false,
            },
            nameColumnText: {
                type: String,
                default: '',
            },
            showSubLevel: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                columnsTotals: [
                    {
                        type: 'percent',
                        numerator: 'total_appointments',
                        denominator: 'total_leads',
                    },
                    {
                        type: 'percent',
                        numerator: 'total_phone_appointments',
                        denominator: 'total_leads',
                    },
                    {
                        type: 'percent',
                        numerator: 'total_leads_presented_with_appointment',
                        denominator: 'total_appointments',
                    },
                    {
                        type: 'percent',
                        numerator: 'total_leads_presented_without_appointment',
                        denominator: 'total_leads',
                    },
                    {
                        type: 'percent',
                        numerator: 'total_sales',
                        denominator: 'total_leads',
                    },
                    {
                        type: 'percent',
                        key: 'total_deliveries',
                        numerator: 'total_deliveries',
                        denominator: 'total_sales',
                    },
                ],
            };
        },
    };
</script>
