<template>
    <div class="flex">
        <div class="relative m-1">
            <activix-tooltip :content="equityTooltip">
                <div>
                    <span
                        class="label label-success | block"
                        :style="hasEquityNotification ? 'opacity: 100%' : 'opacity: 15%'"
                    >
                        E
                    </span>
                    <span
                        class="absolute right-0 top-0 h-2 w-2 -mt-0.5 -mr-0.5 rounded-full bg-orange-600"
                        v-if="hasPartialMatch && hasEquityNotification"
                    />
                </div>
            </activix-tooltip>
        </div>
        <div class="relative m-1">
            <activix-tooltip :content="$t('dashboards.alertTypes.location')">
                <span
                    class="label label-warning | block"
                    :style="hasLocationNotification ? 'opacity: 100%' : 'opacity: 15%'"
                >
                    L
                </span>
            </activix-tooltip>
        </div>
        <div class="relative m-1">
            <activix-tooltip :content="$t('dashboards.alertTypes.warranty')">
                <span
                    class="label label-primary | block"
                    :style="hasWarrantyNotification ? 'opacity: 100%' : 'opacity: 15%'"
                >
                    {{ $t('dashboards.alertTypes.warranty_icon') }}
                </span>
            </activix-tooltip>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '../../../store/store.js';
    import VehicleVinMatchType from '../../../entities/VehicleVinMatchType.js';

    export default {
        props: {
            lead: {
                type: Object,
                required: true,
            },
        },
        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),

            hasEquityNotification() {
                return !!this.equityNotifications().length;
            },

            hasLocationNotification() {
                return !!this.locationNotifications().length;
            },

            hasWarrantyNotification() {
                return !!this.warrantyNotifications().length;
            },

            hasPartialMatch() {
                return this.lead.vehicles.find(
                    vehicle => vehicle.vin_match == VehicleVinMatchType.PARTIAL,
                );
            },

            equityTooltip() {
                if (this.hasPartialMatch) {
                    return this.$t('dashboards.alertTypes.equityWithPartialMatch');
                }

                return this.$t('dashboards.alertTypes.equity');
            },
        },

        methods: {
            equityNotifications() {
                return this.lead.renewal_alert_notifications.filter(notification => {
                    return notification.notification == 'renewalAlertEquity';
                });
            },

            locationNotifications() {
                return this.lead.renewal_alert_notifications.filter(notification => {
                    return notification.notification == 'renewalAlertEndLocation';
                });
            },

            warrantyNotifications() {
                return this.lead.renewal_alert_notifications.filter(notification => {
                    return notification.notification == 'renewalAlertEndWarranty';
                });
            },
        },
    };
</script>
