<template>
    <activix-button type="outline">
        <span class="w-48 flex justify-between items-center">
            <span class="truncate" :class="{'text-gray-600': !label }" v-text="label || placeholder" />
            <icon class="text-grey-600 ml-3 text-xs" :name="$icons.expand" />
        </span>
    </activix-button>
</template>

<script>
    export default {
        props: {
            placeholder: {
                default: '',
                type: String,
            },

            selected: {
                default: () => [],
                type: Array,
            },
        },

        computed: {
            label() {
                return this.selected.map(selectedOption => {
                    return selectedOption.name;
                }).join(', ');
            },
        },
    };
</script>
