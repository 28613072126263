<template>
    <el-table
        class="w-full border-t-2"
        border
        stripe
        :empty-text="isLoading ? $t('dashboards.statsTableLoading') : ''"
        :data="customTableData"
        :default-sort="sort"
        :show-summary="true"
        :summary-method="formatTotals"
        ref="table"
        @sort-change="sortChange"
    >
        <el-table-column label-class-name="sticky-column">
            <el-table-column
                prop="name"
                :fixed="true"
                header-align="center"
                :label="nameColumnText"
                min-width="200"
                sort-by="name"
                :sortable="true"
                :sort-method="sortNameColumn"
                :sort-orders="['descending', 'ascending']"
                class-name="sticky-column"
            >
                <template slot-scope="{ row }">
                    <name-cell
                        :row="row"
                        :sub-level-key="filters.subLevelKey"
                        :show-sub-level="showSubLevel"
                        @toggle-row="toggleRow"
                    />
                </template>
            </el-table-column>
        </el-table-column>
        <el-table-column header-align="center" :label="$t('dashboards.stats.headers.total')">
            <el-table-column
                prop="total_leads"
                :label="$tc('allLead.clients', 0)"
                header-align="center"
                align="center"
                min-width="95"
                sort-by="total_leads"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="total_leads_sales"
                :label="$tc('allLead.sales', 0)"
                header-align="center"
                align="center"
                min-width="95"
                sort-by="total_leads_sales"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }} {{ toPercent(row.total_leads_sales, row.total_leads) }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        :percentage="['total_leads_sales', 'total_leads']"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
        </el-table-column>
        <el-table-column header-align="center" :label="$t('dashboards.stats.headers.web')">
            <el-table-column
                prop="total_web_leads"
                :label="$tc('general.lead', 0) + (authUser.hasWebOrder() ? ` & ${$tc('general.webOrder', 0)}` : '')"
                header-align="center"
                align="center"
                sort-by="total_web_leads"
                min-width="95"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="total_web_leads_sales"
                :label="$tc('allLead.sales', 0)"
                header-align="center"
                align="center"
                sort-by="total_web_leads_sales"
                min-width="95"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }}
                        {{ toPercent(row.total_web_leads_sales, row.total_web_leads) }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        :percentage="['total_web_leads_sales', 'total_web_leads']"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
        </el-table-column>
        <el-table-column header-align="center" :label="$t('dashboards.stats.headers.walkIn')">
            <el-table-column
                prop="total_walk_in_leads"
                :label="$tc('allLead.clients', 0)"
                header-align="center"
                align="center"
                min-width="95"
                sort-by="total_walk_in_leads"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="total_walk_in_leads_sales"
                :label="$tc('allLead.sales', 0)"
                header-align="center"
                align="center"
                min-width="95"
                sort-by="total_walk_in_leads_sales"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }}
                        {{ toPercent(row.total_walk_in_leads_sales, row.total_walk_in_leads) }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        :percentage="['total_walk_in_leads_sales', 'total_walk_in_leads']"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
        </el-table-column>
        <el-table-column header-align="center" :label="$t('dashboards.stats.headers.phoneUp')">
            <el-table-column
                prop="total_phone_up_leads"
                :label="$tc('allLead.clients', 0)"
                header-align="center"
                align="center"
                min-width="95"
                sort-by="total_phone_up_leads"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="total_phone_up_leads_sales"
                :label="$tc('allLead.sales', 0)"
                header-align="center"
                align="center"
                min-width="95"
                sort-by="total_phone_up_leads_sales"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }}
                        {{ toPercent(row.total_phone_up_leads_sales, row.total_phone_up_leads) }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        :percentage="['total_phone_up_leads_sales', 'total_phone_up_leads']"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
        </el-table-column>
        <el-table-column header-align="center" :label="$t('dashboards.stats.headers.renewalLoyalty')">
            <el-table-column
                prop="total_renewal_leads"
                :label="$tc('allLead.clients', 0)"
                header-align="center"
                align="center"
                sort-by="total_renewal_leads"
                min-width="95"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="total_renewal_leads_sales"
                :label="$tc('allLead.sales', 0)"
                header-align="center"
                align="center"
                sort-by="total_renewal_leads_sales"
                min-width="95"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }}
                        {{ toPercent(row.total_renewal_leads_sales, row.total_leads) }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        :percentage="['total_renewal_leads_sales', 'total_leads']"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
        </el-table-column>
        <el-table-column>
            <el-table-column
                prop="total_deliveries"
                :label="$t('saleTable.table.delivered')"
                header-align="center"
                align="center"
                total_renewal_leads
                sort-by="total_deliveries"
                min-width="95"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }} {{ toPercent(row.total_deliveries, row.total_leads) }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        :percentage="['total_deliveries', 'total_leads']"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
        </el-table-column>
        <template v-if="showActivityColumns">
            <el-table-column header-align="center" :label="$t('dashboards.stats.headers.activities')">
                <el-table-column
                    prop="total_follow_ups"
                    header-align="center"
                    align="center"
                    sort-by="total_follow_ups"
                    :sortable="true"
                    :sort-orders="['descending', 'ascending']"
                >
                    <template slot="header">
                        {{ $t('general.total') }}
                        <activix-tooltip placement="top" :content="$t('allLead.allFollowedTooltip')">
                            <icon name="regular/information-circle" class="text-grey-500 text-sm ml-1" />
                        </activix-tooltip>
                    </template>
                    <template slot-scope="{ row, column }">
                        {{ row[column.property] }}
                        <sub-level-cell
                            :row="row"
                            :column-name="column.property"
                            :sub-level-key="filters.subLevelKey"
                            :empty-value="'N/A'"
                            v-if="row.expanded"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="total_activities"
                    header-align="center"
                    align="center"
                    sort-by="total_activities"
                    :sortable="true"
                    :sort-orders="['descending', 'ascending']"
                >
                    <template slot="header">
                        {{ $tc('general.date', 1) }}
                        <activix-tooltip placement="top" :content="$t('allLead.allFollowedTooltipDates')">
                            <icon name="regular/information-circle" class="text-grey-500 text-sm ml-1" />
                        </activix-tooltip>
                    </template>
                    <template slot-scope="{ row, column }">
                        {{ row[column.property] }}
                        <sub-level-cell
                            :row="row"
                            :column-name="column.property"
                            :sub-level-key="filters.subLevelKey"
                            :empty-value="'N/A'"
                            v-if="row.expanded"
                        />
                    </template>
                </el-table-column>
            </el-table-column>
            <el-table-column header-align="center" :label="$t('dashboards.stats.headers.averages')">
                <el-table-column
                    prop="follow_up_ratio"
                    header-align="center"
                    align="center"
                    min-width="95"
                    sort-by="follow_up_ratio"
                    :sortable="true"
                    :sort-orders="['descending', 'ascending']"
                >
                    <template slot="header">
                        {{ $t('allLead.averageFollowed') }}
                        <activix-tooltip placement="top" :content="$t('allLead.averageFollowedTooltip')">
                            <icon name="regular/information-circle" class="text-grey-500 text-sm ml-1" />
                        </activix-tooltip>
                    </template>
                    <template slot-scope="{ row, column }">
                        {{ row[column.property].toFixed(1) }}
                        <sub-level-cell
                            :row="row"
                            :column-name="column.property"
                            :sub-level-key="filters.subLevelKey"
                            :empty-value="'N/A'"
                            v-if="row.expanded"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="contact_ratio"
                    header-align="center"
                    align="center"
                    sort-by="contact_ratio"
                    min-width="95"
                    :sortable="true"
                    :sort-orders="['descending', 'ascending']"
                >
                    <template slot="header">
                        {{ $t('allLead.averageContact') }}
                        <activix-tooltip placement="top" :content="$t('allLead.averageContactTooltip')">
                            <icon name="regular/information-circle" class="text-grey-500 text-sm ml-1" />
                        </activix-tooltip>
                    </template>
                    <template slot-scope="{ row, column }">
                        {{ row[column.property].toFixed(1) }}
                        <sub-level-cell
                            :row="row"
                            :column-name="column.property"
                            :sub-level-key="filters.subLevelKey"
                            :empty-value="'N/A'"
                            v-if="row.expanded"
                        />
                    </template>
                </el-table-column>
            </el-table-column>
        </template>
    </el-table>
</template>

<script>
    // Mixins
    import { mapState } from 'pinia';
    import StatsTableMixin from '../../mixins/StatsTable.js';

    // Components
    import NameCell from './Components/NameCell.vue';
    import SubLevelCell from './Components/SubLevelCell.vue';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            NameCell,
            SubLevelCell,
        },

        mixins: [StatsTableMixin],

        props: {
            tableData: {
                type: Array,
                default: () => [],
            },
            filters: {
                type: Object,
                default: () => {},
            },
            isLoading: {
                type: Boolean,
                default: false,
            },
            nameColumnText: {
                type: String,
                default: '',
            },
            showActivityColumns: {
                type: Boolean,
                default: false,
            },
            showSubLevel: {
                type: Boolean,
                default: false,
            },
            showSubLevelActivities: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                columnsTotals: [
                    {
                        type: 'percent',
                        numerator: 'total_leads_sales',
                        denominator: 'total_leads',
                    },
                    {
                        type: 'percent',
                        numerator: 'total_web_leads_sales',
                        denominator: 'total_web_leads',
                    },
                    {
                        type: 'percent',
                        numerator: 'total_walk_in_and_phone_up_leads_sales',
                        denominator: 'total_walk_in_and_phone_up_leads',
                    },
                    {
                        type: 'percent',
                        numerator: 'total_walk_in_leads_sales',
                        denominator: 'total_walk_in_leads',
                    },
                    {
                        type: 'percent',
                        numerator: 'total_phone_up_leads_sales',
                        denominator: 'total_phone_up_leads',
                    },
                    {
                        type: 'percent',
                        numerator: 'total_renewal_leads_sales',
                        denominator: 'total_leads',
                    },
                    {
                        type: 'percent',
                        numerator: 'total_deliveries',
                        denominator: 'total_leads',
                    },
                    {
                        type: 'division',
                        key: 'follow_up_ratio',
                        numerator: 'total_follow_ups',
                        denominator: 'total_leads',
                    },
                    {
                        type: 'division',
                        key: 'contact_ratio',
                        numerator: 'total_contacts',
                        denominator: 'total_leads',
                    },
                ],
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),

            customTableData() {
                return this.tableData || [];
            },
        },
    };
</script>
