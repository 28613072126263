<template>
    <div>
        <div class="highcharts | w-full lg:w-1/2" ref="byDivision" />
        <div class="highcharts | w-full lg:w-1/2" ref="byUser" />
        <div class="highcharts | w-full lg:w-1/2" ref="salesByUser" />
        <div class="highcharts | w-full lg:w-1/2" ref="salesByTestDriveAndUser" />
    </div>
</template>

<script>
    import Highcharts from 'highcharts/highstock.js';

    // Entities
    import { mapState } from 'pinia';
    import Division from '../../../entities/Division.js';

    // Mixins
    import Graphs from '../../../mixins/Graphs.js';

    // Pinia
    import { useContextStore } from '../../../store/modules/context/store.js';
    import { useDashboardStore } from '../../../store/modules/dashboard/store.js';

    export default {
        mixins: [Graphs],

        props: {
            data: {
                type: Object,
                mandatory: true,
            },
        },

        data() {
            return {
                dataWalkInNew: [],
                dataWalkInUsed: [],
                dataWalkInNoDivision: [],
                dataSaleByUser: [],
                dataSaleRtByUser: [],
                dataWalkInByUser: [],
            };
        },

        computed: {
            ...mapState(useDashboardStore, ['division']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),
        },

        watch: {
            data: {
                immediate: true,
                handler() {
                    this.setData();
                },
            },
        },

        methods: {
            setData() {
                this.dataWalkInNew = {
                    labels: [],
                    data: [],
                };

                this.dataWalkInUsed = {
                    labels: [],
                    data: [],
                };

                this.dataWalkInNoDivision = {
                    labels: [],
                    data: [],
                };

                this.dataSaleByUser = {
                    labels: [],
                    dataWalkIn: [],
                    dataNew: [],
                    dataUsed: [],
                };

                this.dataSaleRtByUser = {
                    labels: [],
                    dataWoRoadTest: [],
                    dataWRoadTest: [],
                };

                this.dataWalkInByUser = {
                    labels: [],
                    data: [],
                };

                const accountUsers = !empty(this.contextAccount) ? this.contextAccount.users : [];

                if (this.data) {
                    for (const [key, value] of Object.entries(this.data.walkinByDatesAndDivisions.dates)) {
                        this.dataWalkInNew.labels.push(key);
                        this.dataWalkInUsed.labels.push(key);
                        this.dataWalkInNoDivision.labels.push(key);

                        this.dataWalkInNew.data.push(value.divisions[Division.NEW] || 0);
                        this.dataWalkInUsed.data.push(value.divisions[Division.USED] || 0);
                        this.dataWalkInNoDivision.data.push(value.divisions?.none || 0);
                    }

                    // WalkIns by user
                    for (let i = accountUsers.length - 1; i >= 0; i--) {
                        if (!accountUsers[i].active) {
                            continue;
                        }

                        const userName = this.getFullName(accountUsers[i]);
                        const countWalkin = this.data.walkInByUsers.users[accountUsers[i].id];

                        if (countWalkin) {
                            const walkinSalesUser = this.data.walkInWithSalesByUsersAndDivisions.users[
                                accountUsers[i].id
                            ];

                            this.dataWalkInByUser.labels.push(userName);
                            this.dataWalkInByUser.data.push(countWalkin);

                            if (walkinSalesUser?.count) {
                                const countSaleNew = walkinSalesUser.divisions[Division.NEW] || 0;
                                const countSaleUsed = walkinSalesUser.divisions[Division.USED] || 0;

                                const walkinSalesRoadTestUser = this.data.walkInWithSalesByUsersAndRoadTest.users[
                                    accountUsers[i].id
                                ];
                                const countSaleWithRoadTest = walkinSalesRoadTestUser.with_road_test;
                                const countSaleWithoutRoadTest = walkinSalesRoadTestUser.without_road_test;

                                this.dataSaleByUser.dataWalkIn.push(countWalkin);

                                this.dataSaleByUser.labels.push(userName);
                                this.dataSaleByUser.dataNew.push(countSaleNew);
                                this.dataSaleByUser.dataUsed.push(countSaleUsed);

                                this.dataSaleRtByUser.labels.push(userName);
                                this.dataSaleRtByUser.dataWoRoadTest.push(countSaleWithoutRoadTest);
                                this.dataSaleRtByUser.dataWRoadTest.push(countSaleWithRoadTest);
                            }
                        }
                    }
                }

                this.$emit('graph-loaded', true);

                this.$nextTick(() => {
                    this.loadGraphs();
                });
            },

            loadGraphs() {
                if (
                    this.dataWalkInNew.data.length == 0 &&
                    this.dataWalkInUsed.data.length == 0 &&
                    this.dataWalkInNoDivision.data.length == 0
                ) {
                    return;
                }

                this.destroyCharts();

                const walkInSeries = [];

                if (this.division.includes('new') || empty(this.division)) {
                    walkInSeries.push({
                        name: this.$t('graph.new'),
                        data: this.dataWalkInNew.data,
                        color: 'rgba(60, 141, 188,1)',
                    });
                }

                if (this.division.includes('used') || empty(this.division)) {
                    walkInSeries.push({
                        name: this.$t('graph.used'),
                        data: this.dataWalkInUsed.data,
                        color: 'rgba(118, 175, 208,1)',
                    });
                }

                if (this.division.includes('none') || empty(this.division)) {
                    walkInSeries.push({
                        name: this.$t('graph.noDivision'),
                        data: this.dataWalkInNoDivision.data,
                        color: 'rgba(158,158,158,1)',
                    });
                }

                // Origin Total
                let walkInTotal = 0;

                $.each(this.dataWalkInByUser.data, (key, user) => {
                    walkInTotal += user;
                });

                this.charts.push(
                    Highcharts.chart(this.$refs.byDivision, {
                        chart: {
                            type: 'areaspline',
                        },
                        title: {
                            text: this.$t('graph.numberOfWalkIn'),
                        },
                        xAxis: {
                            categories: this.dataWalkInNew.labels,
                        },
                        yAxis: {
                            title: {
                                text: this.$t('graph.walkIn'),
                            },
                        },
                        tooltip: {
                            valueSuffix: ` ${this.$t('graph.walkIn')}`,
                        },
                        plotOptions: {
                            areaspline: {
                                fillOpacity: 0.7,
                            },
                        },
                        series: walkInSeries,
                    }),
                );

                this.charts.push(
                    Highcharts.chart(this.$refs.byUser, {
                        chart: {
                            type: 'bar',
                        },
                        title: {
                            text: this.$t('graph.walkInByUser'),
                        },
                        xAxis: {
                            categories: this.dataWalkInByUser.labels,
                        },
                        yAxis: {
                            title: {
                                text: this.$t('graph.walkIn'),
                            },
                        },
                        tooltip: {
                            pointFormatter() {
                                const tmpPercentage = ((this.y / walkInTotal) * 100).toFixed(0);
                                return `<span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${this.y} (${tmpPercentage}%)</b><br>`;
                            },
                        },
                        series: [
                            {
                                name: this.$t('graph.walkIn'),
                                data: this.dataWalkInByUser.data,
                                color: 'rgba(60,141,188,1)',
                            },
                        ],
                    }),
                );

                const seriesNew = {
                    name: `${this.$t('graph.sale')} ${this.$t('graph.new').toLowerCase()}`,
                    data: this.dataSaleByUser.dataNew,
                    color: 'rgba(60,141,188,1)',
                };

                const seriesUsed = {
                    name: `${this.$t('graph.sale')} ${this.$t('graph.used').toLowerCase()}`,
                    data: this.dataSaleByUser.dataUsed,
                    color: 'rgba(158,158,158,1)',
                };

                let seriesToUse = [seriesNew, seriesUsed];

                if (this.division == 'new') {
                    seriesToUse = [seriesNew];
                } else if (this.division == 'used') {
                    seriesToUse = [seriesUsed];
                }

                const self = this;

                this.charts.push(
                    Highcharts.chart(this.$refs.salesByUser, {
                        chart: {
                            type: 'column',
                        },
                        title: {
                            text: this.$t('graph.saleByUser'),
                        },
                        xAxis: {
                            categories: this.dataSaleByUser.labels,
                        },
                        yAxis: {
                            title: {
                                text: this.$t('graph.sale'),
                            },
                        },
                        tooltip: {
                            pointFormatter() {
                                const totWalkInUser =
                                    self.dataSaleByUser.dataWalkIn[self.dataSaleByUser.labels.indexOf(this.category)];
                                const tmpPercentage = ((this.y / totWalkInUser) * 100).toFixed(0);
                                return `<span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${
                                    this.y
                                } (${tmpPercentage}%, ${this.y}/${
                                    self.dataSaleByUser.dataWalkIn[self.dataSaleByUser.labels.indexOf(this.category)]
                                })</b><br>`;
                            },
                        },
                        series: seriesToUse,
                    }),
                );

                this.charts.push(
                    Highcharts.chart(this.$refs.salesByTestDriveAndUser, {
                        chart: {
                            type: 'column',
                        },
                        title: {
                            text: this.$t('graph.saleRtByUser'),
                        },
                        xAxis: {
                            categories: this.dataSaleRtByUser.labels,
                        },
                        yAxis: {
                            title: {
                                text: this.$t('graph.sale'),
                            },
                        },
                        tooltip: {
                            pointFormatter() {
                                const totWalkInUser =
                                    self.dataSaleByUser.dataWalkIn[self.dataSaleByUser.labels.indexOf(this.category)];
                                const tmpPercentage = ((this.y / totWalkInUser) * 100).toFixed(0);
                                return `<span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${
                                    this.y
                                } (${tmpPercentage}%, ${this.y}/${
                                    self.dataSaleByUser.dataWalkIn[self.dataSaleByUser.labels.indexOf(this.category)]
                                })</b><br>`;
                            },
                        },
                        series: [
                            {
                                name: `${this.$t('graph.without')} ${this.$t('graph.roadTest')}`,
                                data: this.dataSaleRtByUser.dataWoRoadTest,
                                color: 'rgba(60,141,188,1)',
                            },
                            {
                                name: `${this.$t('graph.with')} ${this.$t('graph.roadTest')}`,
                                data: this.dataSaleRtByUser.dataWRoadTest,
                                color: 'rgba(158,158,158,1)',
                            },
                        ],
                    }),
                );
            },
        },
    };
</script>
