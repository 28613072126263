<template>
    <activix-tooltip :content="disabled || checked ? tooltip : ''">
        <div class="input-wrapper">
            <activix-checkbox
                :disabled="disabled"
                :value="checked"
                :size="size"
                :color="color"
                @mouseenter.native="disabled || fetchLead(lead.id)"
                @click.native.prevent="onClick"
            />

            <template v-if="inClientCard">
                <span class="editable | ml-4 | md:ml-2" :class="{ disabled: disabled }" @click="onDateClick">
                    {{ localizedDate || emptyValue }}
                </span>
            </template>

            <activix-confirm-modal
                :approve-text="$t('dashboards.removeIt')"
                :content="$t('dashboards.deleteFollowUpConfirmation', [$t('dashboards.performance.appt_confirmation')])"
                :deny-text="$t('general.cancel')"
                :opened.sync="uncheckModalOpened"
                :title="$t('general.areYouSure')"
                type="error"
                @approve="triggerUncheck"
                @deny="updateChecked"
            />
        </div>
    </activix-tooltip>
</template>

<script>
    import { mapActions, mapState } from 'pinia';

    import ActivixDate from '../../../../value-objects/ActivixDate.js';

    import FollowUpMixin from '../../../../mixins/FollowUpMixin.js';
    import { useGlobalStore } from '../../../../store/store.js';

    export default {
        mixins: [FollowUpMixin],

        props: {
            lead: {
                type: Object,
                required: true,
            },
            startDate: {
                type: ActivixDate,
                default: null,
            },
            endDate: {
                type: ActivixDate,
                default: null,
            },
            inClientCard: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                checked: false,
                uncheckModalOpened: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),

            date() {
                return new ActivixDate(this.lead.appt_confirmation_date);
            },

            localizedDate() {
                return this.date.toHumanShort();
            },

            isInDates() {
                return this.date.isBetween(this.startDate, this.endDate);
            },

            color() {
                if (!this.inClientCard && this.isInDates) {
                    return 'green';
                }

                return 'blue';
            },

            disabled() {
                // If lead is type walk-in
                if (this.lead.lead_type_id == 3) {
                    return true;
                }

                // If lead has appointment
                if (!this.lead.appointment_date || this.lead.presented_date) {
                    return true;
                }

                return this.guest;
            },

            tooltip() {
                let tooltip = '';

                if (!this.inClientCard) {
                    tooltip += `<strong>${this.$t('result.apptConfirmation')}</strong>`;

                    if (this.lead.lead_type_id == 3) {
                        tooltip += ` (${this.$t('dashboards.walkIn')})`;
                    }

                    if (this.lead.appt_confirmation_date) {
                        tooltip += `<br>${this.localizedDate}`;

                        if (!this.isInDates) {
                            tooltip += `<br><em>${this.$t('dashboards.notInDate')}</em>`;
                        }
                    }
                }

                return tooltip;
            },
        },

        watch: {
            'lead.appt_confirmation_date': {
                immediate: true,
                handler() {
                    this.updateChecked();
                },
            },

            'lead.lead_type_id'() {
                this.updateChecked();
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction', 'fetchLead']),

            onDateClick() {
                const toggleChecked = !this.checked;

                this.onClick(toggleChecked);
            },

            updateChecked() {
                this.checked = !!this.lead.appt_confirmation_date || this.lead.lead_type_id == 3;
            },

            onClick(toggleChecked = true) {
                if (this.disabled) {
                    return;
                }

                if (toggleChecked) {
                    this.checked = !this.checked;
                }

                this.openModal();
            },

            openModal() {
                if (this.checked) {
                    this.$modal.show('followUp', {
                        leadId: this.lead.id,
                        current: 'appt_confirmation',
                        last: 'appointment',
                    });
                } else {
                    this.uncheckModalOpened = true;
                }
            },

            triggerUncheck() {
                this.updateLeadAction(this.lead.id, {
                    appt_confirmation: false,
                    appt_confirmation_date: null,
                });
            },
        },

        created() {
            this.$eventBus.$on('cancel-follow-up', this.updateChecked);
        },

        mounted() {
            this.updateChecked();
        },

        beforeDestroy() {
            this.$eventBus.$off('cancel-follow-up', this.updateChecked);
        },
    };
</script>
