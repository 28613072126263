import Enum from './Enum.js';

export default class Hotswap extends Enum {
    static get entries() {
        return {
            in1Month: 'in1Month',
            in2Month: 'in2Month',
            in3Month: 'in3Month',
            in4Month: 'in4Month',
            in5Month: 'in5Month',
            in6Month: 'in6Month',
            in7Month: 'in7Month',
            in8Month: 'in8Month',
            in9Month: 'in9Month',
            in10Month: 'in10Month',
            in11Month: 'in11Month',
            in12Month: 'in12Month',
            in16Month: 'in16Month',
            in18Month: 'in18Month',
            in20Month: 'in20Month',
            in24Month: 'in24Month',
            last30: 'last30',
            last7: 'last7',
            last90: 'last90',
            lastMonth: 'lastMonth',
            last3Month: 'last3Month',
            monthsAgo2: 'monthsAgo2',
            monthsAgo3: 'monthsAgo3',
            newerThan: 'newerThan',
            nextMonth: 'nextMonth',
            next3Month: 'next3Month',
            next6Month: 'next6Month',
            next12Month: 'next12Month',
            next18Month: 'next18Month',
            next24Month: 'next24Month',
            next36Month: 'next36Month',
            next7Years: 'next7Years',
            next30: 'next30',
            next7: 'next7',
            next90: 'next90',
            olderThan: 'olderThan',
            sinceBeginning: 'sinceBeginning',
            fiscalYearToDate: 'fiscalYearToDate',
            thisMonth: 'thisMonth',
            thisWeek: 'thisWeek',
            tomorrow: 'tomorrow',
            today: 'today',
            yesterday: 'yesterday',
        };
    }

    static get TODAY() {
        return this.entries.today;
    }

    static get TOMORROW() {
        return this.entries.tomorrow;
    }

    static get YESTERDAY() {
        return this.entries.yesterday;
    }

    static get LAST_7() {
        return this.entries.last7;
    }

    static get THIS_MONTH() {
        return this.entries.thisMonth;
    }

    static get THIS_WEEK() {
        return this.entries.thisWeek;
    }

    static get IN_1_MONTH() {
        return this.entries.in1Month;
    }

    static get IN_2_MONTH() {
        return this.entries.in2Month;
    }

    static get IN_3_MONTH() {
        return this.entries.in3Month;
    }

    static get IN_4_MONTH() {
        return this.entries.in4Month;
    }

    static get IN_5_MONTH() {
        return this.entries.in5Month;
    }

    static get IN_6_MONTH() {
        return this.entries.in6Month;
    }

    static get IN_7_MONTH() {
        return this.entries.in7Month;
    }

    static get IN_8_MONTH() {
        return this.entries.in8Month;
    }

    static get IN_9_MONTH() {
        return this.entries.in9Month;
    }

    static get IN_10_MONTH() {
        return this.entries.in10Month;
    }

    static get IN_11_MONTH() {
        return this.entries.in11Month;
    }

    static get IN_12_MONTH() {
        return this.entries.in12Month;
    }

    static get IN_16_MONTH() {
        return this.entries.in16Month;
    }

    static get IN_18_MONTH() {
        return this.entries.in18Month;
    }

    static get IN_20_MONTH() {
        return this.entries.in20Month;
    }

    static get IN_24_MONTH() {
        return this.entries.in24Month;
    }

    static get LAST_30() {
        return this.entries.last30;
    }

    static get LAST_MONTH() {
        return this.entries.lastMonth;
    }

    static get LAST_3_MONTH() {
        return this.entries.last3Month;
    }

    static get MONTHS_AGO_2() {
        return this.entries.monthsAgo2;
    }

    static get MONTHS_AGO_3() {
        return this.entries.monthsAgo3;
    }

    static get LAST_90() {
        return this.entries.last90;
    }

    static get OLDER_THAN() {
        return this.entries.olderThan;
    }

    static get NEWER_THAN() {
        return this.entries.newerThan;
    }

    static get SINCE_BEGINNING() {
        return this.entries.sinceBeginning;
    }

    static get FISCAL_YEAR_TO_DATE() {
        return this.entries.fiscalYearToDate;
    }

    static get NEXT_MONTH() {
        return this.entries.nextMonth;
    }

    static get NEXT_3_MONTH() {
        return this.entries.next3Month;
    }

    static get NEXT_6_MONTH() {
        return this.entries.next6Month;
    }

    static get NEXT_12_MONTH() {
        return this.entries.next12Month;
    }

    static get NEXT_18_MONTH() {
        return this.entries.next18Month;
    }

    static get NEXT_24_MONTH() {
        return this.entries.next24Month;
    }

    static get NEXT_36_MONTH() {
        return this.entries.next36Month;
    }

    static get NEXT_7_YEARS() {
        return this.entries.next7Years;
    }

    static get NEXT_7() {
        return this.entries.next7;
    }

    static get NEXT_30() {
        return this.entries.next30;
    }

    static get NEXT_90() {
        return this.entries.next90;
    }
}
