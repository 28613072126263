<template>
    <activix-multiselect
        identifier="value"
        label="label"
        min-width="75"
        placeholder="-"
        :allow-empty="multiple"
        :autosize="autosize"
        :class="activeFilterClass"
        :close-on-select="false"
        :limit-text="limitText"
        :max-height="220"
        :multiple-limit="1"
        :multiple="multiple"
        :name="columnName"
        :none-label="noneLabel"
        :none-selected="selected.includes('none')"
        :options="formatedOptions"
        :searchable="false"
        :select-all="selectAll"
        :selected="selectedFilter"
        :with-none="withNone"
        @update="updateFilters"
    />
</template>

<script>
    import { camelCase, sortBy, cloneDeep, range, uniqBy, transform, isArray } from 'lodash-es';
    import { mapState } from 'pinia';
    import Moment from '../../value-objects/Moment.js';
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';

    export default {
        props: {
            selectAll: {
                type: Boolean,
                default: true,
            },
            withNone: {
                type: Boolean,
                default: false,
            },
            noneLabel: {
                type: String,
                default: '',
            },
            withEmpty: {
                type: Boolean,
                default: false,
            },
            optionType: {
                type: String,
                default: '',
            },
            options: {
                type: Array,
                default() {
                    return [];
                },
            },
            columnClass: {
                type: String,
                default: '',
            },
            columnName: {
                type: String,
                default: '',
            },
            baseTrans: {
                type: String,
                default: '',
            },
            filtersBuffer: {
                type: Object,
                default: () => ({}),
            },
            multiple: {
                type: Boolean,
                default: false,
            },
            sorting: {
                type: Boolean,
                default: true,
            },
            autosize: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                selected: [],
            };
        },

        computed: {
            ...mapState(useDashboardStore, {
                filters: store => store.configs.filters,
            }),

            columnNameClass() {
                return this.columnName.replace(':', '-');
            },

            activeFilterClass() {
                let baseClass = `filter-select fixed-class filter-${this.columnNameClass}`;

                if (!empty(this.filters[this.columnName])) {
                    baseClass += ' active-filter';
                }

                return baseClass;
            },

            selectedFilter() {
                let filter;

                if (isArray(this.selected)) {
                    filter = this.formatedOptions.filter(f => this.selected.includes(f.value.toString()));
                } else {
                    filter = this.formatedOptions.find(f => this.selected == f.value.toString());
                }

                return filter;
            },

            formatedOptions() {
                let options = [];

                if (this.withEmpty) {
                    options.push({
                        value: '',
                        label: '-',
                    });
                }

                switch (this.optionType) {
                    case 'bool':
                        options.push(...this.boolOptions);
                        break;

                    case 'years':
                        options.push(...this.yearOptions);
                        break;

                    case 'entities':
                        options.push(...this.dynamicOptions);
                        break;

                    default:
                        if (this.sorting) {
                            options.push(...sortBy(this.dynamicOptions, 'label'));
                        } else {
                            options.push(...this.dynamicOptions);
                        }

                        break;
                }

                options = options.map(option => {
                    return {
                        label: option.label,
                        value: option.value.toString(),
                        icon: option.icon,
                    };
                });

                return uniqBy(options, 'value');
            },

            boolOptions() {
                return [
                    {
                        label: this.$t('general.yes'),
                        value: 'yes',
                    },
                    {
                        label: this.$t('general.no'),
                        value: 'no',
                    },
                ];
            },

            yearOptions() {
                const fromYear = 1899; // fromYear is exclusive
                const toYear = Moment.now().year() + 3;
                const years = range(toYear, fromYear, -1);

                return years.map(year => {
                    return {
                        label: year,
                        value: year,
                    };
                });
            },

            dynamicOptions() {
                return transform(
                    this.options,
                    (array, option) => {
                        // Only add options without condition or with condition evaluated to true
                        if (!option.hasOwnProperty('condition') || option.condition) {
                            const value = option.value || option;
                            let translation = '';

                            if (this.baseTrans && !option.text) {
                                let translationKey = `${this.baseTrans}.${value}`;

                                if (!this.$te(translationKey)) {
                                    translationKey = `${this.baseTrans}.${camelCase(value)}`;
                                }

                                translation = this.$tc(translationKey, 1);
                            }

                            const icon = option.icon || null;

                            array.push({
                                label: option.text || translation || value,
                                value,
                                icon,
                            });
                        }
                    },
                    [],
                );
            },
        },

        watch: {
            filtersBuffer: {
                deep: true,
                immediate: true,
                handler(newValue) {
                    this.selected = newValue[this.columnName] || [];
                },
            },
        },

        methods: {
            updateFilters(filter) {
                let value;

                if (this.multiple) {
                    if (filter.some(f => f.value == 'all') && !this.selectedFilter.some(f => f.value == 'all')) {
                        filter = cloneDeep(this.formatedOptions);
                    } else if (!filter.some(f => f.value == 'all') && this.selectedFilter.some(f => f.value == 'all')) {
                        filter = [];
                    }

                    value = filter.filter(f => f.value != 'all').map(f => f.value);
                } else {
                    value = filter.value;
                }

                this.$emit('update', {
                    column: this.columnName,
                    value,
                });
            },

            limitText(count) {
                return this.$t('selectpicker.xSelect', [count]);
            },
        },
    };
</script>
