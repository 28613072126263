<template>
    <div class="box | mb-6" v-if="isVisible">
        <div class="box-header | flex items-center justify-between">
            <div class="flex items-center">
                <h4 class="box-title" v-text="boxTitle" />
                <activix-select
                    class="ml-3"
                    size="small"
                    identifier="key"
                    label="text"
                    :autosize="true"
                    :options="filterOptions"
                    :value="selectedFilterKey"
                    @input="updateSelectedFilter"
                    @visible-change="visibleChangedFilter"
                >
                    <el-option
                        :label="filter.text"
                        :value="filter.key"
                        :disabled="filter.disabled"
                        :key="filter.key"
                        v-for="filter in filterOptions"
                    >
                        <activix-tooltip
                            :content="disabledEndContractTooltip"
                            v-if="filter.key == 'end_contract_year_month' && filter.disabled"
                        >
                            {{ filter.text }}
                        </activix-tooltip>
                    </el-option>
                </activix-select>
                <div class="flex items-center ml-4" v-if="showSubLevelToggle">
                    <stats-table-toggle
                        :current-key="toggle.key"
                        :active-key="filters.subLevelKey || ''"
                        :icon="icons[toggle.icon]"
                        :tooltip="toggle.tooltip"
                        :key="toggle.key"
                        @click="setSubLevelKey"
                        v-for="toggle in subLevelToggleOptions"
                    />
                </div>
            </div>
            <div class="flex items-center">
                <activix-tooltip
                    :content="sourceToggleTooltip"
                    v-if="showSourceToggle"
                >
                    <activix-switcher
                        @input="updateSelectedFilter(filterByApprovedSource ? 'approved_source' : 'source')"
                        v-model="filterByApprovedSource"
                    />
                </activix-tooltip>
                <activix-tooltip :content="showUserWithoutLeadTooltip" v-if="showUserWithoutLeadToggle && false">
                    <activix-switcher
                        size="small"
                        :value="userWithoutLead"
                        @click.native.prevent="userWithoutLead = !userWithoutLead"
                    />
                </activix-tooltip>
                <div class="ml-4 link-grey" @click="$eventBus.$emit('toggle-expanded', 'statsTable')">
                    <icon :name="$icons.expand" v-if="!expanded.statsTable" />
                    <icon class="spin-inverse" :name="$icons.loading" v-else-if="isLoading" />
                    <icon :name="$icons.collapse" v-else />
                </div>
            </div>
        </div>
        <div class="box-body p-0" :class="{ loading: isLoading }" v-if="expanded.statsTable">
            <component
                :is="component"
                :table-data="tableData"
                :is-loading="isLoading"
                :name-column-text="selectedFilterText"
                :filters="filters"
                :show-activity-columns="showActivityColumns"
                :show-sub-level-activities="showSubLevelActivities"
                :show-sub-level="showSubLevel"
                @sort-apply="reSortSubAggregations"
            />
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'pinia';
    import { format, utcToZonedTime, toDate } from 'date-fns-tz';
    import { getWeek, getWeekYear } from 'date-fns';
    import { orderBy } from 'lodash-es';

    import ActivixDate from '@/value-objects/ActivixDate.js';

    // Components
    import AllLead from './AllLead.vue';
    import WebOrder from './WebOrder.vue';
    import LeadXpress from './LeadXpress.vue';
    import WalkIn from './WalkIn.vue';
    import PhoneUp from './PhoneUp.vue';
    import Renewal from './Renewal.vue';
    import WebBoost from './WebBoost.vue';
    import Loyalty from './Loyalty.vue';
    import Event from './Event.vue';
    import Commercial from './Commercial.vue';
    import Service from './Service.vue';
    import StatsTableToggle from '../../components/dashboards/StatsTableToggle.vue';

    // Mixins
    import DashboardColumns from '../../mixins/DashboardColumns.js';

    // Value Objects
    import StatsTableOptions from '../../value-objects/StatsTableOptions.js';

    // Entities
    import DashboardType from '../../entities/DashboardType.js';

    // Pinia
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        name: 'StatsTableBase',

        components: {
            LeadXpress,
            WebOrder,
            AllLead,
            WalkIn,
            PhoneUp,
            Renewal,
            WebBoost,
            Loyalty,
            Event,
            Commercial,
            Service,
            StatsTableToggle,
        },

        mixins: [DashboardColumns],

        props: {
            dashboard: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                filters: {
                    selectedKey: '',
                    subLevelKey: '',
                },
                userWithoutLead: false,
                filterByApprovedSource: false,
                icons: {
                    advisor: this.$icons.advisor,
                    bdc: this.$icons.bdc,
                    commercialDirector: this.$icons.commercialDirector,
                    takeOver: this.$icons.takeOver,
                    account: this.$icons.account,
                },
                tableData: [],
                activities: [],
            };
        },

        computed: {
            ...mapState(useGlobalStore, {
                authUser: 'authUser',
                groupDisplay: 'groupDisplay',
                currentLocale: store => store.configs.locale,
            }),
            ...mapState(useContextStore, {
                contextAccount: 'account',
                contextUser: 'user',
                contextGroup: 'group',
                contextTeam: 'contextTeam',
            }),
            ...mapState(useDashboardStore, {
                endDate: 'endDate',
                dashboardConfigs: 'configs',
                expanded: store => store.configs.expanded,
                filteredDates: 'filteredDates',
                filteredCampaigns: 'filteredCampaigns',
                options: store => store.configs.options,
                startDate: 'startDate',
            }),

            isVisible() {
                return this.$feature.isEnabled('statstable-visible') || !this.contextUser.id || (this.groupDisplay && !this.authUser.hasAdvisorSkills());
            },

            showSubLevelActivities() {
                return this.filters.subLevelKey == 'account_id';
            },

            sourceToggleTooltip() {
                return !this.filterByApprovedSource ? this.$t('dashboards.clickHereToGroupByApprovedSource') : this.$t('dashboards.clickHereToGroupByClientSource');
            },

            showSourceToggle() {
                return !this.contextAccount.display_approved_sources &&
                    (this.filters.selectedKey == 'source' || this.filters.selectedKey == 'approved_source');
            },

            component() {
                switch (this.dashboard) {
                    case DashboardType.ALL:
                        return 'all-lead';

                    case DashboardType.WEB_ORDER:
                        return 'web-order';

                    case DashboardType.LEAD_XPRESS:
                        return 'lead-xpress';

                    case DashboardType.WALK_IN:
                        return 'walk-in';

                    case DashboardType.PHONE_UP:
                        return 'phone-up';

                    case DashboardType.RENEWAL:
                        return 'renewal';

                    case DashboardType.LOYALTY:
                        return 'loyalty';

                    case DashboardType.WEB_BOOST:
                        return 'web-boost';

                    case DashboardType.EVENT:
                        return 'event';

                    case DashboardType.COMMERCIAL:
                        return 'commercial';

                    case DashboardType.SERVICE:
                        return 'service';

                    default:
                        return '';
                }
            },

            isLoading() {
                return this.$wait.is('fetching.statsTable') && !this.$wait.is('changingDashboard');
            },

            boxTitle() {
                switch (this.dashboard) {
                    case DashboardType.WEB_ORDER:
                        return this.groupDisplay
                            ? this.$t('dashboards.webOrderByAccount')
                            : this.$t('dashboards.webOrderBy');

                    case DashboardType.WALK_IN:
                        return this.groupDisplay
                            ? this.$t('dashboards.walkInByAccount')
                            : this.$t('dashboards.walkInBy');

                    case DashboardType.PHONE_UP:
                        return this.groupDisplay
                            ? this.$t('dashboards.phoneUpByAccount')
                            : this.$t('dashboards.phoneUpBy');

                    case DashboardType.ALL:
                    case DashboardType.RENEWAL:
                    case DashboardType.COMMERCIAL:
                    case DashboardType.LOYALTY:
                    case DashboardType.SERVICE:
                    case DashboardType.SALE:
                    case DashboardType.EVENT:
                        return this.groupDisplay
                            ? this.$t('dashboards.clientByAccount')
                            : this.$t('dashboards.clientBy');

                    default:
                        return this.groupDisplay ? this.$t('dashboards.leadByAccount') : this.$t('dashboards.leadBy');
                }
            },

            showActivityColumns() {
                return ['user_id', 'bdc_user_id', 'commercial_id', 'take_over_director_id'].includes(
                    this.selectedFilterKey,
                );
            },

            selectedFilter() {
                if (this.filters.selectedKey) {
                    return this.filterOptions.find(option => option.key == this.filters.selectedKey);
                }

                if (!this.filterOptions.length) {
                    if (this.dashboard == 'service') {
                        return {
                            key: 'serviceAgent',
                            text: this.$t('leadFilter.serviceAgent'),
                            noneLabel: this.$t('leadFilter.noServiceAgent'),
                            path: 'service_agent_user',
                        };
                    }

                    return {
                        key: 'user_id',
                        text: this.$t('leadFilter.advisor'),
                        noneLabel: this.$t('leadFilter.noAdvisor'),
                        path: 'user',
                    };
                }

                return this.filterOptions.find(option => {
                    return option.default || option.default?.includes(this.dashboard);
                });
            },

            userFilterSelected() {
                return StatsTableOptions.userAggregations().includes(this.selectedFilterKey);
            },

            selectedFilterKey() {
                if (!this.selectedFilter) {
                    return '';
                }

                return this.selectedFilter.key;
            },

            selectedFilterText() {
                if (!this.selectedFilter) {
                    return '';
                }

                return this.selectedFilter.text;
            },

            disabledEndContractTooltip() {
                return this.$t('renewal.disabledEndContractFilterOption');
            },

            showSubLevelToggle() {
                return (
                    !this.groupSelected &&
                    this.expanded.statsTable &&
                    !!this.subLevelToggleOptions.length &&
                    this.dashboard != DashboardType.SERVICE
                );
            },

            showSubLevel() {
                return (
                    !!this.filters.subLevelKey &&
                    this.dashboard != DashboardType.SERVICE &&
                    ((!this.userFilterSelected && !this.groupSelected) ||
                        (!['account'].includes(this.selectedFilterKey) && this.groupDisplay))
                );
            },

            filterOptions() {
                const filteredOption = StatsTableOptions.getSelectOptions(this.dashboard).filter(option => {
                    if (!option.visibleForGroups && !option.hiddenForGroups) {
                        return true;
                    }

                    if (option.visibleForGroups) {
                        return this.groupDisplay;
                    }

                    return !this.groupSelected;
                });

                return filteredOption.map(option => {
                    if (option.key == 'source' && (this.filterByApprovedSource || this.contextAccount.display_approved_sources)) {
                        option.key = 'approved_source';
                    }

                    if (option.key == 'endContractDate') {
                        if (this.filteredDates.length > 1) {
                            option.disabled = true;
                            return option;
                        }

                        const hasContractDate = this.filteredDates.find(date => {
                            return date == 'end_contract_date';
                        });

                        if (!hasContractDate) {
                            option.disabled = true;
                            return option;
                        }
                    }

                    return option;
                });
            },

            subLevelToggleOptions() {
                return StatsTableOptions.getSubLevelToggleOptions(
                    this.selectedFilterKey,
                    this.dashboard,
                    this.accountManagerSelected,
                    this.groupSelected,
                );
            },

            accountManagerSelected() {
                return this.groupDisplay && !this.contextGroup.id;
            },

            groupSelected() {
                return this.groupDisplay && !!this.contextGroup.id;
            },

            showUserWithoutLeadTooltip() {
                return StatsTableOptions.showUsersWithoutLeads(this.userWithoutLead, this.dashboard, this.groupDisplay);
            },

            showUserWithoutLeadToggle() {
                return (
                    StatsTableOptions.userAggregations().includes(this.selectedFilterKey) &&
                    this.expanded.statsTable &&
                    !this.isLoading
                );
            },

            users() {
                const users = this.contextAccount.users;

                this.contextAccount.children.forEach(child => {
                    child.users.forEach(user => {
                        users.push(user);
                    });
                });

                return users;
            },

            payload() {
                const payload = {
                    dashboard: this.dashboard,
                    endDate: this.parsedEndDate.toDateTimeString(),
                    startDate: this.parsedStartDate.toDateTimeString(),
                    filteredDates: this.filteredDates,
                    accountManagerSelected: this.accountManagerSelected,
                    options: this.options,
                    mainAggregation: this.formattedSelectedKey,
                    filters: this.activeDashboardFilters,
                    teamId: this.contextTeam.id,
                    userId: this.contextUser.id,

                };

                if (this.groupSelected) {
                    payload.groupId = this.contextGroup.id;
                } else {
                    if (this.filters.subLevelKey) {
                        payload.subAggregation = this.filters.subLevelKey;
                    }

                    payload.accountId = this.contextAccount.id;
                }

                if (this.dashboard == DashboardType.EVENT) {
                    payload.filteredCampaigns = this.filteredCampaigns;
                }

                return payload;
            },

            formattedSelectedKey() {
                const key = this.filters.selectedKey;

                const userKeys = ['user_id', 'service_agent_id', 'service_advisor_id', 'commercial_id', 'bdc_user_id'];

                if (this.groupDisplay && userKeys.includes(key)) {
                    return `parent_${key}`;
                }

                if (!this.groupDisplay && key.includes('parent_')) {
                    return key.replace('parent_', '');
                }

                if (key == 'lead_form:localized') {
                    return `${key}.${this.currentLocale}.raw`;
                }

                return key;
            },

            mainAggregationFormat() {
                const mainAggregationType = this.formattedSelectedKey;

                if (StatsTableOptions.userAggregations().includes(mainAggregationType)) {
                    return 'user';
                }

                if (
                    [
                        'lead_form:localized.en.raw',
                        'lead_form:localized.fr.raw',
                        'provider',
                        'source',
                        'approved_source',
                    ].includes(mainAggregationType)
                ) {
                    return 'idOrNone';
                }

                if (['created_at:day_of_week'].includes(mainAggregationType)) {
                    return 'day_of_week';
                }

                if (mainAggregationType.endsWith(':month')) {
                    return 'month';
                }

                if (mainAggregationType.endsWith(':year')) {
                    return 'year';
                }

                if (mainAggregationType.endsWith(':week')) {
                    return 'week';
                }

                if (['division'].includes(mainAggregationType)) {
                    return 'division';
                }

                if (
                    [
                        'first_wanted_vehicle.make',
                        'first_wanted_vehicle.model',
                        'first_exchange_vehicle.model',
                        'first_exchange_vehicle.make',
                        'end_contract_year_month',
                    ].includes(mainAggregationType) ||
                    mainAggregationType.endsWith(':year') ||
                    mainAggregationType.endsWith(':month') ||
                    mainAggregationType.endsWith(':week')
                ) {
                    return 'id';
                }

                return mainAggregationType;
            },
        },

        watch: {
            'expanded.statsTable': {
                immediate: true,
                handler() {
                    this.fetchStats();
                },
            },

            filters: {
                deep: true,
                handler() {
                    this.fetchStats();
                    this.updateStatsTableFilter();
                },
            },

            selectedFilterKey(newKey, oldKey) {
                if (!oldKey || newKey == oldKey) {
                    return;
                }

                if (!newKey && oldKey.endsWith(':week')) {
                    this.updateSelectedFilter(this.filterOptions.find(option => option.key.endsWith(':week')).key);
                }

                if (!newKey && oldKey.endsWith(':month')) {
                    this.updateSelectedFilter(this.filterOptions.find(option => option.key.endsWith(':month')).key);
                }

                if (!newKey && oldKey.endsWith(':year')) {
                    this.updateSelectedFilter(this.filterOptions.find(option => option.key.endsWith(':year')).key);
                }
            },

            'contextAccount.id'() {
                this.setInitialFilterKey();
                this.updateSubLevelKeys();
            },

            'contextGroup.id'() {
                this.setInitialFilterKey();
                this.updateSubLevelKeys();
            },
        },

        methods: {
            ...mapActions(useDashboardStore, [
                'setDashboardConfigs',
            ]),

            getNoneValue(key = '') {
                const value = key || this.formattedSelectedKey;

                switch (value) {
                    case 'user_id':
                    case 'parent_user_id':
                        return this.$t('leadFilter.noAdvisor');

                    case 'bdc_user_id':
                    case 'parent_bdc_user_id':
                        return this.$t('leadFilter.noBdcUser');

                    case 'commercial_id':
                    case 'parent_commercial_id':
                        return this.$t('leadFilter.noCommercial');

                    case 'service_agent_id':
                    case 'parent_service_agent_id':
                        return this.$t('leadFilter.noServiceAgent');

                    case 'take_over_director_id':
                    case 'parent_take_over_director_id':
                        return this.$t('leadFilter.noTakeOverDirector');

                    case 'lead_form:localized.en.raw':
                    case 'lead_form:localized.fr.raw':
                        return this.$t('leadFilter.noLeadForm');

                    case 'provider':
                        return this.$t('leadFilter.noProvider');

                    case 'source':
                        return this.$t('leadFilter.noSource');

                    case 'approved_source':
                        return this.$t('leadFilter.noApprovedSource');

                    case 'first_wanted_vehicle.make':
                        return this.$t('leadFilter.noWantedVehicleMake');

                    case 'first_wanted_vehicle.model':
                        return this.$t('leadFilter.noWantedVehicleModel');

                    case 'first_exchange_vehicle.model':
                        return this.$t('leadFilter.noExchangeVehicleModel');

                    case 'first_exchange_vehicle.make':
                        return this.$t('leadFilter.noExchangeVehicleMake');

                    case 'end_contract_year_month':
                        return this.$t('leadFilter.noEndContractDate');

                    case 'service_advisor_id':
                        return this.$t('leadFilter.noServiceAdvisor');

                    case 'division':
                        return this.$t('leadFilter.noDivision');

                    default:
                        return this.$t('general.none');
                }
            },

            setInitialFilterKey() {
                if (
                    this.filters.selectedKey &&
                    !!this.filterOptions.find(option => option.key == this.filters.selectedKey)
                ) {
                    return;
                }

                const option = StatsTableOptions.filterOptions.find(option => {
                    return (
                        (!this.groupSelected && !!option.default && option.default.includes(this.dashboard)) ||
                        (this.groupSelected && option.visibleForGroups)
                    );
                });

                this.filters.selectedKey = option.key;
            },

            setStatsTableFilter(filter) {
                this.updateSelectedFilter(filter.selectedKey);
                this.setSubLevelKey(filter.subLevelKey);
            },

            updateSelectedFilter(value) {
                this.$behavior.track('StatsTable', { action: 'leadby-select', dashboard: this.dashboard, leadby: value });

                this.filters.selectedKey = this.filterOptions.find(option => option.key == value).key;

                if (!this.expanded.statsTable) {
                    this.$eventBus.$emit('toggle-expanded', 'statsTable');
                }

                this.updateSubLevelKeys();
            },

            updateStatsTableFilter() {
                this.setDashboardConfigs({
                    statsTableFilter: {
                        selectedKey: this.formattedSelectedKey,
                        subLevelKey: this.filters.subLevelKey,
                    },
                });
            },

            visibleChangedFilter(visible) {
                if (visible) {
                    this.$behavior.track('StatsTable', { action: 'leadby-open', dashboard: this.dashboard });
                }
            },
            setSubLevelKey(value) {
                this.filters.subLevelKey = this.subLevelToggleOptions.find(option => option.key == value)?.key;
            },

            updateSubLevelKeys() {
                if (!this.subLevelToggleOptions.length) {
                    this.filters.subLevelKey = '';
                    return;
                }

                if (!this.subLevelToggleOptions.find(option => option.key == this.filters.subLevelKey)) {
                    this.filters.subLevelKey = this.subLevelToggleOptions[0].key;
                }
            },

            async fetchStats({ background = false } = {}) {
                if (!this.isVisible || !this.expanded.statsTable || !this.payload.startDate || !this.payload.endDate || !this.payload.mainAggregation) {
                    return;
                }

                if (!background) {
                    this.$wait.start('fetching.statsTable');
                }

                try {
                    const response = await this.$api.dashboard.getStats(this.payload);

                    if (response) {
                        await this.formatResponse(response);

                        this.$wait.end('fetching.statsTable');
                    }
                } catch (error) {
                    this.$notify.warning(this.$t('dashboards.alerts.stats.error'));
                    this.$wait.end('fetching.statsTable');
                }
            },

            async formatResponse(response) {
                this.tableData = [];

                Object.keys(response).forEach(key => {
                    const row = response[key];

                    row.name = this.formatMainAggregationName(key, row);
                    row.redirect = this.userFilterSelected && row.name != 'none';

                    if (!row.name || row.name == 'none') {
                        row.name = this.getNoneValue();
                    }

                    row.expanded = false;

                    if (row.sub_aggregations) {
                        Object.keys(row.sub_aggregations).forEach(subAggregationType => {
                            Object.keys(row.sub_aggregations[subAggregationType]).forEach(subAggregationRowKey => {
                                const subRowId = subAggregationRowKey;
                                let subName = this.formatSubAggregationName(
                                    subAggregationType,
                                    subAggregationRowKey,
                                    subRowId,
                                );

                                let redirect = false;

                                if (!subName || !subName.trim || subName == 'none') {
                                    subName = this.getNoneValue(subAggregationType);
                                } else {
                                    redirect = StatsTableOptions.userAggregations().includes(subAggregationType);
                                }

                                row.sub_aggregations[subAggregationType][subRowId].redirect = redirect;
                                row.sub_aggregations[subAggregationType][subRowId].name = subName;
                            });
                            row.sub_aggregations[subAggregationType] = this.sortSubAggregationItems(
                                row.sub_aggregations[subAggregationType],
                            );
                        });
                    }

                    const matchingRow = this.tableData.find(match => match.id == row.id);

                    if (!matchingRow || this.groupSelected) {
                        this.tableData.push(row);
                    } else if (row.sub_aggregations) {
                        Object.keys(row.sub_aggregations).forEach(subAggregation => {
                            this.tableData.find(existingRow => existingRow.id == row.id).sub_aggregations[
                                subAggregation
                            ] = row.sub_aggregations[subAggregation];
                        });
                    }
                });

                await this.updateActivity();
            },

            async updateActivity() {
                const shouldFetchActivity = StatsTableOptions.getSelectOptions(this.dashboard).some(option => {
                    return option.getActivityData && option.key == this.selectedFilterKey;
                });

                if (!shouldFetchActivity) {
                    this.activities = [];
                    return;
                }

                const response = await this.$axios.get('v1/users/activity', {
                    params: {
                        users: this.tableData.map(index => index.id).filter(user => user != 'none'),
                        group: this.contextGroup.id,
                        startDate: this.startDate,
                        endDate: this.endDate,
                    },
                });

                this.activities = response.data.data;

                if (empty(this.activities)) {
                    return;
                }

                this.tableData.forEach(user => {
                    user.total_activities = this.activities[user.id] || 0;
                });
            },

            getUserName(userId) {
                const currentUser = this.users.find(user => user.id == userId);

                return currentUser ? currentUser.fullName : 'none';
            },

            getUserAccountName(userId) {
                const currentUser = this.users.find(user => user.id == userId);

                const account = this.contextAccount.children.find(account => account.id == currentUser.account_id);

                if (account) {
                    return ` (${account.name})`;
                }

                return '';
            },

            getDivisionName(division) {
                if (division) {
                    return division != 'none' ? this.$t(`divisions.${division}`) : 'none';
                }

                return 'none';
            },

            getSourceName(sourceId) {
                const source = this.contextAccount.sources.find(source => source.id == sourceId);

                return source ? source.name : 'none';
            },

            getAccountName(accountId) {
                if (this.groupDisplay) {
                    const accounts = this.accountManagerSelected
                        ? this.contextAccount.children
                        : this.contextGroup.accounts;
                    const account = accounts.find(account => account.id == accountId);

                    if (account) {
                        return account.name;
                    }
                }

                return this.contextAccount.name;
            },

            getSubLevelAccountName(accountId) {
                if (accountId == this.contextAccount.id) {
                    return this.contextAccount.name;
                }

                return this.contextAccount.children.find(childAccount => childAccount.id == accountId).name || '';
            },

            getSubAggregationFormat(type) {
                if (StatsTableOptions.userAggregations().includes(type)) {
                    return 'user';
                }

                return type;
            },

            formatMainAggregationName(key, row) {
                switch (this.mainAggregationFormat) {
                    case 'user':
                        return this.getUserName(key);

                    case 'account_id':
                        return this.getAccountName(key);

                    case 'idOrNone':
                        return row.id ? row.id : 'none';

                    case 'day_of_week':
                        return this.$t(`weekDay.${row.id}`);

                    case 'month':
                        return this.formatTime(row.id);

                    case 'week':
                        return this.formatWeek(row.id);

                    case 'year':
                        return this.formatTime(row.id, 'yyyy');

                    case 'division':
                        return this.getDivisionName(key);

                    case 'id':
                        return row.id;
                }

                return '';
            },

            formatTime(value, timeFormat = 'yyyy-MM') {
                if (!value || value === 'none') {
                    return value;
                }

                return format(utcToZonedTime(value), timeFormat);
            },

            formatSubAggregationName(subAggregationType, subAggregationRowKey, subRowId) {
                switch (this.getSubAggregationFormat(subAggregationType)) {
                    case 'user':
                        if (subRowId != 'none') {
                            if (!this.accountManagerSelected) {
                                return this.getUserName(subAggregationRowKey);
                            }
                            return `${this.getUserName(subAggregationRowKey)} ${this.getUserAccountName(
                                subAggregationRowKey,
                            )}`;
                        }
                        break;

                    case 'account_id':
                        return this.getSubLevelAccountName(subAggregationRowKey);
                }

                return 'none';
            },

            sortSubAggregationItems(row, sorting) {
                const keys = [];

                Object.keys(row).forEach(key => {
                    keys.push(row[key]);
                });

                const sortedRows = orderBy(keys, sorting?.prop || 'name', (sorting?.order || 'ascending') == 'ascending' ? 'asc' : 'desc');

                const sortedSubAggregations = {};

                sortedRows.forEach((sortedRow, index) => {
                    const rowId = sortedRow.id == 'none' ? sortedRows.length : index;

                    sortedSubAggregations[rowId] = sortedRow;
                });

                return sortedSubAggregations;
            },

            reSortSubAggregations(newSorting) {
                this.tableData.forEach(row => {
                    if (row.sub_aggregations) {
                        Object.keys(row.sub_aggregations).forEach(subAggregationType => {
                            row.sub_aggregations[subAggregationType] = this.sortSubAggregationItems(
                                row.sub_aggregations[subAggregationType],
                                newSorting,
                            );
                        });
                    }
                });
            },

            formatWeek(weekId) {
                if (weekId == 'none') {
                    return weekId;
                }

                let nbrWeekOfYear = getWeek(weekId);
                const weekDate = new ActivixDate(toDate(utcToZonedTime(weekId), { timeZone: 'UTC' }), 'date');

                if (nbrWeekOfYear < 10) {
                    nbrWeekOfYear = `0${nbrWeekOfYear}`;
                }

                return `${getWeekYear(weekId)} ${this.$tc('delays.week', 1)} ${nbrWeekOfYear} (${weekDate.format('dd MMM')})`;
            },
        },

        mounted() {
            this.$eventBus.$on('set-stats-table-filter', this.setStatsTableFilter);
        },

        beforeDestroy() {
            this.$eventBus.$off('set-stats-table-filter', this.setStatsTableFilter);
        },

        activated() {
            this.setInitialFilterKey();
            this.updateSubLevelKeys();
        },
    };
</script>
