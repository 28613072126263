<template>
    <div>
        <template v-if="productDetails">
            {{ productDetails }}
        </template>
        <activix-tooltip :content="$t('service.tooltip.product_checkbox_disabled')" v-else>
            <span> - </span>
        </activix-tooltip>
    </div>
</template>

<script>
    /* eslint-disable vue/require-prop-types */
    // Utils
    import { round } from 'lodash-es';
    import { toMoney } from '../../../utils/index.js';

    export default {
        props: ['lead', 'productName', 'productType', 'size', 'color'],

        computed: {
            checked() {
                return this.product && this.product.pivot.sold;
            },

            product() {
                return this.lead.products.find(product => {
                    return product.type == this.productType && product.name == this.productName;
                });
            },

            productPrice() {
                if (!this.product) {
                    return null;
                }

                return this.product.pivot.price;
            },

            productMinutes() {
                if (!this.product) {
                    return null;
                }

                return this.product.pivot.minutes;
            },

            productDetails() {
                if (!this.checked) {
                    return '';
                }

                let productDetails = '';

                if (this.productPrice) {
                    productDetails += toMoney(this.productPrice);
                }

                if (this.productMinutes) {
                    if (productDetails) {
                        productDetails += ' / ';
                    }

                    productDetails += this.timeHms(round(this.productMinutes, 2) * 60, true);
                }

                return productDetails;
            },
        },
    };
</script>
