/* eslint-disable global-require */
const defaultTheme = require('tailwindcss/defaultTheme');

const defaultSizing = {
    px: '1px',
    '2px': '2px',
    '3px': '3px',
    '36px': '36px',

    0: '0',
    0.5: '0.125rem',
    1: '0.25rem',
    1.5: '0.375rem',
    2: '0.5rem',
    2.5: '0.625rem',
    3: '0.75rem',
    3.5: '0.875rem',
    4: '1rem',
    4.5: '1.125rem',
    5: '1.25rem',
    6: '1.5rem',
    7: '1.75rem',
    8: '2rem',
    9: '2.25rem',
    10: '2.5rem',
    11: '2.75rem',
    12: '3rem',
    13: '3.25rem',
    14: '3.5rem',
    15: '3.75rem',
    16: '4rem',
    18: '4.5rem',
    20: '5rem',
    21: '5.25rem',
    22: '5.5rem',
    24: '6rem',
    26: '6.5rem',
    27: '6.75rem',
    28: '7rem',
    30: '7.5rem',
    32: '8rem',
    34: '8.5rem',
    36: '9rem',
    40: '10rem',
    48: '12rem',
    56: '14rem',
    64: '16rem',
    80: '20rem',
    96: '24rem',
    104: '26rem',
    128: '32rem',

    '1/2': '50%',
    '1/3': '33.33333%',
    '2/3': '66.66667%',
    '1/4': '25%',
    '3/4': '75%',
    '10/12': '83.33333%',

    xs: '25rem',
    sm: '30rem',
    md: '40rem',
    lg: '50rem',
    xl: '60rem',
    '2xl': '70rem',
    '3xl': '80rem',
    '4xl': '90rem',
    '5xl': '100rem',
};

const heights = {
    'screen-50': '50vh',
    'screen-75': '75vh',
    'screen-80': '80vh',

    'main-view': 'calc(100vh - 7.5rem)',
};

module.exports = {
    mode: 'jit',
    darkMode: false,
    purge: [
        './public/index.html',
        './src/**/*.{vue,js,ts,jsx,tsx}',
    ],
    presets: [
        require('@activix/ui/configs/tailwind-preset.js'),
    ],
    theme: {
        screens: {
            xs: '576px',
            sm: '640px',
            md: '768px',
            lg: '1024px',
            xl: '1280px',
            '2xl': '1536px',
            '3xl': '1920px', // Full HD
            print: { raw: 'print' },
        },

        colors: require('./tailwind.colors.js'),

        container: {
            center: true,
            padding: {
                DEFAULT: '2rem',
                sm: '4rem',
                md: '4rem',
                lg: '6rem',
                xl: '6rem',
            },
        },

        extend: {
            boxShadow: {
                inner: 'inset 0 0px 5px 2px rgba(0, 0, 0, 0.09)',
                'inner-sm': 'inset 0 0px 5px rgba(0, 0, 0, 0.1)',

                // Right
                'r-lg': '10px 0 15px -3px rgba(0, 0, 0, 0.1), 4px 0 6px -2px rgba(0, 0, 0, 0.05)',

                // Left
                'l-md': '-5px 0 15px -3px rgba(0, 0, 0, 0.1), -2px 0 6px -2px rgba(0, 0, 0, 0.05)',
                'l-lg': '-10px 0 15px -3px rgba(0, 0, 0, 0.1), -4px 0 6px -2px rgba(0, 0, 0, 0.05)',

                // Top
                't-md': '0 -5px 15px -3px rgba(0, 0, 0, 0.05), 0 -1px 6px -2px rgba(0, 0, 0, 0.05)',
            },

            fontFamily: {
                sans: ['"Source Sans Pro"', ...defaultTheme.fontFamily.sans],
                mono: ['"Source Code Pro"', ...defaultTheme.fontFamily.mono],
            },

            fontSize: {
                '2xs': '0.65rem',
            },

            transitionProperty: {
                spacing: 'margin, padding',
            },

            spacing: defaultSizing,

            width: defaultSizing,

            minWidth: defaultSizing,

            maxWidth: defaultSizing,

            height: {
                ...defaultSizing,
                ...heights,
            },

            minHeight: {
                ...defaultSizing,
                ...heights,
            },

            maxHeight: {
                ...defaultSizing,
                ...heights,
            },

            animation: {
                'slide-out': 'slide-out 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite both',
                'slide-in': 'slide-in 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite both',
            },

            keyframes: {
                'slide-out': {
                    '0%': { transform: 'translateY(0) translateX(0)', opacity: 1 },
                    '100%': { transform: 'translateY(-4px) translateX(4px)', opacity: 0 },
                },
                'slide-in': {
                    '0%': { transform: 'translateY(-4px) translateX(4px)', opacity: 0 },
                    '100%': { transform: 'translateY(0) translateX(0)', opacity: 1 },
                },
            },
        },
    },

    plugins: [
        require('@tailwindcss/line-clamp'),
        require('@tailwindcss/aspect-ratio'),
    ],
};
