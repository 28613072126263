<template>
    <div>
        <div class="flex flex-wrap lg:-mx-3">
            <card-small
                class="px-3 w-full | xs:w-1/2"
                :class="smallCardClass"
                :number="card.number"
                :title="card.title"
                :back-number="card.backNumber"
                :back-title="card.backTitle"
                :back-icon="card.backIcon"
                :key="card.name"
                v-for="card in activeSmallCards"
            >
                <template #description v-if="card.componentDescription">
                    <component :is="card.componentDescription" :cards="cards" />
                </template>
                <template #description v-else-if="card.description">
                    <div class="flex justify-center text-lg">
                        <div class="text-xl">
                            {{ card.description }}
                        </div>
                    </div>
                </template>
                <template #popover v-if="card.informationTooltip">
                    <div class="text-center" v-text="card.informationTooltip" />
                </template>
            </card-small>
        </div>

        <div class="flex flex-wrap lg:-mx-3">
            <card
                class="px-3 w-full | xs:w-1/3"
                :class="bigCardClass"
                :icon="card.icon"
                :card="card.card"
                :denominator="card.denominator"
                :title="card.title"
                :key="card.name"
                v-for="card in activeBigCards"
            >
                <template #description v-if="card.componentDescription">
                    <component :is="card.componentDescription" :cards="cards" />
                </template>
                <template #description v-else-if="card.description">
                    <div class="flex justify-center text-lg">
                        <div class="text-xl">
                            {{ card.description }}
                        </div>
                    </div>
                </template>
                <template #popover v-if="card.informationTooltip">
                    <div class="text-center" v-text="card.informationTooltip" />
                </template>
            </card>
        </div>

        <dashboard-graphs :dashboard="dashboard" :has-leads="!!cards.walkIn.number.count" ref="graphs" />

        <stats-table :dashboard="dashboard" ref="statsTable" />

    </div>
</template>

<script>
    // Utils
    import { mapState } from 'pinia';

    // Components
    import Card from '../../components/dashboards/cards/Card.vue';
    import CardSmall from '../../components/dashboards/cards/CardSmall.vue';
    import DashboardGraphs from '../../components/dashboards/graphs/DashboardGraphs.vue';
    import StatsTable from '../StatsTables/StatsTable.vue';

    // Mixins
    import TrackView from '../../mixins/TrackView.js';
    import DashboardCaching from '../../mixins/DashboardCaching.js';
    import DashboardCustomFields from '../../mixins/DashboardCustomFields.js';
    import DashboardColumns from '../../mixins/DashboardColumns.js';
    import DashboardCards from '../../mixins/DashboardCards.js';

    // Entities
    import DashboardType from '../../entities/DashboardType.js';

    // Pinia
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        name: 'WalkIn',

        components: {
            Card,
            CardSmall,
            DashboardGraphs,
            StatsTable,
        },

        mixins: [TrackView, DashboardCaching, DashboardCustomFields, DashboardColumns, DashboardCards],

        data() {
            return {
                dashboard: DashboardType.WALK_IN,
                cards: {
                    walkIn: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                        assigned: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                    },
                    roadTest: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                    },
                    takeOver: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                    },
                    beBack: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'blue',
                            },
                        },
                    },
                    saleBeBack: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                                1: 'red',
                                50: 'orange',
                                70: 'green',
                            },
                        },
                    },
                    saleBeBackTakeOver: {
                        number: {
                            count: 0,
                        },
                    },
                    saleUp: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                                1: 'red',
                                21: 'orange',
                                25: 'green',
                            },
                        },
                    },
                    sale: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                                1: 'red',
                                20: 'orange',
                                30: 'green',
                            },
                        },
                    },
                    saleFirstWalk: {
                        number: {
                            count: 0,
                        },
                    },
                    delivered: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'blue',
                            },
                        },
                    },
                    csi: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'blue',
                            },
                        },
                    },
                    freshUp: {
                        number: {
                            count: 0,
                        },
                    },
                    ordersUp: {
                        number: {
                            count: 0,
                        },
                    },
                    ordersBBack: {
                        number: {
                            count: 0,
                        },
                    },
                },
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
                contextTeam: 'contextTeam',
            }),
            ...mapState(useDashboardStore, {
                endDate: 'endDate',
                expanded: store => store.configs.expanded,
                options: store => store.configs.options,
                startDate: 'startDate',
            }),
            smallCards() {
                return {
                    walkIn: {
                        visible: true,
                    },
                    roadTest: {
                        visible: true,
                    },
                    takeOver: {
                        visible: true,
                    },
                    csi: {
                        visible: true,
                    },
                };
            },
            bigCards() {
                if (this.contextAccount.isPolestar()) {
                    return {
                        freshUpWalkIn: {
                            visible: true,
                        },
                        ordersUpWalkIn: {
                            visible: true,
                        },
                        ordersBBackWalKIn: {
                            visible: true,
                        },
                        totalOrdersWalkIn: {
                            visible: true,
                        },
                        deliveredWalkIn: {
                            visible: true,
                        },
                    };
                }

                return {
                    beBack: {
                        visible: true,
                    },
                    saleBeBack: {
                        visible: true,
                    },
                    upSaleWalkIn: {
                        visible: true,
                    },
                    saleWalkIn: {
                        visible: true,
                    },
                    deliveryWalkIn: {
                        visible: true,
                    },
                };
            },

            noBeBackLead() {
                let walkInWithoutBeBack = this.cards.walkIn.number.count;

                if (this.options.doubleWalkIn) {
                    walkInWithoutBeBack = this.cards.walkIn.number.count - this.cards.beBack.number.count;
                }

                return walkInWithoutBeBack;
            },
        },

        methods: {
            async fetchStats(options) {
                await Promise.all([
                    this.$refs.statsTable.fetchStats(options),
                    this.$refs.graphs.fetch(options),
                ]);
            },

            setColumns() {
                useDashboardStore().configs.columns = {
                    bulk_select: {
                        visible: true,
                    },
                    fullname: {
                        visible: true,
                    },
                    contact_method: {
                        visible: true,
                    },
                    civility: {
                        visible: false,
                    },
                    business: {
                        visible: false,
                    },
                    created_by: {
                        visible: false,
                    },
                    updated_by: {
                        visible: false,
                    },
                    'customer:casl_consent_status': {
                        visible: false,
                    },
                    'customer:casl_consent_limit_date': {
                        visible: false,
                    },
                    date: {
                        visible: true,
                    },
                    updated_at: {
                        visible: false,
                    },
                    'division:name': {
                        visible: true,
                    },
                    status: {
                        visible: true,
                    },
                    result: {
                        visible: false,
                    },
                    'lead_type:name': {
                        visible: false,
                    },
                    advisors: {
                        visible: true,
                    },
                    agents: {
                        visible: true,
                    },
                    'commercial:': {
                        visible: false,
                    },
                    'account:name': {
                        visible: true,
                    },
                    rating: {
                        visible: false,
                    },
                    city: {
                        visible: false,
                    },
                    address: {
                        visible: false,
                    },
                    locale: {
                        visible: false,
                    },
                    sector: {
                        visible: false,
                    },
                    budget: {
                        visible: false,
                    },
                    walk_around: {
                        visible: false,
                    },
                    'wanted_vehicles:budget_min': {
                        visible: false,
                    },
                    'wanted_vehicles:budget_max': {
                        visible: false,
                    },
                    created_method: {
                        visible: false,
                    },
                    lead_state: {
                        visible: false,
                    },
                    'import:original_file': {
                        visible: false,
                    },
                    'wanted_vehicles:category': {
                        visible: false,
                    },
                    'wanted_vehicles:category_rv': {
                        visible: false,
                    },
                    'wanted_vehicles:mechanical': {
                        visible: false,
                    },
                    'wanted_vehicles:length_min': {
                        visible: false,
                    },
                    'wanted_vehicles:length_max': {
                        visible: false,
                    },
                    'wanted_vehicles:weight': {
                        visible: false,
                    },
                    'wanted_vehicles:make': {
                        visible: false,
                    },
                    'wanted_vehicles:model': {
                        visible: true,
                    },
                    'wanted_vehicles:year': {
                        visible: true,
                    },
                    'wanted_vehicles:fuel': {
                        visible: false,
                    },
                    'wanted_vehicles:version': {
                        visible: false,
                    },
                    'wanted_vehicles:certified': {
                        visible: false,
                    },
                    'wanted_vehicles:transmission': {
                        visible: false,
                    },
                    'wanted_vehicles:color_exterior': {
                        visible: false,
                    },
                    'wanted_vehicles:color_interior': {
                        visible: false,
                    },
                    'wanted_vehicles:stock_state': {
                        visible: false,
                    },
                    'wanted_vehicles:stock': {
                        visible: false,
                    },
                    'wanted_vehicles:vin': {
                        visible: false,
                    },
                    evaluated: {
                        visible: false,
                    },
                    appointment: {
                        visible: false,
                    },
                    presented: {
                        visible: false,
                    },
                    road_test: {
                        visible: true,
                    },
                    long_term: {
                        visible: false,
                    },
                    dealer_tour: {
                        visible: false,
                    },
                    take_over: {
                        visible: true,
                    },
                    'take_over_director:': {
                        visible: false,
                    },
                    twenty_four_hour: {
                        visible: true,
                    },
                    be_back: {
                        visible: true,
                    },
                    deposit: {
                        visible: false,
                    },
                    write_up: {
                        visible: false,
                    },
                    progress_state: {
                        visible: true,
                    },
                    approved: {
                        visible: true,
                    },
                    'lead_bank:name': {
                        visible: false,
                    },
                    waiting_sale: {
                        visible: false,
                    },
                    sale: {
                        visible: true,
                    },
                    available: {
                        visible: false,
                    },
                    paperwork: {
                        visible: true,
                    },
                    'wanted_vehicles:verified': {
                        visible: false,
                    },
                    prepared: {
                        visible: true,
                    },
                    prepared_work_order: {
                        visible: false,
                    },
                    gas: {
                        visible: false,
                    },
                    deliverable: {
                        visible: false,
                    },
                    delivered: {
                        visible: true,
                    },
                    'wanted_vehicles:recorded_date': {
                        visible: false,
                    },
                    csi: {
                        visible: false,
                    },
                    'exchange_vehicles:category': {
                        visible: false,
                    },
                    'exchange_vehicles:category_rv': {
                        visible: false,
                    },
                    'exchange_vehicles:mechanical': {
                        visible: false,
                    },
                    'exchange_vehicles:length_min': {
                        visible: false,
                    },
                    'exchange_vehicles:length_max': {
                        visible: false,
                    },
                    'exchange_vehicles:weight': {
                        visible: false,
                    },
                    'exchange_vehicles:make': {
                        visible: false,
                    },
                    'exchange_vehicles:model': {
                        visible: false,
                    },
                    'exchange_vehicles:year': {
                        visible: false,
                    },
                    'exchange_vehicles:version': {
                        visible: false,
                    },
                    'exchange_vehicles:certified': {
                        visible: false,
                    },
                    'exchange_vehicles:trade_type': {
                        visible: false,
                    },
                    'exchange_vehicles:transmission': {
                        visible: false,
                    },
                    'exchange_vehicles:color_exterior': {
                        visible: false,
                    },
                    'exchange_vehicles:color_interior': {
                        visible: false,
                    },
                    'exchange_vehicles:condition': {
                        visible: false,
                    },
                    'exchange_vehicles:fuel': {
                        visible: false,
                    },
                    'exchange_vehicles:tire_type': {
                        visible: false,
                    },
                    'exchange_vehicles:accidented_damage_cost': {
                        visible: false,
                    },
                    'exchange_vehicles:value': {
                        visible: false,
                    },
                    'exchange_vehicles:actual_value': {
                        visible: false,
                    },
                    'exchange_vehicles:calculator_appraiser': {
                        visible: false,
                    },
                    'exchange_vehicles:calculator_value': {
                        visible: false,
                    },
                    listening: {
                        visible: false,
                    },
                    followed: {
                        visible: true,
                    },
                    followed_by: {
                        visible: false,
                    },
                    todo: {
                        visible: true,
                    },
                    source: {
                        visible: false,
                    },
                    form: {
                        visible: false,
                    },
                    'latest_comment:content': {
                        visible: true,
                    },
                    unsubscribe: {
                        visible: false,
                    },
                    birth_date: {
                        visible: false,
                    },
                    ...this.getCustomFields(),
                };
            },

            setCards(results) {
                this.cards.walkIn.number.count = results.count;
                this.cards.walkIn.assigned.count = results.assignments;
                this.cards.roadTest.number.count = results.testDrives;
                this.cards.takeOver.number.count = results.takeOvers;
                this.cards.beBack.number.count = results.beBacks;
                this.cards.delivered.number.count = results.deliveries;
                this.cards.csi.number.count = results.csi;
                this.cards.freshUp.number.count = results.freshUp;
                this.cards.sale.number.count = results.sales.count;
                this.cards.saleBeBack.number.count = results.sales.beBack;
                this.cards.saleBeBackTakeOver.number.count = results.sales.beBackTakeOver;
                this.cards.saleFirstWalk.number.count = results.sales.firstWalk;
                this.cards.saleUp.number.count = results.sales.up;

                this.$nextTick(() => {
                    this.setCaching('cards', this.cards);
                });
            },
        },
    };
</script>
