<template>
    <activix-modal
        scrollable
        size="sm"
        :name="name"
        portal="modal-2"
        ref="modal"
        @before-open="onBeforeOpen"
        @closed="onClosed"
    >
        <h4 class="modal-title" v-text="title" slot="header" />

        <div class="space-y-2" slot="body">
            <div
                class="flex p-3 | transition duration-200"
                :class="isBoolean ? 'items-center' : 'flex-col-reverse'"
                :key="index"
                v-for="(vehicleObject, index) in vehicleObjects"
            >
                <custom-input
                    :class="{'mr-4': isBoolean}"
                    :disabled="disabledSave"
                    :field="accountCustomField"
                    @input="onInputUpdate(vehicleObject, fieldValues[index])"
                    @blur="onBlurUpdate(vehicleObject, fieldValues[index])"
                    v-model="fieldValues[index]"
                />

                <div class="text-lg mb-1 cursor-default" v-if="vehicleObject.vehicle">
                    <activix-tooltip :content="tooltip(vehicleObject)" v-if="vehicleObject.vehicle.make || vehicleObject.vehicle.model || vehicleObject.vehicle.year">
                        <div>
                            <span class="font-semibold" v-text="vehicleObject.vehicle.make" />
                            {{ vehicleObject.vehicle.model }}
                            {{ vehicleObject.vehicle.year }}
                        </div>
                    </activix-tooltip>
                    <div class="italic" v-text="$t('dashboards.customFields.unknownVehicle')" v-else />
                    <div class="italic text-gray-600 text-md -mt-2" v-if="isBoolean && vehicleObject.vehicle.stock_state && vehicleObject.vehicle.stock">
                        {{ vehicleObject.vehicle.stock_state }}
                        {{ vehicleObject.vehicle.stock }}
                    </div>
                </div>
            </div>
        </div>

        <div class="flex items-center justify-center" slot="footer">
            <activix-button @click="close" v-text="$t('general.cancel')" />
        </div>
    </activix-modal>
</template>

<script>
    // Lodash
    import { debounce } from 'lodash-es';

    // Components
    import { mapActions } from 'pinia';

    // Entities
    import { useGlobalStore } from '@/store/store.js';
    import CustomInput from '../CustomInput.vue';

    export default {
        components: {
            CustomInput,
        },

        props: {
            name: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                accountCustomField: null,
                vehicleObjects: null,
                customFieldId: null,
                type: '',
                title: '',
                lead: null,
                fieldValues: [],
            };
        },

        computed: {
            disabledSave() {
                return this.guest;
            },

            isBoolean() {
                return this.type === 'boolean';
            },

            noBlurInput() {
                return ['string', 'currency'].includes(this.type);
            },

            isText() {
                return ['string', 'textarea', 'currency'].includes(this.type);
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadVehicleAction']),

            getCustomable(vehicleObject) {
                return vehicleObject.custom_fields.find(customField => customField.id == this.customFieldId);
            },

            onInputUpdate(vehicleObject, value) {
                if (this.noBlurInput) {
                    return;
                }

                this.sendUpdate(vehicleObject, value);
            },

            onBlurUpdate(vehicleObject, value) {
                if (!this.isText) {
                    return;
                }

                this.sendUpdate(vehicleObject, value);
            },

            sendUpdate(vehicleObject, value) {
                if (value && (value[0] === null || value[0] === '')) {
                    value = [];
                }

                this.updateLeadVehicleAction(
                    {},
                    vehicleObject.vehicle.id,
                    this.lead.id,
                    {
                        customField: {
                            id: this.customFieldId,
                            value,
                        },
                    },
                );
            },

            async onBeforeOpen({ lead, vehicleObjects, accountCustomField, title }) {
                this.lead = lead;
                this.customFieldId = accountCustomField.id;
                this.vehicleObjects = vehicleObjects;
                this.accountCustomField = accountCustomField;
                this.type = accountCustomField.type;
                this.title = title;

                this.setAllInputModel(vehicleObjects);

                if (!this.isBoolean) {
                    this.onInputUpdate = debounce(this.onInputUpdate, 200);
                }
            },

            setAllInputModel(vehicleObjects) {
                const defaultValue = this.isBoolean ? [false] : [];

                vehicleObjects.forEach((customField => {
                    const customFieldItem = this.getCustomable(customField);
                    this.fieldValues.push(customFieldItem ? customFieldItem.pivot.value : defaultValue);
                }));
            },

            tooltip(vehicleObject) {
                if (this.isBoolean || !vehicleObject.vehicle.stock_state || !vehicleObject.vehicle.stock) {
                    return '';
                }

                return `${this.stockStateText(vehicleObject.vehicle.stock_state)} ${vehicleObject.vehicle.stock}`;
            },

            stockStateText(stockState) {
                const key = {
                    locate: 'leadVehicleStockStates.locate',
                    notAvailable: 'leadVehicleStockStates.notAvailable',
                    order: 'leadVehicleStockStates.order',
                    stock: 'leadVehicleStockStates.stock',
                }[stockState];

                return this.$t(key);
            },

            close() {
                this.$refs.modal.hide();
            },

            onClosed() {
                this.vehicleObjects = null;
                this.customFieldId = null;
                this.title = '';
                this.fieldValues = [];
                this.lead = null;
            },
        },
    };
</script>
