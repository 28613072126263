<template>
    <div class="w-full mx-4">
        <template v-if="isRevive">
            <activix-tooltip :content="tooltip">
                <div class="label label-info block py-1" v-text="$t('result.revive')" />
            </activix-tooltip>
        </template>
        <template v-else>
            <div :class="`label label-${label} block py-1`" v-text="$tc(`result.${value}`, 1)" />
            <div class="text-xs mt-1" v-if="showDetails">
                <template v-if="value == 'canceled'">
                    {{ $t('result.canceledByUser') }}<br />
                    <span class="font-bold">
                        {{ getName(firstCommunication.user_id) }}
                    </span>
                </template>
                <template v-if="value == 'missed'">
                    <span v-text="firstCommunication.in_hours ? $t('result.insideHours') : $t('result.outsideHours')" />
                    <br />
                    <span
                        v-text="
                            firstCommunication.in_schedule ? $t('result.insideSchedule') : $t('result.outsideSchedule')
                        "
                    />
                </template>
            </div>
        </template>
        <div class="text-xs font-bold mt-1" v-text="timeHms(duration)" v-if="showDuration" />
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { getFullName } from '../../../utils/index.js';
    import Service from '../../../entities/Service.js';
    import LeadType from '../../../entities/LeadType.js';

    // Pinia
    import { useContextStore } from '../../../store/modules/context/store.js';

    const results = {
        answered: {
            label: 'success',
            value: 'reached',
        },
        pending: {
            label: 'default',
            value: 'pending',
        },
        calling: {
            label: 'success',
            value: 'calling',
        },
        invalid: {
            label: 'default',
            value: 'invalid',
        },
        attempted: {
            label: 'primary',
            value: 'attempted',
        },
        error: {
            label: 'default',
            value: 'error',
        },
        interrupted: {
            label: 'default',
            value: 'interrupted',
        },
        unknown: {
            label: 'default',
            value: 'unknown',
        },
        canceled: {
            label: 'default',
            value: 'canceled',
        },
    };

    export default {

        props: {
            lead: {
                type: Object,
                required: true,
            },
        },

        computed: {
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            firstCommunication() {
                return this.lead.communications[0];
            },

            reviveCommunication() {
                return this.lead.communications.find(communication => {
                    return (
                        !!communication.url &&
                        communication.service_id == Service.REVIVE &&
                        communication.duration_reached !== null
                    );
                });
            },

            label() {
                if (!this.firstCommunication) {
                    return this.isConsideredReached ? 'success' : 'default';
                }

                if (this.firstCommunication.status && results[this.firstCommunication.status]) {
                    return results[this.firstCommunication.status].label;
                }

                if (this.firstCommunication.user_id) {
                    return 'default';
                }

                if (this.firstCommunication.in_schedule && this.firstCommunication.in_hours) {
                    return 'danger';
                }

                if (this.firstCommunication.in_schedule || this.firstCommunication.in_hours) {
                    return 'warning';
                }

                return 'default';
            },

            value() {
                if (!this.firstCommunication) {
                    return this.isConsideredReached ? 'reached' : 'unknown';
                }

                if (this.firstCommunication.status && results[this.firstCommunication.status]) {
                    return results[this.firstCommunication.status].value;
                }

                if (this.firstCommunication.user_id) {
                    return 'canceled';
                }

                return 'missed';
            },

            duration() {
                if (this.reviveCommunication) {
                    return this.reviveCommunication.duration_reached;
                }

                if (
                    this.firstCommunication &&
                    this.firstCommunication.duration_reached !== null &&
                    this.firstCommunication.url !== '' &&
                    this.lead.status != 'invalid'
                ) {
                    return this.firstCommunication.duration_reached;
                }

                return null;
            },

            isRevive() {
                return ['missed', 'canceled'].includes(this.value) && !!this.reviveCommunication;
            },

            isConsideredReached() {
                return (
                    this.lead.appointment_date ||
                    this.lead.sale_date ||
                    (this.lead.created_by_user && this.lead.lead_type_id == LeadType.PHONE)
                );
            },

            showDetails() {
                return ['missed', 'canceled'].includes(this.value);
            },

            showDuration() {
                return this.duration !== null && (this.isRevive || !this.showDetails);
            },

            tooltip() {
                let tooltip = this.$tc(`result.${this.value}`, 1);

                if (!this.showDetails) {
                    return tooltip;
                }

                if (this.value == 'canceled') {
                    tooltip += `<br>${this.$t('result.canceledByUser')}`;
                    tooltip += `: ${this.getName(this.firstCommunication.user_id)}`;
                }

                if (this.value == 'missed') {
                    tooltip += '<br>';
                    tooltip += this.firstCommunication.in_hours
                        ? this.$t('result.insideHours')
                        : this.$t('result.outsideHours');
                    tooltip += '<br>';
                    tooltip += this.firstCommunication.in_schedule
                        ? this.$t('result.insideSchedule')
                        : this.$t('result.outsideSchedule');
                }

                return tooltip;
            },
        },

        methods: {
            getName() {
                const user = this.contextAccount.activeUsersAndSupplierUsers.find(user => {
                    return user.id == this.firstCommunication.user_id;
                });

                return getFullName(user);
            },
        },
    };
</script>
