<template>
    <activix-multiselect
        identifier="value"
        label="label"
        multiple="multiple"
        icon-size="text-xl"
        :icon="mdLayout ? 'regular/calendar-search' : ''"
        :outline="true"
        :allow-empty="false"
        :close-on-select="false"
        :limit-text="limitText"
        :limit-selected-visible="1"
        :max-height="350"
        :multiple-limit="1"
        :options="filterableDates"
        :searchable="false"
        :select-all="false"
        :selected="selectedDates"
        @update="onUpdate"
    >
        <template #prefix>
            <div class="flex items-center h-full">
                <icon class="text-blue-500 text-2xl | md:text-base" name="regular/calendar-search" />
            </div>
        </template>
    </activix-multiselect>
</template>

<script>
    import { isEmpty, debounce } from 'lodash-es';
    import { mapState, mapActions } from 'pinia';
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        props: {
            newDashboards: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            ...mapState(useDashboardStore, {
                dashboardType: 'dashboardType',
                filteredDates: 'filteredDates',
            }),

            dates() {
                return [
                    {
                        value: 'created_at',
                        label: this.$t('date.createdDate'),
                    },
                    {
                        value: 'updated_at',
                        label: this.$t('date.modificationDate'),
                    },
                    {
                        value: 'appt_call_date',
                        label: this.$t('date.apptCallDate'),
                        hideIn: ['commercial', 'service', 'event'],
                    },
                    {
                        value: 'appointment_date',
                        label: this.$t('date.appointmentDate'),
                        hideIn: ['commercial'],
                    },
                    {
                        value: 'presented_date',
                        label: this.$t('date.presentedDate'),
                        hideIn: ['commercial'],
                    },
                    {
                        value: 'be_back_date',
                        label: this.$t('date.beBackDate'),
                        hideIn: ['commercial', 'renewal', 'service'],
                    },
                    {
                        value: 'road_test_date',
                        label: this.$t('date.roadTestDate'),
                        hideIn: ['commercial', 'renewal'],
                    },
                    {
                        value: 'take_over_date',
                        label: this.$t('date.takeOverDate'),
                        hideIn: ['commercial', 'renewal', 'service'],
                    },
                    {
                        value: 'sale_date',
                        label: this.dashboardType == 'renewal' ? this.$t('date.renewalDate') : this.$t('date.saleDate'),
                        hideIn: ['service'],
                    },
                    {
                        value: 'deliverable_date',
                        label: this.$t('date.deliverableDate'),
                        hideIn: ['service'],
                    },
                    {
                        value: 'delivered_date',
                        label: this.$t('date.deliveredDate'),
                        hideIn: ['service'],
                    },
                    {
                        value: 'delivery_date',
                        label: this.$t('date.deliveryDate'),
                        hideIn: ['service'],
                    },
                    {
                        value: 'last_presented_date',
                        label: this.$t('date.lastPresentedDate'),
                        showIn: ['service'],
                        hideIn: ['renewal'],
                    },
                    {
                        value: 'next_presented_date',
                        label: this.$t('date.nextPresentedDate'),
                        showIn: ['service'],
                    },
                    {
                        value: 'end_contract_date',
                        label: this.$t('date.endContractDate'),
                        showIn: ['renewal', 'allLead'],
                    },
                    {
                        value: 'end_warranty_date',
                        label: this.$t('date.endWarrantyDate'),
                        showIn: ['renewal', 'service'],
                        hideIn: [
                            'phoneUp',
                            'allLead',
                            'leadXpress',
                            'loyalty',
                            'saleTable',
                            'walkIn',
                            'webBoost',
                            'webOrder',
                        ],
                    },
                    {
                        value: 'recorded_date',
                        label: this.$t('date.recordedDate'),
                        showIn: ['commercial'],
                        hideIn: [
                            'phoneUp',
                            'renewal',
                            'allLead',
                            'leadXpress',
                            'loyalty',
                            'saleTable',
                            'service',
                            'walkIn',
                            'webBoost',
                            'webOrder',
                        ],
                    },
                    {
                        value: 'task_date',
                        label: this.$t('date.taskDate'),
                    },
                    {
                        value: 'call_date',
                        label: this.$t('date.callDate'),
                        showIn: ['phoneUp', 'allLead'],
                        hideIn: ['renewal'],
                    },
                    {
                        value: 'discounted_date',
                        label: this.$t('date.discountedFilterDate'),
                        showIn: ['renewal', 'commercial'],
                        hideIn: [
                            'phoneUp',
                            'allLead',
                            'service',
                            'leadXpress',
                            'loyalty',
                            'saleTable',
                            'walkIn',
                            'webBoost',
                            'webOrder',
                        ],
                    },
                    {
                        value: 'refinanced_date',
                        label: this.$t('date.refinancedDate'),
                        showIn: ['renewal'],
                        hideIn: [
                            'phoneUp',
                            'allLead',
                            'service',
                            'leadXpress',
                            'loyalty',
                            'saleTable',
                            'walkIn',
                            'webBoost',
                            'webOrder',
                        ],
                    },
                    {
                        value: 'communication_date',
                        label: this.$t('date.communicationDate'),
                    },
                ];
            },

            filterableDates() {
                return this.dates.filter(date => {
                    if (!empty(date.showIn) && !date.showIn.includes(this.dashboardType)) {
                        return false;
                    }

                    if (!empty(date.hideIn) && date.hideIn.includes(this.dashboardType)) {
                        return false;
                    }

                    return true;
                });
            },

            selectedDates() {
                const filteredDates = !isEmpty(this.filteredDates) ? this.filteredDates : [];
                return this.filterableDates.filter(date => filteredDates.includes(date.value));
            },
        },

        methods: {
            ...mapActions(useDashboardStore, [
                'setDashboardConfigs',
            ]),
            ...mapActions(useGlobalStore, ['updateSessionAction']),

            onUpdate(values) {
                const dates = values.map(d => d.value);

                if (this.newDashboards) {
                    useDashboardStore().filteredDates = dates;
                    this.updateDates(dates);
                } else {
                    this.legacyUpdateDates(dates);
                }
            },

            legacyUpdateDates(dates) {
                const oldDates = this.filteredDates;

                useDashboardStore().filteredDates = dates;
                this.updateSessionAction('dashboard_dates', dates);

                if (
                    (dates.includes('task_date') && !oldDates.includes('task_date')) ||
                    (!dates.includes('task_date') && oldDates.includes('task_date'))
                ) {
                    this.$eventBus.$emit('fetch-stats');
                }

                this.$eventBus.$emit('filtered-dates-changed');
            },

            updateDates(dates) {
                useDashboardStore().filteredDates = dates;

                this.setDashboardConfigs({
                    dates,
                });

                this.updateSessionAction('dashboard_dates', dates);
                this.$eventBus.$emit('filtered-dates-changed');
            },

            limitText(count) {
                return `${count} ${this.$tc('date.dateSelected', count)}`;
            },
        },

        created() {
            this.legacyUpdateDates = debounce(this.legacyUpdateDates, 1000);
            this.updateDates = debounce(this.updateDates, 1000);
        },

        beforeDestroy() {
            this.legacyUpdateDates.cancel();
            this.updateDates.cancel();
        },
    };
</script>
