<template>
    <div class="relative mx-1 my-1">
        <sale-sticker :lead="lead" :waiting-sale="waitingSale" :view="view" />

        <activix-tooltip :content="objectiveTooltip" v-if="showBadgeObjective">
            <div class="badge | absolute top-0 right-0 -mt-2 -mr-2 shadow-md text-xs text-white bg-grey-600 cursor-default">
                {{ objective }}
            </div>
        </activix-tooltip>

        <activix-tooltip :content="badgeCharacterTooltip" v-if="showBadgeCharacter">
            <div class="badge | absolute bottom-0 right-0 -mb-2 -mr-2 shadow-md text-xs text-white cursor-default bg-grey-600">
                {{ badgeCharacter }}
            </div>
        </activix-tooltip>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import SaleSticker from './SaleSticker.vue';
    import ActivixDate from '../../value-objects/ActivixDate.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        components: {
            SaleSticker,
        },

        props: {
            view: String,
            index: Number,
            lead: Object,
            waitingSale: {
                type: Boolean,
                default: false,
            },
            leadCount: {
                type: Number,
                default: 0,
            },
            objective: {
                type: Number,
                default: 0,
            },
        },

        computed: {
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            objectiveTooltip() {
                return `
                    <div>
                        ${this.$t('saleTable.objective')} : ${this.objective}
                        ${this.$tc('saleTable.cards.sale', this.objective)}
                    </div>
                    <div>
                        ${this.$t('saleTable.objectiveReached')} : ${this.leadCount}
                        ${this.$tc('saleTable.cards.sale', this.leadCount)}
                    </div>
                `;
            },

            showBadgeObjective() {
                return this.objective > 0 && this.objective == this.index + 1;
            },

            badgeFieldEnabled() {
                return (
                    (this.contextAccount.sale_table_badge_active && this.contextAccount.sale_table_badge_field) ||
                    this.contextAccount.parents.find(parent => parent.sale_table_badge_active)
                );
            },

            badgeField() {
                if (!this.badgeFieldEnabled) {
                    return null;
                }

                if (this.contextAccount.sale_table_badge_active && this.contextAccount.sale_table_badge_field) {
                    return this.contextAccount.getAllCustomFields().find(customField => {
                        return customField.id == this.contextAccount.sale_table_badge_field;
                    });
                }

                if (this.contextAccount.parents.find(parent => parent.sale_table_badge_active)) {
                    const badgeFieldId = this.contextAccount.parents.find(parent => {
                        return parent.sale_table_badge_active && parent.sale_table_badge_field;
                    }).sale_table_badge_field;

                    return this.contextAccount.getAllCustomFields().find(customField => customField.id == badgeFieldId);
                }

                return null;
            },

            badgeCharacter() {
                if (!this.badgeField) {
                    return '';
                }

                return this.badgeField.name.charAt(0).toUpperCase();
            },

            showBadgeCharacter() {
                return !!this.badgeField?.id && !!this.badgeCharacterValue;
            },

            badgeCharacterValue() {
                if (!this.badgeField) {
                    return null;
                }

                const customField = this.lead.custom_fields.find(customField => {
                    return customField.id == this.badgeField.id;
                });

                if (!customField) {
                    return null;
                }

                return customField.pivot.value[0];
            },

            badgeCharacterTooltip() {
                const tooltip = this.badgeField?.name || '';

                if (this.badgeField?.type == 'datetime' && this.badgeCharacterValue) {
                    const date = new ActivixDate(this.badgeCharacterValue);
                    return `${tooltip} - ${date.toHumanShort()}`;
                }

                return tooltip;
            },
        },
    };
</script>
