<template>
    <div class="flex h-full items-center mr-9">
        <activix-tooltip :content="showAllLeadTooltip">
            <activix-switcher
                color="blue"
                size="small"
                :value="options.showAllLeads"
                @input="$eventBus.$emit('toggle-option', 'showAllLeads')"
            />
        </activix-tooltip>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useDashboardStore } from '@/store/modules/dashboard/store.js';

    export default {
        props: {
            cards: {
                type: Object,
                default: () => {},
            },
        },

        computed: {
            ...mapState(useDashboardStore, {
                options: store => store.configs.options,
            }),

            showAllLeadTooltip() {
                return this.options.showAllLeads
                    ? this.$t('allLead.allLeadsFilteredTooltipOff')
                    : this.$t('allLead.allLeadsFilteredTooltipOn');
            },
        },
    };
</script>
