<template>
    <el-popover placement="top-start">
        <button class="inline-flex items-center text-gray-700" slot="reference">
            <icon class="mr-1 text-gray-500 text-sm" name="regular/information-circle" />
            <span v-t="'clientCard.legend'" />
        </button>
        <div class="flex p-4 space-x-8 whitespace-nowrap text-sm">
            <div class="space-y-1">
                <div class="flex items-center">
                    <activix-checkbox
                        class="mr-2 pointer-events-none"
                        color="orange"
                        size="sm"
                        :value="true"
                    />
                    {{ $t('modal.noShowTooltip') }}
                </div>
                <div class="flex items-center">
                    <activix-checkbox
                        class="mr-2 pointer-events-none"
                        color="red"
                        size="sm"
                        :value="true"
                    />
                    {{ $tc('result.canceled', 0) }}
                </div>
                <div class="flex items-center">
                    <activix-checkbox
                        class="mr-2 pointer-events-none"
                        color="green"
                        size="sm"
                        :value="true"
                    />
                    {{ $tc('result.confirmed', 0) }}
                </div>
            </div>
            <div class="space-y-1">
                <div class="flex items-center">
                    <activix-checkbox
                        class="mr-2 pointer-events-none"
                        color="purple"
                        size="sm"
                        :value="true"
                    />
                    {{ $t('dashboards.apptByPhone') }}
                </div>
                <div class="flex items-center">
                    <activix-checkbox
                        class="mr-2 pointer-events-none"
                        color="blue"
                        size="sm"
                        :value="true"
                    />
                    {{ $t('dashboards.inDate') }}
                </div>
                <div class="flex items-center">
                    <activix-checkbox
                        class="mr-2 pointer-events-none"
                        color="blue"
                        size="sm"
                        :value="true"
                        :cross="true"
                    />
                    {{ $t('dashboards.notInDate') }}
                </div>
            </div>
        </div>
    </el-popover>
</template>

<script>
    export default {};
</script>
