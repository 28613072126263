<template>
    <div class="mb-6" v-on-clickaway="hideDescription">
        <div
            class="relative flex flex-col justify-center h-36 overflow-hidden rounded shadow-lg bg-white transform transition-all duration-300"
            :class="{ 'cursor-pointer hover:shadow-xl hover:-translate-y-2px': hasDescription }"
            @click="toggleShowDescription"
        >
            <transition name="fade">
                <div class="absolute inset-0 bg-white" key="front" v-if="!showDescription">
                    <card-content
                        :card="card"
                        :denominator="denominator"
                        :icon="icon"
                        :title="title"
                        :title-icon="titleIcon"
                        :type="type"
                        :percentage="percentage"
                        :value="value"
                    >
                        <slot />
                    </card-content>
                </div>
                <div class="absolute inset-0 bg-white" key="back" v-else>
                    <card-content
                        :card="backCard"
                        :denominator="backDenominator"
                        :icon="backIcon"
                        :title="backTitle"
                        :type="backType"
                        :value="backValue"
                        v-if="backCard || backValue"
                    >
                        <card-popover position="right" :card="card" :type="type">
                            <slot name="popover" />
                        </card-popover>
                    </card-content>
                    <div class="h-full flex items-center justify-center p-4" v-else>
                        <div class="text-center text-grey-700 overflow-hidden">
                            <slot name="description" />
                        </div>
                        <div
                            class="absolute right-0 bottom-0 m-3 text-grey-500"
                            v-if="type != 'time' && denominator !== null"
                        >
                            <span>{{ formattedBackNumerator }} / {{ formattedBackDenominator }}</span>
                            <span class="ml-3">({{ backPercentage }}%)</span>
                        </div>
                        <card-popover :card="card" :type="type">
                            <slot name="popover" />
                        </card-popover>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    import { toPercent } from '../../../utils/numbers.js';

    import CardContent from './CardContent.vue';
    import CardPopover from './CardPopover.vue';

    export default {
        components: {
            CardContent,
            CardPopover,
        },

        props: {
            // Front
            card: {
                type: Object,
                required: true,
            },
            numerator: {
                type: Number,
                default: null,
            },
            denominator: {
                type: Number,
                default: null,
            },
            backNumerator: {
                type: Number,
                default: null,
            },
            icon: {
                type: String,
                default: '',
            },
            title: {
                type: String,
                required: true,
            },
            titleIcon: {
                type: String,
                default: '',
            },
            type: {
                type: String,
                default: 'number',
            },
            value: {
                type: [Number, String],
                required: false,
            },

            // Back
            backCard: {
                type: Object,
                required: false,
            },
            backDenominator: {
                type: Number,
                default: null,
            },
            backIcon: {
                type: String,
                default: '',
            },
            backTitle: {
                type: String,
                required: false,
            },
            backType: {
                type: String,
                default: 'number',
            },
            backValue: {
                type: [Number, String],
                required: false,
            },
        },

        data() {
            return {
                showDescription: false,
            };
        },

        computed: {
            hasDescription() {
                return !!this.$slots.description || !!this.backCard;
            },

            percentage() {
                if (this.type == 'time') {
                    if (!this.denominator) {
                        return this.formattedNumerator;
                    }

                    return this.formattedNumerator / this.formatedDenominator;
                }

                return toPercent(this.formattedNumerator, this.formatedDenominator);
            },

            backPercentage() {
                return toPercent(this.formattedBackNumerator, this.formattedBackDenominator);
            },

            formattedNumerator() {
                if (this.numerator !== null) {
                    return this.numerator;
                }

                return this.card.count;
            },

            formatedDenominator() {
                if (this.denominator !== null) {
                    return this.denominator;
                }

                return this.card.count;
            },

            formattedBackNumerator() {
                return this.backNumerator || this.formattedNumerator;
            },

            formattedBackDenominator() {
                return this.backDenominator || this.denominator;
            },
        },

        methods: {
            toggleShowDescription() {
                if (!this.hasDescription) {
                    return;
                }

                this.showDescription = !this.showDescription;
            },

            hideDescription() {
                this.showDescription = false;
            },
        },
    };
</script>
