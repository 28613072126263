import { get, cloneDeep } from 'lodash-es';

import { mapActions, mapState } from 'pinia';
import { useDashboardStore } from '../store/modules/dashboard/store.js';
import { useContextStore } from '../store/modules/context/store.js';
import { useGlobalStore } from '../store/store.js';

export default {
    data() {
        return {
            statQueues: {
                statQueue1: 0,
                statQueue2: 0,
                statQueue3: 0,
            },
        };
    },

    computed: {
        ...mapState(useDashboardStore, {
            options: store => store.configs.options,
            filteredDates: 'filteredDates',
            dashboardDisabledForGroups: 'dashboardDisabledForGroups',
        }),
    },

    methods: {
        ...mapActions(useContextStore, ['setContextAccountAction']),

        fetchStatLeads(options) {
            if (!options) {
                options = {};
            }

            const force = get(options, 'force', true);
            const clearAll = get(options, 'clearAll', false);
            const alreadyFetched = this.$stats.leadsFetched();

            if (this.dashboardDisabledForGroups) {
                return;
            }

            if (alreadyFetched && !force) {
                this.filterStatLeads();
                return;
            }

            // Clear current leads
            this.cancelStatQueue(true);

            if (force && clearAll) {
                this.$stats.clearAll();
            } else {
                this.$stats.clear(this.dashboardType);
            }

            this.$stats.latestFetch.groupId = this.contextGroup.id || null;
            this.$stats.latestFetch.accountId = this.contextAccount.id || null;

            this.currentCount = 0;
            this.totalCount = -1;
            this.lastPage = 0;

            this.$wait.start('fetching.stats');

            const payload = {
                from: 'dashboard.activity_report',
                startDate: this.startDate,
                endDate: this.endDate,
                division: this.division,
                options: this.options,
                dashboardType: this.dashboardType,
                orderBy: 'id',
                sortedBy: 'desc',
                filteredDates: this.filteredDates,
                perPage: this.perPage,
                page: 1,
            };

            if (this.contextUser.id) {
                payload.userId = this.contextUser.id;
            } else if (this.contextGroup.id) {
                payload.groupId = this.contextGroup.id;
            } else {
                payload.accountId = this.contextAccount.id;
            }

            const availableQueue = this.getAvailableStatQueue();

            // Abort current request
            this.cancelStatQueue();

            // Load leads
            $.ajaxq(availableQueue, {
                url: `${process.env.VUE_APP_API_URL}/v1/leads`,
                type: 'get',
                data: payload,
                headers: {
                    Authorization: `Bearer ${this.$auth.token()}`,
                    Accept: 'application/json',
                },
            })
                .done(response => {
                    this.$stats.setLeads(response.data);

                    // Current Count
                    this.currentCount += response.to - response.from + 1;

                    // Total
                    if (typeof response.total !== 'undefined') {
                        this.totalCount = response.total;
                    }

                    // Last Page
                    if (typeof response.last_page !== 'undefined') {
                        this.lastPage = response.last_page;
                    }

                    const lastPageStartCount = this.perPage * (this.lastPage - 1);

                    if (this.currentCount > lastPageStartCount) {
                        this.afterStatLeadsFetch();
                        return;
                    }

                    for (let i = 2; i <= this.lastPage; i++) {
                        const params = cloneDeep(payload);

                        params.page = i;

                        // Fetch next page
                        this.queueStatLeads(params);
                    }
                })
                .fail((xhr, textStatus) => {
                    // Prevent error message if manually aborted
                    if (textStatus == 'abort') {
                        return;
                    }

                    if (xhr.status == 503) {
                        this.$eventBus.$emit('set-maintenance-mode', true);
                    } else if (xhr.status == 403) {
                        // If authUser is in wrong account just redirect
                        if (this.contextAccount.id && this.contextAccount.id != this.authUser.account_id) {
                            this.setContextAccountAction(this.authUser.account_id);
                        }
                    } else if (xhr.status == 401) {
                        this.$auth.handleRefreshToken();
                    } else {
                        // Abort current request
                        $.ajaxq.abort(availableQueue);

                        useGlobalStore().appendNewError({
                            code: '0076',
                            display: false,
                            error: xhr,
                            textStatus,
                            request: {
                                url: 'leads',
                                type: 'get',
                                data: payload,
                            },
                        });
                    }
                })
                .always(() => {
                    // Remove from queue
                    this.statQueues[availableQueue]--;
                });
        },

        queueStatLeads(payload) {
            const availableQueue = this.getAvailableStatQueue();

            this.$nextTick(() => {
                $.ajaxq(availableQueue, {
                    url: `${process.env.VUE_APP_API_URL}/v1/leads`,
                    type: 'get',
                    data: payload,
                    headers: {
                        Authorization: `Bearer ${this.$auth.token()}`,
                        Accept: 'application/json',
                    },
                })
                    .done(response => {
                        this.$stats.appendLeads(response.data);

                        // Current Count
                        this.currentCount += response.to - response.from + 1;

                        // Total
                        if (typeof response.total !== 'undefined') {
                            this.totalCount = response.total;
                        }

                        // Last Page
                        if (typeof response.last_page !== 'undefined') {
                            this.lastPage = response.last_page;
                        }

                        const lastPageStartCount = this.perPage * (this.lastPage - 1);

                        if (this.currentCount > lastPageStartCount) {
                            this.afterStatLeadsFetch();
                        }
                    })
                    .fail((xhr, textStatus) => {
                        // Prevent error message if manually aborted
                        if (textStatus == 'abort') {
                            return;
                        }

                        if (xhr.status == 503) {
                            this.$eventBus.$emit('set-maintenance-mode', true);
                        } else if (xhr.status == 403) {
                            // If authUser is in wrong account just redirect
                            if (this.contextAccount.id && this.contextAccount.id != this.authUser.account_id) {
                                this.setContextAccountAction(this.authUser.account_id);
                            }
                        } else if (xhr.status == 401) {
                            this.$auth.handleRefreshToken();
                        } else {
                            // Abort current request
                            $.ajaxq.abort(availableQueue);

                            useGlobalStore().appendNewError({
                                code: '0077',
                                display: false,
                                error: xhr,
                                textStatus,
                                request: {
                                    url: 'leads',
                                    type: 'get',
                                    data: payload,
                                },
                            });

                            return;
                        }

                        this.$wait.end('fetching.stats');
                    })
                    .always(() => {
                        // Remove from queue
                        this.statQueues[availableQueue]--;
                    });
            });
        },

        afterStatLeadsFetch() {
            this.$wait.end('fetching.stats');
            this.filterStatLeads();
        },

        cancelStatQueue(reset = false) {
            for (const i in this.statQueues) {
                if (!this.statQueues.hasOwnProperty(i)) {
                    continue;
                }

                $.ajaxq.abort(i);

                if (reset) {
                    this.statQueues[i] = 0;
                }
            }
        },

        getAvailableStatQueue() {
            let bestQueue = 'statQueue1';

            for (const i in this.statQueues) {
                if (!this.statQueues.hasOwnProperty(i)) {
                    continue;
                }

                if (this.statQueues[bestQueue] > this.statQueues[i]) {
                    bestQueue = i;
                }
            }

            this.statQueues[bestQueue]++;

            return bestQueue;
        },
    },
};
