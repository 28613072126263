<template>
    <div>
        <div class="text-xl">
            {{ $t('allLead.salesBack') }}
        </div>
        <div class="mt-2" v-if="contextAccount.sale_by_phone && !contextAccount.isPolestar()">
            <span class="font-bold">{{ cards.leads.saleByPhone.count }}</span>
            {{ $tc('result.saleByPhone', cards.leads.saleByPhone.count) }}
        </div>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useContextStore } from '@/store/modules/context/store.js';

    export default {
        props: {
            cards: {
                type: Object,
                default: () => {},
            },
        },

        computed: {
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),
        },
    };
</script>
