<template>
    <button class="user-comment text-blue-500" @click="openCommentModal">
        <template v-if="commentContent">
            {{ commentContent }}
        </template>
        <template v-else>
            <activix-tooltip :content="$t('modal.add_comment')">
                <div class="p-4">
                    <icon :name="$icons.add" class="link-grey | text-xs" />
                </div>
            </activix-tooltip>
        </template>
    </button>
</template>

<script>
    /* eslint-disable vue/require-prop-types */
    export default {
        name: 'ColumnsComment',

        props: ['lead'],

        computed: {
            commentContent() {
                return this.lead.latest_comment?.content;
            },
        },

        methods: {
            openCommentModal() {
                this.$modal.show('dashboard:comments', this.lead.id);
            },
        },
    };
</script>
