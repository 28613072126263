// Utils
import { mapState } from 'pinia';
import { toPercent } from '../utils/numbers.js';
import { useDashboardStore } from '../store/modules/dashboard/store.js';
import { useContextStore } from '../store/modules/context/store.js';

export default {
    data() {
        return {
            sort: {
                prop: 'name',
                order: 'ascending',
            },
        };
    },

    computed: {
        ...mapState(useDashboardStore, {
            dashboardConfigs: 'configs',
            dashboardType: 'dashboardType',
        }),
        ...mapState(useContextStore, {
            contextAccount: 'account',
        }),
    },

    methods: {
        sortNameColumn(a, b) {
            if (a.id == 'none') {
                return 1;
            }

            if (b.id == 'none') {
                return -1;
            }

            if (['created_at:day_of_week'].includes(this.dashboardConfigs.statsTableFilter.selectedKey)) {
                const order = { sunday: 0, monday: 1, tuesday: 2, wednesday: 3, thursday: 4, friday: 5, saturday: 6 };

                return order[a.id.toLowerCase()] - order[b.id.toLowerCase()];
            }

            return a.name.toString().localeCompare(b.name.toString());
        },

        sortChange(sort) {
            this.$emit('sort-apply', sort);
            this.$behavior.track('StatsTable', { action: 'order', dashboard: this.dashboardType, column: sort.prop, order: sort.order });
        },

        toPercent(num, denom) {
            const percent = toPercent(num, denom);

            return `(${percent}%)`;
        },

        toggleRow(rowNumber) {
            this.tableData.forEach(row => {
                if (row.rowNumber == rowNumber) {
                    row.expanded = !row.expanded;
                }
            });
        },

        summaryPercentage(num, denomColumn, data) {
            if (!num) {
                return ' (0%)';
            }

            const denom = data
                .map(row => {
                    return row[denomColumn];
                })
                .reduce((sum, value) => {
                    return sum + value;
                });

            return ` ${this.toPercent(num, denom)}`;
        },

        getTotal(data, columnName) {
            const values = data.map(row => {
                return row[columnName];
            });

            if (!values.length) {
                return 0;
            }

            const total = values.reduce((sum, value) => {
                return sum + value;
            });

            return Math.round(total * 100) / 100;
        },

        getAverage(data, total, columnName) {
            const values = data
                .filter(row => {
                    return row[columnName] > 0;
                })
                .map(row => {
                    return row[columnName];
                });

            if (!values.length) {
                return 0;
            }

            if (columnName == 'average_time_denominator') {
                const denominator = values.reduce((sum, value) => {
                    return sum + value;
                });

                return (total / denominator).toFixed();
            }

            return total / values.length;
        },

        formatTotals({ columns, data }) {
            const columnNames = columns.map(column => {
                return column.columnKey || column.property;
            });

            const totals = [];

            for (const columnName of columnNames) {
                if (columnName == 'name') {
                    totals.push(0);
                    continue;
                }

                let total;

                const columnTotalObject = this.columnsTotals.find(columnSummary => {
                    return (
                        (!columnSummary.key && columnSummary.numerator == columnName) ||
                        (!!columnSummary.key && columnSummary.key == columnName)
                    );
                });

                if (columnTotalObject && columnTotalObject.numerator) {
                    total = this.getTotal(data, columnTotalObject.numerator);
                } else {
                    total = this.getTotal(data, columnName);
                }

                let denomTotal;

                if (columnTotalObject) {
                    switch (columnTotalObject.type) {
                        case 'percent':
                            total = `${total} ${this.summaryPercentage(total, columnTotalObject.denominator, data)}`;
                            break;

                        case 'division':
                            denomTotal = this.getTotal(data, columnTotalObject.denominator);
                            total = (total / denomTotal).toFixed(1);
                            break;

                        case 'ratio_and_percent':
                            denomTotal = this.getTotal(data, columnTotalObject.denominator);
                            total = `${total} / ${denomTotal} ${this.summaryPercentage(
                                total,
                                columnTotalObject.denominator,
                                data,
                            )}`;
                            break;

                        case 'money':
                            total = this.toMoney(total);
                            break;

                        case 'money_and_average':
                            denomTotal = this.getTotal(data, columnTotalObject.denominator);
                            total /= denomTotal;
                            total = this.toMoney(total);
                            break;

                        case 'average_time':
                            total = this.getAverage(data, total, columnTotalObject.denominator);
                            total = this.timeHms(total);
                    }
                }

                totals.push(total);
            }

            return totals;
        },
    },
};
