<template>
    <div class="overflow-auto">
        <activix-table
            class="border-t-2"
            sort-by-key="last_month"
            trans-table="saleTable.table"
            :columns="tableColumns"
            :data="tableRows"
            :footers="tableFooter"
            :headers="tableHeader"
            v-if="render"
        />
    </div>
</template>

<script>
    // Utils
    import { get, isInteger } from 'lodash-es';
    import { mapState } from 'pinia';
    import { toPercent } from '../../utils/numbers.js';

    // Components
    import ActivixTable from '../dashboards/Table.vue';

    // Mixins
    import DashboardColumns from '../../mixins/DashboardColumns.js';

    // Entities
    import DashboardType from '../../entities/DashboardType.js';

    // Pinia
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        components: {
            ActivixTable,
        },

        mixins: [DashboardColumns],

        props: {
            users: {
                type: Array,
                required: true,
            },
            showAllUsers: {
                type: Boolean,
                default: false,
            },
            render: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                tableColumns: [
                    {
                        name: 'last_month',
                        class: 'text-center',
                    },
                    {
                        name: 'user',
                        type: 'string',
                    },
                    {
                        name: 'stock',
                        class: 'text-center',
                    },
                    {
                        name: 'locate',
                        class: 'text-center',
                    },
                    {
                        name: 'order',
                        class: 'text-center',
                    },
                    {
                        name: 'objective_sales',
                        class: 'text-center',
                    },
                    {
                        name: 'sold',
                        class: 'text-center',
                    },
                    {
                        name: 'delivered',
                        class: 'text-center',
                    },
                    {
                        name: 'previous_sales',
                        class: 'text-center',
                    },
                    {
                        name: 'variation',
                        class: 'text-center',
                    },
                ],
                results: [],
                pastSales: {
                    lastMonth: [],
                    lastYear: [],
                },
            };
        },

        computed: {
            ...mapState(useContextStore, {
                contextAccount: 'account',
                contextTeam: 'contextTeam',
            }),
            ...mapState(useDashboardStore, {
                expanded: store => store.configs.expanded,
                options: store => store.configs.options,
            }),

            formattedResults() {
                return this.users
                    .map(user => {
                        const result = this.results.find(result => result.id == user.id);
                        const lastMonth = this.pastSales.lastMonth.find(result => result.id == user.id);
                        const lastYear = this.pastSales.lastYear.find(result => result.id == user.id);
                        const objective = user.currentObjectives.reduce((total, objective) => {
                            return total + objective.sale_amount;
                        }, 0);

                        return {
                            user,
                            objective_sales: objective,
                            last_month: get(lastMonth, 'count', 0),
                            sold: get(result, 'total_sales', 0),
                            delivered: get(result, 'total_deliveries', 0),
                            stock: get(result, 'vehicle_stock_stock', 0),
                            order: get(result, 'vehicle_stock_order', 0),
                            locate: get(result, 'vehicle_stock_locate', 0),
                            previous_sales: get(lastYear, 'count', 0),
                        };
                    })
                    .filter(result => {
                        if (result.sold) {
                            return true;
                        }

                        if (!result.user.active) {
                            return false;
                        }

                        return this.showAllUsers || !!result.objective_sales;
                    });
            },

            tableRows() {
                return this.formattedResults.map(result => {
                    const saleAmount = result.objective_sales != 'N/A' ? result.objective_sales : 0;
                    const percentSales = saleAmount != 'N/A' ? toPercent(result.sold, saleAmount) : 0;

                    return {
                        last_month: result.last_month,
                        user: result.user.fullName,
                        sold: result.sold,
                        objective_sales: `${saleAmount} (${percentSales}%)`,
                        objective_sales_raw: saleAmount,
                        delivered: result.delivered,
                        stock: result.stock,
                        order: result.order,
                        locate: result.locate,
                        previous_sales: result.previous_sales,
                        variation: this.generateVariation(result.sold, result.previous_sales),
                        variation_raw: this.generateVariation(result.sold, result.previous_sales, true),
                    };
                });
            },

            totalSales() {
                let total = 0;

                this.formattedResults.forEach(result => {
                    if (result.objective_sales != 'N/A') {
                        total += result.objective_sales;
                    }
                });

                return total;
            },

            tableFooter() {
                const footer = {
                    last_month: {
                        value: 0,
                    },
                    user: {
                        value: this.$t('saleTable.table.total'),
                    },
                    stock: {
                        value: 0,
                    },
                    locate: {
                        value: 0,
                    },
                    order: {
                        value: 0,
                    },
                    objective_sales: {
                        value: 0,
                    },
                    sold: {
                        value: 0,
                    },
                    delivered: {
                        value: 0,
                    },
                    previous_sales: {
                        value: 0,
                    },
                    variation: {
                        value: 0,
                    },
                };

                let cntNa = 0;
                let previousSales = 0;

                this.formattedResults.forEach(result => {
                    footer.sold.value += result.sold;

                    if (result.objective_sales != 'N/A') {
                        footer.objective_sales.value += result.objective_sales;
                    } else {
                        cntNa++;
                    }

                    footer.delivered.value += result.delivered;
                    footer.stock.value += result.stock;
                    footer.order.value += result.order;
                    footer.locate.value += result.locate;
                    footer.last_month.value += result.last_month;
                    previousSales += result.previous_sales;
                });

                footer.stock.value = `${footer.stock.value} (${toPercent(footer.stock.value, footer.sold.value)}%)`;
                footer.order.value = `${footer.order.value} (${toPercent(footer.order.value, footer.sold.value)}%)`;
                footer.locate.value = `${footer.locate.value} (${toPercent(footer.locate.value, footer.sold.value)}%)`;
                footer.objective_sales.value =
                    cntNa == this.tableRows.length
                        ? 'N/A'
                        : `${footer.objective_sales.value} (${toPercent(
                            footer.sold.value,
                            footer.objective_sales.value,
                        )}%)`;
                footer.delivered.value = `${footer.delivered.value} (${toPercent(
                    footer.delivered.value,
                    footer.sold.value,
                )}%)`;
                footer.previous_sales.value = previousSales;
                footer.variation.value = this.generateVariation(footer.sold.value, previousSales);

                return footer;
            },

            tableHeader() {
                return [
                    {
                        name: 'lastMonthIcon',
                        colspan: 1,
                        class: 'width-2',
                        type: 'icon',
                        tooltip: this.$t('saleTable.lastMonthSales'),
                        icon: 'light/trophy',
                    },
                    {
                        name: 'advisors',
                        colspan: 1,
                        type: 'string',
                        class: 'width-18',
                    },
                    {
                        name: 'vehicles',
                        colspan: 3,
                        class: 'width-20',
                    },
                    {
                        name: 'goals',
                        colspan: 1,
                        class: 'width-15',
                    },
                    {
                        name: 'sales',
                        colspan: 1,
                        class: 'width-15',
                    },
                    {
                        name: 'deliveries',
                        colspan: 1,
                        class: 'width-15',
                    },
                    {
                        name: 'variation',
                        colspan: 2,
                        tooltip: this.$t('saleTable.table.variationTooltip'),
                        class: 'width-15',
                    },
                ];
            },
        },

        watch: {
            'expanded.saleTable': {
                immediate: true,
                handler() {
                    this.fetch();
                },
            },
        },

        methods: {
            generateVariation(currentSales, previousSales, raw = false) {
                currentSales = isInteger(currentSales) ? currentSales : 0;
                previousSales = isInteger(previousSales) ? previousSales : 0;

                if (!previousSales) {
                    return raw ? 0 : '-';
                }

                let variation = (currentSales - previousSales) / previousSales;
                variation = Math.round(variation * 100);
                const variationType = variation >= 0 ? 'text-green-500' : 'text-red-500';

                if (raw) {
                    return variation;
                }

                variation = String(variation).replace('-', '- ');

                return `<span class="${variationType}">${variation}%</span>`;
            },

            async fetch({ background = false } = {}) {
                if (!this.expanded.saleTable) {
                    return;
                }

                if (!background) {
                    this.$wait.start('fetching.saleReport');
                }

                const payload = {
                    dashboard: DashboardType.SALE,
                    endDate: this.parsedEndDate.toDateTimeString(),
                    startDate: this.parsedStartDate.toDateTimeString(),
                    options: this.options,
                    accountId: this.contextAccount.id,
                    filters: this.activeDashboardFilters,
                    mainAggregation: 'user_id',
                    teamId: this.contextTeam.id,
                };

                try {
                    const [saleReport, pastSales] = await Promise.all([
                        this.$api.dashboard.getSaleReport(payload),
                        this.$api.dashboard.getPastSales(payload),
                    ]);

                    if (saleReport && pastSales) {
                        this.results = saleReport;
                        this.pastSales.lastMonth = pastSales.last_month_sales;
                        this.pastSales.lastYear = pastSales.last_year_sales;

                        this.$wait.end('fetching.saleReport');
                    }
                } catch (error) {
                    this.$notify.warning(this.$t('dashboards.alerts.stats.error'));
                    this.$wait.end('fetching.saleReport');
                }
            },
        },
    };
</script>
