// Import all components from columns folder
import { upperFirst, camelCase, get } from 'lodash-es';

const components = {};
const requireComponent = require.context('../components/dashboards/cards', true, /\.(js|vue)$/i);

requireComponent.keys().forEach(fileName => {
    // Get component config
    const componentConfig = requireComponent(fileName);

    // Get PascalCase name of component
    const componentName = upperFirst(
        camelCase(
            fileName
                .split('/')
                .pop()
                .replace(/\.\w+$/, ''),
        ),
    );

    components[componentName] = componentConfig.default || componentConfig;
});

export default {
    components: {
        ...components,
    },

    computed: {
        smallCardsConfig() {
            return {
                allLead: {
                    title: this.$tc('allLead.clients', get(this.cards, 'leads.number.count')),
                    valid: true,
                    number: get(this.cards, 'leads.number.count'),
                    backNumber: get(this.cards, 'leads.assigned.count'),
                    backTitle: this.$tc('dashboards.assignedClient', get(this.cards, 'leads.assigned.count')),
                },
                webOrder: {
                    title: this.authUser.hasWebOrder()
                        ? this.$t('allLead.clientsWebAndOrder')
                        : this.$tc('allLead.clientsWeb'),
                    valid: true,
                    number: get(this.cards, 'leadsWebAndOrder.number.count'),
                    backIcon: this.$icons.leadXpress,
                    componentDescription: 'WebOrderDescription',
                },
                leadsUp: {
                    title: this.$t('allLead.clientsUp'),
                    valid: true,
                    number: get(this.cards, 'leadsUp.number.count'),
                    backIcon: this.$icons.upSale,
                    componentDescription: 'LeadsUpDescription',
                },
                renewalLoyalty: {
                    title: this.$tc('allLead.clientsRenewalLoyalty', get(this.cards, 'leadsOther.number.count')),
                    valid: true,
                    number: get(this.cards, 'leadsOther.number.count'),
                    componentSwitch: 'RenewalLoyaltySwitch',
                },
                walkIn: {
                    title: this.$tc('walkIn.walkIn', get(this.cards, 'walkIn.number.count')),
                    valid: true,
                    number: get(this.cards, 'walkIn.number.count'),
                    backNumber: get(this.cards, 'walkIn.assigned.count'),
                    backTitle: this.$tc('dashboards.assignedWalkIn', get(this.cards, 'walkIn.assigned.count')),
                },
                roadTest: {
                    title: this.$tc('walkIn.roadTest', get(this.cards, 'roadTest.number.count')),
                    valid: true,
                    number: get(this.cards, 'roadTest.number.count'),
                    backIcon: this.$icons.roadTest,
                    componentDescription: 'RoadTestDescription',
                },
                takeOver: {
                    title: this.$t('walkIn.takeOver'),
                    valid: true,
                    number: get(this.cards, 'takeOver.number.count'),
                    backIcon: this.$icons.takeOver,
                    componentDescription: 'TakeOverDescription',
                },
                csi: {
                    title: this.$t('clientCard.csi'),
                    valid: this.contextAccount.csi || this.contextAccount.isPolestar(),
                    number: get(this.cards, 'csi.number.count'),
                    backIcon: this.$icons.csi,
                    componentDescription: 'CsiDescription',
                    informationTooltip: this.$t('walkIn.csiPopover'),
                },

                // Polestar
                renewal: {
                    title: this.$tc('allLead.clientsRenewal', get(this.cards, 'leadsRenewal.number.count')),
                    valid: true,
                    number: get(this.cards, 'leadsRenewal.number.count'),
                },
                phoneUp: {
                    title: this.$t('allLead.clientsPhoneUp'),
                    valid: true,
                    number: get(this.cards, 'leadsPhoneUp.number.count'),
                },
                walkInLeads: {
                    title: this.$tc('allLead.walkinLeads', get(this.cards, 'leadsWalkIn.number.count')),
                    valid: true,
                    number: get(this.cards, 'leadsWalkIn.number.count'),
                },
                webLeads: {
                    title: this.$tc('allLead.clientsWeb', get(this.cards, 'leadsWeb.number.count')),
                    valid: true,
                    number: get(this.cards, 'leadsWeb.number.count'),
                },
                receivedOrder: {
                    title: this.$tc('allLead.receivedOrder', get(this.cards, 'leadsWeb.sale.count')),
                    valid: true,
                    number: get(this.cards, 'leadsWeb.sale.count'),
                },
            };
        },

        bigCardsConfig() {
            return {
                sale: {
                    valid: true,
                    title: this.$tc('allLead.sales', get(this.cards, 'leads.sale.count')),
                    card: get(this.cards, 'leads.sale'),
                    denominator: get(this.cards, 'leads.number.count'),
                    icon: this.$icons.sale,
                    componentDescription: 'SaleBigDescription',
                },
                webOrder: {
                    valid: true,
                    title: this.$tc('allLead.salesWeb', get(this.cards, 'leadsWebAndOrder.sale.count')),
                    card: get(this.cards, 'leadsWebAndOrder.sale'),
                    denominator: get(this.cards, 'leadsWebAndOrder.number.count'),
                    icon: 'regular/earth-1',
                    componentDescription: 'WebOrderBigDescription',
                },
                upSale: {
                    valid: true,
                    title: this.$tc('allLead.salesUp', get(this.cards, 'leadsUp.sale.count')),
                    card: get(this.cards, 'leadsUp.sale'),
                    denominator: get(this.cards, 'leadsUp.number.count'),
                    icon: this.$icons.upSale,
                    componentDescription: 'UpSaleBigDescription',
                },
                renewalLoyalty: {
                    valid: true,
                    title: this.$tc('allLead.salesRenewalLoyalty', get(this.cards, 'leadsOther.sale.count')),
                    card: get(this.cards, 'leadsOther.sale'),
                    denominator: get(this.cards, 'leadsOther.number.count'),
                    icon: this.$icons.renewal,
                    description: this.$t('allLead.salesRenewalLoyaltyBack'),
                },
                delivery: {
                    valid: true,
                    title: this.$tc('allLead.deliveries', get(this.cards, 'leads.delivered.count')),
                    card: get(this.cards, 'leads.delivered'),
                    denominator: get(this.cards, 'leads.sale.count'),
                    icon: this.$icons.delivery,
                    informationTooltip: this.$t('allLead.deliveriesPopover'),
                    description: this.$t('allLead.deliveriesBack'),
                },
                beBack: {
                    valid: true,
                    title: this.$tc('walkIn.beBack', get(this.cards, 'beBack.number.count')),
                    card: get(this.cards, 'beBack.number'),
                    denominator: get(this.cards, 'walkIn.number.count'),
                    icon: this.$icons.beBack,
                    description: this.$t('walkIn.beBackCardBack'),
                },
                saleBeBack: {
                    valid: true,
                    title: this.$t('walkIn.saleBeBack'),
                    card: get(this.cards, 'saleBeBack.number'),
                    denominator: get(this.cards, 'beBack.number.count'),
                    icon: this.$icons.sale,
                    description: this.$t('walkIn.beBackCardBack'),
                },
                upSaleWalkIn: {
                    valid: true,
                    title: this.$tc('walkIn.saleUp', get(this.cards, 'saleUp.number.count')),
                    card: get(this.cards, 'saleUp.number'),
                    denominator: this.noBeBackLead,
                    icon: this.$icons.upSale,
                    description: this.$t('walkIn.saleUpCardBack'),
                },
                saleWalkIn: {
                    valid: true,
                    title: this.$tc('walkIn.sale', get(this.cards, 'sale.number.count')),
                    card: get(this.cards, 'sale.number'),
                    denominator: get(this.cards, 'walkIn.number.count'),
                    icon: this.$icons.sale,
                    description: this.$t('walkIn.saleCardBack'),
                },
                deliveryWalkIn: {
                    valid: true,
                    title: this.$tc('walkIn.deliveredVehicle', get(this.cards, 'delivered.number.count')),
                    card: get(this.cards, 'delivered.number'),
                    denominator: get(this.cards, 'sale.number.count'),
                    icon: this.$icons.delivery,
                    description: this.$t('walkIn.deliveredCardBack'),
                },

                // Polestar
                allLeadWebOrder: {
                    valid: true,
                    title: this.$tc('allLead.salesWeb', get(this.cards, 'leadsWeb.sale.count')),
                    card: get(this.cards, 'leadsWeb.sale'),
                    denominator: get(this.cards, 'leads.number.count'),
                    icon: 'regular/earth-1',
                    componentDescription: 'SourceTestDriveBigDescription',
                },
                allLeadWalkIn: {
                    valid: true,
                    title: this.$tc('allLead.walkInOrder', get(this.cards, 'leadsWalkIn.sale.count')),
                    card: get(this.cards, 'leadsWalkIn.sale'),
                    denominator: get(this.cards, 'leads.number.count'),
                    icon: this.$icons.upSale,
                    description: this.$t('walkIn.saleFromWalkin'),
                },
                allLeadPhoneUp: {
                    valid: true,
                    title: this.$tc('allLead.PhoneUpOrder', get(this.cards, 'leadsPhoneUp.sale.count')),
                    card: get(this.cards, 'leadsPhoneUp.sale'),
                    denominator: get(this.cards, 'leads.number.count'),
                    icon: this.$icons.upSale,
                    description: this.$t('allLead.salesPhoneUp'),
                },
                renewal: {
                    valid: true,
                    title: this.$t('allLead.renewalOrders'),
                    card: get(this.cards, 'leadsOther.sale'),
                    denominator: get(this.cards, 'leads.number.count'),
                    icon: this.$icons.renewal,
                    description: this.$t('allLead.salesRenewalLoyaltyBack'),
                },
                delivered: {
                    valid: true,
                    title: this.$tc('allLead.delivered', get(this.cards, 'leads.delivered.count')),
                    card: get(this.cards, 'leads.delivered'),
                    denominator: get(this.cards, 'leads.number.count'),
                    icon: this.$icons.delivery,
                    description: this.$t('allLead.deliveriesBack'),
                },

                // Space Visit
                freshUpWalkIn: {
                    valid: true,
                    title: this.$t('walkIn.freshUp'),
                    card: get(this.cards, 'freshUp.number'),
                    denominator: get(this.cards, 'walkIn.number.count'),
                    icon: this.$icons.upSale,
                    description: this.$tc('walkIn.freshUpBack', get(this.cards, 'freshUp.number')),
                },
                ordersUpWalkIn: {
                    valid: true,
                    title: this.$tc('walkIn.ordersUp', get(this.cards, 'saleFirstWalk.number.count')),
                    card: get(this.cards, 'saleFirstWalk.number'),
                    denominator: get(this.cards, 'walkIn.number.count'),
                    icon: this.$icons.sale,
                    description: this.$tc('walkIn.ordersUpDescription', get(this.cards, 'saleFirstWalk.number.count')),
                },
                ordersBBackWalKIn: {
                    valid: true,
                    title: this.$tc('walkIn.saleBeBack', get(this.cards, 'saleBeBack.number.count')),
                    card: get(this.cards, 'saleBeBackTakeOver.number'),
                    denominator: get(this.cards, 'walkIn.number.count'),
                    icon: this.$icons.sale,
                    description: this.$tc('walkIn.beBackSaleCardBack', get(this.cards, 'saleBeBackTakeOver.number.count')),
                },
                totalOrdersWalkIn: {
                    valid: true,
                    title: this.$tc('walkIn.totalOrders', get(this.cards, 'sale.number.count')),
                    card: get(this.cards, 'sale.number'),
                    denominator: get(this.cards, 'walkIn.number.count'),
                    icon: this.$icons.sale,
                    description: this.$tc('walkIn.totalOrdersBack', get(this.cards, 'sale.number.count')),
                },

                deliveredWalkIn: {
                    valid: true,
                    title: this.$tc('walkIn.delivered', get(this.cards, 'delivered.number.count')),
                    card: get(this.cards, 'delivered.number'),
                    denominator: get(this.cards, 'walkIn.number.count'),
                    icon: this.$icons.delivery,
                    description: this.$t('walkIn.deliveredCardBack'),
                },
            };
        },

        activeSmallCards() {
            return Object.keys(this.smallCards)
                .map(name => {
                    return {
                        name,
                        ...this.smallCards[name],
                        ...this.smallCardsConfig[name],
                    };
                })
                .filter(card => {
                    return card.valid && card.visible;
                });
        },

        activeBigCards() {
            return Object.keys(this.bigCards)
                .map(name => {
                    return {
                        name,
                        ...this.bigCards[name],
                        ...this.bigCardsConfig[name],
                    };
                })
                .filter(card => {
                    return card.valid && card.visible;
                });
        },

        smallCardClass() {
            const countCard = this.activeSmallCards.length;
            return `xl:w-1/${countCard}`;
        },

        bigCardClass() {
            const countCard = this.activeBigCards.length;

            return `xl:w-1/${countCard}`;
        },
    },
};
