import { render, staticRenderFns } from "./DashboardActionIcon.vue?vue&type=template&id=727190d3&"
import script from "./DashboardActionIcon.vue?vue&type=script&lang=js&"
export * from "./DashboardActionIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports