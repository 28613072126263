<template>
    <el-dropdown class="max-w-full" placement="bottom-start" trigger="click">
        <activix-button class="flex items-center max-w-full" :type="hasCurrentView ? 'white' : 'outline'">
            <h1 class="m-0 truncate text-xl font-semibold text-grey-650" v-text="title" />
            <icon class="text-grey-600 ml-4 flex-shrink-0 text-xs" :name="$icons.expand" />
        </activix-button>
        <el-dropdown-menu class="max-w-full" slot="dropdown">
            <el-option-group>
                <el-dropdown-item class="flex group">
                    <router-link
                        class="flex-1 pl-2 truncate"
                        :class="!hasCurrentView ? 'text-blue-500 font-bold' : 'text-inherit'"
                        :to="getViewLink()"
                    >
                        {{ pageTitle }}
                    </router-link>
                </el-dropdown-item>
            </el-option-group>
            <el-option-group :label="group.label" :key="group.label" v-for="group in groupViews">
                <el-dropdown-item class="group" :key="view.id" v-for="view in group.options">
                    <div class="flex items-baseline">
                        <router-link
                            class="flex-1 pl-2 pr-4 truncate"
                            :class="
                                hasCurrentView && currentView.id == view.id ? 'text-blue-500 font-bold' : 'text-inherit'
                            "
                            :to="getViewLink(view)"
                        >
                            {{ view.name }}
                            <icon class="text-sm ml-1" name="regular/calendar-sync" v-if="viewHasSubscription(view)" />
                        </router-link>
                        <div
                            class="transition-opacity duration-150 opacity-0 | group-hover:opacity-100"
                            :class="{ 'opacity-100': lgLayout }"
                        >
                            <icon
                                name="regular/pencil-1"
                                class="link-grey-light | text-sm"
                                @click="$emit('update-view', view)"
                                v-if="!isCreatedBySystem(view)"
                            />
                            <icon
                                :name="$icons.trash"
                                class="link-grey-light text-sm ml-2 | hover:text-red-500"
                                @click="$emit('delete-view', view)"
                                v-if="!isCreatedBySystem(view)"
                            />
                        </div>
                    </div>
                </el-dropdown-item>
            </el-option-group>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
    import { cloneDeep, isEmpty, get } from 'lodash-es';
    import { mapState } from 'pinia';
    import { sort } from '../utils/index.js';
    import { useContextStore } from '../store/modules/context/store.js';
    import { useGlobalStore } from '../store/store.js';

    export default {
        props: {
            views: {
                type: Array,
                required: true,
            },
            currentView: {
                type: Object,
                default: null,
            },
            selectedView: {
                type: Object,
                default: null,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['pageTitle']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            showGroups() {
                return !isEmpty(this.systemViews) || !isEmpty(this.myViews);
            },

            hasCurrentView() {
                return !!this.currentView && !!this.currentView.id;
            },

            sortedViews() {
                const views = cloneDeep(this.views);
                return sort(views, 'name');
            },

            title() {
                if (this.hasCurrentView) {
                    return this.currentView.name;
                }

                return this.pageTitle;
            },

            systemViews() {
                return this.sortedViews.filter(view => {
                    return view.system_generated;
                });
            },

            myViews() {
                return this.sortedViews.filter(view => {
                    return !view.system_generated;
                });
            },

            groupViews() {
                const groupViews = [];

                if (!isEmpty(this.myViews)) {
                    groupViews.push({
                        label: this.$t('viewSelect.myViews'),
                        options: this.myViews,
                    });
                }

                if (!isEmpty(this.systemViews)) {
                    groupViews.push({
                        label: this.$t('viewSelect.system'),
                        options: this.systemViews,
                    });
                }

                return groupViews;
            },
        },

        methods: {
            viewHasSubscription(view) {
                return view.subscription && get(view.subscription, 'recurrence', null) != 'noSubscription';
            },

            isCreatedBySystem(view) {
                if (!view) {
                    return false;
                }

                return view.system_generated;
            },

            lastNotificationDate(viewType) {
                if (viewType === 'renewalAlertEquity') {
                    return now().day(1);
                }

                return now();
            },

            getViewLink(view = null) {
                return {
                    name: this.$route.name,
                    query: {
                        viewId: view ? view.id : null,
                    },
                };
            },
        },
    };
</script>
