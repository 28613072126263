/* eslint-disable max-classes-per-file */
import { debounce } from 'lodash-es';

class Element {
    constructor(element, onScroll) {
        this.element = element;
        this.onScroll = onScroll;
        this.debouncedBind = debounce(this.bind, 100);
    }

    bind() {
        this.element.addEventListener('scroll', this.onScroll);
    }

    unbind() {
        this.element.removeEventListener('scroll', this.onScroll);
    }

    destroy() {
        this.unbind();
        this.debouncedBind.cancel();
    }
}

class SyncScroll {
    constructor(elements) {
        this.boundOnScroll = this.onScroll.bind(this);
        this.elements = elements.map(element => new Element(element, this.boundOnScroll));
        this.elements.forEach(el => el.bind());
    }

    onScroll(event) {
        const otherElements = this.elements.filter(el => !event.target.isSameNode(el.element));

        otherElements.forEach(el => el.unbind());
        otherElements.forEach(el => { el.element.scrollLeft = event.target.scrollLeft; });
        otherElements.forEach(el => el.debouncedBind());
    }

    destroy() {
        this.elements.forEach(el => el.destroy());
    }
}

export default SyncScroll;
