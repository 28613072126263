<template>
    <el-table
        class="w-full border-t-2"
        border
        stripe
        :empty-text="isLoading ? $t('dashboards.statsTableLoading') : ''"
        :data="tableData"
        :default-sort="sort"
        :show-summary="true"
        :summary-method="formatTotals"
        ref="table"
        @sort-change="sortChange"
    >
        <el-table-column label-class-name="sticky-column">
            <el-table-column
                prop="name"
                :fixed="true"
                header-align="center"
                :label="nameColumnText"
                min-width="200"
                sort-by="name"
                :sortable="true"
                :sort-method="sortNameColumn"
                :sort-orders="['descending', 'ascending']"
                class-name="sticky-column"
            >
                <template slot-scope="{ row }">
                    <name-cell
                        :row="row"
                        :sub-level-key="filters.subLevelKey"
                        :show-sub-level="showSubLevel"
                        @toggle-row="toggleRow"
                    />
                </template>
            </el-table-column>
        </el-table-column>
        <el-table-column header-align="center" :label="$t('dashboards.stats.headers.process')">
            <el-table-column
                prop="total_leads"
                :label="$tc('general.client', 0)"
                header-align="center"
                align="center"
                min-width="125"
                sort-by="total_leads"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="total_appointments"
                :label="$tc('webBoost.appointment', 0)"
                header-align="center"
                align="center"
                min-width="105"
                sort-by="total_appointments"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }} {{ toPercent(row.total_appointments, row.total_leads) }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        :percentage="['total_appointments', 'total_leads']"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="total_appointments_confirmed"
                :label="$t('service.columns.confirmation')"
                header-align="center"
                align="center"
                min-width="125"
                sort-by="total_appointments_confirmed"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }} {{ toPercent(row.total_appointments_confirmed, row.total_leads) }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        :percentage="['total_appointments_confirmed', 'total_leads']"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
        </el-table-column>
        <el-table-column header-align="center" :label="$t('dashboards.stats.headers.visits')">
            <el-table-column
                prop="total_leads_presented_with_appointment"
                :label="$tc('leadXpress.visitWithAppointment', 0)"
                header-align="center"
                align="center"
                sort-by="total_leads_presented_with_appointment"
                min-width="95"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }}
                        {{ toPercent(row.total_leads_presented_with_appointment, row.total_appointments) }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        :percentage="['total_leads_presented_with_appointment', 'total_appointments']"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="total_leads_presented_without_appointment"
                :label="$tc('leadXpress.visitWithoutAppointment', 0)"
                header-align="center"
                align="center"
                sort-by="total_leads_presented_without_appointment"
                min-width="95"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot="header">
                    {{ $tc('leadXpress.visitWithoutAppointment', 0) }}
                    <activix-tooltip placement="top" :content="$t('phoneUp.visitWithoutAppointmentTooltip')">
                        <icon name="regular/information-circle" class="text-grey-500 text-sm ml-1" />
                    </activix-tooltip>
                </template>
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }}
                        {{ toPercent(row.total_leads_presented_without_appointment, row.total_leads) }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        :percentage="['total_leads_presented_without_appointment', 'total_leads']"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
        </el-table-column>
        <el-table-column header-align="center" :label="$t('dashboards.stats.headers.soldServices')">
            <el-table-column
                prop="category_maintenance"
                :label="$t('service.categories.maintenance')"
                header-align="center"
                align="center"
                min-width="95"
                sort-by="category_maintenance"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="category_alignment"
                :label="$t('service.categories.alignment')"
                header-align="center"
                align="center"
                min-width="95"
                sort-by="category_alignment"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="category_brakes"
                :label="$t('service.categories.brakes')"
                header-align="center"
                align="center"
                min-width="95"
                sort-by="category_brakes"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="category_transmission"
                :label="$t('service.categories.transmission')"
                header-align="center"
                align="center"
                min-width="95"
                sort-by="category_transmission"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="category_motor"
                :label="$t('service.categories.motor')"
                header-align="center"
                align="center"
                sort-by="category_motor"
                min-width="95"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="category_wash"
                :label="$t('service.categories.wash')"
                header-align="center"
                align="center"
                sort-by="category_wash"
                min-width="95"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="category_tires"
                :label="$t('service.categories.tires')"
                header-align="center"
                align="center"
                sort-by="category_tires"
                min-width="95"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="category_parts"
                :label="$t('service.categories.parts')"
                header-align="center"
                align="center"
                min-width="95"
                sort-by="category_parts"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="category_body"
                :label="$t('service.categories.body')"
                header-align="center"
                align="center"
                min-width="95"
                sort-by="category_body"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="category_others"
                :label="$t('service.categories.others')"
                header-align="center"
                align="center"
                min-width="95"
                sort-by="category_others"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
        </el-table-column>
    </el-table>
</template>

<script>
    // Mixins
    import StatsTableMixin from '../../mixins/StatsTable.js';

    // Components
    import NameCell from './Components/NameCell.vue';
    import SubLevelCell from './Components/SubLevelCell.vue';

    export default {
        components: {
            NameCell,
            SubLevelCell,
        },

        mixins: [StatsTableMixin],

        props: {
            tableData: {
                type: Array,
                default: () => [],
            },
            filters: {
                type: Object,
                default: () => {},
            },
            isLoading: {
                type: Boolean,
                default: false,
            },
            nameColumnText: {
                type: String,
                default: '',
            },
            showActivityColumns: {
                type: Boolean,
                default: false,
            },
            showSubLevel: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                columnsTotals: [
                    {
                        type: 'percent',
                        numerator: 'total_appointments',
                        denominator: 'total_leads',
                    },
                    {
                        type: 'percent',
                        numerator: 'total_phone_appointments',
                        denominator: 'total_leads',
                    },
                    {
                        type: 'percent',
                        numerator: 'total_appointments_confirmed',
                        denominator: 'total_leads',
                    },
                    {
                        type: 'percent',
                        numerator: 'total_leads_presented_with_appointment',
                        denominator: 'total_appointments',
                    },
                    {
                        type: 'percent',
                        numerator: 'total_leads_presented_without_appointment',
                        denominator: 'total_leads',
                    },
                ],
            };
        },
    };
</script>
