<template>
    <component :is="dashboard" />
</template>

<script>
    import { mapState } from 'pinia';
    import LegacyBase from '../../LegacyDashboards/Base.vue';
    import NewBase from '../Dashboard.vue';
    import DashboardType from '../../../entities/DashboardType.js';
    import { useDashboardStore } from '../../../store/modules/dashboard/store.js';

    export default {
        components: {
            LegacyBase,
            NewBase,
        },

        computed: {
            ...mapState(useDashboardStore, ['dashboardType']),

            dashboard() {
                return this.dashboardType == DashboardType.ACTIVITY ? 'LegacyBase' : 'NewBase';
            },
        },
    };
</script>
