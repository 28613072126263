<template>
    <div>
        <div class="flex flex-wrap lg:-mx-3">
            <card-small
                class="px-3 w-full | xs:w-1/2 | xl:w-1/5"
                :number="cards.leads.number.count"
                :title="$tc('searchLead.clients', cards.leads.number.count)"
                :back-number="cards.leads.assigned.count"
                :back-title="$tc('dashboards.assignedClient', cards.leads.assigned.count)"
            />
            <card-small
                class="px-3 w-full | xs:w-1/2 | xl:w-1/5"
                :number="cards.location.number.count"
                :title="$tc('renewal.location', cards.location.number.count)"
            />
            <card-small
                class="px-3 w-full | xs:w-1/2 | xl:w-1/5"
                :number="cards.financing.number.count"
                :title="$t('clientCard.products.financing')"
            />
            <card-small
                class="px-3 w-full | xs:w-1/2 | xl:w-1/5"
                :number="cards.service.number.count"
                :title="$t('divisions.service')"
            />
            <card-small
                class="px-3 w-full | xl:w-1/5"
                :number="cards.notSold.number.count"
                :title="$t('clientCard.notSold')"
            />
        </div>

        <div class="flex flex-wrap lg:-mx-3">
            <card
                class="px-3 w-full | xs:w-1/3 | xl:w-1/6"
                :icon="$icons.reached"
                :card="cards.reached.number"
                :denominator="cards.leads.number.count"
                :title="$tc('event.reached', cards.reached.number.count)"
            >
                <template #description>
                    <div class="text-xl">
                        {{ $t('event.reachedBack') }}
                    </div>
                </template>
            </card>
            <card
                class="px-3 w-full | xs:w-1/3 | xl:w-1/6"
                icon="regular/calendar-favorite-heart"
                :card="cards.potentialAppointment.number"
                :denominator="cards.reached.number.count"
                :title="$tc('event.potentialAppointment', cards.potentialAppointment.number.count)"
            >
                <template #description>
                    <div class="flex justify-center text-lg -mt-1 whitespace-nowrap">
                        <div class="text-right overflow-hidden">
                            <div class="mt-1 truncate">
                                {{ $t('event.apptNoDate') }}
                            </div>
                            <div class="mt-1 truncate">
                                {{ $t('event.leadToReach') }}
                            </div>
                            <div class="mt-1 truncate">
                                {{ $tc('event.uncertainLead', cards.potentialAppointment.uncertain) }}
                            </div>
                        </div>
                        <div class="text-right ml-3">
                            <div class="mt-1">
                                <span class="font-bold">{{ cards.potentialAppointment.noDate }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="font-bold">{{ cards.potentialAppointment.toReach }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="font-bold">{{ cards.potentialAppointment.uncertain }}</span>
                            </div>
                        </div>
                    </div>
                </template>
            </card>
            <card
                class="px-3 w-full | xs:w-1/3 | xl:w-1/6"
                :icon="$icons.appointment"
                :card="cards.appointment.number"
                :denominator="cards.reached.number.count"
                :title="$tc('event.appointment', cards.appointment.number.count)"
            >
                <template #description>
                    <div class="flex justify-center text-lg -mt-1 whitespace-nowrap">
                        <div class="text-right overflow-hidden">
                            <div class="mt-1 truncate">
                                {{ $tc('result.confirmed', cards.appointment.confirmed) }}
                            </div>
                            <div class="mt-1 truncate">
                                {{ $t('result.noShow') }}
                            </div>
                            <div class="mt-1 truncate">
                                {{ $tc('result.canceled', cards.appointment.canceled) }}
                            </div>
                        </div>
                        <div class="text-left ml-3">
                            <div class="mt-1">
                                <span class="font-bold">{{ cards.appointment.confirmed }} </span>
                            </div>
                            <div class="mt-1">
                                <span class="font-bold">{{ cards.appointment.noShow }} </span>
                            </div>
                            <div class="mt-1">
                                <span class="font-bold">{{ cards.appointment.canceled }} </span>
                            </div>
                        </div>
                        <div class="text-left ml-3">
                            <div class="mt-1">
                                <span class="text-grey-600 text-base">({{ confirmedApptPercent }})</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-grey-600 text-base">({{ noShowApptPercent }})</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-grey-600 text-base">({{ canceledApptPercent }})</span>
                            </div>
                        </div>
                    </div>
                </template>
            </card>
            <card
                class="px-3 w-full | xs:w-1/3 | xl:w-1/6"
                :icon="$icons.visit"
                :card="cards.presented.number"
                :denominator="cards.appointment.number.count"
                :title="$tc('event.presented', cards.presented.number.count)"
            >
                <template #description>
                    <div class="flex justify-center text-lg -mt-1">
                        <div class="text-right">
                            <div class="mt-1">
                                {{ $t('event.visitWithAppointment') }}
                            </div>
                            <div class="mt-1">
                                {{ $t('event.visitWithoutAppointment') }}
                            </div>
                        </div>
                        <div class="text-right ml-3">
                            <div class="mt-1">
                                <span class="font-bold">{{ visitWithAppt }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="font-bold">{{ cards.presented.number.countWithoutAppointment }}</span>
                            </div>
                        </div>
                        <div class="text-left ml-3">
                            <div class="mt-1">
                                <span class="text-grey-600 text-base">({{ visitWithApptPercent }})</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-grey-600 text-base">({{ visitWithoutApptPercent }})</span>
                            </div>
                        </div>
                    </div>
                </template>
            </card>
            <card
                class="px-3 w-full | xs:w-1/3 | xl:w-1/6"
                :icon="$icons.sale"
                :card="cards.sale.number"
                :denominator="cards.presented.number.count"
                :title="$tc('renewal.sold', cards.sale.number.count)"
            >
                <template #description>
                    <div class="text-xl">
                        {{ $t('event.saleCardBack') }}
                    </div>
                    <div class="flex justify-center text-lg mt-2" v-if="contextAccount.sale_by_phone">
                        <div class="text-right">
                            <div class="mt-1">
                                {{ $tc('result.saleByPhone', cards.saleByPhone.count) }}
                            </div>
                        </div>
                        <div class="text-left ml-3">
                            <div class="mt-1">
                                <span class="font-bold">{{ cards.saleByPhone.count }}</span>
                            </div>
                        </div>
                    </div>
                </template>
            </card>
            <card
                class="px-3 w-full | xs:w-1/3 | xl:w-1/6"
                :icon="$icons.lost"
                :card="cards.lost.number"
                :denominator="cards.reached.number.count"
                :title="$t('clientCard.lost')"
            >
                <template #description>
                    <div class="text-xl">
                        {{ $t('event.lostBack') }}
                    </div>
                </template>
            </card>
        </div>

        <stats-table :dashboard="dashboard" ref="statsTable" />
    </div>
</template>

<script>
    // Utils
    import { mapState } from 'pinia';
    import { toPercentString } from '../../utils/numbers.js';

    // Components
    import Card from '../../components/dashboards/cards/Card.vue';
    import CardSmall from '../../components/dashboards/cards/CardSmall.vue';
    import StatsTable from '../StatsTables/StatsTable.vue';

    // Mixins
    import TrackView from '../../mixins/TrackView.js';
    import DashboardCaching from '../../mixins/DashboardCaching.js';
    import DashboardCustomFields from '../../mixins/DashboardCustomFields.js';

    // Entities
    import DashboardType from '../../entities/DashboardType.js';

    // Pinia
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        name: 'Event',

        components: {
            Card,
            CardSmall,
            StatsTable,
        },

        mixins: [TrackView, DashboardCaching, DashboardCustomFields],

        data() {
            return {
                dashboard: DashboardType.EVENT,
                cards: {
                    leads: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                        assigned: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                    },
                    location: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                    },
                    financing: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                    },
                    service: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                    },
                    notSold: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                    },
                    reached: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'blue',
                            },
                        },
                    },
                    potentialAppointment: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'blue',
                            },
                        },
                        noDate: 0,
                        toReach: 0,
                        uncertain: 0,
                    },
                    appointment: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'blue',
                            },
                        },
                        call: 0,
                        all: 0,
                        confirmed: 0,
                        noShow: 0,
                        canceled: 0,
                    },
                    presented: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'blue',
                            },
                        },
                    },
                    sale: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'blue',
                            },
                        },
                    },
                    saleByPhone: {
                        count: 0,
                    },
                    lost: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'blue',
                            },
                        },
                    },
                },
            };
        },

        computed: {
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            confirmedApptPercent() {
                return toPercentString(this.cards.appointment.confirmed, this.cards.appointment.number.count);
            },

            noShowApptPercent() {
                return toPercentString(this.cards.appointment.noShow, this.cards.appointment.number.count);
            },

            canceledApptPercent() {
                return toPercentString(this.cards.appointment.canceled, this.cards.appointment.number.count);
            },

            reachedPercent() {
                return toPercentString(this.cards.reached.number.count, this.cards.leads.number.count);
            },

            potentialAppointmentPercent() {
                return toPercentString(this.cards.potentialAppointment.number.count, this.cards.reached.number.count);
            },

            appointmentPercent() {
                return toPercentString(this.cards.appointment.number.count, this.cards.reached.number.count);
            },

            presentedPercent() {
                return toPercentString(this.cards.presented.number.count, this.cards.appointment.number.count);
            },

            salePercent() {
                return toPercentString(this.cards.sale.number.count, this.cards.presented.number.count);
            },

            lostPercent() {
                return toPercentString(this.cards.lost.number.count, this.cards.reached.number.count);
            },

            visitWithApptPercent() {
                return toPercentString(this.visitWithAppt, this.cards.appointment.number.count);
            },

            visitWithoutApptPercent() {
                return toPercentString(
                    this.cards.presented.number.countWithoutAppointment,
                    this.cards.appointment.number.count,
                );
            },

            visitWithAppt() {
                return this.cards.presented.number.count - this.cards.presented.number.countWithoutAppointment;
            },
        },

        methods: {
            async fetchStats(options) {
                await this.$refs.statsTable.fetchStats(options);
            },

            setColumns() {
                useDashboardStore().configs.columns = {
                    bulk_select: {
                        visible: true,
                    },
                    fullname: {
                        visible: true,
                    },
                    contact_method: {
                        title: 'Contact',
                    },
                    civility: {
                        visible: false,
                    },
                    business: {
                        visible: false,
                    },
                    created_by: {
                        visible: false,
                    },
                    updated_by: {
                        visible: false,
                    },
                    date: {
                        visible: true,
                    },
                    updated_at: {
                        visible: false,
                    },
                    'customer:casl_consent_status': {
                        visible: false,
                    },
                    'customer:casl_consent_limit_date': {
                        visible: false,
                    },
                    'division:name': {
                        visible: true,
                    },
                    status: {
                        visible: true,
                    },
                    result: {
                        visible: true,
                    },
                    advisors: {
                        visible: false,
                    },
                    agents: {
                        visible: false,
                    },
                    'commercial:': {
                        visible: false,
                    },
                    rating: {
                        visible: false,
                    },
                    city: {
                        visible: false,
                    },
                    country: {
                        visible: false,
                    },
                    province: {
                        visible: false,
                    },
                    postal_code: {
                        visible: false,
                    },
                    address: {
                        visible: false,
                    },
                    locale: {
                        visible: false,
                    },
                    sector: {
                        visible: true,
                    },
                    budget: {
                        visible: true,
                    },
                    walk_around: {
                        visible: false,
                    },
                    created_method: {
                        visible: false,
                    },
                    lead_state: {
                        visible: false,
                    },
                    'import:original_file': {
                        visible: false,
                    },
                    'wanted_vehicles:make': {
                        visible: true,
                    },
                    'wanted_vehicles:model': {
                        visible: true,
                    },
                    'wanted_vehicles:year': {
                        visible: true,
                    },
                    'wanted_vehicles:fuel': {
                        visible: false,
                    },
                    'wanted_vehicles:version': {
                        visible: false,
                    },
                    'wanted_vehicles:certified': {
                        visible: false,
                    },
                    'wanted_vehicles:transmission': {
                        visible: false,
                    },
                    'wanted_vehicles:color_exterior': {
                        visible: false,
                    },
                    'wanted_vehicles:color_interior': {
                        visible: false,
                    },
                    'wanted_vehicles:vin': {
                        visible: false,
                    },
                    'wanted_vehicles:rate': {
                        visible: false,
                    },
                    'wanted_vehicles:modality': {
                        visible: true,
                    },
                    phone_appointment: {
                        visible: true,
                    },
                    appointment: {
                        visible: true,
                    },
                    presented: {
                        visible: true,
                    },
                    deposit: {
                        visible: false,
                    },
                    long_term: {
                        visible: false,
                    },
                    write_up: {
                        visible: false,
                    },
                    progress_state: {
                        visible: true,
                    },
                    approved: {
                        visible: true,
                    },
                    'lead_bank:name': {
                        visible: false,
                    },
                    waiting_sale: {
                        visible: false,
                    },
                    twenty_four_hour: {
                        visible: true,
                    },
                    available: {
                        visible: false,
                    },
                    paperwork: {
                        visible: false,
                    },
                    prepared: {
                        visible: true,
                    },
                    prepared_work_order: {
                        visible: false,
                    },
                    sale_by_phone: {
                        visible: false,
                    },
                    sale: {
                        visible: true,
                    },
                    gas: {
                        visible: false,
                    },
                    deliverable: {
                        visible: false,
                    },
                    delivered: {
                        visible: true,
                    },
                    refinanced: {
                        visible: true,
                        mandatory: false,
                    },
                    'wanted_vehicles:recorded_date': {
                        visible: false,
                    },
                    csi: {
                        visible: true,
                    },
                    'event_campaigns:name': {
                        visible: true,
                    },
                    segment: {
                        visible: true,
                    },
                    'exchange_vehicles:make': {
                        visible: false,
                    },
                    'exchange_vehicles:model': {
                        visible: false,
                    },
                    'exchange_vehicles:year': {
                        visible: false,
                    },
                    'exchange_vehicles:version': {
                        visible: false,
                    },
                    'exchange_vehicles:certified': {
                        visible: false,
                    },
                    'exchange_vehicles:vin': {
                        visible: false,
                    },
                    'exchange_vehicles:transmission': {
                        visible: false,
                    },
                    'exchange_vehicles:color_exterior': {
                        visible: false,
                    },
                    'exchange_vehicles:color_interior': {
                        visible: false,
                    },
                    'exchange_vehicles:condition': {
                        visible: false,
                    },
                    'exchange_vehicles:fuel': {
                        visible: false,
                    },
                    'exchange_vehicles:sold_by': {
                        visible: true,
                    },
                    'exchange_vehicles:modality': {
                        visible: true,
                    },
                    'exchange_vehicles:payment': {
                        visible: true,
                    },
                    'exchange_vehicles:frequency': {
                        visible: true,
                    },
                    'exchange_vehicles:allowed_mileage': {
                        visible: true,
                    },
                    'exchange_vehicles:mo_remaining': {
                        visible: true,
                    },
                    'exchange_vehicles:tire_type': {
                        visible: false,
                    },
                    'exchange_vehicles:accidented_damage_cost': {
                        visible: false,
                    },
                    'exchange_vehicles:value': {
                        visible: true,
                    },
                    'exchange_vehicles:actual_value': {
                        visible: false,
                    },
                    'exchange_vehicles:calculator_appraiser': {
                        visible: false,
                    },
                    'exchange_vehicles:calculator_value': {
                        visible: true,
                    },
                    'exchange_vehicles:balance': {
                        visible: true,
                    },
                    'exchange_vehicles:equity': {
                        visible: true,
                    },
                    'exchange_vehicles:mileage': {
                        visible: true,
                    },
                    'exchange_vehicles:warranty': {
                        visible: true,
                    },
                    'exchange_vehicles:end_contract_date': {
                        visible: true,
                    },
                    'exchange_vehicles:intention': {
                        visible: true,
                    },
                    'exchange_vehicles:rate': {
                        visible: false,
                    },
                    listening: {
                        visible: true,
                    },
                    followed: {
                        visible: true,
                    },
                    followed_by: {
                        visible: false,
                    },
                    todo: {
                        visible: true,
                    },
                    source: {
                        visible: true,
                    },
                    form: {
                        visible: false,
                    },
                    'latest_comment:content': {
                        visible: true,
                    },
                    unsubscribe: {
                        visible: false,
                    },
                    birth_date: {
                        visible: true,
                    },
                    ...this.getCustomFields(),
                };
            },

            setCards(results) {
                this.cards.leads.number.count = results.count;
                this.cards.leads.assigned.count = results.assignments;

                this.cards.appointment.number.count = results.appointments.count;
                this.cards.appointment.call = results.appointments.byPhone;
                this.cards.appointment.noShow = results.appointments.noShow;
                this.cards.appointment.confirmed = results.appointments.confirmed;
                this.cards.appointment.canceled = results.appointments.canceled;

                this.cards.potentialAppointment.number.count = results.potentialAppointments.count;
                this.cards.potentialAppointment.noDate = results.potentialAppointments.noDate;
                this.cards.potentialAppointment.toReach = results.potentialAppointments.toReach;
                this.cards.potentialAppointment.uncertain = results.potentialAppointments.uncertain;

                this.cards.presented.number.count = results.visits.count;
                this.cards.presented.number.countWithoutAppointment = results.visits.withoutAppointment;

                this.cards.sale.number.count = results.sales.count;
                this.cards.saleByPhone.count = results.sales.byPhone;

                this.cards.lost.number.count = results.losses;

                this.cards.notSold.number.count = results.segments.notSold;
                this.cards.service.number.count = results.segments.service;

                this.cards.location.number.count = results.modalities.leasing;
                this.cards.financing.number.count = results.modalities.financing;

                this.cards.reached.number.count = results.results.reached;

                this.$nextTick(() => {
                    this.setCaching('cards', this.cards);
                });
            },
        },
    };
</script>
