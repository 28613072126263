import Enum from './Enum.js';

export default class SaleTableProfitReferenceDate extends Enum {
    static get entries() {
        return {
            saleDate: 'sale_date',
            recordedDate: 'recorded_date',
            deliveredDate: 'delivered_date',
        };
    }

    static get SALE_DATE() {
        return this.entries.saleDate;
    }

    static get RECORDED_DATE() {
        return this.entries.recordedDate;
    }

    static get DELIVERED_DATE() {
        return this.entries.deliveredDate;
    }

    static get DEFAULT() {
        return this.DELIVERED_DATE;
    }
}
