<template>
    <activix-modal
        size="xs"
        :name="name"
        :loading="loading"
        :loading-overlay="false"
        portal="modal-2"
        ref="modal"
        @before-open="onBeforeOpen"
        @closed="onClosed"
    >
        <template slot="header">
            <h4 class="modal-title">
                {{ title }}
            </h4>
        </template>

        <template slot="body">
            <div class="flex flex-col px-3 | transition duration-200">
                <div class="text-lg mb-2 cursor-default" v-if="vehicle">
                    <activix-tooltip :content="tooltip">
                        <div v-if="vehicle.make || vehicle.model || vehicle.year">
                            <span class="font-semibold" v-text="vehicle.make" /> {{ vehicle.model }} {{ vehicle.year }}
                        </div>
                        <div class="italic" v-text="$t('dashboards.customFields.unknownVehicle')" v-else />
                    </activix-tooltip>
                </div>

                <custom-input
                    :field="accountCustomField"
                    @keyup.enter="onKeyUpEnter"
                    v-model="fieldValue"
                />
            </div>
        </template>

        <template slot="footer">
            <div class="flex items-center justify-center">
                <activix-button @click="close">
                    {{ $t('general.cancel') }}
                </activix-button>

                <activix-button type="primary" :disabled="disabledSave" @click="updateTextValue">
                    {{ $t('general.save') }}
                </activix-button>
            </div>
        </template>
    </activix-modal>
</template>

<script>
    // Components
    import { mapActions, mapState } from 'pinia';

    // Entities
    import Lead from '@/entities/Lead.js';
    import { useContextStore } from '@/store/modules/context/store.js';
    import { useGlobalStore } from '@/store/store.js';
    import CustomInput from '../CustomInput.vue';

    export default {
        components: {
            CustomInput,
        },

        props: {
            name: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                customField: null,
                customFieldId: null,
                title: '',
                lead: new Lead(),
                vehicle: null,
                loading: true,
                fieldValue: [],
            };
        },

        computed: {
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            disabledSave() {
                return this.guest;
            },

            accountCustomField() {
                if(this.customFieldId) {
                    return this.contextAccount.getCustomFieldById(this.customFieldId);
                }
                return null;
            },

            customValue() {
                return this.customField ? this.customField.pivot.value : [];
            },

            tooltip() {
                if (!this.vehicle.stock_state || !this.vehicle.stock) {
                    return '';
                }

                return `${this.stockStateText} ${this.vehicle.stock}`;
            },

            stockStateText() {
                const key = {
                    locate: 'leadVehicleStockStates.locate',
                    notAvailable: 'leadVehicleStockStates.notAvailable',
                    order: 'leadVehicleStockStates.order',
                    stock: 'leadVehicleStockStates.stock',
                }[this.vehicle.stock_state];

                return this.$t(key);
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction', 'fetchLead', 'updateLeadVehicleAction']),

            onKeyUpEnter() {
                if (this.accountCustomField?.type == 'textarea') {
                    return;
                }

                this.updateTextValue();
            },

            updateTextValue() {
                if (this.disabledSave) {
                    return;
                }

                if (this.vehicle) {
                    this.updateLeadVehicleAction(
                        {},
                        this.vehicle.id,
                        this.lead.id,
                        {
                            customField: {
                                id: this.customFieldId,
                                field: this.accountCustomField.field,
                                value: this.fieldValue,
                            },
                        },
                    );
                } else {
                    this.updateLeadAction(
                        this.lead.id,
                        {},
                        {
                            customField: {
                                id: this.customFieldId,
                                field: this.accountCustomField.field,
                                value: this.fieldValue,
                            },
                        },
                    );
                }

                this.close();
            },

            async onBeforeOpen({ leadId, vehicle, title, customField, customFieldId }) {
                this.customField = customField;
                this.customFieldId = customFieldId;
                this.title = title;
                this.vehicle = vehicle || null;
                this.lead = await this.fetchLead(leadId);
                this.onOpen();
                this.loading = false;
            },

            onOpen() {
                this.fieldValue = this.customField ? this.customField?.pivot?.value : [];
            },

            close() {
                this.$refs.modal.hide();
            },

            onClosed() {
                this.customField = null;
                this.customFieldId = null;
                this.vehicle = null;
                this.title = '';
                this.fieldValue = [];
                this.loading = true;
                this.lead = new Lead();
            },
        },
    };
</script>
