<template>
    <el-table
        class="w-full border-0 border-t-2"
        :border="true"
        :data="tableData"
        :empty-text="$t('activityReport.resultsByUser.noResults')"
        :default-sort="{ prop: 'name' }"
        :show-summary="true"
        :stripe="true"
        :sum-text="$t('activityReport.total')"
        @sort-change="updateTableSorting"
    >
        <el-table-column label="">
            <el-table-column
                prop="name"
                header-align="center"
                min-width="150"
                :label="
                    options.showAgent
                        ? $t('activityReport.resultsByUser.agents')
                        : $t('activityReport.resultsByUser.advisors')
                "
                :show-overflow-tooltip="true"
                :sortable="true"
            >
                <template slot-scope="{ row }">
                    <a @click="setContextUserAction(row.id)">{{ row.name }}</a>
                </template>
            </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('activityReport.stats.worked')" align="center">
            <el-table-column
                align="center"
                prop="leads"
                min-width="95"
                :label="$t('activityReport.stats.leads')"
                :sortable="true"
            />
            <el-table-column
                class-name="font-bold"
                align="center"
                prop="totalActivities"
                min-width="95"
                :label="$t('activityReport.stats.activities')"
                :sortable="true"
            />
        </el-table-column>
        <el-table-column :label="$t('activityReport.stats.incomingActivities')" align="center">
            <el-table-column
                class-name="font-bold"
                align="center"
                prop="incomingCommunications"
                min-width="95"
                :label="$t('activityReport.total')"
                :sortable="true"
            />
            <el-table-column
                align="center"
                prop="incomingCalls"
                min-width="95"
                :label="$t('activityReport.stats.incomingCalls')"
                :sortable="true"
            />
            <el-table-column
                align="center"
                prop="incomingEmails"
                min-width="95"
                :label="$t('activityReport.stats.emails')"
                :sortable="true"
            />
            <el-table-column
                align="center"
                prop="incomingTextMessage"
                min-width="95"
                :label="$t('activityReport.stats.textMessages')"
                :sortable="true"
            />
        </el-table-column>
        <el-table-column :label="$t('activityReport.stats.outgoingActivities')" align="center">
            <el-table-column
                class-name="font-bold"
                align="center"
                prop="outgoingCommunications"
                min-width="95"
                :label="$t('activityReport.total')"
                :sortable="true"
            />
            <el-table-column
                align="center"
                prop="outgoingCalls"
                min-width="95"
                :label="$t('activityReport.stats.calls')"
                :sortable="true"
            />
            <el-table-column
                align="center"
                prop="outgoingEmails"
                min-width="95"
                :label="$t('activityReport.stats.emails')"
                :sortable="true"
            />
            <el-table-column
                align="center"
                prop="outgoingTextMessages"
                min-width="95"
                :label="$t('activityReport.stats.textMessages')"
                :sortable="true"
            >
                <template slot="header" slot-scope="{ column }">
                    <div class="flex items-center justify-center">
                        {{ column.label }}
                        <activix-tooltip :content="$t('activityReport.resultsByUser.outgoingTextMessagesTooltip')">
                            <icon name="regular/information-circle" class="text-grey-500 text-sm ml-1" />
                        </activix-tooltip>
                    </div>
                </template>
            </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('activityReport.stats.performances')" align="center">
            <el-table-column
                align="center"
                prop="appointments"
                min-width="100"
                :label="$tc('activityReport.stats.appointments', 2)"
                :sortable="true"
            />
            <el-table-column
                align="center"
                prop="visits"
                min-width="95"
                :label="$tc('activityReport.stats.visits', 2)"
                :sortable="true"
            />
            <el-table-column
                align="center"
                prop="sales"
                min-width="95"
                :label="$tc('activityReport.stats.sales', 2)"
                :sortable="true"
            />
        </el-table-column>
    </el-table>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import DataTableMixin from '../../../mixins/DataTable.js';
    import { useDashboardStore } from '../../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../../store/modules/context/store.js';

    export default {
        mixins: [DataTableMixin],

        props: {
            tableData: {
                type: Array,
                required: true,
            },
        },

        computed: {
            ...mapState(useDashboardStore, {
                endDate: 'endDate',
                startDate: 'startDate',
                options: store => store.configs.options,
            }),
        },
        methods: {
            ...mapActions(useContextStore, ['setContextAccountAction', 'setContextUserAction']),
        },
    };
</script>
