<template>
    <div class="w-full">
        <input
            class="form-control"
            :class="activeFilterClass"
            :type="type"
            :placeholder="$t('dashboards.filterPlaceholder')"
            :value="filtersBuffer[column]"
            @keyup.enter="applyFilter"
            @input="updateFilters($event.target.value)"
        />
    </div>
</template>

<script>
    /* eslint-disable vue/require-prop-types */
    import { isEmpty } from 'lodash-es';

    // Pinia
    import { mapState } from 'pinia';
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {

        props: {
            column: {
                default: '',
            },
            filterApplied: {
                default: true,
            },
            filtersBuffer: {
                default() {
                    return {};
                },
            },
            type: {
                default: 'text',
            },
        },

        computed: {
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),
            ...mapState(useDashboardStore, {
                filters: store => store.configs.filters,
            }),

            activeFilterClass() {
                if (!isEmpty(this.filters[this.column])) {
                    return 'active-filter';
                }

                return '';
            },
        },

        methods: {
            applyFilter() {
                if (!this.filterApplied) {
                    this.$eventBus.$emit('apply-filters');
                }
            },

            updateFilters(value) {
                this.$emit('update', {
                    column: this.column,
                    value,
                });
            },
        },
    };
</script>
