<template>
    <el-popover placement="top" trigger="hover" v-if="colorScale || $slots.default">
        <icon
            class="absolute m-3 bottom-0 text-grey-500 bg-white"
            :class="positionClass"
            name="regular/information-circle"
            slot="reference"
        />
        <div class="p-4 max-w-sm">
            <template v-if="$slots.default">
                <slot />
            </template>
            <template v-else-if="type == 'time'">
                <div class="flex items-center">
                    <icon class="mr-1 text-red-500" name="bold/sign-badge-circle" scale="0.5" />
                    {{ colorScale.red }} sec +
                </div>
                <div class="flex items-center mt-2">
                    <icon class="mr-1 text-orange-500" name="bold/sign-badge-circle" scale="0.5" />
                    {{ colorScale.orange }} sec {{ $t('general.to2') }} {{ colorScale.red - 1 }} sec
                </div>
                <div class="flex items-center mt-2">
                    <icon class="mr-1 text-green-500" name="bold/sign-badge-circle" scale="0.5" />
                    {{ colorScale.orange - 1 }} sec -
                </div>
            </template>
            <template v-else-if="type == 'inverse'">
                <div class="flex items-center">
                    <icon class="mr-1 text-red-500" name="bold/sign-badge-circle" scale="0.5" />
                    {{ colorScale.red }}% +
                </div>
                <div class="flex items-center mt-2">
                    <icon class="mr-1 text-orange-500" name="bold/sign-badge-circle" scale="0.5" />
                    {{ colorScale.orange }}% {{ $t('general.to2') }} {{ colorScale.red - 1 }}%
                </div>
                <div class="flex items-center mt-2">
                    <icon class="mr-1 text-green-500" name="bold/sign-badge-circle" scale="0.5" />
                    {{ colorScale.orange - 1 }}% -
                </div>
            </template>
            <template v-else>
                <div class="flex items-center">
                    <icon class="mr-1 text-red-500" name="bold/sign-badge-circle" scale="0.5" />
                    {{ colorScale.orange - 1 }}% -
                </div>
                <div class="flex items-center mt-2">
                    <icon class="mr-1 text-orange-500" name="bold/sign-badge-circle" scale="0.5" />
                    {{ colorScale.orange }}% {{ $t('general.to2') }} {{ colorScale.green - 1 }}%
                </div>
                <div class="flex items-center mt-2">
                    <icon class="mr-1 text-green-500" name="bold/sign-badge-circle" scale="0.5" />
                    {{ colorScale.green }}% +
                </div>
            </template>
        </div>
    </el-popover>
</template>

<script>
    export default {
        props: {
            card: {
                type: Object,
                required: true,
            },
            type: {
                type: String,
                default: 'number',
            },
            position: {
                type: String,
                default: 'left',
            },
        },

        computed: {
            positionClass() {
                if (this.position == 'right') {
                    return 'right-0';
                }

                return 'left-0';
            },

            colorScale() {
                const colors = this.card.colors;

                if (!colors || Object.keys(colors).length === 1) {
                    return null;
                }

                let red = 0;
                let orange = 0;
                let green = 0;

                Object.keys(colors).forEach(key => {
                    if (colors[key] == 'red') {
                        red = key;
                    }

                    if (colors[key] == 'orange') {
                        orange = key;
                    }

                    if (colors[key] == 'green') {
                        green = key;
                    }
                });

                return {
                    red,
                    orange,
                    green,
                };
            },
        },
    };
</script>
