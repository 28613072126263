<template>
    <activix-tooltip :content="tooltip">
        <div class="input-wrapper">
            <activix-checkbox
                color="purple"
                :disabled="disabled"
                :value="checked"
                :size="size"
                :cross="isCross"
                @mouseenter.native="disabled || fetchLead(lead.id)"
                @click.native.prevent="onClick"
            />

            <activix-confirm-modal
                :approve-text="$t('dashboards.removeIt')"
                :content="$t('dashboards.deleteFollowUpConfirmation', [$t('dashboards.performance.sale_by_phone')])"
                :deny-text="$t('general.cancel')"
                :opened.sync="uncheckModalOpened"
                :title="$t('general.areYouSure')"
                type="error"
                @approve="triggerUncheck"
                @deny="updateChecked"
            />
            <activix-confirm-modal
                :approve-text="$t('followUp.addSaleByPhone')"
                :content="$t('followUp.saleIsByPhone')"
                :deny-text="$t('general.cancel')"
                :opened.sync="dashboardSaleByPhoneModalOpened"
                :title="$t('general.areYouSure')"
                portal="modal-2"
                @approve="openModalSelection"
            />
        </div>
    </activix-tooltip>
</template>

<script>
    import { mapActions, mapState } from 'pinia';

    import ActivixDate from '../../../../value-objects/ActivixDate.js';
    import TaskEventTypeIcon from '../../../../entities/TaskEventTypeIcon.js';
    import LeadType from '../../../../entities/LeadType.js';

    import FollowUpMixin from '../../../../mixins/FollowUpMixin.js';
    import { useGlobalStore } from '../../../../store/store.js';

    export default {
        mixins: [FollowUpMixin],

        props: {
            lead: {
                type: Object,
                required: true,
            },
            startDate: {
                type: ActivixDate,
                default: null,
            },
            endDate: {
                type: ActivixDate,
                default: null,
            },
            inClientCard: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                checked: false,
                uncheckModalOpened: false,
                dashboardSaleByPhoneModalOpened: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),

            date() {
                return new ActivixDate(this.lead.sale_date);
            },

            presentedDate() {
                return new ActivixDate(this.lead.presented_date).or(this.lead.home_presented_date);
            },

            localizedDate() {
                return this.date.toHumanShort(false);
            },

            isInDates() {
                return this.date.isBetween(this.startDate, this.endDate);
            },

            disabledByVehicleInfoRule() {
                return (
                    !this.lead.sale_date &&
                    this.lead.account.stock_required_for_sale &&
                    this.lead.wanted_vehicles.every(vehicle => {
                        return !vehicle.make || !vehicle.model;
                    })
                );
            },

            disabledByStockRule() {
                return (
                    !this.lead.sale_date &&
                    this.lead.account.client_card_fields.wanted_vehicle.stock &&
                    this.lead.account.stock_required_for_sale &&
                    this.lead.wanted_vehicles.every(vehicle => {
                        return !vehicle.make || !vehicle.model || this.emptyStock(vehicle);
                    })
                );
            },

            disabled() {
                if (['invalid', 'lost', 'duplicate'].includes(this.lead.status)) {
                    return true;
                }

                if (!this.inClientCard && !this.presentedDate.isEmpty() && this.date.isEmpty()) {
                    return true;
                }

                if (this.lead.lead_type_id == LeadType.RENEWAL) {
                    return true;
                }

                if (this.lead.sale_date && !this.lead.sale_by_phone) {
                    return true;
                }

                if (this.disabledByStockRule || this.disabledByVehicleInfoRule) {
                    return true;
                }

                if (this.lead.delivered_date && this.lead.lead_type_id != LeadType.RENEWAL) {
                    return true;
                }

                return this.guest;
            },

            tooltip() {
                let tooltip = '';

                if (!this.inClientCard) {
                    if (this.lead.sale_date && !this.lead.sale_by_phone) {
                        return this.$t('clientCard.carIsAlreadySold');
                    }

                    if (this.lead.lead_type_id == LeadType.RENEWAL) {
                        return this.$t('clientCard.notOnLeadRenewal');
                    }

                    if (this.lead.sale_by_phone && !this.date.isEmpty()) {
                        tooltip += `
                                <span class="font-bold mr-1">${this.$t('date.sale')}</span>
                                ${TaskEventTypeIcon.PHONE_APPOINTMENT}
                        `;

                        tooltip += `<br>${this.localizedDate}`;
                        if (!this.isInDates) {
                            tooltip += `<br><em>${this.$t('dashboards.notInDate')}</em>`;
                        }
                    }
                } else if (['invalid', 'lost', 'duplicate'].includes(this.lead.status)) {
                    tooltip += `<em>${this.$t(`clientCard.statusTooltip.${this.lead.status}`, [
                        this.$t('clientCard.statusTooltip.inputs.saleByPhone'),
                    ])}</em>`;
                }

                if (this.lead.delivered_date && this.lead.lead_type_id != LeadType.RENEWAL) {
                    tooltip += `<br><em>${this.$t('clientCard.needDeliveredUnchecked')}</em>`;
                }

                if (this.disabledByStockRule) {
                    return this.$t('clientCard.needVehicleInfoAndStockForSale');
                }

                if (this.disabledByVehicleInfoRule) {
                    return this.$t('clientCard.needVehicleInfoForSale');
                }

                return tooltip;
            },

            isCross() {
                if (this.inClientCard || this.date.isEmpty() || this.isInDates) {
                    return false;
                }

                return true;
            },

            shouldDisplaySelectVehicle() {
                return this.lead.wanted_vehicles.length > 1;
            },
        },

        watch: {
            'lead.sale_by_phone': {
                immediate: true,
                handler() {
                    this.updateChecked();
                },
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction', 'fetchLead']),

            async openModalSelection() {
                await this.$nextTick();

                if (this.shouldDisplaySelectVehicle) {
                    this.$modal.show('selectVehicle', {
                        leadId: this.lead.id,
                        step: 'sale',
                    });
                } else {
                    this.$modal.show('followUp', {
                        leadId: this.lead.id,
                        current: 'sale',
                        last: null,
                    });
                }
            },

            updateChecked() {
                this.checked = this.lead.sale_by_phone;
            },

            emptyStock(vehicle) {
                return !vehicle.stock_state || (vehicle.stock_state == 'stock' && !vehicle.stock);
            },

            onClick() {
                if (this.disabled) {
                    return;
                }

                const checked = !this.checked;

                if (checked) {
                    this.dashboardSaleByPhoneModalOpened = true;
                } else {
                    this.uncheckModalOpened = true;
                }
            },

            triggerUncheck() {
                this.updateLeadAction(this.lead.id, {
                    sale: false,
                    sale_by_phone: false,
                    sale_date: null,
                });
            },
        },

        created() {
            this.$eventBus.$on('cancel-follow-up', this.updateChecked);
        },

        mounted() {
            this.updateChecked();
        },

        beforeDestroy() {
            this.$eventBus.$off('cancel-follow-up', this.updateChecked);
        },
    };
</script>
