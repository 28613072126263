<template>
    <div>
        <span class="font-bold">{{ csiPercent }}</span>
        {{ $t('walkIn.ofCsi') }}
    </div>
</template>

<script>
    import { toPercentString } from '../../../utils/numbers.js';

    export default {
        props: {
            cards: {
                type: Object,
                default: () => {},
            },
        },
        computed: {
            csiPercent() {
                return toPercentString(this.cards.csi.number.count, this.cards.delivered.number.count);
            },
        },
    };
</script>
