<template>
    <el-table
        class="w-full border-t-2"
        border
        stripe
        :empty-text="isLoading ? $t('dashboards.statsTableLoading') : ''"
        :data="tableData"
        :default-sort="sort"
        :show-summary="true"
        :summary-method="formatTotals"
        ref="table"
        @sort-change="sortChange"
    >
        <el-table-column label-class-name="sticky-column">
            <el-table-column
                prop="name"
                :fixed="true"
                header-align="center"
                :label="nameColumnText"
                min-width="200"
                sort-by="name"
                :sortable="true"
                :sort-method="sortNameColumn"
                :sort-orders="['descending', 'ascending']"
                class-name="sticky-column"
            >
                <template slot-scope="{ row }">
                    <name-cell
                        :row="row"
                        :sub-level-key="filters.subLevelKey"
                        :show-sub-level="showSubLevel"
                        @toggle-row="toggleRow"
                    />
                </template>
            </el-table-column>
        </el-table-column>
        <el-table-column>
            <el-table-column
                prop="total_walk_ins"
                :label="$t('walkIn.walkIn')"
                header-align="center"
                align="center"
                min-width="95"
                sort-by="total_walk_ins"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="total_road_tests"
                :label="$tc('walkIn.roadTest', 0)"
                header-align="center"
                align="center"
                min-width="110"
                sort-by="total_road_tests"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }} {{ toPercent(row.total_road_tests, row.total_walk_ins) }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        :percentage="['total_road_tests', 'total_walk_ins']"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="total_take_overs"
                :label="$t('walkIn.takeOver')"
                header-align="center"
                align="center"
                min-width="95"
                sort-by="total_take_overs"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }} {{ toPercent(row.total_take_overs, row.total_walk_ins) }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        :percentage="['total_take_overs', 'total_walk_ins']"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="total_be_backs"
                :label="$tc('walkIn.beBack', 0)"
                header-align="center"
                align="center"
                min-width="95"
                sort-by="total_be_backs"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }} {{ toPercent(row.total_be_backs, row.total_walk_ins) }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        :percentage="['total_be_backs', 'total_walk_ins']"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="total_sale_be_backs"
                :label="$t('walkIn.saleBeBack')"
                header-align="center"
                align="center"
                min-width="110"
                sort-by="total_sale_be_backs"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }} {{ toPercent(row.total_sale_be_backs, row.total_be_backs) }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        :percentage="['total_sale_be_backs', 'total_be_backs']"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="total_sale_ups"
                :label="$tc('walkIn.saleUp', 0)"
                header-align="center"
                align="center"
                min-width="95"
                sort-by="total_sale_ups"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }} {{ toPercent(row.total_sale_ups, row.total_walk_ins) }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        :percentage="['total_sale_ups', 'total_walk_ins']"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="total_sales"
                :label="$tc('allLead.sales', 0)"
                header-align="center"
                align="center"
                min-width="95"
                sort-by="total_sales"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }} {{ toPercent(row.total_sales, row.total_walk_ins) }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        :percentage="['total_sales', 'total_walk_ins']"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="total_deliveries"
                :label="$t('saleTable.table.delivered')"
                header-align="center"
                align="center"
                min-width="95"
                sort-by="total_deliveries"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }} {{ toPercent(row.total_deliveries, row.total_sales) }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        :percentage="['total_deliveries', 'total_sales']"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
        </el-table-column>
        <template v-if="showActivityColumns">
            <el-table-column header-align="center" :label="$t('dashboards.stats.headers.activities')">
                <el-table-column
                    prop="total_follow_ups"
                    header-align="center"
                    align="center"
                    sort-by="total_follow_ups"
                    min-width="95"
                    :sortable="true"
                    :sort-orders="['descending', 'ascending']"
                >
                    <template slot="header">
                        {{ $t('general.walkIn') }}
                        <activix-tooltip placement="top" :content="$t('allLead.allFollowedTooltip')">
                            <icon name="regular/information-circle" class="text-grey-500 text-sm ml-1" />
                        </activix-tooltip>
                    </template>
                    <template slot-scope="{ row, column }">
                        {{ row[column.property] }}
                        <sub-level-cell
                            :row="row"
                            :column-name="column.property"
                            :sub-level-key="filters.subLevelKey"
                            :empty-value="'N/A'"
                            v-if="row.expanded"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="total_activities"
                    header-align="center"
                    align="center"
                    sort-by="total_activities"
                    min-width="95"
                    :sortable="true"
                    :sort-orders="['descending', 'ascending']"
                >
                    <template slot="header">
                        {{ $tc('general.date', 1) }}
                        <activix-tooltip placement="top" :content="$t('allLead.allFollowedTooltipDates')">
                            <icon name="regular/information-circle" class="text-grey-500 text-sm ml-1" />
                        </activix-tooltip>
                    </template>
                    <template slot-scope="{ row, column }">
                        {{ row[column.property] }}
                        <sub-level-cell
                            :row="row"
                            :column-name="column.property"
                            :sub-level-key="filters.subLevelKey"
                            :empty-value="'N/A'"
                            v-if="row.expanded"
                        />
                    </template>
                </el-table-column>
            </el-table-column>
            <el-table-column header-align="center" :label="$t('dashboards.stats.headers.averages')">
                <el-table-column
                    prop="follow_up_ratio"
                    header-align="center"
                    align="center"
                    sort-by="follow_up_ratio"
                    min-width="110"
                    :sortable="true"
                    :sort-orders="['descending', 'ascending']"
                >
                    <template slot="header">
                        {{ $t('allLead.averageFollowed') }}
                        <activix-tooltip placement="top" :content="$t('allLead.averageFollowedTooltip')">
                            <icon name="regular/information-circle" class="text-grey-500 text-sm ml-1" />
                        </activix-tooltip>
                    </template>
                    <template slot-scope="{ row, column }">
                        {{ row[column.property].toFixed(1) }}
                        <sub-level-cell
                            :row="row"
                            :column-name="column.property"
                            :sub-level-key="filters.subLevelKey"
                            :empty-value="'N/A'"
                            v-if="row.expanded"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="contact_ratio"
                    header-align="center"
                    align="center"
                    sort-by="contact_ratio"
                    min-width="95"
                    :sortable="true"
                    :sort-orders="['descending', 'ascending']"
                >
                    <template slot="header">
                        {{ $t('allLead.averageContact') }}
                        <activix-tooltip placement="top" :content="$t('allLead.averageContactTooltip')">
                            <icon name="regular/information-circle" class="text-grey-500 text-sm ml-1" />
                        </activix-tooltip>
                    </template>
                    <template slot-scope="{ row, column }">
                        {{ row[column.property].toFixed(1) }}
                        <sub-level-cell
                            :row="row"
                            :column-name="column.property"
                            :sub-level-key="filters.subLevelKey"
                            :empty-value="'N/A'"
                            v-if="row.expanded"
                        />
                    </template>
                </el-table-column>
            </el-table-column>
        </template>
    </el-table>
</template>

<script>
    // Mixins
    import StatsTableMixin from '../../mixins/StatsTable.js';

    // Components
    import NameCell from './Components/NameCell.vue';
    import SubLevelCell from './Components/SubLevelCell.vue';

    export default {
        components: {
            NameCell,
            SubLevelCell,
        },

        mixins: [StatsTableMixin],

        props: {
            tableData: {
                type: Array,
                default: () => [],
            },
            filters: {
                type: Object,
                default: () => {},
            },
            isLoading: {
                type: Boolean,
                default: false,
            },
            nameColumnText: {
                type: String,
                default: '',
            },
            showActivityColumns: {
                type: Boolean,
                default: false,
            },
            showSubLevel: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                columnsTotals: [
                    {
                        type: 'percent',
                        numerator: 'total_road_tests',
                        denominator: 'total_walk_ins',
                    },
                    {
                        type: 'percent',
                        numerator: 'total_take_overs',
                        denominator: 'total_walk_ins',
                    },
                    {
                        type: 'percent',
                        numerator: 'total_be_backs',
                        denominator: 'total_walk_ins',
                    },
                    {
                        type: 'percent',
                        numerator: 'total_sale_be_backs',
                        denominator: 'total_be_backs',
                    },
                    {
                        type: 'percent',
                        numerator: 'total_sale_ups',
                        denominator: 'total_walk_ins',
                    },
                    {
                        type: 'percent',
                        numerator: 'total_sales',
                        denominator: 'total_walk_ins',
                    },
                    {
                        type: 'percent',
                        numerator: 'total_deliveries',
                        denominator: 'total_sales',
                    },
                    {
                        type: 'division',
                        key: 'follow_up_ratio',
                        numerator: 'total_follow_ups',
                        denominator: 'total_walk_ins',
                    },
                    {
                        type: 'division',
                        key: 'contact_ratio',
                        numerator: 'total_contacts',
                        denominator: 'total_walk_ins',
                    },
                ],
            };
        },
    };
</script>
