import { camelCase } from 'lodash-es';
import i18n from '../plugins/vue-i18n.js';
import DashboardType from '../entities/DashboardType.js';
import { useDashboardStore } from '../store/modules/dashboard/store.js';

export default class StatsTable {
    static get filterOptions() {
        const dashboardStore = useDashboardStore();
        return [
            {
                key: 'user_id',
                text: i18n.t('leadFilter.advisor'),
                redirect: true,
                default: [
                    DashboardType.ALL,
                    DashboardType.LOYALTY,
                    DashboardType.RENEWAL,
                    DashboardType.EVENT,
                    DashboardType.WEB_ORDER,
                    DashboardType.LEAD_XPRESS,
                    DashboardType.WEB_BOOST,
                    DashboardType.PHONE_UP,
                    DashboardType.WALK_IN,
                ],
                getActivityData: true,
                hideIn: [DashboardType.SERVICE],
                hiddenForGroups: true,
            },
            {
                key: 'bdc_user_id',
                text: i18n.t('leadFilter.bdcUser'),
                redirect: true,
                getActivityData: true,
                hideIn: [DashboardType.COMMERCIAL, DashboardType.WALK_IN],
                hiddenForGroups: true,
            },
            {
                key: 'division',
                text: i18n.t('leadFilter.division'),
                redirect: false,
                hideIn: [],
            },
            {
                key: 'created_at:day_of_week',
                text: `${i18n.t('leadFilter.dayOfWeek')} (${i18n.t('date.createdAt')})`,
                redirect: false,
                hideIn: [],
            },
            {
                key: `${dashboardStore.filteredDates[0] || 'created_at'}:month`,
                text: `${i18n.t('leadFilter.month')} (${i18n.t(`date.${camelCase(dashboardStore.filteredDates[0] || 'created_at')}`)})`,
                redirect: false,
                hideIn: [],
            },
            {
                key: `${dashboardStore.filteredDates[0] || 'created_at'}:year`,
                text: `${i18n.t('leadFilter.year')} (${i18n.t(`date.${camelCase(dashboardStore.filteredDates[0] || 'created_at')}`)})`,
                redirect: false,
                hideIn: [],
            },
            {
                key: 'source',
                text: i18n.t('leadFilter.source'),
                redirect: false,
                hideIn: [],
            },
            {
                key: 'lead_form:localized',
                text: i18n.t('leadFilter.leadForm'),
                redirect: false,
                hideIn: [],
            },
            {
                key: 'provider',
                text: i18n.t('leadFilter.provider'),
                redirect: false,
                hideIn: [],
            },
            {
                key: 'first_wanted_vehicle.model',
                text: i18n.t('leadFilter.wantedVehicleModel'),
                redirect: false,
                hideIn: [DashboardType.SERVICE],
            },
            {
                key: 'first_wanted_vehicle.make',
                text: i18n.t('leadFilter.wantedVehicleMake'),
                redirect: false,
                hideIn: [DashboardType.SERVICE],
            },
            {
                key: 'first_exchange_vehicle.model',
                text: i18n.t('leadFilter.exchangeVehicleModel'),
                redirect: false,
                hideIn: [],
            },
            {
                key: 'first_exchange_vehicle.make',
                text: i18n.t('leadFilter.exchangeVehicleMake'),
                redirect: false,
                hideIn: [],
            },
            {
                key: 'commercial_id',
                text: i18n.t('clientCard.commercialShort'),
                redirect: true,
                getActivityData: true,
                default: [DashboardType.COMMERCIAL],
                hideIn: [DashboardType.SERVICE],
                hiddenForGroups: true,
            },
            {
                key: 'take_over_director_id',
                text: i18n.t('leadFilter.takeOver'),
                redirect: true,
                getActivityData: true,
                hideIn: [DashboardType.SERVICE],
                hiddenForGroups: true,
            },
            {
                key: 'service_agent_id',
                text: i18n.t('leadFilter.serviceAgent'),
                redirect: true,
                getActivityData: true,
                default: [DashboardType.SERVICE],
                hideIn: [
                    DashboardType.ALL,
                    DashboardType.LOYALTY,
                    DashboardType.RENEWAL,
                    DashboardType.COMMERCIAL,
                    DashboardType.EVENT,
                    DashboardType.WEB_ORDER,
                    DashboardType.LEAD_XPRESS,
                    DashboardType.WEB_BOOST,
                    DashboardType.PHONE_UP,
                    DashboardType.WALK_IN,
                ],
                hiddenForGroups: true,
            },
            {
                key: 'service_advisor_id',
                text: i18n.t('leadFilter.serviceAdvisor'),
                redirect: true,
                getActivityData: true,
                hideIn: [
                    DashboardType.ALL,
                    DashboardType.LOYALTY,
                    DashboardType.RENEWAL,
                    DashboardType.COMMERCIAL,
                    DashboardType.EVENT,
                    DashboardType.WEB_ORDER,
                    DashboardType.LEAD_XPRESS,
                    DashboardType.WEB_BOOST,
                    DashboardType.PHONE_UP,
                    DashboardType.WALK_IN,
                ],
                hiddenForGroups: true,
            },
            {
                key: 'account_id',
                text: i18n.t('leadFilter.account'),
                redirect: false,
                hideIn: [],
                visibleForGroups: true,
            },
        ];
    }

    static getSelectOptions(dashboardType) {
        return this.filterOptions.filter(option => !option.hideIn.includes(dashboardType));
    }

    static getSubLevelKeys(selectedFilter, dashboardType, accountManagerSelected, groupSelected) {
        if (
            !selectedFilter ||
            !dashboardType ||
            dashboardType == DashboardType.SERVICE ||
            (!accountManagerSelected &&
                ['user_id', 'bdc_user_id', 'commercial_id', 'take_over_director_id', 'service_agent_id'].includes(
                    selectedFilter,
                ))
        ) {
            return [];
        }

        if (selectedFilter != 'account' && groupSelected) {
            return ['account'];
        }

        const keys = [];

        switch (dashboardType) {
            case DashboardType.COMMERCIAL:
                keys.push('user_id', 'commercial_id');
                break;

            case DashboardType.WALK_IN:
                keys.push('user_id', 'take_over_director_id');
                break;

            default:
                keys.push('user_id', 'bdc_user_id');
        }

        if (accountManagerSelected) {
            keys.push('account_id');
        }

        const selectedFilterKey = this.filterOptions.find(option => option.key == selectedFilter)?.key;

        return keys.filter(key => key != selectedFilterKey);
    }

    static getSubLevelToggleOptions(selectedFilter, dashboardType, accountManagerSelected, groupSelected) {
        const subLevelKeys = this.getSubLevelKeys(selectedFilter, dashboardType, accountManagerSelected, groupSelected);

        return [
            {
                key: 'user_id',
                icon: 'advisor',
                tooltip: i18n.t('option.showAdvisorLead'),
            },
            {
                key: 'bdc_user_id',
                icon: 'bdc',
                tooltip: i18n.t('option.showBdcLead'),
            },
            {
                key: 'commercial_id',
                icon: 'commercialDirector',
                tooltip: i18n.t('option.showCommercialLead'),
            },
            {
                key: 'take_over_director_id',
                icon: 'takeOver',
                tooltip: i18n.t('option.showTakeOverDirectorLead'),
            },
            {
                key: 'account_id',
                icon: 'account',
                tooltip: i18n.t('option.showAccounts'),
            },
        ].filter(toggle => {
            return subLevelKeys.some(key => key == toggle.key);
        });
    }

    static showUsersWithoutLeads(userWithoutLead, dashboardType, groupDisplay) {
        switch (dashboard) {
            case DashboardType.WALK_IN:
                if (groupDisplay) {
                    return userWithoutLead ? i18n.t('walkIn.hideNoLeadAccount') : i18n.t('walkIn.showNoLeadAccount');
                }

                return userWithoutLead ? i18n.t('walkIn.hideNoLeadAdvisor') : i18n.t('walkIn.showNoLeadAdvisor');

            case DashboardType.PHONE_UP:
                if (groupDisplay) {
                    return userWithoutLead ? i18n.t('phoneUp.hideNoLeadAccount') : i18n.t('phoneUp.showNoLeadAccount');
                }

                return userWithoutLead ? i18n.t('phoneUp.hideNoLeadAdvisor') : i18n.t('phoneUp.showNoLeadAdvisor');

            case DashboardType.RENEWAL:
                if (groupDisplay) {
                    return userWithoutLead ? i18n.t('renewal.hideNoLeadAccount') : i18n.t('renewal.showNoLeadAccount');
                }

                return userWithoutLead ? i18n.t('renewal.hideNoLeadAdvisor') : i18n.t('renewal.showNoLeadAdvisor');

            case DashboardType.SERVICE:
                if (groupDisplay) {
                    return userWithoutLead ? i18n.t('service.hideNoLeadAccount') : i18n.t('service.showNoLeadAccount');
                }

                return userWithoutLead ? i18n.t('service.hideNoLeadAdvisor') : i18n.t('service.showNoLeadAdvisor');

            default:
                if (groupDisplay) {
                    return userWithoutLead ? i18n.t('walkIn.hideNoLeadAccount') : i18n.t('walkIn.showNoLeadAccount');
                }

                return userWithoutLead ? i18n.t('walkIn.hideNoLeadAdvisor') : i18n.t('walkIn.showNoLeadAdvisor');
        }
    }

    static userAggregations() {
        return [
            'user_id',
            'service_advisor_id',
            'parent_user_id',
            'bdc_user_id',
            'parent_bdc_user_id',
            'commercial_id',
            'parent_commercial_id',
            'take_over_director_id',
            'parent_take_over_director_id',
            'service_agent_id',
            'parent_service_agent_id',
        ];
    }
}
