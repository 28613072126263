<template>
    <div class="relative">
        <activix-checkbox
            :disabled="disabled"
            :value="checked"
            :size="size"
            @click.native.prevent="onClick"
        />
    </div>
</template>

<script>
    /* eslint-disable vue/require-prop-types */
    import { mapActions, mapState } from 'pinia';
    import { useGlobalStore } from '../../../store/store.js';

    export default {
        props: ['lead', 'size'],

        data() {
            return {
                checked: false,
                first: true,
                update: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),

            disabled() {
                if (['invalid', 'lost', 'duplicate'].includes(this.lead.status)) {
                    return true;
                }

                return this.guest;
            },
        },

        watch: {
            lead() {
                this.update = true;
                this.updateChecked();
            },

            checked(newValue) {
                if (!this.first && !this.update) {
                    this.updateLeadAction(this.lead.id, { approved: newValue });
                }
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction']),

            onClick() {
                if (this.disabled) {
                    return;
                }

                this.checked = !this.checked;
            },

            updateChecked() {
                this.checked = this.lead.approved;
                this.$nextTick(() => {
                    this.update = false;
                });
            },

            onCancelFollowUp() {
                this.update = true;
                this.updateChecked();
            },
        },

        mounted() {
            this.$nextTick(() => {
                this.$eventBus.$on('cancel-follow-up', this.onCancelFollowUp);

                this.updateChecked();

                this.first = false;
            });
        },

        beforeDestroy() {
            this.$eventBus.$off('cancel-follow-up', this.onCancelFollowUp);
        },
    };
</script>
