<template>
    <div>
        <template v-if="lead.task_events.length == 0 || empty(nextTodo)">
            <activix-tooltip :content="$t('modal.add_event')">
                <button class="add-reminder p-4" @click="openAddTaskEvent">
                    <icon :name="$icons.add" class="link-grey | text-xs" />
                </button>
            </activix-tooltip>
        </template>
        <template v-else>
            <div class="whitespace-nowrap" :class="{ 'text-red-500': isLate }">
                {{ nextTodoStartAtString }}
            </div>
            <activix-tooltip :content="tooltip">
                <div class="mt-2" @click="openEditTaskEvent(nextTodo)">
                    <span class="label label-default | inline-flex text-base cursor-pointer" v-html="icon" />
                </div>
            </activix-tooltip>
        </template>
    </div>
</template>

<script>
    /* eslint-disable vue/require-prop-types */
    import { get, camelCase, pickBy, sortBy, head } from 'lodash-es';

    import { mapState } from 'pinia';
    import TaskEventType from '../../../entities/TaskEventType.js';
    import TaskEventTypeIcon from '../../../entities/TaskEventTypeIcon.js';
    import ActivixDate from '../../../value-objects/ActivixDate.js';
    import { useGlobalStore } from '../../../store/store.js';

    export default {
        props: ['lead'],

        computed: {
            ...mapState(useGlobalStore, ['authUser']),

            nextTodo() {
                // Get the earliest uncompleted task for the current lead
                return head(sortBy(
                    pickBy(this.lead.task_events, t => !t.status && !t.canceled && !t.no_show),
                    'start_at',
                ));
            },

            nextToDoStartAt() {
                return new ActivixDate(get(this.nextTodo, 'start_at', null));
            },

            nextTodoStartAtString() {
                return this.nextToDoStartAt.toHumanShort();
            },

            tooltip() {
                if (this.nextTodo.task_event_type_id) {
                    const typeName = camelCase(TaskEventType.getKey(this.nextTodo.task_event_type_id));

                    return this.$t(`followUp.todo.${typeName}`);
                }

                return '';
            },

            icon() {
                if (!this.nextTodo) {
                    return '';
                }

                const taskEventType = TaskEventType.getKey(this.nextTodo.task_event_type_id);

                return TaskEventTypeIcon.entries[taskEventType];
            },

            isLate() {
                return this.nextToDoStartAt.isPast();
            },
        },

        methods: {
            openAddTaskEvent() {
                this.$eventBus.$emit('open-add-task-event', {
                    leadId: this.lead.id,
                });
            },

            openEditTaskEvent(taskEvent) {
                this.$eventBus.$emit('open-edit-task-event', {
                    lead: this.lead,
                    taskEvent,
                });
            },
        },
    };
</script>
