<template>
    <activix-modal
        size="sm"
        :opened="opened"
        @close="close"
        @closed="onClosed"
    >
        <template slot="header">
            <h4 class="modal-title">
                {{ $t('massActions.selectField') }}
            </h4>
        </template>

        <template slot="body">
            <div class="grid grid-cols-2 space-x-3">
                <div class="col-span-1">
                    <div class="form-group">
                        <label v-if="!empty(field)">
                            {{ $t('massActions.field') }}
                        </label>
                        <el-select
                            filterable
                            height="400px"
                            :value="fieldObject"
                            @change="updateField"
                        >
                            <el-option-group
                                :label="$t(`massActions.group.${option.groupLabel}`)"
                                :key="model"
                                v-for="(option, model) in options"
                            >
                                <el-option
                                    :label="item.label"
                                    :value="item"
                                    :key="item.value"
                                    v-for="item in sortOptionFields(option.fields)"
                                />
                            </el-option-group>
                        </el-select>
                    </div>
                </div>
                <div class="col-span-1" v-if="!empty(field)">
                    <div class="form-group">
                        <label>{{ $t('massActions.newValue') }}</label>
                        <activix-multiselect
                            :custom-label="fieldObject.customLabel || ((option) => option.label)"
                            :options="fieldObject.options"
                            :searchable="false"
                            @update="updateValue"
                            v-if="fieldObject.component == 'multiselect'"
                        />

                        <input class="form-control" v-model="selected" v-else-if="fieldObject.component == 'input'" />

                        <date-time-picker
                            name="in_service_date"
                            :date-only="true"
                            :allow-empty="true"
                            :start-view="4"
                            :vehicle="true"
                            v-model="selected"
                            v-else-if="fieldObject.component == 'datetime'"
                        />

                        <component
                            :is="customFieldsComponent"
                            :class="{ 'table pt-2': fieldObject.type == 'boolean' }"
                            :options="fieldObject.options"
                            :multiple="true"
                            :searchable="false"
                            :allow-empty="true"
                            v-model="selected"
                            v-else-if="fieldObject.component == 'custom'"
                        />
                    </div>
                </div>
            </div>
            <div>
                <div v-if="lostReasonRequired">
                    <label>{{ $t('result.lostReason') }}</label>
                    <textarea class="form-control" rows="3" v-model="lostReason" />
                </div>
                <div v-if="caslNoteRequired">
                    <label>{{ $t('consent.reason') }}</label>
                    <textarea class="form-control" rows="3" v-model="caslNote" />
                </div>

                <div class="text-center" v-if="!fieldsHasNotification">
                    <small>* {{ $t('massActions.noNotification') }}</small>
                </div>
            </div>
        </template>

        <template slot="footer">
            <activix-button @click="close">
                {{ $t('general.cancel') }}
            </activix-button>
            <activix-button
                :type="deactivateEnabled? 'danger' : 'primary'"
                :disabled="!saveEnabled"
                :loading="processing"
                @click="update"
            >
                {{ deactivateEnabled ? $t('phoneProvider.deactivateIt') : $t('general.update') }}
            </activix-button>

            <span />
        </template>
    </activix-modal>
</template>

<script>
    import { mapActions, mapState } from 'pinia';

    import LeadVehicleIntention from '@/entities/LeadVehicleIntention.js';
    import LeadVehicleCondition from '@/entities/LeadVehicleCondition.js';
    import LeadVehicleFuel from '@/entities/LeadVehicleFuel.js';
    import LeadResult from '@/entities/LeadResult.js';
    import DateTimePicker from '../inputs/DateTimePicker.vue';
    import Division from '../../entities/Division.js';
    import DashboardType from '../../entities/DashboardType.js';
    import LeadVehicleTerm from '../../entities/LeadVehicleTerm.js';
    import LeadStatus from '../../entities/LeadStatus.js';
    import Civility from '../../entities/Civility.js';
    import LeadVehicleFrequency from '../../entities/LeadVehicleFrequency.js';
    import LeadVehicleModality from '../../entities/LeadVehicleModality.js';
    import Locale from '../../entities/Locale.js';
    import LeadVehicleTransmission from '../../entities/LeadVehicleTransmission.js';
    import LeadVehicleStockState from '../../entities/LeadVehicleStockState.js';

    // Pinia
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            DateTimePicker,
        },

        props: {
            opened: {
                type: Boolean,
                required: true,
            },
        },

        data() {
            return {
                processing: false,
                field: null,
                fieldObject: {},
                selected: null,
                lostReason: '',
                caslNote: '',
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest', 'advisorUsers', 'commercialUsers', 'bdcUsers', 'serviceAdvisorUsers', 'serviceAgentUsers', 'sourcesWithProvider', 'configs']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),
            ...mapState(useDashboardStore, ['bulkSelect', 'allLeadsInTableSelected', 'dashboardCriteria', 'dashboardType']),

            customFieldsComponent() {
                switch (this.fieldObject.type) {
                    case 'string':
                        return 'activix-input';
                    case 'array':
                        return 'activix-multiselect';
                    case 'datetime':
                        return 'date-time-picker';
                    case 'boolean':
                        return 'activix-checkbox';
                }

                return '';
            },

            selectedOption() {
                if (this.fieldObject.component !== 'multiselect' || !this.selected) {
                    return null;
                }

                return this.fieldObject.options?.find(option => option.id === this.selected);
            },

            options() {
                const options = {
                    lead: {
                        groupLabel: 'lead',
                        fields: [
                            {
                                label: this.$t('massActions.division'),
                                value: 'division_id',
                                model: 'lead',
                                options: this.divisionsOptions,
                                component: 'multiselect',
                                customLabel: null,
                            },
                            {
                                label: this.$t('massActions.advisor'),
                                value: 'user_id',
                                model: 'lead',
                                options: this.advisorUsers,
                                component: 'multiselect',
                                customLabel: this.formatUsers,
                            },
                            {
                                label: this.$t('massActions.bdcAgent'),
                                value: 'bdc_user_id',
                                model: 'lead',
                                options: this.bdcUsers,
                                component: 'multiselect',
                                customLabel: this.formatUsers,
                            },
                            {
                                label: this.$t('massActions.commercial'),
                                value: 'commercial_id',
                                model: 'lead',
                                options: this.commercialUsers,
                                component: 'multiselect',
                                customLabel: this.formatUsers,
                            },
                            {
                                label: this.$t('massActions.serviceAdvisor'),
                                value: 'service_advisor_id',
                                model: 'lead',
                                options: this.serviceAdvisorUsers,
                                component: 'multiselect',
                                customLabel: this.formatUsers,
                            },
                            {
                                label: this.$t('massActions.serviceAgent'),
                                value: 'service_agent_id',
                                model: 'lead',
                                options: this.serviceAgentUsers,
                                component: 'multiselect',
                                customLabel: this.formatUsers,
                            },
                            {
                                label: this.$t('massActions.status'),
                                value: 'status',
                                model: 'lead',
                                options: this.generateOptions(LeadStatus, true, 'leadStatus.active'),
                                component: 'multiselect',
                                customLabel: null,
                            },
                            {
                                label: this.$t('massActions.source'),
                                value: 'source_id',
                                model: 'lead',
                                options: this.sourcesOptions,
                                component: 'multiselect',
                                customLabel: null,
                            },
                            {
                                label: this.$t('massActions.form'),
                                value: 'lead_form_id',
                                model: 'lead',
                                options: this.leadForms,
                                component: 'multiselect',
                                customLabel: null,
                            },
                            {
                                label: this.$t('massActions.civility'),
                                value: 'civility',
                                model: 'lead',
                                options: this.generateOptions(Civility, true),
                                component: 'multiselect',
                                customLabel: null,
                            },
                            {
                                label: this.$t('massActions.language'),
                                value: 'locale',
                                model: 'lead',
                                options: this.locales,
                                component: 'multiselect',
                                customLabel: null,
                            },
                            {
                                label: `${this.$t('general.province.CA')} / ${this.$t('general.province.US')}`,
                                value: 'province',
                                model: 'lead',
                                options: this.locations,
                                component: 'multiselect',
                                customLabel: null,
                            },
                        ],
                    },
                    wantedVehicle: {
                        groupLabel: 'wantedVehicle',
                        fields: [
                            {
                                label: this.$t('massActions.wantedVehicleMake'),
                                value: 'wantedVehicleMake',
                                model: 'wantedVehicle',
                                options: '',
                                component: 'input',
                            },
                            {
                                label: this.$t('massActions.wantedVehicleModel'),
                                value: 'wantedVehicleModel',
                                model: 'wantedVehicle',
                                options: '',
                                component: 'input',
                            },
                            {
                                label: this.$t('massActions.wantedVehicleTrim'),
                                value: 'wantedVehicleTrim',
                                model: 'wantedVehicle',
                                options: '',
                                component: 'input',
                            },
                            {
                                label: this.$t('massActions.wantedVehicleFrequency'),
                                value: 'wantedVehicleFrequency',
                                model: 'wantedVehicle',
                                options: this.generateOptions(LeadVehicleFrequency, true),
                                component: 'multiselect',
                            },
                            {
                                label: this.$t('massActions.wantedVehicleModality'),
                                value: 'wantedVehicleModality',
                                model: 'wantedVehicle',
                                options: this.generateOptions(LeadVehicleModality, true),
                                component: 'multiselect',
                            },
                            {
                                label: this.$t('massActions.wantedVehicleResidual'),
                                value: 'wantedVehicleResidual',
                                model: 'wantedVehicle',
                                options: '',
                                component: 'input',
                            },
                            {
                                label: this.$t('massActions.wantedVehicleTerm'),
                                value: 'wantedVehicleTerm',
                                model: 'wantedVehicle',
                                options: this.generateOptions(LeadVehicleTerm, true),
                                component: 'multiselect',
                            },
                            {
                                label: this.$t('massActions.wantedVehicleRate'),
                                value: 'wantedVehicleRate',
                                model: 'wantedVehicle',
                                options: '',
                                component: 'input',
                            },
                            {
                                label: this.$t('massActions.wantedVehicleTransmission'),
                                value: 'wantedVehicleTransmission',
                                model: 'wantedVehicle',
                                options: this.generateOptions(LeadVehicleTransmission, true),
                                component: 'multiselect',
                            },
                            {
                                label: this.$t('massActions.wantedVehicleFuel'),
                                value: 'wantedVehicleFuel',
                                model: 'wantedVehicle',
                                options: this.generateOptions(LeadVehicleFuel, true),
                                component: 'multiselect',
                            },
                            {
                                label: this.$t('massActions.wantedVehicleStockState'),
                                value: 'wantedVehicleStockState',
                                model: 'wantedVehicle',
                                options: this.generateOptions(LeadVehicleStockState, true),
                                component: 'multiselect',
                            },
                            {
                                label: this.$t('massActions.wantedVehiclePayment'),
                                value: 'wantedVehiclePayment',
                                model: 'wantedVehicle',
                                options: '',
                                component: 'input',
                            },
                            {
                                label: this.$t('massActions.wantedVehicleTaxedPayment'),
                                value: 'wantedVehicleTaxedPayment',
                                model: 'wantedVehicle',
                                options: '',
                                component: 'input',
                            },
                        ],
                    },
                    exchangeVehicle: {
                        groupLabel: 'exchangeVehicle',
                        fields: [
                            {
                                label: this.$t('massActions.exchangeVehicleInServiceDate'),
                                value: 'exchangeVehicleInServiceDate',
                                model: 'exchangeVehicle',
                                options: '',
                                component: 'datetime',
                            },
                            {
                                label: this.$t('massActions.exchangeVehicleFrequency'),
                                value: 'exchangeVehicleFrequency',
                                model: 'exchangeVehicle',
                                options: this.generateOptions(LeadVehicleFrequency, true),
                                component: 'multiselect',
                            },
                            {
                                label: this.$t('massActions.exchangeVehicleModality'),
                                value: 'exchangeVehicleModality',
                                model: 'exchangeVehicle',
                                options: this.generateOptions(LeadVehicleModality, true),
                                component: 'multiselect',
                            },
                            {
                                label: this.$t('massActions.exchangeVehicleResidual'),
                                value: 'exchangeVehicleResidual',
                                model: 'exchangeVehicle',
                                options: '',
                                component: 'input',
                            },
                            {
                                label: this.$t('massActions.exchangeVehicleTerm'),
                                value: 'exchangeVehicleTerm',
                                model: 'exchangeVehicle',
                                options: this.generateOptions(LeadVehicleTerm, true),
                                component: 'multiselect',
                            },
                            {
                                label: this.$t('massActions.exchangeVehicleRate'),
                                value: 'exchangeVehicleRate',
                                model: 'exchangeVehicle',
                                options: '',
                                component: 'input',
                            },
                            {
                                label: this.$t('massActions.exchangeVehicleTransmission'),
                                value: 'exchangeVehicleTransmission',
                                model: 'exchangeVehicle',
                                options: this.generateOptions(LeadVehicleTransmission, true),
                                component: 'multiselect',
                            },
                            {
                                label: this.$t('massActions.exchangeVehicleFuel'),
                                value: 'exchangeVehicleFuel',
                                model: 'exchangeVehicle',
                                options: this.generateOptions(LeadVehicleFuel, true),
                                component: 'multiselect',
                            },
                            {
                                label: this.$t('massActions.exchangeVehicleIntention'),
                                value: 'exchangeVehicleIntention',
                                model: 'exchangeVehicle',
                                options: this.generateOptions(LeadVehicleIntention, true),
                                component: 'multiselect',
                            },
                            {
                                label: this.$t('massActions.exchangeVehicleCondition'),
                                value: 'exchangeVehicleCondition',
                                model: 'exchangeVehicle',
                                options: this.generateOptions(LeadVehicleCondition, true),
                                component: 'multiselect',
                            },
                            {
                                label: this.$t('massActions.exchangeVehicleValue'),
                                value: 'exchangeVehicleValue',
                                model: 'exchangeVehicle',
                                options: '',
                                component: 'input',
                            },
                            {
                                label: this.$t('massActions.exchangeVehiclePayment'),
                                value: 'exchangeVehiclePayment',
                                model: 'exchangeVehicle',
                                options: '',
                                component: 'input',
                            },
                            {
                                label: this.$t('massActions.exchangeVehicleTaxedPayment'),
                                value: 'exchangeVehicleTaxedPayment',
                                model: 'exchangeVehicle',
                                options: '',
                                component: 'input',
                            },
                        ],
                    },
                    custom: {
                        groupLabel: 'custom',
                        fields: [],
                    },
                };

                if (this.dashboardType == DashboardType.SERVICE) {
                    options.lead.fields.push({
                        label: this.$t('massActions.code'),
                        value: 'code',
                        model: 'lead',
                        options: '',
                        component: 'input',
                    });
                }

                if (this.authUser.isAdmin() || this.authUser.isDirector()) {
                    options.lead.fields.push({
                        label: this.$t('massActions.caslConsent'),
                        value: 'casl_consent',
                        model: 'lead',
                        options: this.caslConsentOptions,
                        component: 'multiselect',
                        customLabel: null,
                    });
                }

                if (this.authUser.isAdmin()) {
                    options.lead.fields.push({
                        label: this.$t('massActions.result'),
                        value: 'result',
                        model: 'lead',
                        options: this.generateOptions(LeadResult, false),
                        component: 'multiselect',
                    });
                }

                // Add custom fields
                this.contextAccount
                    .getLeadCustomFields()
                    .filter(customField => customField.visible)
                    .forEach(customField => {
                        options.custom.fields.push({
                            label: customField.name,
                            value: customField.field,
                            model: 'customable',
                            id: customField.id,
                            type: customField.type,
                            options: customField.select_picker_options,
                            component: 'custom',
                        });
                    });

                return options;
            },

            divisionsOptions() {
                let divisions = this.authUser.divisions.map(d => d.id);

                if (this.authUser.isAdmin()) {
                    divisions = [Division.NEW, Division.USED, Division.SERVICE];
                }

                divisions = divisions.map(divisionId => {
                    return {
                        label: Division.getTranslation(divisionId),
                        id: divisionId,
                    };
                });

                divisions.unshift({
                    label: this.$t('divisions.none'),
                    id: -1,
                });

                return divisions;
            },

            sourcesOptions() {
                return this.sourcesWithProvider.map(source => {
                    return {
                        label: source.name,
                        id: source.id,
                    };
                });
            },

            caslConsentOptions() {
                return [
                    {
                        id: 'manual',
                        label: this.$t('consent.methods.manual'),
                    },
                    {
                        id: 'deactivate',
                        label: this.$t('consent.deactivate'),
                    },
                ];
            },

            leadForms() {
                const leadForms = [];

                // Default forms
                for (const leadForm of this.configs.leadForms) {
                    leadForms.push({
                        id: leadForm.id,
                        label:
                            leadForm[`display_name_${this.$i18n.locale}`] ||
                            this.$t(`leadForms.${leadForm.name}`),
                    });
                }

                // Account specific forms
                if (!empty(this.contextAccount.lead_forms)) {
                    for (const leadForm of this.contextAccount.lead_forms) {
                        leadForms.push({
                            id: leadForm.id,
                            label: leadForm.name,
                        });
                    }
                }

                // Sort
                leadForms.sort((a, b) => a.label.localeCompare(b.label));

                return leadForms;
            },

            consentNoteIsValid() {
                return this.caslNote.length > 12 && this.caslNote.split(' ').length > 1;
            },

            saveEnabled() {
                if (this.fieldObject.type == 'boolean') {
                    return true;
                }

                if (!this.field || this.selected == null) {
                    return false;
                }

                if (this.lostReasonRequired && !this.lostReason) {
                    return false;
                }

                if (this.caslNoteRequired && (!this.caslNote || !this.consentNoteIsValid)) {
                    return false;
                }
                return true;
            },

            deactivateEnabled() {
                return this.field == 'casl_consent' && this.selected == 'deactivate';
            },

            lostReasonRequired() {
                return this.field == 'status' && ['lost', 'invalid'].includes(this.selected);
            },

            caslNoteRequired() {
                return this.field == 'casl_consent' && this.selected == 'manual';
            },

            fieldsHasNotification() {
                const fieldsWithNotification = ['user_id', 'bdc_user_id', 'commercial_id', 'service_advisor_id', 'service_agent_id'];
                return fieldsWithNotification.includes(this.field);
            },

            locales() {
                return Locale.list.map(locale => {
                    return {
                        id: locale.id,
                        label: this.$t(`database.${locale.id}`),
                    };
                });
            },

            locations() {
                return Object.entries(this.configs.provinces).reduce((locations, [countryKey, countryProvinces]) => {
                    countryProvinces.forEach(provinceKey => {
                        const provinceName = this.$t(`clientCard.provinces.${provinceKey}`);
                        locations.push({
                            id: provinceKey,
                            label: `${countryKey} - ${provinceName}`,
                            additionalData: {
                                country: countryKey,
                            },
                        });
                    });

                    return locations;
                }, []);
            },
        },

        watch: {
            lostReasonRequired(newValue) {
                if (!newValue) {
                    this.lostReason = '';
                }
            },
            caslNoteRequired(newValue) {
                if (!newValue) {
                    this.caslNote = '';
                }
            },
        },

        methods: {
            ...mapActions(useDashboardStore, ['setAllLeadsInTableSelected']),
            ...mapActions(useGlobalStore, ['bulkUpdateLeads', 'bulkUpdateWantedVehicles', 'bulkUpdateExchangeVehicles']),

            generateOptions(model, withNone = false, noneLabel = 'general.none') {
                const options = model.selectOptions('id', 'label');

                if (withNone) {
                    options.unshift({
                        label: this.$t(noneLabel),
                        id: -1,
                    });
                }

                return options;
            },

            formatUsers(value) {
                return this.getFullName(value);
            },

            updateField(field) {
                this.selected = null;
                this.lostReason = '';
                this.caslNote = '';
                this.field = empty(field) ? null : field.value;
                this.fieldObject = empty(field) ? {} : field;
            },

            updateValue(option) {
                this.selected = empty(option) ? null : option.id;
            },

            sortOptionFields(fields) {
                return fields.sort((a, b) => a.label.localeCompare(b.label));
            },

            async update() {
                if (!this.saveEnabled) {
                    return;
                }

                this.processing = true;

                if (this.fieldObject.model == 'wantedVehicle') {
                    await this.updateBulkWantedVehicles();
                } else if (this.fieldObject.model == 'exchangeVehicle') {
                    await this.updateBulkExchangeVehicles();
                } else {
                    await this.updateBulkLeads();
                }

                this.close();
                this.setAllLeadsInTableSelected(false);
                this.$eventBus.$emit('fetch-stats', { background: true });
            },

            async updateBulkLeads() {
                const data = {};

                switch (this.field) {
                    case 'status':
                        data.status = this.selected != -1 ? this.selected : '';
                        if (this.lostReasonRequired) {
                            data.lost_reason = this.lostReason;
                        }

                        break;

                    case 'casl_consent':
                        data.casl_consent = this.selected;

                        if (this.caslNoteRequired) {
                            data.casl_consent_note = this.caslNote;
                        }

                        break;

                    default:
                        if (this.fieldObject.model == 'customable') {
                            let customValues = this.selected;

                            if (this.fieldObject.type == 'array') {
                                customValues = customValues.map(item => {
                                    return item.label;
                                });
                            }

                            if (this.fieldObject.type == 'boolean' && empty(this.selected)) {
                                customValues = false;
                            }

                            data.customField = {
                                id: this.fieldObject.id,
                                label: this.fieldObject.label,
                                field: this.fieldObject.value,
                                value: customValues,
                            };
                        } else {
                            data[this.field] = this.selected != -1 ? this.selected : null;

                            if (this.selectedOption?.additionalData) {
                                Object.assign(data, this.selectedOption.additionalData);
                            }
                        }

                        break;
                }

                await this.bulkUpdateLeads(
                    data,
                    this.bulkSelect.map(lead => lead.id),
                    this.allLeadsInTableSelected,
                    this.dashboardCriteria,
                );
                if (this.bulkSelect.length > 1) {
                    this.$notify.info(this.$t('massActions.warningUpdate'));
                }
            },

            async updateBulkWantedVehicles() {
                const mapping = {
                    wantedVehicleMake: 'make',
                    wantedVehicleModel: 'model',
                    wantedVehicleTrim: 'trim',
                    wantedVehiclePayment: 'payment',
                    wantedVehicleTaxedPayment: 'payment_with_tax',
                    wantedVehicleResidual: 'residual',
                    wantedVehicleRate: 'rate',
                    wantedVehicleFrequency: 'frequency',
                    wantedVehicleModality: 'modality',
                    wantedVehicleTerm: 'term',
                    wantedVehicleTransmission: 'transmission',
                    wantedVehicleFuel: 'fuel',
                    wantedVehicleStockState: 'stock_state',
                };

                const data = {
                    [mapping[this.field]]: this.selected != -1 ? this.selected : null,
                };

                if (this.selectedOption?.additionalData) {
                    Object.assign(data, this.selectedOption.additionalData);
                }

                await this.bulkUpdateWantedVehicles(
                    data,
                    this.bulkSelect.map(lead => lead.id),
                    this.allDashboardLeadSelect,
                    this.dashboardCriteria,
                );
            },

            async updateBulkExchangeVehicles() {
                const mapping = {
                    exchangeVehicleInServiceDate: 'in_service_date',
                    exchangeVehiclePayment: 'payment',
                    exchangeVehicleTaxedPayment: 'payment_with_tax',
                    exchangeVehicleResidual: 'residual',
                    exchangeVehicleRate: 'rate',
                    exchangeVehicleFrequency: 'frequency',
                    exchangeVehicleModality: 'modality',
                    exchangeVehicleTerm: 'term',
                    exchangeVehicleTransmission: 'transmission',
                    exchangeVehicleFuel: 'fuel',
                    exchangeVehicleIntention: 'intention',
                    exchangeVehicleCondition: 'condition',
                    exchangeVehicleValue: 'value',
                };

                const data = {
                    [mapping[this.field]]: this.selected != -1 ? this.selected : null,
                };

                if (this.selectedOption?.additionalData) {
                    Object.assign(data, this.selectedOption.additionalData);
                }

                await this.bulkUpdateExchangeVehicles(
                    data,
                    this.bulkSelect.map(lead => lead.id),
                    this.allDashboardLeadSelect,
                    this.dashboardCriteria,
                );
            },

            reset() {
                this.processing = false;
                this.field = null;
                this.fieldObject = {};
                this.selected = null;
                this.lostReason = '';
                this.caslNote = '';
            },

            close() {
                this.$emit('update:opened', false);
            },

            onClosed() {
                this.reset();
            },
        },
    };
</script>
