<template>
    <activix-tooltip :content="tooltip">
        <icon
            class="text-xl cursor-pointer mr-2"
            :class="isActive ? 'text-blue-500 hover:text-blue-400' : 'text-grey-500 hover:text-grey-600'"
            :name="icon"
            @click="$emit('click', currentKey)"
        />
    </activix-tooltip>
</template>

<script>
    export default {
        props: {
            currentKey: {
                type: String,
                required: true,
            },
            activeKey: {
                type: String,
                required: true,
            },
            icon: {
                type: String,
                required: true,
            },
            tooltip: {
                type: String,
                required: true,
            },
        },

        computed: {
            isActive() {
                return this.activeKey == this.currentKey;
            },
        },
    };
</script>
