<template>
    <div>
        <span>
            <span class="font-bold">{{ cards.leadsWalkIn.number.count }}</span>
            {{ $t('walkIn.walkIn') }}
        </span>
        <span class="ml-4">
            <span class="font-bold">{{ cards.leadsPhoneUp.number.count }}</span>
            {{ $t('phoneUp.phoneUp') }}
        </span>
    </div>
</template>

<script>
    export default {
        props: {
            cards: {
                type: Object,
                default: () => {},
            },
        },
    };
</script>
