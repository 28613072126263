export default {
    data() {
        return {
            charts: [],
        };
    },

    methods: {
        destroyCharts() {
            this.charts.forEach(chart => {
                chart.destroy();
            });

            this.charts = [];
        },
    },

    beforeDestroy() {
        this.destroyCharts();
    },
};
