<template>
    <div>
        <div class="flex flex-wrap lg:-mx-3">
            <card-small
                class="px-3 w-full | xl:w-1/3"
                :number="cards.leads.count"
                :title="$tc('activityReport.stats.leadsWorked', cards.leads.count)"
            />
            <card-small
                class="px-3 w-full | xs:w-1/2 | xl:w-1/3"
                :number="cards.outgoingCommunications.count"
                :title="$tc('activityReport.stats.outgoingCommunications', cards.outgoingCommunications.count)"
            />
            <card-small
                class="px-3 w-full | xs:w-1/2 | xl:w-1/3"
                :number="cards.incomingCommunications.count"
                :title="$tc('activityReport.stats.incomingCommunications', cards.incomingCommunications.count)"
            />
        </div>

        <div class="flex flex-wrap lg:-mx-3">
            <card
                class="px-3 w-full | xs:w-1/3 | xl:w-1/5"
                icon="regular/phone-actions-next"
                :card="cards.outgoingCalls"
                :title="$tc('activityReport.stats.outgoingCalls', cards.outgoingCalls.count)"
            />
            <card
                class="px-3 w-full | xs:w-1/3 | xl:w-1/5"
                icon="regular/drawer-send"
                :card="cards.outgoingEmailsAndTextMessages"
                :title="
                    $tc('activityReport.stats.outgoingEmailsAndTextMessages', cards.outgoingEmailsAndTextMessages.count)
                "
            >
                <template #description>
                    <div class="flex justify-center text-lg -mt-1">
                        <div class="text-right">
                            <div class="mt-1">
                                {{ $tc('activityReport.stats.outgoingEmails', cards.outgoingEmails.count) }}
                            </div>
                            <div class="mt-1">
                                {{ $tc('activityReport.stats.outgoingTextMessages', cards.outgoingTextMessages.count) }}
                            </div>
                        </div>
                        <div class="text-left ml-3">
                            <div class="mt-1">
                                <span class="font-bold">{{ cards.outgoingEmails.count }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="font-bold">{{ cards.outgoingTextMessages.count }}</span>
                            </div>
                        </div>
                    </div>
                </template>
            </card>
            <card
                class="px-3 w-full | xs:w-1/3 | xl:w-1/5"
                :icon="$icons.appointment"
                :card="cards.appointments"
                :title="$tc('activityReport.stats.appointments', cards.appointments.count)"
            />
            <card
                class="px-3 w-full | xs:w-1/2 | xl:w-1/5"
                :icon="$icons.visit"
                :card="cards.visits"
                :title="$tc('activityReport.stats.visits', cards.visits.count)"
            />
            <card
                class="px-3 w-full | xs:w-1/2 | xl:w-1/5"
                :icon="$icons.sale"
                :card="cards.sales"
                :title="$tc('activityReport.stats.sales', cards.sales.count)"
            />
        </div>

        <div class="box | mb-6" :class="{ loading: !loaded.userStats }" v-if="!contextUser.id">
            <div class="box-header | flex items-center justify-between">
                <h4 class="box-title">
                    {{
                        options.showAgent
                            ? $t('activityReport.boxTitles.resultsByAgent')
                            : $t('activityReport.boxTitles.resultsByAdvisor')
                    }}
                </h4>
                <div class="flex items-center">
                    <div class="ml-3 link-grey" @click="$eventBus.$emit('toggle-expanded', 'statsByAgent')">
                        <icon
                            class="spin-inverse"
                            :name="$icons.loading"
                            v-if="!loaded.userStats && expanded.statsByAgent"
                        />
                        <icon :name="$icons.collapse" v-else-if="expanded.statsByAgent" />
                        <icon :name="$icons.expand" v-else />
                    </div>
                </div>
            </div>
            <div class="box-body p-0" v-if="expanded.statsByAgent">
                <stats-by-agent :table-data="$stats.userLeads[dashboard]" />
            </div>
        </div>

        <advanced-stats :loading="!loaded.advancedStats" :filtered-users="filteredUsers" ref="advancedStats" />
    </div>
</template>

<script>
    // Utils
    import { debounce, isEqual } from 'lodash-es';

    // Components
    import { mapState } from 'pinia';
    import StatsByAgent from './StatsByAgent.vue';
    import AdvancedStats from './AdvancedStats.vue';
    import Card from '../../../components/dashboards/cards/Card.vue';
    import CardSmall from '../../../components/dashboards/cards/CardSmall.vue';

    // Mixins
    import DashboardCaching from '../../../mixins/DashboardCaching.js';
    import DashboardCustomFields from '../../../mixins/DashboardCustomFields.js';

    // Entities
    import Role from '../../../entities/Role.js';
    import User from '../../../entities/User.js';
    import DashboardType from '../../../entities/DashboardType.js';

    // Pinia
    import { useDashboardStore } from '../../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../../store/modules/context/store.js';
    import { useGlobalStore } from '../../../store/store.js';

    export default {
        name: 'ActivityReport',

        components: {
            Card,
            CardSmall,
            StatsByAgent,
            AdvancedStats,
        },

        mixins: [DashboardCaching, DashboardCustomFields],

        data() {
            return {
                dashboard: DashboardType.ACTIVITY,
                users: [],
                view: 'grid',
                cards: {
                    leads: {
                        count: 0,
                        colors: {
                            0: 'grey',
                        },
                    },
                    outgoingCommunications: {
                        count: 0,
                        colors: {
                            0: 'blue',
                        },
                    },
                    incomingCommunications: {
                        count: 0,
                        colors: {
                            0: 'blue',
                        },
                    },
                    outgoingCalls: {
                        count: 0,
                        colors: {
                            0: 'blue',
                        },
                    },
                    outgoingEmails: {
                        count: 0,
                        colors: {
                            0: 'blue',
                        },
                    },
                    outgoingTextMessages: {
                        count: 0,
                        colors: {
                            0: 'blue',
                        },
                    },
                    outgoingEmailsAndTextMessages: {
                        count: 0,
                        colors: {
                            0: 'blue',
                        },
                    },
                    appointments: {
                        count: 0,
                        colors: {
                            0: 'blue',
                        },
                    },
                    visits: {
                        count: 0,
                        colors: {
                            0: 'blue',
                        },
                    },
                    sales: {
                        count: 0,
                        colors: {
                            0: 'blue',
                        },
                    },
                    deliveries: {
                        count: 0,
                        colors: {
                            0: 'blue',
                        },
                    },
                },
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
                contextUser: 'user',
            }),
            ...mapState(useDashboardStore, {
                dashboardDisabledForGroups: 'dashboardDisabledForGroups',
                endDate: 'endDate',
                expanded: store => store.configs.expanded,
                options: store => store.configs.options,
                filteredLeadTypes: 'filteredLeadTypes',
                startDate: 'startDate',
            }),

            // @TODO Handler fetching.objectives?
            // isLoading() {
            //     return this.loading || !this.loaded || this.$wait.is('fetching.objectives');
            // },

            loaded() {
                return this.$stats.loaded[this.dashboard];
            },

            cardStatsLoading() {
                return this.$wait.is([`filtering.stats.${this.dashboard}`, `calculating.cardStats.${this.dashboard}`]);
            },

            userStatsLoading() {
                return this.$wait.is([`filtering.stats.${this.dashboard}`, `calculating.userStats.${this.dashboard}`]);
            },

            advancedStatsLoading() {
                return this.$wait.is([
                    `filtering.stats.${this.dashboard}`,
                    `calculating.advancedStats.${this.dashboard}`,
                ]);
            },

            filteredUsers() {
                if (this.contextUser.id) {
                    return this.users.filter(user => {
                        return user.id == this.contextUser.id;
                    });
                }

                if (this.options.showAgent) {
                    return this.users.filter(user => {
                        return [Role.AGENT, Role.DIRECTOR_BDC].includes(user.role_id) && user.active && !user.hide_in_user_select;
                    });
                }

                return this.users.filter(user => {
                    return [Role.ADVISOR, Role.DIRECTOR].includes(user.role_id) && user.active && !user.hide_in_user_select;
                });
            },
        },

        watch: {
            'contextAccount.id': {
                immediate: true,
                handler() {
                    this.fetchUsers();
                },
            },

            filteredLeadTypes(newValue, oldValue) {
                if (isEqual(newValue, oldValue)) {
                    return;
                }

                this.debounceLeadsUpdated();
            },

            filteredUsers(newValue, oldValue) {
                if (isEqual(newValue, oldValue)) {
                    return;
                }

                this.debounceLeadsUpdated();
            },

            cardStatsLoading(isLoading) {
                if (!isLoading) {
                    this.$stats.loaded[this.dashboard].cardStats = true;
                }
            },

            userStatsLoading(isLoading) {
                if (!isLoading) {
                    this.$stats.loaded[this.dashboard].userStats = true;
                }
            },

            advancedStatsLoading(isLoading) {
                if (!isLoading) {
                    this.$stats.loaded[this.dashboard].advancedStats = true;
                }
            },
        },

        methods: {
            async fetchUsers() {
                if (!this.contextAccount.id) {
                    return;
                }

                this.$wait.start('fetching.objectives');

                try {
                    const users = await this.$api.users.index({
                        filter: {
                            id: this.contextUser.id,
                            accountId: this.contextAccount.id,
                        },
                        include: ['objectives', 'children'],
                    });

                    this.users = users.map(user => new User(user));

                    this.$wait.end('fetching.objectives');
                } catch (error) {
                    this.$wait.end('fetching.objectives');
                    this.$notify.error(this.$t('objectives.alerts.index.error'));

                    throw error;
                }
            },

            calculateCards() {
                if (!this.startDate || !this.endDate || this.$wait.is('fetching.stats')) {
                    return;
                }

                this.$wait.start(`calculating.cardStats.${this.dashboard}`);

                this.$stats.calculateStats({
                    action: 'calculateCards',
                    dashboard: this.dashboard,
                    payload: {
                        cards: this.cards,
                        endDate: this.endDate,
                        leadTypes: this.filteredLeadTypes,
                        showAgent: this.options.showAgent,
                        startDate: this.startDate,
                        users: this.filteredUsers,
                    },
                });
            },

            calculateUsers() {
                if (!this.startDate || !this.endDate || this.$wait.is('fetching.stats')) {
                    return;
                }

                this.$wait.start(`calculating.userStats.${this.dashboard}`);

                this.$stats.calculateStats({
                    action: 'calculateUsers',
                    dashboard: this.dashboard,
                    payload: {
                        endDate: this.endDate,
                        leadTypes: this.filteredLeadTypes,
                        showAgent: this.options.showAgent,
                        startDate: this.startDate,
                        users: this.filteredUsers,
                    },
                });
            },

            setCardsStats(cards) {
                this.cards = Object.freeze(cards);
                this.setCaching('cards', cards);
                this.$wait.end(`calculating.cardStats.${this.dashboard}`);
            },

            setUserStats(users) {
                this.$stats.setUserLeads(users);
                this.$wait.end(`calculating.userStats.${this.dashboard}`);
            },

            setAdvancedStats(advancedStats) {
                this.$stats.setAdvancedStats(advancedStats);
                this.$wait.end(`calculating.advancedStats.${this.dashboard}`);
            },

            debounceLeadsUpdated() {
                this.leadsUpdated();
            },

            leadsUpdated() {
                this.calculateCards();
                this.calculateUsers();

                if (this.$refs.advancedStats) {
                    this.$refs.advancedStats.calculateAdvancedStats();
                }
            },

            onStatsCalculated(data) {
                if (data.dashboard != this.dashboard) {
                    return;
                }

                if (data.action == 'calculateCards') {
                    this.setCardsStats(data.result);
                } else if (data.action == 'calculateUsers') {
                    this.setUserStats(data.result);
                } else if (data.action == 'calculateAdvancedStats') {
                    this.setAdvancedStats(data.result);
                }
            },
        },

        created() {
            this.debounceLeadsUpdated = debounce(this.debounceLeadsUpdated, 200);
            this.$stats.$on('stats-worker', this.onStatsCalculated);
        },

        beforeDestroy() {
            this.$wait.end('fetching.objectives');
            this.$wait.end(`filtering.stats.${this.dashboard}`);
            this.$wait.end(`calculating.cardStats.${this.dashboard}`);
            this.$wait.end(`calculating.userStats.${this.dashboard}`);
            this.$wait.end(`calculating.advancedStats.${this.dashboard}`);

            this.$stats.$off('stats-worker', this.onStatsCalculated);
        },
    };
</script>
