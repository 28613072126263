<template>
    <div>
        <div class="flex flex-wrap lg:-mx-3">
            <card-small
                class="px-3 w-full | xs:w-1/2 lg:w-1/3 "
                :class="smallCardClass"
                :number="card.number"
                :title="card.title"
                :back-number="card.backNumber"
                :back-title="card.backTitle"
                :back-icon="card.backIcon"
                :key="card.name"
                v-for="card in activeSmallCards"
            >
                <template #description v-if="card.componentDescription">
                    <component :is="card.componentDescription" :cards="cards" />
                </template>
                <template #description v-else-if="card.description">
                    <div class="flex justify-center text-lg">
                        <div class="text-xl">
                            {{ card.description }}
                        </div>
                    </div>
                </template>
                <template #switch v-if="card.componentSwitch">
                    <component :is="card.componentSwitch" :cards="cards" />
                </template>
            </card-small>
        </div>

        <div class="flex flex-wrap lg:-mx-3">
            <card
                class="px-3 w-full | xs:w-1/3"
                :class="bigCardClass"
                :icon="card.icon"
                :card="card.card"
                :denominator="card.denominator"
                :title="card.title"
                :key="card.name"
                v-for="card in activeBigCards"
            >
                <template #description v-if="card.componentDescription">
                    <component :is="card.componentDescription" :cards="cards" />
                </template>
                <template #description v-else-if="card.description">
                    <div class="flex justify-center text-lg">
                        <div class="text-xl">
                            {{ card.description }}
                        </div>
                    </div>
                </template>
                <template #popover v-if="card.informationTooltip">
                    <div class="text-center" v-text="card.informationTooltip" />
                </template>
            </card>
        </div>

        <stats-table :dashboard="dashboard" ref="statsTable" />

        <div class="box | mb-6" v-if="tradeReportEnabled">
            <div class="box-header | flex items-center justify-between">
                <h4 class="box-title">
                    {{ $t('allLead.tradeReport') }}
                </h4>
                <div class="flex items-center">
                    <div class="ml-3 link-grey" @click="$eventBus.$emit('toggle-expanded', 'tradeReport')">
                        <icon :name="$icons.expand" v-if="!expanded.tradeReport" />
                        <icon class="spin-inverse" :name="$icons.loading" v-else-if="tradeReportIsLoading" />
                        <icon :name="$icons.collapse" v-else />
                    </div>
                </div>
            </div>
            <div class="box-body p-0" :class="{ loading: tradeReportIsLoading }" v-if="expanded.tradeReport">
                <trade-report
                    :general-leads="tradeReportData.generalLeads"
                    :detailed-leads="tradeReportData.detailedLeads"
                />
            </div>
        </div>
    </div>
</template>

<script>
    // Components
    import { mapState } from 'pinia';
    import TradeReport from '@/components/dashboards/TradeReport';
    import DashboardColumns from '@/mixins/DashboardColumns';
    import Card from '../../components/dashboards/cards/Card.vue';
    import CardSmall from '../../components/dashboards/cards/CardSmall.vue';
    import StatsTable from '../StatsTables/StatsTable.vue';

    // Mixins
    import TrackView from '../../mixins/TrackView.js';
    import DashboardCaching from '../../mixins/DashboardCaching.js';
    import DashboardCustomFields from '../../mixins/DashboardCustomFields.js';
    import DashboardCards from '../../mixins/DashboardCards.js';

    // Entities
    import DashboardType from '../../entities/DashboardType.js';

    // Pinia
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        name: 'AllLead',

        components: {
            Card,
            CardSmall,
            StatsTable,
            TradeReport,
        },

        mixins: [TrackView, DashboardCaching, DashboardCustomFields, DashboardColumns, DashboardCards],

        data() {
            return {
                dashboard: DashboardType.ALL,
                cards: {
                    leads: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                        assigned: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                        sale: {
                            count: 0,
                            colors: {
                                0: 'grey',
                                1: 'red',
                                12: 'orange',
                                18: 'green',
                            },
                        },
                        saleByPhone: {
                            count: 0,
                        },
                        delivered: {
                            count: 0,
                            colors: {
                                0: 'blue',
                            },
                        },
                    },
                    leadsWebAndOrder: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                        sale: {
                            count: 0,
                            colors: {
                                0: 'grey',
                                1: 'red',
                                10: 'orange',
                                12: 'green',
                            },
                        },
                    },
                    leadsUp: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                        sale: {
                            count: 0,
                            colors: {
                                0: 'grey',
                                1: 'red',
                                10: 'orange',
                                12: 'green',
                            },
                        },
                    },
                    leadsOther: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                        sale: {
                            count: 0,
                            colors: {
                                0: 'grey',
                                1: 'red',
                                10: 'orange',
                                12: 'green',
                            },
                        },
                    },
                    leadsWeb: {
                        number: {
                            count: 0,
                        },
                        sale: {
                            count: 0,
                        },
                    },
                    leadsWebOrder: {
                        number: {
                            count: 0,
                        },
                        sale: {
                            count: 0,
                        },
                    },
                    leadsPhoneUp: {
                        number: {
                            count: 0,
                        },
                        sale: {
                            count: 0,
                        },
                    },
                    leadsWalkIn: {
                        number: {
                            count: 0,
                        },
                        sale: {
                            count: 0,
                        },
                    },
                    leadsRenewal: {
                        number: {
                            count: 0,
                        },
                        sale: {
                            count: 0,
                        },
                    },
                    leadWebSaleSource: {
                        testDrive: {
                            count: 0,
                        },
                        other: {
                            count: 0,
                        },
                    },
                },
                tradeReportData: {
                    generalLeads: [],
                    detailedLeads: [],
                },
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
                contextTeam: 'contextTeam',
            }),
            ...mapState(useDashboardStore, {
                endDate: 'endDate',
                expanded: store => store.configs.expanded,
                options: store => store.configs.options,
                startDate: 'startDate',
                filteredDates: 'filteredDates',
            }),

            smallCards() {
                if (this.contextAccount.isPolestar()) {
                    return {
                        allLead: {
                            visible: true,
                        },
                        receivedOrder: {
                            visible: true,
                        },
                        webLeads: {
                            visible: true,
                        },
                        walkInLeads: {
                            visible: true,
                        },
                        phoneUp: {
                            visible: true,
                        },
                        renewal: {
                            visible: true,
                        },
                    };
                }

                return {
                    allLead: {
                        visible: true,
                    },
                    webOrder: {
                        visible: true,
                    },
                    leadsUp: {
                        visible: true,
                    },
                    renewalLoyalty: {
                        visible: true,
                    },
                };
            },

            bigCards() {
                if (this.contextAccount.isPolestar()) {
                    return {
                        sale: {
                            visible: true,
                        },
                        allLeadWebOrder: {
                            visible: true,
                        },
                        allLeadWalkIn: {
                            visible: true,
                        },
                        allLeadPhoneUp: {
                            visible: true,
                        },
                        renewal: {
                            visible: true,
                        },
                        delivered: {
                            visible: true,
                        },
                    };
                }

                return {
                    sale: {
                        visible: true,
                    },
                    webOrder: {
                        visible: true,
                    },
                    upSale: {
                        visible: true,
                    },
                    renewalLoyalty: {
                        visible: true,
                    },
                    delivery: {
                        visible: true,
                    },
                };
            },

            tradeReportIsLoading() {
                return this.$wait.is('fetching.tradeReport') && !this.$wait.is('changingDashboard');
            },

            tradeReportEnabled() {
                return this.contextAccount.trade_report && (this.authUser.trade_report || this.authUser.isAdmin());
            },
        },

        watch: {
            'expanded.tradeReport': {
                immediate: true,
                handler() {
                    this.fetchTradeReport();
                },
            },
        },

        methods: {
            async fetchStats(options) {
                await Promise.all([
                    this.$refs.statsTable.fetchStats(options),
                    this.fetchTradeReport(options),
                ]);
            },

            async fetchTradeReport({ background = false } = {}) {
                if (!this.expanded.tradeReport || !this.startDate || !this.endDate || !this.tradeReportEnabled) {
                    return;
                }

                if (!background) {
                    this.$wait.start('fetching.tradeReport');
                }

                try {
                    const response = await this.$api.dashboard.getTradeReport({
                        dashboard: DashboardType.ALL,
                        endDate: this.parsedEndDate.toDateTimeString(),
                        startDate: this.parsedStartDate.toDateTimeString(),
                        filteredDates: this.filteredDates,
                        options: this.options,
                        accountId: this.contextAccount.id,
                        filters: this.activeDashboardFilters,
                        teamId: this.contextTeam.id,
                    });

                    if (response) {
                        this.tradeReportData.generalLeads = [response.generalLeads];
                        this.tradeReportData.detailedLeads = [response.detailedLeads];

                        this.$wait.end('fetching.tradeReport');
                    }
                } catch (error) {
                    this.$notify.warning(this.$t('dashboards.alerts.stats.error'));
                    this.$wait.end('fetching.tradeReport');
                }
            },

            setColumns() {
                useDashboardStore().configs.columns = {
                    bulk_select: {
                        visible: true,
                    },
                    fullname: {
                        visible: true,
                    },
                    contact_method: {
                        visible: true,
                    },
                    civility: {
                        visible: false,
                    },
                    business: {
                        visible: false,
                    },
                    created_by: {
                        visible: false,
                    },
                    updated_by: {
                        visible: false,
                    },
                    date: {
                        visible: true,
                    },
                    updated_at: {
                        visible: false,
                    },
                    'customer:casl_consent_status': {
                        visible: false,
                    },
                    'customer:casl_consent_limit_date': {
                        visible: false,
                    },
                    'division:name': {
                        visible: true,
                    },
                    status: {
                        visible: true,
                    },
                    result: {
                        visible: true,
                    },
                    'lead_type:name': {
                        visible: true,
                    },
                    advisors: {
                        visible: true,
                    },
                    agents: {
                        visible: true,
                    },
                    'commercial:': {
                        visible: false,
                    },
                    'account:name': {
                        visible: true,
                    },
                    rating: {
                        visible: false,
                    },
                    city: {
                        visible: false,
                    },
                    country: {
                        visible: false,
                    },
                    province: {
                        visible: false,
                    },
                    postal_code: {
                        visible: false,
                    },
                    address: {
                        visible: false,
                    },
                    locale: {
                        visible: false,
                    },
                    sector: {
                        visible: false,
                    },
                    budget: {
                        visible: false,
                    },
                    walk_around: {
                        visible: false,
                    },
                    'wanted_vehicles:budget_min': {
                        visible: false,
                    },
                    'wanted_vehicles:budget_max': {
                        visible: false,
                    },
                    created_method: {
                        visible: false,
                    },
                    lead_state: {
                        visible: false,
                    },
                    'import:original_file': {
                        visible: false,
                    },
                    'wanted_vehicles:category': {
                        visible: false,
                    },
                    'wanted_vehicles:category_rv': {
                        visible: false,
                    },
                    'wanted_vehicles:mechanical': {
                        visible: false,
                    },
                    'wanted_vehicles:length_min': {
                        visible: false,
                    },
                    'wanted_vehicles:length_max': {
                        visible: false,
                    },
                    'wanted_vehicles:weight': {
                        visible: false,
                    },
                    'wanted_vehicles:make': {
                        visible: false,
                    },
                    'wanted_vehicles:model': {
                        visible: true,
                    },
                    'wanted_vehicles:year': {
                        visible: true,
                    },
                    'wanted_vehicles:fuel': {
                        visible: false,
                    },
                    'wanted_vehicles:version': {
                        visible: false,
                    },
                    'wanted_vehicles:certified': {
                        visible: false,
                    },
                    'wanted_vehicles:transmission': {
                        visible: false,
                    },
                    'wanted_vehicles:color_exterior': {
                        visible: false,
                    },
                    'wanted_vehicles:color_interior': {
                        visible: false,
                    },
                    'wanted_vehicles:stock_state': {
                        visible: false,
                    },
                    'wanted_vehicles:stock': {
                        visible: false,
                    },
                    'wanted_vehicles:vin': {
                        visible: false,
                    },
                    'wanted_vehicles:end_warranty_date': {
                        visible: false,
                    },
                    'wanted_vehicles:modality': {
                        visible: false,
                    },
                    'wanted_vehicles:tire': {
                        visible: false,
                    },
                    phone_appointment: {
                        visible: true,
                    },
                    appointment: {
                        visible: true,
                    },
                    presented: {
                        visible: true,
                    },
                    road_test: {
                        visible: true,
                    },
                    long_term: {
                        visible: false,
                    },
                    dealer_tour: {
                        visible: false,
                    },
                    take_over: {
                        visible: true,
                    },
                    'take_over_director:': {
                        visible: false,
                    },
                    twenty_four_hour: {
                        visible: true,
                    },
                    be_back: {
                        visible: true,
                    },
                    deposit: {
                        visible: false,
                    },
                    write_up: {
                        visible: false,
                    },
                    qualification: {
                        visible: false,
                    },
                    progress_state: {
                        visible: true,
                    },
                    approved: {
                        visible: true,
                    },
                    'lead_bank:name': {
                        visible: false,
                    },
                    waiting_sale: {
                        visible: false,
                    },
                    sale_by_phone: {
                        visible: false,
                    },
                    sale: {
                        visible: true,
                    },
                    available: {
                        visible: true,
                    },
                    paperwork: {
                        visible: true,
                    },
                    refinanced: {
                        visible: false,
                    },
                    deliverable: {
                        visible: false,
                    },
                    delivery: {
                        visible: true,
                    },
                    'wanted_vehicles:verified': {
                        visible: false,
                    },
                    prepared: {
                        visible: false,
                    },
                    prepared_work_order: {
                        visible: false,
                    },
                    gas: {
                        visible: false,
                    },
                    inspected: {
                        visible: false,
                    },
                    delivered: {
                        visible: true,
                    },
                    'wanted_vehicles:recorded_date': {
                        visible: false,
                    },
                    discounted_date: {
                        visible: false,
                    },
                    csi: {
                        visible: true,
                    },
                    'exchange_vehicles:category': {
                        visible: false,
                    },
                    'exchange_vehicles:category_rv': {
                        visible: false,
                    },
                    'exchange_vehicles:mechanical': {
                        visible: false,
                    },
                    'exchange_vehicles:length_min': {
                        visible: false,
                    },
                    'exchange_vehicles:length_max': {
                        visible: false,
                    },
                    'exchange_vehicles:weight': {
                        visible: false,
                    },
                    'exchange_vehicles:make': {
                        visible: false,
                    },
                    'exchange_vehicles:model': {
                        visible: false,
                    },
                    'exchange_vehicles:year': {
                        visible: false,
                    },
                    'exchange_vehicles:version': {
                        visible: false,
                    },
                    'exchange_vehicles:certified': {
                        visible: false,
                    },
                    'exchange_vehicles:trade_type': {
                        visible: false,
                    },
                    'exchange_vehicles:transmission': {
                        visible: false,
                    },
                    'exchange_vehicles:color_exterior': {
                        visible: false,
                    },
                    'exchange_vehicles:color_interior': {
                        visible: false,
                    },
                    'exchange_vehicles:condition': {
                        visible: false,
                    },
                    'exchange_vehicles:fuel': {
                        visible: false,
                    },
                    'exchange_vehicles:tire_type': {
                        visible: false,
                    },
                    'exchange_vehicles:accidented_damage_cost': {
                        visible: false,
                    },
                    'exchange_vehicles:value': {
                        visible: false,
                    },
                    'exchange_vehicles:actual_value': {
                        visible: false,
                    },
                    'exchange_vehicles:residual': {
                        visible: false,
                    },
                    'exchange_vehicles:calculator_appraiser': {
                        visible: false,
                    },
                    'exchange_vehicles:calculator_value': {
                        visible: false,
                    },
                    'exchange_vehicles:modality': {
                        visible: false,
                    },
                    storage: {
                        visible: false,
                    },
                    listening: {
                        visible: false,
                    },
                    followed: {
                        visible: true,
                    },
                    followed_by: {
                        visible: false,
                    },
                    todo: {
                        visible: true,
                    },
                    provider: {
                        visible: false,
                    },
                    source: {
                        visible: true,
                    },
                    form: {
                        visible: false,
                    },
                    imported_campaign: {
                        visible: false,
                    },
                    referrer: {
                        visible: false,
                    },
                    search_term: {
                        visible: false,
                    },
                    keyword: {
                        visible: false,
                    },
                    'latest_comment:content': {
                        visible: true,
                    },
                    unsubscribe: {
                        visible: false,
                    },
                    birth_date: {
                        visible: false,
                    },
                    ...this.getCustomFields(),
                    updated_by_supplier: {
                        visible: false,
                    },
                };
            },

            setCards(results) {
                this.cards.leads.number.count = results.count;
                this.cards.leads.assigned.count = results.assignments;
                this.cards.leads.sale.count = results.sales;
                this.cards.leads.saleByPhone.count = results.salesByPhone;
                this.cards.leads.delivered.count = results.deliveries;

                this.cards.leadsWeb.number.count = results.webLeads.count;
                this.cards.leadsWeb.sale.count = results.webLeads.sales;

                this.cards.leadsWebOrder.number.count = results.webOrderLeads.count;
                this.cards.leadsWebOrder.sale.count = results.webOrderLeads.sales;

                this.cards.leadsWebAndOrder.number.count = results.webAndOrderLeads.count;
                this.cards.leadsWebAndOrder.sale.count = results.webAndOrderLeads.sales;

                this.cards.leadsPhoneUp.number.count = results.phoneLeads.count;
                this.cards.leadsPhoneUp.sale.count = results.phoneLeads.sales;

                this.cards.leadsWalkIn.number.count = results.walkInLeads.count;
                this.cards.leadsWalkIn.sale.count = results.walkInLeads.sales;

                this.cards.leadsUp.number.count = results.upLeads.count;
                this.cards.leadsUp.sale.count = results.upLeads.sales;

                this.cards.leadsOther.number.count = results.otherLeads.count;
                this.cards.leadsOther.sale.count = results.otherLeads.sales;

                this.cards.leadsRenewal.number.count = results.renewalLeads.count;
                this.cards.leadsRenewal.sale.count = results.renewalLeads.sales;

                this.cards.leadWebSaleSource.testDrive.count = results.leadWebSaleSource.testDrive;
                this.cards.leadWebSaleSource.other.count = results.leadWebSaleSource.other;

                this.$nextTick(() => {
                    this.setCaching('cards', this.cards);
                });
            },
        },
    };
</script>
