<template>
    <div :class="{ flex: dashboardDisabledForGroups }">
        <dashboard-header :current-count="currentCount" :total-count="totalCount" @trigger-fetch="fetchStatLeads" />

        <warning
            :icon="$icons.warning"
            :title="$t('error.warning')"
            :content="$t('dashboards.disabledForGroups')"
            :show-home="true"
            :show-back="true"
            v-if="dashboardDisabledForGroups"
        />
        <template v-else>
            <keep-alive>
                <router-view class="stats" ref="dashboard" />
            </keep-alive>

            <lead-table-box />
        </template>
    </div>
</template>

<script>
    // Utils
    import { debounce } from 'lodash-es';
    import { mapActions, mapState } from 'pinia';
    import { orderByKey } from '../../utils/index.js';

    // Components
    import DashboardHeader from '../../components/dashboards/DashboardHeader.vue';
    import LeadTableBox from '../../components/dashboards/LeadTableBox.vue';
    import Warning from '../../components/Warning.vue';

    // Mixins
    import TrackView from '../../mixins/TrackView.js';
    import DashboardCaching from '../../mixins/DashboardCaching.js';
    import FetchDashboardStats from '../../mixins/FetchDashboardStats.js';
    import DashboardColumns from '../../mixins/DashboardColumns.js';

    // Pinia
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        name: 'Dashboard',

        components: {
            DashboardHeader,
            LeadTableBox,
            Warning,
        },

        mixins: [TrackView, DashboardCaching, FetchDashboardStats, DashboardColumns],

        data() {
            return {
                currentCount: 0,
                totalCount: 0,
                lastPage: 0,
                perPage: 100,
            };
        },

        computed: {
            ...mapState(useGlobalStore, {
                session: store => store.parentAuthUser.session,
                parentAuthUser: 'parentAuthUser',
                authUser: 'authUser',
                groupDisplay: 'groupDisplay',
            }),
            ...mapState(useContextStore, {
                contextAccount: 'account',
                contextTeamId: 'teamId',
                contextUser: 'user',
            }),
            ...mapState(useDashboardStore, {
                division: 'division',
                dashboardView: 'dashboardView',
                dashboardDisabledForGroups: 'dashboardDisabledForGroups',
                dashboardConfigs: 'configs',
                bulkSelect: 'bulkSelect',
                dashboardType: 'dashboardType',
                disableStatFetch: 'disableStatFetch',
                endDate: 'endDate',
                filteredCampaigns: 'filteredCampaigns',
                filteredDates: 'filteredDates',
                startDate: 'startDate',
                viewId: 'viewId',
            }),
        },

        watch: {
            '$route.name'() {
                // Can't use `immediate: true` because it doesn't trigger `dashboardType` watch.
                this.setDashboardType();
            },

            '$route.query.viewId'(newViewId) {
                useDashboardStore().viewId = newViewId || null;
            },

            dashboardType(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.getDashboardColumns();
                }

                this.setAllLeadsInTableSelected(false);
                this.updateQuery();
                this.setValuesFromSession();
            },

            dashboardView() {
                this.getDashboardColumns();
                this.setValuesFromSession();
            },

            'contextGroup.id'() {
                this.onGroupChanged();
            },

            'contextAccount.id'() {
                this.onAccountChanged();
            },

            'contextUser.id'() {
                this.onUserChanged();
            },

            viewId(newValue) {
                if (newValue == 'null') {
                    useDashboardStore().viewId = null;
                    return;
                }

                if (!empty(newValue)) {
                    this.fetchView();
                    return;
                }

                useDashboardStore().selectedDashboardView = {};
            },

            startDate(newValue, oldValue) {
                if (newValue == oldValue) {
                    return;
                }

                this.updateQuery();

                if (typeof oldValue === 'undefined') {
                    return;
                }

                this.setAllLeadsInTableSelected(false);
                this.fetchStatLeads({ clearAll: true });
                this.$eventBus.$emit('fetch-table-leads', { page: 1 });
            },

            endDate(newValue, oldValue) {
                if (newValue == oldValue) {
                    return;
                }

                this.updateQuery();

                if (typeof oldValue === 'undefined') {
                    return;
                }

                this.setAllLeadsInTableSelected(false);
                this.fetchStatLeads({ clearAll: true });
                this.$eventBus.$emit('fetch-table-leads', { page: 1 });
            },

            contextTeamId(newValue, oldValue) {
                this.updateQuery(true);

                if (typeof oldValue === 'undefined') {
                    return;
                }

                this.$eventBus.$emit('fetch-table-leads', { page: 1 });
                this.filterStatLeads();
            },

            bulkSelect(newValue) {
                if ((empty(newValue) || newValue.length != 2) && !empty(this.mergeLead)) {
                    this.setMergeLead = null;
                    return;
                }

                if (!empty(newValue) && newValue.length == 2) {
                    this.setContextLeadIdAction(newValue[0].id);
                    this.setMergedLead(newValue[1]);
                }
            },

            filteredCampaigns() {
                this.$nextTick(() => {
                    this.fetchStatLeads();
                    this.$eventBus.$emit('fetch-table-leads', { page: 1 });
                });
            },
        },

        methods: {
            ...mapActions(useDashboardStore, [
                'setAllLeadsInTableSelected',
                'setDashboardConfigs',
                'setStartDate',
                'setEndDate',
                'setStartContractDateStore',
            ]),
            ...mapActions(useContextStore, ['setContextLeadIdAction', 'setContextUserAction', 'setContextTeamId']),
            ...mapActions(useGlobalStore, ['updateSessionAction', 'setMergedLead']),

            onGroupChanged() {
                useDashboardStore().filteredCampaigns = [];

                this.setAllLeadsInTableSelected(false);

                this.updateQuery();
                this.fetchStatLeads({ clearAll: true });
                this.$eventBus.$emit('fetch-table-leads', { page: 1 });
            },

            async onAccountChanged() {
                if (!this.contextAccount.id) {
                    return;
                }

                this.setAllLeadsInTableSelected(false);

                this.setStartContractDate();
                this.updateQuery(true);
                await this.getDashboardColumns();

                this.$eventBus.$emit('fetch-table-leads', { page: 1 });

                if (this.disableStatFetch || this.dashboardDisabledForGroups) {
                    useDashboardStore().disableStatFetch = true;
                    this.filterStatLeads();
                    return;
                }

                this.fetchStatLeads({ clearAll: true });
            },

            onUserChanged() {
                if (!this.contextUser.id) {
                    this.filterStatLeads();
                    return;
                }

                this.setAllLeadsInTableSelected(false);

                this.updateQuery(true);
                this.$eventBus.$emit('fetch-table-leads', { page: 1 });

                if (this.disableStatFetch || this.dashboardDisabledForGroups) {
                    useDashboardStore().disableStatFetch = true;
                    this.filterStatLeads();
                    return;
                }

                this.fetchStatLeads({ clearAll: true });
            },

            async getDashboardColumns() {
                await this.$nextTick();

                if (this.$refs.dashboard && this.$refs.dashboard.setColumns) {
                    this.$refs.dashboard.setColumns();
                }
            },

            setStartContractDate() {
                const accountActivationDate = as_locale(this.contextAccount.activation_date, 'activation_date');

                if (accountActivationDate.isValid()) {
                    this.setStartContractDateStore(accountActivationDate.toString());
                }
            },

            setDashboardType() {
                const oldDashboardType = this.dashboardType;
                const currentDashboardType = this.$route.name.replace(/^dashboards./, '');

                useDashboardStore().dashboardType = currentDashboardType;

                // Work around to fix infinite loading when changing page before fetch is finished
                if (oldDashboardType == currentDashboardType) {
                    this.fetchStatLeads({ force: false });
                }
            },

            debounceFilterStatLeads() {
                this.filterStatLeads();
            },

            filterStatLeads() {
                this.$wait.start(`filtering.stats.${this.dashboardType}`);

                const payload = {
                    authUser: this.parentAuthUser,
                    dashboardType: this.dashboardType,
                    divisions: this.division,
                    endDate: this.endDate,
                    filteredDates: this.filteredDates,
                    filters: this.activeDashboardFilters,
                    groupDisplay: this.groupDisplay,
                    options: this.dashboardConfigs.options,
                    startDate: this.startDate,
                };

                this.$stats.filterLeads(payload);
            },

            onLeadsFiltered(payload) {
                this.$wait.end(`filtering.stats.${payload.dashboardType}`);

                if (this.$wait.is('fetching.stats')) {
                    return;
                }

                this.totalCount = 0;
                this.lastPage = 0;

                if (this.$refs.dashboard) {
                    this.$refs.dashboard.leadsUpdated();
                }
            },

            toggleOption(option, value) {
                const isActive = typeof value === 'undefined' ? !this.dashboardConfigs.options[option] : value;
                const updatedOptions = {
                    [option]: isActive,
                };

                this.setDashboardConfigs({
                    options: updatedOptions,
                });

                this.updateSessionAction('dashboard_option', updatedOptions, false);

                switch (option) {
                    case 'phoneAppointment':
                        this.filterStatLeads();
                        break;

                    case 'carryOver':
                        this.filterStatLeads();
                        break;

                    case 'divisionByUser':
                        this.filterStatLeads();
                        break;

                    case 'doubleWalkIn':
                        this.filterStatLeads();
                        break;

                    case 'endContractStats':
                        this.$eventBus.$emit('fetch-table-leads', { page: 1 });
                        this.fetchStatLeads({ force: false });
                        break;

                    case 'showAllLeads':
                    case 'recordedDate':
                        this.$eventBus.$emit('fetch-table-leads', { page: 1 });
                        this.filterStatLeads();
                        break;

                    case 'resultStats':
                        this.$eventBus.$emit('fetch-table-leads', { page: 1 });
                        this.filterStatLeads();
                        break;

                    case 'showAgent':
                        break;
                }
            },

            toggleExpanded(box) {
                const isExpanded = !this.dashboardConfigs.expanded[box];
                const updatedExpanded = {
                    [box]: isExpanded,
                };

                this.setDashboardConfigs({
                    expanded: updatedExpanded,
                });

                this.updateSessionAction('dashboard_expand', updatedExpanded, false);
            },

            // @TODO Refactor using `FiltersInUrl`
            async updateQuery(redirect = false) {
                if (
                    this.authUser.isAdvisor() ||
                    (this.authUser.isBdc() && !this.authUser.bdc_super_access && this.contextAccount.id)
                ) {
                    await this.setContextUserAction(this.authUser.id);
                }

                const query = {};

                if (!empty(this.startDate)) {
                    query.startDate = as_locale(this.startDate, 'startDate').toDateString();
                }

                if (!empty(this.$route.query.modal)) {
                    query.modal = this.$route.query.modal;
                }

                if (!empty(this.endDate)) {
                    query.endDate = as_locale(this.endDate, 'endDate').toDateString();
                }

                if (!empty(this.viewId)) {
                    query.viewId = this.viewId;
                }

                if (this.contextTeamId) {
                    query.teamId = this.contextTeamId;
                }

                if (this.contextUser.id) {
                    query.userId = this.contextUser.id;
                } else if (this.contextGroup.id) {
                    query.groupId = this.contextGroup.id;
                } else if (!this.authUser.hasAdvisorSkills() && this.contextAccount.id) {
                    query.accountId = this.contextAccount.id;
                }

                const newRoute = {
                    ...this.$route,
                    query: orderByKey(query),
                };

                if (redirect) {
                    this.$router.push(newRoute);
                } else {
                    this.$router.replace(newRoute);
                }
            },

            async fetchView() {
                if (!this.viewId) {
                    return;
                }

                try {
                    const response = await this.$axios.get(`v1/dashboard-views/${this.viewId}`);

                    useDashboardStore().selectedDashboardView = response.data.data;
                } catch (error) {
                    this.$notify.error(this.$t('dashboardViews.alerts.show.error'));

                    throw error;
                }
            },

            deleteLeads(leadIds) {
                this.$stats.deleteLeads(leadIds);
                this.$eventBus.$emit('fetch-table-leads', { getCount: true });
            },

            onFiltersApplied() {
                this.$eventBus.$emit('fetch-table-leads', { page: 1 });
                this.filterStatLeads();
            },

            onFilteredDatesChanged() {
                this.$eventBus.$emit('fetch-table-leads', { page: 1 });
                this.filterStatLeads();
            },

            setValuesFromSession() {
                // Dashboard dependant options
                let columnVisibilities = this.session.dashboard_column[this.dashboardType] || {};
                let dates = this.session.dashboard_dates[this.dashboardType] || [];
                let expanded = this.session.dashboard_expand[this.dashboardType] || {};
                let filters = this.session.dashboard_filter[this.dashboardType] || {};
                let order = this.session.order[this.dashboardType] || {};

                // Global options
                let options = this.session.dashboard_option || {};
                let hotSwapDate = this.session.hot_swap_date || null;

                if (!this.dashboardView.isEmpty()) {
                    expanded = this.dashboardView.expand;
                    filters = this.dashboardView.filter;
                    options = this.dashboardView.option;
                    columnVisibilities = this.dashboardView.column;
                    dates = this.dashboardView.dates;
                    order = this.dashboardView.order;
                    hotSwapDate = this.dashboardView.hot_swap_date;

                    if (this.dashboardView.start_date) {
                        hotSwapDate = null;

                        this.setStartDate(this.dashboardView.start_date);
                        this.setEndDate(this.dashboardView.end_date);
                    }
                }

                if (empty(columnVisibilities)) {
                    columnVisibilities = {};
                }

                this.setDashboardConfigs({
                    columnVisibilities,
                    dates,
                    expanded: { ...expanded },
                    options,
                    order: { ...order },
                    hotSwapDate,
                    filters: { ...filters },
                });

                if (!this.$route.query.teamId) {
                    this.setContextTeamId(this.session.team_id);
                }

                this.$nextTick(() => {
                    this.$eventBus.$emit('fetch-table-leads', { page: 1 });
                    this.fetchStatLeads({ force: false });
                });
            },

            setValuesFromUrl() {
                const startDate = as_locale(this.$route.query.startDate, 'startDate');
                const endDate = as_locale(this.$route.query.endDate, 'endDate');
                const teamId = this.$route.query.teamId;
                const viewId = this.$route.query.viewId;

                if (startDate.isValid()) {
                    this.setStartDate(startDate);
                }

                if (endDate.isValid()) {
                    this.setEndDate(endDate);
                }

                if (teamId) {
                    this.setContextTeamId(teamId);
                }

                if (viewId) {
                    useDashboardStore().viewId = viewId;
                }

                this.updateQuery();
            },

            resetStatsIfNeeded() {
                if (this.contextGroup.id != this.$stats.latestFetch.groupId) {
                    this.onGroupChanged();
                }

                if (this.contextAccount.id != this.$stats.latestFetch.accountId) {
                    this.onAccountChanged();
                }
            },
        },

        created() {
            this.debounceFilterStatLeads = debounce(this.debounceFilterStatLeads, 100);

            this.$stats.$on('filter-worker', this.onLeadsFiltered);

            this.$eventBus.$on('delete-leads', this.deleteLeads);
            this.$eventBus.$on('filtered-dates-changed', this.onFilteredDatesChanged);
            this.$eventBus.$on('filters-applied', this.onFiltersApplied);
            this.$eventBus.$on('stats-updated', this.debounceFilterStatLeads);
            this.$eventBus.$on('toggle-expanded', this.toggleExpanded);
            this.$eventBus.$on('toggle-option', this.toggleOption);
            this.$eventBus.$on('update-query', this.updateQuery);
            this.$eventBus.$on('fetch-stats', this.fetchStatLeads);

            this.setValuesFromUrl();
            this.setDashboardType();
            this.setStartContractDate();
            this.resetStatsIfNeeded();
        },

        beforeDestroy() {
            this.$stats.$off('filter-worker', this.onLeadsFiltered);

            this.$eventBus.$off('delete-leads', this.deleteLeads);
            this.$eventBus.$off('filtered-dates-changed', this.onFilteredDatesChanged);
            this.$eventBus.$off('filters-applied', this.onFiltersApplied);
            this.$eventBus.$off('stats-updated', this.debounceFilterStatLeads);
            this.$eventBus.$off('toggle-expanded', this.toggleExpanded);
            this.$eventBus.$off('toggle-option', this.toggleOption);
            this.$eventBus.$off('update-query', this.updateQuery);
            this.$eventBus.$off('fetch-stats', this.fetchStatLeads);
        },
    };
</script>
