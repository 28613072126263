import Enum from './Enum.js';

export default class GridReportView extends Enum {
    static get entries() {
        return {
            car: 'car',
            division: 'division',
            stock: 'stock',
        };
    }

    static get CAR() {
        return this.entries.car;
    }

    static get DIVISION() {
        return this.entries.division;
    }

    static get STOCK() {
        return this.entries.stock;
    }
}
