<template>
    <activix-button>
        <icon class="mr-3" :name="$icons.appointment" />
        <span>{{ formatedStartDate }} - {{ formatedEndDate }}</span>
        <icon class="text-grey-600 ml-3 text-xs" :name="$icons.expand" />
    </activix-button>
</template>

<script>
    import { mapState } from 'pinia';
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        props: {
            startDate: {
                type: String,
                default: '',
            },
            endDate: {
                type: String,
                default: '',
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),
            ...mapState(useDashboardStore, ['startContractDate']),

            formatedStartDate() {
                return as_locale(this.startDate, 'start_date').humanShort(true) || 'N/A';
            },

            formatedEndDate() {
                return as_locale(this.endDate, 'end_date').humanShort(true) || 'N/A';
            },

            showOlderThanOption() {
                return this.startContractDate && this.authUser.older_than;
            },
        },

        watch: {
            '$i18n.locale'() {
                this.setRangePicker();
            },

            startDate(newValue, oldValue) {
                if (newValue == oldValue) {
                    return;
                }

                const startDate = as_locale(newValue, 'start_date');
                const picker = $(this.$el).data('daterangepicker');

                if (picker && startDate.isValid()) {
                    picker.setStartDate(startDate.getObject());
                }
            },

            endDate(newValue, oldValue) {
                if (newValue == oldValue) {
                    return;
                }

                const endDate = as_locale(newValue, 'end_date');
                const picker = $(this.$el).data('daterangepicker');

                if (picker && endDate.isValid()) {
                    picker.setEndDate(endDate.getObject());
                }
            },

            startContractDate(newValue, oldValue) {
                if (newValue == oldValue) {
                    return;
                }

                this.setRangePicker();
            },

            authUser(newValue, oldValue) {
                if (newValue == oldValue) {
                    return;
                }

                this.setRangePicker();
            },

            contextAccount(newValue, oldValue) {
                if (newValue == oldValue) {
                    return;
                }

                this.setRangePicker();
            },
        },

        methods: {
            onChange(start, end) {
                this.$emit('update:startDate', locale_date(start).toString());
                this.$emit('update:endDate', locale_date(end).toString());
            },

            onShow(event, picker) {
                if (!this.mdLayout) {
                    picker.container[0].classList.add('opened');
                }

                if (picker.container[0].querySelectorAll('.calendar-wrapper').length) {
                    return;
                }

                this.configureCalendarDom(picker, 'end');
                this.configureCalendarDom(picker, 'start');
            },

            onHide(event, picker) {
                if (!this.mdLayout) {
                    picker.container[0].classList.remove('opened');
                }
            },

            configureCalendarDom(picker, type) {
                const title = document.createElement('h3');
                title.classList.add('calendar-title');
                title.append(this.$t(`datepicker.${type}`));

                const wrapper = document.createElement('div');
                wrapper.classList.add('calendar-wrapper');
                wrapper.append(title);

                const calendar = picker.container[0].querySelector(`.calendar.${type == 'start' ? 'first' : 'second'}`);
                wrapper.append(calendar);

                picker.container[0].prepend(wrapper);
            },

            setRangePicker() {
                const startContractDate = locale_date(this.startContractDate);
                const dateRanges = {};

                if (
                    !startContractDate.isValid() ||
                    now()
                        .subDays(30)
                        .isAfter(startContractDate)
                ) {
                    dateRanges[this.$t('datepicker.last30Days')] = [
                        now()
                            .subDays(30)
                            .getObject(),
                        now().getObject(),
                    ];
                }

                if (
                    !startContractDate.isValid() ||
                    now()
                        .subMonths(1)
                        .startOfMonth()
                        .isAfter(startContractDate)
                ) {
                    dateRanges[this.$t('datepicker.lastMonth')] = [
                        now()
                            .subMonths(1)
                            .startOfMonth()
                            .getObject(),
                        now()
                            .subMonths(1)
                            .endOfMonth()
                            .getObject(),
                    ];
                }

                if (
                    !startContractDate.isValid() ||
                    now()
                        .subDays(90)
                        .isAfter(startContractDate)
                ) {
                    dateRanges[this.$t('datepicker.last90Days')] = [
                        now()
                            .subDays(90)
                            .getObject(),
                        now().getObject(),
                    ];
                }

                if (
                    !startContractDate.isValid() ||
                    now()
                        .subMonths(6)
                        .startOfMonth()
                        .isAfter(startContractDate)
                ) {
                    dateRanges[this.$t('datepicker.last6Month')] = [
                        now()
                            .subMonths(6)
                            .startOfMonth()
                            .getObject(),
                        now()
                            .subMonths(1)
                            .endOfMonth()
                            .getObject(),
                    ];
                }

                if (
                    !startContractDate.isValid() ||
                    now()
                        .subMonths(6)
                        .startOfMonth()
                        .isAfter(startContractDate)
                ) {
                    dateRanges[this.$t('datepicker.last12Month')] = [
                        now()
                            .subMonths(12)
                            .startOfMonth()
                            .getObject(),
                        now()
                            .subMonths(1)
                            .endOfMonth()
                            .getObject(),
                    ];
                }

                if (
                    !startContractDate.isValid() ||
                    now()
                        .startOfYear()
                        .isAfter(startContractDate)
                ) {
                    dateRanges[this.$t('datepicker.thisYear')] = [
                        now()
                            .startOfYear()
                            .getObject(),
                        now().getObject(),
                    ];
                }

                if (startContractDate.isValid()) {
                    dateRanges[this.$t('datepicker.sinceBeginning')] = [
                        startContractDate.getObject(),
                        now().getObject(),
                    ];
                }

                if (this.showOlderThanOption) {
                    dateRanges[this.$t('datepicker.olderThan', { months: this.authUser.older_than })] = [
                        startContractDate.clone().getObject(),
                        now()
                            .subMonths(this.authUser.older_than)
                            .getObject(),
                    ];
                }

                // Setup daterangepicker
                const picker = $(this.$el).data('daterangepicker');
                const payload = {
                    showDropdowns: true,
                    format: 'YYYY-MM-DD',
                    startDate: as_locale(this.startDate, 'start_date').getObject(),
                    endDate: as_locale(this.endDate, 'end_date').getObject(),
                    ranges: dateRanges,
                    opens: 'left',
                    applyClass: 'btn-primary',
                    cancelClass: 'btn-default',
                    locale: this.$i18n.locale == 'en' ? datetimeLangEn : datetimeLangFr,
                    parentEl: this.$parent.$el.querySelector('.modal'),
                };

                if (!picker) {
                    $(this.$el)
                        .daterangepicker(payload, this.onChange)
                        .on('show.daterangepicker', this.onShow)
                        .on('hide.daterangepicker', this.onHide);
                } else {
                    picker.setOptions(payload, this.onChange);
                }
            },
        },

        async mounted() {
            await this.$nextTick();

            this.setRangePicker();
        },

        beforeDestroy() {
            $(this.$el).off('show.daterangepicker');
            $(this.$el)
                .data('daterangepicker')
                .remove();
        },
    };
</script>
