var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{ref:"table",staticClass:"w-full border-t-2",attrs:{"border":"","stripe":"","empty-text":_vm.isLoading ? _vm.$t('dashboards.statsTableLoading') : '',"data":_vm.tableData,"default-sort":_vm.sort,"show-summary":true,"summary-method":_vm.formatTotals},on:{"sort-change":_vm.sortChange}},[_c('el-table-column',{attrs:{"label-class-name":"sticky-column"}},[_c('el-table-column',{attrs:{"prop":"name","fixed":true,"header-align":"center","label":_vm.nameColumnText,"min-width":"200","sort-by":"name","sortable":true,"sort-method":_vm.sortNameColumn,"sort-orders":['descending', 'ascending'],"class-name":"sticky-column"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('name-cell',{attrs:{"row":row,"sub-level-key":_vm.filters.subLevelKey,"show-sub-level":_vm.showSubLevel},on:{"toggle-row":_vm.toggleRow}})]}}])})],1),_c('el-table-column',[_c('el-table-column',{attrs:{"prop":"total_leads","label":_vm.$tc('general.client', 0),"header-align":"center","align":"center","min-width":"95","sort-by":"total_leads","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('span',{class:{ 'font-semibold': row.expanded }},[_vm._v(" "+_vm._s(row[column.property])+" ")]),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"total_appointments","label":_vm.$tc('webBoost.appointment', 0),"header-align":"center","align":"center","min-width":"105","sort-by":"total_appointments","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('span',{class:{ 'font-semibold': row.expanded }},[_vm._v(" "+_vm._s(row[column.property])+" "+_vm._s(_vm.toPercent(row.total_appointments, row.total_leads))+" ")]),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey,"percentage":['total_appointments', 'total_leads']}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"total_phone_appointments","label":_vm.$tc('webBoost.appointment', 0),"header-align":"center","align":"center","min-width":"105","sort-by":"total_phone_appointments","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('span',{class:{ 'font-semibold': row.expanded }},[_vm._v(" "+_vm._s(row[column.property])+" "+_vm._s(_vm.toPercent(row.total_phone_appointments, row.total_leads))+" ")]),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey,"percentage":['total_phone_appointments', 'total_leads']}}):_vm._e()]}}])},[_c('template',{slot:"header"},[_c('span',{staticClass:"flex items-center justify-center"},[_vm._v(" "+_vm._s(_vm.$tc('general.appointment', 0))+" "),_c('icon',{staticClass:"ml-2",attrs:{"name":"regular/phone-landline"}})],1)])],2)],1),_c('el-table-column',{attrs:{"header-align":"center","label":_vm.$tc('leadXpress.presented', 0)}},[_c('el-table-column',{attrs:{"prop":"total_leads_presented_with_appointment","label":_vm.$t('dashboards.stats.headers.withAppointments'),"header-align":"center","align":"center","sort-by":"total_leads_presented_with_appointment","min-width":"95","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('span',{class:{ 'font-semibold': row.expanded }},[_vm._v(" "+_vm._s(row[column.property])+" "+_vm._s(_vm.toPercent(row.total_leads_presented_with_appointment, row.total_appointments))+" ")]),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey,"percentage":['total_leads_presented_with_appointment', 'total_appointments']}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"total_leads_presented_without_appointment","label":_vm.$t('dashboards.stats.headers.withoutAppointments'),"header-align":"center","align":"center","sort-by":"total_leads_presented_without_appointment","min-width":"95","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('span',{class:{ 'font-semibold': row.expanded }},[_vm._v(" "+_vm._s(row[column.property])+" "+_vm._s(_vm.toPercent(row.total_leads_presented_without_appointment, row.total_leads))+" ")]),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey,"percentage":['total_leads_presented_without_appointment', 'total_leads']}}):_vm._e()]}}])},[_c('template',{slot:"header"},[_vm._v(" "+_vm._s(_vm.$tc('leadXpress.visitWithoutAppointment', 0))+" "),_c('activix-tooltip',{attrs:{"placement":"top","content":_vm.$t('phoneUp.visitWithoutAppointmentTooltip')}},[_c('icon',{staticClass:"text-grey-500 text-sm ml-1",attrs:{"name":"regular/information-circle"}})],1)],1)],2)],1),_c('el-table-column',{attrs:{"header-align":"center","label":_vm.$t('dashboards.stats.headers.performances')}},[_c('el-table-column',{attrs:{"prop":"total_sales","label":_vm.$tc('allLead.sales', 0),"header-align":"center","align":"center","sort-by":"total_sales","min-width":"95","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('span',{class:{ 'font-semibold': row.expanded }},[_vm._v(" "+_vm._s(row[column.property])+" "+_vm._s(_vm.toPercent(row.total_sales, row.total_leads))+" ")]),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey,"percentage":['total_sales', 'total_leads']}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"total_lost","label":_vm.$tc('saleTable.cards.lost', 0),"header-align":"center","align":"center","sort-by":"total_lost","min-width":"95","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('span',{class:{ 'font-semibold': row.expanded }},[_vm._v(" "+_vm._s(row[column.property])+" "+_vm._s(_vm.toPercent(row.total_lost, row.total_leads))+" ")]),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey,"percentage":['total_lost', 'total_leads']}}):_vm._e()]}}])})],1),(_vm.showActivityColumns)?[_c('el-table-column',{attrs:{"header-align":"center","label":_vm.$t('dashboards.stats.headers.activities')}},[_c('el-table-column',{attrs:{"prop":"total_follow_ups","header-align":"center","align":"center","sort-by":"total_follow_ups","min-width":"95","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_vm._v(" "+_vm._s(row[column.property])+" "),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey,"empty-value":'N/A'}}):_vm._e()]}}],null,false,321593372)},[_c('template',{slot:"header"},[_vm._v(" "+_vm._s(_vm.$t('general.total'))+" "),_c('activix-tooltip',{attrs:{"placement":"top","content":_vm.$t('allLead.allFollowedTooltip')}},[_c('icon',{staticClass:"text-grey-500 text-sm ml-1",attrs:{"name":"regular/information-circle"}})],1)],1)],2),_c('el-table-column',{attrs:{"prop":"total_activities","header-align":"center","align":"center","sort-by":"total_activities","min-width":"95","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_vm._v(" "+_vm._s(row[column.property])+" "),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey,"empty-value":'N/A'}}):_vm._e()]}}],null,false,321593372)},[_c('template',{slot:"header"},[_vm._v(" "+_vm._s(_vm.$tc('general.date', 1))+" "),_c('activix-tooltip',{attrs:{"placement":"top","content":_vm.$t('allLead.allFollowedTooltipDates')}},[_c('icon',{staticClass:"text-grey-500 text-sm ml-1",attrs:{"name":"regular/information-circle"}})],1)],1)],2)],1),_c('el-table-column',{attrs:{"header-align":"center","label":_vm.$t('dashboards.stats.headers.averages')}},[_c('el-table-column',{attrs:{"prop":"follow_up_ratio","header-align":"center","align":"center","sort-by":"follow_up_ratio","min-width":"95","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_vm._v(" "+_vm._s(row[column.property].toFixed(1))+" "),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey,"empty-value":'N/A'}}):_vm._e()]}}],null,false,1203750671)},[_c('template',{slot:"header"},[_vm._v(" "+_vm._s(_vm.$t('allLead.averageFollowed'))+" "),_c('activix-tooltip',{attrs:{"placement":"top","content":_vm.$t('allLead.averageFollowedTooltip')}},[_c('icon',{staticClass:"text-grey-500 text-sm ml-1",attrs:{"name":"regular/information-circle"}})],1)],1)],2),_c('el-table-column',{attrs:{"prop":"contact_ratio","header-align":"center","align":"center","sort-by":"contact_ratio","min-width":"95","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_vm._v(" "+_vm._s(row[column.property].toFixed(1))+" "),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey,"empty-value":'N/A'}}):_vm._e()]}}],null,false,1203750671)},[_c('template',{slot:"header"},[_vm._v(" "+_vm._s(_vm.$t('allLead.averageContact'))+" "),_c('activix-tooltip',{attrs:{"placement":"top","content":_vm.$t('allLead.averageContactTooltip')}},[_c('icon',{staticClass:"text-grey-500 text-sm ml-1",attrs:{"name":"regular/information-circle"}})],1)],1)],2)],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }