<template>
    <div>
        <span class="font-bold">{{ takeOverPercent }}</span>
        {{ $t('walkIn.ofTakeOver') }}
    </div>
</template>

<script>
    import { toPercentString } from '../../../utils/numbers.js';

    export default {
        props: {
            cards: {
                type: Object,
                default: () => {},
            },
        },
        computed: {
            takeOverPercent() {
                return toPercentString(this.cards.takeOver.number.count, this.cards.walkIn.number.count);
            },
        },
    };
</script>
