<template>
    <div class="input-wrapper">
        <activix-checkbox
            color="blue"
            :disabled="disabled"
            :value="checked"
            @click.native.prevent="onClick"
        />
    </div>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { useGlobalStore } from '../../../store/store.js';

    export default {
        props: {
            lead: {
                type: Object,
                required: true,
            },
            inClientCard: {
                type: Boolean,
                default: false,
            },
            customField: {
                type: Object,
                default: null,
                required: true,
            },
        },

        data() {
            return {
                checked: false,
                update: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['guest']),

            disabled() {
                return this.guest;
            },
        },

        watch: {
            'lead.custom_fields': {
                immediate: true,
                handler() {
                    this.updateChecked();
                },
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction']),

            updateChecked() {
                if (this.update) {
                    this.update = false;
                    return;
                }

                const customField = this.lead.custom_fields.find(
                    customField => customField.id == this.customField.id,
                );

                this.checked = !!customField && !!customField.pivot.value[0];
            },

            onClick() {
                this.update = true;
                this.checked = !this.checked;
                this.updateLeadAction(
                    this.lead.id,
                    {},
                    {
                        customField: {
                            id: this.customField.id,
                            label: this.customField.label,
                            field: this.customField.field,
                            value: this.checked,
                        },
                    },
                );
            },
        },
    };
</script>
