<template>
    <div class="overflow-hidden cursor-normal">
        <span v-if="!user">-</span>
        <activix-tooltip :content="advisorTooltip">
            <div class="truncate">
                <div class="truncate" :class="{ 'opacity-50': secondUser }">
                    {{ getName(user) }}
                </div>
            </div>
        </activix-tooltip>
    </div>
</template>

<script>
    /* eslint-disable vue/require-prop-types */
    import { mapState } from 'pinia';
    import { getFullName } from '../../../utils/index.js';

    // Pinia
    import { useDashboardStore } from '../../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../../store/modules/context/store.js';
    import { useGlobalStore } from '../../../store/store.js';

    export default {
        props: ['lead', 'light'],

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'configs']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),
            ...mapState(useDashboardStore, ['dashboardType']),

            advisorTooltip() {
                if (this.secondUser) {
                    return `
                        ${this.$t('clientCard.assignation.transferAssignedTooltip', [this.getName(this.user)])}
                        <br>
                        ${this.$t('clientCard.assignation.transferWaitingTooltip', [this.getName(this.secondUser)])}
                    `;
                }

                return this.user ? this.getName(this.user) : '';
            },

            user() {
                return this.lead.user_id ? this.lead.user : null;
            },

            secondUser() {
                return this.lead.second_user_id ? this.lead.second_user : null;
            },

        },

        methods: {
            getName(user) {
                return user ? getFullName(user) : '';
            },
        },
    };
</script>
