import SyncScroll from '@/plugins/sync-scroll.js';

class StickyScroll {
    constructor({ element, className = '', offset = 0 }) {
        this.element = element;
        this.className = className;
        this.offset = offset;
        this.init();
    }

    setScrollbarWidth() {
        const scrollDiv = document.createElement('div');
        scrollDiv.style.width = '100px';
        scrollDiv.style.height = '100px';
        scrollDiv.style.overflow = 'scroll';
        scrollDiv.style.position = 'absolute';
        scrollDiv.style.top = '-9999px';

        document.body.appendChild(scrollDiv);
        this.scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
        document.body.removeChild(scrollDiv);
    }

    init() {
        if (this.initiated) {
            return;
        }

        this.setScrollbarWidth();
        this.initScrollElement();
        this.initResizeObserver();
        this.initSyncScroll();

        this.initiated = true;
    }

    initScrollElement() {
        this.scrollElement = document.createElement('div');
        this.scrollElement.style.height = '1px';
        this.scrollElement.style.overflow = 'hidden';
        this.scrollElement.style.width = `${this.element.scrollWidth}px`;

        this.scrollContainer = document.createElement('div');
        this.scrollContainer.classList = this.className;
        this.scrollContainer.style.position = 'sticky';
        this.scrollContainer.style.overflow = 'auto';
        this.scrollContainer.style.bottom = `${this.offset}px`;
        this.scrollContainer.appendChild(this.scrollElement);

        if (this.scrollbarWidth === 0) {
            this.scrollContainer.style.height = `${this.scrollbarWidth}px`;
            this.scrollContainer.style.marginTop = `-${this.scrollbarWidth}px`;
        } else {
            this.scrollContainer.style.height = 'auto';
            this.scrollContainer.style.marginTop = '0px';
        }

        this.element.dataset.scroll = 'hidden';
        this.element.parentNode.appendChild(this.scrollContainer);
    }

    initResizeObserver() {
        this.resizeObserver = new ResizeObserver(() => this.update());
        this.resizeObserver.observe(this.element);
    }

    initSyncScroll() {
        this.syncScroll = new SyncScroll([
            this.element,
            this.scrollContainer,
        ]);
    }

    update() {
        this.scrollElement.style.width = `${this.element.scrollWidth}px`;
    }

    destroy() {
        this.scrollContainer.remove();
        this.scrollContainer = null;

        this.resizeObserver.disconnect();
        this.resizeObserver = null;

        this.syncScroll.destroy();
        this.syncScroll = null;
    }
}

export default StickyScroll;
