import { mapState } from 'pinia';
import { useContextStore } from '../store/modules/context/store.js';

export default {
    computed: {
        ...mapState(useContextStore, {
            contextAccount: 'account',
        }),
    },
    methods: {
        getCustomFields() {
            const customFieldsObject = {};

            this.contextAccount.getAllCustomFields().forEach(customField => {
                customFieldsObject[`customId_${customField.id}`] = {
                    visible: false,
                };
            });

            return customFieldsObject;
        },
    },
};
