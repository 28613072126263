// Utils
import { cloneDeep } from 'lodash-es';
import { mapState } from 'pinia';
import { merge } from '../utils/index.js';

// Pinia
import { useContextStore } from '../store/modules/context/store.js';
import { useGlobalStore } from '../store/store.js';

export default {
    data() {
        return {
            cacheVersion: '4', // You can ovewrite this in a dashboard to bust the cache
        };
    },
    computed: {
        ...mapState(useGlobalStore, ['authUser']),
        ...mapState(useContextStore, {
            contextAccount: 'account',
            contextGroup: 'group',
        }),
    },

    methods: {
        setCaching(type, value) {
            const key = this.getCachingKey(type);
            const cache = {
                authUserId: this.authUser.id,
                version: this.cacheVersion,
                [type]: cloneDeep(value),
            };

            localStorage.setItem(key, JSON.stringify(cache));
        },

        getCaching(type) {
            this.clearOldCaching();

            const key = this.getCachingKey(type);
            const cache = localStorage.getItem(key);

            if (!cache) {
                return null;
            }

            const parsedCache = JSON.parse(cache);

            if (!this.cacheIsValid(parsedCache)) {
                localStorage.removeItem(key);
                return null;
            }

            return parsedCache[type];
        },

        getCachingKey(type) {
            let key = `dashboardCaching:${type}:${this.dashboard}`;

            if (this.contextAccount.id) {
                key += ':account';
            } else if (this.contextGroup.id) {
                key += ':group';
            } else {
                key += ':user';
            }

            return key;
        },

        cacheIsValid(cache) {
            return cache.authUserId == this.authUser.id && cache.version == this.cacheVersion;
        },

        clearOldCaching() {
            Object.keys(localStorage).forEach(key => {
                if (
                    key.includes('DataTables') ||
                    key.includes('accountCache') ||
                    key.includes('userCache') ||
                    key.includes('groupCache') ||
                    key.includes(`dashboardCaching:${this.dashboard}`)
                ) {
                    localStorage.removeItem(key);
                }
            });
        },

        setCardsFromCaching() {
            const caching = this.getCaching('cards');

            if (!caching || !this.cards) {
                return;
            }

            const defaultCards = cloneDeep(this.cards);
            merge(defaultCards, caching);
            this.cards = defaultCards;
        },
    },

    created() {
        this.setCardsFromCaching();
    },
};
