<template>
    <div class="w-full" v-if="hasNotifications()">
        <div class="flex-fill">
            <activix-tooltip :content="$t('dashboards.alertTypes.equity')">
                <span class="text-success">
                    {{ lastEquityNotificationDate() }}
                </span>
            </activix-tooltip>
        </div>
        <div class="flex-fill">
            <activix-tooltip :content="$t('dashboards.alertTypes.location')">
                <span class="text-warning">
                    {{ lastLocationNotificationDate() }}
                </span>
            </activix-tooltip>
        </div>
        <div class="flex-fill">
            <activix-tooltip :content="$t('dashboards.alertTypes.warranty')">
                <span class="text-primary">
                    {{ lastWarrantyNotificationDate() }}
                </span>
            </activix-tooltip>
        </div>
    </div>
    <div class="w-full" v-else>
        <span>-</span>
    </div>
</template>

<script>
    // Utils
    import { orderBy } from 'lodash-es';
    import { mapState } from 'pinia';
    import { useGlobalStore } from '../../../store/store.js';

    export default {
        props: {
            lead: {
                type: Object,
                required: true,
            },
        },
        computed: {
            ...mapState(useGlobalStore, ['authUser', 'guest']),
        },
        methods: {
            lastEquityNotificationDate() {
                if (!this.equityNotifications().length) {
                    return null;
                }

                return this.equityNotifications()[0]['executed_date:localized'][this.$i18n.locale];
            },

            lastLocationNotificationDate() {
                if (!this.locationNotifications().length) {
                    return null;
                }

                return this.locationNotifications()[0]['executed_date:localized'][this.$i18n.locale];
            },

            lastWarrantyNotificationDate() {
                if (!this.warrantyNotifications().length) {
                    return null;
                }

                return this.warrantyNotifications()[0]['executed_date:localized'][this.$i18n.locale];
            },

            equityNotifications() {
                const notifications = this.lead.renewal_alert_notifications.filter(notification => {
                    return notification.notification == 'renewalAlertEquity';
                });

                return orderBy(notifications, ['executed_date'], ['desc']);
            },

            locationNotifications() {
                return this.lead.renewal_alert_notifications.filter(notification => {
                    return notification.notification == 'renewalAlertEndLocation';
                });
            },

            warrantyNotifications() {
                return this.lead.renewal_alert_notifications.filter(notification => {
                    return notification.notification == 'renewalAlertEndWarranty';
                });
            },

            hasNotifications() {
                return (
                    !!this.lastEquityNotificationDate() ||
                    !!this.lastLocationNotificationDate() ||
                    !!this.lastWarrantyNotificationDate()
                );
            },
        },
    };
</script>
