<template>
    <div>
        <portal to="content-header-prepend">
            <div class="flex items-center ml-1 mb-3" v-if="contextUser.id && mdLayout">
                <content-header-sub class="ml-0" v-if="showUserName">
                    {{ contextUser.fullName }}
                </content-header-sub>

                <activix-tooltip :content="$t('general.backToSummary')" v-if="!authUser.hasSimpleAccess()">
                    <icon
                        class="link-grey | text-lg ml-2"
                        name="regular/navigation-left"
                        @click="backToSummary(contextAccount.id)"
                    />
                </activix-tooltip>
            </div>
        </portal>

        <portal to="content-header">
            <span class="max-w-full" :class="{ 'pl-3': lgLayout }" data-intercom-target="dashboard.viewSelector">
                <dashboard-view-selector
                    :views="dashboardViews"
                    :current-view="dashboardView"
                    :selected-view="selectedDashboardView"
                    @update-view="onUpdateView"
                    @delete-view="onDeleteView"
                    v-if="dashboardViews.length"
                />
                <h1 class="m-0 text-2xl font-semibold text-grey-650" v-text="pageTitle" v-else />
            </span>
        </portal>

        <portal to="content-header-left">
            <!-- Current user name -->
            <div class="flex items-center" v-if="contextUser.id && !mdLayout">
                <content-header-sub v-if="showUserName">
                    {{ contextUser.fullName }}
                </content-header-sub>

                <activix-tooltip :content="$t('general.backToSummary')" v-if="!authUser.hasSimpleAccess()">
                    <icon
                        class="link-grey | text-lg ml-2 flex-shrink-0"
                        name="regular/navigation-left"
                        @click="backToSummary(contextAccount.id)"
                    />
                </activix-tooltip>
            </div>
        </portal>

        <portal to="content-header-right" v-if="!dashboardDisabledForGroups">
            <div class="flex items-center h-full" :class="{ 'mr-3 text-xl': lgLayout }">
                <div class="flex items-center">
                    <!-- Loading state -->
                    <span class="text-grey-500 ml-4 w-7" v-show="showPercentage"> {{ loadingPercent }}%</span>

                    <!-- Reload -->
                    <dashboard-action-icon
                        class="w-7"
                        :icon="$icons.loading"
                        :spinning="isLoading"
                        :disabled="isLoading"
                        :tooltip="$t('dashboards.refresh')"
                        @click="$emit('trigger-fetch')"
                        v-if="showReload && (!mdLayout || !showPercentage)"
                    />

                    <!-- Stats by result toggle -->
                    <dashboard-action-icon
                        icon="regular/check-2"
                        :tooltip="resultStatsTooltip"
                        :active="options.resultStats"
                        @click="$eventBus.$emit('toggle-option', 'resultStats')"
                        v-if="showStatsByResult"
                    />

                    <!-- Stats by BDC toggle -->
                    <dashboard-action-icon
                        :icon="$icons.bdc"
                        :tooltip="statsByBdcTooltip"
                        :active="options.showAgent"
                        @click="$eventBus.$emit('toggle-option', 'showAgent')"
                        v-if="showStatsByBdc"
                    />

                    <!-- Calculate Be Back toggle -->
                    <dashboard-action-icon
                        :icon="$icons.beBack"
                        :tooltip="doubleWalkInTooltip"
                        :active="options.doubleWalkIn"
                        @click="$eventBus.$emit('toggle-option', 'doubleWalkIn')"
                        v-if="showDoubleWalkIn"
                    />

                    <!-- Show no modality leads toggle -->
                    <dashboard-action-icon
                        icon="regular/accounting-bill-stack-1"
                        :tooltip="noModalityTooltip"
                        :active="options.endContractStats"
                        @click="$eventBus.$emit('toggle-option', 'endContractStats')"
                        v-if="showNoModality"
                    />

                    <!-- Show Stats -->
                    <dashboard-action-icon
                        :icon="options.showStats ? $icons.collapse : $icons.expand"
                        :tooltip="toggleStatsTooltip"
                        @click="$eventBus.$emit('toggle-option', 'showStats')"
                        v-if="showToggleStats"
                    />
                </div>

                <activix-tooltip :content="$t('dashboards.dateMultiselectTooltip')" v-if="showDateFilter">
                    <date-filter :new-dashboards="newDashboards" class="ml-4 lg:ml-3" />
                </activix-tooltip>

                <lead-type-filter class="ml-4 lg:ml-3" v-if="showLeadTypeFilter" />

                <event-filter class="ml-4 lg:ml-3" v-if="showEventFilter" />

                <date-picker class="ml-4 lg:ml-3" v-if="!xsLayout && showDatePicker" />
            </div>
        </portal>

        <div class="flex items-center mx-3 mb-3" v-if="xsLayout">
            <date-picker class="w-full" v-if="showDatePicker" />
        </div>

        <activix-confirm-modal
            type="error"
            :content="$t('dashboards.deleteViewConfirmation')"
            :opened.sync="modals.deleteViewOpened"
            @approve="triggerDeleteView"
            @closed="emptySelectedView"
        />

        <dashboard-view-modal
            :edit-mode="true"
            :opened.sync="modals.viewOpened"
            :dashboard-view="selectedDashboardView"
            @closed="emptySelectedView"
        />
    </div>
</template>

<script>
    import { mapActions, mapState } from 'pinia';

    import DashboardActionIcon from './DashboardActionIcon.vue';
    import DashboardViewModal from '../modals/DashboardView.vue';
    import DateFilter from './DateFilter.vue';
    import DatePicker from './DatePicker.vue';
    import EventFilter from './EventFilter.vue';
    import LeadTypeFilter from './LeadTypeFilter.vue';
    import ContentHeaderSub from '../ContentHeaderSub.vue';

    import DashboardType from '../../entities/DashboardType.js';
    import DashboardView from '../../entities/DashboardView.js';
    import DashboardViewSelector from '../DashboardViewSelector.vue';

    // Pinia
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            DashboardActionIcon,
            DashboardViewModal,
            DashboardViewSelector,
            DateFilter,
            DatePicker,
            EventFilter,
            LeadTypeFilter,
            ContentHeaderSub,
        },

        props: {
            currentCount: {
                type: Number,
                default: -1,
            },
            totalCount: {
                type: Number,
                default: -1,
            },
            newDashboards: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                selectedDashboardView: new DashboardView(),
                modals: {
                    deleteViewOpened: false,
                    viewOpened: false,
                    reportBuilderOpened: false,
                },
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'pageTitle', 'parentAuthUser']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
                contextUser: 'user',
            }),
            ...mapState(useDashboardStore, {
                dashboardDisabledForGroups: 'dashboardDisabledForGroups',
                dashboardView: 'dashboardView',
                dashboardType: 'dashboardType',
                options: store => store.configs.options,
                viewId: 'viewId',
            }),

            isLoading() {
                if (this.newDashboards) {
                    return this.$wait.is('fetching.cards') && !this.$wait.is('changingDashboard');
                }

                return (
                    this.$wait.is('fetching.stats') ||
                    !this.$stats.loaded[this.dashboardType] ||
                    !this.$stats.loaded[this.dashboardType].cardStats
                );
            },

            showUserName() {
                return (
                    !this.contextUser.isEmpty() &&
                    ![this.contextUser.id, this.contextUser.parent_user_id].includes(this.authUser.id)
                );
            },

            showLoading() {
                return this.isLoading && (this.options.showStats || this.dashboardType == DashboardType.ACTIVITY);
            },

            showPercentage() {
                if (this.newDashboards) {
                    return false;
                }

                return this.currentCount >= 0 && this.loadingPercent < 100 && this.$wait.is('fetching.stats');
            },

            showReload() {
                return this.options.showStats || this.dashboardType == DashboardType.ACTIVITY;
            },

            showToggleStats() {
                return this.dashboardType != DashboardType.ACTIVITY;
            },

            showStatsByResult() {
                return [
                    DashboardType.ALL,
                    DashboardType.LOYALTY,
                    DashboardType.SERVICE,
                    DashboardType.LEAD_XPRESS,
                    DashboardType.WEB_ORDER,
                    DashboardType.PHONE_UP,
                ].includes(this.dashboardType);
            },

            showStatsByBdc() {
                return this.dashboardType == DashboardType.ACTIVITY && !this.contextUser.id;
            },

            showDoubleWalkIn() {
                return this.dashboardType == DashboardType.WALK_IN;
            },

            showNoModality() {
                return this.dashboardType == DashboardType.RENEWAL;
            },

            showDateFilter() {
                return (
                    ![DashboardType.ACTIVITY, DashboardType.EVENT, DashboardType.SALE, DashboardType.WALK_IN].includes(
                        this.dashboardType,
                    ) &&
                    (!this.options.resultStats ||
                        [DashboardType.COMMERCIAL, DashboardType.RENEWAL, DashboardType.WEB_BOOST].includes(
                            this.dashboardType,
                        ))
                );
            },

            showLeadTypeFilter() {
                return this.dashboardType == DashboardType.ACTIVITY;
            },

            showEventFilter() {
                return this.dashboardType == DashboardType.EVENT;
            },

            showDatePicker() {
                return !!this.contextAccount && !!this.authUser;
            },

            loadingPercent() {
                if (this.totalCount <= 0) {
                    return 0;
                }

                let percent = Math.floor((this.currentCount / this.totalCount) * 100);

                // Prevent showing 100%
                if (percent == 100) {
                    percent = 99;
                }

                return percent;
            },

            toggleStatsTooltip() {
                return this.options.showStats ? this.$t('option.disableShowStats') : this.$t('option.enableShowStats');
            },

            resultStatsTooltip() {
                if (this.options.resultStats) {
                    return this.$t('option.disableResultStat');
                }

                if (this.dashboardType == DashboardType.ALL) {
                    return this.$t('allLead.options.enableResultStat');
                }

                return this.$t('option.enableResultStat');
            },

            statsByBdcTooltip() {
                return this.options.showAgent ? this.$t('option.showAdvisor') : this.$t('option.showAgent');
            },

            doubleWalkInTooltip() {
                return this.options.doubleWalkIn
                    ? this.$t('option.disableDoubleWalkIn')
                    : this.$t('option.enableDoubleWalkIn');
            },

            noModalityTooltip() {
                return this.options.endContractStats
                    ? this.$t('option.hideNoModality')
                    : this.$t('option.showNoModality');
            },

            dashboardViews() {
                return this.parentAuthUser.dashboard_views[this.dashboardType] || [];
            },
        },

        methods: {
            ...mapActions(useContextStore, ['setContextAccountAction', 'setContextUserAction']),

            backToSummary(accountId) {
                useDashboardStore().disableStatFetch = true;
                this.setContextUserAction();
                this.setContextAccountAction(accountId);
                this.$eventBus.$emit('fetch-table-leads');
            },

            onUpdateView(view) {
                this.selectedDashboardView = new DashboardView(view);
                this.modals.viewOpened = true;
            },

            onDeleteView(view) {
                this.selectedDashboardView = new DashboardView(view);
                this.modals.deleteViewOpened = true;
            },

            emptySelectedView() {
                this.selectedDashboardView = new DashboardView();
            },

            async triggerDeleteView() {
                const viewId = this.selectedDashboardView.id;

                try {
                    await this.$axios.delete(`v1/dashboard-views/${viewId}`);

                    this.authUser.deleteDashboardView(viewId);

                    if (viewId == this.dashboardView.id) {
                        this.$router.push({
                            name: this.$route.name,
                            query: {
                                viewId: null,
                            },
                        });
                    }
                } catch (error) {
                    this.$notify.error(this.$t('dashboardViews.alerts.destroy.error'));

                    throw error;
                }
            },
        },
    };
</script>
