var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row | flex flex-wrap -mb-6"},_vm._l((_vm.tables),function(table,name){return _c('div',{key:name,staticClass:"col-xs-12 col-lg-6 col-xl-4 | pb-8"},[_c('div',{staticClass:"box | h-full mb-0",class:{ loading: _vm.loading }},[_c('div',{staticClass:"box-header text-center"},[_c('h4',{staticClass:"box-title"},[_vm._v(" "+_vm._s(_vm.$t(("activityReport.advancedStats.boxTitles." + name)))+" ")])]),_c('div',{staticClass:"box-body | p-0"},[_c('el-table',{staticClass:"w-full border-t-2 border-b",attrs:{"data":table}},[_c('el-table-column',{attrs:{"class-name":"font-normal","prop":"name","min-width":"128"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$tc(("activityReport.stats." + (row.name)), 2))+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"align":"center","prop":"total","min-width":"95","label":_vm.$t('activityReport.total')}}),_c('el-table-column',{attrs:{"align":"center","prop":"ratio","min-width":"95","label":_vm.$t('activityReport.ratio')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.valueIsSet(row.ratio) ? ((row.ratio) + "%") : '-')+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"align":"center","prop":"objective","min-width":"95","label":_vm.$t('activityReport.objective')},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('div',{staticClass:"flex items-center justify-center"},[_vm._v(" "+_vm._s(column.label)+" "),_c('activix-tooltip',{attrs:{"content":_vm.$t('activityReport.advancedStats.objectiveTooltip')}},[_c('icon',{staticClass:"text-grey-500 text-sm ml-1",attrs:{"name":"regular/information-circle"}})],1)],1)]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.valueIsSet(row.objective) ? row.objective : '-')+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"align":"center","prop":"variation","min-width":"95","label":_vm.$t('activityReport.variation')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('span',{class:{
                                'text-green-500': _vm.valueIsSet(row.variation) && row.variation > 0,
                                'text-red-500': _vm.valueIsSet(row.variation) && row.variation < 0,
                            }},[_vm._v(" "+_vm._s(_vm.computeVariationValue(row))+" ")])}}],null,true)})],1)],1)])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }