<template>
    <div class="relative mx-1 my-1">
        <activix-tooltip :content="$t('saleTable.yourObjective')" :disabled="!isObjective">
            <div
                class="flex items-center justify-center shadow"
                :class="[
                    carView ? 'h-7 w-10' : 'h-10 w-13',
                    isObjective ? 'bg-grey-300 text-grey-600 font-semibold' : 'bg-white text-grey-500',
                ]"
            >
                {{ currentPosition }}
            </div>
        </activix-tooltip>
    </div>
</template>

<script>
    export default {
        props: {
            carView: Boolean,
            remaining: Number,
            index: Number,
            leadCount: Number,
        },

        computed: {
            currentPosition() {
                return this.leadCount + this.index;
            },

            isObjective() {
                return this.index == this.remaining;
            },
        },
    };
</script>
