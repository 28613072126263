<template>
    <div>
        <slot name="before" />
        <div class="lead-table-wrapper | overflow-x-auto overflow-y-hidden">
            <template v-for="item in items">
                <slot :item="item" />
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            items: {
                type: Array,
                required: true,
            },
        },
    };
</script>
