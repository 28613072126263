<template>
    <div class="mb-6" v-on-clickaway="hideDescription">
        <div
            class="relative flex flex-col justify-center h-12 overflow-hidden rounded shadow-md transform transition-all duration-300"
            :class="{ 'cursor-pointer hover:shadow-lg hover:-translate-y-2px': hasDescription }"
            @click="toggleShowDescription"
        >
            <transition name="fade" mode="in-out">
                <div class="absolute inset-0 flex items-center bg-white" key="front" v-if="!showDescription">
                    <div class="flex items-center h-full px-4 bg-grey-300">
                        <div
                            class="text-2xl text-grey-700 text-center min-w-9 | lg:min-w-0 2xl:text-3xl"
                            v-text="number"
                        />
                    </div>

                    <activix-tooltip :content="title" :only-on-truncate="true">
                        <div class="flex-1 text-grey-700 text-xl truncate px-4" v-text="title" />
                    </activix-tooltip>

                    <slot name="switch" v-if="$slots.switch" />
                </div>

                <div class="absolute inset-0 flex items-center bg-white" key="back" v-else-if="hasDescription">
                    <div
                        class="flex items-center h-full px-4 bg-grey-300 text-2xl text-grey-700 | 2xl:text-3xl"
                        v-if="hasBackNumber || backIcon"
                    >
                        <div class="text-center min-w-9 | lg:min-w-0" v-text="backNumber" v-if="hasBackNumber" />
                        <icon :name="backIcon" v-else-if="backIcon" />
                    </div>

                    <div class="flex-1 text-grey-700 truncate px-4 text-xl">
                        <span v-text="backTitle" v-if="backTitle" />
                        <slot name="description" v-if="$slots.description" />
                    </div>

                    <el-popover placement="top" trigger="hover" v-if="$slots.popover">
                        <icon class="mr-4 text-grey-500 bg-white" name="regular/information-circle" slot="reference" />
                        <div class="p-4">
                            <slot name="popover" />
                        </div>
                    </el-popover>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            backIcon: {
                type: String,
                default: '',
            },
            backNumber: {
                type: [Number, String],
                default: null,
            },
            backTitle: {
                type: String,
                default: '',
            },
            number: {
                type: Number,
                required: true,
            },
            title: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                showDescription: false,
            };
        },

        computed: {
            hasDescription() {
                return this.hasBackNumber || !!this.backTitle || !!this.$slots.description;
            },

            hasBackNumber() {
                return this.backNumber !== null;
            },
        },

        methods: {
            toggleShowDescription() {
                if (!this.hasDescription) {
                    return;
                }

                this.showDescription = !this.showDescription;
            },

            hideDescription() {
                this.showDescription = false;
            },
        },
    };
</script>
