<template>
    <div :class="{ 'flex': dashboardDisabledForGroups }">
        <dashboard-header :new-dashboards="newDashboards" @trigger-fetch="fetchStats" />

        <warning
            :icon="$icons.warning"
            :title="$t('error.warning')"
            :content="$t('dashboards.disabledForGroups')"
            :show-home="true"
            :show-back="true"
            v-if="dashboardDisabledForGroups"
        />
        <template v-else>
            <keep-alive>
                <router-view class="stats" ref="dashboard" v-show="showStats" />
            </keep-alive>

            <lead-table-box />
        </template>
    </div>
</template>

<script>
    // Utils
    import { debounce } from 'lodash-es';
    import { mapActions, mapState } from 'pinia';
    import { orderByKey } from '../../utils/index.js';
    // Entities
    import DashboardType from '../../entities/DashboardType.js';
    // Components
    import DashboardHeader from '../../components/dashboards/DashboardHeader.vue';
    import LeadTableBox from '../../components/dashboards/LeadTableBox.vue';
    import Warning from '../../components/Warning.vue';
    // Mixins
    import DashboardColumns from '../../mixins/DashboardColumns.js';

    // Pinia
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        name: 'Dashboard',

        components: {
            DashboardHeader,
            LeadTableBox,
            Warning,
        },

        mixins: [DashboardColumns],

        data() {
            return {
                fetchRequested: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, {
                session: store => store.parentAuthUser.session,
                locale: store => store.configs.locale,
                parentAuthUser: 'parentAuthUser',
                authUser: 'authUser',
                groupDisplay: 'groupDisplay',
            }),
            ...mapState(useContextStore, {
                contextAccount: 'account',
                contextGroup: 'group',
                contextUser: 'user',
                contextTeamId: 'teamId',
            }),
            ...mapState(useDashboardStore, {
                dashboardDisabledForGroups: 'dashboardDisabledForGroups',
                division: 'division',
                dashboardView: 'dashboardView',
                bulkSelect: 'bulkSelect',
                dashboardConfigs: 'configs',
                dashboardType: 'dashboardType',
                disableStatFetch: 'disableStatFetch',
                endDate: 'endDate',
                filteredCampaigns: 'filteredCampaigns',
                filteredDates: 'filteredDates',
                options: store => store.configs.options,
                startDate: 'startDate',
                viewId: 'viewId',
                notificationDate: 'notificationDate',
            }),

            newDashboards() {
                return this.dashboardType !== DashboardType.ACTIVITY;
            },

            showStats() {
                return this.dashboardConfigs.options.showStats || this.dashboardType == DashboardType.ACTIVITY;
            },

            dashboardHasExternalFetch() {
                return [DashboardType.ACTIVITY, DashboardType.EVENT].includes(this.dashboardType);
            },

            dashboardHasResultStats() {
                return ![
                    DashboardType.COMMERCIAL,
                    DashboardType.RENEWAL,
                    DashboardType.SALE,
                    DashboardType.WEB_BOOST,
                    DashboardType.WALK_IN,
                ].includes(this.dashboardType);
            },
        },

        watch: {
            '$route.meta.dashboard': {
                immediate: true,
                handler(newValue) {
                    this.setDashboardType(newValue);
                },
            },

            '$route.query.startDate'(newStartDate) {
                if (!newStartDate) {
                    return;
                }

                this.setStartDate(newStartDate);
            },

            '$route.query.endDate'(newEndDate) {
                if (!newEndDate) {
                    return;
                }

                this.setEndDate(newEndDate);
            },

            '$route.query.viewId'(newViewId) {
                useDashboardStore().viewId = newViewId || null;
            },

            '$route.query.notificationDate'(newNotificationDate) {
                useDashboardStore().notificationDate = newNotificationDate || null;
            },

            '$route.query.notificationType'(newNotificationType) {
                if (!newNotificationType) {
                    return;
                }

                this.fetchViewByNotificationType(newNotificationType);
            },

            dashboardView() {
                this.setValuesFromSession();
            },

            'contextGroup.id'() {
                this.onGroupChanged();
            },

            'contextAccount.id'() {
                this.onAccountChanged();
            },

            'contextUser.id'() {
                this.onUserChanged();
            },

            locale() {
                this.setAllLeadsInTableSelected(false);
                this.fetchStats();
                this.$eventBus.$emit('fetch-table-leads', { page: 1 });
            },

            viewId(newValue) {
                if (newValue == 'null') {
                    useDashboardStore().viewId = null;
                    return;
                }

                if (!empty(newValue)) {
                    this.fetchView();
                    return;
                }

                useDashboardStore().selectedDashboardView = {};
            },

            notificationDate(newValue, oldValue) {
                if (newValue == oldValue) {
                    return;
                }

                this.addNotificationDateInDashboardFilters();
            },

            startDate(newValue, oldValue) {
                if (newValue == oldValue) {
                    return;
                }

                this.updateQuery();

                if (typeof oldValue === 'undefined') {
                    return;
                }

                this.setAllLeadsInTableSelected(false);
                this.fetchStats();
                this.$eventBus.$emit('fetch-table-leads', { page: 1 });
            },

            endDate(newValue, oldValue) {
                if (newValue == oldValue) {
                    return;
                }

                this.updateQuery();

                if (typeof oldValue === 'undefined') {
                    return;
                }

                this.setAllLeadsInTableSelected(false);
                this.fetchStats();
                this.$eventBus.$emit('fetch-table-leads', { page: 1 });
            },

            contextTeamId(newValue, oldValue) {
                this.updateQuery(true);

                if (typeof oldValue === 'undefined') {
                    return;
                }

                this.$eventBus.$emit('fetch-table-leads', { page: 1 });
                this.fetchStats();
            },

            bulkSelect(newValue) {
                if ((empty(newValue) || newValue.length != 2) && !empty(this.mergeLead)) {
                    this.setMergeLead = null;
                    return;
                }

                if (!empty(newValue) && newValue.length == 2) {
                    this.setContextLeadIdAction(newValue[0].id);
                    this.setMergedLead(newValue[1]);
                }
            },

            filteredCampaigns() {
                this.$nextTick(() => {
                    this.fetchStats();
                    this.$eventBus.$emit('fetch-table-leads', { page: 1 });
                });
            },
        },

        methods: {
            ...mapActions(useDashboardStore, [
                'setAllLeadsInTableSelected',
                'setStartDate',
                'setEndDate',
                'setStartContractDateStore',
                'setDashboardConfigs',
                'appendRenewalAlertDateInFilters',
            ]),
            ...mapActions(useGlobalStore, ['updateSessionAction', 'deleteLead', 'setMergedLead']),
            ...mapActions(useContextStore, ['setContextLeadIdAction', 'setContextUserAction', 'setContextTeamId']),

            triggerFetch({ passive = false, immediate = false, ...options } = {}) {
                this.fetchRequested = true;

                if (passive) {
                    this.passiveFetchStats(options);
                } else if (!immediate) {
                    this.debounceFetchStats(options);
                } else {
                    this.fetchStats(options);
                }
            },

            onDebounceFetchStats(options) {
                if (this.fetchRequested) {
                    this.fetchStats(options);
                }
            },

            async fetchStats(options) {
                this.fetchRequested = false;

                if (
                    !this.newDashboards ||
                    !this.showStats ||
                    !this.dashboardType ||
                    !this.endDate ||
                    !this.startDate ||
                    this.dashboardDisabledForGroups
                ) {
                    this.$wait.end('changingDashboard');
                    return;
                }

                await Promise.all([this.fetchCards(options), this.fetchDashboardStats(options)]);

                this.$wait.end('changingDashboard');
            },

            async fetchCards({ background = false } = {}) {
                if (!background) {
                    this.$wait.start('fetching.cards');
                }

                try {
                    const response = await this.$api.dashboard.getCards({
                        dashboard: this.dashboardType,
                        endDate: this.parsedEndDate.toDateTimeString(),
                        startDate: this.parsedStartDate.toDateTimeString(),
                        userId: this.contextUser.id,
                        groupId: this.contextGroup.id,
                        accountId: this.contextAccount.id,
                        filteredDates: this.filteredDates,
                        filteredCampaigns: this.filteredCampaigns,
                        options: this.dashboardConfigs.options,
                        divisions: this.division,
                        filters: this.activeDashboardFilters,
                        teamId: this.contextTeamId,
                    });

                    if (response && this.$refs.dashboard) {
                        this.$refs.dashboard.setCards(response.data);
                        this.$wait.end('fetching.cards');
                    }
                } catch (error) {
                    this.$notify.warning(this.$t('dashboards.alerts.stats.error'));
                    this.$wait.end('fetching.cards');
                }
            },

            async fetchDashboardStats(options) {
                if (!this.$refs.dashboard || !this.$refs.dashboard.fetchStats) {
                    return;
                }

                await this.$refs.dashboard.fetchStats(options);
            },

            onGroupChanged() {
                useDashboardStore().filteredCampaigns = [];

                this.setAllLeadsInTableSelected(false);

                this.updateQuery();
                this.fetchStats();
                this.$eventBus.$emit('fetch-table-leads', { page: 1 });
            },

            async onAccountChanged() {
                if (!this.contextAccount.id) {
                    return;
                }

                this.setAllLeadsInTableSelected(false);

                this.setStartContractDate();
                this.updateQuery(true);

                this.$nextTick(() => {
                    this.getDashboardColumns();

                    if (this.dashboardType != DashboardType.EVENT || empty(this.filteredCampaigns)) {
                        this.$eventBus.$emit('fetch-table-leads', { page: 1 });
                    }

                    this.fetchStats();
                });
            },

            addNotificationDateInDashboardFilters() {
                if (!this.viewId) {
                    return;
                }

                if (!this.notificationDate) {
                    return;
                }

                this.appendRenewalAlertDateInFilters(this.notificationDate);
            },

            onUserChanged() {
                this.setAllLeadsInTableSelected(false);

                this.updateQuery(true);
                this.$eventBus.$emit('fetch-table-leads', { page: 1 });
                this.fetchStats();
            },

            getDashboardColumns() {
                if (this.$refs.dashboard && this.$refs.dashboard.setColumns) {
                    this.$refs.dashboard.setColumns();
                }
            },

            setStartContractDate() {
                const accountActivationDate = as_locale(this.contextAccount.activation_date, 'activation_date');

                if (accountActivationDate.isValid()) {
                    this.setStartContractDateStore(accountActivationDate.toString());
                }
            },

            toggleOption(option, value) {
                const isActive = typeof value === 'undefined' ? !this.dashboardConfigs.options[option] : value;
                const updatedOptions = {
                    [option]: isActive,
                };

                this.setDashboardConfigs({
                    options: updatedOptions,
                });

                this.updateSessionAction('dashboard_option', updatedOptions, false);

                switch (option) {
                    case 'phoneAppointment':
                    case 'showStats':
                    case 'carryOver':
                    case 'doubleWalkIn':
                        this.fetchStats();
                        break;

                    case 'endContractStats':
                    case 'showAllLeads':
                    case 'recordedDate':
                    case 'lostDate':
                        this.$eventBus.$emit('fetch-table-leads', { page: 1 });
                        this.fetchStats();
                        break;

                    case 'resultStats':
                        this.updateDashboardDates();
                        this.$eventBus.$emit('fetch-table-leads', { page: 1 });
                        this.fetchStats();
                        break;

                    case 'showAgent':
                        break;
                }
            },

            toggleExpanded(box) {
                const isExpanded = !this.dashboardConfigs.expanded[box];
                const updatedExpanded = {
                    [box]: isExpanded,
                };

                this.setDashboardConfigs({
                    expanded: updatedExpanded,
                });

                this.updateSessionAction('dashboard_expand', updatedExpanded, false);
                this.$behavior.track(box, { action: (isExpanded ? 'open' : 'close'), dashboard: this.dashboardType });
            },

            updateDashboardDates() {
                if (this.dashboardHasExternalFetch) {
                    return;
                }

                // Dashboard specific
                if (this.dashboardType == 'walkIn') {
                    useDashboardStore().filteredDates = ['presented_date', 'be_back_date'];
                    return;
                }

                if (this.dashboardType == 'saleTable') {
                    useDashboardStore().filteredDates = ['created_at'];
                    return;
                }

                // Based on result stats
                if (this.dashboardHasResultStats && this.dashboardConfigs.options.resultStats) {
                    if (this.dashboardType == 'allLead') {
                        useDashboardStore().filteredDates = [
                            'created_at',
                            'appointment_date',
                            'appt_call_date',
                            'presented_date',
                            'be_back_date',
                            'sale_date',
                            'delivered_date',
                            'end_contract_date',
                        ];
                    } else {
                        useDashboardStore().filteredDates = [
                            'created_at',
                            'appointment_date',
                            'appt_call_date',
                            'presented_date',
                            'be_back_date',
                            'sale_date',
                            'delivered_date',
                        ];
                    }

                    return;
                }

                let dates = this.dashboardConfigs.dates;

                if (empty(dates)) {
                    dates = ['created_at'];
                }
                useDashboardStore().filteredDates = dates;
            },

            // @TODO Refactor using `FiltersInUrl`
            async updateQuery(redirect = false) {
                if (
                    this.authUser.isAdvisor() ||
                    (this.authUser.isBdc() && !this.authUser.bdc_super_access && this.contextAccount.id)
                ) {
                    await this.setContextUserAction(this.authUser.id);
                }

                const query = {};

                if (!empty(this.startDate)) {
                    query.startDate = as_locale(this.startDate, 'startDate').toDateString();
                }

                if (!empty(this.$route.query.modal)) {
                    query.modal = this.$route.query.modal;
                }

                if (!empty(this.endDate)) {
                    query.endDate = as_locale(this.endDate, 'endDate').toDateString();
                }

                if (!empty(this.viewId)) {
                    query.viewId = this.viewId;
                }

                if (!empty(this.notificationDate)) {
                    query.notificationDate = this.notificationDate;
                }

                if (this.contextTeamId) {
                    query.teamId = this.contextTeamId;
                }

                if (this.contextUser.id) {
                    query.userId = this.contextUser.id;
                } else if (this.contextGroup.id) {
                    query.groupId = this.contextGroup.id;
                } else if (!this.authUser.hasAdvisorSkills() && this.contextAccount.id) {
                    query.accountId = this.contextAccount.id;
                }

                const newRoute = {
                    ...this.$route,
                    query: orderByKey(query),
                };

                if (redirect) {
                    this.$router.push(newRoute);
                } else {
                    this.$router.replace(newRoute);
                }
            },

            async fetchView() {
                if (!this.viewId) {
                    return;
                }

                try {
                    const response = await this.$axios.get(`v1/dashboard-views/${this.viewId}`);

                    useDashboardStore().selectedDashboardView = response.data.data;
                } catch (error) {
                    this.$notify.error(this.$t('dashboardViews.alerts.show.error'));

                    throw error;
                }
            },

            async fetchViewByNotificationType(notificationType) {
                try {
                    const response = await this.$axios.get(`v1/dashboard-views/notification-type/${notificationType}`);

                    useDashboardStore().selectedDashboardView = response.data.data;
                    useDashboardStore().viewId = response.data.data.id;
                    await this.updateQuery();
                } catch (error) {
                    this.$notify.error(this.$t('dashboardViews.alerts.show.error'));

                    throw error;
                }
            },

            deleteLeads(leads) {
                this.triggerFetch();

                leads.forEach(leadId => {
                    this.deleteLead(leadId);
                });
            },

            onFiltersApplied() {
                this.$eventBus.$emit('fetch-table-leads', { page: 1 });
                this.fetchStats();
            },

            onFilteredDatesChanged() {
                this.$eventBus.$emit('fetch-table-leads', { page: 1 });
                this.fetchStats();
            },

            setValuesFromSession() {
                // Dashboard dependant options
                let columnVisibilities = this.session.dashboard_column[this.dashboardType] || {};
                let dates = this.session.dashboard_dates[this.dashboardType] || [];
                let expanded = this.session.dashboard_expand[this.dashboardType] || {};
                let filters = this.session.dashboard_filter[this.dashboardType] || {};
                let order = this.session.order[this.dashboardType] || {};

                // Global options
                let options = this.session.dashboard_option || {};
                let hotSwapDate = this.session.hot_swap_date || null;

                if (this.dashboardType == DashboardType.RENEWAL) {
                    hotSwapDate = this.session.hot_swap_date_renewal || null;
                }

                if (!this.dashboardView.isEmpty()) {
                    expanded = this.dashboardView.expand;
                    filters = this.dashboardView.filter;
                    options = this.dashboardView.option;
                    columnVisibilities = this.dashboardView.column;
                    dates = this.dashboardView.dates;
                    order = this.dashboardView.order;
                    hotSwapDate = this.dashboardView.hot_swap_date;

                    if (this.dashboardView.start_date) {
                        hotSwapDate = null;

                        this.setStartDate(this.dashboardView.start_date);
                        this.setEndDate(this.dashboardView.end_date);
                    }

                    if (this.dashboardView.stats_table_filter?.selectedKey) {
                        this.$eventBus.$emit('set-stats-table-filter', this.dashboardView.stats_table_filter);
                    }
                }

                this.setDashboardConfigs({
                    columnVisibilities,
                    dates,
                    expanded,
                    options,
                    order,
                    hotSwapDate,
                    filters,
                });

                if (!this.$route.query.teamId) {
                    this.setContextTeamId(this.session.team_id);
                }

                this.$nextTick(() => {
                    this.updateDashboardDates();
                    this.$eventBus.$emit('fetch-table-leads', { page: 1 });
                    this.fetchStats();
                });
            },

            setValuesFromUrl() {
                const startDate = as_locale(this.$route.query.startDate, 'startDate');
                const endDate = as_locale(this.$route.query.endDate, 'endDate');
                const teamId = this.$route.query.teamId;
                const viewId = this.$route.query.viewId;
                const notificationDate = this.$route.query.notificationDate;
                const notificationType = this.$route.query.notificationType;

                if (startDate.isValid()) {
                    this.setStartDate(startDate);
                }

                if (endDate.isValid()) {
                    this.setEndDate(endDate);
                }

                if (teamId) {
                    this.setContextTeamId(teamId);
                }

                if (viewId) {
                    useDashboardStore().viewId = viewId;
                }

                if (notificationDate) {
                    useDashboardStore().notificationDate = notificationDate;
                }

                if (notificationType) {
                    this.fetchViewByNotificationType(notificationType);
                }

                this.updateQuery();
            },

            setDashboardType(newDashboard) {
                this.$wait.start('changingDashboard');
                useDashboardStore().dashboardType = newDashboard;

                this.$nextTick(() => {
                    this.setAllLeadsInTableSelected(false);
                    this.getDashboardColumns();
                    this.updateQuery();
                    this.setValuesFromSession();
                });
            },
        },

        created() {
            this.$eventBus.$on('delete-leads', this.deleteLeads);
            this.$eventBus.$on('filtered-dates-changed', this.onFilteredDatesChanged);
            this.$eventBus.$on('filters-applied', this.onFiltersApplied);
            this.$eventBus.$on('toggle-expanded', this.toggleExpanded);
            this.$eventBus.$on('toggle-option', this.toggleOption);
            this.$eventBus.$on('update-query', this.updateQuery);
            this.$eventBus.$on('fetch-stats', this.triggerFetch);

            // Used for passive reloading when updates are not made in the current browser instance.
            // Debounces for 30 seconds and for a maximum of 2 minutes.
            this.passiveFetchStats = debounce(this.onDebounceFetchStats, 1000 * 30, {
                maxWait: 1000 * 120,
            });

            // Used to allow Elasticsearch to update it's index.
            // Debounces for 2 seconds and for a maximum of 10 seconds.
            this.debounceFetchStats = debounce(this.onDebounceFetchStats, 1000 * 2, {
                maxWait: 1000 * 10,
            });

            this.setValuesFromUrl();
            this.setStartContractDate();
        },

        beforeDestroy() {
            this.passiveFetchStats.cancel();
            this.debounceFetchStats.cancel();

            this.$eventBus.$off('delete-leads', this.deleteLeads);
            this.$eventBus.$off('filtered-dates-changed', this.onFilteredDatesChanged);
            this.$eventBus.$off('filters-applied', this.onFiltersApplied);
            this.$eventBus.$off('toggle-expanded', this.toggleExpanded);
            this.$eventBus.$off('toggle-option', this.toggleOption);
            this.$eventBus.$off('update-query', this.updateQuery);
            this.$eventBus.$off('fetch-stats', this.triggerFetch);
        },
    };
</script>
