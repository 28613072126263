<template>
    <div>
        <span :class="defaultClass" :key="index" v-for="(subItem, index) in subItems">
            <template v-if="timeFormat">
                {{ timeHms(subItem[columnName]) }}
            </template>
            <template v-else-if="moneyFormat">
                {{ toMoney(subItem[columnName]) }}
            </template>
            <template v-else-if="ratioFormat">
                {{ formatRatio(subItem) }}
            </template>
            <template v-else-if="!empty(subItem[columnName])">
                <a @click="redirect(subItem.id)" v-if="!groupDisplay && subItem.redirect && columnName == 'name'">
                    {{ subItem[columnName] }}
                </a>
                <template v-else>
                    {{ subItem[columnName] }}
                </template>
            </template>
            <template v-else>
                {{ emptyValue !== null ? emptyValue : subItem[columnName] }}
            </template>
            {{ toPercent(subItem) }}
        </span>
    </div>
</template>

<script>
    // Utils
    import { mapActions, mapState } from 'pinia';
    import { toPercent } from '../../../utils/numbers.js';

    import { useDashboardStore } from '../../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../../store/modules/context/store.js';
    import { useGlobalStore } from '../../../store/store.js';

    export default {
        props: {
            row: {
                type: Object,
                default: () => {},
            },
            columnName: {
                type: String,
                default: '',
            },
            subLevelKey: {
                type: String,
                default: '',
            },
            defaultClass: {
                type: String,
                default: 'flex items-end h-10 justify-center',
            },
            percentage: {
                type: Array,
                default: () => [],
            },
            timeFormat: {
                type: Boolean,
                default: false,
            },
            ratioFormat: {
                type: Boolean,
                default: false,
            },
            moneyFormat: {
                type: Boolean,
                default: false,
            },
            emptyValue: {
                type: [Number, String],
                default: null,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['groupDisplay']),

            subItems() {
                const subAggregations = this.row.sub_aggregations || {};

                if (!subAggregations || !this.subLevelKey) {
                    return {};
                }

                return subAggregations[this.subLevelKey] || {};
            },
        },

        methods: {
            ...mapActions(useContextStore, ['setContextUserAction']),

            formatRatio(item) {
                if (!this.percentage) {
                    return item[this.columnName];
                }

                return `${item[this.percentage[0]]} / ${item[this.percentage[1]]}`;
            },

            toPercent(item) {
                if (!this.percentage.length) {
                    return '';
                }

                const num = item[this.percentage[0]];
                const denom = item[this.percentage[1]];

                if (denom == 0) {
                    return '(0%)';
                }

                const percent = toPercent(num, denom);

                return `(${percent}%)`;
            },

            redirect(id) {
                useDashboardStore().disableStatFetch = true;
                this.setContextUserAction(id);
            },
        },
    };
</script>
