<template>
    <div class="flex items-center space-x-3">
        <button
            class="relative"
            :class="[phoneClass, { disabled: phoneIsDisabled }]"
            @mouseenter="phoneIsDisabled || fetchLead(lead.id)"
            @click="onPhoneClick"
        >
            <activix-tooltip :content="phoneTooltip">
                <icon scale="1.0625" :name="$icons.phoneBold" v-if="lead.communication_preference == 'phone'" />
                <icon scale="1.0625" :name="$icons.phone" v-else />
            </activix-tooltip>
        </button>

        <button
            class="relative"
            :class="[emailClass, { disabled: emailIsDisabled }]"
            @mouseenter="emailIsDisabled || fetchLead(lead.id)"
            @click="onEmailClick"
        >
            <activix-tooltip :content="emailTooltip">
                <icon scale="1.25" :name="$icons.emailBold" v-if="lead.communication_preference == 'email'" />
                <icon scale="1.25" :name="$icons.email" v-else />
            </activix-tooltip>

            <span
                class="absolute right-0 top-0 h-2 w-2 mt-0.5 -mr-0.5 rounded-full bg-grey-600"
                v-if="emailReadAtAlert"
            />
        </button>

        <button
            class="relative"
            :class="[smsClass, { disabled: smsIsDisabled }]"
            @mouseenter="smsIsDisabled || fetchLead(lead.id)"
            @click="onSmsClick"
            v-if="hasNioText"
        >
            <activix-tooltip :content="smsTooltip">
                <icon scale="1.0625" :name="$icons.smsBold" v-if="lead.communication_preference == 'sms'" />
                <icon scale="1.064" :name="$icons.sms" v-else />
            </activix-tooltip>
        </button>

        <button
            class="relative"
            :class="[videoClass, { disabled: videoDisabled }]"
            @mouseenter="videoDisabled || fetchLead(lead.id)"
            @click="onVideoClick"
        >
            <activix-tooltip :content="videoTooltip">
                <icon scale="1.35" :name="$icons.camera" />
            </activix-tooltip>
        </button>
    </div>
</template>

<script>
    /* eslint-disable vue/require-prop-types */
    import { mapActions, mapState } from 'pinia';
    import { sort } from '../../../utils/index.js';

    import LeadPhoneValidation from '../../../mixins/LeadPhoneValidation.js';
    import ActivixDate from '../../../value-objects/ActivixDate.js';
    import CommunicationMethod from '../../../entities/CommunicationMethod.js';
    import CommunicationType from '../../../entities/CommunicationType.js';
    import { useGlobalStore } from '../../../store/store.js';

    export default {
        mixins: [LeadPhoneValidation],

        props: ['lead', 'account'],

        computed: {
            ...mapState(useGlobalStore, ['authUser']),

            customerConsentInactive() {
                return this.$feature.isEnabled('casl_compliance') && !this.lead.customer.consentAllowsContact;
            },

            hasNioText() {
                const childHasNioText = this.authUser.children.some(child => {
                    return child.account_id == this.lead.account_id && child.hasNioText();
                });

                return this.lead.account.hasNioText() && (this.authUser.hasNioText() || childHasNioText);
            },

            hasVideoconference() {
                return this.lead.account.video_conference;
            },

            hasValidEmail() {
                return this.lead.emails.some(email => email.valid);
            },

            hasValidPhone() {
                return this.lead.phones.some(phone => phone.valid);
            },

            hasValidMobilePhone() {
                return !!this.lead.getMobilePhones().length;
            },

            hasInvalidPhone() {
                const invalidPhones = this.lead.phones.filter(phone => {
                    return !phone.valid;
                });

                return this.lead.phones.length && invalidPhones.length == this.lead.phones.length;
            },

            tooltipUnsubscribeAll() {
                if (!this.lead.unsubscribe_all_date) {
                    return '';
                }

                const unsubscribeAllDate = new ActivixDate(this.lead.unsubscribe_all_date);

                return unsubscribeAllDate.year === 2222
                    ? this.$t('clientCard.clientIsAllUnsubscribedForever')
                    : this.$t('clientCard.clientIsAllUnsubscribed', [unsubscribeAllDate.toHumanShort(false)]);
            },

            phoneIsDisabled() {
                return (
                    !!this.lead.unsubscribe_all_date ||
                    !!this.lead.unsubscribe_call_date ||
                    !this.hasValidPhone ||
                    !this.lead.account.outgoingPhoneProviders.length
                );
            },

            phoneClass() {
                const phoneCommunications = this.lead.communications.filter(communication => {
                    return (
                        communication.communication_method_id == CommunicationMethod.PHONE && communication.connected
                    );
                });

                const latestPhoneCommunication = sort(phoneCommunications, 'created_at', false)[0];

                if (this.hasInvalidPhone) {
                    return 'text-red-500';
                }

                if (!this.hasValidPhone) {
                    return 'text-grey-500';
                }

                if (!latestPhoneCommunication) {
                    return 'link-blue';
                }

                return latestPhoneCommunication.communication_type_id == CommunicationType.OUTGOING
                    ? 'link-orange'
                    : 'link-green';
            },

            phoneTooltip() {
                if (this.tooltipUnsubscribeAll) {
                    return this.tooltipUnsubscribeAll;
                }

                if (this.lead.unsubscribe_call_date) {
                    return this.$t('clientCard.clientIsCallUnsubscribed');
                }

                if (!this.hasValidPhone) {
                    return this.$t('client.noPhone');
                }

                if (this.lead.communication_preference !== 'phone') {
                    return this.$t('client.makeCall');
                }

                return `
                    ${this.$t('client.makeCall')}<br>
                    <em>${this.$t('clientCard.communicationPreferenceTooltip')}</em>
                `;
            },

            emailReadAtAlert() {
                return this.lead.communications.some(communication => {
                    return (
                        communication.communication_method_id == CommunicationMethod.EMAIL &&
                        communication.communication_type_id == CommunicationType.OUTGOING &&
                        !!communication.email_read_at &&
                        !communication.user_seen_email_read_at &&
                        communication.user_id == this.authUser.id
                    );
                });
            },

            emailIsDisabled() {
                return !!this.lead.unsubscribe_all_date ||
                    !!this.lead.unsubscribe_email_date ||
                    !this.hasValidEmail ||
                    this.customerConsentInactive;
            },

            emailClass() {
                const emailCommunications = this.lead.communications.filter(communication => {
                    return (
                        communication.communication_method_id == CommunicationMethod.EMAIL && communication.connected
                    );
                });

                const latestEmailCommunication = sort(emailCommunications, 'created_at', false)[0];

                if (!this.hasValidEmail) {
                    return 'text-grey-500';
                }

                if (!latestEmailCommunication) {
                    return 'link-blue';
                }

                return latestEmailCommunication.communication_type_id == CommunicationType.OUTGOING
                    ? 'link-orange'
                    : 'link-green';
            },

            emailTooltip() {
                if (this.tooltipUnsubscribeAll) {
                    return this.tooltipUnsubscribeAll;
                }

                if (this.lead.unsubscribe_email_date) {
                    return this.$t('clientCard.clientIsEmailUnsubscribed');
                }

                if (this.customerConsentInactive) {
                    return `${this.$t('consent.consent')} ${this.$t('consent.statuses.expired').toLowerCase()}`;
                }

                if (!this.hasValidEmail) {
                    return this.$t('client.noEmail');
                }

                if (this.lead.communication_preference !== 'email') {
                    return this.$t('client.sendEmail');
                }

                return `
                    ${this.$t('client.sendEmail')}<br>
                    <em>${this.$t('clientCard.communicationPreferenceTooltip')}</em>
                `;
            },

            smsIsDisabled() {
                return (
                    this.customerConsentInactive ||
                    !this.hasNioText ||
                    !!this.lead.unsubscribe_all_date ||
                    (!this.hasValidatedMobile(this.lead) && !this.hasNotValidatedPhone(this.lead)) ||
                    this.$wait.is('validating.leadPhones')
                );
            },

            smsClass() {
                const smsCommunications = this.lead.communications.filter(
                    communication => communication.communication_method_id == CommunicationMethod.SMS,
                );

                const latestSmsCommunication = sort(smsCommunications, 'created_at', false)[0];

                if (this.smsIsDisabled) {
                    return 'text-grey-500';
                }

                if (!latestSmsCommunication) {
                    return 'link-blue';
                }

                return latestSmsCommunication.communication_type_id == CommunicationType.OUTGOING
                    ? 'link-orange'
                    : 'link-green';
            },

            smsTooltip() {
                if (this.tooltipUnsubscribeAll) {
                    return this.tooltipUnsubscribeAll;
                }

                if (this.customerConsentInactive) {
                    return `${this.$t('consent.consent')} ${this.$t('consent.statuses.expired').toLowerCase()}`;
                }

                if (this.$wait.is('validating.leadPhones')) {
                    return this.$t('clientCard.validating');
                }

                if (!this.hasValidatedMobile(this.lead) && !this.hasNotValidatedPhone(this.lead)) {
                    return this.$t('client.noMobilePhone');
                }

                if (this.lead.communication_preference !== 'sms') {
                    return this.$t('clientCard.smsTheClient');
                }

                return `
                    ${this.$t('clientCard.smsTheClient')}<br>
                    <em>${this.$t('clientCard.communicationPreferenceTooltip')}</em>
                `;
            },

            videoClass() {
                const videoCommunications = this.lead.communications.filter(
                    communication => communication.communication_method_id === CommunicationMethod.VIDEO,
                );

                const latestVideoCommunication = sort(videoCommunications, 'created_at', false)[0];

                if (this.videoDisabled) {
                    return 'text-grey-500';
                }

                if (!latestVideoCommunication) {
                    return 'link-blue';
                }

                return latestVideoCommunication.communication_type_id === CommunicationType.OUTGOING
                    ? 'link-orange'
                    : 'link-green';
            },

            videoDisabled() {
                return (
                    this.authUser.isAdmin() ||
                    this.$auth.impersonating() ||
                    !this.$auth.audienceIsInSync() ||
                    !!this.lead.unsubscribe_all_date ||
                    !!this.lead.unsubscribe_call_date ||
                    this.customerConsentInactive ||
                    !this.hasVideoconference ||
                    !this.hasValidEmail ||
                    !this.lead.fullName
                );
            },

            videoTooltip() {
                if (this.authUser.isAdmin()) {
                    return this.$t('communications.videoconference.adminUnauthorized');
                }

                if (this.$auth.impersonating()) {
                    return this.$t('communications.videoconference.impersonateUnauthorized');
                }

                if (!this.hasVideoconference) {
                    return this.$t('communications.videoconference.needVideoconference');
                }

                if (!this.$auth.audienceIsInSync()) {
                    return this.$t('communications.videoconference.needToReconnect');
                }

                if (this.tooltipUnsubscribeAll) {
                    return this.tooltipUnsubscribeAll;
                }

                if (this.lead.unsubscribe_call_date) {
                    return this.$t('clientCard.clientIsCallUnsubscribed');
                }

                if (this.customerConsentInactive) {
                    return `${this.$t('consent.consent')} ${this.$t('consent.statuses.expired').toLowerCase()}`;
                }

                if (!this.hasValidEmail) {
                    return this.$t('communications.videoconference.invitation.noContactInfo');
                }

                if (!this.lead.fullName) {
                    return this.$t('communications.videoconference.needClientName');
                }

                return this.$t('clientCard.videoconference');
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['fetchLead', 'clearAllSeenEmailReadAlert']),

            onPhoneClick() {
                if (this.phoneIsDisabled || !this.lead.id) {
                    return;
                }

                this.$modal.show('dashboard:callLead', this.lead.id);
            },

            onEmailClick() {
                if (this.emailIsDisabled || !this.lead.id) {
                    return;
                }

                if (this.emailReadAtAlert) {
                    this.clearAllSeenEmailReadAlert(this.lead.communications);
                }

                this.$modal.show('dashboard:emailLead', this.lead.id);
            },

            onSmsClick() {
                if (!this.lead.id) {
                    return;
                }

                this.$eventBus.$emit('open-sms-modal', {
                    leadId: this.lead.id,
                });
            },

            onVideoClick() {
                if (this.videoDisabled || !this.lead.id) {
                    return;
                }

                this.$behavior.track('VideoConference', { action: 'call', location: 'dashboard' });

                this.$modal.show('dashboard:videoconferenceInvitation', this.lead.id);
            },
        },
    };
</script>
