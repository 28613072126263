<template>
    <div>
        <div class="box | rounded" v-if="showLeadTable">
            <div class="box-header | flex items-center justify-between rounded-t">
                <div class="flex items-center">
                    <h4 class="box-title" v-text="leadTableTitle" />

                    <activix-tooltip :content="$t('client.addLead')" v-if="showAddLead">
                        <button
                            class="link-blue p-2 flex items-center"
                            data-intercom-target="leadCreate.addButton"
                            @click="openLeadCreate"
                        >
                            <icon class="text-sm" :name="$icons.add" />
                        </button>
                    </activix-tooltip>

                    <div class="flex items-center ml-2 space-x-2" v-if="dashboardConfigs.expanded.leadTable">
                        <template v-if="!xsLayout">
                            <activix-tooltip :content="$t('massActions.massMailing')" v-if="massMailingVisible">
                                <activix-button
                                    class="h-8 w-10 p-0"
                                    type="white"
                                    data-intercom-target="massAction.mail"
                                    :disabled="massMailingDisabled"
                                    @click="openMassMailing"
                                >
                                    <icon class="text-grey-600" name="regular/email-action-unread" />
                                </activix-button>
                            </activix-tooltip>

                            <activix-tooltip :content="$t('dashboards.infoAddEventLeads')" v-if="massEventVisible">
                                <activix-button
                                    class="h-8 w-10 p-0"
                                    type="white"
                                    data-intercom-target="massAction.event"
                                    :disabled="pagination.total === 0"
                                    @click="openAddEventLeads"
                                >
                                    <icon class="text-grey-600" name="regular/megaphone-1" />
                                </activix-button>
                            </activix-tooltip>
                        </template>

                        <el-dropdown
                            class="flex items-center"
                            trigger="click"
                            placement="bottom"
                            data-intercom-target="massAction.more"
                            @command="onDropdownCommand"
                            v-if="!groupDisplay"
                        >
                            <activix-tooltip :content="$t('dashboards.massAction')">
                                <activix-button
                                    class="h-8 w-10 p-0"
                                    type="white"
                                    :disabled="numberOfSelectedLeads == 0"
                                >
                                    <icon class="text-grey-600" name="bold/navigation-menu-horizontal" />
                                </activix-button>
                            </activix-tooltip>

                            <el-dropdown-menu slot="dropdown">
                                <template v-if="xsLayout">
                                    <el-dropdown-item command="openMassMailing" v-if="massMailingVisible">
                                        <div class="flex items-center">
                                            <icon class="mr-2 text-grey-600" name="regular/email-action-unread" />
                                            {{ $t('massActions.massMailing') }}
                                        </div>
                                    </el-dropdown-item>
                                    <el-dropdown-item command="openAddEventLeads" v-if="massEventVisible">
                                        <div class="flex items-center">
                                            <icon class="mr-2 text-grey-600" name="regular/megaphone-1" />
                                            {{ $t('dashboards.infoAddEventLeads') }}
                                        </div>
                                    </el-dropdown-item>
                                </template>

                                <el-dropdown-item command="openConfirmAssociateModal" :disabled="numberOfSelectedLeads < 2">
                                    <div class="flex items-center">
                                        <icon class="mr-2 text-grey-600" name="bold/multiple-neutral-2" />
                                        {{ $t('clientCard.associateLead') }}
                                    </div>
                                </el-dropdown-item>
                                <el-dropdown-item
                                    command="openActionDirectionModal"
                                    :disabled="numberOfSelectedLeads != 2"
                                    v-if="authUser.hasCustomPermission('merge_lead') || authUser.isAdmin()"
                                >
                                    <div class="flex items-center">
                                        <icon class="mr-2 text-grey-500" name="regular/multiple-neutral-2" />
                                        {{ $t('clientCard.mergeLead') }}
                                    </div>
                                </el-dropdown-item>
                                <el-dropdown-item command="openMassEditLead" :disabled="numberOfSelectedLeads == 0">
                                    <div class="flex items-center">
                                        <icon class="mr-2 text-grey-500" name="regular/pencil-1" />
                                        {{ $t('massActions.massEdit') }}
                                    </div>
                                </el-dropdown-item>
                                <el-dropdown-item
                                    command="openDeleteLead"
                                    :disabled="numberOfSelectedLeads == 0"
                                    v-if="authUser.isAdmin() || authUser.custom_permissions.delete_lead"
                                >
                                    <div class="flex items-center">
                                        <icon class="mr-2 text-grey-500" :name="$icons.trash" />
                                        {{ $t('massActions.massDelete') }}
                                    </div>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>

                <div class="flex items-center">
                    <div class="flex items-center space-x-2" v-if="dashboardConfigs.expanded.leadTable">
                        <activix-button
                            type="primary"
                            class="h-8 w-10 p-0 | md:w-auto md:px-3"
                            @click="$eventBus.$emit('apply-filters')"
                            v-if="!filterApplied"
                        >
                            <span class="text-xs md:hidden"><icon name="bold/check-1" /></span>
                            <span class="hidden text-sm | md:block">{{ $t('dashboards.applyFilter') }}</span>
                        </activix-button>
                        <activix-button
                            type="primary"
                            class="h-8 w-10 p-0 text-xs | md:w-auto md:px-3"
                            @click="$eventBus.$emit('clear-filters')"
                            v-if="filtered || !filterApplied"
                        >
                            <span class="text-xs md:hidden"><icon name="bold/close" /></span>
                            <span class="hidden text-sm | md:block">{{ $t('dashboards.clearFilter') }}</span>
                        </activix-button>

                        <activix-tooltip :content="$t('dashboards.addDashboardView')" v-if="!mdLayout">
                            <activix-button
                                class="h-8 w-10 p-0"
                                type="white"
                                @click="openViewModal"
                            >
                                <icon
                                    name="regular/floppy-disk-1"
                                    :class="filtered || !filterApplied ? 'link-blue' : 'link-grey'"
                                />
                            </activix-button>
                        </activix-tooltip>
                        <activix-tooltip :content="$t('option.generateReport')" v-if="!mdLayout && authUser.hasAccessToExport()">
                            <activix-button
                                class="h-8 w-10 p-0"
                                type="white"
                                :disabled="!hasLeads"
                                @click="generateReport"
                            >
                                <icon class="text-grey-600" name="regular/database-upload" />
                            </activix-button>
                        </activix-tooltip>
                        <column-visibility
                            :class="{ 'text-xl': mdLayout }"
                            @select-all-toggled="onSelectAllToggled"
                            @update:column="updateColumnVisibility"
                        />
                        <activix-tooltip :content="$t('dashboards.refresh')" v-if="!mdLayout">
                            <activix-button
                                class="relative h-8 w-10 p-0"
                                type="white"
                                @click="triggerRefresh"
                            >
                                <icon
                                    class="text-grey-600"
                                    :name="$icons.loading"
                                    :class="{ 'spin-inverse': $wait.is('fetching.leadTable') }"
                                />
                                <div
                                    class="badge badge-warning | absolute top-0 right-0 -mt-1 -mr-1"
                                    v-if="showReloadIndicator"
                                />
                            </activix-button>
                        </activix-tooltip>
                    </div>

                    <div class="ml-4 link-grey" @click="$eventBus.$emit('toggle-expanded', 'leadTable')">
                        <icon :name="$icons.collapse" v-if="dashboardConfigs.expanded.leadTable" />
                        <icon :name="$icons.expand" v-else />
                    </div>
                </div>
            </div>

            <div
                class="box-body p-0"
                :class="{ loading: $wait.is('fetching.leadTable') }"
                v-if="dashboardConfigs.expanded.leadTable"
            >
                <lead-table
                    :bulk-edit-visible="bulkEditVisible"
                    :filter-applied="filterApplied"
                    :filtered="filtered"
                    :filters-buffer="filtersBuffer"
                    :hide-rows="!tableLeads.length || tooManyResults"
                    :all-leads-on-page-selected="allLeadsOnPageSelected"
                    @update:filter="updateFilter"
                    @update:filters="updateFilters"
                >
                    <template slot="header">
                        <div
                            class="border-b border-gray-200 bg-gray-100 p-3 flex items-center justify-center flex-col | lg:flex-row lg:space-x-2"
                            v-if="allLeadsOnPageSelected || someLeadsOnAnotherPageIsSelected || isAllSelected"
                        >
                            <div class="text-grey-600">
                                <span v-if="!isAllSelected && bulkSelect.length < pagination.total">
                                    {{ $tc('dashboards.leadsSelectedOnThisPage', bulkSelect.length, { count: bulkSelect.length }) }}
                                </span>
                                <span v-else-if="isAllSelected && blackListedLeadIds.length">
                                    {{ $tc('dashboards.leadsSelectedOnThisPage', bulkSelect.length, { count: pagination.total - blackListedLeadIds.length }) }}
                                </span>
                                <span v-else>
                                    {{ $t('dashboards.allLeadsSelected', { count: pagination.total }) }}
                                </span>
                            </div>
                            <div>
                                <button
                                    class="font-bold text-blue-500 hover:text-blue-600"
                                    :data-intercom-target="!isAllSelected ? 'massAction.selectAll' : 'massAction.unselectAll'"
                                    @click="toggleSelectAllLeads"
                                    v-text="!isAllSelected ? $t('dashboards.selectAllLeads', { count: pagination.total }) : $t('dashboards.unselectAllLeads')"
                                />
                            </div>
                        </div>
                    </template>
                </lead-table>

                <div
                    class="lead-table-no-results | bg-grey-100 flex items-center justify-center"
                    v-text="$t('dashboards.noResults')"
                    v-if="!tableLeads.length"
                />
                <div
                    class="lead-table-no-results | bg-grey-100 flex items-center justify-center"
                    v-text="$t('dashboards.tooManyResults')"
                    v-if="tooManyResults"
                />

                <lead-table-footer />
            </div>
        </div>

        <lead-table-legend class="hidden justify-start mt-3 -mb-3 lg:flex" v-if="showLegend" />

        <call-lead name="dashboard:callLead" />
        <comment-modal name="dashboard:comments" />
        <email-lead name="dashboard:emailLead" />
        <videoconference-invitation name="dashboard:videoconferenceInvitation" />
        <followed-communication name="dashboard:followed" />
        <revive-webboost name="dashboard:reviveWebBoost" />
        <dashboard-custom-field-text-edit name="dashboard:customTextEdit" />
        <dashboard-custom-field-vehicles-edit name="dashboard:customVehiclesEdit" />

        <follow-up name="followUp" />
        <consent-config name="consentConfig" />
        <duplicate-info name="duplicateInfo" :in-client-card="false" />
        <select-vehicle name="selectVehicle" :in-client-card="false" />
        <cancel-appointment name="cancelAppointment" />

        <action-direction :opened.sync="modals.actionDirectionOpened" />
        <add-event-leads :opened.sync="modals.addEventLeadsOpened" />
        <dashboard-view-modal :opened.sync="modals.viewOpened" />
        <delete-lead :opened.sync="modals.deleteLeadOpened" :leads-count="numberOfSelectedLeads" />
        <generate-report :opened.sync="modals.generateReportOpened" />
        <mass-edit-lead :opened.sync="modals.massEditLeadOpened" />
        <activix-confirm-modal
            type="info"
            :title="modalNextStepTitle"
            :approve-text="$t('general.continue')"
            :deny-text="$t('modal.cancel')"
            :content="modalNextStepContent"
            :opened.sync="modals.confirmAllLeadsModalOpened"
            @approve="triggerApprove"
        />
        <activix-confirm-modal
            type="info"
            :title="$t('modal.associateLeadsTitle')"
            :approve-text="$t('clientCard.associateLead')"
            :deny-text="$t('modal.cancel')"
            :content="$t('modal.associateLeads', { count: numberOfSelectedLeads })"
            :opened.sync="modals.confirmAssociateLeads"
            @approve="associateLeads"
        />
    </div>
</template>

<script>
    import { isEmpty, cloneDeep, get, isArray, isEqual, some } from 'lodash-es';

    import { mapActions, mapState } from 'pinia';

    // Components
    import ActionDirection from '../modals/ActionDirection.vue';
    import AddEventLeads from '../modals/AddEventLeads.vue';
    import CallLead from '../modals/CallLead.vue';
    import CancelAppointment from '../modals/CancelAppointment.vue';
    import ConsentConfig from '../modals/ConsentConfig.vue';
    import ColumnVisibility from './ColumnVisibility.vue';
    import CommentModal from '../modals/CommentModal.vue';
    import DashboardCustomFieldTextEdit from '../modals/DashboardCustomFieldTextEdit.vue';
    import DashboardCustomFieldVehiclesEdit from '../modals/DashboardCustomFieldVehiclesEdit.vue';
    import DashboardViewModal from '../modals/DashboardView.vue';
    import DeleteLead from '../modals/DeleteLead.vue';
    import DuplicateInfo from '../modals/DuplicateInfo.vue';
    import EmailLead from '../modals/EmailLead.vue';
    import FollowedCommunication from '../modals/FollowedCommunication.vue';
    import FollowUp from '../modals/FollowUp.vue';
    import GenerateReport from '../modals/GenerateReport.vue';
    import LeadTable from './LeadTable.vue';
    import LeadTableLegend from './LeadTableLegend.vue';
    import MassEditLead from '../modals/MassEditLead.vue';
    import LeadTableFooter from './LeadTableFooter.vue';
    import ReviveWebboost from '../modals/ReviveWebboost.vue';
    import SelectVehicle from '../modals/SelectVehicle.vue';
    import VideoconferenceInvitation from '../modals/VideoconferenceInvitation.vue';

    // Entities
    import DashboardType from '../../entities/DashboardType.js';

    // Mixins
    import DashboardColumns from '../../mixins/DashboardColumns.js';

    // Utils
    import { nl2br } from '../../utils/string.js';

    // Pinia
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            ActionDirection,
            AddEventLeads,
            CallLead,
            CancelAppointment,
            ColumnVisibility,
            CommentModal,
            ConsentConfig,
            DashboardCustomFieldTextEdit,
            DashboardCustomFieldVehiclesEdit,
            DashboardViewModal,
            DeleteLead,
            DuplicateInfo,
            EmailLead,
            FollowedCommunication,
            FollowUp,
            GenerateReport,
            LeadTable,
            LeadTableFooter,
            LeadTableLegend,
            MassEditLead,
            ReviveWebboost,
            SelectVehicle,
            VideoconferenceInvitation,
        },

        mixins: [DashboardColumns],

        data() {
            return {
                filtersBuffer: {},
                filtersDropdown: {},
                nextAction: '',
                isApproved: false,
                modals: {
                    confirmAssociateLeads: false,
                    confirmAllLeadsModalOpened: false,
                    actionDirectionOpened: false,
                    addEventLeadsOpened: false,
                    deleteLeadOpened: false,
                    generateReportOpened: false,
                    massEditLeadOpened: false,
                    viewOpened: false,
                },
            };
        },

        computed: {
            ...mapState(useContextStore, {
                contextLead: 'contextLead',
                contextAccount: 'account',
                contextUser: 'user',
            }),
            ...mapState(useGlobalStore, ['authUser', 'groupDisplay']),
            ...mapState(useDashboardStore, {
                dashboardView: 'dashboardView',
                isMassMailing: 'isMassMailing',
                dashboardCriteria: 'dashboardCriteria',
                blackListedLeadIds: 'blackListedLeadIds',
                bulkSelect: 'bulkSelect',
                dashboardConfigs: 'configs',
                tableLeads: 'tableLeads',
                dashboardType: 'dashboardType',
                pagination: 'pagination',
                allLeadsInTableSelected: 'allLeadsInTableSelected',
            }),

            hasLeads() {
                return this.pagination.total > 0;
            },

            numberOfSelectedLeads() {
                return this.allLeadsInTableSelected ? this.pagination.total - this.blackListedLeadIds.length : this.bulkSelect.length;
            },

            isAllSelected() {
                return this.allLeadsInTableSelected
                    ? this.allLeadsInTableSelected
                    : this.pagination.total > 0 && this.pagination.total === this.bulkSelect.length;
            },

            bulkSelected() {
                return this.bulkSelect.length > 0;
            },

            massMailingVisible() {
                return !this.groupDisplay && (!this.authUser.isClient() || this.authUser.mass_mailing);
            },

            massMailingDisabled() {
                if (this.pagination.total === 0) {
                    return true;
                }

                if (this.bulkSelected) {
                    return !this.isMassMailing;
                }

                return false;
            },

            tooManyResults() {
                return this.pagination.total > 10000;
            },

            showReloadIndicator() {
                return this.$wait.is('indicator.leadTable');
            },

            showLeadTable() {
                return this.dashboardType != DashboardType.ACTIVITY;
            },

            showLegend() {
                return (
                    ![DashboardType.ACTIVITY, DashboardType.SALE].includes(this.dashboardType) &&
                    this.dashboardConfigs.expanded.leadTable
                );
            },

            filtered() {
                if (this.dashboardView.isEmpty()) {
                    return !!Object.keys(this.activeDashboardFilters).length;
                }

                return Object.keys(this.activeDashboardFilters).some(key => {
                    const filter = this.activeDashboardFilters[key];
                    const viewFilter = this.dashboardView.filter[key];

                    let differentValues = false;

                    if (isArray(filter)) {
                        differentValues = JSON.stringify(filter) != JSON.stringify(viewFilter);
                    } else {
                        differentValues = filter != viewFilter;
                    }

                    return (!isEmpty(filter) || !isEmpty(viewFilter)) && differentValues;
                });
            },

            filterApplied() {
                return !some(this.filtersBuffer, (bufferValue, name) => {
                    let filterValue = get(this.dashboardConfigs.filters, name);

                    if (isEmpty(bufferValue)) {
                        bufferValue = null;
                    }

                    if (isEmpty(filterValue)) {
                        filterValue = null;
                    }

                    return !isEqual(bufferValue, filterValue);
                });
            },

            bulkEditVisible() {
                return !this.groupDisplay && (!!this.contextAccount.id || !!this.contextUser.id);
            },

            canCreateEvent() {
                return (
                    this.authUser.isAdmin() ||
                    (this.authUser.hasAccessTo('dashboards.event') && this.authUser.allow_event_creation)
                );
            },

            leadTableTitle() {
                switch (this.dashboardType) {
                    case DashboardType.WEB_ORDER:
                        return this.$tc('general.webOrder', 0);

                    case DashboardType.WALK_IN:
                        return this.$t('general.walkIn');

                    case DashboardType.PHONE_UP:
                        return this.$t('general.phoneUp');

                    case DashboardType.ALL:
                    case DashboardType.RENEWAL:
                    case DashboardType.COMMERCIAL:
                    case DashboardType.LOYALTY:
                    case DashboardType.SERVICE:
                    case DashboardType.EVENT:
                        return this.$tc('general.client', 0);

                    case DashboardType.SALE:
                        return this.$t('saleTable.saleJournal');

                    default:
                        return this.$tc('general.lead', 0);
                }
            },

            showAddLead() {
                return this.contextAccount.id && this.dashboardType != DashboardType.EVENT;
            },

            allLeadsOnPageSelected() {
                return (
                    !!this.tableLeads.length &&
                    this.tableLeads.every(lead => {
                        return this.bulkSelect.some(bulkSelectLead => bulkSelectLead.id == lead.id);
                    })
                );
            },

            someLeadsOnAnotherPageIsSelected() {
                return this.bulkSelect.some(bulkLead => {
                    return !this.tableLeads.some(tableLead => tableLead.id == bulkLead.id);
                });
            },

            modalNextStepContent() {
                let content = '';

                switch (this.nextAction) {
                    case 'email':
                        content = this.$t('modal.modifyAllLeadsEmail', { count: this.pagination.total });
                        break;
                    case 'event':
                        content = this.$t('modal.modifyAllLeadsEvent', { count: this.pagination.total });
                        break;
                }

                return content;
            },

            modalNextStepTitle() {
                let title = '';

                switch (this.nextAction) {
                    case 'email':
                        title = this.$t('modal.modifyAllLeadsEmailTitle');
                        break;
                    case 'event':
                        title = this.$t('modal.modifyAllLeadsEventTitle');
                        break;
                }

                return title;
            },

            massEventVisible() {
                return !this.groupDisplay && this.canCreateEvent && this.dashboardType !== 'event';
            },
        },

        methods: {
            ...mapActions(useDashboardStore, [
                'setAllLeadsInTableSelected',
            ]),
            ...mapActions(useContextStore, ['setContextLeadIdAction']),
            ...mapActions(useGlobalStore, ['updateSessionAction', 'appendNewError', 'setMergedLeadIdAction']),

            toggleSelectAllLeads() {
                if (this.isAllSelected) {
                    this.$behavior.track('MassAction', { action: 'unselectAll', location: 'leadTable' });
                    this.setAllLeadsInTableSelected(false);
                } else {
                    this.$behavior.track('MassAction', { action: 'selectAll', location: 'leadTable' });
                    this.setAllLeadsInTableSelected(true);
                }
            },

            openLeadCreate() {
                this.$eventBus.$emit('open-lead-create', {
                    accountId: this.contextAccount.id,
                    type: this.underscore(this.dashboardType),
                });
            },

            triggerRefresh() {
                this.setAllLeadsInTableSelected(false);
                this.$eventBus.$emit('fetch-table-leads', { getCount: true });
            },

            generateReport() {
                this.modals.generateReportOpened = true;
            },

            openViewModal() {
                this.modals.viewOpened = true;
            },

            triggerApprove() {
                this.isApproved = true;

                this.setAllLeadsInTableSelected(true);

                switch (this.nextAction) {
                    case 'email':
                        this.openMassMailing();
                        break;
                    case 'event':
                        this.openAddEventLeads();
                        break;
                }
            },

            openConfirmAssociateModal() {
                this.modals.confirmAssociateLeads = true;
            },

            openConfirmAllModal(name) {
                this.nextAction = name;
                this.modals.confirmAllLeadsModalOpened = true;
            },

            openMassMailing() {
                if (this.numberOfSelectedLeads === 0 && !this.isApproved) {
                    this.openConfirmAllModal('email');
                    return;
                }

                const filteredLead = this.bulkSelect.filter(lead => !!lead.emails.length);

                if (filteredLead.length === 1) {
                    this.$modal.show('dashboard:emailLead', filteredLead[0].id);
                }

                this.isApproved = false;

                this.$behavior.track('MassAction', { action: 'mail', location: 'leadTable' });

                this.$modal.show('dashboard:emailLead');
            },

            updateColumnVisibility(name) {
                const columnsVisibility = { ...this.dashboardConfigs.columnVisibilities };
                const column = this.dashboardColumns.find(column => column.name == name);
                const newValue = column.visible ? 'hidden' : 'visible';

                columnsVisibility[name] = newValue;

                if (newValue === 'hidden' && this.activeDashboardFilters[name]) {
                    if (this.dashboardView.filter[name] && this.filterApplied) {
                        this.updateFilters(this.dashboardView.filter[name]);
                    } else {
                        this.updateFilter(name, null);
                    }

                    this.$eventBus.$emit('apply-filters');
                }

                useDashboardStore().configs.columnVisibilities = columnsVisibility;
                this.updateSessionAction('dashboard_column', columnsVisibility);
            },

            onSelectAllToggled(enabled) {
                if (enabled) {
                    this.selectAllColumns();
                } else {
                    this.deselectAllColumns();
                }
            },

            selectAllColumns() {
                const columnsVisibility = {};

                this.dashboardColumns
                    .filter(column => !column.mandatory)
                    .forEach(column => {
                        columnsVisibility[column.name] = 'visible';
                    });

                useDashboardStore().configs.columnVisibilities = columnsVisibility;
                this.updateSessionAction('dashboard_column', columnsVisibility);
            },

            deselectAllColumns() {
                const columnsVisibility = {};
                let filterUpdated = false;

                this.dashboardColumns
                    .filter(column => !column.mandatory)
                    .forEach(column => {
                        columnsVisibility[column.name] = 'hidden';

                        if (this.activeDashboardFilters[column.name]) {
                            filterUpdated = true;
                            this.updateFilter(column.name, null);
                        }
                    });

                if (filterUpdated) {
                    this.$eventBus.$emit('apply-filters');
                }

                useDashboardStore().configs.columnVisibilities = columnsVisibility;
                this.updateSessionAction('dashboard_column', columnsVisibility);
            },

            openActionDirectionModal() {
                this.setContextLeadIdAction(this.bulkSelect.map(lead => lead.id)[0]);
                this.setMergedLeadIdAction(this.bulkSelect.map(lead => lead.id)[1]);
                this.modals.actionDirectionOpened = true;
            },

            openAddEventLeads() {
                if (this.numberOfSelectedLeads === 0 && !this.isApproved) {
                    this.openConfirmAllModal('event');
                    return;
                }

                this.$behavior.track('MassAction', { action: 'event', location: 'leadTable' });

                this.modals.addEventLeadsOpened = true;
                this.isApproved = false;
            },

            openMassEditLead() {
                this.modals.massEditLeadOpened = true;
            },

            openDeleteLead() {
                this.modals.deleteLeadOpened = true;
            },

            async associateLeads() {
                this.modals.confirmAssociateLeads = false;

                if (this.bulkSelect.length < 2) {
                    return;
                }

                const [masterLead, ...associatedIds] = this.bulkSelect.map(lead => lead.id);

                const query = (() => {
                    if (this.allLeadsInTableSelected) {
                        return { search: this.dashboardCriteria };
                    }

                    return { id: masterLead };
                })();

                try {
                    await this.$api.leads.associate(query, {
                        lang: this.$i18n.locale,
                        masterLead,
                        associatedIds,
                        dissociation: false,
                        blackListedLeadIds: this.allLeadsInTableSelected ? this.blackListedLeadIds : [],
                    });
                    this.$notify.info(this.$t('massActions.warningAssociate'));
                    this.setAllLeadsInTableSelected(false);
                } catch (error) {
                    if (!error || !error.response) {
                        return;
                    }

                    if (error.response.status !== 403) {
                        this.appendNewError({
                            code: '0079',
                            display: true,
                            error,
                            request: {
                                associatedIds,
                                dissociation: false,
                            },
                        });
                    }
                }
            },

            sourceClickHandler(event) {
                const leadId = $(event.target).data('id');
                const lead = this.tableLeads.find(l => l.id == leadId);

                if (!lead) {
                    return;
                }

                const emailRegex = /(\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*)/;
                const phoneRegex = /((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/;
                let contentPhone = nl2br(lead.scraper_description);

                if (this.authUser.hasAdvisorSkills()) {
                    contentPhone = contentPhone.replace(emailRegex, 'XXXX');
                    contentPhone = contentPhone.replace(phoneRegex, 'XXXX');
                }

                const template = `
                    <div class="popover popover-source-content">
                        <div class="arrow"></div>
                        <div class="popover-content"></div>
                    </div>
                `;

                $(event.target)
                    .popover({
                        content: contentPhone,
                        html: true,
                        placement: 'left',
                        container: 'body',
                        trigger: 'manual',
                        template,
                    })
                    .popover('show');
            },

            bodyClickHandler(event) {
                const target = $(event.target);

                // Did not click popover source
                if (
                    !target.parents('.popover-source').length &&
                    !target.parents('.popover-source-content').length &&
                    !target.hasClass('popover-source')
                ) {
                    $('.popover-source').popover('destroy');
                }
            },

            unselectAllLeads() {
                this.setAllLeadsInTableSelected(false);
            },

            updateFilter(column, value) {
                this.$set(this.filtersBuffer, column, value);
            },

            updateFilters(filters) {
                if (empty(filters)) {
                    filters = {};
                }

                const updatedFilters = cloneDeep(filters);

                this.$set(this, 'filtersBuffer', updatedFilters);
            },

            onDropdownCommand(command) {
                if (command) {
                    this.$behavior.track('MassAction', { action: command, location: 'leadTable' });

                    this[command]();
                }
            },
        },

        created() {
            this.$nextTick(() => {
                $(document).on('click', '#table-leads .popover-source', this.sourceClickHandler);
                $('body').on('click', this.bodyClickHandler);
            });
        },

        beforeDestroy() {
            $(document).off('click', '#table-leads .popover-source', this.sourceClickHandler);
            $('body').off('click', this.bodyClickHandler);
            $('.popover-source').popover('destroy');
        },
    };
</script>
