<template>
    <activix-select
        label-key="name"
        multiple
        filterable
        select-all
        :options="possibleCampaigns"
        :placeholder="$t('multiselect.selectOption')"
        @input="updateCampaigns"
        v-model="selectedCampaigns"
    >
        <template #trigger="{ placeholder, selected }">
            <event-trigger :placeholder="placeholder" :selected="selected" />
        </template>
    </activix-select>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { debounce, orderBy, sortBy } from 'lodash-es';
    import { ActivixSelect } from '@activix/ui';
    import CampaignType from '../../entities/CampaignType.js';
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import EventTrigger from './EventTrigger.vue';

    export default {
        components: {
            ActivixSelect,
            EventTrigger,
        },

        data() {
            return {
                selectedCampaigns: [],
            };
        },

        computed: {
            ...mapState(useDashboardStore, {
                filteredCampaigns: 'filteredCampaigns',
                startDate: 'startDate',
                endDate: 'endDate',
            }),

            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            possibleCampaigns() {
                return orderBy(
                    this.contextAccount.campaigns
                        .filter(campaign => campaign.type === CampaignType.EVENT)
                        .map(c => {
                            return {
                                value: c.id,
                                name: c.name,
                            };
                        }),
                    ['value'],
                    ['desc'],
                );
            },

            earliestCampaignStartDate() {
                const selectedCampaignIds = this.selectedCampaigns;
                const selectedCampaigns = this.contextAccount.campaigns.filter(
                    c => selectedCampaignIds.includes(c.id) && c.type === CampaignType.EVENT,
                );
                const earliestCampaign = sortBy(selectedCampaigns, 'created_at').find(c => c.created_at);

                return earliestCampaign
                    ? locale_date(earliestCampaign.created_at).toString()
                    : now_date().subDays(7).toString();
            },

            latestCampaignEndDate() {
                const selectedCampaignIds = this.selectedCampaigns;
                const selectedCampaigns = this.contextAccount.campaigns.filter(
                    c => selectedCampaignIds.includes(c.id) && c.type === CampaignType.EVENT,
                );
                const latestCampaign = orderBy(selectedCampaigns, 'created_at', 'desc').find(c => c.created_at);

                return latestCampaign ? locale_date(latestCampaign.created_at).toString() : now_date().toString();
            },
        },

        watch: {
            possibleCampaigns() {
                this.initCampaigns();
            },

            selectedCampaigns() {
                this.setDates();
            },
        },

        methods: {
            ...mapActions(useDashboardStore, [
                'setStartDate',
                'setEndDate',
            ]),

            updateCampaigns(newValue) {
                useDashboardStore().filteredCampaigns = newValue;
            },

            limitText(count) {
                return this.$t('selectpicker.xSelected', [count]);
            },

            initCampaigns() {
                if (empty(this.possibleCampaigns)) {
                    useDashboardStore().filteredCampaigns = [];
                    this.selectedCampaigns = [];
                } else if (empty(this.selectedCampaigns)) {
                    // If empty, set first
                    useDashboardStore().filteredCampaigns = [this.possibleCampaigns[0].value];
                    this.selectedCampaigns = [this.possibleCampaigns[0].value];
                }

                this.setDates();
            },

            setDates() {
                if (this.earliestCampaignStartDate && this.earliestCampaignStartDate != this.startDate) {
                    this.setStartDate(this.earliestCampaignStartDate);
                }

                if (this.latestCampaignEndDate && this.latestCampaignEndDate != this.endDate) {
                    this.setEndDate(this.latestCampaignEndDate);
                }
            },
        },

        created() {
            this.updateCampaigns = debounce(this.updateCampaigns, 500);
        },

        mounted() {
            this.setEndDate(now_date());
            this.initCampaigns();
        },
    };
</script>
