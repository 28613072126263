<template>
    <activix-tooltip :content="tooltip">
        <icon
            class="ml-4 link-grey | lg:ml-3"
            :name="icon"
            :class="{
                'cursor-not-allowed text-grey-400': disabled,
                'spin-inverse': spinning,
                'text-grey-400 hover:text-grey-500': !active,
            }"
            @click="onClick"
        />
    </activix-tooltip>
</template>

<script>
    export default {
        props: {
            active: {
                type: Boolean,
                default: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            icon: {
                type: String,
                required: true,
            },
            spinning: {
                type: Boolean,
                default: false,
            },
            tooltip: {
                type: String,
                required: true,
            },
        },

        methods: {
            onClick() {
                if (this.disabled) {
                    return;
                }

                this.$emit('click');
            },
        },
    };
</script>
