<template>
    <activix-modal
        size="xl"
        :name="name"
        :loading="loading"
        ref="modal"
        @before-open="onBeforeOpen"
        @closed="onClosed"
    >
        <template slot="header">
            <h4 class="modal-title">
                {{ $t('clientCard.communications') }}
            </h4>
        </template>

        <template slot="body">
            <communication-list
                class="-m-6"
                table-classes="border-t"
                :read-only="true"
                :communication-type="communicationType"
                :lead="lead"
            />
        </template>

        <template slot="footer">
            <activix-button type="primary" @click="close">
                {{ $t('general.close') }}
            </activix-button>
        </template>
    </activix-modal>
</template>

<script>
    // Components
    import { mapActions } from 'pinia';
    import CommunicationList from '../lead/CommunicationList.vue';

    // Entites
    import CommunicationMethod from '../../entities/CommunicationMethod.js';
    import Lead from '../../entities/Lead.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            CommunicationList,
        },

        props: {
            name: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                loading: true,
                lead: new Lead(),
                communicationType: null,
            };
        },

        methods: {
            ...mapActions(useGlobalStore, ['fetchLead']),

            async onBeforeOpen({ leadId, type }) {
                switch (type) {
                    case 'call':
                        this.communicationType = CommunicationMethod.PHONE;
                        break;
                    case 'email':
                        this.communicationType = CommunicationMethod.EMAIL;
                        break;
                    case 'sms':
                        this.communicationType = CommunicationMethod.SMS;
                        break;
                    case 'messenger':
                        this.communicationType = CommunicationMethod.MESSENGER;
                        break;
                    case 'video':
                        this.communicationType = CommunicationMethod.VIDEO;
                        break;
                }

                this.lead = await this.fetchLead(leadId);
                this.loading = false;
            },

            close() {
                this.$refs.modal.hide();
            },

            onClosed() {
                this.communicationType = null;
                this.loading = true;
                this.lead = new Lead();
            },
        },
    };
</script>
