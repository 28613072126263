<template>
    <activix-modal
        :opened="opened"
        size="xs"
        @close="close"
        @closed="onClosed"
    >
        <template slot="header">
            <div class="flex items-center justify-center">
                <h4 class="modal-title">
                    {{ $t('dashboards.addEventLeads') }}
                </h4>
            </div>
        </template>

        <template slot="body">
            <div class="form-group">
                <label for="event-edit">{{ $t('dashboards.event') }}</label>
                <activix-multiselect
                    id="event-edit"
                    identifier="value"
                    label="text"
                    :selected="event"
                    :options="campaigns"
                    :searchable="false"
                    :show-labels="false"
                    :allow-empty="false"
                    :multiple="false"
                    @update="updateEvent"
                />
            </div>

            <div class="form-group" v-show="isNewEvent">
                <label for="event-new">{{ $t('dashboards.eventName') }}</label>
                <input
                    id="event-new"
                    name="event-new"
                    class="form-control"
                    v-model="eventNew"
                    v-show="isNewEvent"
                />
            </div>

            <div class="form-group">
                <label for="segment">{{ $t('dashboards.segment') }}</label>
                <activix-multiselect
                    id="segment"
                    identifier="value"
                    label="text"
                    :selected="segment"
                    :options="segmentOptions"
                    :searchable="false"
                    :allow-empty="false"
                    :multiple="false"
                    @update="updateSegment"
                />
            </div>
        </template>

        <template slot="footer">
            <activix-button @click="close">
                {{ $t('general.cancel') }}
            </activix-button>
            <activix-button
                class="ml-2"
                type="primary"
                :disabled="empty(eventName) || empty(segment) || saving"
                :loading="saving"
                @click="save"
            >
                {{ $t('general.save') }}
            </activix-button>
        </template>
    </activix-modal>
</template>

<script>
    import { sortBy } from 'lodash-es';
    import { mapActions, mapState } from 'pinia';
    import CampaignType from '../../entities/CampaignType.js';

    // Pinia
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        props: {
            opened: {
                type: Boolean,
                required: true,
            },
        },

        data() {
            return {
                saving: false,
                event: '',
                eventNew: '',
                segment: '',
                segmentOptions: [
                    { value: 'conquest', text: this.$t('result.conquest') },
                    { value: 'promo', text: this.$t('result.promo') },
                    { value: 'notSold', text: this.$t('result.notSold') },
                    { value: 'service', text: this.$t('result.service') },
                    { value: 'loyalty', text: this.$t('result.loyalty') },
                    { value: 'reminder', text: this.$t('result.reminder') },
                    { value: 'endWarranty', text: this.$t('result.endWarranty') },
                    { value: 'endLcap', text: this.$t('result.endLcap') },
                    { value: 'endLnette', text: this.$t('result.endLnette') },
                    { value: 'csi', text: this.$t('result.csi') },
                    { value: 'noShow', text: this.$t('result.noShow') },
                    { value: 'other', text: this.$t('result.other') },
                ],
            };
        },

        computed: {
            ...mapState(useContextStore, {
                contextAccount: 'account',
                contextGroup: 'group',
                contextUser: 'user',
            }),

            ...mapState(useDashboardStore, {
                division: 'division',
                dashboardCriteria: 'dashboardCriteria',
                blackListedLeadIds: 'blackListedLeadIds',
                bulkSelect: 'bulkSelect',
                allLeadsInTableSelected: 'allLeadsInTableSelected',
                mainStartDate: 'startDate',
                mainEndDate: 'endDate',
                order: store => store.configs.order,
                options: store => store.configs.options,
                dashboardType: 'dashboardType',
                filters: store => store.configs.filters,
                selectedCampaigns: 'selectedCampaigns',
                filteredDates: 'filteredDates',
            }),

            isNewEvent() {
                return this.eventId == -1;
            },

            eventId() {
                return !empty(this.event) ? this.event.value : null;
            },

            eventName() {
                if (this.isNewEvent) {
                    return this.eventNew;
                }

                return !empty(this.event) ? this.event.text : '';
            },

            campaigns() {
                let campaigns = [];

                if (!empty(this.contextAccount.campaigns)) {
                    for (const campaign of this.contextAccount.campaigns.filter(c => c.type === CampaignType.EVENT)) {
                        campaigns.push({
                            value: campaign.id,
                            text: campaign.name,
                        });
                    }
                }

                campaigns = sortBy(campaigns, campaign => {
                    return -campaign.value;
                });

                // Add divider under "New event"
                if (campaigns.length) {
                    campaigns[0].divided = true;
                }

                campaigns.unshift({
                    value: -1,
                    text: this.$t('dashboards.newEvent'),
                });

                return campaigns;
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['appendNewError']),

            ...mapActions(useDashboardStore, ['setAllLeadsInTableSelected']),

            updateEvent(val) {
                this.event = val;
            },

            updateSegment(val) {
                this.segment = val;
            },

            async save() {
                this.saving = true;

                const query = (() => {
                    if (this.allLeadsInTableSelected || this.bulkSelect.length === 0) {
                        return { search: this.dashboardCriteria };
                    }

                    return { ids: this.bulkSelect.map(lead => lead.id) };
                })();

                // Add event leads
                try {
                    const response = await this.$api.leads.event(query, {
                        lang: this.$i18n.locale,
                        segment: this.segment,
                        event: this.eventName,
                        dashboard: this.dashboardType,
                        accountId: this.contextAccount.id,
                        blackListedLeadIds: this.allLeadsInTableSelected ? this.blackListedLeadIds : [],
                    });

                    if (empty(this.contextAccount.campaigns.filter(c => c.name === response.data.data.campaign.name))) {
                        useContextStore().account.campaigns.push(response.data.data.campaign);
                        this.$notify.info(this.$t('dashboards.eventBeingCreated'));
                    } else {
                        this.$notify.info(this.$t('dashboards.eventAddingLeads', { event: this.eventName }));
                    }

                    this.close();
                    this.saving = false;

                    this.setAllLeadsInTableSelected(false);
                    this.$eventBus.$emit('fetch-table-leads');
                } catch (error) {
                    this.saving = false;

                    this.appendNewError({
                        code: '0019',
                        display: true,
                        error,
                        request: {
                            startDate: this.mainStartDate,
                            endDate: this.mainEndDate,
                            userId: this.contextUser.id,
                            accountId: this.contextAccount.id,
                            groupId: this.contextGroup.id,
                            options: this.options,
                            division: this.division,
                            dashboard: this.dashboardType,
                            customFilters: this.filters,
                            campaigns: this.selectedCampaigns,
                            customOrderBy: this.order.orderBy ? this.order.orderBy : 'fullname',
                            customSortBy: this.order.sortBy ? this.order.sortBy : 'asc',
                            segment: this.segment,
                            event: this.eventName,
                            filteredDates: this.filteredDates,
                        },
                    });
                }
            },

            close() {
                this.$emit('update:opened', false);
            },

            onClosed() {
                this.segment = '';
                this.event = '';
                this.eventNew = '';
            },
        },
    };
</script>
