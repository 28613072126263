<template>
    <div class="row | flex flex-wrap -mb-6">
        <div class="col-xs-12 col-lg-6 col-xl-4 | pb-8" :key="name" v-for="(table, name) in tables">
            <div class="box | h-full mb-0" :class="{ loading: loading }">
                <div class="box-header text-center">
                    <h4 class="box-title">
                        {{ $t(`activityReport.advancedStats.boxTitles.${name}`) }}
                    </h4>
                </div>
                <div class="box-body | p-0">
                    <el-table class="w-full border-t-2 border-b" :data="table">
                        <el-table-column class-name="font-normal" prop="name" min-width="128">
                            <template slot-scope="{ row }">
                                {{ $tc(`activityReport.stats.${row.name}`, 2) }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            align="center"
                            prop="total"
                            min-width="95"
                            :label="$t('activityReport.total')"
                        />
                        <el-table-column
                            align="center"
                            prop="ratio"
                            min-width="95"
                            :label="$t('activityReport.ratio')"
                        >
                            <template slot-scope="{ row }">
                                {{ valueIsSet(row.ratio) ? `${row.ratio}%` : '-' }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            align="center"
                            prop="objective"
                            min-width="95"
                            :label="$t('activityReport.objective')"
                        >
                            <template slot="header" slot-scope="{ column }">
                                <div class="flex items-center justify-center">
                                    {{ column.label }}
                                    <activix-tooltip :content="$t('activityReport.advancedStats.objectiveTooltip')">
                                        <icon name="regular/information-circle" class="text-grey-500 text-sm ml-1" />
                                    </activix-tooltip>
                                </div>
                            </template>
                            <template slot-scope="{ row }">
                                {{ valueIsSet(row.objective) ? row.objective : '-' }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            align="center"
                            prop="variation"
                            min-width="95"
                            :label="$t('activityReport.variation')"
                        >
                            <span
                                :class="{
                                    'text-green-500': valueIsSet(row.variation) && row.variation > 0,
                                    'text-red-500': valueIsSet(row.variation) && row.variation < 0,
                                }"
                                slot-scope="{ row }"
                            >
                                {{ computeVariationValue(row) }}
                            </span>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { merge } from '../../../utils/index.js';
    import DataTableMixin from '../../../mixins/DataTable.js';
    import DashboardType from '../../../entities/DashboardType.js';
    import { useDashboardStore } from '../../../store/modules/dashboard/store.js';

    export default {
        mixins: [DataTableMixin],

        props: {
            filteredUsers: {
                type: Array,
                required: true,
            },
            loading: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                tables: {
                    phoneUp: [
                        {
                            name: 'leads',
                            total: 0,
                        },
                        {
                            name: 'outgoingCalls',
                            total: 0,
                            ratio: null,
                            objective: 0,
                            variation: null,
                        },
                        {
                            name: 'outgoingEmails',
                            total: 0,
                            ratio: null,
                            objective: 0,
                            variation: null,
                        },
                        {
                            name: 'incomingEmails',
                            total: 0,
                        },
                        {
                            name: 'appointments',
                            total: 0,
                            ratio: null,
                            objective: 0,
                            variation: null,
                        },
                        {
                            name: 'visits',
                            total: 0,
                        },
                        {
                            name: 'sales',
                            total: 0,
                            ratio: null,
                            objective: 0,
                            variation: null,
                        },
                    ],
                    walkIn: [
                        {
                            name: 'leads',
                            total: 0,
                        },
                        {
                            name: 'outgoingCalls',
                            total: 0,
                            ratio: null,
                            objective: 0,
                            variation: null,
                        },
                        {
                            name: 'outgoingEmails',
                            total: 0,
                            ratio: null,
                            objective: 0,
                            variation: null,
                        },
                        {
                            name: 'incomingEmails',
                            total: 0,
                        },
                        {
                            name: 'beBacks',
                            total: 0,
                        },
                        {
                            name: 'sales',
                            total: 0,
                            ratio: null,
                            objective: 0,
                            variation: null,
                        },
                    ],
                    web: [
                        {
                            name: 'leads',
                            total: 0,
                        },
                        {
                            name: 'outgoingCalls',
                            total: 0,
                            ratio: null,
                            objective: 0,
                            variation: null,
                        },
                        {
                            name: 'outgoingEmails',
                            total: 0,
                            ratio: null,
                            objective: 0,
                            variation: null,
                        },
                        {
                            name: 'appointments',
                            total: 0,
                            ratio: null,
                            objective: 0,
                            variation: null,
                        },
                        {
                            name: 'visits',
                            total: 0,
                        },
                        {
                            name: 'sales',
                            total: 0,
                            ratio: null,
                            objective: 0,
                            variation: null,
                        },
                    ],
                    renewal: [
                        {
                            name: 'leads',
                            total: 0,
                        },
                        {
                            name: 'outgoingCalls',
                            total: 0,
                            ratio: null,
                            objective: 0,
                            variation: null,
                        },
                        {
                            name: 'outgoingEmails',
                            total: 0,
                            ratio: null,
                            objective: 0,
                            variation: null,
                        },
                        {
                            name: 'appointments',
                            total: 0,
                            ratio: null,
                            objective: 0,
                            variation: null,
                        },
                        {
                            name: 'visits',
                            total: 0,
                        },
                        {
                            name: 'sales',
                            total: 0,
                            ratio: null,
                            objective: 0,
                            variation: null,
                        },
                    ],
                    total: [
                        {
                            name: 'leads',
                            total: 0,
                        },
                        {
                            name: 'outgoingCalls',
                            total: 0,
                            ratio: null,
                            objective: 0,
                            variation: null,
                        },
                        {
                            name: 'outgoingEmails',
                            total: 0,
                            ratio: null,
                            objective: 0,
                            variation: null,
                        },
                        {
                            name: 'incomingEmails',
                            total: 0,
                        },
                        {
                            name: 'appointments',
                            total: 0,
                            ratio: null,
                            objective: 0,
                            variation: null,
                        },
                        {
                            name: 'visits',
                            total: 0,
                        },
                        {
                            name: 'sales',
                            total: 0,
                            ratio: null,
                            objective: 0,
                            variation: null,
                        },
                    ],
                },
            };
        },

        computed: {
            ...mapState(useDashboardStore, {
                endDate: 'endDate',
                startDate: 'startDate',
            }),
        },

        watch: {
            '$stats.advancedStats': {
                immediate: true,
                handler(advancedStats) {
                    merge(this.tables, advancedStats);
                },
            },
        },

        methods: {
            valueIsSet(value) {
                return typeof value !== 'undefined' && value !== null;
            },

            computeVariationValue(row) {
                if (this.valueIsSet(row.variation)) {
                    return row.variation < 0 ? row.variation : `+${String(row.variation)}`;
                }

                return '-';
            },

            calculateAdvancedStats() {
                if (!this.startDate || !this.endDate || this.$wait.is('fetching.stats')) {
                    return;
                }

                this.$wait.start(`calculating.advancedStats.${DashboardType.ACTIVITY}`);

                this.$stats.calculateStats({
                    action: 'calculateAdvancedStats',
                    dashboard: DashboardType.ACTIVITY,
                    payload: {
                        endDate: this.endDate,
                        startDate: this.startDate,
                        tables: this.tables,
                        users: this.filteredUsers,
                    },
                });
            },
        },
    };
</script>
