var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{ref:"table",staticClass:"w-full border-t-2",attrs:{"border":"","stripe":"","empty-text":_vm.isLoading ? _vm.$t('dashboards.statsTableLoading') : '',"data":_vm.tableData,"default-sort":_vm.sort,"show-summary":true,"summary-method":_vm.formatTotals},on:{"sort-change":_vm.sortChange}},[_c('el-table-column',{attrs:{"label-class-name":"sticky-column"}},[_c('el-table-column',{attrs:{"prop":"name","fixed":true,"header-align":"center","label":_vm.nameColumnText,"min-width":"200","sort-by":"name","sortable":true,"sort-method":_vm.sortNameColumn,"sort-orders":['descending', 'ascending'],"class-name":"sticky-column"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('name-cell',{attrs:{"row":row,"sub-level-key":_vm.filters.subLevelKey,"show-sub-level":_vm.showSubLevel},on:{"toggle-row":_vm.toggleRow}})]}}])})],1),_c('el-table-column',{attrs:{"header-align":"center","label":_vm.$t('dashboards.stats.headers.premium')}},[_c('el-table-column',{attrs:{"prop":"category_insurance","label":_vm.$t('commercial.insurance'),"header-align":"center","align":"center","min-width":"95","sort-by":"category_insurance","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('span',{class:{ 'font-semibold': row.expanded }},[_vm._v(" "+_vm._s(_vm.toMoney(row[column.property]))+" ")]),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey,"money-format":true}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"category_replacement","label":_vm.$t('commercial.replacement'),"header-align":"center","align":"center","min-width":"95","sort-by":"category_replacement","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('span',{class:{ 'font-semibold': row.expanded }},[_vm._v(" "+_vm._s(_vm.toMoney(row[column.property]))+" ")]),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey,"money-format":true}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"category_warranty","label":_vm.$t('commercial.warranty'),"header-align":"center","align":"center","min-width":"95","sort-by":"category_warranty","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('span',{class:{ 'font-semibold': row.expanded }},[_vm._v(" "+_vm._s(_vm.toMoney(row[column.property]))+" ")]),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey,"money-format":true}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"category_premium","label":_vm.$t('dashboards.stats.headers.total'),"header-align":"center","align":"center","min-width":"105","sort-by":"category_premium","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('span',{class:{ 'font-semibold': row.expanded }},[_vm._v(" "+_vm._s(_vm.toMoney(row[column.property]))+" ")]),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey,"money-format":true}}):_vm._e()]}}])})],1),_c('el-table-column',{attrs:{"header-align":"center","label":_vm.$t('dashboards.stats.headers.standard')}},[_c('el-table-column',{attrs:{"prop":"financing","label":_vm.$t('commercial.financing'),"header-align":"center","align":"center","sort-by":"financing","min-width":"105","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('span',{class:{ 'font-semibold': row.expanded }},[_vm._v(" "+_vm._s(_vm.toMoney(row[column.property]))+" ")]),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey,"money-format":true}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"category_anti_thief","label":_vm.$t('commercial.antiThief'),"header-align":"center","align":"center","sort-by":"anti_theft","min-width":"95","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('span',{class:{ 'font-semibold': row.expanded }},[_vm._v(" "+_vm._s(_vm.toMoney(row[column.property]))+" ")]),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey,"money-format":true}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"category_other","label":_vm.$t('commercial.other'),"header-align":"center","align":"center","sort-by":"other","min-width":"95","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('span',{class:{ 'font-semibold': row.expanded }},[_vm._v(" "+_vm._s(_vm.toMoney(row[column.property]))+" ")]),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey,"money-format":true}}):_vm._e()]}}])})],1),_c('el-table-column',{attrs:{"header-align":"center","label":_vm.$t('dashboards.stats.headers.process')}},[_c('el-table-column',{attrs:{"prop":"total_pending","label":_vm.$tc('commercial.pending', 0),"header-align":"center","align":"center","min-width":"110","sort-by":"total_pending","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('span',{class:{ 'font-semibold': row.expanded }},[_vm._v(" "+_vm._s(row[column.property])+" ")]),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"total_approved","label":_vm.$tc('commercial.approved', 0),"header-align":"center","align":"center","min-width":"95","sort-by":"total_approved","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('span',{class:{ 'font-semibold': row.expanded }},[_vm._v(" "+_vm._s(row[column.property])+" ")]),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"total_deliveries","label":_vm.$t('saleTable.table.delivered'),"header-align":"center","align":"center","min-width":"95","sort-by":"total_deliveries","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('span',{class:{ 'font-semibold': row.expanded }},[_vm._v(" "+_vm._s(row[column.property])+" ")]),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey}}):_vm._e()]}}])})],1),_c('el-table-column',{attrs:{"header-align":"center","label":_vm.$t('dashboards.stats.headers.profit')}},[_c('el-table-column',{attrs:{"prop":"total","label":_vm.$t('commercial.profit'),"header-align":"center","align":"center","sort-by":"total","min-width":"95","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('span',{class:{ 'font-semibold': row.expanded }},[_vm._v(" "+_vm._s(_vm.toMoney(row[column.property]))+" ")]),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey,"money-format":true}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"average_profit","label":_vm.$t('commercial.average'),"header-align":"center","align":"center","sort-by":"average_profit","min-width":"115","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('span',{class:{ 'font-semibold': row.expanded }},[_vm._v(" "+_vm._s(_vm.toMoney(row[column.property]))+" ")]),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey,"money-format":true}}):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }