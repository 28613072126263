<template>
    <div>
        <div class="highcharts | w-full lg:w-1/2" ref="byMonth" />
        <div class="highcharts | w-full lg:w-1/2" ref="bySource" />
        <div class="highcharts | w-full lg:w-1/2" ref="byAppointment" />
        <div class="highcharts | w-full lg:w-1/2" ref="bySale" />
        <div class="highcharts | w-full lg:w-1/2" ref="byAdvisor" />
        <div class="highcharts | w-full lg:w-1/2" ref="byModel" />
    </div>
</template>

<script>
    import Highcharts from 'highcharts/highstock.js';

    // Entities
    import { mapState } from 'pinia';
    import Division from '../../../entities/Division.js';

    // Mixins
    import Graphs from '../../../mixins/Graphs.js';

    // Pinia
    import { useContextStore } from '../../../store/modules/context/store.js';
    import { useDashboardStore } from '../../../store/modules/dashboard/store.js';

    export default {
        mixins: [Graphs],

        props: {
            data: {
                type: Object,
                mandatory: true,
            },
        },

        data() {
            return {
                dataPhoneUpCallsNew: [],
                dataPhoneUpCallsUsed: [],
                dataPhoneUpCallsNoDivision: [],
                dataPhoneUpCallsBySource: [],
                dataPhoneUpApptBySources: [],
                dataPhoneUpSalesBySource: [],
                dataPhoneUpCallsByAdvisors: [],
                dataPhoneUpCallsByModels: [],
            };
        },

        computed: {
            ...mapState(useDashboardStore, ['division']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),
        },

        watch: {
            data: {
                immediate: true,
                handler() {
                    this.setData();
                },
            },
        },

        methods: {
            setData() {
                this.dataPhoneUpCallsNew = {
                    labels: [],
                    data: [],
                };

                this.dataPhoneUpCallsUsed = {
                    labels: [],
                    data: [],
                };

                this.dataPhoneUpCallsNoDivision = {
                    labels: [],
                    data: [],
                };

                this.dataPhoneUpCallsBySource = {
                    labels: [],
                    dataUsed: [],
                    dataNew: [],
                };

                this.dataPhoneUpApptBySources = {
                    labels: [],
                    dataUsed: [],
                    dataNew: [],
                };

                this.dataPhoneUpSalesBySource = {
                    labels: [],
                    dataUsed: [],
                    dataNew: [],
                };

                this.dataPhoneUpCallsByAdvisors = {
                    labels: [],
                    dataUsed: [],
                    dataNew: [],
                };

                this.dataPhoneUpCallsByModels = {
                    labels: [],
                    data: [],
                    data2: [],
                };

                const sources = !empty(this.contextAccount) ? this.contextAccount.sources : [];

                if (this.data) {
                    // Phone calls by dates and divisions
                    for (const [key, value] of Object.entries(this.data.phoneUpByDatesAndDivisions.dates)) {
                        this.dataPhoneUpCallsNew.labels.push(key);
                        this.dataPhoneUpCallsUsed.labels.push(key);
                        this.dataPhoneUpCallsNoDivision.labels.push(key);

                        this.dataPhoneUpCallsNew.data.push(value.divisions[Division.NEW] || 0);
                        this.dataPhoneUpCallsUsed.data.push(value.divisions[Division.USED] || 0);
                        this.dataPhoneUpCallsNoDivision.data.push(value.divisions?.none || 0);
                    }

                    // Phone calls by sources and divisions
                    for (const [key, value] of Object.entries(this.data.phoneUpBySourcesAndDivisions.sources)) {
                        const currentSource = sources.find(source => source.id == key);
                        const sourceName = currentSource?.name || this.$t('general.withoutSources');

                        this.dataPhoneUpCallsBySource.labels.push(sourceName);
                        this.dataPhoneUpCallsBySource.dataNew.push(value.divisions[Division.NEW] || 0);
                        this.dataPhoneUpCallsBySource.dataUsed.push(value.divisions[Division.USED] || 0);
                    }

                    // Phone calls by source and divisions with appointments
                    for (const [key, value] of Object.entries(
                        this.data.phoneUpWithAppointmentsBySourcesAndDivisions.sources,
                    )) {
                        const currentSource = sources.find(source => source.id == key);
                        const sourceName = currentSource?.name || this.$t('general.withoutSources');

                        this.dataPhoneUpApptBySources.labels.push(sourceName);
                        this.dataPhoneUpApptBySources.dataNew.push(value.divisions[Division.NEW] || 0);
                        this.dataPhoneUpApptBySources.dataUsed.push(value.divisions[Division.USED] || 0);
                    }

                    // Phone calls by source and divisions with sales
                    for (const [key, value] of Object.entries(
                        this.data.phoneUpWithSalesBySourcesAndDivisions.sources,
                    )) {
                        const currentSource = sources.find(source => source.id == key);
                        const sourceName = currentSource?.name || this.$t('general.withoutSources');

                        this.dataPhoneUpSalesBySource.labels.push(sourceName);
                        this.dataPhoneUpSalesBySource.dataNew.push(value.divisions[Division.NEW] || 0);
                        this.dataPhoneUpSalesBySource.dataUsed.push(value.divisions[Division.USED] || 0);
                    }

                    // Phone calls by users and divisions
                    for (const [key, value] of Object.entries(this.data.phoneUpByUsersAndDivisions.users)) {
                        this.dataPhoneUpCallsByAdvisors.labels.push(key);
                        this.dataPhoneUpCallsByAdvisors.dataNew.push(value.divisions[Division.NEW] || 0);
                        this.dataPhoneUpCallsByAdvisors.dataUsed.push(value.divisions[Division.USED] || 0);
                    }

                    // Phone calls by lead vehicles models with sales and calls
                    for (const [key, value] of Object.entries(this.data.phoneUpWithSalesByModels.models)) {
                        this.dataPhoneUpCallsByModels.labels.push(key);
                        this.dataPhoneUpCallsByModels.data.push(value.with_calls || 0);
                        this.dataPhoneUpCallsByModels.data2.push(value.with_sales || 0);
                    }
                }

                this.$emit('graph-loaded', true);

                this.$nextTick(() => {
                    this.loadGraphs();
                });
            },

            loadGraphs() {
                if (
                    this.dataPhoneUpCallsNew.data.length == 0 &&
                    this.dataPhoneUpCallsUsed.data.length == 0 &&
                    this.dataPhoneUpCallsNoDivision.data.length == 0
                ) {
                    return;
                }

                this.destroyCharts();

                const phoneUpSeries = [];

                if (this.division.includes('new') || empty(this.division)) {
                    phoneUpSeries.push({
                        name: this.$t('graph.new'),
                        data: this.dataPhoneUpCallsNew.data,
                        color: 'rgba(60, 141, 188,1)',
                    });
                }
                if (this.division.includes('used') || empty(this.division)) {
                    phoneUpSeries.push({
                        name: this.$t('graph.used'),
                        data: this.dataPhoneUpCallsUsed.data,
                        color: 'rgba(118, 175, 208,1)',
                    });
                }
                if (this.division.includes('none') || empty(this.division)) {
                    phoneUpSeries.push({
                        name: this.$t('graph.noDivision'),
                        data: this.dataPhoneUpCallsNoDivision.data,
                        color: 'rgba(158,158,158,1)',
                    });
                }

                this.charts.push(
                    Highcharts.chart(this.$refs.byMonth, {
                        chart: {
                            type: 'areaspline',
                        },
                        title: {
                            text: this.$t('graph.numberOfCalls'),
                        },
                        xAxis: {
                            title: {
                                text: this.$t('graph.weeks'),
                            },
                            categories: this.dataPhoneUpCallsNew.labels,
                        },
                        yAxis: {
                            title: {
                                text: this.$t('graph.numberOfCalls'),
                            },
                        },
                        tooltip: {
                            valueSuffix: ` ${this.$t('graph.calls')}`,
                        },
                        plotOptions: {
                            areaspline: {
                                fillOpacity: 0.7,
                            },
                        },
                        series: phoneUpSeries,
                    }),
                );

                this.charts.push(
                    Highcharts.chart(this.$refs.bySource, {
                        chart: {
                            type: 'bar',
                        },
                        title: {
                            text: this.$t('graph.callsBySources'),
                        },
                        xAxis: {
                            title: {
                                text: this.$t('graph.sources'),
                            },
                            categories: this.dataPhoneUpCallsBySource.labels,
                        },
                        yAxis: {
                            title: {
                                text: this.$t('graph.numberOfCalls'),
                            },
                        },
                        series: [
                            {
                                name: this.$t('graph.new'),
                                data: this.dataPhoneUpCallsBySource.dataNew,
                                color: 'rgba(118, 175, 208,1)',
                            },
                            {
                                name: this.$t('graph.used'),
                                data: this.dataPhoneUpCallsBySource.dataUsed,
                                color: 'rgba(158,158,158,1)',
                            },
                        ],
                    }),
                );

                this.charts.push(
                    Highcharts.chart(this.$refs.byAppointment, {
                        chart: {
                            type: 'column',
                        },
                        title: {
                            text: this.$t('graph.callsByAppointments'),
                        },
                        xAxis: {
                            title: {
                                text: this.$t('graph.sources'),
                            },
                            categories: this.dataPhoneUpApptBySources.labels,
                        },
                        yAxis: {
                            title: {
                                text: this.$t('graph.numberOfAppointments'),
                            },
                        },
                        series: [
                            {
                                name: this.$t('graph.new'),
                                data: this.dataPhoneUpApptBySources.dataNew,
                                color: 'rgba(118, 175, 208,1)',
                            },
                            {
                                name: this.$t('graph.used'),
                                data: this.dataPhoneUpApptBySources.dataUsed,
                                color: 'rgba(158,158,158,1)',
                            },
                        ],
                    }),
                );

                this.charts.push(
                    Highcharts.chart(this.$refs.bySale, {
                        chart: {
                            type: 'column',
                        },
                        title: {
                            text: this.$t('graph.salesBySources'),
                        },
                        xAxis: {
                            title: {
                                text: this.$t('graph.sources'),
                            },
                            categories: this.dataPhoneUpSalesBySource.labels,
                        },
                        yAxis: {
                            title: {
                                text: this.$t('graph.numberOfSales'),
                            },
                        },
                        series: [
                            {
                                name: this.$t('graph.new'),
                                data: this.dataPhoneUpSalesBySource.dataNew,
                                color: 'rgba(118, 175, 208,1)',
                            },
                            {
                                name: this.$t('graph.used'),
                                data: this.dataPhoneUpSalesBySource.dataUsed,
                                color: 'rgba(158,158,158,1)',
                            },
                        ],
                    }),
                );

                this.charts.push(
                    Highcharts.chart(this.$refs.byAdvisor, {
                        chart: {
                            type: 'column',
                        },
                        title: {
                            text: this.$t('graph.callsByAdvisors'),
                        },
                        xAxis: {
                            title: {
                                text: this.$t('graph.advisor'),
                            },
                            categories: this.dataPhoneUpCallsByAdvisors.labels,
                        },
                        yAxis: {
                            title: {
                                text: this.$t('graph.numberOfCalls'),
                            },
                        },
                        series: [
                            {
                                name: this.$t('graph.new'),
                                data: this.dataPhoneUpCallsByAdvisors.dataNew,
                                color: 'rgba(118, 175, 208,1)',
                            },
                            {
                                name: this.$t('graph.used'),
                                data: this.dataPhoneUpCallsByAdvisors.dataUsed,
                                color: 'rgba(158,158,158,1)',
                            },
                        ],
                    }),
                );

                this.charts.push(
                    Highcharts.chart(this.$refs.byModel, {
                        chart: {
                            type: 'column',
                        },
                        title: {
                            text: this.$t('graph.callsbyModels'),
                        },
                        xAxis: {
                            title: {
                                text: this.$t('graph.models'),
                            },
                            categories: this.dataPhoneUpCallsByModels.labels,
                        },
                        yAxis: {
                            title: {
                                text: `${this.$t('graph.numberOfCalls')} / ${this.$t('graph.numberOfSales')}`,
                            },
                        },
                        series: [
                            {
                                name: this.$t('graph.numberOfCalls'),
                                data: this.dataPhoneUpCallsByModels.data,
                                color: 'rgba(118, 175, 208,1)',
                            },
                            {
                                name: this.$t('graph.numberOfSales'),
                                data: this.dataPhoneUpCallsByModels.data2,
                                color: 'rgba(158,158,158,1)',
                            },
                        ],
                        legend: {
                            enabled: false,
                        },
                    }),
                );
            },
        },
    };
</script>
