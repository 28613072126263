<template>
    <el-table
        class="w-full border-t-2"
        border
        stripe
        :empty-text="isLoading ? $t('dashboards.statsTableLoading') : ''"
        :data="tableData"
        :default-sort="sort"
        :show-summary="true"
        :summary-method="formatTotals"
        ref="table"
        @sort-change="sortChange"
    >
        <el-table-column label-class-name="sticky-column">
            <el-table-column
                prop="name"
                :fixed="true"
                header-align="center"
                :label="nameColumnText"
                min-width="200"
                sort-by="name"
                :sortable="true"
                :sort-method="sortNameColumn"
                :sort-orders="['descending', 'ascending']"
                class-name="sticky-column"
            >
                <template slot-scope="{ row }">
                    <name-cell
                        :row="row"
                        :sub-level-key="filters.subLevelKey"
                        :show-sub-level="showSubLevel"
                        @toggle-row="toggleRow"
                    />
                </template>
            </el-table-column>
        </el-table-column>
        <el-table-column header-align="center" :label="$t('dashboards.stats.headers.premium')">
            <el-table-column
                prop="category_insurance"
                :label="$t('commercial.insurance')"
                header-align="center"
                align="center"
                min-width="95"
                sort-by="category_insurance"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ toMoney(row[column.property]) }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        :money-format="true"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="category_replacement"
                :label="$t('commercial.replacement')"
                header-align="center"
                align="center"
                min-width="95"
                sort-by="category_replacement"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ toMoney(row[column.property]) }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        :money-format="true"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="category_warranty"
                :label="$t('commercial.warranty')"
                header-align="center"
                align="center"
                min-width="95"
                sort-by="category_warranty"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ toMoney(row[column.property]) }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        :money-format="true"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="category_premium"
                :label="$t('dashboards.stats.headers.total')"
                header-align="center"
                align="center"
                min-width="105"
                sort-by="category_premium"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ toMoney(row[column.property]) }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        :money-format="true"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
        </el-table-column>
        <el-table-column header-align="center" :label="$t('dashboards.stats.headers.standard')">
            <el-table-column
                prop="financing"
                :label="$t('commercial.financing')"
                header-align="center"
                align="center"
                sort-by="financing"
                min-width="105"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ toMoney(row[column.property]) }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        :money-format="true"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="category_anti_thief"
                :label="$t('commercial.antiThief')"
                header-align="center"
                align="center"
                sort-by="anti_theft"
                min-width="95"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ toMoney(row[column.property]) }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        :money-format="true"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="category_other"
                :label="$t('commercial.other')"
                header-align="center"
                align="center"
                sort-by="other"
                min-width="95"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ toMoney(row[column.property]) }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        :money-format="true"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
        </el-table-column>
        <el-table-column header-align="center" :label="$t('dashboards.stats.headers.process')">
            <el-table-column
                prop="total_pending"
                :label="$tc('commercial.pending', 0)"
                header-align="center"
                align="center"
                min-width="110"
                sort-by="total_pending"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="total_approved"
                :label="$tc('commercial.approved', 0)"
                header-align="center"
                align="center"
                min-width="95"
                sort-by="total_approved"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="total_deliveries"
                :label="$t('saleTable.table.delivered')"
                header-align="center"
                align="center"
                min-width="95"
                sort-by="total_deliveries"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ row[column.property] }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
        </el-table-column>
        <el-table-column header-align="center" :label="$t('dashboards.stats.headers.profit')">
            <el-table-column
                prop="total"
                :label="$t('commercial.profit')"
                header-align="center"
                align="center"
                sort-by="total"
                min-width="95"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ toMoney(row[column.property]) }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        :money-format="true"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
            <el-table-column
                prop="average_profit"
                :label="$t('commercial.average')"
                header-align="center"
                align="center"
                sort-by="average_profit"
                min-width="115"
                :sortable="true"
                :sort-orders="['descending', 'ascending']"
            >
                <template slot-scope="{ row, column }">
                    <span :class="{ 'font-semibold': row.expanded }">
                        {{ toMoney(row[column.property]) }}
                    </span>
                    <sub-level-cell
                        :row="row"
                        :column-name="column.property"
                        :sub-level-key="filters.subLevelKey"
                        :money-format="true"
                        v-if="row.expanded"
                    />
                </template>
            </el-table-column>
        </el-table-column>
    </el-table>
</template>

<script>
    // Mixins
    import StatsTableMixin from '../../mixins/StatsTable.js';

    // Components
    import NameCell from './Components/NameCell.vue';
    import SubLevelCell from './Components/SubLevelCell.vue';

    export default {
        components: {
            NameCell,
            SubLevelCell,
        },

        mixins: [StatsTableMixin],

        props: {
            tableData: {
                type: Array,
                default: () => [],
            },
            filters: {
                type: Object,
                default: () => {},
            },
            isLoading: {
                type: Boolean,
                default: false,
            },
            nameColumnText: {
                type: String,
                default: '',
            },
            showActivityColumns: {
                type: Boolean,
                default: false,
            },
            showSubLevel: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                columnsTotals: [
                    {
                        type: 'money',
                        key: 'category_insurance',
                    },
                    {
                        type: 'money',
                        key: 'category_replacement',
                    },
                    {
                        type: 'money',
                        key: 'category_warranty',
                    },
                    {
                        type: 'money',
                        key: 'category_premium',
                    },
                    {
                        type: 'money',
                        key: 'financing',
                    },
                    {
                        type: 'money',
                        key: 'anti_theft',
                    },
                    {
                        type: 'money',
                        key: 'other',
                    },
                    {
                        type: 'money',
                        key: 'total',
                    },
                    {
                        type: 'money_and_average',
                        key: 'average_profit',
                        numerator: 'total',
                        denominator: 'total_leads',
                    },
                ],
            };
        },
    };
</script>
