<template>
    <div>
        <div class="flex flex-wrap lg:-mx-3">
            <card-small
                class="px-3 w-full | xs:w-1/2 | lg:w-1/4"
                :number="cards.sales.number.count"
                :title="$tc('saleTable.cards.sale', cards.sales.number.count)"
                :back-number="totalSalesAndCarryOver"
                :back-title="$tc('saleTable.cards.saleAndCarryOver', totalSalesAndCarryOver)"
            />
            <card-small
                class="px-3 w-full | xs:w-1/2 | lg:w-1/4"
                :number="cards.sales.inStock.count"
                :title="$t('saleTable.inStock')"
            />
            <card-small
                class="px-3 w-full | xs:w-1/2 | lg:w-1/4"
                :number="cards.sales.locate.count"
                :title="$t('saleTable.locates')"
            />
            <card-small
                class="px-3 w-full | xs:w-1/2 | lg:w-1/4"
                :number="cards.sales.order.count"
                :title="$t('saleTable.orders')"
                :back-number="cards.sales.notDelivered.count"
                :back-title="$t('saleTable.ordersNotDelivered')"
            />
        </div>

        <div class="flex flex-wrap lg:-mx-3">
            <card
                class="px-3 w-full | xs:w-1/3 | xl:w-1/5"
                icon="regular/navigation-arrows-left-1"
                :card="cards.sales.carryOver"
                :title="$tc('saleTable.cards.carryOver', cards.sales.carryOver.count)"
            >
                <template #description>
                    <div class="flex justify-center text-lg -mt-1 whitespace-nowrap">
                        <div class="text-right overflow-hidden">
                            <div class="mt-1 truncate">
                                {{
                                    $tc(
                                        'saleTable.cards.carryOverNotDelivered',
                                        cards.sales.carryOverNotDelivered.count,
                                    )
                                }}
                            </div>
                        </div>
                        <div class="text-left ml-3">
                            <div class="mt-1">
                                <span class="font-bold">{{ cards.sales.carryOverNotDelivered.count }}</span>
                            </div>
                        </div>
                    </div>
                </template>
                <template #popover>
                    <div class="text-center" v-text="$t('saleTable.cards.carryOverBack')" />
                </template>
                <activix-tooltip :content="carryOverToggleTooltip">
                    <activix-switcher
                        color="orange"
                        class="absolute right-0 bottom-0 mb-6 mr-9"
                        size="small"
                        :value="options.carryOver"
                        @input="$eventBus.$emit('toggle-option', 'carryOver', $event)"
                    />
                </activix-tooltip>
            </card>
            <card
                class="px-3 w-full | xs:w-1/3 | xl:w-1/5"
                icon="regular/navigation-arrows-right-1"
                :card="cards.sales.postponed"
                :denominator="cards.sales.number.count"
                :title="$tc('saleTable.cards.postponed', cards.sales.postponed.count)"
            >
                <template #description>
                    <div class="text-xl">
                        {{ $t('saleTable.cards.postponedBack') }}
                    </div>
                </template>
                <template #popover>
                    <div class="text-center" v-text="$t('saleTable.cards.postponedTooltip')" />
                </template>
            </card>
            <card
                class="px-3 w-full | xs:w-1/3 | xl:w-1/5"
                icon="regular/calendar-favorite"
                :card="cards.sales.plannedAndInDate"
                :denominator="cards.sales.planned.count"
                :title="$tc('saleTable.cards.planned', cards.sales.plannedAndInDate.count)"
            >
                <template #description>
                    <div class="flex justify-center text-md -mt-1 whitespace-nowrap">
                        <div class="text-right overflow-hidden">
                            <div class="mt-1 truncate">
                                {{ `${$tc('saleTable.cards.delivery.inDate', cards.sales.plannedAndInDate.count)}` }}
                            </div>
                            <div class="mt-1 truncate">
                                {{ `${$tc('saleTable.cards.delivery.inFuture', cards.sales.plannedAndFutureDate.count)}` }}
                            </div>
                        </div>
                        <div class="text-left ml-3">
                            <div class="mt-1">
                                <span class="font-bold">{{ cards.sales.plannedAndInDate.count }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="font-bold">{{ cards.sales.plannedAndFutureDate.count }}</span>
                            </div>
                        </div>
                        <div class="text-left ml-3">
                            <div class="mt-1">
                                <span class="text-grey-600 text-base">{{ toPercentString(cards.sales.plannedAndInDate.count, cards.sales.planned.count) }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-grey-600 text-base">{{ toPercentString(cards.sales.plannedAndFutureDate.count, cards.sales.planned.count) }}</span>
                            </div>
                        </div>
                    </div>
                </template>
                <template #popover>
                    <div
                        class="text-center"
                        v-text="$t('saleTable.cards.plannedTooltip')"
                    />
                </template>
            </card>
            <card
                class="px-3 w-full | xs:w-1/2 | xl:w-1/5"
                :icon="$icons.lost"
                :card="cards.sales.lostAndRefused"
                :denominator="salesWithCarryOverCount"
                :title="`${$tc('saleTable.cards.refused', cards.sales.lostAndRefused.count)} / ${$tc(
                    'saleTable.cards.lost',
                    cards.sales.lostAndRefused.count,
                )}`"
            >
                <template #description>
                    <div class="flex justify-center text-lg -mt-1 whitespace-nowrap">
                        <div class="text-right overflow-hidden">
                            <div class="mt-1 truncate">
                                {{ $tc('saleTable.cards.refused', cards.sales.refused.count) }}
                            </div>
                            <div class="mt-1 truncate">
                                {{ $tc('saleTable.cards.lostByClient', cards.sales.lostSource.client.count) }}
                            </div>
                            <div class="mt-1 truncate">
                                {{ $tc('saleTable.cards.lostByDealer', cards.sales.lostSource.dealer.count) }}
                            </div>
                        </div>
                        <div class="text-left ml-3">
                            <div class="mt-1">
                                <span class="font-bold">{{ cards.sales.refused.count }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="font-bold">{{ cards.sales.lostSource.client.count }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="font-bold">{{ cards.sales.lostSource.dealer.count }}</span>
                            </div>
                        </div>
                    </div>
                </template>
            </card>
            <card
                class="px-3 w-full | xs:w-1/2 | xl:w-1/5"
                :icon="$icons.delivery"
                :card="cards.sales.delivered"
                :value="deliveredCardValue"
                :denominator="salesWithCarryOverCount"
                :title="deliveredOrRecordedCardTitle"
            >
                <template #description>
                    <template v-if="!totalObjective && !hasDiscountedField">
                        <div class="text-xl" v-if="options.recordedDate">
                            {{ $t('saleTable.cards.recordedBack') }}
                        </div>
                        <div class="text-xl" v-else>
                            {{ $t('saleTable.cards.deliveredBack') }}
                        </div>
                    </template>
                    <div class="flex justify-center text-lg -mt-1" v-else>
                        <div class="text-right">
                            <div class="mt-1" v-if="totalObjective">
                                {{ $t('saleTable.objective') }}
                            </div>
                            <div class="mt-1" v-if="hasDiscountedField">
                                {{ $tc('clientCard.discounted', cards.sales.discounted.count) }}
                            </div>
                            <div class="mt-1" v-if="options.recordedDate">
                                {{ $tc('clientCard.reportedSold', cards.sales.recordedSold.count) }}
                            </div>
                            <div class="mt-1" v-if="options.recordedDate">
                                {{ $tc('clientCard.reportedNotSold', cards.sales.recordedNotSold.count) }}
                            </div>
                        </div>
                        <div class="text-left ml-3">
                            <div class="mt-1" v-if="totalObjective">
                                <span class="font-bold">{{ totalObjective }}</span>
                            </div>
                            <div class="mt-1" v-if="hasDiscountedField">
                                <span class="font-bold">{{ cards.sales.discounted.count }}</span>
                            </div>
                            <div class="mt-1" v-if="options.recordedDate">
                                <span class="font-bold">{{ cards.sales.recordedSold.count }}</span>
                            </div>
                            <div class="mt-1" v-if="options.recordedDate">
                                <span class="font-bold">{{ cards.sales.recordedNotSold.count }}</span>
                            </div>
                        </div>
                    </div>
                </template>
                <activix-tooltip :content="recordedDateToggleTooltip">
                    <activix-switcher
                        color="green"
                        class="absolute right-0 bottom-0 mb-6 mr-9"
                        size="small"
                        :value="options.recordedDate"
                        @input="$eventBus.$emit('toggle-option', 'recordedDate', $event)"
                    />
                </activix-tooltip>
            </card>
        </div>

        <div class="box | mb-6">
            <div class="box-header | flex items-center justify-between">
                <h4 class="box-title">
                    {{ $t('dashboards.saleTable') }}
                </h4>
                <div class="flex items-center">
                    <template v-if="expanded.saleTable">
                        <activix-tooltip :content="showAllUsersTooltip">
                            <icon
                                class="text-xl"
                                name="regular/view-1"
                                :class="showAllUsers ? 'link-blue' : 'link-grey'"
                                @click="showAllUsers = !showAllUsers"
                            />
                        </activix-tooltip>
                        <icon
                            class="link-grey text-sm ml-2"
                            :name="view == 'table' ? 'bold/analytics-bars-horizontal' : 'bold/layout-module'"
                            @click="toggleView"
                        />
                    </template>

                    <button class="ml-3 link-grey" @click="$eventBus.$emit('toggle-expanded', 'saleTable')">
                        <icon :name="$icons.expand" v-if="!expanded.saleTable" />
                        <icon
                            class="spin-inverse"
                            :name="$icons.loading"
                            v-else-if="saleReportIsLoading || gridReportIsLoading"
                        />
                        <icon :name="$icons.collapse" v-else />
                    </button>
                </div>
            </div>
            <div
                class="box-body p-0"
                :class="{ loading: saleReportIsLoading || gridReportIsLoading }"
                v-show="expanded.saleTable"
            >
                <sale-report
                    :render="expanded.saleTable"
                    :users="formattedUsers"
                    :show-all-users="showAllUsers"
                    ref="saleReport"
                    v-show="view == 'table'"
                />
                <grid-report
                    :render="expanded.saleTable"
                    :users="formattedUsers"
                    :show-all-users="showAllUsers"
                    ref="gridReport"
                    v-show="view == 'grid'"
                />
            </div>
        </div>

        <div class="box | mb-6" v-if="shouldShowProfitAndAverage">
            <div class="box-header | flex items-center justify-between">
                <h4 class="box-title">
                    {{ $t('dashboards.profitAndAverage') }}
                </h4>
                <div class="flex items-center justify-center">
                    <div class="justify-center items-center flex" v-if="expanded.saleProfit">
                        <activix-tooltip :content="disabledTooltip || toggleProfitReferenceDateTooltip">
                            <activix-switcher
                                color="blue"
                                class="block"
                                size="small"
                                :value="saleProfitByDeliveredDate"
                                :disabled="options.recordedDate"
                                @input="toggleSaleProfitReferenceDate"
                            />
                        </activix-tooltip>
                    </div>
                    <div class="ml-3 link-grey" @click="$eventBus.$emit('toggle-expanded', 'saleProfit')">
                        <icon :name="$icons.expand" v-if="!expanded.saleProfit" />
                        <icon class="spin-inverse" :name="$icons.loading" v-else-if="profitAndAverageIsLoading" />
                        <icon :name="$icons.collapse" v-else />
                    </div>
                </div>
            </div>

            <div class="box-body p-0" :class="{ loading: profitAndAverageIsLoading }" v-show="expanded.saleProfit">
                <profit-and-average
                    :render="expanded.saleProfit"
                    :users="formattedUsers"
                    :profit-date="profitDate"
                    ref="profitAndAverage"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { cloneDeep } from 'lodash-es';

    import { toPercentString } from '@/utils/numbers';
    import { mapState } from 'pinia';

    // Components
    import Card from '../../components/dashboards/cards/Card.vue';
    import CardSmall from '../../components/dashboards/cards/CardSmall.vue';
    import SaleReport from '../../components/sale_table/SaleReport.vue';
    import GridReport from '../../components/sale_table/GridReport.vue';
    import ProfitAndAverage from '../../components/sale_table/ProfitAndAverage.vue';

    // Entities
    import SaleTableProfitReferenceDate from '../../entities/SaleTableProfitReferenceDate.js';
    import DashboardType from '../../entities/DashboardType.js';
    import User from '../../entities/User.js';

    // Value Objects
    import ActivixDate from '../../value-objects/ActivixDate.js';

    // Mixins
    import TrackView from '../../mixins/TrackView.js';
    import DashboardCaching from '../../mixins/DashboardCaching.js';
    import DashboardCustomFields from '../../mixins/DashboardCustomFields.js';
    import DashboardColumns from '../../mixins/DashboardColumns.js';

    // Pinia
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        name: 'SaleTable',

        components: {
            Card,
            CardSmall,
            SaleReport,
            ProfitAndAverage,
            GridReport,
        },

        mixins: [TrackView, DashboardCaching, DashboardCustomFields, DashboardColumns],

        data() {
            return {
                dashboard: DashboardType.SALE,
                users: [],
                showAllUsers: this.$ls.get('showUserWithoutSale', false),
                view: 'grid',
                cards: {
                    saleByPhone: {
                        count: 0,
                    },
                    sales: {
                        recordedSold: {
                            count: 0,
                        },
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                        waitingSale: {
                            count: 0,
                        },
                        inStock: {
                            count: 0,
                        },
                        locate: {
                            count: 0,
                        },
                        order: {
                            count: 0,
                        },
                        carryOver: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                        postponed: {
                            count: 0,
                            colors: {
                                0: 'blue',
                            },
                        },
                        planned: {
                            count: 0,
                        },
                        plannedAndInDate: {
                            count: 0,
                            colors: {
                                0: 'blue',
                            },
                        },
                        plannedAndFutureDate: {
                            count: 0,
                        },
                        carryOverNotDelivered: {
                            count: 0,
                        },
                        refused: {
                            count: 0,
                            colors: {
                                0: 'red',
                            },
                        },
                        lostAndRefused: {
                            count: 0,
                            colors: {
                                0: 'red',
                            },
                        },
                        lostSource: {
                            client: {
                                count: 0,
                            },
                            dealer: {
                                count: 0,
                            },
                        },
                        delivered: {
                            count: 0,
                            colors: {
                                0: 'green',
                            },
                        },
                        notDelivered: {
                            count: 0,
                        },
                        recordedNotSold: {
                            count: 0,
                        },
                        discounted: {
                            count: 0,
                        },
                    },
                },
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),
            ...mapState(useDashboardStore, {
                division: 'division',
                expanded: store => store.configs.expanded,
                filters: store => store.configs.filters,
                options: store => store.configs.options,
            }),

            saleProfitByDeliveredDate() {
                if (this.options.recordedDate) {
                    return false;
                }

                const option = this.options.saleTableProfitByDeliveredDate;

                if (option === undefined) {
                    return true;
                }

                return option;
            },

            hasDiscountedField() {
                return this.contextAccount.hasProcessField('discounted');
            },

            profitAndAverageIsLoading() {
                return this.$wait.is(['fetching.objectives', 'fetching.profitAndAverage']);
            },

            profitDate() {
                if (!this.saleProfitByDeliveredDate) {
                    return this.options.recordedDate ? SaleTableProfitReferenceDate.RECORDED_DATE : SaleTableProfitReferenceDate.SALE_DATE;
                }

                return SaleTableProfitReferenceDate.DELIVERED_DATE;
            },

            saleReportIsLoading() {
                return this.view == 'table' && this.$wait.is(['fetching.objectives', 'fetching.saleReport']);
            },

            gridReportIsLoading() {
                return this.view == 'grid' && this.$wait.is(['fetching.objectives', 'fetching.gridReport']);
            },

            totalSalesAndCarryOver() {
                return this.cards.sales.number.count + this.cards.sales.carryOver.count;
            },

            totalReported() {
                return this.cards.sales.recordedSold.count + this.cards.sales.recordedNotSold.count;
            },

            carryOverToggleTooltip() {
                if (this.options.carryOver) {
                    return this.$t('saleTable.carryOverTooltipOff');
                }

                return this.$t('saleTable.carryOverTooltipOn');
            },

            shouldShowProfitAndAverage() {
                if (!this.authUser.isBdc() && !this.authUser.isBdcDirector()) {
                    return true;
                }

                if (this.contextAccount.sale_table_options && this.contextAccount.sale_table_options.hide_profit) {
                    return false;
                }

                return true;
            },

            recordedDateToggleTooltip() {
                if (this.options.recordedDate) {
                    return this.$t('saleTable.recordedDateTooltipOff');
                }

                return this.$t('saleTable.recordedDateTooltipOn');
            },

            showAllUsersTooltip() {
                if (this.showAllUsers) {
                    return this.$t('saleTable.displayUsersWithSaleOrObjectivesEyeToolTip');
                }

                return this.$t('saleTable.displayAllUsersEyeBlueToolTip');
            },

            disabledTooltip() {
                if (this.options.recordedDate) {
                    return this.$t('saleTable.profitAndAverage.tooltip.disabledByRecordedDate');
                }

                return '';
            },

            toggleProfitReferenceDateTooltip() {
                if (this.saleProfitByDeliveredDate) {
                    return this.options.recordedDate
                        ? this.$t('saleTable.profitAndAverage.tooltip.byRecordedDate')
                        : this.$t('saleTable.profitAndAverage.tooltip.bySaleDate');
                }

                return this.$t('saleTable.profitAndAverage.tooltip.byDeliveredDate');
            },

            salesWithCarryOverCount() {
                return this.cards.sales.number.count + (this.options.carryOver ? this.cards.sales.carryOver.count : 0);
            },

            deliveredCardValue() {
                let value = this.cards.sales.delivered.count;

                if (this.options.recordedDate) {
                    value = this.totalReported;
                }

                return this.totalObjective ? `${value} / ${this.totalObjective}` : value;
            },

            reachedObjective() {
                return this.totalObjective && this.cards.sales.delivered.count >= this.totalObjective;
            },

            deliveredOrRecordedCardTitle() {
                if (this.options.recordedDate) {
                    return this.$tc('saleTable.cards.recorded', this.cards.sales.delivered.count);
                }

                return this.$tc('saleTable.cards.delivered', this.cards.sales.delivered.count);
            },

            totalObjective() {
                return this.formattedUsers.filter(user => user.active).reduce((total, user) => {
                    const count = user.currentObjectives.reduce((sales, objective) => {
                        return sales + objective.sale_amount;
                    }, 0);

                    return total + count;
                }, 0);
            },

            formattedUsers() {
                const noAdvisorUser = {
                    id: 'none',
                    first_name: this.$t('saleTable.noAdvisorFistName'),
                    last_name: this.$t('saleTable.noAdvisorLastName'),
                    divisions: [{ name: 'new' }, { name: 'used' }],
                    objectives: [],
                };

                const users = cloneDeep(this.users);
                users.push(noAdvisorUser);

                return users
                    .filter(user => {
                        return (
                            this.contextAccount.users.some(accountUser => accountUser.id == user.id) ||
                            user.id == 'none'
                        );
                    })
                    .map(user => {
                        return new User({
                            ...user,
                            currentObjectives: user.objectives.filter(objective => {
                                return this.isValidObjective(objective);
                            }),
                        });
                    });
            },
        },

        watch: {
            'contextAccount.id': {
                immediate: true,
                handler() {
                    this.updateOrderTotal();
                },
            },

            showAllUsers(newValue) {
                this.$ls.set('showUserWithoutSale', newValue);
            },
        },

        methods: {
            toPercentString(value, total) {
                return `(${toPercentString(value, total)})`;
            },

            toggleSaleProfitReferenceDate(isDeliveredDate) {
                this.$eventBus.$emit('toggle-option', 'saleTableProfitByDeliveredDate', isDeliveredDate);
            },

            async fetchStats(options) {
                await Promise.all([
                    this.fetchUsers(options),
                    this.$refs.gridReport.fetch(options),
                    this.$refs.saleReport.fetch(options),
                    this.$refs.profitAndAverage.fetch(options),
                ]);
            },

            isValidObjective(objective) {
                if (objective.type != 'saleTable') {
                    return false;
                }

                if (!empty(this.division) && !this.division.some(d => d == objective.division.name)) {
                    return false;
                }

                const definedDate = new ActivixDate(objective.defined_date);
                const monthStart = definedDate.setDate(this.contextAccount.month_start_day).startOfDay();
                const monthEnd = definedDate
                    .setDate(this.contextAccount.month_start_day)
                    .addMonths()
                    .subDays()
                    .endOfDay();

                return monthStart.isSameOrBefore(this.parsedStartDate) && monthEnd.isSameOrAfter(this.parsedEndDate);
            },

            updateOrderTotal() {
                if (!this.contextAccount.id) {
                    return;
                }

                this.$axios.get(`v1/accounts/${this.contextAccount.id}/orders`).then(response => {
                    this.cards.sales.notDelivered.count = response.data.data;
                });
            },

            toggleView() {
                if (this.view == 'table') {
                    this.view = 'grid';
                } else {
                    this.view = 'table';
                }
            },

            async fetchUsers({ background = false } = {}) {
                if (!this.contextAccount.id) {
                    return;
                }

                if (!background) {
                    this.$wait.start('fetching.objectives');
                }

                try {
                    const users = await this.$api.users.index({
                        filter: {
                            accountId: this.contextAccount.id,
                        },
                        include: ['objectives.division', 'divisions'],
                    });

                    this.users = users;
                } catch (error) {
                    this.$notify.error(this.$t('objectives.alerts.index.error'));

                    throw error;
                } finally {
                    this.$wait.end('fetching.objectives');
                }
            },

            setColumns() {
                useDashboardStore().configs.columns = {
                    bulk_select: {
                        visible: true,
                    },
                    fullname: {
                        visible: true,
                    },
                    civility: {
                        visible: false,
                    },
                    business: {
                        visible: false,
                    },
                    'customer:casl_consent_status': {
                        visible: false,
                    },
                    'customer:casl_consent_limit_date': {
                        visible: false,
                    },
                    date: {
                        title: this.$t('clientCard.sold'),
                        visible: true,
                    },
                    updated_at: {
                        visible: false,
                    },
                    'division:name': {
                        visible: true,
                    },
                    status: {
                        visible: true,
                    },
                    'lead_type:name': {
                        visible: true,
                    },
                    advisors: {
                        visible: true,
                    },
                    agents: {
                        visible: true,
                    },
                    'commercial:': {
                        visible: true,
                    },
                    'delivered_by:': {
                        visible: true,
                    },
                    rating: {
                        visible: false,
                    },
                    city: {
                        visible: false,
                    },
                    country: {
                        visible: false,
                    },
                    province: {
                        visible: false,
                    },
                    postal_code: {
                        visible: false,
                    },
                    address: {
                        visible: false,
                    },
                    locale: {
                        visible: false,
                    },
                    sector: {
                        visible: true,
                    },
                    budget: {
                        visible: true,
                    },
                    walk_around: {
                        visible: false,
                    },
                    'wanted_vehicles:budget_min': {
                        visible: false,
                    },
                    'wanted_vehicles:budget_max': {
                        visible: false,
                    },
                    created_method: {
                        visible: false,
                    },
                    created_by: {
                        visible: false,
                    },
                    updated_by: {
                        visible: false,
                    },
                    lead_state: {
                        visible: false,
                    },
                    'import:original_file': {
                        visible: false,
                    },
                    'wanted_vehicles:category': {
                        visible: false,
                    },
                    'wanted_vehicles:category_rv': {
                        visible: false,
                    },
                    'wanted_vehicles:mechanical': {
                        visible: false,
                    },
                    'wanted_vehicles:length_min': {
                        visible: false,
                    },
                    'wanted_vehicles:length_max': {
                        visible: false,
                    },
                    'wanted_vehicles:weight': {
                        visible: false,
                    },
                    'wanted_vehicles:fuel': {
                        visible: false,
                    },
                    'wanted_vehicles:make': {
                        visible: false,
                    },
                    'wanted_vehicles:model': {
                        visible: false,
                    },
                    'wanted_vehicles:year': {
                        visible: false,
                    },
                    'wanted_vehicles:version': {
                        visible: false,
                    },
                    'wanted_vehicles:certified': {
                        visible: false,
                    },
                    'wanted_vehicles:transmission': {
                        visible: false,
                    },
                    'wanted_vehicles:color_exterior': {
                        visible: true,
                    },
                    'wanted_vehicles:color_interior': {
                        visible: false,
                    },
                    'wanted_vehicles:stock_state': {
                        visible: true,
                    },
                    'wanted_vehicles:stock': {
                        visible: true,
                    },
                    'wanted_vehicles:profit': {
                        visible: false,
                    },
                    'wanted_vehicles:vin': {
                        visible: false,
                    },
                    'wanted_vehicles:modality': {
                        visible: false,
                    },
                    'wanted_vehicles:suffix': {
                        visible: true,
                    },
                    'wanted_vehicles:offer': {
                        visible: true,
                    },
                    'wanted_vehicles:tire': {
                        visible: false,
                    },
                    approved: {
                        visible: true,
                    },
                    'lead_bank:name': {
                        visible: false,
                    },
                    waiting_sale: {
                        visible: false,
                    },
                    progress_state: {
                        visible: true,
                    },
                    deposit: {
                        visible: false,
                    },
                    long_term: {
                        visible: false,
                    },
                    write_up: {
                        visible: false,
                    },
                    sale_by_phone: {
                        visible: false,
                    },
                    deliverable: {
                        visible: false,
                    },
                    delivery: {
                        visible: true,
                    },
                    available: {
                        visible: true,
                    },
                    paperwork: {
                        visible: true,
                    },
                    'wanted_vehicles:verified': {
                        visible: false,
                    },
                    prepared: {
                        visible: true,
                    },
                    prepared_work_order: {
                        visible: false,
                    },
                    inspected: {
                        visible: true,
                    },
                    gas: {
                        visible: true,
                    },
                    delivered: {
                        visible: true,
                    },
                    'wanted_vehicles:recorded_date': {
                        visible: true,
                    },
                    discounted_date: {
                        visible: false,
                    },
                    csi: {
                        visible: true,
                    },
                    sale_state: {
                        visible: true,
                    },
                    'exchange_vehicles:category': {
                        visible: false,
                    },
                    'exchange_vehicles:category_rv': {
                        visible: false,
                    },
                    'exchange_vehicles:mechanical': {
                        visible: false,
                    },
                    'exchange_vehicles:length_min': {
                        visible: false,
                    },
                    'exchange_vehicles:length_max': {
                        visible: false,
                    },
                    'exchange_vehicles:weight': {
                        visible: false,
                    },
                    'exchange_vehicles:make': {
                        visible: false,
                    },
                    'exchange_vehicles:model': {
                        visible: false,
                    },
                    'exchange_vehicles:year': {
                        visible: false,
                    },
                    'exchange_vehicles:version': {
                        visible: false,
                    },
                    'exchange_vehicles:certified': {
                        visible: false,
                    },
                    'exchange_vehicles:color_exterior': {
                        visible: false,
                    },
                    'exchange_vehicles:color_interior': {
                        visible: false,
                    },
                    'exchange_vehicles:condition': {
                        visible: false,
                    },
                    'exchange_vehicles:fuel': {
                        visible: false,
                    },
                    'exchange_vehicles:tire_type': {
                        visible: false,
                    },
                    'exchange_vehicles:accidented_damage_cost': {
                        visible: false,
                    },
                    'exchange_vehicles:value': {
                        visible: false,
                    },
                    'exchange_vehicles:actual_value': {
                        visible: false,
                    },
                    'exchange_vehicles:calculator_appraiser': {
                        visible: false,
                    },
                    'exchange_vehicles:calculator_value': {
                        visible: false,
                    },
                    followed: {
                        visible: true,
                    },
                    followed_by: {
                        visible: false,
                    },
                    source: {
                        visible: false,
                    },
                    form: {
                        visible: false,
                    },
                    'latest_comment:content': {
                        visible: false,
                    },
                    unsubscribe: {
                        visible: false,
                    },
                    birth_date: {
                        visible: true,
                    },
                    ...this.getCustomFields(),
                };
            },

            setCards(results) {
                this.cards.sales.waitingSale.count = results.waitingSales;

                this.cards.sales.locate.count = results.stockStates.locate;
                this.cards.sales.order.count = results.stockStates.order;
                this.cards.sales.inStock.count = results.stockStates.stock;

                this.cards.sales.number.count = results.sales.count;
                this.cards.saleByPhone.count = results.sales.byPhone;

                this.cards.sales.carryOver.count = results.carryOvers.count;
                this.cards.sales.carryOverNotDelivered.count = results.carryOvers.notDelivered;

                this.cards.sales.recordedSold.count = results.recorded;

                this.cards.sales.delivered.count = results.deliveries.count;
                this.cards.sales.discounted.count = results.deliveries.discounted;
                this.cards.sales.recordedNotSold.count = results.recordedNotSold;

                this.cards.sales.planned.count = results.planned.count;
                this.cards.sales.plannedAndInDate.count = results.planned.inDate;
                this.cards.sales.plannedAndFutureDate.count = results.planned.futureDate;
                this.cards.sales.postponed.count = results.postponed;

                this.cards.sales.refused.count = results.refused;

                this.cards.sales.lostSource.client.count = results.losses.byClient;
                this.cards.sales.lostSource.dealer.count = results.losses.byDealer;

                this.cards.sales.lostAndRefused.count = results.losses.count + results.refused;

                this.$nextTick(() => {
                    this.setCaching('cards', this.cards);
                });
            },
        },

        beforeDestroy() {
            this.$wait.end('fetching.objectives');
        },
    };
</script>
