<template>
    <table class="table table-bordered table-striped activix-table">
        <thead>
            <tr v-if="!empty(headers)">
                <th
                    class="text-center"
                    :class="column.class || ''"
                    :colspan="column.colspan"
                    :key="index"
                    v-for="(column, index) in headers"
                >
                    <activix-tooltip :content="column.tooltip">
                        <icon :name="column.icon" v-if="column.type == 'icon'" />
                        <span v-else>{{ capitalizedTrans(column) }}</span>
                    </activix-tooltip>
                </th>
            </tr>

            <tr v-if="!empty(columns)">
                <th
                    class="cursor-pointer"
                    :class="{
                        'sorting-asc': sortOrders[column.name] > 0 && column.name == sortKey,
                        'sorting-desc': sortOrders[column.name] <= 0 && column.name == sortKey,
                    }"
                    :key="index"
                    @click="sortBy(column.name, column.type)"
                    v-for="(column, index) in columns"
                >
                    <activix-tooltip :content="column.tooltip">
                        {{ capitalizedTrans(column) }}
                    </activix-tooltip>
                </th>
            </tr>
        </thead>

        <tbody>
            <tr v-if="empty(data)">
                <td :colspan="columns.length" class="text-center">
                    {{ $t('dashboards.noData') }}
                </td>
            </tr>
            <template v-else>
                <tr :key="entryIndex" v-for="(entry, entryIndex) in filteredSortedData">
                    <activix-tooltip :content="column.subTooltip" :key="index" v-for="(column, index) in columns">
                        <td
                            :class="[!empty(column.class) ? column.class : '']"
                            v-html="formatValue(entry[column.name], column.type)"
                        />
                    </activix-tooltip>
                </tr>
            </template>
        </tbody>

        <tfoot v-if="!empty(footers)">
            <tr>
                <activix-tooltip
                    :content="footers[column.name].tooltip || ''"
                    :key="index"
                    v-for="(column, index) in columns"
                >
                    <th
                        class="text-center"
                        :class="footers[column.name].class || ''"
                        v-html="footers[column.name].value"
                    />
                </activix-tooltip>
            </tr>
        </tfoot>
    </table>
</template>

<script>
    import { camelCase, cloneDeep, has } from 'lodash-es';

    export default {
        props: {
            data: Array,
            columns: Array,
            filterKey: String,
            headers: Array,
            footers: Object,
            transTable: String,
            sortByKey: String,
        },

        data() {
            const sortOrders = {};
            const sortTypes = {};

            $.each(this.columns, (index, col) => {
                sortOrders[col.name] = 1;
                sortTypes[col.name] = '';
            });

            return {
                sortKey: '',
                sortOrders,
                sortTypes,
            };
        },

        computed: {
            filteredSortedData() {
                return cloneDeep(this.data).sort((a, b) => {
                    const aValue = has(a, `${this.sortKey}_raw`) ? a[`${this.sortKey}_raw`] : a[this.sortKey];
                    const bValue = has(b, `${this.sortKey}_raw`) ? b[`${this.sortKey}_raw`] : b[this.sortKey];

                    if (this.sortTypes[this.sortKey] == 'string') {
                        if (this.sortOrders[this.sortKey] > 0) {
                            return `${aValue}`.localeCompare(`${bValue}`);
                        }

                        return `${bValue}`.localeCompare(`${aValue}`);
                    }

                    if (this.sortOrders[this.sortKey] > 0) {
                        return aValue - bValue;
                    }

                    return bValue - aValue;
                });
            },
        },
        watch: {
            columns() {
                const sortOrders = {};

                $.each(this.columns, (index, col) => {
                    sortOrders[col.name] = 1;
                });

                this.sortOrders = sortOrders;
            },
        },

        methods: {
            formatValue(value, type) {
                if (type == 'currency') {
                    return this.toMoney(value, 0);
                }

                return value;
            },

            sortBy(key, type) {
                this.sortKey = key;
                this.sortTypes[key] = type || '';
                this.sortOrders[key] *= -1;
            },

            trans(key) {
                const formattedKey = camelCase(key);
                const value = this.$t(`${this.transTable}.${formattedKey}`);

                if (value === 'null') {
                    return '';
                }

                return value;
            },

            capitalizedTrans(column) {
                const translationKey = column.customTranslationKey || column.name;

                if (empty(translationKey) || translationKey === null || translationKey === undefined) {
                    return '';
                }

                const translation = this.trans(translationKey);

                if (translation.length > 0) {
                    return translation[0].toUpperCase() + translation.slice(1);
                }

                return '';
            },
        },

        mounted() {
            this.$nextTick(() => {
                this.sortBy(this.sortByKey);
            });
        },
    };
</script>
