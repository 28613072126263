<template>
    <div>
        <div class="flex flex-wrap lg:-mx-3">
            <card-small
                class="px-3 w-full | lg:w-1/3"
                :number="cards.number.count"
                :title="$tc('webBoost.leads', cards.number.count)"
                :back-number="cards.assigned.count"
                :back-title="$tc('dashboards.assignedLead', cards.assigned.count)"
            />
            <card-small
                class="px-3 w-full | lg:w-1/3"
                :back-icon="$icons.phone"
                :number="cards.validPhone.count"
                :title="$tc('webBoost.validPhone', cards.validPhone.count)"
            >
                <template #description>
                    <span>
                        <span class="font-bold">{{ validPhonePercent }}</span>
                        {{ $t('webBoost.validPhoneBack') }}
                    </span>
                </template>
            </card-small>
            <card-small
                class="px-3 w-full | lg:w-1/3"
                :back-icon="$icons.appointment"
                :number="cards.businessHours.count"
                :title="$tc('webBoost.businessHours', cards.businessHours.count)"
            >
                <template #description>
                    <span>
                        <span class="font-bold">{{ inBusinessHoursPercent }}</span>
                        {{ $t('webBoost.businessHoursBack') }}
                    </span>
                </template>
            </card-small>
        </div>

        <div class="flex flex-wrap lg:-mx-3">
            <card
                class="px-3 w-full | xs:w-1/3 | xl:w-1/5"
                :icon="$icons.attempted"
                :card="cards.leadsTreated"
                :denominator="cards.businessHours.count"
                :title="$tc('webBoost.leadsTreated', cards.leadsTreated.count)"
            >
                <template #description>
                    <div class="text-xl">
                        {{ $t('webBoost.leadsTreatedBack') }}
                    </div>
                </template>
            </card>
            <card
                class="px-3 w-full | xs:w-1/3 | xl:w-1/5"
                :icon="$icons.reached"
                :card="cards.leadsReached"
                :denominator="cards.leadsTreated.count"
                :title="$tc('webBoost.leadsReached', cards.leadsReached.count)"
            >
                <template #description>
                    <div class="text-xl">
                        {{ $t('webBoost.leadsReachedBack') }}
                    </div>
                </template>
            </card>
            <card
                class="px-3 w-full | xs:w-1/3 | xl:w-1/5"
                type="time"
                :icon="$icons.time"
                :card="cards.reachedTime"
                :title="$t('webBoost.reachedTime')"
            >
                <template #description>
                    <div class="text-xl">
                        {{ $t('webBoost.reachedTimeBack') }}
                    </div>
                </template>
            </card>
            <card
                class="px-3 w-full | xs:w-1/2 | xl:w-1/5"
                :icon="$icons.appointment"
                :card="appointmentValue"
                :denominator="cards.leadsReached.count"
                :title-icon="options.phoneAppointment ? $icons.phoneAppointment : ''"
                :title="$tc('webBoost.appointment', appointmentValue.count)"
            >
                <template #description>
                    <div class="flex justify-center text-lg -mt-1">
                        <template v-if="options.phoneAppointment">
                            <div class="text-right">
                                <div class="flex items-center mt-1">
                                    {{ $tc('leadXpress.appointment', cards.phoneAppointment.count) }}
                                    <icon class="text-base ml-2" :name="$icons.phoneAppointment" />
                                </div>
                                <div class="mt-1">
                                    {{ $tc('leadXpress.appointment', cards.appointment.count) }}
                                </div>
                                <div class="mt-1">
                                    {{ $t('general.total') }}
                                </div>
                            </div>
                            <div class="text-left ml-3">
                                <div class="mt-1">
                                    <span class="font-bold">{{ cards.phoneAppointment.count }}</span>
                                </div>
                                <div class="mt-1">
                                    <span class="font-bold">{{ cards.appointment.count }}</span>
                                </div>
                                <div class="mt-1">
                                    <span class="font-bold">
                                        {{ cards.phoneAppointment.count + cards.appointment.count }}
                                    </span>
                                </div>
                            </div>
                            <div class="text-left ml-3">
                                <div class="mt-1">
                                    <span class="text-grey-600 text-base">({{ phoneApptPercent }})</span>
                                </div>
                                <div class="mt-1">
                                    <span class="text-grey-600 text-base">({{ regularApptPercent }})</span>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="text-right">
                                <div class="mt-1" v-if="contextAccount.confirmation_appt_sale">
                                    {{ $tc('result.confirmed', cards.appointment.confirmed) }}
                                </div>
                                <div class="mt-1">
                                    {{ $t('result.noShow') }}
                                </div>
                                <div class="mt-1">
                                    {{ $tc('result.canceled', cards.appointment.canceled) }}
                                </div>
                            </div>
                            <div class="text-left ml-3">
                                <div class="mt-1" v-if="contextAccount.confirmation_appt_sale">
                                    <span class="font-bold">{{ cards.appointment.confirmed }}</span>
                                </div>
                                <div class="mt-1">
                                    <span class="font-bold">{{ cards.appointment.noShow }}</span>
                                </div>
                                <div class="mt-1">
                                    <span class="font-bold">{{ cards.appointment.canceled }}</span>
                                </div>
                            </div>
                            <div class="text-left ml-3">
                                <div class="mt-1">
                                    <span class="text-grey-600 text-base">({{ confirmedApptPercent }})</span>
                                </div>
                                <div class="mt-1">
                                    <span class="text-grey-600 text-base">({{ noShowApptPercent }})</span>
                                </div>
                                <div class="mt-1">
                                    <span class="text-grey-600 text-base">({{ canceledApptPercent }})</span>
                                </div>
                            </div>
                        </template>
                    </div>
                </template>
                <activix-tooltip :content="phoneAppointmentToggleTooltip">
                    <activix-switcher
                        color="grey"
                        class="absolute right-0 bottom-0 mb-6 mr-9"
                        size="small"
                        :value="options.phoneAppointment"
                        @input="$eventBus.$emit('toggle-option', 'phoneAppointment', $event)"
                    />
                </activix-tooltip>
            </card>
            <card
                class="px-3 w-full | xs:w-1/2 | xl:w-1/5"
                :icon="$icons.sale"
                :card="cards.sale"
                :denominator="cards.leadsReached.count"
                :title="$tc('webBoost.sale', cards.sale.count)"
            >
                <template #description>
                    <div class="text-xl">
                        {{ $t('webBoost.saleBack') }}
                    </div>
                    <div class="flex justify-center text-lg mt-2" v-if="contextAccount.sale_by_phone">
                        <div class="text-right">
                            <div class="mt-1">
                                {{ $tc('result.saleByPhone', cards.saleByPhone.count) }}
                            </div>
                        </div>
                        <div class="text-left ml-3">
                            <div class="mt-1">
                                <span class="font-bold">{{ cards.saleByPhone.count }}</span>
                            </div>
                        </div>
                    </div>
                </template>
            </card>
        </div>

        <stats-table :dashboard="dashboard" ref="statsTable" />
    </div>
</template>

<script>
    // Utils
    import { mapState } from 'pinia';
    import { toPercentString } from '../../utils/numbers.js';

    // Components
    import Card from '../../components/dashboards/cards/Card.vue';
    import CardSmall from '../../components/dashboards/cards/CardSmall.vue';
    import StatsTable from '../StatsTables/StatsTable.vue';

    // Mixins
    import TrackView from '../../mixins/TrackView.js';
    import DashboardCaching from '../../mixins/DashboardCaching.js';
    import DashboardCustomFields from '../../mixins/DashboardCustomFields.js';

    // Entities
    import DashboardType from '../../entities/DashboardType.js';

    // Pinia
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        name: 'WebBoost',

        components: {
            Card,
            CardSmall,
            StatsTable,
        },

        mixins: [TrackView, DashboardCaching, DashboardCustomFields],

        data() {
            return {
                dashboard: DashboardType.WEB_BOOST,
                cards: {
                    number: {
                        count: 0,
                        colors: {
                            0: 'grey',
                        },
                    },
                    assigned: {
                        count: 0,
                        colors: {
                            0: 'grey',
                        },
                    },
                    validPhone: {
                        count: 0,
                        colors: {
                            0: 'grey',
                        },
                    },
                    businessHours: {
                        count: 0,
                        colors: {
                            0: 'grey',
                        },
                    },
                    leadsTreated: {
                        count: 0,
                        colors: {
                            0: 'grey',
                            1: 'red',
                            15: 'orange',
                            25: 'green',
                        },
                    },
                    leadsReached: {
                        count: 0,
                        colors: {
                            0: 'blue',
                        },
                    },
                    reachedTime: {
                        count: 0,
                        colors: {
                            0: 'grey',
                        },
                    },
                    appointment: {
                        count: 0,
                        confirmed: 0,
                        noShow: 0,
                        canceled: 0,
                        colors: {
                            0: 'grey',
                            1: 'red',
                            15: 'orange',
                            25: 'green',
                        },
                    },
                    phoneAppointment: {
                        count: 0,
                        colors: {
                            0: 'grey',
                            1: 'red',
                            15: 'orange',
                            25: 'green',
                        },
                    },
                    sale: {
                        count: 0,
                        colors: {
                            0: 'grey',
                            1: 'red',
                            10: 'orange',
                            12: 'green',
                        },
                    },
                    saleByPhone: {
                        count: 0,
                    },
                },
            };
        },

        computed: {
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),
            ...mapState(useDashboardStore, {
                options: store => store.configs.options,
            }),

            phoneApptPercent() {
                return toPercentString(
                    this.cards.phoneAppointment.count,
                    this.cards.phoneAppointment.count + this.cards.appointment.count,
                );
            },

            regularApptPercent() {
                return toPercentString(
                    this.cards.appointment.count,
                    this.cards.phoneAppointment.count + this.cards.appointment.count,
                );
            },

            confirmedApptPercent() {
                return toPercentString(this.cards.appointment.confirmed, this.cards.appointment.count);
            },

            noShowApptPercent() {
                return toPercentString(this.cards.appointment.noShow, this.cards.appointment.count);
            },

            canceledApptPercent() {
                return toPercentString(this.cards.appointment.canceled, this.cards.appointment.count);
            },

            appointmentValue() {
                if (this.options.phoneAppointment) {
                    return this.cards.phoneAppointment;
                }

                return this.cards.appointment;
            },

            phoneAppointmentToggleTooltip() {
                if (this.options.phoneAppointment) {
                    return this.$t('loyalty.appointmentCallTooltipOn');
                }

                return this.$t('loyalty.appointmentCallTooltipOff');
            },

            validPhonePercent() {
                return toPercentString(this.cards.validPhone.count, this.cards.number.count);
            },

            inBusinessHoursPercent() {
                return toPercentString(this.cards.businessHours.count, this.cards.validPhone.count);
            },

            processedThroughWebBoostPercent() {
                return toPercentString(this.cards.leadsTreated.count, this.cards.businessHours.count);
            },

            reachedWithWebBoostPercent() {
                return toPercentString(this.cards.leadsReached.count, this.cards.leadsTreated.count);
            },

            confirmedAppointmentPercent() {
                return toPercentString(this.cards.appointment.count, this.cards.leadsReached.count);
            },

            salePercent() {
                return toPercentString(this.cards.sale.count, this.cards.leadsReached.count);
            },
        },

        methods: {
            async fetchStats(options) {
                await this.$refs.statsTable.fetchStats(options);
            },

            setColumns() {
                useDashboardStore().configs.columns = {
                    bulk_select: {
                        visible: true,
                    },
                    fullname: {
                        visible: true,
                    },
                    contact_method: {
                        visible: true,
                    },
                    civility: {
                        visible: false,
                    },
                    business: {
                        visible: false,
                    },
                    created_by: {
                        visible: false,
                    },
                    updated_by: {
                        visible: false,
                    },
                    'customer:casl_consent_status': {
                        visible: false,
                    },
                    'customer:casl_consent_limit_date': {
                        visible: false,
                    },
                    date: {
                        visible: true,
                    },
                    updated_at: {
                        visible: false,
                    },
                    'division:name': {
                        visible: true,
                    },
                    status: {
                        visible: true,
                    },
                    result_wb: {
                        visible: true,
                    },
                    'lead_type:name': {
                        visible: false,
                    },
                    advisors: {
                        visible: true,
                    },
                    agents: {
                        visible: true,
                    },
                    'commercial:': {
                        visible: false,
                    },
                    'account:name': {
                        visible: true,
                    },
                    rating: {
                        visible: false,
                    },
                    city: {
                        visible: false,
                    },
                    country: {
                        visible: false,
                    },
                    province: {
                        visible: false,
                    },
                    postal_code: {
                        visible: false,
                    },
                    address: {
                        visible: false,
                    },
                    locale: {
                        visible: false,
                    },
                    sector: {
                        visible: false,
                    },
                    budget: {
                        visible: false,
                    },
                    walk_around: {
                        visible: false,
                    },
                    created_method: {
                        visible: false,
                    },
                    lead_state: {
                        visible: false,
                    },
                    'import:original_file': {
                        visible: false,
                    },
                    'wanted_vehicles:make': {
                        visible: true,
                    },
                    'wanted_vehicles:model': {
                        visible: true,
                    },
                    'wanted_vehicles:year': {
                        visible: true,
                    },
                    'wanted_vehicles:fuel': {
                        visible: false,
                    },
                    'wanted_vehicles:version': {
                        visible: false,
                    },
                    'wanted_vehicles:certified': {
                        visible: false,
                    },
                    'wanted_vehicles:transmission': {
                        visible: false,
                    },
                    'wanted_vehicles:color_exterior': {
                        visible: false,
                    },
                    'wanted_vehicles:color_interior': {
                        visible: false,
                    },
                    'wanted_vehicles:stock_state': {
                        visible: false,
                    },
                    'wanted_vehicles:stock': {
                        visible: true,
                    },
                    'wanted_vehicles:vin': {
                        visible: false,
                    },
                    phone_appointment: {
                        visible: true,
                    },
                    appointment: {
                        visible: true,
                    },
                    presented: {
                        visible: true,
                    },
                    road_test: {
                        visible: true,
                    },
                    long_term: {
                        visible: false,
                    },
                    dealer_tour: {
                        visible: false,
                    },
                    take_over: {
                        visible: false,
                    },
                    'take_over_director:': {
                        visible: false,
                    },
                    twenty_four_hour: {
                        visible: true,
                    },
                    be_back: {
                        visible: false,
                    },
                    deposit: {
                        visible: false,
                    },
                    write_up: {
                        visible: false,
                    },
                    progress_state: {
                        visible: true,
                    },
                    approved: {
                        visible: true,
                    },
                    'lead_bank:name': {
                        visible: false,
                    },
                    waiting_sale: {
                        visible: false,
                    },
                    sale_by_phone: {
                        visible: false,
                    },
                    sale: {
                        visible: true,
                    },
                    available: {
                        visible: false,
                    },
                    paperwork: {
                        visible: false,
                    },
                    prepared: {
                        visible: true,
                    },
                    prepared_work_order: {
                        visible: false,
                    },
                    gas: {
                        visible: false,
                    },
                    deliverable: {
                        visible: false,
                    },
                    delivery: {
                        visible: false,
                    },
                    delivered: {
                        visible: false,
                    },
                    'wanted_vehicles:recorded_date': {
                        visible: false,
                    },
                    csi: {
                        visible: false,
                    },
                    'exchange_vehicles:make': {
                        visible: false,
                    },
                    'exchange_vehicles:model': {
                        visible: false,
                    },
                    'exchange_vehicles:year': {
                        visible: false,
                    },
                    'exchange_vehicles:version': {
                        visible: false,
                    },
                    'exchange_vehicles:certified': {
                        visible: false,
                    },
                    'exchange_vehicles:transmission': {
                        visible: false,
                    },
                    'exchange_vehicles:color_exterior': {
                        visible: false,
                    },
                    'exchange_vehicles:color_interior': {
                        visible: false,
                    },
                    'exchange_vehicles:condition': {
                        visible: false,
                    },
                    'exchange_vehicles:fuel': {
                        visible: false,
                    },
                    'exchange_vehicles:tire_type': {
                        visible: false,
                    },
                    'exchange_vehicles:accidented_damage_cost': {
                        visible: false,
                    },
                    'exchange_vehicles:value': {
                        visible: false,
                    },
                    'exchange_vehicles:actual_value': {
                        visible: false,
                    },
                    'exchange_vehicles:calculator_appraiser': {
                        visible: false,
                    },
                    'exchange_vehicles:calculator_value': {
                        visible: false,
                    },
                    listening: {
                        visible: true,
                    },
                    followed: {
                        visible: true,
                    },
                    followed_by: {
                        visible: false,
                    },
                    todo: {
                        visible: true,
                    },
                    source: {
                        visible: true,
                    },
                    form: {
                        visible: false,
                    },
                    'latest_comment:content': {
                        visible: true,
                    },
                    unsubscribe: {
                        visible: false,
                    },
                    birth_date: {
                        visible: false,
                    },
                    ...this.getCustomFields(),
                };
            },

            setCards(results) {
                this.cards.number.count = results.count;
                this.cards.assigned.count = results.assignments;

                this.cards.validPhone.count = results.communications.count;
                this.cards.businessHours.count = results.communications.inHours;
                this.cards.leadsTreated.count = results.communications.treated;
                this.cards.leadsReached.count = results.communications.reached;
                this.cards.reachedTime.count = results.communications.responseTime;

                this.cards.phoneAppointment.count = results.phoneAppointments;
                this.cards.appointment.count = results.appointments.count;
                this.cards.appointment.noShow = results.appointments.noShow;
                this.cards.appointment.confirmed = results.appointments.confirmed;
                this.cards.appointment.canceled = results.appointments.canceled;

                this.cards.sale.count = results.sales.count;
                this.cards.saleByPhone.count = results.sales.byPhone;

                this.$nextTick(() => {
                    this.setCaching('cards', this.cards);
                });
            },
        },
    };
</script>
