<template>
    <div class="overflow-x-auto">
        <activix-table
            class="border-t-2"
            sort-by-key="last_month"
            trans-table="saleTable.table"
            :columns="tableColumns"
            :data="tableRows"
            :footers="tableFooter"
            :headers="tableHeader"
            v-if="render"
        />
    </div>
</template>

<script>
    // Utils
    import { get } from 'lodash-es';

    import { mapState } from 'pinia';

    // Components
    import ActivixTable from '../dashboards/Table.vue';

    // Mixins
    import DashboardColumns from '../../mixins/DashboardColumns.js';

    // Entities
    import DashboardType from '../../entities/DashboardType.js';

    // Pinia
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            ActivixTable,
        },

        mixins: [DashboardColumns],

        props: {
            profitDate: {
                type: String,
                required: true,
            },
            users: {
                type: Array,
                required: true,
            },
            render: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                results: [],
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
                contextTeam: 'contextTeam',
            }),
            ...mapState(useDashboardStore, {
                expanded: store => store.configs.expanded,
                options: store => store.configs.options,
            }),

            formattedResults() {
                return this.users
                    .map(user => {
                        const result = this.results.find(result => result.id == user.id);

                        const objectiveProfit = user.currentObjectives.reduce((total, objective) => {
                            return total + objective.sale_amount;
                        }, 0);

                        const objectiveAverage = user.currentObjectives.reduce((total, objective) => {
                            return total + objective.average_value;
                        }, 0);

                        return {
                            user,
                            sold: get(result, 'total_sales', 0),
                            tire_sold: get(result, 'sold_tires', 0),
                            accessory_profit: get(result, 'vehicle_accessories', 0),
                            objective_profit: objectiveProfit,
                            objective_average: objectiveAverage,
                            sale_profit: get(result, 'vehicle_profit', 0),
                            commercial_profit: get(result, 'commercial_profit', 0),
                            last_month: get(result, 'last_month_sales', 0),
                        };
                    })
                    .filter(result => {
                        if (this.hideProfits) {
                            return this.authUser.id == result.user.id;
                        }

                        return result.sold > 0 || result.objective_sales > 0;
                    });
            },

            hideProfits() {
                return (
                    this.authUser.isAdvisor() &&
                    this.contextAccount.sale_table_options &&
                    this.contextAccount.sale_table_options.hide_profit
                );
            },

            tableColumns() {
                const columns = [
                    {
                        name: 'last_month',
                        class: 'text-center',
                    },
                    {
                        name: 'user',
                        type: 'string',
                    },
                ];

                if (this.contextAccount.sale_table_options && this.contextAccount.sale_table_options.accessory_column) {
                    if (this.contextAccount.client_card_fields.wanted_vehicle.tire) {
                        columns.push({
                            name: 'tire_percent',
                            class: 'text-center',
                        });
                    }

                    columns.push(
                        {
                            name: 'accessory_profit',
                            type: 'currency',
                            class: 'text-center',
                        },
                        {
                            name: 'accessory_average',
                            type: 'currency',
                            class: 'text-center',
                        },
                    );
                }

                columns.push(
                    {
                        name: 'objective_profit',
                        class: 'text-center',
                    },
                    {
                        name: 'objective_average',
                        type: 'currency',
                        class: 'text-center',
                    },
                );

                columns.push(
                    {
                        name: 'sales',
                        class: 'text-center',
                        customTranslationKey: this.profitDate === 'delivered_date' ? 'delivered' : 'sales',
                    },
                    {
                        name: 'sale_profit',
                        type: 'currency',
                        class: 'text-center',
                    },
                    {
                        name: 'sale_average',
                        type: 'currency',
                        class: 'text-center',
                    },
                );

                if (
                    this.contextAccount.sale_table_options &&
                    this.contextAccount.sale_table_options.commercial_column &&
                    this.contextAccount.commercial
                ) {
                    columns.push(
                        {
                            name: 'commercial_profit',
                            type: 'currency',
                            class: 'text-center',
                        },
                        {
                            name: 'commercial_average',
                            type: 'currency',
                            class: 'text-center',
                        },
                    );
                }

                return columns;
            },

            tableRows() {
                return this.formattedResults.map(result => {
                    const userStat = {
                        last_month: result.last_month,
                        user: result.user.fullName,
                        sales: result.sold,
                    };

                    if (
                        this.contextAccount.sale_table_options &&
                        this.contextAccount.sale_table_options.accessory_column
                    ) {
                        if (this.contextAccount.client_card_fields.wanted_vehicle.tire) {
                            userStat.tire_percent_raw = result.tire_sold;
                            userStat.tire_percent = `${result.tire_sold} (${result.tire_sold}/${result.sold})`;
                        }

                        userStat.accessory_profit = result.accessory_profit;
                        userStat.accessory_average = result.sold > 0 ? result.accessory_profit / result.sold : 0;
                    }

                    userStat.objective_profit =
                        result.objective_profit != 'N/A' ? result.objective_profit : result.objective_profit;
                    userStat.objective_average =
                        result.objective_average != 'N/A' ? result.objective_average : result.objective_average;
                    userStat.sale_profit = result.sale_profit;
                    userStat.sale_average = result.sold > 0 ? result.sale_profit / result.sold : 0;

                    if (
                        this.contextAccount.sale_table_options &&
                        this.contextAccount.sale_table_options.commercial_column &&
                        this.contextAccount.commercial
                    ) {
                        userStat.commercial_profit = result.commercial_profit;
                        userStat.commercial_average = result.sold > 0 ? result.commercial_profit / result.sold : 0;
                    }

                    return userStat;
                });
            },

            tableFooter() {
                const footer = {
                    last_month: {
                        value: 0,
                    },
                    user: {
                        value: this.$t('saleTable.table.total'),
                    },
                };

                if (this.contextAccount.sale_table_options && this.contextAccount.sale_table_options.accessory_column) {
                    if (this.contextAccount.client_card_fields.wanted_vehicle.tire) {
                        footer.tire_percent = {
                            value: 0,
                        };
                    }

                    footer.accessory_profit = {
                        value: 0,
                        tooltip: this.$t('saleTable.tooltips.accessoryProfit'),
                    };

                    footer.accessory_average = {
                        value: 0,
                        tooltip: this.$t('saleTable.tooltips.accessoryAverage'),
                    };
                }

                footer.objective_profit = {
                    value: 0,
                };

                footer.objective_average = {
                    value: 0,
                };

                footer.sales = {
                    value: 0,
                };

                footer.sale_profit = {
                    value: 0,
                };

                footer.sale_average = {
                    value: 0,
                };

                if (
                    this.contextAccount.sale_table_options &&
                    this.contextAccount.sale_table_options.commercial_column &&
                    this.contextAccount.commercial
                ) {
                    footer.commercial_profit = {
                        value: 0,
                        tooltip: this.$t('saleTable.tooltips.commercialProfit'),
                    };

                    footer.commercial_average = {
                        value: 0,
                        tooltip: this.$t('saleTable.tooltips.commercialAverage'),
                    };
                }

                let totalSaleProfit = 0;
                let totalObjectiveProfit = 0;
                let totalCommercialProfit = 0;
                let totalAccessoryProfit = 0;
                let totalSold = 0;
                let totalObjectiveAverage = 0;
                let totalTireSold = 0;
                let totalLastMonth = 0;
                let cntNa = 0;

                this.formattedResults.forEach(result => {
                    totalSaleProfit += result.sale_profit;

                    if (result.objective_profit != 'N/A') {
                        totalObjectiveProfit += result.objective_profit;
                    } else {
                        cntNa++;
                    }

                    totalObjectiveAverage += result.objective_average;
                    totalCommercialProfit += result.commercial_profit;
                    totalAccessoryProfit += result.accessory_profit;
                    totalTireSold += result.tire_sold;
                    totalSold += result.sold;
                    totalLastMonth += result.last_month;
                });

                totalSaleProfit = Math.round(totalSaleProfit);
                totalObjectiveProfit = Math.round(totalObjectiveProfit);
                totalObjectiveAverage = Math.round(totalObjectiveAverage);
                totalCommercialProfit = Math.round(totalCommercialProfit);
                totalAccessoryProfit = Math.round(totalAccessoryProfit);
                totalTireSold = Math.round(totalTireSold);

                footer.last_month.value = totalLastMonth;
                footer.sale_profit.value = this.toMoney(totalSaleProfit, 0);
                footer.sale_average.value =
                    totalSold > 0 ? this.toMoney(totalSaleProfit / totalSold, 0) : this.toMoney(0, 0);

                if (cntNa != this.formattedResults.length) {
                    footer.objective_profit.value = totalObjectiveProfit;
                    footer.objective_average.value = this.toMoney(totalObjectiveAverage, 0);
                } else {
                    footer.objective_profit.value = 'N/A';
                    footer.objective_average.value = 'N/A';
                }

                if (
                    this.contextAccount.sale_table_options &&
                    this.contextAccount.sale_table_options.commercial_column &&
                    this.contextAccount.commercial
                ) {
                    footer.commercial_profit.value = this.toMoney(totalCommercialProfit, 0);
                    footer.commercial_average.value =
                        totalSold > 0 ? this.toMoney(totalCommercialProfit / totalSold, 0) : this.toMoney(0, 0);
                }

                if (this.contextAccount.sale_table_options && this.contextAccount.sale_table_options.accessory_column) {
                    if (this.contextAccount.client_card_fields.wanted_vehicle.tire) {
                        footer.tire_percent.value = `${totalTireSold} (${totalTireSold}/${totalSold})`;
                    } else {
                        footer.sales.value = totalSold;
                    }

                    footer.accessory_profit.value = this.toMoney(totalAccessoryProfit, 0);
                    footer.accessory_average.value =
                        totalSold > 0 ? this.toMoney(totalAccessoryProfit / totalSold, 0) : this.toMoney(0, 0);
                }

                footer.sales.value = totalSold;

                return footer;
            },

            tableHeader() {
                let widthLeft = 'width-40';
                let widthRight = 'width-40';
                const header = [
                    {
                        name: 'lastMonthIcon',
                        colspan: 1,
                        class: 'width-2',
                        type: 'icon',
                        tooltip: this.$t('saleTable.lastMonthSales'),
                        icon: 'light/trophy',
                    },
                    {
                        name: 'advisors',
                        colspan: 1,
                        type: 'string',
                        class: 'width-18',
                    },
                ];

                if (this.contextAccount.sale_table_options && this.contextAccount.sale_table_options.accessory_column) {
                    widthLeft = 'width-20';

                    header.push({
                        name: 'accessory_only',
                        colspan: this.contextAccount.client_card_fields.wanted_vehicle.tire ? 3 : 2,
                        class: 'width-20',
                    });
                }

                if (
                    this.contextAccount.sale_table_options &&
                    this.contextAccount.sale_table_options.commercial_column &&
                    this.contextAccount.commercial
                ) {
                    widthRight = 'width-20';
                }

                header.push(
                    {
                        name: 'objective',
                        colspan: 2,
                        class: `${widthLeft}`,
                    },
                    {
                        name: 'sales',
                        colspan: 3,
                        class: `${widthRight}`,
                    },
                );

                if (
                    this.contextAccount.sale_table_options &&
                    this.contextAccount.sale_table_options.commercial_column &&
                    this.contextAccount.commercial
                ) {
                    header.push({
                        name: 'commercial',
                        colspan: 2,
                        class: 'width-20',
                    });
                }

                return header;
            },
        },

        watch: {
            'expanded.saleProfit': {
                immediate: true,
                handler() {
                    this.fetch();
                },
            },

            profitDate() {
                if (this.expanded.saleProfit) {
                    this.fetch();
                }
            },
        },

        methods: {
            async fetch({ background = false } = {}) {
                if (!this.expanded.saleProfit) {
                    return;
                }

                if (!background) {
                    this.$wait.start('fetching.profitAndAverage');
                }

                try {
                    const response = await this.$api.dashboard.getProfitAndAverageReport({
                        dashboard: DashboardType.SALE,
                        endDate: this.parsedEndDate.toDateTimeString(),
                        startDate: this.parsedStartDate.toDateTimeString(),
                        options: this.options,
                        accountId: this.contextAccount.id,
                        filters: this.activeDashboardFilters,
                        mainAggregation: 'user_id',
                        teamId: this.contextTeam.id,
                        profitDate: this.profitDate,
                    });

                    if (response) {
                        this.results = response;

                        this.$wait.end('fetching.profitAndAverage');
                    }
                } catch (error) {
                    this.$notify.warning(this.$t('dashboards.alerts.stats.error'));
                    this.$wait.end('fetching.profitAndAverage');
                }
            },
        },
    };
</script>
