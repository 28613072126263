<template>
    <component
        id="table-leads"
        :is="component"
        class="table-striped table-bordered | h-full border-t-2"
        key-field="id"
        :items="hideRows ? [emptyLead] : tableLeads"
        :item-size="85"
        :page-mode="true"
        :class="{ 'no-checkbox': !bulkEditVisible }"
        :buffer="hideRows ? 200 : Math.max(tableLeads.length * 85 / 25, 10 * 85)"
    >
        <template #before>
            <div
                class="lead-table-header | sticky overflow-x-auto"
                data-scroll="hidden"
                :style="headerStyle"
                ref="header"
            >
                <div class="table-header-row" v-if="defer(1)">
                    <div
                        class="table-header-cell | relative flex items-center justify-center p-2"
                        :class="[
                            column.class,
                            {
                                'sorting-asc': pagination.orderBy == column.name && pagination.sortBy == 'asc',
                                'sorting-desc': pagination.orderBy == column.name && pagination.sortBy == 'desc',
                                'cursor-pointer': !column.fixed && !column.disableSorting,
                            },
                        ]"
                        :data-column="`col-${column.name}`"
                        :style="column.style"
                        :key="column.name"
                        @click="!column.fixed && !column.disableSorting ? sort(column.name) : ''"
                        v-for="column in activeDashboardColumns"
                    >
                        <activix-tooltip :content="column.tooltip">
                            <activix-checkbox
                                size="xs"
                                :disabled="$wait.is('fetching.leadTable')"
                                data-intercom-target="massAction.selectPage"
                                :value="allLeadsOnPageSelected || allLeadsInTableSelected"
                                @click.native.prevent="selectAll"
                                v-if="column.name == 'bulk_select'"
                            />
                            <div :class="{ truncate: column.truncating }" v-html="column.title" v-else />
                        </activix-tooltip>
                    </div>
                </div>
            </div>
            <div class="overflow-x-auto" data-scroll="hidden" ref="filter">
                <lead-filter
                    :filters-buffer="filtersBuffer"
                    :filter-applied="filterApplied"
                    :filtered="filtered"
                    @update:filter="updateFilter"
                    @update:filters="updateFilters"
                    v-if="defer(1)"
                />
            </div>
            <slot name="header" />
        </template>

        <template #default="{ item: lead }">
            <lead-table-row
                :class="{ 'opacity-0 pointer-events-none': hideRows }"
                :lead="lead"
                :active-dashboard-columns="activeDashboardColumns"
                :parsed-start-date="parsedStartDate"
                :parsed-end-date="parsedEndDate"
                @toggle-bulk-select="toggleBulkSelect"
                v-if="defer(1)"
            />
        </template>
    </component>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { isEqual } from 'lodash-es';
    import StickyScroll from '@/plugins/sticky-scroll.js';
    import SyncScroll from '@/plugins/sync-scroll.js';
    import { isSm } from '@/utils/layout.js';
    import Defer from '@/mixins/Defer.js';
    import DashboardColumns from '@/mixins/DashboardColumns.js';
    import DashboardType from '@/entities/DashboardType.js';
    import Lead from '@/entities/Lead.js';
    import ActivixDate from '@/value-objects/ActivixDate.js';

    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useLayoutStore } from '../../store/modules/layout/store.js';
    import { useGlobalStore } from '../../store/store.js';

    import LeadFilter from './LeadTableFilter.vue';
    import LeadTableRow from './LeadTableRow.vue';
    import LeadTableWrapper from './LeadTableWrapper.vue';

    export default {
        components: {
            LeadFilter,
            LeadTableRow,
            LeadTableWrapper,
        },

        mixins: [DashboardColumns, Defer()],

        props: {
            bulkEditVisible: {
                type: Boolean,
                default: false,
            },
            filterApplied: {
                type: Boolean,
                required: true,
            },
            filtered: {
                type: Boolean,
                default: false,
            },
            filtersBuffer: {
                type: Object,
                required: true,
            },
            hideRows: {
                type: Boolean,
                default: false,
            },
            allLeadsOnPageSelected: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                body: null,
                stickyScroll: null,
                syncScroll: null,
            };
        },

        computed: {
            ...mapState(useLayoutStore, ['bodySpacingTop', 'headerHeight']),
            ...mapState(useGlobalStore, {
                session: store => store.parentAuthUser.session,
            }),
            ...mapState(useContextStore, {
                contextAccount: 'account',
                contextGroup: 'group',
                contextUser: 'user',
                contextTeam: 'contextTeam',
            }),
            ...mapState(useDashboardStore, {
                dashboardDisabledForGroups: 'dashboardDisabledForGroups',
                division: 'division',
                blackListedLeadIds: 'blackListedLeadIds',
                bulkSelect: 'bulkSelect',
                dashboardConfigs: 'configs',
                tableLeads: 'tableLeads',
                dashboardType: 'dashboardType',
                endDate: 'endDate',
                filteredCampaigns: 'filteredCampaigns',
                filteredDates: 'filteredDates',
                pagination: 'pagination',
                startDate: 'startDate',
                allLeadsInTableSelected: 'allLeadsInTableSelected',
            }),

            component() {
                return this.pagination.perPage > 25 ? 'activix-recycle-scroller' : 'lead-table-wrapper';
            },

            emptyLead() {
                return new Lead({ id: 0, account_id: this.contextAccount.id });
            },

            headerStyle() {
                const top = this.headerHeight + this.bodySpacingTop;

                return {
                    top: `${top}px`,
                };
            },
        },

        watch: {
            'dashboardConfigs.filters': {
                immediate: true,
                handler(newFilters, oldFilters) {
                    if (!isEqual(newFilters, oldFilters)) {
                        this.updateFilters(newFilters);
                    }
                },
            },

            async activeDashboardColumns(newValue, oldValue) {
                if (newValue.length === oldValue.length) {
                    return;
                }

                await this.$nextTick();

                if (this.stickyScroll) {
                    this.stickyScroll.update();
                }
            },

            component: {
                immediate: true,
                handler() {
                    this.resetTableLeads();
                },
            },
        },

        methods: {
            ...mapActions(useDashboardStore, [
                'setAllLeadsInTableSelected',
                'setBulkSelect',
                'setDashboardTableLeads',
                'setDashboardConfigs',
                'updatePagination',
            ]),
            ...mapActions(useGlobalStore, ['updateSessionAction']),

            async resetTableLeads() {
                await this.$nextTick();

                if (this.component === 'activix-recycle-scroller') {
                    this.body = this.$el.querySelector('.vue-recycle-scroller__item-wrapper');
                } else {
                    this.body = this.$el.querySelector('.lead-table-wrapper');
                }

                this.destroyScroll();
                this.initScroll();
            },

            selectAll() {
                if (this.allLeadsInTableSelected) {
                    this.setAllLeadsInTableSelected(false);
                    return;
                }

                const bulkSelectWithoutCurrentTable = this.bulkSelect.filter(lead => {
                    return !this.tableLeads.some(tableLead => tableLead.id == lead.id);
                });

                if (this.allLeadsOnPageSelected) {
                    this.setBulkSelect([]);
                } else {
                    this.setBulkSelect([...bulkSelectWithoutCurrentTable, ...this.tableLeads]);
                }
            },

            sort(column) {
                if (column == this.pagination.orderBy) {
                    this.updatePagination('sortBy', this.pagination.sortBy == 'asc' ? 'desc' : 'asc');
                }

                this.updatePagination('orderBy', column);

                this.$nextTick(() => {
                    const order = {
                        orderBy: this.pagination.orderBy,
                        sortBy: this.pagination.sortBy,
                    };

                    this.setDashboardConfigs({ order });
                    this.updateSessionAction('order', order);
                    this.triggerFetch({ page: 1 });
                });
            },

            updateFilters(filters) {
                this.$emit('update:filters', filters);
            },

            updateFilter(column, value) {
                this.$emit('update:filter', column, value);
            },

            toggleBulkSelect(event) {
                const { lead, checked } = event;

                if (this.allLeadsInTableSelected) {
                    useDashboardStore().blackListedLeadIds = Object.freeze(checked
                        ? this.blackListedLeadIds.filter(selectedLeadId => selectedLeadId !== lead.id)
                        : [...this.blackListedLeadIds, lead.id]);

                    if (this.pagination.total === this.blackListedLeadIds.length) {
                        this.setAllLeadsInTableSelected(false);
                    }

                    return;
                }

                this.setBulkSelect(
                    checked
                        ? [...this.bulkSelect, lead]
                        : this.bulkSelect.filter(selectedLead => selectedLead.id !== lead.id),
                );
            },

            initScroll() {
                if (!this.dashboardConfigs.expanded.leadTable) {
                    return;
                }

                this.stickyScroll = new StickyScroll({
                    element: this.body,
                    offset: document.querySelector('.lead-table-footer').offsetHeight,
                    className: 'z-10',
                });

                this.syncScroll = new SyncScroll([
                    this.body,
                    this.$refs.header,
                    this.$refs.filter,
                ]);
            },

            destroyScroll() {
                if (this.stickyScroll) {
                    this.stickyScroll.destroy();
                    this.stickyScroll = null;
                }

                if (this.syncScroll) {
                    this.syncScroll.destroy();
                    this.syncScroll = null;
                }
            },

            async triggerFetch(options = {}) {
                if (
                    this.dashboardDisabledForGroups ||
                    this.dashboardType == DashboardType.ACTIVITY ||
                    !this.startDate ||
                    !this.endDate ||
                    !this.dashboardConfigs.expanded.leadTable
                ) {
                    return;
                }

                this.setBulkSelect([]);

                this.$wait.start('fetching.leadTable');
                this.$wait.end('indicator.leadTable');

                if (isSm) {
                    options.perPage = 10;
                }

                if (!options.perPage) {
                    options.perPage = this.pagination.perPage || this.session.per_page || 10;
                }

                if (!options.page) {
                    options.page = this.pagination.currentPage;
                }

                this.updatePagination('orderBy', this.dashboardConfigs.order.orderBy);
                this.updatePagination('sortBy', this.dashboardConfigs.order.sortBy);

                await this.$nextTick();
                await this.fetchLeads(options);
            },

            async fetchLeads(options) {
                try {
                    const response = await this.$api.dashboard.getLeads({
                        accountId: this.contextAccount.id,
                        dashboard: this.dashboardType,
                        divisions: this.division,
                        endDate: new ActivixDate(this.endDate).endOfDay().toDateTimeString(),
                        filteredCampaigns: this.filteredCampaigns,
                        filteredDates: this.filteredDates,
                        filters: this.activeDashboardFilters,
                        groupId: this.contextGroup.id,
                        options: this.dashboardConfigs.options,
                        orderBy: this.pagination.orderBy,
                        page: options.page,
                        perPage: options.perPage,
                        sortBy: this.pagination.sortBy,
                        startDate: new ActivixDate(this.startDate).startOfDay().toDateTimeString(),
                        teamId: this.contextTeam.id,
                        userId: this.contextUser.id,
                    });

                    if (response) {
                        this.updatePagination('currentPage', response.meta.current_page);
                        this.updatePagination('perPage', response.meta.per_page);
                        this.updatePagination('from', response.meta.from);
                        this.updatePagination('to', response.meta.to);
                        this.updatePagination('lastPage', response.meta.last_page);
                        this.updatePagination('total', response.meta.total);

                        this.setDashboardTableLeads(response.data);

                        this.$wait.end('fetching.leadTable');
                    }
                } catch (error) {
                    this.$notify.warning(this.$t('dashboards.alerts.leads.error'));
                    this.$wait.end('fetching.leadTable');
                }
            },
        },

        created() {
            this.$eventBus.$on('fetch-table-leads', this.triggerFetch);

            this.triggerFetch();
        },

        async beforeDestroy() {
            this.$eventBus.$off('fetch-table-leads', this.triggerFetch);

            this.destroyScroll();
        },
    };
</script>
