<template>
    <activix-button type="outline">
        <icon class="text-blue-500 mr-3" :name="$icons.appointment" />
        <span>{{ formatedStartDate }} - {{ formatedEndDate }}</span>
        <icon class="text-grey-600 ml-3 text-xs" :name="$icons.expand" />
    </activix-button>
</template>

<script>
    import { get } from 'lodash-es';

    import { mapState, mapActions } from 'pinia';

    import Moment from '../../value-objects/Moment.js';
    import Hotswap from '../../entities/Hotswap.js';
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useAccountCardStore } from '../../store/modules/accountCard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        computed: {
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useDashboardStore, {
                dashboardType: 'dashboardType',
                endDate: 'endDate',
                hotSwapDate: store => store.configs.hotSwapDate,
                startContractDate: 'startContractDate',
                startDate: 'startDate',
                storedStartDate: 'storedStartDate',
                storedEndDate: 'storedEndDate',
                dashboardView: 'dashboardView',
            }),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),
            ...mapState(useAccountCardStore, ['currentForm']),

            fiscalYearDate() {
                if (!this.contextAccount.fiscal_year_date) {
                    return null;
                }

                const currentYear = now().year();
                const fiscalYearDate = locale_date(this.contextAccount.fiscal_year_date).year(currentYear);

                if (fiscalYearDate.isAfter(now_date())) {
                    return fiscalYearDate.year(currentYear - 1);
                }

                return fiscalYearDate;
            },

            formatedStartDate() {
                return as_locale(this.startDate, 'start_date').humanShort(true) || 'N/A';
            },

            formatedEndDate() {
                return as_locale(this.endDate, 'end_date').humanShort(true) || 'N/A';
            },

            olderThanOption() {
                if (!this.startContractDate) {
                    return null;
                }

                return get(this.dashboardView.preset_dates, 'olderThan', this.authUser.older_than);
            },

            newerThanOption() {
                return get(this.dashboardView.preset_dates, 'newerThan', this.authUser.newer_than);
            },
        },

        watch: {
            '$route.name'() {
                this.generate();
            },

            '$i18n.locale'() {
                this.generate();
            },

            startDate(newValue) {
                const startDate = as_locale(newValue, 'start_date');
                const picker = $(this.$el).data('daterangepicker');

                if (this.dashboardType != 'event') {
                    useDashboardStore().storedStartDate = newValue;
                }

                if (picker && startDate.isValid()) {
                    picker.setStartDate(startDate.getObject());
                }

                const hotSwapDate = this.defineHotSwap(this.startDate, this.endDate);
                useDashboardStore().configs.currentView.hotSwapDate = hotSwapDate;
            },

            endDate(newValue) {
                const endDate = as_locale(newValue, 'end_date');
                const picker = $(this.$el).data('daterangepicker');

                if (this.dashboardType != 'event') {
                    useDashboardStore().storedEndDate = newValue;
                }

                if (picker && endDate.isValid()) {
                    picker.setEndDate(endDate.getObject());
                }

                const hotSwapDate = this.defineHotSwap(this.startDate, this.endDate);

                useDashboardStore().configs.currentView.hotSwapDate = hotSwapDate;
            },

            dashboardType(newValue, oldValue) {
                if (oldValue != 'event') {
                    return;
                }

                if (this.storedStartDate && this.storedEndDate) {
                    this.setStartDate(this.storedStartDate);
                    this.setEndDate(this.storedEndDate);
                } else {
                    this.setPickerDates();
                }
            },

            hotSwapDate() {
                this.initPicker(true);
            },

            authUser() {
                this.initPicker();
            },

            contextAccount() {
                this.initPicker();
            },

            startContractDate() {
                this.initPicker();
            },
        },

        methods: {
            ...mapActions(useDashboardStore, [
                'setDashboardConfigs',
                'setStartDate',
                'setEndDate',
            ]),
            ...mapActions(useGlobalStore, ['updateSessionAction']),

            defineHotSwap(startDate, endDate) {
                let hotSwapDate = null;
                const startContractDate = locale_date(this.startContractDate);

                startDate = as_locale(startDate, 'start_date');
                endDate = as_locale(endDate, 'end_date');

                if (startDate.isToday() && endDate.isToday()) {
                    hotSwapDate = Hotswap.TODAY;
                } else if (startDate.isSameDay(yesterday()) && endDate.isSameDay(yesterday())) {
                    hotSwapDate = Hotswap.YESTERDAY;
                } else if (startDate.isSameDay(tomorrow()) && endDate.isSameDay(tomorrow())) {
                    hotSwapDate = Hotswap.TOMORROW;
                } else if (startDate.isSameDay(now().subDays(7)) && endDate.isToday()) {
                    hotSwapDate = Hotswap.LAST_7;
                } else if (startDate.isSameDay(now().startOfMonth()) && endDate.isSameDay(now().endOfMonth())) {
                    hotSwapDate = Hotswap.THIS_MONTH;
                } else if (startDate.isSameDay(now().subDays(30)) && endDate.isToday()) {
                    hotSwapDate = Hotswap.LAST_30;
                } else if (
                    this.fiscalYearDate &&
                    startDate.isSameDay(this.fiscalYearDate) &&
                    startDate.isSameMonth(this.fiscalYearDate) &&
                    endDate.isToday()
                ) {
                    hotSwapDate = Hotswap.FISCAL_YEAR_TO_DATE;
                } else if (
                    startDate.isSameDay(now().subMonths(1).startOfMonth()) &&
                    endDate.isSameDay(now().subMonths(1).endOfMonth())
                ) {
                    hotSwapDate = Hotswap.LAST_MONTH;
                } else if (
                    startDate.isSameDay(now().subMonths(3).startOfMonth()) &&
                    endDate.isSameDay(now().subMonths(1).endOfMonth())
                ) {
                    hotSwapDate = 'last3Month';
                } else if (startDate.isSameDay(now().subDays(90)) && endDate.isToday()) {
                    hotSwapDate = Hotswap.LAST_90;
                } else if (
                    this.olderThanOption &&
                    startDate.isSameDay(startContractDate) &&
                    endDate.isSameDay(now().subMonths(this.olderThanOption))
                ) {
                    hotSwapDate = Hotswap.OLDER_THAN;
                } else if (
                    this.newerThanOption &&
                    startDate.isSameDay(now().subMonths(this.newerThanOption)) &&
                    endDate.isToday()
                ) {
                    hotSwapDate = Hotswap.NEWER_THAN;
                } else if (startContractDate.isValid() && startDate.isSameDay(startContractDate) && endDate.isToday()) {
                    hotSwapDate = Hotswap.SINCE_BEGINNING;
                } else if (startDate.isToday() && endDate.isSameDay(now().addDays(7))) {
                    hotSwapDate = Hotswap.NEXT_7;
                } else if (startDate.isToday() && endDate.isSameDay(now().addDays(30))) {
                    hotSwapDate = Hotswap.NEXT_30;
                } else if (startDate.isToday() && endDate.isSameDay(now().addDays(90))) {
                    hotSwapDate = Hotswap.NEXT_90;
                } else if (
                    startDate.isSameDay(now().addMonths(1).startOfMonth()) &&
                    endDate.isSameDay(now().addMonths(1).endOfMonth())
                ) {
                    hotSwapDate = 'nextMonth';
                } else if (
                    startDate.isSameDay(now().addMonths(1).startOfMonth()) &&
                    endDate.isSameDay(now().addMonths(3).endOfMonth())
                ) {
                    hotSwapDate = 'next3Month';
                } else if (
                    startDate.isSameDay(now().addMonths(1).startOfMonth()) &&
                    endDate.isSameDay(now().addMonths(6).endOfMonth())
                ) {
                    hotSwapDate = 'next6Month';
                } else if (
                    startDate.isSameDay(now().addMonths(1).startOfMonth()) &&
                    endDate.isSameDay(now().addMonths(12).endOfMonth())
                ) {
                    hotSwapDate = 'next12Month';
                } else if (
                    startDate.isSameDay(now().addMonths(1).startOfMonth()) &&
                    endDate.isSameDay(now().addMonths(18).endOfMonth())
                ) {
                    hotSwapDate = 'next18Month';
                } else if (
                    startDate.isSameDay(now().addMonths(1).startOfMonth()) &&
                    endDate.isSameDay(now().addMonths(24).endOfMonth())
                ) {
                    hotSwapDate = 'next24Month';
                } else if (
                    startDate.isSameDay(now().addMonths(1).startOfMonth()) &&
                    endDate.isSameDay(now().addMonths(36).endOfMonth())
                ) {
                    hotSwapDate = 'next36Month';
                } else if (
                    startDate.isSameDay(now().startOfMonth()) &&
                    endDate.isSameDay(now().addYears(7).endOfMonth())
                ) {
                    hotSwapDate = 'next7Years';
                } else {
                    hotSwapDate = null;
                }

                return hotSwapDate;
            },

            setPickerDates(overwriteDates = false) {
                if (!overwriteDates && this.startDate && this.endDate) {
                    return;
                }

                let startDate = null;
                let endDate = null;

                switch (this.hotSwapDate) {
                    case Hotswap.TOMORROW:
                        startDate = tomorrow_date();
                        endDate = tomorrow_date();
                        break;

                    case Hotswap.TODAY:
                        startDate = now_date();
                        endDate = now_date();
                        break;

                    case Hotswap.YESTERDAY:
                        startDate = yesterday_date();
                        endDate = yesterday_date();
                        break;

                    case Hotswap.THIS_WEEK:
                        startDate = now_date().startOfWeek();
                        endDate = now_date().endOfWeek();
                        break;

                    case Hotswap.IN_1_MONTH:
                        startDate = now_date().day(1).addMonths().subDays(8);
                        endDate = now_date().day(1).addMonths().subDays();
                        break;

                    case Hotswap.IN_2_MONTH:
                        startDate = now_date().day(1).addMonths(2).subDays(8);
                        endDate = now_date().day(1).addMonths(2).subDays();
                        break;

                    case Hotswap.IN_3_MONTH:
                        startDate = now_date().day(1).addMonths(3).subDays(8);
                        endDate = now_date().day(1).addMonths(3).subDays();
                        break;

                    case Hotswap.IN_4_MONTH:
                        startDate = now_date().day(1).addMonths(4).subDays(8);
                        endDate = now_date().day(1).addMonths(4).subDays();
                        break;

                    case Hotswap.IN_5_MONTH:
                        startDate = now_date().day(1).addMonths(5).subDays(8);
                        endDate = now_date().day(1).addMonths(5).subDays();
                        break;

                    case Hotswap.IN_6_MONTH:
                        startDate = now_date().day(1).addMonths(6).subDays(8);
                        endDate = now_date().day(1).addMonths(6).subDays();
                        break;

                    case Hotswap.IN_7_MONTH:
                        startDate = now_date().day(1).addMonths(7).subDays(8);
                        endDate = now_date().day(1).addMonths(7).subDays();
                        break;

                    case Hotswap.IN_8_MONTH:
                        startDate = now_date().day(1).addMonths(8).subDays(8);
                        endDate = now_date().day(1).addMonths(8).subDays();
                        break;

                    case Hotswap.IN_9_MONTH:
                        startDate = now_date().day(1).addMonths(9).subDays(8);
                        endDate = now_date().day(1).addMonths(9).subDays();
                        break;

                    case Hotswap.IN_10_MONTH:
                        startDate = now_date().day(1).addMonths(10).subDays(8);
                        endDate = now_date().day(1).addMonths(10).subDays();
                        break;

                    case Hotswap.IN_11_MONTH:
                        startDate = now_date().day(1).addMonths(11).subDays(8);
                        endDate = now_date().day(1).addMonths(11).subDays();
                        break;

                    case Hotswap.IN_12_MONTH:
                        startDate = now_date().day(1).addMonths(12).subDays(8);
                        endDate = now_date().day(1).addMonths(12).subDays();
                        break;

                    case Hotswap.IN_16_MONTH:
                        startDate = now_date().day(1).addMonths(16).subDays(8);
                        endDate = now_date().day(1).addMonths(16).subDays();
                        break;

                    case Hotswap.IN_18_MONTH:
                        startDate = now_date().day(1).addMonths(18).subDays(8);
                        endDate = now_date().day(1).addMonths(18).subDays();
                        break;

                    case Hotswap.IN_20_MONTH:
                        startDate = now_date().day(1).addMonths(20).subDays(8);
                        endDate = now_date().day(1).addMonths(20).subDays();
                        break;

                    case Hotswap.IN_24_MONTH:
                        startDate = now_date().day(1).addMonths(24).subDays(8);
                        endDate = now_date().day(1).addMonths(24).subDays();
                        break;

                    case Hotswap.LAST_7:
                        startDate = now_date().subDays(7);
                        endDate = now_date();
                        break;

                    case Hotswap.LAST_30:
                        startDate = now_date().subDays(30);
                        endDate = now_date();
                        break;

                    case Hotswap.LAST_90:
                        startDate = now_date().subDays(90);
                        endDate = now_date();
                        break;

                    case Hotswap.THIS_MONTH:
                        startDate = now_date().startOfMonth();
                        endDate = now_date().endOfMonth();
                        break;

                    case Hotswap.LAST_MONTH:
                        startDate = now_date().subMonths(1).startOfMonth();
                        endDate = now_date().subMonths(1).endOfMonth();
                        break;

                    case Hotswap.LAST_3_MONTH:
                        startDate = now_date().subMonths(3).startOfMonth();
                        endDate = now_date().subMonths(1).endOfMonth();
                        break;

                    case Hotswap.MONTHS_AGO_2:
                        startDate = now_date().subMonths(2).startOfMonth();
                        endDate = now_date().subMonths(2).endOfMonth();
                        break;

                    case Hotswap.MONTHS_AGO_3:
                        startDate = now_date().subMonths(3).startOfMonth();
                        endDate = now_date().subMonths(3).endOfMonth();
                        break;

                    case Hotswap.OLDER_THAN:
                        if (this.olderThanOption) {
                            startDate = this.startContractDate;
                            endDate = now_date().subMonths(this.olderThanOption);
                        } else {
                            startDate = now_date().subDays(7);
                            endDate = now_date();
                        }
                        break;

                    case Hotswap.NEWER_THAN:
                        if (this.newerThanOption) {
                            startDate = now_date().subMonths(this.newerThanOption);
                        } else {
                            startDate = now_date().subDays(7);
                        }

                        endDate = now_date();
                        break;

                    case Hotswap.SINCE_BEGINNING:
                        if (this.startContractDate) {
                            startDate = this.startContractDate;
                        } else {
                            startDate = now_date().subDays(7);
                        }

                        endDate = now_date();
                        break;

                    case Hotswap.FISCAL_YEAR_TO_DATE:
                        startDate = this.fiscalYearDate;
                        endDate = now_date();
                        break;

                    case Hotswap.NEXT_7:
                        startDate = now_date();
                        endDate = now_date().addDays(7);
                        break;

                    case Hotswap.NEXT_30:
                        startDate = now_date();
                        endDate = now_date().addDays(30);
                        break;

                    case Hotswap.NEXT_90:
                        startDate = now_date();
                        endDate = now_date().addDays(90);
                        break;

                    case Hotswap.NEXT_MONTH:
                        startDate = now_date().addMonths(1).startOfMonth();
                        endDate = now_date().addMonths(1).endOfMonth();
                        break;

                    case Hotswap.NEXT_3_MONTH:
                        startDate = now_date().addMonths(1).startOfMonth();
                        endDate = now_date().addMonths(3).endOfMonth();
                        break;

                    case Hotswap.NEXT_6_MONTH:
                        startDate = now_date().addMonths(1).startOfMonth();
                        endDate = now_date().addMonths(6).endOfMonth();
                        break;

                    case Hotswap.NEXT_12_MONTH:
                        startDate = now_date().addMonths(1).startOfMonth();
                        endDate = now_date().addMonths(12).endOfMonth();
                        break;

                    case Hotswap.NEXT_18_MONTH:
                        startDate = now_date().addMonths(1).startOfMonth();
                        endDate = now_date().addMonths(18).endOfMonth();
                        break;

                    case Hotswap.NEXT_24_MONTH:
                        startDate = now_date().addMonths(1).startOfMonth();
                        endDate = now_date().addMonths(24).endOfMonth();
                        break;

                    case Hotswap.NEXT_36_MONTH:
                        startDate = now_date().addMonths(1).startOfMonth();
                        endDate = now_date().addMonths(36).endOfMonth();
                        break;

                    case Hotswap.NEXT_7_YEARS:
                        startDate = now_date().startOfMonth();
                        endDate = now_date().addYears(7).endOfMonth();
                        break;

                    default:
                        if (!this.startDate || !this.endDate) {
                            startDate = now_date().subDays(7);
                            endDate = now_date();
                        }
                        break;
                }

                if (startDate) {
                    this.setStartDate(startDate);
                }

                if (endDate) {
                    this.setEndDate(endDate);
                }
            },

            onChange(start, end) {
                const startDate = locale_date(start.format(Moment.DATE_OUTPUT_FORMAT));
                const endDate = locale_date(end.format(Moment.DATE_OUTPUT_FORMAT));

                this.setStartDate(startDate);
                this.setEndDate(endDate);

                const hotSwapDate = this.defineHotSwap(this.startDate, this.endDate);

                if (hotSwapDate) {
                    if (this.dashboardType == 'renewal') {
                        this.updateSessionAction('hot_swap_date_renewal', hotSwapDate);
                    } else {
                        this.updateSessionAction('hot_swap_date', hotSwapDate);
                    }

                    this.setDashboardConfigs({
                        hotSwapDate,
                    });
                }
            },

            onShow(event, picker) {
                if (!this.mdLayout) {
                    picker.container[0].classList.add('opened');
                }

                if (picker.container[0].querySelectorAll('.calendar-wrapper').length) {
                    return;
                }

                this.configureCalendarDom(picker, 'end');
                this.configureCalendarDom(picker, 'start');
            },

            onHide(event, picker) {
                if (!this.mdLayout) {
                    picker.container[0].classList.remove('opened');
                }
            },

            configureCalendarDom(picker, type) {
                const title = document.createElement('h3');
                title.classList.add('calendar-title');
                title.append(this.$t(`datepicker.${type}`));

                const wrapper = document.createElement('div');
                wrapper.classList.add('calendar-wrapper');
                wrapper.append(title);

                const calendar = picker.container[0].querySelector(`.calendar.${type == 'start' ? 'first' : 'second'}`);
                wrapper.append(calendar);

                picker.container[0].prepend(wrapper);
            },

            async generate() {
                const startContractDate = locale_date(this.startContractDate);
                let dateRanges = {};

                dateRanges[this.$t('datepicker.today')] = [now_date().getObject(), now_date().getObject()];

                if (['allLead', 'loyalty', 'service'].includes(this.dashboardType)) {
                    dateRanges[this.$t('datepicker.tomorrow')] = [
                        tomorrow_date().getObject(), tomorrow_date().getObject(),
                    ];
                }

                dateRanges[this.$t('datepicker.yesterday')] = [yesterday_date().getObject(), yesterday_date().getObject()];

                if (['allLead', 'walkIn'].includes(this.dashboardType)) {
                    dateRanges[this.$t('datepicker.thisWeek')] = [
                        now_date().startOfWeek().getObject(),
                        now_date().endOfWeek().getObject(),
                    ];
                }

                dateRanges[this.$t('datepicker.last7Days')] = [now_date().subDays(7).getObject(), now_date().getObject()];

                switch (this.dashboardType) {
                    case 'activityReport':
                        dateRanges[this.$t('datepicker.lastMonth')] = [
                            now_date().subMonths(1).startOfMonth().getObject(),
                            now_date().subMonths(1).endOfMonth().getObject(),
                        ];
                        dateRanges[this.$t('datepicker.monthsAgo2')] = [
                            now_date().subMonths(2).startOfMonth().getObject(),
                            now_date().subMonths(2).endOfMonth().getObject(),
                        ];
                        dateRanges[this.$t('datepicker.monthsAgo3')] = [
                            now_date().subMonths(3).startOfMonth().getObject(),
                            now_date().subMonths(3).endOfMonth().getObject(),
                        ];

                        break;

                    case 'renewal':
                        dateRanges = {
                            [this.$t('datepicker.last3Month')]: [
                                now_date().subMonths(3).startOfMonth().getObject(),
                                now_date().subMonths(1).endOfMonth().getObject(),
                            ],
                            [this.$t('datepicker.lastMonth')]: [
                                now_date().subMonths(1).startOfMonth().getObject(),
                                now_date().subMonths(1).endOfMonth().getObject(),
                            ],
                            [this.$t('datepicker.thisMonth')]: [
                                now_date().startOfMonth().getObject(),
                                now_date().endOfMonth().getObject(),
                            ],
                            [this.$t('datepicker.nextMonth')]: [
                                now_date().addMonths(1).startOfMonth().getObject(),
                                now_date().addMonths(1).endOfMonth().getObject(),
                            ],
                            [this.$t('datepicker.next3Month')]: [
                                now_date().addMonths(1).startOfMonth().getObject(),
                                now_date().addMonths(3).endOfMonth().getObject(),
                            ],
                            [this.$t('datepicker.next6Month')]: [
                                now_date().addMonths(1).startOfMonth().getObject(),
                                now_date().addMonths(6).endOfMonth().getObject(),
                            ],
                            [this.$t('datepicker.next12Month')]: [
                                now_date().addMonths(1).startOfMonth().getObject(),
                                now_date().addMonths(12).endOfMonth().getObject(),
                            ],
                            [this.$t('datepicker.next18Month')]: [
                                now_date().addMonths(1).startOfMonth().getObject(),
                                now_date().addMonths(18).endOfMonth().getObject(),
                            ],
                            [this.$t('datepicker.next24Month')]: [
                                now_date().addMonths(1).startOfMonth().getObject(),
                                now_date().addMonths(24).endOfMonth().getObject(),
                            ],
                            [this.$t('datepicker.next36Month')]: [
                                now_date().addMonths(1).startOfMonth().getObject(),
                                now_date().addMonths(36).endOfMonth().getObject(),
                            ],
                        };

                        break;

                    default:
                        dateRanges[this.$t('datepicker.thisMonth')] = [
                            now_date().startOfMonth().getObject(),
                            now_date().endOfMonth().getObject(),
                        ];
                        dateRanges[this.$t('datepicker.last30Days')] = [
                            now_date().subDays(30).getObject(),
                            now_date().getObject(),
                        ];

                        if (
                            !startContractDate.isValid() ||
                            now_date().subMonths().startOfMonth().isAfter(startContractDate)
                        ) {
                            dateRanges[this.$t('datepicker.lastMonth')] = [
                                now_date().subMonths().startOfMonth().getObject(),
                                now_date().subMonths().endOfMonth().getObject(),
                            ];
                        }

                        dateRanges[this.$t('datepicker.last90Days')] = [
                            now_date().subDays(90).getObject(),
                            now_date().getObject(),
                        ];

                        if (['allLead', 'leadXpress', 'webOrder', 'service'].includes(this.dashboardType)) {
                            dateRanges[this.$t('datepicker.next90')] = [
                                now_date().getObject(),
                                now_date().addDays(90).getObject(),
                            ];
                        }

                        if (['saleTable'].includes(this.dashboardType) && this.fiscalYearDate) {
                            dateRanges[this.$t('datepicker.fiscalYearToDate')] = [
                                this.fiscalYearDate.getObject(),
                                now_date().getObject(),
                            ];
                        }

                        if (startContractDate.isValid()) {
                            dateRanges[this.$t('datepicker.sinceBeginning')] = [
                                startContractDate.getObject(),
                                now_date().getObject(),
                            ];
                        }

                        if (this.olderThanOption) {
                            dateRanges[this.$t('datepicker.olderThan', { months: this.olderThanOption })] = [
                                startContractDate.getObject(),
                                now_date().subMonths(this.olderThanOption).getObject(),
                            ];
                        }

                        if (this.newerThanOption) {
                            dateRanges[this.$t('datepicker.newerThan', { months: this.newerThanOption })] = [
                                now_date().subMonths(this.newerThanOption).getObject(),
                                now_date().getObject(),
                            ];
                        }
                }

                // Setup daterangepicker
                const picker = $(this.$el).data('daterangepicker');
                const payload = {
                    showDropdowns: true,
                    startDate: as_locale(this.startDate, 'start_date').getObject(),
                    endDate: as_locale(this.endDate, 'end_date').getObject(),
                    ranges: dateRanges,
                    opens: 'left',
                    applyClass: 'btn-primary',
                    cancelClass: 'btn-default',
                    locale: this.$i18n.locale == 'en' ? datetimeLangEn : datetimeLangFr,
                    parentEl: document.querySelector('main'),
                };

                if (!picker) {
                    $(this.$el)
                        .daterangepicker(payload, this.onChange)
                        .on('show.daterangepicker', this.onShow)
                        .on('hide.daterangepicker', this.onHide);
                } else {
                    picker.setOptions(payload, this.onChange);
                }

                const hotSwapDate = this.defineHotSwap(this.startDate, this.endDate);

                useDashboardStore().configs.currentView.hotSwapDate = hotSwapDate;
            },

            async initPicker(overwriteDates) {
                await this.$nextTick();

                if (this.dashboardType != 'event') {
                    this.setPickerDates(overwriteDates);
                }

                this.generate();
            },
        },

        mounted() {
            this.initPicker();
        },

        beforeDestroy() {
            if (
                this.dashboardType == 'event' &&
                !this.$route.name.includes('dashboard') &&
                this.storedStartDate &&
                this.storedEndDate
            ) {
                this.setStartDate(this.storedStartDate);
                this.setEndDate(this.storedEndDate);
            }

            $(this.$el).off('show.daterangepicker');
            $(this.$el).data('daterangepicker').remove();
        },
    };
</script>
