// See documentation https://app.nuclino.com/Activix/Development/-Add-a-new-column-in-dashboards-e271d914-71d8-4d1d-b4ed-cabb3e3592c8
import { capitalize, get, isEmpty, kebabCase, filter, uniq, map, union } from 'lodash-es';
import Civility from '@/entities/Civility';
import LeadVehicleCondition from '@/entities/LeadVehicleCondition';
import LeadVehicleFuel from '@/entities/LeadVehicleFuel';
import { mapState } from 'pinia';
import CaslConsentState from '@/entities/CaslConsentState.js';
import i18n from '../plugins/vue-i18n.js';
import { getFullName } from '../utils/index.js';
import { getIconMarkup } from '../utils/icon.js';
import { formater } from '../globals/LeadFormatter.js';
// Entities
import Supplier from '../entities/Supplier.js';
import DashboardType from '../entities/DashboardType.js';
import LeadVehicleTerm from '../entities/LeadVehicleTerm.js';
// Value Objects
import ActivixDate from '../value-objects/ActivixDate.js';
import CreatedMethod from '../entities/CreatedMethod.js';
import CampaignType from '../entities/CampaignType.js';
import ProgressState from '../entities/ProgressState.js';

// Pinia
import { useDashboardStore } from '../store/modules/dashboard/store.js';
import { useContextStore } from '../store/modules/context/store.js';
import { useGlobalStore } from '../store/store.js';
import { useClientCardStore } from '../store/modules/clientCard/store.js';
import ClientCardSection from '../entities/ClientCardSection.js';

export default {
    computed: {
        ...mapState(useClientCardStore, ['leadBanks']),
        ...mapState(useGlobalStore, ['authUser', 'configs', 'commercialUsers', 'groupDisplay', 'serviceUsers', 'serviceAgentUsers']),
        ...mapState(useDashboardStore, {
            dashboardConfigs: 'configs',
            startDate: 'startDate',
            endDate: 'endDate',
            dashboardType: 'dashboardType',
        }),
        ...mapState(useContextStore, {
            contextAccount: 'account',
            contextGroup: 'group',
        }),

        calculatorValueVisible() {
            return this.authUser.isAdmin() ||
                this.authUser.isDirector() ||
                this.authUser.isBdcDirector() ||
                this.authUser.isCommercialDirector() ||
                this.authUser.calculator_value_visible;
        },

        intentionsOptions() {
            return union(uniq(map(
                filter(this.contextAccount.products, p => p.type === 'service'),
                p => p.category,
            )), ['problem']).sort()
                .map(option => {
                    return {
                        value: option,
                        text: this.$t(`service.categories.${option}`),
                    };
                });
        },

        parsedStartDate() {
            return new ActivixDate(this.startDate).startOfDay();
        },

        parsedEndDate() {
            return new ActivixDate(this.endDate).endOfDay();
        },

        clientColumnTitle() {
            switch (this.dashboardType) {
                case DashboardType.WEB_ORDER:
                    return i18n.tc('general.webOrder', 0);

                case DashboardType.WALK_IN:
                    return i18n.t('general.walkIn');

                case DashboardType.PHONE_UP:
                    return i18n.t('general.phoneUp');

                case DashboardType.ALL:
                case DashboardType.RENEWAL:
                case DashboardType.COMMERCIAL:
                case DashboardType.LOYALTY:
                case DashboardType.SERVICE:
                case DashboardType.SALE:
                case DashboardType.EVENT:
                    return i18n.tc('general.client', 0);

                default:
                    return i18n.tc('general.lead', 0);
            }
        },

        dateColumnTooltip() {
            switch (this.dashboardType) {
                case DashboardType.RENEWAL:
                    return i18n.t('date.createdDate');

                case DashboardType.SERVICE:
                    return `
                        ${i18n.t('date.presentedDate')}<br>
                        ${i18n.t('date.appointmentDate')}<br>
                        ${i18n.t('date.createdDate')}
                    `;

                case DashboardType.ALL: {
                    let webOrderType = '';

                    if (this.authUser.isAdmin() || this.authUser.hasWebOrder()) {
                        webOrderType = `
                            <span class="font-semibold">${i18n.t('dashboards.webOrder')}</span> :
                            ${i18n.t('date.createdDate')}<br>
                        `;
                    }

                    return `
                        <div class="text-left">
                            ${webOrderType}
                            <span class="font-semibold">${i18n.t('dashboards.webLead')}</span> :
                            ${i18n.t('date.createdDate')}<br>
                            <span class="font-semibold">${i18n.t('dashboards.phoneUp')}</span> :
                            ${i18n.t('date.callDate')}<br>
                            <span class="font-semibold">${i18n.t('dashboards.walkIn')}</span> :
                            ${i18n.t('date.presentedDate')}<br>
                            <span class="font-semibold">${i18n.t('dashboards.loyalty')}</span> :
                            ${i18n.t('date.createdDate')}<br>
                            <span class="font-semibold">${i18n.t('dashboards.renewal')}</span> :
                            ${i18n.t('date.endContractDate')}<br>
                        </div>
                    `;
                }

                default:
                    return '';
            }
        },

        accountOptions() {
            if (this.contextGroup.id) {
                return this.contextGroup.accounts.map(account => {
                    return {
                        value: account.id,
                        text: account.name,
                    };
                });
            }
            return (this.contextAccount.children || []).map(childAccount => {
                return {
                    value: childAccount.id.toString(),
                    text: childAccount.name,
                };
            });
        },

        accountHasNioText() {
            return this.contextAccount.hasNioText() || this.contextAccount.children.some(child => child.hasNioText());
        },

        accountHasMessenger() {
            return (
                this.contextAccount.hasMessenger() || this.contextAccount.children.some(child => child.hasMessenger())
            );
        },

        accountHasVideoConference() {
            return (
                this.contextAccount.video_conference ||
                this.contextAccount.children.some(child => child.video_conference)
            );
        },

        advisorOptions() {
            return this.contextAccount.advisorUsers.map(user => {
                return {
                    value: user.parent_user_id || user.id,
                    text: getFullName(user),
                };
            });
        },

        agentOptions() {
            return this.contextAccount.bdcUsers.map(user => {
                return {
                    value: user.parent_user_id || user.id,
                    text: getFullName(user),
                };
            });
        },

        phoneAppointmentOptions() {
            return [
                {
                    value: 'yes',
                    text: i18n.t('general.yes'),
                },
                {
                    value: 'no',
                    text: i18n.t('general.no'),
                },
                'canceled',
                'confirmed',
                'noDate',
                'notConfirmed',
                'toReach',
                'uncertain',
                'completed',
                'notCompleted',
            ];
        },

        appointmentOptions() {
            return [
                {
                    value: 'yes',
                    text: i18n.t('general.yes'),
                },
                {
                    value: 'no',
                    text: i18n.t('general.no'),
                },
                {
                    value: 'noTime',
                    condition: this.dashboardType == 'event',
                },
                'canceled',
                'confirmed',
                'noDate',
                'noShow',
                'notConfirmed',
                'toReach',
                'uncertain',
                'completed',
                'notCompleted',
            ];
        },

        caslConsentLimitDateOptions() {
            const values = [];

            // 10 days
            values.push({
                value: '<= 10',
                text: i18n.t('consent.durations.lessThanTenDays'),
            });

            // 11 - 30 days
            values.push({
                value: ['>=11', '<= 30'],
                text: i18n.t('consent.durations.elevenToThirtyDays'),
            });

            // 1 - 3 months
            values.push({
                value: ['>=31', '<=120'],
                text: i18n.t('consent.durations.oneToThreeMonths'),
            });

            // 4 months - 11 months
            values.push({
                value: ['>=121', '<=364'],
                text: i18n.t('consent.durations.fourToElevenMonths'),
            });

            // 12 months - 24 months
            values.push({
                value: ['>=365', '<=731'],
                text: i18n.t('consent.durations.twelveToTwentyFourMonths'),
            });

            return values;
        },

        civilityOptions() {
            return Civility.values.map(civility => {
                return {
                    value: civility,
                    text: Civility.getTranslation(civility),
                };
            });
        },

        commercialOptions() {
            return this.commercialUsers.map(user => {
                return {
                    value: user.parent_user_id || user.id,
                    text: getFullName(user),
                };
            });
        },

        conditionOptions() {
            return LeadVehicleCondition.values.map(condition => {
                return {
                    value: condition,
                    text: LeadVehicleCondition.getTranslation(condition),
                };
            });
        },

        fuelOptions() {
            return LeadVehicleFuel.values.map(fuel => {
                return {
                    value: fuel,
                    text: LeadVehicleFuel.getTranslation(fuel),
                };
            });
        },

        divisionOptions() {
            const baseDivision = this.dashboardType == 'service' ? ['service'] : ['new', 'used'];

            if (['allLead', 'event', 'leadXpress', 'webBoost', 'phoneUp'].includes(this.dashboardType)) {
                baseDivision.push('service');
            }

            return baseDivision.map(division => {
                return {
                    value: division,
                    condition: this.authUserHasDivision(division),
                };
            });
        },

        followedOptions() {
            return [
                'none',
                'noCall',
                'noEmail',
                {
                    value: 'noSms',
                    condition: this.accountHasNioText,
                },
                {
                    value: 'noMessenger',
                    condition: this.accountHasMessenger,
                },
                {
                    value: 'noVideo',
                    condition: this.accountHasVideoConference,
                },
                'withCall',
                'withEmail',
                {
                    value: 'withSms',
                    condition: this.accountHasNioText,
                },
                {
                    value: 'withMessenger',
                    condition: this.accountHasMessenger,
                },
                {
                    value: 'withVideo',
                    condition: this.accountHasVideoConference,
                },
                'withAutomated',
                'noAutomated',
            ];
        },

        leadBankOptions() {
            return this.leadBanks.map(bank => {
                return {
                    value: bank.text,
                    text: bank.text,
                };
            });
        },

        leadTypeOptions() {
            return [
                'email',
                'phone',
                'walk_in',
                'loyalty',
                'renewal',
                {
                    value: 'web_order',
                    condition: this.authUser.isAdmin() || this.authUser.hasWebOrder(),
                },
                {
                    value: 'event',
                    condition: ['walkIn', 'saleTable', 'allLead'].includes(this.dashboardType),
                },
                {
                    value: 'pre_booking',
                    condition: this.divisionOptions.filter(division => division.value == 'service').length == 1,
                },
                {
                    value: 'dms',
                    condition: this.divisionOptions.filter(division => division.value == 'service').length == 1,
                },
                {
                    value: 'sms',
                    condition: this.accountHasNioText,
                },
            ];
        },

        localeOptions() {
            return ['fr', 'en'].map(lang => {
                return {
                    value: lang,
                    text: capitalize(lang),
                };
            });
        },

        presentedOptions() {
            if (this.dashboardType === 'service') {
                return [
                    {
                        value: 'presented',
                        text: i18n.t('general.yes'),
                    },
                    {
                        value: 'none',
                        text: i18n.t('general.no'),
                    },
                ];
            }

            return [
                {
                    value: 'presented',
                    text: i18n.t('filters.presented.presented'),
                },
                {
                    value: 'homePresented',
                    text: i18n.t('filters.presented.homePresented'),
                },
            ];
        },

        roadTestOptions() {
            if (this.dashboardType === 'service') {
                return [
                    {
                        value: 'roadTest',
                        text: i18n.t('general.yes'),
                    },
                    {
                        value: 'none',
                        text: i18n.t('general.no'),
                    },
                ];
            }

            return [
                {
                    value: 'roadTest',
                    text: i18n.t('filters.roadTest.roadTest'),
                },
                {
                    value: 'homeRoadTest',
                    text: i18n.t('filters.roadTest.homeRoadTest'),
                },
            ];
        },

        mediaOptions() {
            return [
                {
                    value: 'audio',
                    text: i18n.t('filters.media.audio'),
                },
                {
                    value: 'video',
                    text: i18n.t('filters.media.video'),
                },
            ];
        },

        provinceOptions() {
            // @TODO Check if config from Vuex is accessible from here
            return get(this.configs, `provinces.${this.contextAccount.country || 'CA'}`, []);
        },

        countryOptions() {
            // @TODO Check if config from Vuex is accessible from here
            return get(this.configs, 'countries', []);
        },

        saleTableDateOptions() {
            return [
                {
                    value: 'yes',
                    text: i18n.t('general.yes'),
                },
                {
                    value: 'no',
                    text: i18n.t('general.no'),
                },
                {
                    value: 'inside_dates',
                    text: i18n.t('general.insideDates'),
                    condition: this.dashboardType == 'saleTable',
                },
                {
                    value: 'outside_dates',
                    text: i18n.t('general.outsideDates'),
                    condition: this.dashboardType == 'saleTable',
                },
            ];
        },

        discountedOptions() {
            return [
                {
                    value: 'yes',
                    text: i18n.t('general.yes'),
                },
                {
                    value: 'no',
                    text: i18n.t('general.no'),
                },
                {
                    value: 'inside_dates',
                    text: i18n.t('general.insideDates'),
                    condition: ['saleTable', 'commercial'].includes(this.dashboardType),
                },
                {
                    value: 'outside_dates',
                    text: i18n.t('general.outsideDates'),
                    condition: ['saleTable', 'commercial'].includes(this.dashboardType),
                },
            ];
        },

        saleStateOptions() {
            const states = [
                'approved',
                'carryOver',
                'delivered',
                'deliveryCarryOver',
                'lost',
                'planned',
                'postponed',
                'refused',
                'sold',
                'waitingSale',
            ];

            if (this.hasClientCardField('process', 'discounted')) {
                states.push('discounted');
            }

            return states;
        },

        serviceAgentOptions() {
            const serviceUsers = this.serviceUsers.map(user => {
                return {
                    value: user.parent_user_id || user.id,
                    text: getFullName(user),
                };
            });

            const serviceAgentUsers = this.serviceAgentUsers.map(user => {
                return {
                    value: user.parent_user_id || user.id,
                    text: getFullName(user),
                };
            });

            return [
                {
                    value: 'reception',
                    text: i18n.t('divisions.reception'),
                },
                ...serviceUsers,
                ...serviceAgentUsers,
            ];
        },

        sourceOptions() {
            return this.contextAccount.sources
                .map(source => {
                    if (this.contextAccount.display_approved_sources && source.approved_source) {
                        return {
                            value: source.approved_source.name.trim(),
                            text: source.approved_source.name.trim(),
                        };
                    }
                    return {
                        value: source.name.trim(),
                        text: source.name.trim(),
                    };
                })
                .sort((a, b) => a.text.trim().localeCompare(b.text.trim()));
        },

        providerOptions() {
            return this.contextAccount.providers
                .map(provider => {
                    return {
                        value: provider.name.trim(),
                        text: (provider[`display_name_${this.$i18n.locale}`] || provider.name).trim(),
                    };
                })
                .sort((a, b) => a.text.trim().localeCompare(b.text.trim()));
        },

        todoOptions() {
            return [
                {
                    value: 'late',
                    text: i18n.t('followUp.todo.late'),
                },
                {
                    value: 1,
                    text: i18n.t('followUp.todo.call'),
                },
                {
                    value: 2,
                    text: i18n.t('followUp.todo.email'),
                },
                {
                    value: 7,
                    text: i18n.t('followUp.todo.sms'),
                    condition: this.accountHasNioText,
                },
                {
                    value: 3,
                    text: i18n.t('followUp.todo.appointment'),
                },
                {
                    value: 8,
                    text: i18n.t('followUp.todo.phoneAppointment'),
                },
                {
                    value: 4,
                    text: i18n.t('followUp.todo.delivery'),
                },
                {
                    value: 5,
                    text: i18n.t('followUp.todo.other'),
                },
                {
                    value: 6,
                    text: i18n.t('followUp.todo.csi'),
                    condition: this.contextAccount.hasOption('csi'),
                },
                {
                    value: 'automated',
                    text: i18n.t('followUp.todo.automated'),
                },
            ];
        },

        usersOrAccounts() {
            return this.contextAccount.notServiceUsers.map(user => {
                return {
                    value: user.parent_user_id || user.id,
                    text: user.fullName,
                };
            });
        },

        activeUsers() {
            return this.contextAccount.activeUsers.map(user => {
                return {
                    value: user.parent_user_id || user.id,
                    text: user.fullName,
                };
            });
        },

        activeUsersAndSupplierUsers() {
            return this.contextAccount.activeUsersAndSupplierUsers.map(user => {
                return {
                    value: user.parent_user_id || user.id,
                    text: user.fullName,
                };
            });
        },

        contactMethodOptions() {
            const options = ['withEmail', 'withoutEmail', 'withPhone', 'withoutPhone', 'answered', 'waiting', 'withoutEmailAndPhone'];

            if (this.accountHasNioText) {
                options.push('withMobile');
                options.push('withoutMobile');
                options.push('withoutEmailAndMobile');
            }

            return options;
        },

        suppliers() {
            const suppliers = [];

            Object.keys(Supplier.entries).forEach(key => {
                suppliers.push({ text: i18n.t(`suppliers.${[key]}`), value: Supplier.entries[key] });
            });

            return suppliers;
        },

        activeDirectors() {
            return this.contextAccount.directorUsers.map(user => {
                return {
                    value: user.parent_user_id || user.id,
                    text: user.fullName,
                };
            });
        },

        columns() {
            return {
                'account:name': {
                    valid: this.groupDisplay,
                    style: { width: '120px' },
                    title: i18n.t('general.accountColumn'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatAccountName(lead, true);
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        options: this.accountOptions,
                    },
                },

                address: {
                    title: i18n.t('clientCard.address'),
                    style: { width: '120px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatAddress(lead);
                    },
                    filter: {
                        type: 'text',
                    },
                },

                agents: {
                    valid: isEmpty(this.contextGroup) && this.contextAccount.bdc_advisor,
                    style: { width: '125px' },
                    title: i18n.t('general.agent'),
                    component: 'agent',
                    filter: {
                        type: 'select',
                        multiple: true,
                        withNone: true,
                        sorting: false,
                        options: this.activeUsers,
                    },
                },

                advisors: {
                    valid: isEmpty(this.contextGroup),
                    style: { width: '125px' },
                    title: i18n.t('general.advisor'),
                    component: 'advisor',
                    filter: {
                        type: 'select',
                        multiple: true,
                        withNone: true,
                        sorting: false,
                        options: this.activeUsers,
                    },
                },

                phone_appointment: {
                    valid: this.hasClientCardField('performance', 'phone_appointment'),
                    title: `
                        <div class="flex items-center">
                            <span class="mr-2">
                                ${i18n.t('general.appointment')}
                            </span>
                            ${getIconMarkup('regular/phone-landline', { class: 'event-icon' })}
                        </div>
                    `,
                    sortTitle: i18n.t('general.appointment'),
                    style: { width: '75px' },
                    component: 'phone-appointment',
                    filter: {
                        multiple: true,
                        type: 'select',
                        baseTrans: 'filters.appointment',
                        options: this.phoneAppointmentOptions,
                        sorting: false,
                    },
                },

                appointment: {
                    valid: this.hasClientCardField('performance', 'appointment'),
                    title: i18n.t('general.appointment'),
                    style: { width: '75px' },
                    component: 'appointment',
                    filter: {
                        multiple: true,
                        type: 'select',
                        baseTrans: 'filters.appointment',
                        options: this.appointmentOptions,
                        sorting: false,
                    },
                },

                approved: {
                    valid: this.hasClientCardField('process', 'approved'),
                    title: i18n.tc('commercial.approved', 1),
                    style: { width: '75px' },
                    component: 'approved',
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                appt_confirmation: {
                    title: i18n.t('service.columns.confirmation'),
                    style: { width: '75px' },
                    component: 'appt-confirmation',
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                available: {
                    valid: this.hasClientCardField('process', 'available'),
                    title: i18n.t('clientCard.availableDate'),
                    style: { width: '90px' },
                    component: 'available',
                    filter: {
                        type: 'select',
                        withEmpty: this.dashboardType != 'saleTable',
                        multiple: this.dashboardType == 'saleTable',
                        options: this.saleTableDateOptions,
                        sorting: false,
                    },
                },

                paperwork: {
                    valid: this.hasClientCardField('process', 'paperwork'),
                    title: i18n.t('clientCard.paperwork'),
                    style: { width: '120px' },
                    component: 'paperwork',
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                average_spending: {
                    title: i18n.t('service.columns.averageSpending'),
                    style: { width: '120px' },
                    tooltip: i18n.t('clientCard.services.averageSpendingTooltip'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatAverageSpending(lead);
                    },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                be_back: {
                    valid: this.hasClientCardField('performance', 'be_back'),
                    title: i18n.t('result.beBack'),
                    style: { width: '75px' },
                    component: 'be-back',
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                birth_date: {
                    title: i18n.t('clientCard.birthDate'),
                    style: { width: '120px' },
                    formater: lead => {
                        return formater.formatBirthDate(lead);
                    },
                    filterTooltip: i18n.t('dashboards.dateFilterWithoutYearTooltip'),
                    filter: {
                        type: 'text',
                    },
                },

                budget: {
                    valid: this.hasClientCardField('general_info', 'budget'),
                    title: i18n.t('accounts.edit.budgetTextField'),
                    style: { width: '120px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatBudget(lead);
                    },
                    filter: {
                        type: 'text',
                    },
                },

                bulk_select: {
                    valid: !this.groupDisplay,
                    style: { width: '42px' },
                    fixed: true,
                    mandatory: true,
                    filter: {
                        type: 'custom',
                    },
                },

                business: {
                    title: i18n.t('clientCard.business'),
                    style: { width: '80px' },
                    component: 'business',
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'event_campaigns:name': {
                    valid: this.hasClientCardField('general_info', 'campaign'),
                    title: i18n.t('clientCard.event.event'),
                    style: { width: '120px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatCampaign(lead, CampaignType.EVENT);
                    },
                    filter: {
                        type: 'string',
                    },
                },

                imported_campaign: {
                    valid: this.hasClientCardField('general_info', 'campaign'),
                    title: i18n.t('dashboards.importedCampaign'),
                    style: { width: '120px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatImportedCampaign(lead);
                    },
                    filter: {
                        type: 'string',
                    },
                },

                city: {
                    title: i18n.t('general.city'),
                    style: { width: '100px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatCity(lead);
                    },
                    filter: {
                        type: 'string',
                    },
                },

                civility: {
                    title: i18n.t('clientCard.civility'),
                    style: { width: '100px' },
                    formater: lead => {
                        return formater.formatCivility(lead);
                    },
                    filter: {
                        type: 'select',
                        withNone: true,
                        options: this.civilityOptions,
                    },
                },

                code: {
                    title: i18n.t('service.columns.code'),
                    style: { width: '120px' },
                    formater: lead => {
                        return formater.formatCode(lead);
                    },
                    filter: {
                        type: 'string',
                    },
                },

                'commercial_products:pellicule': {
                    valid: this.hasAccountProduct('pellicule'),
                    title: i18n.t('clientCard.products.pellicule'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'commercial_products:wheel_protection': {
                    valid: this.hasAccountProduct('wheel_protection'),
                    title: i18n.t('clientCard.products.wheel_protection'),
                    style: { width: '160px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'commercial_products:mouse_repellent': {
                    valid: this.hasAccountProduct('mouse_repellent'),
                    title: i18n.t('clientCard.products.mouse_repellent'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'commercial_products:paint_treatment': {
                    valid: this.hasAccountProduct('paint_treatment'),
                    title: i18n.t('clientCard.products.paint_treatment'),
                    style: { width: '150px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'commercial_products:roof_treatment': {
                    valid: this.hasAccountProduct('roof_treatment'),
                    title: i18n.t('clientCard.products.roof_treatment'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'commercial_products:leather_tissu_interior_treatment': {
                    valid: this.hasAccountProduct('leather_tissu_interior_treatment'),
                    title: i18n.t('clientCard.products.leather_tissu_interior_treatment'),
                    style: { width: '200px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'commercial_products:niotext': {
                    valid: this.hasAccountProduct('niotext'),
                    title: i18n.t('clientCard.products.niotext'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'commercial_products:walk_in': {
                    valid: this.hasAccountProduct('walk_in'),
                    title: i18n.t('clientCard.products.walk_in'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'commercial_products:sale_table': {
                    valid: this.hasAccountProduct('sale_table'),
                    title: i18n.t('clientCard.products.sale_table'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'commercial_products:in_turn': {
                    valid: this.hasAccountProduct('in_turn'),
                    title: i18n.t('clientCard.products.in_turn'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'commercial_products:renewal': {
                    valid: this.hasAccountProduct('renewal'),
                    title: i18n.t('clientCard.products.renewal'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'commercial_products:event': {
                    valid: this.hasAccountProduct('event'),
                    title: i18n.t('clientCard.products.event'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'commercial_products:service': {
                    valid: this.hasAccountProduct('service'),
                    title: i18n.t('clientCard.products.service'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'commercial_products:financing': {
                    valid: this.hasAccountProduct('financing'),
                    title: i18n.t('clientCard.products.financing'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'commercial_products:ins_filling': {
                    valid: this.hasAccountProduct('ins_filling'),
                    title: i18n.t('clientCard.products.ins_filling'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'commercial_products:extended_warranty': {
                    valid: this.hasAccountProduct('extended_warranty'),
                    title: i18n.t('clientCard.products.extended_warranty'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'commercial_products:starter': {
                    valid: this.hasAccountProduct('starter'),
                    title: i18n.t('clientCard.products.starter'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'commercial_products:window_tint': {
                    valid: this.hasAccountProduct('window_tint'),
                    title: i18n.t('clientCard.products.window_tint'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'commercial_products:pre_paid_maintenance': {
                    valid: this.hasAccountProduct('pre_paid_maintenance'),
                    title: i18n.t('clientCard.products.pre_paid_maintenance'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'commercial_products:seat_protection': {
                    valid: this.hasAccountProduct('seat_protection'),
                    title: i18n.t('clientCard.products.seat_protection'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'commercial_products:pef': {
                    valid: this.hasAccountProduct('pef'),
                    title: i18n.t('clientCard.products.pef'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'commercial_products:pep': {
                    valid: this.hasAccountProduct('pep'),
                    title: i18n.t('clientCard.products.pep'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'commercial_products:other': {
                    valid: this.hasAccountProduct('other'),
                    title: i18n.t('clientCard.products.other'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'commercial_products:ins_rental': {
                    valid: this.hasAccountProduct('ins_rental'),
                    title: i18n.t('clientCard.products.ins_rental'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'commercial_products:ins_invalidity': {
                    valid: this.hasAccountProduct('ins_invalidity'),
                    title: i18n.t('clientCard.products.ins_invalidity'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'commercial_products:ins_health': {
                    valid: this.hasAccountProduct('ins_health'),
                    title: i18n.t('clientCard.products.ins_health'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'commercial_products:ins_life': {
                    valid: this.hasAccountProduct('ins_life'),
                    title: i18n.t('clientCard.products.ins_life'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'commercial_products:rustproofing': {
                    valid: this.hasAccountProduct('rustproofing'),
                    title: i18n.t('clientCard.products.rustproofing'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'commercial_products:chiselling': {
                    valid: this.hasAccountProduct('chiselling'),
                    title: i18n.t('clientCard.products.chiselling'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'commercial_products:anti_theft': {
                    valid: this.hasAccountProduct('anti_theft'),
                    title: i18n.t('clientCard.products.anti_theft'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'commercial_products:windshield_treatment': {
                    valid: this.hasAccountProduct('windshield_treatment'),
                    title: i18n.t('clientCard.products.windshield_treatment'),
                    style: { width: '160px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'commercial_products:burn_protection': {
                    valid: this.hasAccountProduct('burn_protection'),
                    title: i18n.t('clientCard.products.burn_protection'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'commercial_products:flame_quard_protection': {
                    valid: this.hasAccountProduct('flame_quard_protection'),
                    title: i18n.t('clientCard.products.flame_quard_protection'),
                    style: { width: '150px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'commercial:': {
                    title: i18n.t('general.commercial'),
                    style: { width: '120px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatCommercial(lead);
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        withNone: true,
                        options: this.activeUsers,
                    },
                },

                commercial_meeting: {
                    title: i18n.t('commercial.meeting'),
                    style: { width: '120px' },
                    component: 'commercial-meeting',
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                commercial_no_need: {
                    title: i18n.t('commercial.noNeed'),
                    style: { width: '120px' },
                    component: 'commercial-no-need',
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'customer:casl_consent_limit_date': {
                    valid: this.$feature.isEnabled('casl_compliance'),
                    title: i18n.t('consent.consentExpiration'),
                    style: { width: '165px' },
                    truncating: true,
                    formater: formater.formatCaslConsentLimitDate,
                    filter: {
                        type: 'select',
                        selectAll: false,
                        sorting: false,
                        withEmpty: true,
                        options: this.caslConsentLimitDateOptions,
                    },
                },

                'customer:casl_consent_status': {
                    valid: this.$feature.isEnabled('casl_compliance'),
                    title: i18n.t('consent.consentStatus'),
                    style: { width: '135px' },
                    component: 'ConsentStatus',
                    filter: {
                        type: 'select',
                        baseTrans: 'consent.states',
                        withEmpty: true,
                        options: [CaslConsentState.CONFIRMED, CaslConsentState.TEMPORARY, CaslConsentState.EXPIRED],
                    },
                },

                contact_method: {
                    title: 'Contact',
                    style: { width: this.accountHasNioText ? '135px' : '105px' },
                    baseClass: 'whitespace-nowrap',
                    component: 'contact-method',
                    filter: {
                        type: 'select',
                        multiple: true,
                        baseTrans: 'client',
                        options: this.contactMethodOptions,
                    },
                },

                created_by: {
                    title: i18n.t('dashboards.createdBy'),
                    style: { width: '120px' },
                    formater: formater.formatCreatedBy,
                    filter: {
                        sorting: false,
                        type: 'select',
                        multiple: true,
                        options: this.activeUsersAndSupplierUsers,
                        withNone: true,
                        noneLabel: 'Activix CRM',
                    },
                },

                updated_by: {
                    title: i18n.t('dashboards.updatedBy'),
                    style: { width: '120px' },
                    formater: formater.formatUpdatedBy,
                    filter: {
                        sorting: false,
                        type: 'select',
                        multiple: true,
                        options: this.activeUsersAndSupplierUsers,
                        withNone: true,
                        noneLabel: 'Activix CRM',
                    },
                },

                created_method: {
                    valid: this.hasClientCardField('general_info', 'created_method'),
                    title: i18n.t('dashboards.createdMethodString'),
                    style: { width: '100px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatCreatedMethod(lead);
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        baseTrans: 'createdMethods',
                        options: [
                            CreatedMethod.ACTIVIX,
                            CreatedMethod.ACURA_CANADA,
                            CreatedMethod.API,
                            CreatedMethod.AUTO_RENEWAL,
                            CreatedMethod.CDK,
                            CreatedMethod.CT_WIZARD,
                            CreatedMethod.DEALER_VU,
                            CreatedMethod.EASYDEAL,
                            CreatedMethod.F_C_A_DIGITAL,
                            CreatedMethod.FORD_DIRECT,
                            CreatedMethod.HONDA_CANADA,
                            CreatedMethod.KIA_DIGITAL,
                            CreatedMethod.M_B_C_A_DIGITAL,
                            CreatedMethod.MANUAL,
                            CreatedMethod.MANUAL_IMPORT,
                            CreatedMethod.MESSENGER,
                            CreatedMethod.N_C_I_DIGITAL,
                            CreatedMethod.NIOTEXT,
                            CreatedMethod.PHONE_SYSTEM,
                            CreatedMethod.POLARIS_DIGITAL,
                            CreatedMethod.PORSCHE_DIGITAL,
                            CreatedMethod.PORSCHE_FINDER,
                            CreatedMethod.S_C_I_MARKETVIEW,
                            CreatedMethod.SCAN,
                            CreatedMethod.SCRAPER,
                            CreatedMethod.SERTI,
                            CreatedMethod.HARLEY_DAVIDSON_DIGITAL,
                            CreatedMethod.POLESTAR,
                            CreatedMethod.MASERATI_DIGITAL,
                        ],
                    },
                },

                csi: {
                    valid: this.hasClientCardField('process', 'csi') && this.contextAccount.hasOption('csi'),
                    title: i18n.t('clientCard.csi'),
                    style: { width: '75px' },
                    component: 'csi',
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                csi_date: {
                    valid: this.hasClientCardField('process', 'csi') && this.contextAccount.hasOption('csi'),
                    style: { width: '120px' },
                    formater: lead => {
                        return formater.formatCsiDate(lead);
                    },
                    filter: {
                        type: 'text',
                    },
                },

                ...this.customFieldColumns,

                date: {
                    title: 'Date',
                    style: { width: '100px' },
                    formater: lead => {
                        return formater.formatDate(lead, this.parsedStartDate, this.parsedEndDate, this.dashboardType);
                    },
                    filterTooltip: i18n.t('dashboards.dateFilterWithoutYearTooltip'),
                    tooltip: this.dateColumnTooltip,
                    filter: {
                        type: 'text',
                    },
                },

                dealer_tour: {
                    valid: this.hasClientCardField('performance', 'dealer_tour'),
                    title: i18n.t('clientCard.dealerTour'),
                    style: { width: '75px' },
                    component: 'dealer-tour',
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                deliverable: {
                    valid: this.hasClientCardField('process', 'deliverable'),
                    title: i18n.t('clientCard.deliverableDate'),
                    style: { width: '90px' },
                    component: 'deliverable',
                    filter: {
                        type: 'select',
                        withEmpty: this.dashboardType != 'saleTable',
                        multiple: this.dashboardType == 'saleTable',
                        options: this.saleTableDateOptions,
                        sorting: false,
                    },
                },

                delivered: {
                    valid: this.hasClientCardField('process', 'delivered'),
                    title: i18n.t('result.delivered'),
                    style: { width: '75px' },
                    component: 'delivered',
                    filter: {
                        type: 'select',
                        withEmpty: this.dashboardType != 'saleTable',
                        multiple: this.dashboardType == 'saleTable',
                        options: this.saleTableDateOptions,
                        sorting: false,
                    },
                },

                'delivered_by:': {
                    valid: this.contextAccount.hasOption('delivered_by'),
                    title: i18n.t('clientCard.deliveredBy'),
                    style: { width: '120px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatDeliveredBy(lead);
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        withNone: true,
                        options: this.usersOrAccounts,
                    },
                },

                delivery: {
                    valid: this.hasClientCardField('process', 'delivery'),
                    title: i18n.t('clientCard.deliveryDate'),
                    style: { width: '95px' },
                    component: 'delivery',
                    filter: {
                        type: 'select',
                        withEmpty: this.dashboardType != 'saleTable',
                        multiple: this.dashboardType == 'saleTable',
                        options: this.saleTableDateOptions,
                        sorting: false,
                    },
                },

                deposit: {
                    valid: this.hasClientCardField('performance', 'deposit'),
                    title: i18n.t('result.deposit'),
                    style: { width: '75px' },
                    component: 'deposit',
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                write_up: {
                    valid: this.hasClientCardField('performance', 'write_up'),
                    title: i18n.t('clientCard.writeUp'),
                    style: { width: '75px' },
                    component: 'writeUp',
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                discounted_date: {
                    title: i18n.tc('clientCard.discounted', 1),
                    style: { width: '85px' },
                    component: 'discounted',
                    filter: {
                        type: 'select',
                        options: this.discountedOptions,
                        sorting: false,
                        withEmpty: !['saleTable', 'commercial'].includes(this.dashboardType),
                        multiple: ['saleTable', 'commercial'].includes(this.dashboardType),
                    },
                },

                'division:name': {
                    valid: this.hasClientCardField('general_info', 'division'),
                    title: 'Division',
                    style: { width: '100px' },
                    mandatory: true,
                    truncating: true,
                    formater: lead => {
                        return formater.formatDivision(lead);
                    },
                    filter: {
                        type: 'select',
                        baseTrans: 'divisions',
                        multiple: true,
                        withNone: true,
                        selectAll: true,
                        options: this.divisionOptions,
                    },
                },

                end_service_date: {
                    title: i18n.t('clientCard.endServiceDate'),
                    style: { width: '140px' },
                    formater: lead => {
                        return as_locale(lead.end_service_date, 'end_service_date').humanShort() || '-';
                    },
                    filterTooltip: i18n.t('dashboards.dateFilterTooltip'),
                    filter: {
                        type: 'text',
                    },
                },

                evaluated: {
                    title: i18n.t('history.lead.evaluated'),
                    style: { width: '120px' },
                    formater: lead => {
                        return formater.formatEvaluated(lead);
                    },
                    filter: {
                        type: 'select',
                        optionType: 'bool',
                    },
                },

                'exchange_vehicles:accidented_damage_cost': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('general_info', 'accidented_damage_cost'),
                    title: i18n.t('clientCard.vehicles.accidented_damage_cost'),
                    style: { width: '120px' },
                    tooltip: i18n.t('general.currentVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleExchangeAccidented(lead);
                    },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'exchange_vehicles:allowed_mileage': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('exchange_vehicle', 'allowed_mileage'),
                    title: i18n.t('clientCard.vehicles.allowed_mileage'),
                    style: { width: '120px' },
                    tooltip: i18n.t('general.currentVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleExchangeAllowedKm(lead);
                    },
                    filter: {
                        type: 'text',
                    },
                },

                'exchange_vehicles:balance': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('exchange_vehicle', 'balance'),
                    title: i18n.t('clientCard.vehicles.balance'),
                    style: { width: '120px' },
                    tooltip: i18n.t('general.currentVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleExchangeBalance(lead);
                    },
                    filter: {
                        type: 'text',
                    },
                },

                'exchange_vehicles:residual': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('exchange_vehicle', 'residual'),
                    title: i18n.t('clientCard.vehicles.residual'),
                    style: { width: '120px' },
                    tooltip: i18n.t('general.currentVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleExchangeResidual(lead);
                    },
                    filter: {
                        type: 'text',
                    },
                },

                'exchange_vehicles:bank:name': {
                    valid: !this.contextAccount.isCustom(),
                    title: i18n.t('clientCard.institutionVehicle') + i18n.t('general.currentAbr'),
                    style: { width: '120px' },
                    tooltip: i18n.t('general.currentVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleInstitutionBank(lead, 'exchange');
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        withNone: true,
                        options: this.leadBankOptions,
                    },
                },

                'exchange_vehicles:calculator_appraiser': {
                    valid: !this.contextAccount.isCustom(),
                    title: i18n.t('history.vehicle.calculatorAppraiser'),
                    style: { width: '120px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleExchangeCalculatorAppraiser(lead, 'exchange');
                    },
                    filter: {
                        type: 'text',
                    },
                },

                'exchange_vehicles:category': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('exchange_vehicle', 'category'),
                    title: i18n.t('clientCard.vehicles.category') + i18n.t('general.currentAbr'),
                    style: { width: '120px' },
                    tooltip: i18n.t('general.currentVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleCategory(lead, 'exchange');
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        withNone: true,
                        baseTrans: 'clientCard.vehicles',
                        options: [
                            'automotive',
                            'boat',
                            'truck',
                            'van',
                            'hybride',
                            'motorcycle',
                            'watercraft',
                            'snowmobile',
                            'motorized',
                            'caravan',
                            'trailer',
                            'utility',
                            'atv',
                            'suv',
                            'bike',
                            'mechanical',
                        ],
                    },
                },

                'exchange_vehicles:category_rv': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('exchange_vehicle', 'category_rv'),
                    title: i18n.t('clientCard.vehicles.categoryRV') + i18n.t('general.currentAbr'),
                    style: { width: '120px' },
                    tooltip: i18n.t('general.currentVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleCategoryRV(lead, 'exchange');
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        withNone: true,
                        baseTrans: 'clientCard.vehicles',
                        options: [
                            'class_a_diesel',
                            'hybride',
                            'motorized_a',
                            'motorized_b',
                            'motorized_c',
                            'travel_trailer',
                            'fifth_wheel',
                            'trailer_park',
                            'tent_trailer',
                        ],
                    },
                },

                'exchange_vehicles:certified': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('exchange_vehicle', 'certified'),
                    title: i18n.t('clientCard.certified') + i18n.t('general.currentAbr'),
                    style: { width: '120px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleCertified(lead, 'exchange');
                    },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'exchange_vehicles:color_exterior': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('exchange_vehicle', 'color_exterior'),
                    title: i18n.t('clientCard.vehicles.color') + i18n.t('general.currentAbr'),
                    style: { width: '120px' },
                    tooltip: i18n.t('general.currentVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleColor(lead, 'exchange', 'color_exterior');
                    },
                    filter: {
                        type: 'text',
                    },
                },

                'exchange_vehicles:color_interior': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('exchange_vehicle', 'color_interior'),
                    title: `${i18n.t('accounts.edit.colorInterior') + i18n.t('general.currentAbr')}`,
                    style: { width: '150px' },
                    tooltip: i18n.t('general.currentVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleColor(lead, 'exchange', 'color_interior');
                    },
                    filter: {
                        type: 'text',
                    },
                },

                'exchange_vehicles:condition': {
                    title: `${i18n.t('accounts.edit.condition') + i18n.t('general.currentAbr')}`,
                    style: { width: '120px' },
                    tooltip: i18n.t('general.currentVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleExchangeCondition(lead);
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        withNone: true,
                        options: this.conditionOptions,
                    },
                },

                'exchange_vehicles:end_contract_date': {
                    // TODO TEST NAMING END_CONTRACT VS END_CONTRACT_DATE
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('exchange_vehicle', 'end_contract_date'),
                    title: `${i18n.t('clientCard.vehicles.contractEnd') + i18n.t('general.currentAbr')}`,
                    style: { width: '150px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleEndContract(lead, 'exchange');
                    },
                    filterTooltip: i18n.t('dashboards.dateFilterTooltip'),
                    filter: {
                        type: 'text',
                    },
                },

                'exchange_vehicles:end_warranty_date': {
                    valid: !this.contextAccount.isCustom(),
                    title: i18n.t('clientCard.vehicles.endWarrantyDate') + i18n.t('general.currentAbr'),
                    style: { width: '130px' },
                    formater: lead => {
                        return formater.formatVehicleEndWarrantyDate(lead);
                    },
                    filterTooltip: i18n.t('dashboards.dateFilterTooltip'),
                    filter: {
                        type: 'text',
                    },
                },

                'exchange_vehicles:equity': {
                    valid: !this.contextAccount.isCustom(),
                    title: i18n.t('clientCard.vehicles.equity'),
                    style: { width: '120px' },
                    tooltip: i18n.t('general.currentVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleExchangeEquity(lead);
                    },
                    filter: {
                        type: 'text',
                    },
                },

                'exchange_vehicles:extended_warranty': {
                    valid: !this.contextAccount.isCustom(),
                    title: `${i18n.t('clientCard.vehicleExchangeExtendedWarranty') + i18n.t('general.currentAbr')}`,
                    style: { width: '130px' },
                    formater: lead => {
                        return formater.formatVehicleExchangeExtendedWarranty(lead);
                    },
                    filter: {
                        type: 'text',
                    },
                },

                'exchange_vehicles:frequency': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('exchange_vehicle', 'frequency'),
                    title: i18n.t('clientCard.vehicles.frequency'),
                    style: { width: '120px' },
                    tooltip: i18n.t('general.currentVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleExchangeFrequency(lead);
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        withNone: true,
                        baseTrans: 'filters.vehicle_frequency',
                        options: ['one_payment', 'weekly', 'two_weeks', 'bi_monthly', 'monthly'],
                    },
                },

                'exchange_vehicles:fuel': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('exchange_vehicle', 'fuel'),
                    title: i18n.t('clientCard.vehicles.fuel') + i18n.t('general.currentAbr'),
                    style: { width: '120px' },
                    tooltip: i18n.t('general.currentVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleFuel(lead, 'exchange');
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        withNone: true,
                        options: this.fuelOptions,
                    },
                },

                'exchange_vehicles:intention': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('exchange_vehicle', 'intention'),
                    title: i18n.t('clientCard.vehicles.intention'),
                    style: { width: '120px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleExchangeIntention(lead);
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        withNone: true,
                        baseTrans: 'clientCard.vehicles',
                        options: ['renewal', 'refinance', 'buyBack', 'return'],
                    },
                },

                'exchange_vehicles:length_max': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('exchange_vehicle', 'length'),
                    title: `${i18n.t('clientCard.vehicles.length')} max.${i18n.t('general.currentAbr')}`,
                    style: { width: '125px' },
                    tooltip: i18n.t('general.currentVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleLength(lead, 'exchange', 'max');
                    },
                    filter: {
                        type: 'text',
                    },
                },

                'exchange_vehicles:length_min': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('exchange_vehicle', 'length'),
                    title: `${i18n.t('clientCard.vehicles.length')} min.${i18n.t('general.currentAbr')}`,
                    style: { width: '125px' },
                    tooltip: i18n.t('general.currentVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleLength(lead, 'exchange', 'min');
                    },
                    filter: {
                        type: 'text',
                    },
                },

                'exchange_vehicles:license_plate': {
                    valid: !this.contextAccount.isCustom(),
                    title: i18n.t('clientCard.vehicleExchangeLicensePlate'),
                    style: { width: '120px' },
                    formater: lead => {
                        return formater.formatVehicleExchangeLicensePlate(lead);
                    },
                    filter: {
                        type: 'string',
                    },
                },

                'exchange_vehicles:make': {
                    valid: !this.contextAccount.isCustom(),
                    title: i18n.t('general.make') + i18n.t('general.currentAbr'),
                    style: { width: '120px' },
                    tooltip: i18n.t('general.currentVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleMake(lead, 'exchange');
                    },
                    filter: {
                        type: 'string',
                    },
                },

                'exchange_vehicles:mechanical': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('exchange_vehicle', 'category'),
                    title: i18n.t('clientCard.vehicles.mechanical') + i18n.t('general.currentAbr'),
                    style: { width: '120px' },
                    tooltip: i18n.t('general.currentVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleMechanical(lead, 'exchange');
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        withNone: true,
                        baseTrans: 'clientCard.vehicles',
                        options: [
                            'generator',
                            'outside',
                            'water_pump',
                            'blower',
                            'border_size',
                            'motoculteur',
                            'mower',
                        ],
                    },
                },

                'exchange_vehicles:mileage': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('exchange_vehicle', 'mileage'),
                    title: i18n.t('clientCard.vehicles.mileage'),
                    style: { width: '120px' },
                    tooltip: i18n.t('general.currentVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleExchangeMileage(lead);
                    },
                    filter: {
                        type: 'text',
                    },
                },

                'exchange_vehicles:mo_remaining': {
                    valid: !this.contextAccount.isCustom(),
                    title: i18n.tc('clientCard.vehicles.monthsRemaining', 2),
                    style: { width: '120px' },
                    tooltip: i18n.t('general.currentVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleExchangeMoRemaining(lead);
                    },
                    filter: {
                        type: 'number',
                    },
                },

                'exchange_vehicles:modality': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('exchange_vehicle', 'modality'),
                    title: i18n.t('clientCard.vehicles.modality') + i18n.t('general.currentAbr'),
                    style: { width: '120px' },
                    tooltip: i18n.t('general.currentVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleModality(lead, this.dashboardType, 'exchange');
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        withNone: true,
                        baseTrans: 'clientCard.vehicles',
                        options: ['financing', 'leasing', 'cash'],
                    },
                },

                'exchange_vehicles:model': {
                    valid: !this.contextAccount.isCustom(),
                    title: i18n.t('general.model') + i18n.t('general.currentAbr'),
                    style: { width: '120px' },
                    tooltip: i18n.t('general.currentVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleModel(lead, 'exchange');
                    },
                    filter: {
                        type: 'string',
                    },
                },

                'exchange_vehicles:payment': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('exchange_vehicle', 'payment'),
                    title: i18n.t('clientCard.vehicles.payment'),
                    style: { width: '120px' },
                    tooltip: i18n.t('general.currentVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleExchangePayment(lead);
                    },
                    filter: {
                        type: 'text',
                    },
                },

                'exchange_vehicles:payment_with_tax': {
                    valid: this.accountIsDealer && this.hasClientCardField('exchange_vehicle', 'payment_with_tax'),
                    title: i18n.t('clientCard.vehicles.paymentWithTax') + i18n.t('general.currentAbr'),
                    style: { width: '160px' },
                    tooltip: i18n.t('general.currentVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleExchangePaymentWithTax(lead);
                    },
                    filter: {
                        type: 'text',
                    },
                },

                'exchange_vehicles:purchase_date': {
                    valid: !this.contextAccount.isCustom(),
                    title: i18n.t('clientCard.vehicleExchangePurchaseDate'),
                    style: { width: '120px' },
                    formater: lead => {
                        return formater.formatVehicleExchangePurchaseDate(lead);
                    },
                    filterTooltip: i18n.t('dashboards.dateFilterTooltip'),
                    filter: {
                        type: 'text',
                    },
                },

                'exchange_vehicles:rate': {
                    valid: !this.contextAccount.isCustom(),
                    title: i18n.t('accounts.edit.rate'),
                    style: { width: '120px' },
                    formater: lead => {
                        return formater.formatVehicleRate(lead, 'exchange');
                    },
                    filter: {
                        type: 'text',
                    },
                },

                'exchange_vehicles:recall': {
                    valid: !this.contextAccount.isCustom(),
                    title: i18n.t('clientCard.vehicleExchangeRecall'),
                    style: { width: '120px' },
                    formater: lead => {
                        return formater.formatVehicleExchangeRecall(lead);
                    },
                    filter: {
                        type: 'text',
                    },
                },

                'exchange_vehicles:sold_by': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('exchange_vehicle', 'sold_by'),
                    title: i18n.t('renewal.soldBy'),
                    style: { width: '120px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleExchangeSoldBy(lead);
                    },
                    filter: {
                        type: 'string',
                    },
                },

                'exchange_vehicles:term': {
                    valid: !this.contextAccount.isCustom(),
                    title: i18n.t('clientCard.vehicles.term'),
                    style: { width: '120px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleTerm(lead, 'exchange');
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        options: LeadVehicleTerm.selectOptions('value', 'text'),
                        optionType: 'entities',
                        withNone: true,
                    },
                },

                'exchange_vehicles:tire_type': {
                    valid: !this.contextAccount.isCustom(),
                    title: i18n.t('clientCard.vehicles.tire_type') + i18n.t('general.currentAbr'),
                    style: { width: '100px' },
                    tooltip: i18n.t('clientCard.vehicles.tireTypeTooltip'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleTireType(lead, 'exchange');
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        withNone: true,
                        options: [
                            {
                                value: 'summer',
                                text: i18n.t('clientCard.vehicles.tireType.summer'),
                            },
                            {
                                value: 'winter',
                                text: i18n.t('clientCard.vehicles.tireType.winter'),
                            },
                        ],
                    },
                },

                'exchange_vehicles:trade_type': {
                    valid:
                        !this.contextAccount.isCustom() &&
                        this.contextAccount.trade_report &&
                        (this.authUser.trade_report || this.authUser.isAdmin()),
                    title: i18n.t('clientCard.vehicles.trade_type'),
                    style: { width: '120px' },
                    formater: lead => {
                        return formater.formatVehicleExchangeTradeType(lead);
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        baseTrans: 'clientCard.vehicles.tradeTypes',
                        withNone: true,
                        options: ['retail', 'wholesale', 'return', 'recycled', 'lost', 'excluded', 'other'],
                    },
                },

                'exchange_vehicles:transmission': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('exchange_vehicle', 'transmission'),
                    title: i18n.t('service.categories.transmission') + i18n.t('general.currentAbr'),
                    style: { width: '120px' },
                    tooltip: i18n.t('general.currentVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleTransmission(lead, 'exchange');
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        withNone: true,
                        baseTrans: 'clientCard.vehicles',
                        options: ['auto', 'manual', 'sequential'],
                    },
                },

                'exchange_vehicles:version': {
                    valid: !this.contextAccount.isCustom(),
                    title: i18n.t('clientCard.vehicles.version') + i18n.t('general.currentAbr'),
                    style: { width: '120px' },
                    tooltip: i18n.t('general.currentVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleTrim(lead, 'exchange');
                    },
                    filter: {
                        type: 'string',
                    },
                },

                'exchange_vehicles:value': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('exchange_vehicle', 'value'),
                    title: i18n.t('clientCard.vehicles.value'),
                    style: { width: '100px' },
                    tooltip: i18n.t('general.currentVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleExchangeValue(lead);
                    },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'exchange_vehicles:actual_value': {
                    valid: !this.contextAccount.isCustom() &&
                        this.contextAccount.trade_report &&
                        this.hasClientCardField('exchange_vehicle', 'actual_value'),
                    title: i18n.t('clientCard.vehicles.actual_value'),
                    tooltip: i18n.t('general.currentVehicle'),
                    style: { width: '100px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleActualValue(lead, 'exchange');
                    },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'exchange_vehicles:calculator_value': {
                    valid:
                        !this.contextAccount.isCustom() &&
                        this.calculatorValueVisible &&
                        (this.contextAccount.hasSupplier(Supplier.VAUTO) ||
                        this.contextAccount.hasSupplier(Supplier.AUTOPROPULSION)),
                    title: i18n.t('clientCard.vehicles.calculator_value') + i18n.t('general.currentAbr'),
                    style: { width: '100px' },
                    tooltip: i18n.t('general.currentVehicle'),
                    truncating: false,
                    formater: lead => {
                        return formater.formatVehicleExchangeSupplierValue(lead);
                    },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'exchange_vehicles:vin': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('exchange_vehicle', 'vin'),
                    title: i18n.t('clientCard.vehicles.vin') + i18n.t('general.currentAbr'),
                    style: { width: '150px' },
                    tooltip: i18n.t('general.currentVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleVin(lead, 'exchange');
                    },
                    filter: {
                        type: 'string',
                    },
                },

                'exchange_vehicles:warranty': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('exchange_vehicle', 'warranty'),
                    title: i18n.t('clientCard.vehicles.warranty'),
                    style: { width: '120px' },
                    tooltip: i18n.t('general.currentVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleExchangeWarranty(lead);
                    },
                    filter: {
                        type: 'text',
                    },
                },

                'exchange_vehicles:weight': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('exchange_vehicle', 'weight'),
                    title: i18n.t('clientCard.vehicles.weight') + i18n.t('general.currentAbr'),
                    style: { width: '100px' },
                    tooltip: i18n.t('general.currentVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleWeight(lead, 'exchange');
                    },
                    filter: {
                        type: 'string',
                    },
                },

                'exchange_vehicles:year': {
                    valid: !this.contextAccount.isCustom(),
                    title: i18n.t('general.year') + i18n.t('general.currentAbr'),
                    style: { width: '120px' },
                    tooltip: i18n.t('general.currentVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleYear(lead, 'exchange');
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        withNone: true,
                        selectAll: false,
                        optionType: 'years',
                    },
                },

                followed: {
                    title: i18n.t('general.followUp'),
                    style: { width: this.accountHasMessenger ? '190px' : '160px' },
                    component: 'followed',
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        baseTrans: 'followUp',
                        options: this.followedOptions,
                    },
                },

                followed_by: {
                    title: i18n.t('general.followBy'),
                    style: { width: '190px' },
                    component: 'followed-by',
                    filter: {
                        type: 'select',
                        multiple: true,
                        withNone: false,
                        sorting: false,
                        options: this.activeUsers,
                    },
                },

                form: {
                    valid: this.hasClientCardField('general_info', 'lead_form'),
                    title: i18n.t('clientCard.leadForm'),
                    style: { width: '120px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatLeadForm(lead);
                    },
                    filter: {
                        type: 'string',
                    },
                },

                fullname: {
                    title: this.clientColumnTitle,
                    style: { width: '160px' },
                    mandatory: true,
                    component: 'client-name',
                    filter: {
                        type: 'string',
                    },
                },

                gas: {
                    valid: this.hasClientCardField('process', 'gas'),
                    title: i18n.t('clientCard.gas'),
                    style: { width: '75px' },
                    component: 'gas',
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'import:original_file': {
                    title: i18n.t('general.importFile'),
                    style: { width: '150px' },
                    tooltip: i18n.t('dashboards.importFileTooltip'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatImportFile(lead);
                    },
                    filter: {
                        type: 'string',
                    },
                },

                inspected: {
                    valid: this.hasClientCardField('process', 'inspected'),
                    title: i18n.t('clientCard.inspected'),
                    style: { width: '75px' },
                    component: 'inspected',
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                invoiced: {
                    title: i18n.t('clientCard.invoiced'),
                    style: { width: '120px' },
                    formater: lead => {
                        return formater.formatInvoiced(lead);
                    },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                keyword: {
                    valid: this.hasClientCardField('general_info', 'keyword'),
                    title: i18n.t('clientCard.keyword'),
                    style: { width: '120px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatKeyword(lead);
                    },
                    filter: {
                        type: 'string',
                    },
                },

                last_presented_date: {
                    title: i18n.t('service.columns.lastPresentedDate'),
                    style: { width: '120px' },
                    formater: lead => {
                        return formater.formatLastPresentedDate(lead);
                    },
                    filterTooltip: i18n.t('dashboards.dateFilterTooltip'),
                    filter: {
                        type: 'text',
                    },
                },

                'latest_comment:content': {
                    title: i18n.t('general.notes'),
                    style: { width: '250px' },
                    filter: {
                        type: 'string',
                    },
                },

                'lead_bank:name': {
                    valid: this.hasClientCardField('process', 'lead_bank'),
                    title: i18n.t('clientCard.institution'),
                    style: { width: '120px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatInstitutionBank(lead);
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        withNone: true,
                        sorting: true,
                        options: this.leadBankOptions,
                    },
                },

                lead_state: {
                    title: i18n.t('dashboards.leadStateString'),
                    style: { width: '150px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatLeadState(lead);
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        baseTrans: 'dashboards.leadState',
                        withNone: true,
                        options: ['toCheck', 'merged', 'associated', 'bothStates'],
                    },
                },

                'lead_type:name': {
                    valid: this.hasClientCardField('general_info', 'lead_type'),
                    title: i18n.t('client.type'),
                    style: { width: '100px' },
                    formater: lead => {
                        return formater.formatType(lead);
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        baseTrans: 'filters.lead_type',
                        options: this.leadTypeOptions,
                    },
                },

                listening: {
                    title: i18n.t('general.media'),
                    style: { width: '100px' },
                    component: 'communication-media',
                    filter: {
                        type: 'select',
                        withNone: true,
                        multiple: true,
                        sorting: false,
                        options: this.mediaOptions,
                    },
                },

                locale: {
                    title: i18n.t('general.lang'),
                    style: { width: '80px' },
                    formater: lead => {
                        return formater.formatLocale(lead);
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        options: this.localeOptions,
                    },
                },

                loyalty: {
                    title: i18n.t('clientCard.loyalty'),
                    style: { width: '75px' },
                    component: 'loyalty',
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                maintenance: {
                    title: i18n.t('service.columns.maintenance'),
                    style: { width: '90px' },
                    component: 'service-maintenance',
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                next_presented_date: {
                    title: i18n.t('service.columns.nextPresentedDate'),
                    style: { width: '120px' },
                    formater: lead => {
                        return formater.formatNextPresentedDate(lead);
                    },
                    filterTooltip: i18n.t('dashboards.dateFilterTooltip'),
                    filter: {
                        type: 'text',
                    },
                },

                open_work_order_date: {
                    title: i18n.t('clientCard.openWorkOrderDate'),
                    style: { width: '120px' },
                    formater: lead => {
                        return as_locale(lead.open_work_order_date, 'open_work_order_date').humanShort() || '-';
                    },
                    filterTooltip: i18n.t('dashboards.dateFilterTooltip'),
                    filter: {
                        type: 'text',
                    },
                },

                work_order_partial_closure_date: {
                    title: i18n.t('clientCard.workOrderPartialClosureDate'),
                    style: { width: '120px' },
                    formater: lead => {
                        return as_locale(lead.work_order_partial_closure_date, 'work_order_partial_closure_date').humanShort() || '-';
                    },
                    filterTooltip: i18n.t('dashboards.dateFilterTooltip'),
                    filter: {
                        type: 'text',
                    },
                },

                work_order_closure_date: {
                    title: i18n.t('clientCard.workOrderClosureDate'),
                    style: { width: '120px' },
                    formater: lead => {
                        return as_locale(lead.work_order_closure_date, 'work_order_closure_date').humanShort() || '-';
                    },
                    filterTooltip: i18n.t('dashboards.dateFilterTooltip'),
                    filter: {
                        type: 'text',
                    },
                },

                oversale: {
                    title: i18n.t('service.columns.overSale'),
                    style: { width: '75px' },
                    component: 'service-over-sale',
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                phone_destination: {
                    title: i18n.t('result.destination'),
                    style: { width: '120px' },
                    fixed: true,
                    truncating: true,
                    formater: lead => {
                        return formater.formatPhoneDestination(lead);
                    },
                    filter: {
                        type: 'custom',
                    },
                },

                phone_origin: {
                    title: i18n.t('result.origin'),
                    style: { width: '120px' },
                    fixed: true,
                    truncating: true,
                    formater: lead => {
                        return formater.formatPhoneOrigin(lead);
                    },
                    filter: {
                        type: 'custom',
                    },
                },

                planned_pick_up_date: {
                    title: i18n.t('clientCard.plannedPickUpDate'),
                    style: { width: '140px' },
                    formater: lead => {
                        return as_locale(lead.planned_pick_up_date, 'planned_pick_up_date').humanShort() || '-';
                    },
                    filterTooltip: i18n.t('dashboards.dateFilterTooltip'),
                    filter: {
                        type: 'text',
                    },
                },

                postal_code: {
                    title: i18n.t(`general.postalCode.${this.contextAccount.country || 'CA'}`),
                    style: { width: '90px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatPostalCode(lead);
                    },
                    filter: {
                        type: 'string',
                    },
                },

                prepaid: {
                    title: i18n.t('clientCard.prepaid'),
                    style: { width: '75px' },
                    component: 'prepaid',
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                prepared: {
                    valid: this.hasClientCardField('process', 'prepared'),
                    title: i18n.t('clientCard.prepared'),
                    style: { width: '75px' },
                    component: 'prepared',
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                prepared_work_order: {
                    valid: this.hasClientCardField('process', 'prepared_work_order'),
                    title: i18n.t('clientCard.prepared_work_order'),
                    style: { width: '120px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatProgressWorkOrder(lead);
                    },
                    filter: {
                        type: 'string',
                    },
                },

                presented: {
                    valid: this.hasClientCardField('performance', 'presented'),
                    title: i18n.t('result.presentedColumn'),
                    style: { width: '75px' },
                    component: 'presented',
                    filter: {
                        multiple: this.dashboardType !== 'service',
                        type: 'select',
                        options: this.presentedOptions,
                        withNone: this.dashboardType !== 'service',
                        withEmpty: this.dashboardType === 'service',
                        sorting: false,
                    },
                },

                progress_state: {
                    valid: this.hasClientCardField('process', 'progress_state'),
                    title: i18n.t('clientCard.state'),
                    style: { width: '120px' },
                    tooltip: i18n.t('saleTable.progressStateTooltip'),
                    formater: lead => {
                        return formater.formatProgressState(lead);
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        withNone: true,
                        baseTrans: 'commercial',
                        options: ProgressState.values,
                    },
                },

                promised_date: {
                    title: i18n.t('clientCard.promisedDate'),
                    style: { width: '120px' },
                    formater: lead => {
                        return as_locale(lead.promised_date, 'promised_date').humanShort() || '-';
                    },
                    filterTooltip: i18n.t('dashboards.dateFilterTooltip'),
                    filter: {
                        type: 'text',
                    },
                },

                provider: {
                    title: i18n.t('leadFilter.provider'),
                    style: { width: '175px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatProvider(lead, this.providerOptions);
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        withNone: true,
                        options: this.providerOptions,
                    },
                },

                province: {
                    title: i18n.t(`general.province.${this.contextAccount.country || 'CA'}`),
                    style: { width: '100px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatProvince(lead);
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        withNone: true,
                        options: this.provinceOptions,
                    },
                },

                country: {
                    title: i18n.t('general.country'),
                    style: { width: '100px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatCountry(lead);
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        withNone: true,
                        options: this.countryOptions,
                    },
                },

                qualification: {
                    valid: this.hasClientCardField('performance', 'qualification'),
                    title: i18n.t('clientCard.qualification'),
                    style: { width: '120px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatQualification(lead);
                    },
                    filter: {
                        type: 'string',
                    },
                },

                rating: {
                    valid: this.hasClientCardField('general_info', 'rating'),
                    title: i18n.t('clientCard.rating'),
                    style: { width: '90px' },
                    tooltip: i18n.t('clientCard.ratingTooltip'),
                    component: 'rating',
                    filter: {
                        type: 'select',
                        multiple: true,
                        withNone: true,
                        options: [1, 2, 3, 4, 5].map(rating => {
                            return {
                                value: rating,
                                text: rating,
                            };
                        }),
                    },
                },

                referrer: {
                    valid: this.hasClientCardField('general_info', 'referrer'),
                    title: i18n.t('clientCard.referrer'),
                    style: { width: '120px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatReferrer(lead);
                    },
                    filter: {
                        type: 'string',
                    },
                },

                refinanced: {
                    valid: this.hasClientCardField('performance', 'refinanced'),
                    title: i18n.t('dashboards.performance.refinanced'),
                    style: { width: '75px' },
                    component: 'refinanced',
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                repair_date: {
                    title: i18n.t('clientCard.repairDate'),
                    style: { width: '120px' },
                    formater: lead => {
                        return as_locale(lead.repair_date, 'repair_date').humanShort() || '-';
                    },
                    filterTooltip: i18n.t('dashboards.dateFilterTooltip'),
                    filter: {
                        type: 'text',
                    },
                },

                renewal_alert_date: {
                    valid: !this.contextAccount.isCustom(),
                    title: i18n.t('dashboards.alertDate'),
                    style: { width: '120px' },
                    filterTooltip: i18n.t('dashboards.dateFilterTooltip'),
                    component: 'renewal-alert-notification-date',
                    filter: {
                        type: 'text',
                    },
                },

                renewal_alert_types: {
                    valid: !this.contextAccount.isCustom(),
                    title: i18n.t('dashboards.alertTypes.columnName'),
                    style: { width: '120px' },
                    component: 'renewal-alert-notification-type',
                    filter: {
                        type: 'select',
                        multiple: true,
                        options: [
                            {
                                value: 'renewalAlertEquity',
                                text: i18n.t('dashboards.alertTypes.equity'),
                            },
                            {
                                value: 'renewalAlertEndLocation',
                                text: i18n.t('dashboards.alertTypes.location'),
                            },
                            {
                                value: 'renewalAlertEndWarranty',
                                text: i18n.t('dashboards.alertTypes.warranty'),
                            },
                        ],
                    },
                },

                repair_order: {
                    title: i18n.t('clientCard.repairOrder'),
                    style: { width: '120px' },
                    formater: lead => {
                        return formater.formatRepairOrder(lead);
                    },
                    filter: {
                        type: 'string',
                    },
                },

                result: {
                    title: i18n.t('clientCard.result'),
                    style: { width: '100px' },
                    component: 'result-time',
                    filter: {
                        type: 'select',
                        multiple: true,
                        baseTrans: 'result',
                        options: ['pending', 'attempted', 'reached'],
                    },
                },

                result_wb: {
                    title: i18n.t('general.result'),
                    style: { width: '140px' },
                    component: 'result-web-boost',
                    filter: {
                        type: 'select',
                        multiple: true,
                        baseTrans: 'result',
                        options: [
                            'reached',
                            'pending',
                            'calling',
                            'invalid',
                            'attempted',
                            'error',
                            'interrupted',
                            'canceled',
                            'revive',
                            'missed',
                            'unknown',
                        ],
                    },
                },

                road_test: {
                    valid: this.hasClientCardField('performance', 'road_test'),
                    title: i18n.t('result.roadTest'),
                    style: { width: '75px' },
                    component: 'road-test',
                    filter: {
                        multiple: this.dashboardType !== 'service',
                        type: 'select',
                        options: this.roadTestOptions,
                        withNone: this.dashboardType !== 'service',
                        withEmpty: this.dashboardType === 'service',
                        sorting: false,
                    },
                },

                long_term: {
                    valid: this.hasClientCardField('performance', 'long_term'),
                    title: i18n.t('result.longTerm'),
                    style: { width: '85px' },
                    component: 'long-term',
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                sale: {
                    valid: this.hasClientCardField('performance', 'sale'),
                    title: i18n.t('result.sale'),
                    style: { width: '75px' },
                    component: 'sale',
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                sale_by_phone: {
                    valid:
                        this.hasClientCardField('performance', 'sale_by_phone') &&
                        this.contextAccount.hasOption('sale_by_phone'),
                    title: `
                        <div class="flex items-center">
                            <span class="mr-2">
                                ${i18n.t('result.sale')}
                            </span>
                            ${getIconMarkup('regular/phone-landline', { class: 'event-icon' })}
                        </div>
                    `,
                    sortTitle: i18n.t('result.sale'),
                    style: { width: '75px' },
                    exportTitle: i18n.tc('result.saleByPhone', 1),
                    component: 'sale-by-phone',
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                sale_state: {
                    title: i18n.t('clientCard.result'),
                    style: { width: '120px' },
                    component: 'sale-sticker',
                    disableSorting: true,
                    filter: {
                        type: 'select',
                        multiple: true,
                        baseTrans: 'saleTable.filters',
                        options: this.saleStateOptions,
                    },
                },

                search_term: {
                    valid: this.hasClientCardField('general_info', 'search_term'),
                    title: i18n.t('clientCard.searchTerm'),
                    style: { width: '120px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatSearchTerm(lead);
                    },
                    filter: {
                        type: 'string',
                    },
                },

                sector: {
                    valid: this.hasClientCardField('general_info', 'sector'),
                    title: i18n.t('accounts.edit.sector'),
                    style: { width: '120px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatSector(lead);
                    },
                    filter: {
                        type: 'string',
                    },
                },

                segment: {
                    valid: this.hasClientCardField('general_info', 'segment'),
                    title: i18n.t('clientCard.event.segment'),
                    style: { width: '120px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatEventSegment(lead);
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        baseTrans: 'result',
                        options: [
                            'other',
                            'conquest',
                            'loyalty',
                            'notSold',
                            'promo',
                            'service',
                            'reminder',
                            'endWarranty',
                            'endLcap',
                            'endLnette',
                            'csi',
                            'noShow',
                        ],
                    },
                },

                service_agents: {
                    title: i18n.t('service.columns.advisorAgent'),
                    style: { width: '165px' },
                    truncating: true,
                    component: 'service-advisor-agent',
                    filter: {
                        type: 'select',
                        multiple: true,
                        withNone: true,
                        options: this.activeUsers,
                    },
                },

                odometer_last_visit: {
                    title: i18n.t('service.columns.odometerLastVisit'),
                    style: { width: '120px' },
                    formater: lead => {
                        return formater.formatOdometerLastVisit(lead);
                    },
                    filter: {
                        type: 'text',
                    },
                },

                service_intention: {
                    title: i18n.t('clientCard.services.intention'),
                    style: { width: '120px' },
                    formater: lead => {
                        return formater.formatServiceIntention(lead);
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        options: this.intentionsOptions,
                    },
                },

                service_walk_around: {
                    title: i18n.t('clientCard.services.walkAround'),
                    style: { width: '150px' },
                    formater: lead => {
                        return formater.formatServiceWalkaround(lead);
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        options: [
                            { value: 'a1', text: i18n.t('clientCard.services.walkArounds.a1') },
                            { value: 'scratch', text: i18n.t('clientCard.services.walkArounds.scratch') },
                            { value: 'brokenGlass', text: i18n.t('clientCard.services.walkArounds.brokenGlass') },
                            { value: 'bump', text: i18n.t('clientCard.services.walkArounds.bump') },
                            { value: 'cleaning', text: i18n.t('clientCard.services.walkArounds.cleaning') },
                            { value: 'other', text: i18n.t('clientCard.services.walkArounds.other') },
                        ],
                    },
                },

                service_transport: {
                    title: i18n.t('clientCard.services.transport'),
                    style: { width: '120px' },
                    formater: lead => {
                        return formater.formatServiceTransport(lead);
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        options: [
                            { value: 'courtesy', text: i18n.t('clientCard.services.transports.courtesy') },
                            { value: 'drop', text: i18n.t('clientCard.services.transports.drop') },
                            { value: 'limousine', text: i18n.t('clientCard.services.transports.limousine') },
                            { value: 'other', text: i18n.t('clientCard.services.transports.other') },
                            { value: 'renting', text: i18n.t('clientCard.services.transports.renting') },
                            { value: 'wait', text: i18n.t('clientCard.services.transports.wait') },
                        ],
                    },
                },

                service_interval_km: {
                    title: i18n.t('service.columns.serviceIntervalKm'),
                    style: { width: '120px' },
                    formater: lead => {
                        return formater.formatServiceIntervalKm(lead);
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        sorting: false,
                        options: [
                            { value: 1000, text: '1000 KM' },
                            { value: 5000, text: '5000 KM' },
                            { value: 6000, text: '6000 KM' },
                            { value: 8000, text: '8000 KM' },
                            { value: 12000, text: '12000 KM' },
                            { value: 16000, text: '16000 KM' },
                            { value: 18000, text: '18000 KM' },
                            { value: 20000, text: '20000 KM' },
                            { value: 24000, text: '24000 KM' },
                            { value: 25000, text: '25000 KM' },
                        ],
                    },
                },

                service_monthly_km: {
                    title: i18n.t('service.columns.serviceMonthlyKm'),
                    style: { width: '120px' },
                    formater: lead => {
                        return formater.formatServiceMonthlyKm(lead);
                    },
                    filter: {
                        type: 'text',
                    },
                },

                'service_products:air_filter': {
                    valid: this.hasAccountProduct('air_filter'),
                    title: i18n.t('clientCard.products.air_filter'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'service_products:alignment': {
                    valid: this.hasAccountProduct('alignment'),
                    title: i18n.t('clientCard.products.alignment'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'service_products:body': {
                    valid: this.hasAccountProduct('body'),
                    title: i18n.t('clientCard.products.body'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'service_products:brakes': {
                    valid: this.hasAccountProduct('brakes'),
                    title: i18n.t('clientCard.products.brakes'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'service_products:diagnostic': {
                    valid: this.hasAccountProduct('diagnostic'),
                    title: i18n.t('clientCard.products.diagnostic'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'service_products:injection': {
                    valid: this.hasAccountProduct('injection'),
                    title: i18n.t('clientCard.products.injection'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'service_products:maintenance_a': {
                    valid: this.hasAccountProduct('maintenance_a'),
                    title: i18n.t('clientCard.products.maintenance_a'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'service_products:maintenance_b': {
                    valid: this.hasAccountProduct('maintenance_b'),
                    title: i18n.t('clientCard.products.maintenance_b'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'service_products:maintenance_c': {
                    valid: this.hasAccountProduct('maintenance_c'),
                    title: i18n.t('clientCard.products.maintenance_c'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'service_products:maintenance_d': {
                    valid: this.hasAccountProduct('maintenance_d'),
                    title: i18n.t('clientCard.products.maintenance_d'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'service_products:maintenance_recommended': {
                    valid: this.hasAccountProduct('maintenance_recommended'),
                    title: i18n.t('clientCard.products.maintenance_recommended'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'service_products:oil_filter': {
                    valid: this.hasAccountProduct('oil_filter'),
                    title: i18n.t('clientCard.products.oil_filter'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'service_products:others': {
                    valid: this.hasAccountProduct('others'),
                    title: i18n.t('clientCard.products.others'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'service_products:parts': {
                    valid: this.hasAccountProduct('parts'),
                    title: i18n.t('clientCard.products.parts'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'service_products:pollen_filter': {
                    valid: this.hasAccountProduct('pollen_filter'),
                    title: i18n.t('clientCard.products.pollen_filter'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'service_products:tires': {
                    valid: this.hasAccountProduct('tires'),
                    title: i18n.t('clientCard.products.tires'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'service_products:transmission': {
                    valid: this.hasAccountProduct('transmission'),
                    title: i18n.t('clientCard.products.transmission'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'service_products:wash': {
                    valid: this.hasAccountProduct('wash'),
                    title: i18n.t('clientCard.products.wash'),
                    style: { width: '120px' },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                source: {
                    valid: this.hasClientCardField('general_info', 'source'),
                    title: 'Source',
                    style: { width: '175px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatSource(lead, true);
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        withNone: true,
                        options: this.sourceOptions,
                    },
                },

                status: {
                    title: i18n.t('general.status'),
                    style: { width: '75px' },
                    component: 'lead-status',
                    filter: {
                        type: 'select',
                        multiple: true,
                        baseTrans: 'result',
                        withNone: true,
                        noneLabel: i18n.t('general.active'),
                        options: ['duplicate', 'invalid', 'lost'],
                    },
                },

                storage: {
                    valid: this.hasClientCardField('general_info', 'storage'),
                    title: i18n.t('service.columns.storage'),
                    style: { width: '120px' },
                    formater: lead => {
                        return formater.formatStorage(lead);
                    },
                    filter: {
                        type: 'string',
                    },
                },

                take_over: {
                    valid: this.hasClientCardField('performance', 'take_over'),
                    title: i18n.t('result.takeOver'),
                    style: { width: '75px' },
                    component: 'take-over',
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                twenty_four_hour: {
                    valid: this.hasClientCardField('performance', 'twenty_four_hour'),
                    title: i18n.t('result.twenty_four_hour'),
                    style: { width: '75px' },
                    component: 'twenty-four-hour',
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'take_over_director:': {
                    valid: this.hasClientCardField('performance', 'take_over'),
                    title: i18n.t('result.takeOverBy'),
                    style: { width: '120px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatTakeOverDirector(lead);
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        withNone: true,
                        options: () => {
                            return this.activeDirectors;
                        },
                    },
                },

                todo: {
                    title: i18n.t('result.todo'),
                    style: { width: '150px' },
                    component: 'todo',
                    filter: {
                        type: 'select',
                        multiple: true,
                        withNone: true,
                        options: this.todoOptions,
                    },
                },

                total_minutes: {
                    title: i18n.t('service.columns.totalMinutes'),
                    style: { width: '120px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatTotalMinutes(lead);
                    },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                total_price: {
                    title: i18n.t('service.columns.totalPrice'),
                    style: { width: '120px' },
                    baseClass: 'column-total-price',
                    tooltip: i18n.t('commercial.totalPrice'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatTotalPrice(lead, false, 'service');
                    },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                total_price_commercial_director: {
                    title: i18n.t('service.columns.totalPrice'),
                    style: { width: '120px' },
                    baseClass: 'column-total-price',
                    tooltip: i18n.t('commercial.totalPriceCommercialDirector'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatTotalPrice(lead, false, 'commercial');
                    },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                unsubscribe: {
                    title: i18n.t('general.optOut'),
                    style: { width: '120px' },
                    formater: lead => {
                        return formater.formatUnsubscribe(lead);
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        withNone: true,
                        baseTrans: 'optOut',
                        options: ['email', 'call', 'sms', 'dnd'],
                    },
                },

                updated_at: {
                    title: i18n.t('clientCard.updatedAt'),
                    style: { width: '100px' },
                    formater: lead => {
                        return as_locale(lead.updated_at, 'updated_at').humanShort() || '-';
                    },
                    filterTooltip: i18n.t('dashboards.dateFilterTooltip'),
                    filter: {
                        type: 'text',
                    },
                },

                walk_around: {
                    valid: this.hasClientCardField('performance', 'walk_around'),
                    title: i18n.t('clientCard.walkAround'),
                    style: { width: '75px' },
                    component: 'walk-around',
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                waiting_sale: {
                    valid: this.hasClientCardField('process', 'waiting_sale'),
                    title: i18n.tc('clientCard.waitingSale', 1),
                    style: { width: '105px' },
                    component: 'waitingSale',
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'wanted_vehicles:budget_max': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('wanted_vehicle', 'budget'),
                    title: i18n.t('clientCard.vehicles.budget_max'),
                    style: { width: '120px' },
                    tooltip: i18n.t('general.wantedVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleWantedBudget(lead, 'max');
                    },
                    filter: {
                        type: 'text',
                    },
                },

                'wanted_vehicles:budget_min': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('wanted_vehicle', 'budget'),
                    title: i18n.t('clientCard.vehicles.budget_min'),
                    style: { width: '120px' },
                    tooltip: i18n.t('general.wantedVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleWantedBudget(lead, 'min');
                    },
                    filter: {
                        type: 'text',
                    },
                },

                'wanted_vehicles:category': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('wanted_vehicle', 'category'),
                    title: i18n.t('clientCard.vehicles.category'),
                    style: { width: '120px' },
                    tooltip: i18n.t('general.wantedVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleCategory(lead);
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        baseTrans: 'clientCard.vehicles',
                        withNone: true,
                        options: [
                            'automotive',
                            'boat',
                            'truck',
                            'van',
                            'hybride',
                            'motorcycle',
                            'watercraft',
                            'snowmobile',
                            'motorized',
                            'caravan',
                            'trailer',
                            'utility',
                            'atv',
                            'suv',
                            'bike',
                            'mechanical',
                        ],
                    },
                },

                'wanted_vehicles:category_rv': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('wanted_vehicle', 'category_rv'),
                    title: i18n.t('clientCard.vehicles.categoryRV'),
                    style: { width: '120px' },
                    tooltip: i18n.t('general.wantedVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleCategoryRV(lead);
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        baseTrans: 'clientCard.vehicles',
                        withNone: true,
                        options: [
                            'class_a_diesel',
                            'hybride',
                            'motorized_a',
                            'motorized_b',
                            'motorized_c',
                            'travel_trailer',
                            'fifth_wheel',
                            'trailer_park',
                            'tent_trailer',
                        ],
                    },
                },

                'wanted_vehicles:certified': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('wanted_vehicle', 'certified'),
                    title: i18n.t('clientCard.certified') + i18n.t('general.wantedAbr'),
                    style: { width: '120px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleCertified(lead, 'wanted');
                    },
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'wanted_vehicles:color_exterior': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('wanted_vehicle', 'color_exterior'),
                    title: i18n.t('clientCard.vehicles.color') + i18n.t('general.wantedAbr'),
                    style: { width: '120px' },
                    tooltip: i18n.t('general.wantedVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleColor(lead, 'wanted', 'color_exterior');
                    },
                    filter: {
                        type: 'text',
                    },
                },

                'wanted_vehicles:color_interior': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('wanted_vehicles', 'color_interior'),
                    title: `${i18n.t('accounts.edit.colorInterior') + i18n.t('general.wantedAbr')}`,
                    style: { width: '150px' },
                    tooltip: i18n.t('general.wantedVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleColor(lead, 'wanted', 'color_interior');
                    },
                    filter: {
                        type: 'text',
                    },
                },

                'wanted_vehicles:end_contract_date': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('wanted', 'end_contract_date'),
                    title: `${i18n.t('clientCard.vehicles.contractEnd') + i18n.t('general.wantedAbr')}`,
                    style: { width: '150px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleEndContract(lead, 'wanted');
                    },
                    filterTooltip: i18n.t('dashboards.dateFilterTooltip'),
                    filter: {
                        type: 'text',
                    },
                },

                'wanted_vehicles:end_warranty_date': {
                    valid: !this.contextAccount.isCustom(),
                    title: i18n.t('clientCard.vehicles.endWarrantyDate') + i18n.t('general.wantedAbr'),
                    style: { width: '130px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleEndWarrantyDate(lead, 'wanted');
                    },
                    filterTooltip: i18n.t('dashboards.dateFilterTooltip'),
                    filter: {
                        type: 'text',
                    },
                },

                'wanted_vehicles:length_max': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('wanted_vehicle', 'length'),
                    title: `${i18n.t('clientCard.vehicles.length')} max.`,
                    style: { width: '125px' },
                    tooltip: i18n.t('general.wantedVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleLength(lead, 'wanted', 'max');
                    },
                    filter: {
                        type: 'text',
                    },
                },

                'wanted_vehicles:length_min': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('wanted_vehicle', 'length'),
                    title: `${i18n.t('clientCard.vehicles.length')} min.`,
                    style: { width: '125px' },
                    tooltip: i18n.t('general.wantedVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleLength(lead, 'wanted', 'min');
                    },
                    filter: {
                        type: 'text',
                    },
                },

                'wanted_vehicles:make': {
                    valid: !this.contextAccount.isCustom(),
                    title: i18n.t('general.make'),
                    style: { width: '120px' },
                    tooltip: i18n.t('general.wantedVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleMake(lead, 'wanted');
                    },
                    filter: {
                        type: 'string',
                    },
                },

                'wanted_vehicles:mechanical': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('wanted_vehicle', 'category'),
                    title: i18n.t('clientCard.vehicles.mechanical'),
                    style: { width: '120px' },
                    tooltip: i18n.t('general.wantedVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleMechanical(lead);
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        baseTrans: 'clientCard.vehicles',
                        withNone: true,
                        options: [
                            'generator',
                            'outside',
                            'water_pump',
                            'blower',
                            'border_size',
                            'motoculteur',
                            'mower',
                        ],
                    },
                },

                'wanted_vehicles:modality': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('wanted_vehicle', 'modality'),
                    title: i18n.t('clientCard.vehicles.modality'),
                    style: { width: '120px' },
                    tooltip: i18n.t('general.wantedVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleModality(lead, this.dashboardType, 'wanted');
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        withNone: true,
                        baseTrans: 'clientCard.vehicles',
                        options: ['financing', 'leasing', 'cash'],
                    },
                },

                'wanted_vehicles:model': {
                    valid: !this.contextAccount.isCustom(),
                    title: i18n.t('general.model'),
                    style: { width: '120px' },
                    tooltip: i18n.t('general.wantedVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleModel(lead, 'wanted');
                    },
                    filter: {
                        type: 'string',
                    },
                },

                'wanted_vehicles:offer': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('wanted_vehicle', 'offer'),
                    title: i18n.t('general.offer'),
                    style: { width: '120px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleWantedOffer(lead);
                    },
                    filter: {
                        type: 'text',
                    },
                },

                'wanted_vehicles:price': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('wanted_vehicle', 'price'),
                    title: i18n.t('general.price'),
                    style: { width: '120px' },
                    tooltip: i18n.t('general.wantedVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleWantedPrice(lead);
                    },
                    filter: {
                        type: 'text',
                    },
                },

                'wanted_vehicles:profit': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('wanted_vehicle', 'profit'),
                    title: i18n.t('clientCard.vehicles.profit'),
                    style: { width: '120px' },
                    tooltip: i18n.t('general.wantedVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleWantedProfit(lead, this.authUser);
                    },
                    filter: {
                        type: 'text',
                    },
                },

                'wanted_vehicles:rate': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('wanted_vehicle', 'rate'),
                    title: i18n.t('clientCard.vehicles.rate') + i18n.t('general.wantedAbr'),
                    style: { width: '120px' },
                    formater: lead => {
                        return formater.formatVehicleRate(lead, 'wanted');
                    },
                    filter: {
                        type: 'text',
                    },
                },

                'wanted_vehicles:recorded_date': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('process', 'recorded'),
                    title: i18n.t('saleTable.recordedDate'),
                    style: { width: '75px' },
                    component: 'recorded',
                    filter: {
                        type: 'select',
                        options: this.saleTableDateOptions,
                        sorting: false,
                        withEmpty: this.dashboardType != 'saleTable',
                        multiple: this.dashboardType == 'saleTable',
                    },
                },

                'wanted_vehicles:stock_state': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('wanted_vehicle', 'stock'),
                    title: i18n.t('general.stockState'),
                    style: { width: '100px' },
                    formater: lead => {
                        return formater.formatVehicleWantedStockState(lead);
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        withNone: true,
                        baseTrans: 'saleTable',
                        options: ['stock', 'locate', 'order', 'notAvailable'],
                    },
                },

                'wanted_vehicles:stock': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('wanted_vehicle', 'stock'),
                    title: i18n.t('general.stock'),
                    style: { width: '100px' },
                    formater: lead => {
                        return formater.formatVehicleWantedStockNumber(lead);
                    },
                    filter: {
                        type: 'string',
                    },
                },

                'wanted_vehicles:suffix': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('wanted_vehicle', 'suffix'),
                    title: i18n.t('clientCard.vehicles.suffix'),
                    style: { width: '120px' },
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleWantedSuffix(lead);
                    },
                    filter: {
                        type: 'string',
                    },
                },

                'wanted_vehicles:transmission': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('wanted_vehicle', 'transmission'),
                    title: i18n.t('service.categories.transmission'),
                    style: { width: '120px' },
                    tooltip: i18n.t('general.wantedVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleTransmission(lead, 'wanted');
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        withNone: true,
                        baseTrans: 'clientCard.vehicles',
                        options: ['auto', 'manual', 'sequential'],
                    },
                },

                'wanted_vehicles:verified': {
                    valid: !this.contextAccount.isCustom() && this.contextAccount.hasOption('verified_sale'),
                    title: i18n.t('result.verified'),
                    style: { width: '75px' },
                    component: 'verified',
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                'wanted_vehicles:version': {
                    valid: !this.contextAccount.isCustom(),
                    title: i18n.t('clientCard.vehicles.version'),
                    style: { width: '120px' },
                    tooltip: i18n.t('general.wantedVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleTrim(lead, 'wanted');
                    },
                    filter: {
                        type: 'string',
                    },
                },

                'wanted_vehicles:vin': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('wanted_vehicle', 'vin'),
                    title: i18n.t('clientCard.vehicles.vin'),
                    style: { width: '150px' },
                    tooltip: i18n.t('general.wantedVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleVin(lead, 'wanted');
                    },
                    filter: {
                        type: 'string',
                    },
                },

                'wanted_vehicles:weight': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('wanted_vehicle', 'weight'),
                    title: i18n.t('clientCard.vehicles.weight'),
                    style: { width: '100px' },
                    tooltip: i18n.t('general.wantedVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleWeight(lead, 'wanted');
                    },
                    filter: {
                        type: 'string',
                    },
                },

                'wanted_vehicles:fuel': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('wanted_vehicle', 'fuel'),
                    title: i18n.t('clientCard.vehicles.fuel'),
                    style: { width: '100px' },
                    tooltip: i18n.t('general.wantedVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleFuel(lead, 'wanted');
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        withNone: true,
                        options: this.fuelOptions,
                    },
                },

                'wanted_vehicles:year': {
                    valid: !this.contextAccount.isCustom(),
                    title: i18n.t('general.year'),
                    style: { width: '120px' },
                    tooltip: i18n.t('general.wantedVehicle'),
                    truncating: true,
                    formater: lead => {
                        return formater.formatVehicleYear(lead, 'wanted');
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        withNone: true,
                        selectAll: false,
                        optionType: 'years',
                    },
                },

                'wanted_vehicles:tire': {
                    valid: !this.contextAccount.isCustom() && this.hasClientCardField('wanted_vehicle', 'tire'),
                    title: i18n.t('clientCard.vehicles.tires'),
                    tooltip: i18n.t('clientCard.vehicles.tireTooltip'),
                    style: { width: '100px' },
                    component: 'tire',
                    filter: {
                        type: 'select',
                        withEmpty: true,
                        optionType: 'bool',
                    },
                },

                work_order: {
                    title: i18n.t('clientCard.workOrder'),
                    style: { width: '120px' },
                    formater: lead => {
                        return formater.formatWorkOrder(lead);
                    },
                    filter: {
                        type: 'string',
                    },
                },

                updated_by_supplier: {
                    title: i18n.t('allLead.updatedBySupplier'),
                    style: { width: '120px' },
                    formater: lead => {
                        return formater.formatLeadUpdatedBySupplier(lead);
                    },
                    filter: {
                        type: 'select',
                        multiple: true,
                        withNone: true,
                        options: this.suppliers,
                    },
                },
            };
        },

        customFieldColumns() {
            const customFields = {};

            this.contextAccount.getAllCustomFields().forEach(customField => {
                let filterType = '';
                let filterTooltip = '';
                let optionType = '';
                let multiple = false;
                let component = false;
                const clientCardSection = customField.section;
                let formaterFunction = lead => {
                    return formater.formatAllCustomField(lead, customField, this.groupDisplay);
                };

                const isVehicleCustomField = [ClientCardSection.VEHICLE_WANTED, ClientCardSection.VEHICLE_EXCHANGE].includes(customField.section);
                const isActualVehicleCustomField = customField.section === ClientCardSection.VEHICLE_EXCHANGE;

                switch (customField.type) {
                    case 'array':
                        filterType = 'select';
                        multiple = true;
                        component = 'inline-custom-field-text-edit';
                        break;

                    case 'boolean':
                        filterType = 'select';
                        optionType = 'bool';
                        component = 'custom-checkbox';
                        formaterFunction = false;
                        break;

                    case 'currency':
                        filterType = 'number';
                        component = 'inline-custom-field-text-edit';
                        break;

                    case 'datetime':
                        filterType = 'text';
                        filterTooltip = i18n.t('dashboards.dateFilterWithoutYearTooltip');
                        component = 'inline-custom-field-text-edit';
                        break;

                    case 'textarea':
                        filterType = 'string';
                        component = 'inline-custom-field-text-edit';
                        break;

                    default:
                        filterType = 'string';
                        component = 'inline-custom-field-text-edit';
                        break;
                }

                component = isVehicleCustomField ? 'custom-field-vehicles' : component;

                customFields[`customId_${customField.id}`] = {
                    valid: customField.visible,
                    title: `${customField.name}${isActualVehicleCustomField ? i18n.t('general.currentAbr') : ''}`,
                    tooltip: `${customField.name}${isActualVehicleCustomField ? i18n.t('general.currentAbr') : ''} ${customField.parentName ? ` (${customField.parentName})` : ''}`,
                    style: { width: '120px' },
                    truncating: true,
                    customField,
                    component,
                    clientCardSection,
                    formater: formaterFunction,
                    filterTooltip,
                    filter: {
                        type: filterType,
                        optionType,
                        multiple,
                        withEmpty: !multiple,
                        withNone: multiple,
                        options: customField.select_picker_options
                            .map(option => {
                                return {
                                    value: option,
                                    text: option,
                                };
                            })
                            .sort((a, b) => a.text.localeCompare(b.text)),
                        sorting: false,
                    },
                };
            });

            return customFields;
        },

        dashboardColumns() {
            return Object.keys(this.dashboardConfigs.columns)
                .map(name => {
                    const visibility = this.dashboardConfigs.columnVisibilities[name];

                    const column = {
                        ...this.columns[name],
                        ...this.dashboardConfigs.columns[name],
                    };

                    const classes = [column.baseClass || '', column.class || '', kebabCase(`column-${name}`)];

                    const title = typeof column.title == 'function' ? column.title() : column.title;

                    return {
                        ...column,
                        name,
                        title,
                        valid: typeof column.valid !== 'undefined' ? column.valid : true,
                        visible: visibility == 'visible' || (visibility != 'hidden' && column.visible),
                        class: classes.join(' ').trim(),
                        sortTitle: column.sortTitle || title || '',
                    };
                })
                .filter(column => {
                    return column.valid;
                });
        },

        activeDashboardColumns() {
            return Object.freeze(
                this.dashboardColumns.filter(column => {
                    return column.visible || column.mandatory;
                }),
            );
        },

        activeDashboardFilters() {
            const filters = {};

            this.activeDashboardColumns.forEach(column => {
                const filter = this.dashboardConfigs.filters[column.name];

                if (!isEmpty(filter)) {
                    filters[column.name] = filter;
                }
            });

            return filters;
        },
    },

    methods: {
        authUserHasDivision(division) {
            if (this.authUser.account.leads_other_division) {
                return true;
            }

            return this.authUser.divisions.find(d => d.name == division) || this.authUser.isAdmin();
        },

        hasClientCardField(type, field) {
            return get(this.contextAccount.client_card_fields, `${type}.${field}`, true);
        },

        hasAccountProduct(product) {
            return (this.contextAccount.products || []).some(p => p.name == product && p.visible);
        },
    },
};
