<template>
    <div class="overflow-auto lg:overflow-visible">
        <table class="table table-bordered | border-t-2" v-if="render">
            <thead>
                <tr>
                    <th
                        class="cursor-pointer w-px"
                        :class="{
                            'sorting-asc': sortKey == 'name' && !reverse,
                            'sorting-desc': sortKey == 'name' && reverse,
                        }"
                        @click="setSortBy('name')"
                    >
                        {{ $t('general.advisor') }}
                    </th>
                    <th
                        class="cursor-pointer border-r-0 border-l-0"
                        :class="{
                            'sorting-asc': sortKey == 'leads' && !reverse,
                            'sorting-desc': sortKey == 'leads' && reverse,
                        }"
                        @click="setSortBy('leads')"
                    />
                    <th
                        class="cursor-pointer text-center border-r-0 w-px"
                        :class="{
                            'sorting-asc': sortKey == 'divisionGroupedTotal' && !reverse,
                            'sorting-desc': sortKey == 'divisionGroupedTotal' && reverse,
                        }"
                        @click="setSortBy('divisionGroupedTotal')"
                        v-if="divisionGroupedTotalColumn"
                    >
                        {{ $t('general.division') }}
                    </th>
                    <th class="p-0 border-0" />
                    <activix-tooltip :content="$t('saleTable.tooltips.waitingSale')" v-if="waitingSale && !collapsed">
                        <th
                            class="cursor-pointer border-r-0"
                            :class="{
                                'sorting-asc': sortKey == 'waitingLeads' && !reverse,
                                'sorting-desc': sortKey == 'waitingLeads' && reverse,
                            }"
                            @click="setSortBy('waitingLeads')"
                        >
                            <div>
                                {{ $t('saleTable.waitingSale') }}
                            </div>
                        </th>
                    </activix-tooltip>
                </tr>
            </thead>
            <tbody>
                <tr v-if="!rows.length">
                    <td colspan="100" class="text-center">
                        {{ $t('dashboards.noData') }}
                    </td>
                </tr>
                <tr v-if="tooManyResults">
                    <td colspan="100" class="text-center">
                        {{ $t('dashboards.tooManySalesResults') }}
                    </td>
                </tr>
                <tr :key="row.user.id" v-for="(row, rowIndex) in rows" v-else>
                    <td class="whitespace-nowrap w-px">
                        {{ row.user.fullName }}
                    </td>
                    <td class="border-r-0 align-top">
                        <div class="hidden sortable-data">
                            {{ row.leads.length }}
                        </div>

                        <div class="flex">
                            <div class="flex-1">
                                <div class="flex flex-wrap -my-1 -mx-1">
                                    <grid-report-lead-card
                                        :view="view"
                                        :index="leadIndex"
                                        :lead-count="row.leads.length"
                                        :lead="lead"
                                        :objective="row.objective.sale_amount"
                                        :key="lead.id"
                                        v-for="(lead, leadIndex) in row.leads"
                                    />

                                    <template v-if="row.objective.remaining > 0">
                                        <grid-report-objective-card
                                            :remaining="row.objective.remaining"
                                            :index="index"
                                            :lead-count="row.leads.length"
                                            :key="index"
                                            v-for="index in row.objective.remaining"
                                        />
                                    </template>

                                    <div class="relative mx-1 my-1" v-else>
                                        <div
                                            class="flex items-center justify-center bg-white text-grey-500 shadow"
                                            :class="cardClassName"
                                        >
                                            {{ row.leads.length + 1 }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <grid-report-trophy
                                :sale-objective="row.objective.sale_amount"
                                :delivery-objective="row.objective.delivered"
                            />
                        </div>
                    </td>
                    <td class="border-r-0 w-px" v-if="divisionGroupedTotalColumn">
                        <div class="flex justify-center">
                            <div
                                class="flex items-center justify-center w-12 bg-grey-100 text-grey-600 text-sm shadow leading-tight"
                            >
                                <div class="text-left">
                                    <div>{{ $t('saleTable.newShort') }}&nbsp;:&nbsp;</div>
                                    <div>{{ $t('saleTable.usedShort') }}&nbsp;:&nbsp;</div>
                                </div>
                                <div class="text-right">
                                    <div>{{ row.counts.new }}</div>
                                    <div>{{ row.counts.used }}</div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td rowspan="100" class="p-0 border-0" v-if="rowIndex == 0">
                        <div class="relative h-full">
                            <div class="expand-arrow flex items-center justify-center absolute inset-0">
                                <icon
                                    :name="collapsed ? 'bold/arrow-thick-left' : 'bold/arrow-thick-right'"
                                    class="text-2xl border-grey-100 text-grey-500 p-1 shadow bg-white cursor-pointer"
                                    style="min-width: 1em"
                                    @click="toggleCollapse"
                                />
                            </div>
                        </div>
                    </td>
                    <td class="border-r-0 align-top" v-if="waitingSale && !collapsed">
                        <div class="hidden sortable-data">
                            {{ row.waitingLeads.length }}
                        </div>

                        <div class="flex flex-wrap -my-1 -mx-1">
                            <grid-report-lead-card
                                :view="view"
                                :index="leadIndex"
                                :lead="lead"
                                :waiting-sale="true"
                                :key="lead.id"
                                v-for="(lead, leadIndex) in row.waitingLeads"
                            />
                        </div>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td class="border-l-0 border-r-0 whitespace-nowrap" colspan="2">
                        <div class="flex justify-between">
                            <div class="flex space-x-2">
                                <div>
                                    <activix-tooltip :content="$t('saleTable.legendTooltips.postponed')">
                                        <div class="flex items-center">
                                            <div class="border-2 h-4 w-4 rounded-full mr-2 bg-white border-blue-500" />
                                            {{ $tc('saleTable.cards.postponed', 1) }}
                                        </div>
                                    </activix-tooltip>
                                    <activix-tooltip :content="$t('saleTable.legendTooltips.planned')">
                                        <div class="flex items-center">
                                            <div
                                                class="border-2 h-4 w-4 rounded-full mr-2 bg-blue-500 border-blue-500"
                                            />
                                            {{ $tc('saleTable.cards.planned', 1) }}
                                        </div>
                                    </activix-tooltip>
                                </div>
                                <div>
                                    <activix-tooltip :content="$t('saleTable.legendTooltips.refused')">
                                        <div class="flex items-center">
                                            <div class="border-2 h-4 w-4 rounded-full mr-2 bg-white border-red-500" />
                                            {{ $tc('saleTable.cards.refused', 1) }}
                                        </div>
                                    </activix-tooltip>
                                    <activix-tooltip :content="$t('saleTable.legendTooltips.lost')">
                                        <div class="flex items-center">
                                            <div class="border-2 h-4 w-4 rounded-full mr-2 bg-red-500 border-red-500" />
                                            {{ $tc('saleTable.cards.lost', 1) }}
                                        </div>
                                    </activix-tooltip>
                                </div>
                                <div>
                                    <activix-tooltip :content="$t('saleTable.legendTooltips.approved')">
                                        <div class="flex items-center">
                                            <div class="border-2 h-4 w-4 rounded-full mr-2 bg-white border-green-500" />
                                            {{ $tc('saleTable.cards.approved', 1) }}
                                        </div>
                                    </activix-tooltip>
                                    <activix-tooltip :content="$t('saleTable.legendTooltips.delivered')">
                                        <div class="flex items-center">
                                            <div
                                                class="border-2 h-4 w-4 rounded-full mr-2 bg-green-500 border-green-500"
                                            />
                                            {{ $tc('saleTable.cards.delivered', 1) }}
                                        </div>
                                    </activix-tooltip>
                                </div>
                                <div>
                                    <activix-tooltip :content="$t('saleTable.legendTooltips.sales')">
                                        <div class="flex items-center">
                                            <div class="border-2 h-4 w-4 rounded-full mr-2 bg-white border-grey-600" />
                                            {{ $tc('saleTable.cards.sale', 1) }}
                                        </div>
                                    </activix-tooltip>
                                    <activix-tooltip :content="$t('saleTable.legendTooltips.discounted')">
                                        <div class="flex items-center">
                                            <div
                                                class="border-2 h-4 w-4 rounded-full mr-2 bg-grey-600 border-grey-600"
                                            />
                                            {{ $tc('clientCard.discounted', 1) }}
                                        </div>
                                    </activix-tooltip>
                                </div>
                                <div>
                                    <activix-tooltip :content="$t('saleTable.legendTooltips.carryOverNotDelivered')">
                                        <div class="flex items-center">
                                            <div
                                                class="border-2 h-4 w-4 rounded-full mr-2 bg-white border-orange-500"
                                            />
                                            {{ $tc('saleTable.cards.carryOverNotDelivered', 1) }}
                                        </div>
                                    </activix-tooltip>
                                    <activix-tooltip :content="$t('saleTable.legendTooltips.carryOver')">
                                        <div class="flex items-center">
                                            <div
                                                class="border-2 h-4 w-4 rounded-full mr-2 bg-orange-500 border-orange-500"
                                            />
                                            {{ $tc('saleTable.cards.carryOver', 1) }}
                                        </div>
                                    </activix-tooltip>
                                </div>
                            </div>
                            <div class="ml-2">
                                <activix-tooltip :content="$t('saleTable.gridReport.selectViewType')">
                                    <activix-multiselect
                                        class="w-28"
                                        label="text"
                                        :allow-empty="false"
                                        :options="viewOptions"
                                        :selected="selectedView"
                                        :searchable="false"
                                        @update="toggleView"
                                    />
                                </activix-tooltip>
                            </div>
                        </div>
                    </td>
                    <td class="border-r-0" v-if="divisionGroupedTotalColumn">
                        <div class="flex justify-center">
                            <div
                                class="flex items-center justify-center bg-grey-100 text-grey-600 text-sm shadow leading-tight w-14"
                                :class="totals.division.none ? 'h-14' : 'h-9'"
                            >
                                <div class="text-left">
                                    <div>{{ $t('saleTable.newShort') }}&nbsp;:&nbsp;</div>
                                    <div>{{ $t('saleTable.usedShort') }}&nbsp;:&nbsp;</div>
                                    <div v-if="totals.division.none">
                                        {{ $t('saleTable.noDivisionShort') }}&nbsp;:&nbsp;
                                    </div>
                                </div>
                                <div class="text-right">
                                    <div>{{ totals.division.new }}</div>
                                    <div>{{ totals.division.used }}</div>
                                    <div v-if="totals.division.none">
                                        {{ totals.division.none }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td class="p-0 border-0" />
                    <td class="border-r-0" v-if="waitingSale && !collapsed">
                        <div class="flex flex-wrap -my-1 -mx-1">
                            <div class="relative mx-1 my-1">
                                <activix-tooltip :content="$t('saleTable.waitingSaleTotal')">
                                    <div
                                        class="flex items-center justify-center bg-white text-grey-500 shadow"
                                        :class="cardClassName"
                                    >
                                        {{ totals.waitingSales.assigned }}
                                    </div>
                                </activix-tooltip>
                            </div>
                            <div class="relative mx-1 my-1">
                                <activix-tooltip :content="$t('saleTable.waitingSaleNoAssigned')">
                                    <div
                                        class="flex items-center justify-center bg-white text-grey-500 shadow"
                                        :class="cardClassName"
                                    >
                                        {{ totals.waitingSales.unassigned }}
                                    </div>
                                </activix-tooltip>
                            </div>
                        </div>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>

<script>
    // Utils
    import { get } from 'lodash-es';

    import { mapState } from 'pinia';

    // Entities
    import Lead from '../../entities/Lead.js';
    import DashboardType from '../../entities/DashboardType.js';
    import GridReportView from '../../entities/GridReportView.js';

    // Value Objects
    import ActivixDate from '../../value-objects/ActivixDate.js';

    // Components
    import GridReportLeadCard from './GridReportLeadCard.vue';
    import GridReportObjectiveCard from './GridReportObjectiveCard.vue';
    import GridReportTrophy from './GridReportTrophy.vue';

    // Mixins
    import DashboardColumns from '../../mixins/DashboardColumns.js';

    // Pinia
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        components: {
            GridReportLeadCard,
            GridReportObjectiveCard,
            GridReportTrophy,
        },

        mixins: [DashboardColumns],

        props: {
            users: {
                type: Array,
                required: true,
            },
            showAllUsers: {
                type: Boolean,
                default: false,
            },
            render: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                view: this.$ls.get('gridReport:view', GridReportView.DIVISION),
                collapsed: this.$ls.get('gridReport:collapsedWaitingSales', true),
                sortKey: 'leads',
                reverse: true,
                results: [],
                totals: {
                    division: {
                        new: 0,
                        used: 0,
                        none: 0,
                    },
                    waitingSales: {
                        assigned: 0,
                        unassigned: 0,
                    },
                    sales: 0,
                },
            };
        },

        computed: {
            ...mapState(useContextStore, {
                contextTeam: 'contextTeam',
                contextAccount: 'account',
            }),
            ...mapState(useDashboardStore, {
                expanded: store => store.configs.expanded,
                options: store => store.configs.options,
            }),

            tooManyResults() {
                return this.totals.sales > 1000;
            },

            viewOptions() {
                return [
                    { value: GridReportView.CAR, text: this.$t('saleTable.gridReport.carView') },
                    { value: GridReportView.DIVISION, text: this.$t('saleTable.gridReport.divisionView') },
                    { value: GridReportView.STOCK, text: this.$t('saleTable.gridReport.stockView') },
                ];
            },

            selectedView() {
                return this.viewOptions.find(view => view.value == this.view);
            },

            cardClassName() {
                switch (this.view) {
                    case GridReportView.CAR:
                        return 'h-7 w-10';

                    case GridReportView.STOCK:
                    case GridReportView.DIVISION:
                    default:
                        return 'h-9 w-12';
                }
            },

            divisionGroupedTotalColumn() {
                return (
                    this.contextAccount &&
                    this.contextAccount.sale_table_options &&
                    this.contextAccount.sale_table_options.division_grouped_total_column
                );
            },

            waitingSale() {
                return this.contextAccount && this.contextAccount.waiting_sale_option;
            },

            rows() {
                return this.users
                    .map(user => {
                        const result = this.results.find(result => result.id == user.id);
                        const bySaleDate = (a, b) => {
                            const saleA = new ActivixDate(a.sale_date);
                            const saleB = new ActivixDate(b.sale_date);

                            if (saleA.isEmpty()) {
                                return 1;
                            }

                            if (saleB.isEmpty()) {
                                return -1;
                            }

                            return saleA.isBefore(saleB) ? -1 : 1;
                        };

                        const leads = get(result, 'leads', [])
                            .map(lead => new Lead(lead))
                            .sort(bySaleDate);
                        const waitingLeads = get(result, 'waiting_sales', [])
                            .map(lead => new Lead(lead))
                            .sort(bySaleDate);

                        return {
                            user,
                            leads: leads.map(lead => new Lead(lead)),
                            waitingLeads: waitingLeads.map(lead => new Lead(lead)),
                            objective: this.formatObjective(user, leads),
                            counts: {
                                new: get(result, 'division_new', 0),
                                used: get(result, 'division_used', 0),
                            },
                        };
                    })
                    .filter(result => {
                        if (!!result.leads.length || !!result.waitingLeads.length) {
                            return true;
                        }

                        if (!result.user.active) {
                            return false;
                        }

                        return this.showAllUsers || !!result.objective.sale_amount;
                    })
                    .sort((a, b) => {
                        let compare;

                        switch (this.sortKey) {
                            case 'leads':
                                compare = a.leads.length - b.leads.length;
                                break;

                            case 'waitingLeads':
                                compare = a.waitingLeads.length - b.waitingLeads.length;
                                break;

                            case 'divisionGroupedTotal':
                                compare = a.counts.new + a.counts.used - (b.counts.new + b.counts.used);
                                break;

                            default:
                                compare = a.user.fullName > b.user.fullName ? 1 : -1;
                                break;
                        }

                        return this.reverse ? -compare : compare;
                    });
            },
        },

        watch: {
            'expanded.saleTable': {
                immediate: true,
                handler() {
                    this.fetch();
                },
            },

            view(newValue) {
                this.$ls.set('gridReport:view', newValue);
            },
        },

        methods: {
            toggleView(view) {
                this.view = view.value;
            },

            toggleCollapse() {
                const newValue = !this.collapsed;

                this.$ls.set('gridReport:collapsedWaitingSales', newValue);
                this.collapsed = newValue;
            },

            setSortBy(sortKey) {
                this.reverse = this.sortKey == sortKey ? !this.reverse : false;
                this.sortKey = sortKey;
            },

            async fetch({ background = false } = {}) {
                if (!this.expanded.saleTable) {
                    return;
                }

                if (!background) {
                    this.$wait.start('fetching.gridReport');
                }

                const payload = {
                    dashboard: DashboardType.SALE,
                    endDate: this.parsedEndDate.toDateTimeString(),
                    startDate: this.parsedStartDate.toDateTimeString(),
                    options: this.options,
                    accountId: this.contextAccount.id,
                    filters: this.activeDashboardFilters,
                    teamId: this.contextTeam.id,
                };

                try {
                    const response = await this.$api.dashboard.getGridReport(payload);

                    if (response) {
                        this.totals.division.new = response.total_new;
                        this.totals.division.used = response.total_used;
                        this.totals.division.none = response.total_no_division;
                        this.totals.waitingSales.assigned = response.total_waiting_sales;
                        this.totals.waitingSales.unassigned = response.total_unassigned_waiting_sales;
                        this.totals.sales = response.count;
                        this.results = response.formatted_results;

                        this.$wait.end('fetching.gridReport');
                    }
                } catch (error) {
                    this.$notify.warning(this.$t('dashboards.alerts.stats.error'));
                    this.$wait.end('fetching.gridReport');
                }
            },

            formatObjective(user, leads = []) {
                const objective = {
                    sale_amount: 0,
                    remaining: 0,
                    delivered: 0,
                };

                user.currentObjectives.forEach(userObjective => {
                    const deliveries = leads.filter(lead => {
                        return (
                            lead.division_id == userObjective.division_id &&
                            !!lead.delivered_date &&
                            !this.isPostponedSale(lead)
                        );
                    });

                    objective.sale_amount += userObjective.sale_amount;
                    objective.delivered += deliveries.length;
                });

                objective.remaining = objective.sale_amount - leads.length;

                return objective;
            },

            isPostponedSale(lead) {
                if (!lead.sale_date) {
                    return false;
                }

                const deliveredDate = new ActivixDate(lead.delivered_date);
                const deliveryDate = new ActivixDate(lead.delivery_date);
                const saleDate = new ActivixDate(lead.sale_date, 'date');

                return (
                    saleDate.isBetween(this.parsedStartDate, this.parsedEndDate) &&
                    (deliveryDate.isAfter(this.parsedEndDate) || deliveredDate.isAfter(this.parsedEndDate))
                );
            },
        },
    };
</script>

<style lang="less">
    @media (max-width: 991px) {
        .expand-arrow {
            left: -16px !important;
        }
    }
</style>
