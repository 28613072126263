<template>
    <activix-tooltip :content="tooltip">
        <icon
            class="text-xl"
            :class="[checked ? 'link-blue' : 'link-grey-light', { disabled: !authorized }]"
            :name="checked ? $icons.meetingOn : $icons.meetingOff"
            @click="update"
        />
    </activix-tooltip>
</template>

<script>
    /* eslint-disable vue/require-prop-types */
    import { mapActions, mapState } from 'pinia';
    import { useGlobalStore } from '../../../store/store.js';

    export default {
        props: ['lead'],

        data() {
            return {
                checked: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),

            authorized() {
                return this.authUser.isAuthorizedToActionOnLead(this.lead);
            },

            tooltip() {
                if (this.checked) {
                    return this.$t('clientCard.met');
                }

                return this.$t('clientCard.notMet');
            },
        },

        watch: {
            'lead.commercial_meeting'(newValue) {
                this.checked = newValue;
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction']),

            update() {
                if (this.authorized) {
                    this.updateLeadAction(this.lead.id, { commercial_meeting: !this.checked });
                }
            },
        },

        mounted() {
            this.$nextTick(() => {
                this.checked = this.lead.commercial_meeting;
            });
        },
    };
</script>
