<template>
    <div>
        <div class="flex flex-wrap lg:-mx-3">
            <card-small
                class="px-3 w-full | xs:w-1/2 | lg:w-1/4"
                :number="cards.leads.number.count"
                :title="$tc('searchLead.clients', cards.leads.number.count)"
                :back-number="cards.leads.assigned.count"
                :back-title="$tc('dashboards.assignedClient', cards.leads.assigned.count)"
            />
            <card-small
                class="px-3 w-full | xs:w-1/2 | lg:w-1/4"
                :number="cards.location.number.count"
                :title="$tc('renewal.location', cards.location.number.count)"
            />
            <card-small
                class="px-3 w-full | xs:w-1/2 | lg:w-1/4"
                :number="cards.financing.number.count"
                :title="$t('clientCard.products.financing')"
                :back-number="cards.cashDeal.number.count"
                :back-title="$t('renewal.cash')"
            />
            <card-small
                class="px-3 w-full | xs:w-1/2 | lg:w-1/4"
                :number="cards.posEquity.number.count"
                :title="$t('renewal.positiveEquity')"
                :back-number="cards.negEquity.number.count"
                :back-title="$t('renewal.negativeEquity')"
            />
        </div>

        <div class="flex flex-wrap lg:-mx-3">
            <card
                class="px-3 w-full | xs:w-1/3 | xl:w-1/5"
                :icon="$icons.appointment"
                :card="appointmentValue"
                :denominator="cards.leads.number.count"
                :title-icon="options.phoneAppointment ? $icons.phoneAppointment : ''"
                :title="$tc('renewal.appointment', appointmentValue.count)"
            >
                <template #description>
                    <div class="flex justify-center text-lg -mt-1">
                        <template v-if="options.phoneAppointment">
                            <div class="text-right">
                                <div class="flex items-center mt-1">
                                    {{ $tc('renewal.appointment', cards.phoneAppointment.number.count) }}
                                    <icon class="text-base ml-2" :name="$icons.phoneAppointment" />
                                </div>
                                <div class="mt-1">
                                    {{ $tc('renewal.appointment', cards.appointment.number.count) }}
                                </div>
                                <div class="mt-1">
                                    {{ $t('general.total') }}
                                </div>
                            </div>
                            <div class="text-left ml-3">
                                <div class="mt-1">
                                    <span class="font-bold">{{ cards.phoneAppointment.number.count }}</span>
                                </div>
                                <div class="mt-1">
                                    <span class="font-bold">{{ cards.appointment.number.count }}</span>
                                </div>
                                <div class="mt-1">
                                    <span class="font-bold">
                                        {{ cards.phoneAppointment.number.count + cards.appointment.number.count }}
                                    </span>
                                </div>
                            </div>
                            <div class="text-left ml-3">
                                <div class="mt-1">
                                    <span class="text-grey-600 text-base">({{ phoneApptPercent }})</span>
                                </div>
                                <div class="mt-1">
                                    <span class="text-grey-600 text-base">({{ regularApptPercent }})</span>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="text-right">
                                <div class="mt-1" v-if="contextAccount.confirmation_appt_sale">
                                    {{ $tc('result.confirmed', cards.appointment.number.confirmed) }}
                                </div>
                                <div class="mt-1">
                                    {{ $t('result.noShow') }}
                                </div>
                                <div class="mt-1">
                                    {{ $tc('result.canceled', cards.appointment.number.canceled) }}
                                </div>
                            </div>
                            <div class="text-left ml-3">
                                <div class="mt-1" v-if="contextAccount.confirmation_appt_sale">
                                    <span class="font-bold">{{ cards.appointment.number.confirmed }}</span>
                                </div>
                                <div class="mt-1">
                                    <span class="font-bold">{{ cards.appointment.number.noShow }}</span>
                                </div>
                                <div class="mt-1">
                                    <span class="font-bold">{{ cards.appointment.number.canceled }}</span>
                                </div>
                            </div>
                            <div class="text-left ml-3">
                                <div class="mt-1">
                                    <span class="text-grey-600 text-base">({{ confirmedApptPercent }})</span>
                                </div>
                                <div class="mt-1">
                                    <span class="text-grey-600 text-base">({{ noShowApptPercent }})</span>
                                </div>
                                <div class="mt-1">
                                    <span class="text-grey-600 text-base">({{ canceledApptPercent }})</span>
                                </div>
                            </div>
                        </template>
                    </div>
                </template>
                <activix-tooltip :content="phoneAppointmentToggleTooltip">
                    <activix-switcher
                        color="grey"
                        class="absolute right-0 bottom-0 mb-6 mr-9"
                        size="small"
                        :value="options.phoneAppointment"
                        @input="$eventBus.$emit('toggle-option', 'phoneAppointment', $event)"
                    />
                </activix-tooltip>
            </card>
            <card
                class="px-3 w-full | xs:w-1/3 | xl:w-1/5"
                :icon="$icons.visit"
                :card="presentedValue"
                :numerator="visitWithAppt"
                :denominator="cards.leads.number.count"
                :back-numerator="presentedValue.count"
                :back-denominator="cards.leads.number.count"
                :title="$tc('renewal.presented', presentedValue.count)"
                :title-icon="options.homeVisit ? $icons.homeVisit : ''"
            >
                <template #description>
                    <div class="flex justify-center text-lg -mt-1">
                        <div class="text-right">
                            <div class="mt-1">
                                {{ $t('renewal.visitWithAppointment') }}
                            </div>
                            <div class="mt-1">
                                {{ $t('renewal.visitWithoutAppointment') }}
                            </div>
                        </div>
                        <div class="text-right ml-3">
                            <div class="mt-1">
                                <span class="font-bold">{{ visitWithAppt }}</span> /
                                <span class="font-bold">{{ cards.appointment.number.count }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="font-bold">{{ presentedValue.countWithoutAppointment }}</span> /
                                <span class="font-bold">{{ totalWithoutAppt }}</span>
                            </div>
                        </div>
                        <div class="text-left ml-3 ">
                            <div class="mt-1">
                                <span class="text-grey-600 text-base">({{ visitWithApptPercent }})</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-grey-600 text-base">({{ visitWithoutApptPercent }})</span>
                            </div>
                        </div>
                    </div>
                </template>
                <activix-tooltip :content="homeVisitToggleTooltip">
                    <activix-switcher
                        color="grey"
                        class="absolute right-0 bottom-0 mb-6 mr-9"
                        size="small"
                        :value="options.homeVisit"
                        @input="$eventBus.$emit('toggle-option', 'homeVisit', $event)"
                    />
                </activix-tooltip>
            </card>
            <card
                class="px-3 w-full | xs:w-1/3 | xl:w-1/5"
                :icon="$icons.sale"
                :card="cards.sale.number"
                :denominator="cards.leads.number.count"
                :title="$tc('accounts.edit.renewed', cards.sale.number.count)"
            >
                <template #description>
                    <div class="text-xl">
                        {{ $t('renewal.saleCardBack') }}
                    </div>
                    <div class="flex justify-center text-lg mt-2" v-if="contextAccount.sale_by_phone">
                        <div class="text-right">
                            <div class="mt-1">
                                {{ $tc('result.renewalByPhone', cards.saleByPhone.count) }}
                            </div>
                        </div>
                        <div class="text-left ml-3">
                            <div class="mt-1">
                                <span class="font-bold">{{ cards.saleByPhone.count }}</span>
                            </div>
                        </div>
                    </div>
                </template>
            </card>
            <card
                class="px-3 w-full | xs:w-1/2 | xl:w-1/5"
                icon="regular/house-garage"
                :card="cards.refinanced.number"
                :denominator="cards.leads.number.count"
                :title="$t('accounts.edit.refinanced')"
            >
                <template #description>
                    <div class="flex justify-center text-lg -mt-1">
                        <div class="text-right">
                            <div class="mt-1">
                                {{ $t('renewal.financed') }}
                            </div>
                            <div class="mt-1">
                                {{ $t('renewal.cash') }}
                            </div>
                        </div>
                        <div class="text-left ml-3">
                            <div class="mt-1">
                                <span class="font-bold">{{ cards.refinanced.refinancedTypes.financed }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="font-bold">{{ cards.refinanced.refinancedTypes.cash }}</span>
                            </div>
                        </div>
                    </div>
                </template>
            </card>
            <card
                class="px-3 w-full | xs:w-1/2 | xl:w-1/5"
                :icon="$icons.lost"
                :card="cards.lost.number"
                :denominator="cards.leads.number.count"
                :title="$t('clientCard.lost')"
            >
                <activix-tooltip :content="$t('date.lostDateTooltip')">
                    <activix-switcher
                        color="grey"
                        class="absolute right-0 bottom-0 mb-6 mr-9"
                        size="small"
                        :value="options.lostDate"
                        @input="$eventBus.$emit('toggle-option', 'lostDate', $event)"
                    />
                </activix-tooltip>
            </card>
        </div>

        <stats-table :dashboard="dashboard" ref="statsTable" />
    </div>
</template>

<script>
    // Utils
    import { mapState } from 'pinia';
    import { toPercentString } from '../../utils/numbers.js';
    // Components
    import Card from '../../components/dashboards/cards/Card.vue';
    import CardSmall from '../../components/dashboards/cards/CardSmall.vue';
    import StatsTable from '../StatsTables/StatsTable.vue';

    // Mixins
    import TrackView from '../../mixins/TrackView.js';
    import DashboardCaching from '../../mixins/DashboardCaching.js';
    import DashboardCustomFields from '../../mixins/DashboardCustomFields.js';

    // Entitites
    import DashboardType from '../../entities/DashboardType.js';

    // Pinia
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        name: 'Renewal',

        components: {
            Card,
            CardSmall,
            StatsTable,
        },

        mixins: [TrackView, DashboardCaching, DashboardCustomFields],

        data() {
            return {
                dashboard: DashboardType.RENEWAL,
                cards: {
                    leads: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                        assigned: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                    },
                    location: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                    },
                    financing: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                    },
                    cashDeal: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                    },
                    posEquity: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                    },
                    negEquity: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                    },
                    appointment: {
                        number: {
                            count: 0,
                            confirmed: 0,
                            noShow: 0,
                            canceled: 0,
                            colors: {
                                0: 'blue',
                            },
                        },
                    },

                    phoneAppointment: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'blue',
                            },
                        },
                    },

                    presented: {
                        number: {
                            count: 0,
                            countWithoutAppointment: 0,
                            colors: {
                                0: 'blue',
                            },
                        },
                    },
                    presentedHome: {
                        number: {
                            count: 0,
                            countWithoutAppointment: 0,
                            colors: {
                                0: 'grey',
                                1: 'red',
                                50: 'orange',
                                70: 'green',
                            },
                        },
                    },
                    sale: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'blue',
                            },
                        },
                    },
                    saleByPhone: {
                        count: 0,
                    },
                    refinanced: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'blue',
                            },
                        },
                        refinancedTypes: {
                            financed: 0,
                            cash: 0,
                        },
                    },
                    lost: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                                1: 'blue',
                            },
                        },
                    },
                },
            };
        },

        computed: {
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),
            ...mapState(useDashboardStore, {
                options: store => store.configs.options,
            }),

            phoneApptPercent() {
                return toPercentString(
                    this.cards.phoneAppointment.number.count,
                    this.cards.phoneAppointment.number.count + this.cards.appointment.number.count,
                );
            },
            regularApptPercent() {
                return toPercentString(
                    this.cards.appointment.number.count,
                    this.cards.phoneAppointment.number.count + this.cards.appointment.number.count,
                );
            },
            confirmedApptPercent() {
                return toPercentString(this.cards.appointment.number.confirmed, this.cards.appointment.number.count);
            },
            noShowApptPercent() {
                return toPercentString(this.cards.appointment.number.noShow, this.cards.appointment.number.count);
            },
            canceledApptPercent() {
                return toPercentString(this.cards.appointment.number.canceled, this.cards.appointment.number.count);
            },
            appointmentValue() {
                if (this.options.phoneAppointment) {
                    return this.cards.phoneAppointment.number;
                }

                return this.cards.appointment.number;
            },

            presentedValue() {
                if (this.options.homeVisit) {
                    return this.cards.presentedHome.number;
                }

                return this.cards.presented.number;
            },

            phoneAppointmentToggleTooltip() {
                if (this.options.phoneAppointment) {
                    return this.$t('loyalty.appointmentCallTooltipOn');
                }

                return this.$t('loyalty.appointmentCallTooltipOff');
            },

            homeVisitToggleTooltip() {
                if (this.options.homeVisit) {
                    return this.$t('loyalty.homeVisitTooltipOn');
                }

                return this.$t('loyalty.homeVisitTooltipOff');
            },

            visitWithApptPercent() {
                return toPercentString(this.visitWithAppt, this.cards.appointment.number.count);
            },

            visitWithoutApptPercent() {
                return toPercentString(
                    this.visitWithAppt,
                    this.totalWithoutAppt,
                );
            },

            visitWithAppt() {
                return this.presentedValue.count - this.presentedValue.countWithoutAppointment;
            },

            totalWithoutAppt() {
                return this.cards.leads.number.count - this.cards.appointment.number.count;
            },
        },

        methods: {
            async fetchStats(options) {
                await this.$refs.statsTable.fetchStats(options);
            },

            setColumns() {
                useDashboardStore().configs.columns = {
                    bulk_select: {
                        visible: true,
                    },
                    fullname: {
                        visible: true,
                    },
                    contact_method: {
                        visible: true,
                    },
                    civility: {
                        visible: false,
                    },
                    business: {
                        visible: false,
                    },
                    created_by: {
                        visible: false,
                    },
                    updated_by: {
                        visible: false,
                    },
                    'customer:casl_consent_status': {
                        visible: false,
                    },
                    'customer:casl_consent_limit_date': {
                        visible: false,
                    },
                    date: {
                        visible: true,
                    },
                    'exchange_vehicles:end_contract_date': {
                        visible: true,
                    },
                    updated_at: {
                        visible: false,
                    },
                    'division:name': {
                        visible: true,
                    },
                    status: {
                        visible: true,
                    },
                    renewal_alert_date: {
                        visible: true,
                    },
                    renewal_alert_types: {
                        visible: true,
                    },
                    result: {
                        visible: true,
                    },
                    advisors: {
                        visible: true,
                    },
                    agents: {
                        visible: true,
                    },
                    'commercial:': {
                        visible: false,
                    },
                    'account:name': {
                        visible: true,
                    },
                    rating: {
                        visible: false,
                    },
                    city: {
                        visible: false,
                    },
                    country: {
                        visible: false,
                    },
                    province: {
                        visible: false,
                    },
                    postal_code: {
                        visible: false,
                    },
                    address: {
                        visible: false,
                    },
                    locale: {
                        visible: false,
                    },
                    sector: {
                        visible: false,
                    },
                    budget: {
                        visible: false,
                    },
                    walk_around: {
                        visible: false,
                    },
                    created_method: {
                        visible: false,
                    },
                    lead_state: {
                        visible: false,
                    },
                    'import:original_file': {
                        visible: false,
                    },
                    'wanted_vehicles:make': {
                        visible: false,
                    },
                    'wanted_vehicles:model': {
                        visible: false,
                    },
                    'wanted_vehicles:year': {
                        visible: false,
                    },
                    'wanted_vehicles:fuel': {
                        visible: false,
                    },
                    'wanted_vehicles:version': {
                        visible: false,
                    },
                    'wanted_vehicles:certified': {
                        visible: false,
                    },
                    'wanted_vehicles:transmission': {
                        visible: false,
                    },
                    'wanted_vehicles:color_exterior': {
                        visible: false,
                    },
                    'wanted_vehicles:color_interior': {
                        visible: false,
                    },
                    'wanted_vehicles:end_contract_date': {
                        visible: false,
                    },
                    'wanted_vehicles:vin': {
                        visible: false,
                    },
                    'wanted_vehicles:rate': {
                        visible: true,
                    },
                    'wanted_vehicles:end_warranty_date': {
                        visible: false,
                    },
                    'wanted_vehicles:modality': {
                        visible: false,
                    },
                    phone_appointment: {
                        visible: true,
                    },
                    appointment: {
                        visible: true,
                    },
                    presented: {
                        visible: false,
                    },
                    long_term: {
                        visible: false,
                    },
                    twenty_four_hour: {
                        visible: true,
                    },
                    deposit: {
                        visible: false,
                    },
                    write_up: {
                        visible: false,
                    },
                    progress_state: {
                        visible: true,
                    },
                    approved: {
                        visible: true,
                    },
                    'lead_bank:name': {
                        visible: false,
                    },
                    waiting_sale: {
                        visible: false,
                    },
                    sale_by_phone: {
                        visible: false,
                    },
                    sale: {
                        title: this.$t('result.renewed'),
                        visible: true,
                    },
                    refinanced: {
                        visible: true,
                        mandatory: false,
                    },
                    available: {
                        visible: false,
                    },
                    paperwork: {
                        visible: false,
                    },
                    prepared: {
                        visible: true,
                    },
                    prepared_work_order: {
                        visible: false,
                    },
                    gas: {
                        visible: false,
                    },
                    deliverable: {
                        visible: false,
                    },
                    delivery: {
                        visible: false,
                    },
                    delivered: {
                        visible: true,
                    },
                    'wanted_vehicles:recorded_date': {
                        visible: false,
                    },
                    csi: {
                        visible: false,
                    },
                    'exchange_vehicles:extended_warranty': {
                        visible: false,
                    },
                    'exchange_vehicles:make': {
                        visible: false,
                    },
                    'exchange_vehicles:model': {
                        visible: true,
                    },
                    'exchange_vehicles:year': {
                        visible: true,
                    },
                    'exchange_vehicles:version': {
                        visible: false,
                    },
                    'exchange_vehicles:certified': {
                        visible: false,
                    },
                    'exchange_vehicles:vin': {
                        visible: false,
                    },
                    'exchange_vehicles:transmission': {
                        visible: false,
                    },
                    'exchange_vehicles:color_exterior': {
                        visible: false,
                    },
                    'exchange_vehicles:color_interior': {
                        visible: false,
                    },
                    'exchange_vehicles:condition': {
                        visible: false,
                    },
                    'exchange_vehicles:fuel': {
                        visible: false,
                    },
                    'exchange_vehicles:sold_by': {
                        visible: false,
                    },
                    'exchange_vehicles:modality': {
                        visible: true,
                    },
                    'exchange_vehicles:payment': {
                        visible: false,
                    },
                    'exchange_vehicles:payment_with_tax': {
                        visible: false,
                    },
                    'exchange_vehicles:term': {
                        visible: false,
                    },
                    'wanted_vehicles:stock_state': {
                        visible: false,
                    },
                    'exchange_vehicles:frequency': {
                        visible: false,
                    },
                    'exchange_vehicles:allowed_mileage': {
                        visible: false,
                    },
                    'exchange_vehicles:mo_remaining': {
                        visible: true,
                    },
                    'exchange_vehicles:tire_type': {
                        visible: false,
                    },
                    'exchange_vehicles:accidented_damage_cost': {
                        visible: false,
                    },
                    'exchange_vehicles:value': {
                        visible: false,
                    },
                    'exchange_vehicles:actual_value': {
                        visible: false,
                    },
                    'exchange_vehicles:calculator_appraiser': {
                        visible: false,
                    },
                    'exchange_vehicles:calculator_value': {
                        visible: false,
                    },
                    'exchange_vehicles:balance': {
                        visible: false,
                    },
                    'exchange_vehicles:residual': {
                        visible: false,
                    },
                    'exchange_vehicles:equity': {
                        visible: false,
                    },
                    'exchange_vehicles:mileage': {
                        visible: false,
                    },
                    'exchange_vehicles:warranty': {
                        visible: false,
                    },
                    'exchange_vehicles:end_warranty_date': {
                        visible: false,
                    },
                    'exchange_vehicles:intention': {
                        visible: true,
                    },
                    'exchange_vehicles:rate': {
                        visible: false,
                    },
                    'exchange_vehicles:recall': {
                        visible: false,
                    },
                    'exchange_vehicles:bank:name': {
                        visible: false,
                    },
                    total_price_commercial_director: {
                        visible: false,
                    },
                    listening: {
                        visible: false,
                    },
                    followed: {
                        visible: true,
                    },
                    followed_by: {
                        visible: false,
                    },
                    todo: {
                        visible: true,
                    },
                    source: {
                        visible: false,
                    },
                    odometer_last_visit: {
                        visible: false,
                    },
                    service_interval_km: {
                        visible: false,
                    },
                    service_monthly_km: {
                        visible: false,
                    },
                    storage: {
                        visible: false,
                    },
                    form: {
                        visible: false,
                    },
                    imported_campaign: {
                        visible: false,
                    },
                    'latest_comment:content': {
                        visible: true,
                    },
                    unsubscribe: {
                        visible: false,
                    },
                    birth_date: {
                        visible: false,
                    },
                    ...this.getCustomFields(),
                };
            },

            setCards(results) {
                this.cards.leads.number.count = results.count;
                this.cards.leads.assigned.count = results.assignments;
                this.cards.lost.number.count = results.losses;

                this.cards.phoneAppointment.number.count = results.phoneAppointments;
                this.cards.appointment.number.count = results.appointments.count;
                this.cards.appointment.number.noShow = results.appointments.noShow;
                this.cards.appointment.number.confirmed = results.appointments.confirmed;
                this.cards.appointment.number.canceled = results.appointments.canceled;

                this.cards.presented.number.count = results.visits.count;
                this.cards.presented.number.countWithoutAppointment = results.visits.withoutAppointment;

                this.cards.presentedHome.number.count = results.homeVisits.count;
                this.cards.presentedHome.number.countWithoutAppointment = results.homeVisits.withoutAppointment;

                this.cards.sale.number.count = results.sales.count;
                this.cards.saleByPhone.count = results.sales.byPhone;

                this.cards.refinanced.number.count = results.refinances.count;
                this.cards.refinanced.refinancedTypes.financed = results.refinances.financed;
                this.cards.refinanced.refinancedTypes.cash = results.refinances.cash;

                this.cards.location.number.count = results.modalities.leasing;
                this.cards.financing.number.count = results.modalities.financing;
                this.cards.cashDeal.number.count = results.modalities.cash;

                this.cards.posEquity.number.count = results.equities.positive;
                this.cards.negEquity.number.count = results.equities.negative;

                this.$nextTick(() => {
                    this.setCaching('cards', this.cards);
                });
            },
        },
    };
</script>
