<template>
    <div>
        <div class="flex flex-wrap lg:-mx-3">
            <card-small
                class="px-3 w-full | md:w-1/3"
                :number="cards.leads.number.count"
                :title="$tc('phoneUp.clients', cards.leads.number.count)"
            />
            <card-small
                class="px-3 w-full | md:w-1/3"
                :number="cards.auto.number.count"
                :title="$tc('phoneUp.clientAuto', cards.auto.number.count)"
                :back-number="cards.auto.number.ivr"
                :back-title="$tc('phoneUp.viaIvr', cards.auto.number.ivr)"
            />
            <card-small
                class="px-3 w-full | md:w-1/3"
                :number="cards.manual.number.count"
                :title="$tc('phoneUp.clientManual', cards.manual.number.count)"
            />
        </div>

        <div class="flex flex-wrap lg:-mx-3">
            <card
                class="px-3 w-full | xs:w-1/3 | xl:w-1/5"
                :icon="$icons.phone"
                :card="cards.answered.number"
                :denominator="cards.leads.number.count"
                :title="$tc('phoneUp.answered', cards.answered.number.count)"
            >
                <template #description>
                    <div class="flex justify-center text-lg -mt-1">
                        <div class="text-right overflow-hidden">
                            <div class="mt-1 whitespace-nowrap truncate">
                                {{ $tc('phoneUp.clientAuto', cards.answered.auto.count) }}
                            </div>
                            <div class="mt-1 whitespace-nowrap truncate">
                                {{ $tc('phoneUp.clientManual', cards.answered.manual.count) }}
                            </div>
                        </div>
                        <div class="text-left ml-3">
                            <div class="mt-1">
                                <span class="font-bold">{{ cards.answered.auto.count }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="font-bold">{{ cards.answered.manual.count }}</span>
                            </div>
                        </div>
                    </div>
                </template>
            </card>
            <card
                class="px-3 w-full | xs:w-1/3 | xl:w-1/5"
                :icon="$icons.time"
                type="time"
                :card="cards.waitTime.number"
                :title="$t('phoneUp.waitTime')"
            >
                <template #description>
                    <div class="text-xl">
                        {{ $t('phoneUp.waitTimeCardBack') }}
                    </div>
                </template>
            </card>
            <card
                class="px-3 w-full | xs:w-1/3 | xl:w-1/5"
                :icon="$icons.appointment"
                :card="appointmentValue"
                :denominator="cards.answered.number.count"
                :title-icon="options.phoneAppointment ? $icons.phoneAppointment : ''"
                :title="$tc('phoneUp.appointment', appointmentValue.count)"
            >
                <template #description>
                    <div class="flex justify-center text-lg -mt-1">
                        <template v-if="options.phoneAppointment">
                            <div class="text-right">
                                <div class="flex items-center mt-1">
                                    {{ $tc('phoneUp.appointment', cards.phoneAppointment.number.count) }}
                                    <icon class="text-base ml-2" :name="$icons.phoneAppointment" />
                                </div>
                                <div class="mt-1">
                                    {{ $tc('phoneUp.appointment', cards.appointment.number.count) }}
                                </div>
                                <div class="mt-1">
                                    {{ $t('general.total') }}
                                </div>
                            </div>
                            <div class="text-left ml-3">
                                <div class="mt-1">
                                    <span class="font-bold">{{ cards.phoneAppointment.number.count }}</span>
                                </div>
                                <div class="mt-1">
                                    <span class="font-bold">{{ cards.appointment.number.count }}</span>
                                </div>
                                <div class="mt-1">
                                    <span class="font-bold">
                                        {{ cards.phoneAppointment.number.count + cards.appointment.number.count }}
                                    </span>
                                </div>
                            </div>
                            <div class="text-left ml-3">
                                <div class="mt-1">
                                    <span class="text-grey-600 text-base">({{ phoneApptPercent }})</span>
                                </div>
                                <div class="mt-1">
                                    <span class="text-grey-600 text-base">({{ regularApptPercent }})</span>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="text-right">
                                <div class="mt-1" v-if="contextAccount.confirmation_appt_sale">
                                    {{ $tc('result.confirmed', cards.appointment.number.confirmed) }}
                                </div>
                                <div class="mt-1">
                                    {{ $t('result.noShow') }}
                                </div>
                                <div class="mt-1">
                                    {{ $tc('result.canceled', cards.appointment.number.canceled) }}
                                </div>
                            </div>
                            <div class="text-right ml-3">
                                <div class="mt-1" v-if="contextAccount.confirmation_appt_sale">
                                    <span class="font-bold">{{ cards.appointment.number.confirmed }}</span>
                                </div>
                                <div class="mt-1">
                                    <span class="font-bold">{{ cards.appointment.number.noShow }}</span>
                                </div>
                                <div class="mt-1">
                                    <span class="font-bold">{{ cards.appointment.number.canceled }}</span>
                                </div>
                            </div>
                            <div class="text-left ml-3">
                                <div class="mt-1">
                                    <span class="text-grey-600 text-base">({{ confirmedApptPercent }})</span>
                                </div>
                                <div class="mt-1">
                                    <span class="text-grey-600 text-base">({{ noShowApptPercent }})</span>
                                </div>
                                <div class="mt-1">
                                    <span class="text-grey-600 text-base">({{ canceledApptPercent }})</span>
                                </div>
                            </div>
                        </template>
                    </div>
                </template>
                <activix-tooltip :content="phoneAppointmentToggleTooltip">
                    <activix-switcher
                        color="grey"
                        class="absolute right-0 bottom-0 mb-6 mr-9"
                        size="small"
                        :value="options.phoneAppointment"
                        @input="$eventBus.$emit('toggle-option', 'phoneAppointment', $event)"
                    />
                </activix-tooltip>
            </card>
            <card
                class="px-3 w-full | xs:w-1/2 | xl:w-1/5"
                :icon="$icons.visit"
                :card="presentedValue"
                :numerator="visitWithAppt"
                :denominator="cards.appointment.number.count"
                :back-numerator="presentedValue.count"
                :back-denominator="cards.leads.number.count"
                :title="$tc('phoneUp.presented', presentedValue.count)"
                :title-icon="options.homeVisit ? $icons.homeVisit : ''"
            >
                <template #description>
                    <div class="flex justify-center text-lg -mt-1">
                        <div class="text-right">
                            <div class="mt-1">
                                {{ $t('phoneUp.visitWithAppointment', visitWithAppt) }}
                            </div>
                            <div class="mt-1">
                                {{ $t('phoneUp.visitWithoutAppointment', presentedValue.countWithoutAppointment) }}
                            </div>
                        </div>
                        <div class="text-right ml-3">
                            <div class="mt-1">
                                <span class="font-bold">{{ visitWithAppt }}</span> /
                                <span class="font-bold">{{ cards.appointment.number.count }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="font-bold">{{ presentedValue.countWithoutAppointment }}</span> /
                                <span class="font-bold">{{ cards.leads.number.count }}</span>
                            </div>
                        </div>
                        <div class="text-left ml-3">
                            <div class="mt-1">
                                <span class="text-grey-600 text-base">({{ visitWithApptPercent }})</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-grey-600 text-base">({{ visitWithoutApptPercent }})</span>
                            </div>
                        </div>
                    </div>
                </template>
                <activix-tooltip :content="homeVisitToggleTooltip">
                    <activix-switcher
                        color="grey"
                        class="absolute right-0 bottom-0 mb-6 mr-9"
                        size="small"
                        :value="options.homeVisit"
                        @input="$eventBus.$emit('toggle-option', 'homeVisit', $event)"
                    />
                </activix-tooltip>
            </card>
            <card
                class="px-3 w-full | xs:w-1/2 | xl:w-1/5"
                :icon="$icons.sale"
                :card="cards.sale.number"
                :denominator="cards.answered.number.count"
                :title="$tc('phoneUp.sale', cards.sale.number.count)"
            >
                <template #description>
                    <div class="flex text-lg -mt-1 whitespace-nowrap">
                        <div class="text-right">
                            <div class="mt-1">
                                <span class="font-bold">{{ salePercent }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="font-bold">{{ salePerVisitPercent }}</span>
                            </div>
                        </div>
                        <div class="text-left ml-3 overflow-hidden">
                            <div class="mt-1 truncate">
                                {{ $t('phoneUp.salesOverCalls') }}
                            </div>
                            <div class="mt-1 truncate">
                                {{ $t('phoneUp.salesOverVisits') }}
                            </div>
                        </div>
                    </div>
                </template>
            </card>
        </div>

        <dashboard-graphs :dashboard="dashboard" :has-leads="!!cards.leads.number.count" ref="graphs" />

        <stats-table :dashboard="dashboard" ref="statsTable" />
    </div>
</template>

<script>
    // Utils
    import { mapState } from 'pinia';
    import { toPercentString } from '../../utils/numbers.js';

    // Components
    import Card from '../../components/dashboards/cards/Card.vue';
    import CardSmall from '../../components/dashboards/cards/CardSmall.vue';
    import DashboardGraphs from '../../components/dashboards/graphs/DashboardGraphs.vue';
    import StatsTable from '../StatsTables/StatsTable.vue';

    // Mixins
    import TrackView from '../../mixins/TrackView.js';
    import DashboardCaching from '../../mixins/DashboardCaching.js';
    import DashboardCustomFields from '../../mixins/DashboardCustomFields.js';

    // Entities
    import DashboardType from '../../entities/DashboardType.js';

    // Pinia
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        name: 'PhoneUp',

        components: {
            Card,
            CardSmall,
            DashboardGraphs,
            StatsTable,
        },

        mixins: [TrackView, DashboardCaching, DashboardCustomFields],

        data() {
            return {
                dashboard: DashboardType.PHONE_UP,
                cards: {
                    leads: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                    },
                    auto: {
                        number: {
                            count: 0,
                            ivr: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                    },
                    manual: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                    },
                    answered: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                                1: 'red',
                                70: 'orange',
                                80: 'green',
                            },
                        },
                        auto: {
                            count: 0,
                            colors: {
                                0: 'grey',
                                1: 'green',
                                30: 'orange',
                                60: 'red',
                            },
                        },
                        manual: {
                            count: 0,
                            colors: {
                                0: 'grey',
                                1: 'red',
                                10: 'orange',
                                12: 'green',
                            },
                        },
                    },
                    waitTime: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                                1: 'green',
                                30: 'orange',
                                60: 'red',
                            },
                        },
                    },
                    appointment: {
                        number: {
                            count: 0,
                            confirmed: 0,
                            noShow: 0,
                            canceled: 0,
                            colors: {
                                0: 'grey',
                                1: 'red',
                                15: 'orange',
                                25: 'green',
                            },
                        },
                    },
                    phoneAppointment: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                                1: 'red',
                                15: 'orange',
                                25: 'green',
                            },
                        },
                    },
                    presented: {
                        number: {
                            count: 0,
                            countWithoutAppointment: 0,
                            colors: {
                                0: 'grey',
                                1: 'red',
                                50: 'orange',
                                70: 'green',
                            },
                        },
                    },
                    presentedHome: {
                        number: {
                            count: 0,
                            countWithoutAppointment: 0,
                            colors: {
                                0: 'grey',
                                1: 'red',
                                50: 'orange',
                                70: 'green',
                            },
                        },
                    },
                    sale: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                                1: 'red',
                                5: 'orange',
                                12: 'green',
                            },
                        },
                    },
                    saleByPhone: {
                        count: 0,
                    },
                },
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),
            ...mapState(useDashboardStore, {
                options: store => store.configs.options,
            }),

            phoneApptPercent() {
                return toPercentString(
                    this.cards.phoneAppointment.number.count,
                    this.cards.phoneAppointment.number.count + this.cards.appointment.number.count,
                );
            },
            regularApptPercent() {
                return toPercentString(
                    this.cards.appointment.number.count,
                    this.cards.phoneAppointment.number.count + this.cards.appointment.number.count,
                );
            },
            confirmedApptPercent() {
                return toPercentString(this.cards.appointment.number.confirmed, this.cards.appointment.number.count);
            },
            noShowApptPercent() {
                return toPercentString(this.cards.appointment.number.noShow, this.cards.appointment.number.count);
            },
            canceledApptPercent() {
                return toPercentString(this.cards.appointment.number.canceled, this.cards.appointment.number.count);
            },
            appointmentValue() {
                if (this.options.phoneAppointment) {
                    return this.cards.phoneAppointment.number;
                }

                return this.cards.appointment.number;
            },

            presentedValue() {
                if (this.options.homeVisit) {
                    return this.cards.presentedHome.number;
                }

                return this.cards.presented.number;
            },

            phoneAppointmentToggleTooltip() {
                if (this.options.phoneAppointment) {
                    return this.$t('loyalty.appointmentCallTooltipOn');
                }

                return this.$t('loyalty.appointmentCallTooltipOff');
            },

            homeVisitToggleTooltip() {
                if (this.options.homeVisit) {
                    return this.$t('loyalty.homeVisitTooltipOn');
                }

                return this.$t('loyalty.homeVisitTooltipOff');
            },

            salePercent() {
                return toPercentString(this.cards.sale.number.count, this.cards.answered.number.count);
            },

            salePerVisitPercent() {
                return toPercentString(this.cards.sale.number.count, this.cards.presented.number.count);
            },

            visitWithApptPercent() {
                return toPercentString(this.visitWithAppt, this.cards.appointment.number.count);
            },

            visitWithoutApptPercent() {
                return toPercentString(this.presentedValue.countWithoutAppointment, this.cards.leads.number.count);
            },

            visitWithAppt() {
                return this.presentedValue.count - this.presentedValue.countWithoutAppointment;
            },
        },

        methods: {
            async fetchStats(options) {
                await Promise.all([this.$refs.statsTable.fetchStats(options), this.$refs.graphs.fetch(options)]);
            },

            setColumns() {
                useDashboardStore().configs.columns = {
                    bulk_select: {
                        visible: true,
                    },
                    fullname: {
                        visible: true,
                    },
                    contact_method: {
                        visible: true,
                    },
                    civility: {
                        visible: false,
                    },
                    business: {
                        visible: false,
                    },
                    created_by: {
                        visible: false,
                    },
                    updated_by: {
                        visible: false,
                    },
                    'customer:casl_consent_status': {
                        visible: false,
                    },
                    'customer:casl_consent_limit_date': {
                        visible: false,
                    },
                    date: {
                        visible: true,
                    },
                    updated_at: {
                        visible: false,
                    },
                    'division:name': {
                        visible: true,
                    },
                    status: {
                        visible: true,
                    },
                    result: {
                        visible: true,
                    },
                    'lead_type:name': {
                        visible: false,
                    },
                    advisors: {
                        visible: true,
                    },
                    agents: {
                        visible: true,
                    },
                    'commercial:': {
                        visible: false,
                    },
                    'account:name': {
                        visible: true,
                    },
                    rating: {
                        visible: false,
                    },
                    province: {
                        visible: false,
                    },
                    city: {
                        visible: false,
                    },
                    country: {
                        visible: false,
                    },
                    address: {
                        visible: false,
                    },
                    locale: {
                        visible: false,
                    },
                    sector: {
                        visible: false,
                    },
                    budget: {
                        visible: false,
                    },
                    walk_around: {
                        visible: false,
                    },
                    'wanted_vehicles:budget_min': {
                        visible: false,
                    },
                    'wanted_vehicles:budget_max': {
                        visible: false,
                    },
                    created_method: {
                        visible: false,
                    },
                    lead_state: {
                        visible: false,
                    },
                    'import:original_file': {
                        visible: false,
                    },
                    'wanted_vehicles:category': {
                        visible: false,
                    },
                    'wanted_vehicles:category_rv': {
                        visible: false,
                    },
                    'wanted_vehicles:mechanical': {
                        visible: false,
                    },
                    'wanted_vehicles:length_min': {
                        visible: false,
                    },
                    'wanted_vehicles:length_max': {
                        visible: false,
                    },
                    'wanted_vehicles:weight': {
                        visible: false,
                    },
                    'wanted_vehicles:fuel': {
                        visible: false,
                    },
                    'wanted_vehicles:make': {
                        visible: false,
                    },
                    'wanted_vehicles:model': {
                        visible: false,
                    },
                    'wanted_vehicles:year': {
                        visible: false,
                    },
                    'wanted_vehicles:version': {
                        visible: false,
                    },
                    'wanted_vehicles:certified': {
                        visible: false,
                    },
                    'wanted_vehicles:transmission': {
                        visible: false,
                    },
                    'wanted_vehicles:color_exterior': {
                        visible: false,
                    },
                    'wanted_vehicles:color_interior': {
                        visible: false,
                    },
                    'wanted_vehicles:stock_state': {
                        visible: false,
                    },
                    'wanted_vehicles:stock': {
                        visible: false,
                    },
                    'wanted_vehicles:vin': {
                        visible: false,
                    },
                    phone_appointment: {
                        visible: true,
                    },
                    appointment: {
                        visible: true,
                    },
                    presented: {
                        visible: true,
                    },
                    progress_state: {
                        visible: true,
                    },
                    approved: {
                        visible: true,
                    },
                    'lead_bank:name': {
                        visible: false,
                    },
                    waiting_sale: {
                        visible: false,
                    },
                    road_test: {
                        visible: false,
                    },
                    long_term: {
                        visible: false,
                    },
                    dealer_tour: {
                        visible: false,
                    },
                    take_over: {
                        visible: false,
                    },
                    'take_over_director:': {
                        visible: false,
                    },
                    twenty_four_hour: {
                        visible: true,
                    },
                    be_back: {
                        visible: false,
                    },
                    deposit: {
                        visible: false,
                    },
                    write_up: {
                        visible: false,
                    },
                    sale_by_phone: {
                        visible: true,
                    },
                    sale: {
                        visible: true,
                    },
                    available: {
                        visible: false,
                    },
                    paperwork: {
                        visible: false,
                    },
                    prepared: {
                        visible: true,
                    },
                    prepared_work_order: {
                        visible: false,
                    },
                    deliverable: {
                        visible: false,
                    },
                    delivery: {
                        visible: false,
                    },
                    delivered: {
                        visible: true,
                    },
                    gas: {
                        visible: false,
                    },
                    'wanted_vehicles:recorded_date': {
                        visible: false,
                    },
                    csi: {
                        visible: false,
                    },
                    'exchange_vehicles:category': {
                        visible: false,
                    },
                    'exchange_vehicles:category_rv': {
                        visible: false,
                    },
                    'exchange_vehicles:mechanical': {
                        visible: false,
                    },
                    'exchange_vehicles:length_min': {
                        visible: false,
                    },
                    'exchange_vehicles:length_max': {
                        visible: false,
                    },
                    'exchange_vehicles:weight': {
                        visible: false,
                    },
                    'exchange_vehicles:make': {
                        visible: false,
                    },
                    'exchange_vehicles:model': {
                        visible: false,
                    },
                    'exchange_vehicles:year': {
                        visible: false,
                    },
                    'exchange_vehicles:version': {
                        visible: false,
                    },
                    'exchange_vehicles:certified': {
                        visible: false,
                    },
                    'exchange_vehicles:transmission': {
                        visible: false,
                    },
                    'exchange_vehicles:color_exterior': {
                        visible: false,
                    },
                    'exchange_vehicles:color_interior': {
                        visible: false,
                    },
                    'exchange_vehicles:condition': {
                        visible: false,
                    },
                    'exchange_vehicles:fuel': {
                        visible: false,
                    },
                    'exchange_vehicles:tire_type': {
                        visible: false,
                    },
                    'exchange_vehicles:accidented_damage_cost': {
                        visible: false,
                    },
                    'exchange_vehicles:value': {
                        visible: false,
                    },
                    'exchange_vehicles:actual_value': {
                        visible: false,
                    },
                    'exchange_vehicles:calculator_appraiser': {
                        visible: false,
                    },
                    'exchange_vehicles:calculator_value': {
                        visible: false,
                    },
                    phone_origin: {
                        visible: false,
                    },
                    phone_destination: {
                        visible: false,
                    },
                    listening: {
                        visible: true,
                    },
                    followed: {
                        visible: true,
                    },
                    followed_by: {
                        visible: false,
                    },
                    todo: {
                        visible: true,
                    },
                    source: {
                        visible: true,
                    },
                    form: {
                        visible: false,
                    },
                    'latest_comment:content': {
                        visible: true,
                    },
                    unsubscribe: {
                        visible: false,
                    },
                    birth_date: {
                        visible: false,
                    },
                    ...this.getCustomFields(),
                };
            },

            setCards(results) {
                this.cards.leads.number.count = results.count;

                this.cards.phoneAppointment.number.count = results.phoneAppointments;

                this.cards.appointment.number.count = results.appointments.count;
                this.cards.appointment.number.noShow = results.appointments.noShow;
                this.cards.appointment.number.confirmed = results.appointments.confirmed;
                this.cards.appointment.number.canceled = results.appointments.canceled;

                this.cards.presented.number.count = results.visits.count;
                this.cards.presented.number.countWithoutAppointment = results.visits.withoutAppointment;

                this.cards.presentedHome.number.count = results.homeVisits.count;
                this.cards.presentedHome.number.countWithoutAppointment = results.homeVisits.withoutAppointment;

                this.cards.sale.number.count = results.sales.count;
                this.cards.saleByPhone.count = results.sales.byPhone;

                this.cards.manual.number.count = results.createdMethods.manual;
                this.cards.auto.number.count = results.createdMethods.automatic;
                this.cards.auto.number.ivr = results.createdMethods.ivr;

                this.cards.answered.number.count = results.answers.count;
                this.cards.answered.auto.count = results.answers.automatic;
                this.cards.answered.manual.count = results.answers.manual;
                this.cards.waitTime.number.count = results.answers.responseTime;

                this.$nextTick(() => {
                    this.setCaching('cards', this.cards);
                });
            },
        },
    };
</script>
