<template>
    <div class="flex items-center justify-center cursor-pointer" @click="openConsentModal">
        <div class="flex items-center">
            <consent-shield class="text-xl" :lead="lead" />
        </div>
        <span class="ml-2">{{ lead.customer.consentStateString }}</span>
    </div>
</template>

<script>
    import ConsentShield from '@/components/ConsentShield.vue';

    export default {
        components: {
            ConsentShield,
        },

        props: {
            lead: {
                type: Object,
                required: true,
            },
        },

        methods: {
            openConsentModal() {
                this.$modal.show('consentConfig', {
                    leadId: this.lead.id,
                });
            },
        },
    };
</script>
