<template>
    <div>
        <div class="flex flex-wrap lg:-mx-3">
            <card-small
                class="px-3 w-full | xs:w-1/2 | lg:w-1/4"
                :number="cards.leads.number.count"
                :title="$tc('general.client', cards.leads.number.count)"
                :back-number="cards.leads.assigned.count"
                :back-title="$tc('dashboards.assignedClient', cards.leads.assigned.count)"
            />
            <card-small
                class="px-3 w-full | xs:w-1/2 | lg:w-1/4"
                :number="cards.met.number.count"
                :title="$tc('commercial.clientMet', cards.met.number.count)"
            />
            <card-small
                class="px-3 w-full | xs:w-1/2 | lg:w-1/4"
                :number="cards.notMet.number.count"
                :title="$tc('commercial.clientNotMet', cards.notMet.number.count)"
                :back-number="cards.exclude.number.count"
                :back-title="$tc('commercial.clientExclude', cards.exclude.number.count)"
            />
            <card-small
                class="px-3 w-full | xs:w-1/2 | lg:w-1/4"
                :number="cards.refused.number.count"
                :title="$tc('commercial.clientRefused', cards.refused.number.count)"
            />
        </div>

        <div class="flex flex-wrap lg:-mx-3">
            <card
                class="px-3 w-full | xs:w-1/3 | xl:w-1/5"
                icon="regular/send-email-monitor"
                :card="cards.pending.number"
                :denominator="cards.leads.number.count"
                :title="$tc('commercial.pending', cards.pending.number.count)"
            />
            <card
                class="px-3 w-full | xs:w-1/3 | xl:w-1/5"
                icon="regular/task-list-approve"
                :card="cards.approved.number"
                :denominator="cards.leads.number.count"
                :title="$tc('commercial.approved', cards.approved.number.count)"
            />
            <card
                class="px-3 w-full | xs:w-1/3 | xl:w-1/5"
                :icon="$icons.delivery"
                :card="cards.delivered.number"
                :denominator="cards.leads.number.count"
                :title="$tc('commercial.delivered', cards.delivered.number.count)"
            />
            <card
                class="px-3 w-full | xs:w-1/2 | xl:w-1/5"
                type="money"
                :icon="$icons.sale"
                :card="cards.totalProfit.number"
                :title="$t('commercial.totalProfit')"
            />
            <card
                class="px-3 w-full | xs:w-1/2 | xl:w-1/5"
                icon="regular/accounting-document"
                type="money"
                :card="cards.averageProfit.number"
                :title="$t('commercial.averageProfit')"
            />
        </div>

        <stats-table :dashboard="dashboard" ref="statsTable" />
    </div>
</template>

<script>
    // Components
    import Card from '../../components/dashboards/cards/Card.vue';
    import CardSmall from '../../components/dashboards/cards/CardSmall.vue';
    import StatsTable from '../StatsTables/StatsTable.vue';

    // Mixins
    import TrackView from '../../mixins/TrackView.js';
    import DashboardCaching from '../../mixins/DashboardCaching.js';
    import DashboardCustomFields from '../../mixins/DashboardCustomFields.js';

    // Entities
    import DashboardType from '../../entities/DashboardType.js';

    // Pinia
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';

    export default {
        name: 'Commercial',

        components: {
            Card,
            CardSmall,
            StatsTable,
        },

        mixins: [TrackView, DashboardCaching, DashboardCustomFields],

        data() {
            return {
                dashboard: DashboardType.COMMERCIAL,
                cards: {
                    leads: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                        assigned: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                    },
                    met: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                    },
                    notMet: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                    },
                    exclude: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                    },
                    refused: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'grey',
                            },
                        },
                    },
                    pending: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'blue',
                            },
                        },
                    },
                    approved: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'blue',
                            },
                        },
                    },
                    delivered: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'blue',
                            },
                        },
                    },
                    totalProfit: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'green',
                            },
                        },
                    },
                    averageProfit: {
                        number: {
                            count: 0,
                            colors: {
                                0: 'green',
                            },
                        },
                    },
                },
            };
        },

        methods: {
            async fetchStats(options) {
                await this.$refs.statsTable.fetchStats(options);
            },

            setColumns() {
                useDashboardStore().configs.columns = {
                    bulk_select: {
                        visible: true,
                    },
                    fullname: {
                        visible: true,
                    },
                    contact_method: {
                        visible: true,
                    },
                    civility: {
                        visible: false,
                    },
                    business: {
                        visible: false,
                    },
                    created_by: {
                        visible: false,
                    },
                    updated_by: {
                        visible: false,
                    },
                    'customer:casl_consent_status': {
                        visible: false,
                    },
                    'customer:casl_consent_limit_date': {
                        visible: false,
                    },
                    date: {
                        title: this.$t('clientCard.sold'),
                        visible: true,
                    },
                    updated_at: {
                        visible: false,
                    },
                    'division:name': {
                        visible: true,
                        mandatory: true,
                    },
                    status: {
                        visible: true,
                    },
                    advisors: {
                        visible: true,
                    },
                    agents: {
                        visible: true,
                    },
                    'commercial:': {
                        visible: true,
                    },
                    'account:name': {
                        visible: true,
                    },
                    rating: {
                        visible: false,
                    },
                    address: {
                        visible: false,
                    },
                    locale: {
                        visible: false,
                    },
                    sector: {
                        visible: true,
                    },
                    budget: {
                        visible: true,
                    },
                    walk_around: {
                        visible: false,
                    },
                    'wanted_vehicles:budget_min': {
                        visible: false,
                    },
                    'wanted_vehicles:budget_max': {
                        visible: false,
                    },
                    created_method: {
                        visible: false,
                    },
                    lead_state: {
                        visible: false,
                    },
                    'import:original_file': {
                        visible: false,
                    },
                    'wanted_vehicles:category': {
                        visible: false,
                    },
                    'wanted_vehicles:category_rv': {
                        visible: false,
                    },
                    'wanted_vehicles:mechanical': {
                        visible: false,
                    },
                    'wanted_vehicles:length_min': {
                        visible: false,
                    },
                    'wanted_vehicles:length_max': {
                        visible: false,
                    },
                    'wanted_vehicles:weight': {
                        visible: false,
                    },
                    'wanted_vehicles:make': {
                        visible: false,
                    },
                    'wanted_vehicles:model': {
                        visible: false,
                    },
                    'wanted_vehicles:year': {
                        visible: false,
                    },
                    'wanted_vehicles:fuel': {
                        visible: false,
                    },
                    'wanted_vehicles:version': {
                        visible: false,
                    },
                    'wanted_vehicles:certified': {
                        visible: false,
                    },
                    'wanted_vehicles:transmission': {
                        visible: false,
                    },
                    'wanted_vehicles:color_exterior': {
                        visible: false,
                    },
                    'wanted_vehicles:color_interior': {
                        visible: false,
                    },
                    'wanted_vehicles:stock_state': {
                        visible: true,
                    },
                    'wanted_vehicles:stock': {
                        visible: true,
                    },
                    'wanted_vehicles:vin': {
                        visible: false,
                    },
                    'wanted_vehicles:price': {
                        visible: true,
                    },
                    'wanted_vehicles:profit': {
                        visible: true,
                    },
                    'wanted_vehicles:rate': {
                        visible: true,
                    },
                    'wanted_vehicles:modality': {
                        visible: true,
                    },
                    progress_state: {
                        visible: true,
                    },
                    approved: {
                        visible: true,
                    },
                    'lead_bank:name': {
                        visible: true,
                    },
                    waiting_sale: {
                        visible: false,
                    },
                    sale_by_phone: {
                        visible: false,
                    },
                    deliverable: {
                        visible: false,
                    },
                    delivery: {
                        visible: true,
                    },
                    available: {
                        visible: false,
                    },
                    paperwork: {
                        visible: false,
                    },
                    prepared: {
                        visible: true,
                    },
                    prepared_work_order: {
                        visible: false,
                    },
                    inspected: {
                        visible: true,
                    },
                    gas: {
                        visible: false,
                    },
                    delivered: {
                        visible: true,
                    },
                    'wanted_vehicles:recorded_date': {
                        visible: false,
                    },
                    discounted_date: {
                        visible: false,
                    },
                    long_term: {
                        visible: false,
                    },
                    write_up: {
                        visible: false,
                    },
                    'exchange_vehicles:category': {
                        visible: false,
                    },
                    'exchange_vehicles:category_rv': {
                        visible: false,
                    },
                    'exchange_vehicles:mechanical': {
                        visible: false,
                    },
                    'exchange_vehicles:length_min': {
                        visible: false,
                    },
                    'exchange_vehicles:length_max': {
                        visible: false,
                    },
                    'exchange_vehicles:weight': {
                        visible: false,
                    },
                    'exchange_vehicles:certified': {
                        visible: false,
                    },
                    'exchange_vehicles:transmission': {
                        visible: false,
                    },
                    'exchange_vehicles:color_exterior': {
                        visible: false,
                    },
                    'exchange_vehicles:color_interior': {
                        visible: false,
                    },
                    'exchange_vehicles:condition': {
                        visible: false,
                    },
                    'exchange_vehicles:fuel': {
                        visible: false,
                    },
                    'exchange_vehicles:tire_type': {
                        visible: false,
                    },
                    'exchange_vehicles:accidented_damage_cost': {
                        visible: false,
                    },
                    'exchange_vehicles:value': {
                        visible: false,
                    },
                    'exchange_vehicles:actual_value': {
                        visible: false,
                    },
                    'exchange_vehicles:calculator_appraiser': {
                        visible: false,
                    },
                    'exchange_vehicles:calculator_value': {
                        visible: false,
                    },
                    'commercial_products:pellicule': {
                        visible: false,
                    },
                    'commercial_products:wheel_protection': {
                        visible: false,
                    },
                    'commercial_products:mouse_repellent': {
                        visible: false,
                    },
                    'commercial_products:paint_treatment': {
                        visible: false,
                    },
                    'commercial_products:roof_treatment': {
                        visible: false,
                    },
                    'commercial_products:leather_tissu_interior_treatment': {
                        visible: false,
                    },
                    'commercial_products:niotext': {
                        visible: false,
                    },
                    'commercial_products:walk_in': {
                        visible: false,
                    },
                    'commercial_products:sale_table': {
                        visible: false,
                    },
                    'commercial_products:in_turn': {
                        visible: false,
                    },
                    'commercial_products:renewal': {
                        visible: false,
                    },
                    'commercial_products:event': {
                        visible: false,
                    },
                    'commercial_products:service': {
                        visible: false,
                    },
                    'commercial_products:financing': {
                        visible: false,
                    },
                    'commercial_products:ins_filling': {
                        visible: false,
                    },
                    'commercial_products:extended_warranty': {
                        visible: false,
                    },
                    'commercial_products:starter': {
                        visible: false,
                    },
                    'commercial_products:window_tint': {
                        visible: false,
                    },
                    'commercial_products:pre_paid_maintenance': {
                        visible: false,
                    },
                    'commercial_products:seat_protection': {
                        visible: false,
                    },
                    'commercial_products:pef': {
                        visible: false,
                    },
                    'commercial_products:pep': {
                        visible: false,
                    },
                    'commercial_products:other': {
                        visible: false,
                    },
                    'commercial_products:ins_rental': {
                        visible: false,
                    },
                    'commercial_products:ins_invalidity': {
                        visible: false,
                    },
                    'commercial_products:ins_health': {
                        visible: false,
                    },
                    'commercial_products:ins_life': {
                        visible: false,
                    },
                    'commercial_products:rustproofing': {
                        visible: false,
                    },
                    'commercial_products:chiselling': {
                        visible: false,
                    },
                    'commercial_products:anti_theft': {
                        visible: false,
                    },
                    'commercial_products:windshield_treatment': {
                        visible: false,
                    },
                    'commercial_products:burn_protection': {
                        visible: false,
                    },
                    'commercial_products:flame_quard_protection': {
                        visible: false,
                    },
                    followed: {
                        visible: true,
                    },
                    todo: {
                        visible: true,
                    },
                    commercial_meeting: {
                        visible: false,
                    },
                    commercial_no_need: {
                        visible: false,
                    },
                    'latest_comment:content': {
                        visible: true,
                    },
                    unsubscribe: {
                        visible: false,
                    },
                    birth_date: {
                        visible: true,
                    },
                    ...this.getCustomFields(),
                };
            },

            setCards(results) {
                this.cards.leads.number.count = results.count;
                this.cards.leads.assigned.count = results.assignments;

                this.cards.approved.number.count = results.progressStates.approved;
                this.cards.refused.number.count = results.progressStates.refused;
                this.cards.pending.number.count = results.progressStates.pending;

                this.cards.delivered.number.count = results.deliveries;
                this.cards.totalProfit.number.count = results.profits.total;
                this.cards.averageProfit.number.count = results.profits.average;

                this.cards.met.number.count = results.meetings.met;
                this.cards.notMet.number.count = results.meetings.notMet;
                this.cards.exclude.number.count = results.meetings.excluded;

                this.$nextTick(() => {
                    this.setCaching('cards', this.cards);
                });
            },
        },
    };
</script>
