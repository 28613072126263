<template>
    <div class="overflow-hidden cursor-normal">
        <span v-if="!bdcUser">-</span>
        <activix-tooltip :content="agentTooltip">
            <div class="truncate">
                <div class="truncate" :class="{ 'opacity-50': secondBdcUser }">
                    {{ getName(bdcUser) }}
                </div>
            </div>
        </activix-tooltip>
    </div>
</template>

<script>
    /* eslint-disable vue/require-prop-types */
    import { mapState } from 'pinia';
    import { getFullName } from '../../../utils/index.js';
    import { useDashboardStore } from '../../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../../store/modules/context/store.js';
    import { useGlobalStore } from '../../../store/store.js';

    export default {
        props: ['lead', 'light'],

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'configs']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),
            ...mapState(useDashboardStore, ['dashboardType']),

            agentTooltip() {
                const exportStringBDC = `${this.getName(this.bdcUser)}`;
                const exportStringSecondBDC = `${this.getName(this.secondBdcUser)}`;

                if (this.secondBdcUser) {
                    return `
                        ${this.$t('clientCard.assignation.transferAssignedTooltip', [exportStringBDC])}
                        <br>
                        ${this.$t('clientCard.assignation.transferWaitingTooltip', [exportStringSecondBDC])}
                    `;
                }

                return this.bdcUser ? exportStringBDC : '';
            },

            bdcUser() {
                return this.lead.bdc_user_id ? this.lead.bdc_user : null;
            },

            secondBdcUser() {
                return this.lead.second_bdc_user_id ? this.lead.second_bdc_user : null;
            },
        },

        methods: {
            getName(user) {
                return user ? getFullName(user) : '';
            },
        },
    };
</script>
