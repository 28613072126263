<template>
    <activix-checkbox
        size="xs"
        :disabled="disabled"
        :value="isInBulkSelect"
        @click.native.prevent="select"
    />
</template>

<script>
    /* eslint-disable vue/require-prop-types */
    import { mapState } from 'pinia';
    import { useDashboardStore } from '../../../store/modules/dashboard/store.js';

    export default {
        props: ['lead', 'disabled'],

        computed: {
            ...mapState(useDashboardStore, {
                bulkSelect: 'bulkSelect',
                blackListedLeadIds: 'blackListedLeadIds',
                allLeadsInTableSelected: 'allLeadsInTableSelected',
            }),

            isInBulkSelect() {
                if (this.allLeadsInTableSelected) {
                    return !this.blackListedLeadIds.some(leadId => leadId == this.lead.id);
                }

                return this.bulkSelect.some(lead => lead.id == this.lead.id);
            },
        },

        methods: {
            select() {
                this.$emit('toggle-bulk-select', {
                    lead: this.lead,
                    checked: !this.isInBulkSelect,
                });
            },
        },
    };
</script>
