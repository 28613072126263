<template>
    <div class="lead-table-filters | table-header-row">
        <div
            class="flex items-center justify-center table-header-cell | p-2"
            :class="column.class"
            :style="column.style"
            :key="column.name"
            v-for="column in activeDashboardColumns"
        >
            <activix-tooltip :content="column.filterTooltip">
                <template v-if="column.name == 'bulk_select'">
                    <icon class="text-sm text-grey-500" name="bold/filter-2" />
                </template>
                <template v-else>
                    <template v-if="['text', 'number'].includes(column.filter.type)">
                        <input-filter
                            :type="column.filter.type"
                            :column="column.name"
                            :filter-applied="filterApplied"
                            :filters-buffer="filtersBuffer"
                            @update="updateBuffer"
                        />
                    </template>
                    <template v-else-if="column.filter.type == 'string'">
                        <activix-tooltip :content="$t('general.excludeTerm')">
                            <input-filter
                                :column="column.name"
                                :filter-applied="filterApplied"
                                :filters-buffer="filtersBuffer"
                                @update="updateBuffer"
                            />
                        </activix-tooltip>
                    </template>
                    <multiselect-filter
                        class="w-full"
                        :base-trans="column.filter.baseTrans"
                        :column-class="column.class"
                        :column-name="column.name"
                        :filters-buffer="filtersBuffer"
                        :multiple="column.filter.multiple"
                        :option-type="column.filter.optionType"
                        :options="selectOptions(column)"
                        :select-all="column.filter.selectAll"
                        :sorting="column.filter.sorting === false ? false : true"
                        :with-empty="column.filter.withEmpty"
                        :with-none="column.filter.withNone"
                        :none-label="column.filter.noneLabel"
                        @update="updateBuffer"
                        v-else-if="column.filter.type == 'select'"
                    />
                </template>
            </activix-tooltip>
        </div>
    </div>
</template>

<script>
    import { isFunction, isArray, mapValues } from 'lodash-es';

    import { mapActions, mapState } from 'pinia';

    // Components
    import InputFilter from './InputFilter.vue';
    import MultiselectFilter from './MultiselectFilter.vue';

    // Mixins
    import DashboardColumns from '../../mixins/DashboardColumns.js';
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            InputFilter,
            MultiselectFilter,
        },

        mixins: [DashboardColumns],

        props: {
            filterApplied: {
                type: Boolean,
                required: true,
            },
            filtered: {
                type: Boolean,
                default: false,
            },
            filtersBuffer: {
                type: Object,
                required: true,
            },
        },
        computed: {
            ...mapState(useDashboardStore, ['dashboardView']),
        },

        methods: {
            ...mapActions(useDashboardStore, ['setDashboardConfigs']),
            ...mapActions(useGlobalStore, ['updateSessionAction']),

            updateBuffer({ column, value }) {
                this.$emit('update:filter', column, value);
            },

            applyFilters() {
                const filters = mapValues(this.filtersBuffer, filterValue => {
                    return filterValue || '';
                });

                this.updateFilters(filters);
            },

            clearFilters() {
                const filters = mapValues(this.filtersBuffer, (value, column) => {
                    let emptyValue = isArray(value) ? [] : '';

                    if (this.dashboardView.filter[column]) {
                        emptyValue = JSON.parse(JSON.stringify(this.dashboardView.filter[column]));
                    }

                    this.updateBuffer({ column, value: emptyValue });

                    return emptyValue;
                });

                this.updateFilters(filters, this.filtered);
            },

            updateFilters(filters, fetch = true) {
                this.setDashboardConfigs({ filters });
                this.updateSessionAction('dashboard_filter', filters);

                if (fetch) {
                    this.$eventBus.$emit('filters-applied');
                }
            },

            selectOptions(column) {
                return isFunction(column.filter.options) ? column.filter.options() : column.filter.options || [];
            },
        },

        created() {
            this.$eventBus.$on('apply-filters', this.applyFilters);
            this.$eventBus.$on('clear-filters', this.clearFilters);
        },

        beforeDestroy() {
            this.$eventBus.$off('apply-filters', this.applyFilters);
            this.$eventBus.$off('clear-filters', this.clearFilters);
        },
    };
</script>
