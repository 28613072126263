<template>
    <activix-modal
        size="sm"
        :opened="opened"
        :closable="false"
        @close="close"
        @closed="onClosed"
    >
        <template slot="body">
            <div :class="{ 'flex flex-col justify-center h-full': lgLayout }">
                <sweet-alert-icon class="mx-auto" type="error" />
                <div class="text-2xl font-semibold text-center my-2 text-grey-650" v-text="$tc('client.deleteLeads.title', leadsCount)" />
                <div class="text-lg text-center" v-text="modalBody" />
            </div>
        </template>

        <template slot="footer">
            <activix-button @click="close">
                {{ $t('general.no') }}
            </activix-button>
            <activix-button
                type="danger"
                :disabled="failed"
                :loading="processing"
                @click="deleteLeads"
            >
                {{ $t('general.yes') }}
            </activix-button>
        </template>
    </activix-modal>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import SweetAlertIcon from '../elements/SweetAlert/Icon.vue';
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            SweetAlertIcon,
        },

        props: {
            leadsCount: {
                type: Number,
                required: true,
            },
            opened: {
                type: Boolean,
                required: true,
            },
        },

        data() {
            return {
                processing: false,
                onCallLeads: [],
                failedLeads: [],
            };
        },

        computed: {
            ...mapState(useDashboardStore, {
                dashboardCriteria: 'dashboardCriteria',
                order: store => store.configs.order,
                options: store => store.configs.options,
                dashboardType: 'dashboardType',
                allLeadsInTableSelected: 'allLeadsInTableSelected',
                bulkSelect: 'bulkSelect',
                blackListedLeadIds: 'blackListedLeadIds',
            }),

            modalBody() {
                // Failed
                if (this.failedLeads.length) {
                    return this.$tc('client.deleteLeads.failed', this.failedLeads.length);
                }

                // On call
                if (this.onCallLeads.length) {
                    return this.$tc('client.deleteLeads.failedCalling', this.onCallLeads.length);
                }

                // Default
                return this.$tc('client.deleteLeads.confirmation', this.leadsCount, { count: this.leadsCount });
            },

            failed() {
                return !!this.failedLeads.length || !!this.onCallLeads.length;
            },
        },

        methods: {
            ...mapActions(useDashboardStore, ['setAllLeadsInTableSelected']),
            ...mapActions(useGlobalStore, ['appendNewError']),

            async deleteLeads() {
                this.processing = true;

                const query = (() => {
                    if (this.allLeadsInTableSelected) {
                        return { search: this.dashboardCriteria };
                    }

                    if (this.bulkSelect) {
                        return { ids: this.bulkSelect.map(lead => lead.id) };
                    }

                    return { id: this.contextLead.id };
                })();

                // Delete lead
                try {
                    const response = await this.$api.leads.delete(query, {
                        blackListedLeadIds: this.allLeadsInTableSelected ? this.blackListedLeadIds : [],
                    });

                    this.$notify.info(this.$t('massActions.warningDelete'));
                    this.processing = false;

                    const data = response.data;

                    if (data.failed?.length > 0 || data.onCall?.length > 0) {
                        this.onCallLeads = data.onCall;
                        this.failedLeads = data.failed;
                    } else {
                        this.close();
                    }

                    this.setAllLeadsInTableSelected(false);

                    if (data.deleted?.length > 0) {
                        this.$eventBus.$emit('delete-leads', data.deleted);
                    }
                } catch (error) {
                    this.processing = false;

                    if (error.response && error.response.status === 403) {
                        return;
                    }

                    this.appendNewError({
                        code: '0102',
                        display: true,
                        error,
                    });
                }
            },

            close() {
                this.$emit('update:opened', false);
            },

            onClosed() {
                this.processing = false;
                this.onCallLeads = [];
                this.failedLeads = [];
            },
        },
    };
</script>
