<template>
    <div class="flex justify-center text-lg">
        <div class="text-right">
            <div class="mt-1">
                {{ $tc('leadXpress.leads', cards.leadsWeb.sale.count) }}
            </div>
            <div class="mt-1">
                {{ $tc('webOrder.leads', cards.leadsWebOrder.sale.count) }}
            </div>
        </div>
        <div class="text-left ml-3">
            <div class="mt-1">
                <span class="font-bold">{{ cards.leadsWeb.sale.count }}</span>
            </div>
            <div class="mt-1">
                <span class="font-bold">{{ cards.leadsWebOrder.sale.count }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            cards: {
                type: Object,
                default: () => {},
            },
        },
    };
</script>
