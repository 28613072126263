<template>
    <el-dropdown
        placement="bottom"
        trigger="click"
        :hide-on-click="false"
        @command="onColumnClick"
        @visible-change="columnsSelectorVisible"
    >
        <activix-tooltip :content="$t('dashboards.toggleColumns')">
            <activix-button
                class="h-8 w-10 p-0"
                type="white"
                @mouseenter="render = true"
            >
                <icon name="regular/layout-two-colums" class="link-grey" />
            </activix-button>
        </activix-tooltip>

        <el-dropdown-menu class="max-h-96 overflow-y-auto pt-0" slot="dropdown" ref="dropdownMenu">
            <label class="flex items-center px-4 m-0">
                <icon class="text-grey-600 text-sm flex-shrink-0" name="regular/search" />
                <input
                    class="pl-2 h-12 w-full"
                    :placeholder="$t('search.search')"
                    ref="search"
                    v-model="searchColumnsInput"
                />
            </label>

            <el-dropdown-item
                class="mt-0"
                command="all"
                :divided="true"
                v-if="!searchColumnsInput"
            >
                <activix-checkbox size="xs" :value="allSelected" @click.native.prevent>
                    <span class="bold">{{ $t('filters.all') }}</span>
                </activix-checkbox>
            </el-dropdown-item>

            <template v-if="render">
                <div class="border-t border-gray-200 italic text-center text-gray-600 pt-2" v-text="$t('multiselect.noResult')" v-if="!sortedColumns.length" />

                <el-dropdown-item
                    class="whitespace-nowrap"
                    :class="{ 'mt-0': index === 0 && searchColumnsInput }"
                    :command="column.name"
                    :divided="index === 0"
                    :key="column.name"
                    v-for="(column, index) in sortedColumns"
                >
                    <activix-checkbox size="xs" :value="column.visible" @click.native.prevent>
                        <span v-html="column.title" />
                    </activix-checkbox>
                </el-dropdown-item>
            </template>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
    import { latinize } from '@/utils/string.js';
    import DashboardColumns from '@/mixins/DashboardColumns.js';
    import { mapState } from 'pinia';
    import { sort } from '../../utils/index.js';

    // Mixins

    // Pinia
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';

    export default {
        mixins: [DashboardColumns],

        data() {
            return {
                render: false,
                searchColumnsInput: '',
            };
        },

        computed: {
            ...mapState(useDashboardStore, {
                dashboardConfigs: 'configs',
            }),

            sortedColumns() {
                const columns = this.dashboardColumns
                    .filter(column => {
                        const title = latinize(column.sortTitle.toLowerCase());
                        const search = latinize(this.searchColumnsInput.toLowerCase());

                        return title.includes(search) && !column.mandatory;
                    });

                return sort(columns, 'sortTitle');
            },

            allSelected() {
                return Object.values(this.sortedColumns).every(config => config.visible);
            },
        },

        watch: {
            searchColumnsInput() {
                this.$nextTick(() => {
                    // eslint-disable-next-line vue/custom-event-name-casing
                    this.$refs.dropdownMenu.$emit('updatePopper');
                });
            },
        },

        methods: {
            onColumnClick(name) {
                if (name == 'all') {
                    this.$emit('select-all-toggled', !this.allSelected);
                } else {
                    this.$emit('update:column', name);
                }
            },

            columnsSelectorVisible(visible) {
                if (visible) {
                    this.$nextTick(() => {
                        this.$refs.search.focus({ preventScroll: true });
                    });
                } else {
                    this.searchColumnsInput = '';
                }
            },
        },
    };
</script>
