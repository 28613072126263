<template>
    <div>
        <div class="w-full lg:w-1/2">
            <div class="highcharts" ref="leadsByDivision" />
            <div class="text-center italic">
                {{ $t('graph.useOnlyCreatedAt') }}
            </div>
        </div>
        <div class="highcharts | w-full lg:w-1/2" ref="salesBySource" />
        <div class="highcharts | w-full lg:w-1/2" ref="leadsByProvider" />
        <div class="highcharts | w-full lg:w-1/2" ref="appointmentsWithOrWithoutPhone" />
        <div class="highcharts | w-full lg:w-1/2" ref="leadsByUser" />
        <div class="highcharts | w-full lg:w-1/2" ref="salesWithOrWithoutPhone" />
        <div class="highcharts | w-full lg:w-1/2" ref="leadsBySource" />
        <div class="highcharts | w-full lg:w-1/2" ref="leadsByForm" />
        <div class="highcharts | w-full lg:w-1/2" ref="salesAndDeliveriesByUser" />
        <div class="highcharts | w-full lg:w-1/2" ref="salesAndDeliveriesByModel" />
    </div>
</template>

<script>
    import Highcharts from 'highcharts/highstock.js';

    // Entities
    import { mapState } from 'pinia';
    import Division from '../../../entities/Division.js';

    // Mixins
    import Graphs from '../../../mixins/Graphs.js';

    // Pinia
    import { useContextStore } from '../../../store/modules/context/store.js';
    import { useDashboardStore } from '../../../store/modules/dashboard/store.js';

    export default {
        mixins: [Graphs],

        props: {
            data: {
                type: Object,
                mandatory: true,
            },
        },

        data() {
            return {
                dataLeadByForm: [],
                dataLeadBySource: [],
                dataLeadByUser: [],
                dataLeadNew: [],
                dataLeadNoDivision: [],
                dataLeadUsed: [],
                dataOrigin: [],
                dataResult: [],
                dataSaleDeliveryByModel: [],
                dataSaleDeliveryByUser: [],
            };
        },

        computed: {
            ...mapState(useDashboardStore, ['division']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),
        },

        watch: {
            data: {
                immediate: true,
                handler() {
                    this.setData();
                },
            },
        },

        methods: {
            setData() {
                this.dataSaleDeliveryByUser = {
                    labels: [],
                    data: [],
                    dataDelivered: [],
                };

                this.dataSaleDeliveryByModel = {
                    labels: [],
                    data: [],
                    dataDelivered: [],
                };

                this.dataLeadNew = {
                    labels: [],
                    data: [],
                };

                this.dataLeadUsed = {
                    labels: [],
                    data: [],
                };

                this.dataLeadNoDivision = {
                    labels: [],
                    data: [],
                };

                this.dataOrigin = {
                    labels: [],
                    labels2: [],
                    data: [],
                    dataPhone: [],
                };

                this.dataResult = {
                    labels: [],
                    data: [],
                };

                this.dataLeadByUser = {
                    labels: [],
                    data: [],
                    dataAppt: [],
                };

                this.dataLeadBySource = {
                    labels: [],
                    data: [],
                    dataAppt: [],
                };

                this.dataLeadByForm = {
                    labels: [],
                    data: [],
                    dataAppt: [],
                };

                // Code
                // Fetch Leads
                // Array used for data appt
                // const rangeArr = [];
                const apptArr = [];
                const saleArr = [];

                let apptWithTel = 0;
                let apptWithoutTel = 0;
                let saleWithTel = 0;
                let saleWithoutTel = 0;

                const accountUsers = !empty(this.contextAccount) ? this.contextAccount.users : [];
                const sources = !empty(this.contextAccount) ? this.contextAccount.sources : [];

                if (this.data) {
                    // Web Order by dates and divisions
                    for (const [key, value] of Object.entries(this.data.webOrderByDatesAndDivisions.dates)) {
                        this.dataLeadNew.labels.push(key);
                        this.dataLeadUsed.labels.push(key);
                        this.dataLeadNoDivision.labels.push(key);

                        this.dataLeadNew.data.push(value.divisions[Division.NEW] || 0);
                        this.dataLeadUsed.data.push(value.divisions[Division.USED] || 0);
                        this.dataLeadNoDivision.data.push(value.divisions?.none || 0);
                    }

                    // Web Order with appointments and sales by sources
                    for (const [key, value] of Object.entries(
                        this.data.webOrderWithAppointmentsAndSalesBySources.sources,
                    )) {
                        const currentSource = sources.find(source => source.id == key);
                        const sourceName = currentSource?.name || this.$t('general.withoutSources');

                        this.dataResult.labels.push(sourceName);
                        apptArr.push(value.appointments || 0);
                        saleArr.push(value.sales || 0);
                    }

                    // Web Order with phones by sources
                    for (const [key, value] of Object.entries(this.data.webOrderWithPhonesBySources.sources)) {
                        const currentSource = sources.find(source => source.id == key);
                        const sourceName = currentSource?.name || this.$t('general.withoutSources');

                        this.dataOrigin.labels.push(sourceName);
                        this.dataOrigin.data.push(value.count);
                        this.dataOrigin.dataPhone.push(value.phones);
                    }

                    // Web Order with phones by appointments
                    apptWithTel = this.data.webOrderWithPhonesByAppointments.with_phone;
                    apptWithoutTel = this.data.webOrderWithPhonesByAppointments.without_phone;

                    // Web Order with phones by sales
                    saleWithTel = this.data.webOrderWithPhonesBySales.with_phone;
                    saleWithoutTel = this.data.webOrderWithPhonesBySales.without_phone;

                    // Web Order with appointments by users
                    for (let i = accountUsers.length - 1; i >= 0; i--) {
                        const userName = this.getFullName(accountUsers[i]);
                        const appointmentUser = this.data.webOrderWithAppointmentsByUsers.users[accountUsers[i].id];
                        const saleAndDeliveryUser = this.data.webOrderWithSalesAndDeliveriesByUsers.users[
                            accountUsers[i].id
                        ];

                        if (appointmentUser) {
                            this.dataLeadByUser.labels.push(userName);
                            this.dataLeadByUser.data.push(appointmentUser.count);
                            this.dataLeadByUser.dataAppt.push(appointmentUser.with_appointment);
                        }

                        if (saleAndDeliveryUser) {
                            this.dataSaleDeliveryByUser.labels.push(userName);
                            this.dataSaleDeliveryByUser.data.push(saleAndDeliveryUser.sales);
                            this.dataSaleDeliveryByUser.dataDelivered.push(saleAndDeliveryUser.delivered);
                        }
                    }

                    this.dataResult.data.push({
                        appt: apptArr,
                        sale: saleArr,
                        apptWithTel,
                        apptWithoutTel,
                        saleWithTel,
                        saleWithoutTel,
                    });

                    // Web Order with appointments by lead forms
                    for (const [key, value] of Object.entries(this.data.webOrderWithAppointmentsByForms.forms)) {
                        this.dataLeadByForm.labels.push(key);
                        this.dataLeadByForm.data.push(value.count);
                        this.dataLeadByForm.dataAppt.push(value.with_appointment);
                    }

                    // Web Order with appointments by sources
                    for (const [key, value] of Object.entries(this.data.webOrderWithAppointmentsBySources.sources)) {
                        this.dataLeadBySource.labels.push(key);
                        this.dataLeadBySource.data.push(value.count);
                        this.dataLeadBySource.dataAppt.push(value.with_appointment);
                    }

                    // Web Order with sales and deliveries by models
                    for (const [key, value] of Object.entries(
                        this.data.webOrderWithSalesandDeliveriesByModels.models,
                    )) {
                        this.dataSaleDeliveryByModel.labels.push(key);
                        this.dataSaleDeliveryByModel.data.push(value.sales);
                        this.dataSaleDeliveryByModel.dataDelivered.push(value.deliveries);
                    }
                }

                this.$emit('graph-loaded', true);

                this.$nextTick(() => {
                    this.loadGraphs();
                });
            },

            loadGraphs() {
                if (
                    this.dataLeadNew.data.length == 0 &&
                    this.dataLeadUsed.data.length == 0 &&
                    this.dataLeadNoDivision.data.length == 0
                ) {
                    return;
                }

                this.destroyCharts();

                const leadSeries = [];

                if (this.division.includes('new') || empty(this.division)) {
                    leadSeries.push({
                        name: this.$t('graph.new'),
                        data: this.dataLeadNew.data,
                        color: 'rgba(60, 141, 188,1)',
                    });
                }

                if (this.division.includes('used') || empty(this.division)) {
                    leadSeries.push({
                        name: this.$t('graph.used'),
                        data: this.dataLeadUsed.data,
                        color: 'rgba(118, 175, 208,1)',
                    });
                }

                if (this.division.includes('none') || empty(this.division)) {
                    leadSeries.push({
                        name: this.$t('graph.noDivision'),
                        data: this.dataLeadNoDivision.data,
                        color: 'rgba(158,158,158,1)',
                    });
                }

                // Origin Total
                let originTotal = 0;

                $.each(this.dataOrigin.data, (key, origin) => {
                    originTotal += origin;
                });

                // Origin Total
                let leadTotal = 0;

                $.each(this.dataLeadByUser.data, (key, user) => {
                    leadTotal += user;
                });

                let scrollbarLead = false;
                let maxLead = this.dataLeadByUser.labels.length - 1;

                if (this.dataLeadByUser.labels.length > 10) {
                    scrollbarLead = true;
                    maxLead = 9;
                }

                let scrollbarOrigin = false;
                let maxOrigin = this.dataOrigin.labels.length - 1;

                if (this.dataOrigin.labels.length > 10) {
                    scrollbarOrigin = true;
                    maxOrigin = 9;
                }

                let scrollbarSource = false;
                let maxSource = this.dataLeadBySource.labels.length - 1;

                if (this.dataLeadBySource.labels.length > 10) {
                    scrollbarSource = true;
                    maxSource = 9;
                }

                let scrollbarForm = false;
                let maxForm = this.dataLeadByForm.labels.length - 1;

                if (this.dataLeadByForm.labels.length > 10) {
                    scrollbarForm = true;
                    maxForm = 9;
                }

                let scrollbarSaleDeliveryByUsers = false;
                let maxSaleDeliveryByUsers = this.dataSaleDeliveryByUser.labels.length - 1;

                if (this.dataSaleDeliveryByUser.labels.length > 10) {
                    scrollbarSaleDeliveryByUsers = true;
                    maxSaleDeliveryByUsers = 9;
                }

                let scrollbarSaleDeliveryByModels = false;
                let maxSaleDeliveryByModels = this.dataSaleDeliveryByModel.labels.length - 1;

                if (this.dataSaleDeliveryByModel.labels.length > 10) {
                    scrollbarSaleDeliveryByModels = true;
                    maxSaleDeliveryByModels = 9;
                }

                const self = this;

                this.charts.push(
                    Highcharts.chart(this.$refs.leadsBySource, {
                        chart: {
                            type: 'bar',
                        },
                        title: {
                            text: this.$t('graph.webOrderBySource'),
                        },
                        xAxis: {
                            categories: this.dataLeadBySource.labels,
                            max: maxSource,
                            scrollbar: {
                                enabled: scrollbarSource,
                            },
                        },
                        series: [
                            {
                                name: this.$t('graph.appt'),
                                data: this.dataLeadBySource.dataAppt,
                                color: 'rgba(60,141,188,1)',
                            },
                            {
                                name: this.$t('graph.webOrder'),
                                data: this.dataLeadBySource.data,
                                color: 'rgba(158,158,158,1)',
                            },
                        ],
                    }),
                );

                this.charts.push(
                    Highcharts.chart(this.$refs.leadsByForm, {
                        chart: {
                            type: 'bar',
                        },
                        title: {
                            text: this.$t('graph.webOrderByForm'),
                        },
                        xAxis: {
                            categories: this.dataLeadByForm.labels,
                            max: maxForm,
                            scrollbar: {
                                enabled: scrollbarForm,
                            },
                        },
                        series: [
                            {
                                name: this.$t('graph.appt'),
                                data: this.dataLeadByForm.dataAppt,
                                color: 'rgba(60,141,188,1)',
                            },
                            {
                                name: this.$t('graph.webOrder'),
                                data: this.dataLeadByForm.data,
                                color: 'rgba(158,158,158,1)',
                            },
                        ],
                    }),
                );

                this.charts.push(
                    Highcharts.chart(this.$refs.leadsByDivision, {
                        chart: {
                            type: 'areaspline',
                        },
                        title: {
                            text: this.$t('graph.numberOfWebOrder'),
                        },
                        xAxis: {
                            categories: this.dataLeadNew.labels,
                        },
                        yAxis: {
                            title: {
                                text: this.$t('graph.webOrder'),
                            },
                            tickInterval: 1,
                        },
                        tooltip: {
                            valueSuffix: ` ${this.$t('graph.webOrder').toLowerCase()}`,
                        },
                        plotOptions: {
                            areaspline: {
                                fillOpacity: 0.7,
                            },
                        },
                        series: leadSeries,
                    }),
                );

                this.charts.push(
                    Highcharts.chart(this.$refs.salesBySource, {
                        chart: {
                            type: 'bar',
                        },
                        title: {
                            text: this.$t('graph.apptSaleByOrigin'),
                        },
                        xAxis: {
                            categories: this.dataResult.labels,
                        },
                        series: [
                            {
                                name: this.$t('graph.appt'),
                                data: this.dataResult.data[0].appt,
                                color: 'rgba(60,141,188,1)',
                            },
                            {
                                name: this.$t('graph.sale'),
                                data: this.dataResult.data[0].sale,
                                color: 'rgba(158,158,158,1)',
                            },
                        ],
                    }),
                );

                this.charts.push(
                    Highcharts.chart(this.$refs.leadsByProvider, {
                        chart: {
                            type: 'column',
                        },
                        title: {
                            text: this.$t('graph.webOrderByOrigin'),
                        },
                        legend: {
                            enabled: true,
                        },
                        xAxis: {
                            min: 0,
                            max: maxOrigin,
                            categories: this.dataOrigin.labels,
                            labels: {
                                formatter() {
                                    return `<div style="text-align:center; white-space:nowrap; min-height:45px; max-height:45px;">${this.value.replace(
                                        ' ',
                                        '<br>',
                                    )}</div>`;
                                },
                                useHTML: true,
                            },
                        },
                        yAxis: {
                            min: 0,
                            title: {
                                text: this.$t('graph.webOrder'),
                            },
                        },
                        scrollbar: {
                            enabled: scrollbarOrigin,
                        },
                        tooltip: {
                            pointFormatter() {
                                if (this.series.name == self.$t('graph.webOrder')) {
                                    // TODO Validate if the total should be on the lead with a source (originLead) or the lead total (leadTotal)
                                    const tmpPercentage = ((this.y / originTotal) * 100).toFixed(0);
                                    return `<span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${this.y} (${tmpPercentage}%)</b><br>`;
                                }

                                const totalForCurrentOrigin =
                                    self.dataOrigin.data[self.dataOrigin.labels.indexOf(this.category)];
                                const tmpPercentage = ((this.y / totalForCurrentOrigin) * 100).toFixed(0);
                                return `<span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${this.y} (${tmpPercentage}%, ${this.y}/${totalForCurrentOrigin})</b><br>`;
                            },
                        },
                        plotOptions: {
                            column: {
                                grouping: false,
                                shadow: false,
                            },
                        },
                        series: [
                            {
                                name: this.$t('graph.webOrder'),
                                color: 'rgba(118, 175, 208,1)',
                                data: this.dataOrigin.data,
                            },
                            {
                                name: this.$t('graph.withPhone'),
                                color: 'rgba(60, 141, 188,1)',
                                data: this.dataOrigin.dataPhone,
                            },
                        ],
                    }),
                );

                this.charts.push(
                    Highcharts.chart(this.$refs.appointmentsWithOrWithoutPhone, {
                        chart: {
                            plotBackgroundColor: null,
                            plotBorderWidth: null,
                            plotShadow: false,
                            type: 'pie',
                        },
                        title: {
                            text: this.$t('graph.apptWithWitoutPhone'),
                        },
                        tooltip: {
                            pointFormat: '{series.name}: <b>{point.y} ({point.percentage:.0f}%)</b>',
                        },
                        plotOptions: {
                            pie: {
                                allowPointSelect: true,
                                cursor: 'pointer',
                                dataLabels: {
                                    enabled: true,
                                    format: '<b>{point.name}</b>: {point.y} ({point.percentage:.0f}%)',
                                    style: {
                                        color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
                                    },
                                },
                            },
                        },
                        series: [
                            {
                                name: this.$t('graph.appt'),
                                data: [
                                    {
                                        name: this.$t('graph.withPhone'),
                                        color: 'rgba(118, 175, 208,1)',
                                        y: this.dataResult.data[0].apptWithTel,
                                    },
                                    {
                                        name: this.$t('graph.withoutPhone'),
                                        color: 'rgba(60, 141, 188,1)',
                                        y: this.dataResult.data[0].apptWithoutTel,
                                    },
                                ],
                            },
                        ],
                    }),
                );

                this.charts.push(
                    Highcharts.chart(this.$refs.leadsByUser, {
                        chart: {
                            type: 'column',
                        },
                        title: {
                            text: this.$t('graph.webOrderByUser'),
                        },
                        legend: {
                            enabled: true,
                        },
                        xAxis: {
                            min: 0,
                            max: maxLead,
                            categories: this.dataLeadByUser.labels,
                            labels: {
                                formatter() {
                                    return `<div style="text-align:center; white-space:nowrap; min-height:45px; max-height:45px;">${this.value
                                        .replace(/(\s) {1}/g, '<br>')
                                        .replace('<br>/<br>', ' /<br>')}</div>`;
                                },
                                useHTML: true,
                            },
                        },
                        yAxis: {
                            min: 0,
                            title: {
                                text: this.$t('graph.webOrder'),
                            },
                        },
                        scrollbar: {
                            enabled: scrollbarLead,
                        },
                        tooltip: {
                            pointFormatter() {
                                if (this.series.name == self.$t('graph.appt')) {
                                    const totWithAppt =
                                        self.dataLeadByUser.data[self.dataLeadByUser.labels.indexOf(this.category)];
                                    const tmpPercentage = ((this.y / totWithAppt) * 100).toFixed(0);
                                    return `<span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${this.y} (${tmpPercentage}%, ${this.y}/${totWithAppt})</b><br>`;
                                }

                                const tmpPercentage = ((this.y / leadTotal) * 100).toFixed(0);
                                return `<span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${this.y} (${tmpPercentage}%)</b><br>`;
                            },
                        },
                        plotOptions: {
                            column: {
                                grouping: false,
                                shadow: false,
                            },
                        },
                        series: [
                            {
                                name: this.$t('graph.webOrder'),
                                color: 'rgba(118, 175, 208,1)',
                                data: this.dataLeadByUser.data,
                            },
                            {
                                name: this.$t('graph.appt'),
                                color: 'rgba(60, 141, 188,1)',
                                data: this.dataLeadByUser.dataAppt,
                            },
                        ],
                    }),
                );

                this.charts.push(
                    Highcharts.chart(this.$refs.salesWithOrWithoutPhone, {
                        chart: {
                            plotBackgroundColor: null,
                            plotBorderWidth: null,
                            plotShadow: false,
                            type: 'pie',
                        },
                        title: {
                            text: this.$t('graph.saleWithWitoutPhone'),
                        },
                        tooltip: {
                            pointFormat: '{series.name}: <b>{point.y} ({point.percentage:.0f}%)</b>',
                        },
                        plotOptions: {
                            pie: {
                                allowPointSelect: true,
                                cursor: 'pointer',
                                dataLabels: {
                                    enabled: true,
                                    format: '<b>{point.name}</b>: {point.y} ({point.percentage:.0f}%)',
                                    style: {
                                        color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
                                    },
                                },
                            },
                        },
                        series: [
                            {
                                name: this.$t('graph.sale'),
                                data: [
                                    {
                                        name: this.$t('graph.withPhone'),
                                        color: 'rgba(118, 175, 208,1)',
                                        y: this.dataResult.data[0].saleWithTel,
                                    },
                                    {
                                        name: this.$t('graph.withoutPhone'),
                                        color: 'rgba(60, 141, 188,1)',
                                        y: this.dataResult.data[0].saleWithoutTel,
                                    },
                                ],
                            },
                        ],
                    }),
                );

                this.charts.push(
                    Highcharts.chart(this.$refs.salesAndDeliveriesByUser, {
                        chart: {
                            type: 'column',
                        },
                        title: {
                            text: this.$t('graph.saleDeliveryByUsers'),
                        },
                        xAxis: {
                            title: {
                                text: this.$t('graph.users'),
                            },
                            categories: this.dataSaleDeliveryByUser.labels,
                            max: maxSaleDeliveryByUsers,
                            scrollbar: {
                                enabled: scrollbarSaleDeliveryByUsers,
                            },
                        },
                        yAxis: {
                            title: {
                                text: this.$t('graph.numbers'),
                            },
                        },
                        series: [
                            {
                                name: this.$t('graph.sale'),
                                data: this.dataSaleDeliveryByUser.data,
                                color: 'rgba(118, 175, 208,1)',
                            },
                            {
                                name: this.$t('graph.delivered'),
                                data: this.dataSaleDeliveryByUser.dataDelivered,
                                color: 'rgba(158, 158, 158, 1)',
                            },
                        ],
                        legend: {
                            enabled: false,
                        },
                    }),
                );

                this.charts.push(
                    Highcharts.chart(this.$refs.salesAndDeliveriesByModel, {
                        chart: {
                            type: 'column',
                        },
                        title: {
                            text: this.$t('graph.saleDeliveryByModels'),
                        },
                        xAxis: {
                            title: {
                                text: this.$t('graph.models'),
                            },
                            categories: this.dataSaleDeliveryByModel.labels,
                            max: maxSaleDeliveryByModels,
                            scrollbar: {
                                enabled: scrollbarSaleDeliveryByModels,
                            },
                        },
                        yAxis: {
                            title: {
                                text: this.$t('graph.numbers'),
                            },
                        },
                        series: [
                            {
                                name: this.$t('graph.sale'),
                                data: this.dataSaleDeliveryByModel.data,
                                color: 'rgba(118, 175, 208,1)',
                            },
                            {
                                name: this.$t('graph.delivered'),
                                data: this.dataSaleDeliveryByModel.dataDelivered,
                                color: 'rgba(158, 158, 158, 1)',
                            },
                        ],
                        legend: {
                            enabled: false,
                        },
                    }),
                );
            },
        },
    };
</script>
