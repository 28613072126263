<template>
    <div>
        <div class="flex flex-wrap lg:-mx-3">
            <card-small
                class="px-3 w-full | xs:w-1/2 | lg:w-1/4"
                back-icon="regular/single-neutral-actions"
                :number="cards.number.count"
                :title="$tc('webOrder.leads', cards.number.count)"
            >
                <template #description>
                    <span class="font-bold">{{ cards.assigned.count }}</span>
                    {{ $tc('dashboards.assignedWebOrder', cards.assigned.count) }}
                </template>
            </card-small>
            <card-small
                class="px-3 w-full | xs:w-1/2 | lg:w-1/4"
                back-icon="regular/navigation-menu-horizontal"
                :number="cards.untreated.count"
                :title="$tc('webOrder.untreated', cards.untreated.count)"
            >
                <template #description>
                    <span class="font-bold">{{ untreatedPercent }}</span>
                    {{ $t('webOrder.untreatedBack') }}
                </template>
            </card-small>
            <card-small
                class="px-3 w-full | xs:w-1/2 | lg:w-1/4"
                :back-icon="$icons.attempted"
                :number="cards.attempted.count"
                :title="$tc('webOrder.attempted', cards.attempted.count)"
            >
                <template #description>
                    <span class="font-bold">{{ attemptedPercent }}</span>
                    {{ $t('webOrder.attemptedBack') }}
                </template>
            </card-small>
            <card-small
                class="px-3 w-full | xs:w-1/2 | lg:w-1/4"
                :back-icon="$icons.reached"
                :number="cards.reached.count"
                :title="$tc('webOrder.reached', cards.reached.count)"
            >
                <template #description>
                    <span class="font-bold">{{ reachedPercent }}</span>
                    {{ $t('webOrder.reachedBackShort') }}
                </template>
            </card-small>
        </div>

        <div class="flex flex-wrap lg:-mx-3">
            <card
                class="px-3 w-full | xs:w-1/3 | xl:w-1/5"
                back-type="time"
                type="time"
                :back-icon="$icons.time"
                :icon="$icons.time"
                :card="cards.reachedAverage"
                :title="$t('webOrder.reachedAverage')"
                :back-card="cards.firstUpdateAverage"
                :back-title="$t('webOrder.firstUpdateAverage')"
            >
                <template #popover>
                    <div v-html="$t('webOrder.resultTimeInfo')" />
                </template>
            </card>
            <card
                class="px-3 w-full | xs:w-1/3 | xl:w-1/5"
                :icon="$icons.appointment"
                :card="appointmentValue"
                :denominator="cards.number.count"
                :title-icon="options.phoneAppointment ? $icons.phoneAppointment : ''"
                :title="$tc('webOrder.appointment', appointmentValue.count)"
            >
                <template #description>
                    <div class="flex justify-center text-lg -mt-1">
                        <template v-if="options.phoneAppointment">
                            <div class="text-right">
                                <div class="flex items-center mt-1">
                                    {{ $tc('webOrder.appointment', cards.phoneAppointment.count) }}
                                    <icon class="text-base ml-2" :name="$icons.phoneAppointment" />
                                </div>
                                <div class="mt-1">
                                    {{ $tc('webOrder.appointment', cards.appointment.count) }}
                                </div>
                                <div class="mt-1">
                                    {{ $t('general.total') }}
                                </div>
                            </div>
                            <div class="text-left ml-3">
                                <div class="mt-1">
                                    <span class="font-bold">{{ cards.phoneAppointment.count }}</span>
                                </div>
                                <div class="mt-1">
                                    <span class="font-bold">{{ cards.appointment.count }}</span>
                                </div>
                                <div class="mt-1">
                                    <span class="font-bold">
                                        {{ cards.phoneAppointment.count + cards.appointment.count }}
                                    </span>
                                </div>
                            </div>
                            <div class="text-left ml-3">
                                <div class="mt-1">
                                    <span class="text-grey-600 text-base">({{ phoneApptPercent }})</span>
                                </div>
                                <div class="mt-1">
                                    <span class="text-grey-600 text-base">({{ regularApptPercent }})</span>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="text-right">
                                <div class="mt-1" v-if="contextAccount.confirmation_appt_sale">
                                    {{ $tc('result.confirmed', cards.appointment.confirmed) }}
                                </div>
                                <div class="mt-1">
                                    {{ $t('result.noShow') }}
                                </div>
                                <div class="mt-1">
                                    {{ $tc('result.canceled', cards.appointment.canceled) }}
                                </div>
                            </div>
                            <div class="text-left ml-3">
                                <div class="mt-1" v-if="contextAccount.confirmation_appt_sale">
                                    <span class="font-bold">{{ cards.appointment.confirmed }}</span>
                                </div>
                                <div class="mt-1">
                                    <span class="font-bold">{{ cards.appointment.noShow }}</span>
                                </div>
                                <div class="mt-1">
                                    <span class="font-bold">{{ cards.appointment.canceled }}</span>
                                </div>
                            </div>
                            <div class="text-left ml-3">
                                <div class="mt-1">
                                    <span class="text-grey-600 text-base">({{ confirmedApptPercent }})</span>
                                </div>
                                <div class="mt-1">
                                    <span class="text-grey-600 text-base">({{ noShowApptPercent }})</span>
                                </div>
                                <div class="mt-1">
                                    <span class="text-grey-600 text-base">({{ canceledApptPercent }})</span>
                                </div>
                            </div>
                        </template>
                    </div>
                </template>
                <activix-tooltip :content="phoneAppointmentToggleTooltip">
                    <activix-switcher
                        color="grey"
                        class="absolute right-0 bottom-0 mb-6 mr-9"
                        size="small"
                        :value="options.phoneAppointment"
                        @input="$eventBus.$emit('toggle-option', 'phoneAppointment', $event)"
                    />
                </activix-tooltip>
            </card>
            <card
                class="px-3 w-full | xs:w-1/3 | xl:w-1/5"
                :icon="$icons.visit"
                :card="presentedValue"
                :numerator="visitWithAppt"
                :denominator="cards.appointment.count"
                :back-numerator="presentedValue.count"
                :back-denominator="cards.number.count"
                :title="$tc('webOrder.presented', presentedValue.count)"
                :title-icon="options.homeVisit ? $icons.homeVisit : ''"
            >
                <template #description>
                    <div class="flex justify-center text-lg -mt-1">
                        <div class="text-right">
                            <div class="mt-1">
                                {{ $t('webOrder.visitWithAppointment') }}
                            </div>
                            <div class="mt-1">
                                {{ $t('webOrder.visitWithoutAppointment') }}
                            </div>
                        </div>
                        <div class="text-right ml-3">
                            <div class="mt-1">
                                <span class="font-bold">{{ visitWithAppt }}</span> /
                                <span class="font-bold">{{ cards.appointment.count }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="font-bold">{{ presentedValue.countWithoutAppointment }}</span> /
                                <span class="font-bold">{{ cards.number.count }}</span>
                            </div>
                        </div>
                        <div class="text-left ml-3">
                            <div class="mt-1">
                                <span class="text-grey-600 text-base">({{ visitWithApptPercent }})</span>
                            </div>
                            <div class="mt-1">
                                <span class="text-grey-600 text-base">({{ visitWithoutApptPercent }})</span>
                            </div>
                        </div>
                    </div>
                </template>
                <activix-tooltip :content="homeVisitToggleTooltip">
                    <activix-switcher
                        color="grey"
                        class="absolute right-0 bottom-0 mb-6 mr-9"
                        size="small"
                        :value="options.homeVisit"
                        @input="$eventBus.$emit('toggle-option', 'homeVisit', $event)"
                    />
                </activix-tooltip>
            </card>
            <card
                class="px-3 w-full | xs:w-1/2 | xl:w-1/5"
                :icon="$icons.sale"
                :card="cards.sale"
                :denominator="cards.number.count"
                :title="$tc('webOrder.sale', cards.sale.count)"
            >
                <template #description>
                    <div class="flex text-lg -mt-1">
                        <div class="text-right">
                            <div class="mt-1">
                                <span class="font-bold">{{ salePercent }}</span>
                            </div>
                            <div class="mt-1">
                                <span class="font-bold">{{ salePerVisitPercent }}</span>
                            </div>
                        </div>
                        <div class="text-left ml-3">
                            <div class="mt-1">
                                {{ $t('webOrder.salesOverLeads') }}
                            </div>
                            <div class="mt-1">
                                {{ $t('webOrder.salesOverVisits') }}
                            </div>
                        </div>
                    </div>
                </template>
            </card>
            <card
                class="px-3 w-full | xs:w-1/2 | xl:w-1/5"
                :icon="$icons.delivery"
                :card="cards.delivered"
                :denominator="cards.number.count"
                :title="$tc('webOrder.delivered', cards.delivered.count)"
            >
                <template #description>
                    <div class="text-xl">
                        {{ $t('webOrder.deliveredBack') }}
                    </div>
                </template>
            </card>
        </div>

        <dashboard-graphs :dashboard="dashboard" :has-leads="!!cards.number.count" ref="graphs">
            <div class="mt-4 italic">
                * {{ $t('graph.withPhoneStatInitial') }}
            </div>
        </dashboard-graphs>

        <stats-table :dashboard="dashboard" ref="statsTable" />
    </div>
</template>

<script>
    // Utils
    import { mapState } from 'pinia';
    import { toPercentString } from '../../utils/numbers.js';

    // Components
    import Card from '../../components/dashboards/cards/Card.vue';
    import CardSmall from '../../components/dashboards/cards/CardSmall.vue';
    import DashboardGraphs from '../../components/dashboards/graphs/DashboardGraphs.vue';
    import StatsTable from '../StatsTables/StatsTable.vue';

    // Mixins
    import TrackView from '../../mixins/TrackView.js';
    import DashboardCaching from '../../mixins/DashboardCaching.js';
    import DashboardCustomFields from '../../mixins/DashboardCustomFields.js';

    // Entities
    import DashboardType from '../../entities/DashboardType.js';

    // Pinia
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        name: 'WebOrder',

        components: {
            Card,
            CardSmall,
            DashboardGraphs,
            StatsTable,
        },

        mixins: [TrackView, DashboardCaching, DashboardCustomFields],

        data() {
            return {
                dashboard: DashboardType.WEB_ORDER,
                cards: {
                    number: {
                        count: 0,
                        colors: {
                            0: 'grey',
                        },
                    },
                    assigned: {
                        count: 0,
                        colors: {
                            0: 'grey',
                        },
                    },
                    untreated: {
                        count: 0,
                        colors: {
                            0: 'grey',
                        },
                    },
                    attempted: {
                        count: 0,
                        colors: {
                            0: 'grey',
                        },
                    },
                    reached: {
                        count: 0,
                        colors: {
                            0: 'grey',
                        },
                    },
                    reachedAverage: {
                        count: 0,
                        colors: {
                            0: 'blue',
                        },
                    },
                    firstUpdateAverage: {
                        count: 0,
                        colors: {
                            0: 'blue',
                        },
                    },
                    appointment: {
                        count: 0,
                        confirmed: 0,
                        noShow: 0,
                        canceled: 0,
                        colors: {
                            0: 'grey',
                            1: 'red',
                            30: 'orange',
                            60: 'green',
                        },
                    },
                    phoneAppointment: {
                        count: 0,
                        colors: {
                            0: 'grey',
                            1: 'red',
                            30: 'orange',
                            60: 'green',
                        },
                    },
                    presented: {
                        count: 0,
                        countWithoutAppointment: 0,
                        colors: {
                            0: 'grey',
                            1: 'red',
                            70: 'orange',
                            90: 'green',
                        },
                    },
                    presentedHome: {
                        count: 0,
                        countWithoutAppointment: 0,
                        colors: {
                            0: 'grey',
                            1: 'red',
                            50: 'orange',
                            70: 'green',
                        },
                    },
                    sale: {
                        count: 0,
                        colors: {
                            0: 'grey',
                            1: 'red',
                            70: 'orange',
                            85: 'green',
                        },
                    },
                    saleByPhone: {
                        count: 0,
                    },
                    delivered: {
                        count: 0,
                        colors: {
                            0: 'blue',
                        },
                    },
                },
            };
        },

        computed: {
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),
            ...mapState(useDashboardStore, {
                options: store => store.configs.options,
            }),

            phoneApptPercent() {
                return toPercentString(
                    this.cards.phoneAppointment.count,
                    this.cards.phoneAppointment.count + this.cards.appointment.count,
                );
            },

            regularApptPercent() {
                return toPercentString(
                    this.cards.appointment.count,
                    this.cards.phoneAppointment.count + this.cards.appointment.count,
                );
            },

            confirmedApptPercent() {
                return toPercentString(this.cards.appointment.confirmed, this.cards.appointment.count);
            },

            noShowApptPercent() {
                return toPercentString(this.cards.appointment.noShow, this.cards.appointment.count);
            },

            canceledApptPercent() {
                return toPercentString(this.cards.appointment.canceled, this.cards.appointment.count);
            },

            appointmentValue() {
                if (this.options.phoneAppointment) {
                    return this.cards.phoneAppointment;
                }

                return this.cards.appointment;
            },

            phoneAppointmentToggleTooltip() {
                if (this.options.phoneAppointment) {
                    return this.$t('loyalty.appointmentCallTooltipOn');
                }

                return this.$t('loyalty.appointmentCallTooltipOff');
            },

            presentedValue() {
                if (this.options.homeVisit) {
                    return this.cards.presentedHome;
                }

                return this.cards.presented;
            },

            homeVisitToggleTooltip() {
                if (this.options.homeVisit) {
                    return this.$t('loyalty.homeVisitTooltipOn');
                }

                return this.$t('loyalty.homeVisitTooltipOff');
            },

            untreatedPercent() {
                return toPercentString(this.cards.untreated.count, this.cards.number.count);
            },

            attemptedPercent() {
                return toPercentString(this.cards.attempted.count, this.cards.number.count);
            },

            reachedPercent() {
                return toPercentString(this.cards.reached.count, this.cards.number.count);
            },

            salePercent() {
                return toPercentString(this.cards.sale.count, this.cards.number.count);
            },

            salePerVisitPercent() {
                return toPercentString(this.cards.sale.count, this.cards.presented.count);
            },

            visitWithApptPercent() {
                return toPercentString(this.visitWithAppt, this.cards.appointment.count);
            },

            visitWithoutApptPercent() {
                return toPercentString(this.presentedValue.countWithoutAppointment, this.cards.number.count);
            },

            visitWithAppt() {
                return this.presentedValue.count - this.presentedValue.countWithoutAppointment;
            },
        },

        methods: {
            async fetchStats(options) {
                await Promise.all([this.$refs.statsTable.fetchStats(options), this.$refs.graphs.fetch(options)]);
            },

            setColumns() {
                useDashboardStore().configs.columns = {
                    bulk_select: {
                        visible: true,
                    },
                    fullname: {
                        visible: true,
                    },
                    contact_method: {
                        visible: true,
                    },
                    civility: {
                        visible: false,
                    },
                    business: {
                        visible: false,
                    },
                    created_by: {
                        visible: false,
                    },
                    updated_by: {
                        visible: false,
                    },
                    'customer:casl_consent_status': {
                        visible: false,
                    },
                    'customer:casl_consent_limit_date': {
                        visible: false,
                    },
                    date: {
                        visible: true,
                    },
                    updated_at: {
                        visible: false,
                    },
                    'division:name': {
                        visible: true,
                    },
                    status: {
                        visible: true,
                    },
                    result: {
                        visible: true,
                    },
                    'lead_type:name': {
                        visible: false,
                    },
                    advisors: {
                        visible: true,
                    },
                    agents: {
                        visible: true,
                    },
                    'commercial:': {
                        visible: false,
                    },
                    'account:name': {
                        visible: true,
                    },
                    rating: {
                        visible: false,
                    },
                    city: {
                        visible: false,
                    },
                    country: {
                        visible: false,
                    },
                    province: {
                        visible: false,
                    },
                    postal_code: {
                        visible: false,
                    },
                    address: {
                        visible: false,
                    },
                    locale: {
                        visible: false,
                    },
                    sector: {
                        visible: false,
                    },
                    budget: {
                        visible: false,
                    },
                    walk_around: {
                        visible: false,
                    },
                    'wanted_vehicles:budget_min': {
                        visible: false,
                    },
                    'wanted_vehicles:budget_max': {
                        visible: false,
                    },
                    created_method: {
                        visible: false,
                    },
                    lead_state: {
                        visible: false,
                    },
                    'import:original_file': {
                        visible: false,
                    },
                    'wanted_vehicles:category': {
                        visible: false,
                    },
                    'wanted_vehicles:category_rv': {
                        visible: false,
                    },
                    'wanted_vehicles:mechanical': {
                        visible: false,
                    },
                    'wanted_vehicles:length_min': {
                        visible: false,
                    },
                    'wanted_vehicles:length_max': {
                        visible: false,
                    },
                    'wanted_vehicles:weight': {
                        visible: false,
                    },
                    'wanted_vehicles:fuel': {
                        visible: false,
                    },
                    'wanted_vehicles:make': {
                        visible: true,
                    },
                    'wanted_vehicles:model': {
                        visible: true,
                    },
                    'wanted_vehicles:year': {
                        visible: true,
                    },
                    'wanted_vehicles:version': {
                        visible: false,
                    },
                    'wanted_vehicles:certified': {
                        visible: false,
                    },
                    'wanted_vehicles:transmission': {
                        visible: false,
                    },
                    'wanted_vehicles:color_exterior': {
                        visible: false,
                    },
                    'wanted_vehicles:color_interior': {
                        visible: false,
                    },
                    'wanted_vehicles:stock_state': {
                        visible: false,
                    },
                    'wanted_vehicles:stock': {
                        visible: true,
                    },
                    'wanted_vehicles:vin': {
                        visible: false,
                    },
                    phone_appointment: {
                        visible: true,
                    },
                    appointment: {
                        visible: true,
                    },
                    presented: {
                        visible: true,
                    },
                    road_test: {
                        visible: true,
                    },
                    long_term: {
                        visible: false,
                    },
                    dealer_tour: {
                        visible: false,
                    },
                    take_over: {
                        visible: false,
                    },
                    'take_over_director:': {
                        visible: false,
                    },
                    twenty_four_hour: {
                        visible: true,
                    },
                    be_back: {
                        visible: false,
                    },
                    deposit: {
                        visible: false,
                    },
                    write_up: {
                        visible: false,
                    },
                    progress_state: {
                        visible: true,
                    },
                    approved: {
                        visible: true,
                    },
                    'lead_bank:name': {
                        visible: false,
                    },
                    waiting_sale: {
                        visible: false,
                    },
                    sale_by_phone: {
                        visible: false,
                    },
                    sale: {
                        visible: true,
                    },
                    available: {
                        visible: false,
                    },
                    paperwork: {
                        visible: false,
                    },
                    prepared: {
                        visible: true,
                    },
                    prepared_work_order: {
                        visible: false,
                    },
                    gas: {
                        visible: false,
                    },
                    deliverable: {
                        visible: false,
                    },
                    delivery: {
                        visible: false,
                    },
                    delivered: {
                        visible: false,
                    },
                    'wanted_vehicles:recorded_date': {
                        visible: false,
                    },
                    csi: {
                        visible: false,
                    },
                    'exchange_vehicles:category': {
                        visible: false,
                    },
                    'exchange_vehicles:category_rv': {
                        visible: false,
                    },
                    'exchange_vehicles:mechanical': {
                        visible: false,
                    },
                    'exchange_vehicles:length_min': {
                        visible: false,
                    },
                    'exchange_vehicles:length_max': {
                        visible: false,
                    },
                    'exchange_vehicles:weight': {
                        visible: false,
                    },
                    'exchange_vehicles:make': {
                        visible: false,
                    },
                    'exchange_vehicles:model': {
                        visible: false,
                    },
                    'exchange_vehicles:year': {
                        visible: false,
                    },
                    'exchange_vehicles:version': {
                        visible: false,
                    },
                    'exchange_vehicles:certified': {
                        visible: false,
                    },
                    'exchange_vehicles:transmission': {
                        visible: false,
                    },
                    'exchange_vehicles:color_exterior': {
                        visible: false,
                    },
                    'exchange_vehicles:color_interior': {
                        visible: false,
                    },
                    'exchange_vehicles:condition': {
                        visible: false,
                    },
                    'exchange_vehicles:fuel': {
                        visible: false,
                    },
                    'exchange_vehicles:tire_type': {
                        visible: false,
                    },
                    'exchange_vehicles:accidented_damage_cost': {
                        visible: false,
                    },
                    'exchange_vehicles:value': {
                        visible: false,
                    },
                    'exchange_vehicles:actual_value': {
                        visible: false,
                    },
                    'exchange_vehicles:calculator_appraiser': {
                        visible: false,
                    },
                    'exchange_vehicles:calculator_value': {
                        visible: false,
                    },
                    listening: {
                        visible: false,
                    },
                    followed: {
                        visible: true,
                    },
                    followed_by: {
                        visible: false,
                    },
                    todo: {
                        visible: true,
                    },
                    provider: {
                        visible: false,
                    },
                    source: {
                        visible: true,
                    },
                    form: {
                        visible: false,
                    },
                    imported_campaign: {
                        visible: true,
                    },
                    referrer: {
                        visible: false,
                    },
                    search_term: {
                        visible: false,
                    },
                    keyword: {
                        visible: false,
                    },
                    'latest_comment:content': {
                        visible: true,
                    },
                    unsubscribe: {
                        visible: false,
                    },
                    birth_date: {
                        visible: false,
                    },
                    ...this.getCustomFields(),
                };
            },

            setCards(results) {
                this.cards.number.count = results.count;
                this.cards.assigned.count = results.assignments;
                this.cards.phoneAppointment.count = results.phoneAppointments;

                this.cards.appointment.count = results.appointments.count;
                this.cards.appointment.noShow = results.appointments.noShow;
                this.cards.appointment.confirmed = results.appointments.confirmed;
                this.cards.appointment.canceled = results.appointments.canceled;

                this.cards.presented.count = results.visits.count;
                this.cards.presented.countWithoutAppointment = results.visits.withoutAppointment;

                this.cards.presentedHome.count = results.homeVisits.count;
                this.cards.presentedHome.countWithoutAppointment = results.homeVisits.withoutAppointment;

                this.cards.sale.count = results.sales.count;
                this.cards.saleByPhone.count = results.sales.byPhone;

                this.cards.delivered.count = results.deliveries;

                this.cards.reached.count = results.results.reached;
                this.cards.attempted.count = results.results.attempted;
                this.cards.untreated.count = results.results.pending;

                this.cards.reachedAverage.count = results.timings.responseTime.average;
                this.cards.firstUpdateAverage.count = results.timings.firstUpdateTime.average;

                this.$nextTick(() => {
                    this.setCaching('cards', this.cards);
                });
            },
        },
    };
</script>
