var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{ref:"table",staticClass:"w-full border-t-2",attrs:{"border":"","stripe":"","empty-text":_vm.isLoading ? _vm.$t('dashboards.statsTableLoading') : '',"data":_vm.tableData,"default-sort":_vm.sort,"show-summary":true,"summary-method":_vm.formatTotals},on:{"sort-change":_vm.sortChange}},[_c('el-table-column',{attrs:{"label-class-name":"sticky-column"}},[_c('el-table-column',{attrs:{"prop":"name","fixed":true,"header-align":"center","label":_vm.nameColumnText,"min-width":"200","sort-by":"name","sortable":true,"sort-method":_vm.sortNameColumn,"sort-orders":['descending', 'ascending'],"class-name":"sticky-column"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('name-cell',{attrs:{"row":row,"sub-level-key":_vm.filters.subLevelKey,"show-sub-level":_vm.showSubLevel},on:{"toggle-row":_vm.toggleRow}})]}}])})],1),_c('el-table-column',{attrs:{"header-align":"center","label":_vm.$t('dashboards.stats.headers.process')}},[_c('el-table-column',{attrs:{"prop":"total_leads","label":_vm.$tc('general.client', 0),"header-align":"center","align":"center","min-width":"125","sort-by":"total_leads","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('span',{class:{ 'font-semibold': row.expanded }},[_vm._v(" "+_vm._s(row[column.property])+" ")]),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"total_appointments","label":_vm.$tc('webBoost.appointment', 0),"header-align":"center","align":"center","min-width":"105","sort-by":"total_appointments","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('span',{class:{ 'font-semibold': row.expanded }},[_vm._v(" "+_vm._s(row[column.property])+" "+_vm._s(_vm.toPercent(row.total_appointments, row.total_leads))+" ")]),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey,"percentage":['total_appointments', 'total_leads']}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"total_appointments_confirmed","label":_vm.$t('service.columns.confirmation'),"header-align":"center","align":"center","min-width":"125","sort-by":"total_appointments_confirmed","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('span',{class:{ 'font-semibold': row.expanded }},[_vm._v(" "+_vm._s(row[column.property])+" "+_vm._s(_vm.toPercent(row.total_appointments_confirmed, row.total_leads))+" ")]),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey,"percentage":['total_appointments_confirmed', 'total_leads']}}):_vm._e()]}}])})],1),_c('el-table-column',{attrs:{"header-align":"center","label":_vm.$t('dashboards.stats.headers.visits')}},[_c('el-table-column',{attrs:{"prop":"total_leads_presented_with_appointment","label":_vm.$tc('leadXpress.visitWithAppointment', 0),"header-align":"center","align":"center","sort-by":"total_leads_presented_with_appointment","min-width":"95","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('span',{class:{ 'font-semibold': row.expanded }},[_vm._v(" "+_vm._s(row[column.property])+" "+_vm._s(_vm.toPercent(row.total_leads_presented_with_appointment, row.total_appointments))+" ")]),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey,"percentage":['total_leads_presented_with_appointment', 'total_appointments']}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"total_leads_presented_without_appointment","label":_vm.$tc('leadXpress.visitWithoutAppointment', 0),"header-align":"center","align":"center","sort-by":"total_leads_presented_without_appointment","min-width":"95","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('span',{class:{ 'font-semibold': row.expanded }},[_vm._v(" "+_vm._s(row[column.property])+" "+_vm._s(_vm.toPercent(row.total_leads_presented_without_appointment, row.total_leads))+" ")]),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey,"percentage":['total_leads_presented_without_appointment', 'total_leads']}}):_vm._e()]}}])},[_c('template',{slot:"header"},[_vm._v(" "+_vm._s(_vm.$tc('leadXpress.visitWithoutAppointment', 0))+" "),_c('activix-tooltip',{attrs:{"placement":"top","content":_vm.$t('phoneUp.visitWithoutAppointmentTooltip')}},[_c('icon',{staticClass:"text-grey-500 text-sm ml-1",attrs:{"name":"regular/information-circle"}})],1)],1)],2)],1),_c('el-table-column',{attrs:{"header-align":"center","label":_vm.$t('dashboards.stats.headers.soldServices')}},[_c('el-table-column',{attrs:{"prop":"category_maintenance","label":_vm.$t('service.categories.maintenance'),"header-align":"center","align":"center","min-width":"95","sort-by":"category_maintenance","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('span',{class:{ 'font-semibold': row.expanded }},[_vm._v(" "+_vm._s(row[column.property])+" ")]),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"category_alignment","label":_vm.$t('service.categories.alignment'),"header-align":"center","align":"center","min-width":"95","sort-by":"category_alignment","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('span',{class:{ 'font-semibold': row.expanded }},[_vm._v(" "+_vm._s(row[column.property])+" ")]),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"category_brakes","label":_vm.$t('service.categories.brakes'),"header-align":"center","align":"center","min-width":"95","sort-by":"category_brakes","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('span',{class:{ 'font-semibold': row.expanded }},[_vm._v(" "+_vm._s(row[column.property])+" ")]),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"category_transmission","label":_vm.$t('service.categories.transmission'),"header-align":"center","align":"center","min-width":"95","sort-by":"category_transmission","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('span',{class:{ 'font-semibold': row.expanded }},[_vm._v(" "+_vm._s(row[column.property])+" ")]),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"category_motor","label":_vm.$t('service.categories.motor'),"header-align":"center","align":"center","sort-by":"category_motor","min-width":"95","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('span',{class:{ 'font-semibold': row.expanded }},[_vm._v(" "+_vm._s(row[column.property])+" ")]),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"category_wash","label":_vm.$t('service.categories.wash'),"header-align":"center","align":"center","sort-by":"category_wash","min-width":"95","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('span',{class:{ 'font-semibold': row.expanded }},[_vm._v(" "+_vm._s(row[column.property])+" ")]),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"category_tires","label":_vm.$t('service.categories.tires'),"header-align":"center","align":"center","sort-by":"category_tires","min-width":"95","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('span',{class:{ 'font-semibold': row.expanded }},[_vm._v(" "+_vm._s(row[column.property])+" ")]),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"category_parts","label":_vm.$t('service.categories.parts'),"header-align":"center","align":"center","min-width":"95","sort-by":"category_parts","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('span',{class:{ 'font-semibold': row.expanded }},[_vm._v(" "+_vm._s(row[column.property])+" ")]),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"category_body","label":_vm.$t('service.categories.body'),"header-align":"center","align":"center","min-width":"95","sort-by":"category_body","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('span',{class:{ 'font-semibold': row.expanded }},[_vm._v(" "+_vm._s(row[column.property])+" ")]),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"category_others","label":_vm.$t('service.categories.others'),"header-align":"center","align":"center","min-width":"95","sort-by":"category_others","sortable":true,"sort-orders":['descending', 'ascending']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('span',{class:{ 'font-semibold': row.expanded }},[_vm._v(" "+_vm._s(row[column.property])+" ")]),(row.expanded)?_c('sub-level-cell',{attrs:{"row":row,"column-name":column.property,"sub-level-key":_vm.filters.subLevelKey}}):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }