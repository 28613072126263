<template>
    <a
        :class="{ truncate: customFieldType != 'datetime' }"
        @mouseenter="fetchLead(lead.id)"
        @click="openCustomFieldEditModal"
    >
        <template v-if="displayName">
            {{ displayName }}
        </template>
        <template v-else>-</template>
    </a>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    // Value Objects
    import { parseCustomField } from '../../../value-objects/CustomField.js';

    // Entities
    import Lead from '../../../entities/Lead.js';

    // Pinia
    import { useContextStore } from '../../../store/modules/context/store.js';
    import { useGlobalStore } from '../../../store/store.js';

    export default {
        name: 'ColumnsComment',

        props: {
            lead: {
                type: Lead,
                required: true,
            },
            field: {
                type: String,
                required: true,
            },
            columnTitle: {
                type: String,
                required: true,
            },
        },

        computed: {
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            customId() {
                const length = 'customId_'.length;
                return this.field.substr(length);
            },

            customField() {
                return this.lead.custom_fields.find(custom => {
                    return custom.id == this.customId;
                });
            },

            customFieldType() {
                return this.contextAccount.getCustomFieldById(this.customId).type;
            },

            displayName() {
                if (!this.customField) {
                    return '-';
                }

                return parseCustomField.toRead(this.customField.pivot.value, this.customFieldType);
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['fetchLead']),

            openCustomFieldEditModal() {
                this.$modal.show('dashboard:customTextEdit', {
                    leadId: this.lead.id,
                    title: this.columnTitle,
                    customField: this.customField,
                    customFieldId: this.customId,
                });
            },
        },
    };
</script>
